﻿.banner {

    @include media-breakpoint-up(md) {
        display: flex;
        max-height: rem-calc(650);
        min-height: rem-calc(516);
        overflow: hidden;
        position: relative;
    }


    &__inner {
        position: relative;

        @include media-breakpoint-up(md) {
            padding: rem-calc(30);
            width: 100%;
        }

        @include media-breakpoint-up(xl) {
            padding: rem-calc(45 100);
        }
    }

    &__media {
        overflow: hidden;
        position: relative;
        padding-bottom: 50%;

        @include media-breakpoint-up(md) {
            bottom: 0;
            left: 0;
            padding-bottom: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
    

        &__image {
            bottom: 0;
            display: block;
            left: 50%;
            min-height: 100%;
            min-width: 100%;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate3d(-50%, -50%, 0);
            width: auto;
            //check supports
            @supports (object-fit: cover) {
                height: 100%;
                left: 0;
                min-height: 0;
                min-width: 0;
                object-fit: cover;
                object-position: right;
                top: 0;
                transform: none;
                width: 100%;
            }
        }
    }

    &__content {
        background-color: palette('black');
        color: #fff;
        font-weight: 300;
        line-height: 1.4;
        padding: rem-calc(40 30 30);

        @include media-breakpoint-up(md) {
            background-color: transparent;
            font-size: rem-calc(24);
            position: relative;
            overflow: hidden;
            padding: rem-calc(30 30 60);
            width: calc(100% / 3);
            z-index: 1;

            &::after {
                background-color: palette('black');
                content: '';
                display: block;
                height: 100%;
                left: 0;
                opacity: 0.8;
                position: absolute;
                transform: skewY(-10deg);
                transform-origin: top left;
                transition: opacity 0.3s ease;
                top: 0;
                width: 100%;
                z-index: -1;
            }

            &:hover::after {
                opacity: 0.9;
            }
        }

        @include media-breakpoint-up(xl) {
            padding: rem-calc(40 40 70);
        }
    }

    &__title {
        font-size: rem-calc(26);
        font-weight: 600;
        margin-bottom: rem-calc(24);
    }


    @each $theme, $bgcolor in $brand-palette {

        &--#{$theme} {

            .banner__content {
                background-color: palette($theme);

                &::after {
                    background-color: palette($theme);
                }

                @include media-breakpoint-up(md) {
                    background-color: transparent;
                }
            }
        }
    }
}
