.featured-person {

  .person-card-angled {
    width: 40%;
    max-width: px-to-em(410px);
    height: 100%;
    background-color: transparent;
    box-shadow: none;
    margin-right: px-to-em(80px);

    &-svg-wrapper {
      $card-height: 460;
      $card-width: 410;

      position: relative;
      width: 100%;
      height: 0;
      padding-top: calc($card-height / $card-width) * 100%;
      display: block;
    }
  }

  &__overview {
    @include content-wrapper;

    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    &--slide {
      max-width: px-to-em(1605px);
      padding: 0;
    }

    &--employee-card {
      max-width: px-to-em(1120px);
      padding: 0;
    }
  }

  &__info-container {
    display: flex;
    flex-direction: column;
    width: 50%;
  }

  &__quote {
    font-family: $font-family-copy;
    font-size: px-to-em(18px);
    font-weight: $font-weight-bold;
    line-height: 1.6;
  }

  &__divider {
    border-bottom: 3px solid $color-brand-red;
    width: 95px;
    margin: px-to-em(40px) 0;
  }

  &__info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: px-to-em(20px);
    font-family: $font-family-primary;
    font-weight: $font-weight-bold;
    line-height: 1.4;
  }

  &__name, &__position {
    padding-right: px-to-em(6px);

    &--last {
      &:after {
        content: ',';
        padding-right: px-to-em(6px);
      }
    }
  }

  &__location {
    &:before {
      content: '-';
      padding-right: px-to-em(6px);
    }
  }

  @include screen($bp-between-desktop) {
    .person-card-angled {
        width: 45%;
    }

    &__overview {
      padding-left: 1.875em;
      padding-right: 1.875em;
    }

    &__info-container {
      width: 48%;
    }
  }

  @include screen($bp-tablet) {

    &__divider {
      margin: px-to-em(45px) 0;
    }
  }

  @include screen($bp-mobile-landscape) {

    .person-card-angled {
      width: 100%;
      max-width: px-to-em(300px);
      margin-left: auto;
      margin-right: auto;
      margin-bottom: $section-spacing-mobile;
  }

    &__overview {
      flex-direction: column;
    }

    &__info-container {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    &__quote {
      text-align: center;
    }

    &__divider {
      margin: px-to-em(35px) auto;
    }

    &__info {
      justify-content: center;
    }

    &__name, &__name--last, &__position, &__location {
      font-size: $font-size-h5-mobile;
    }
  }

  @include screen($bp-mobile-portrait) {
    &__info-container {
      max-width: px-to-em(300px);
    }
  }
}