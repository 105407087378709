﻿.blockquote-feature {
    font-family: 'Lora';
    font-size: rem-calc(22);
    font-style: italic;
    line-height: 1.4;
    margin: 0;
    padding: 0;


    &__cite {
        display: block;
        font-family: 'Open Sans';
        font-size: rem-calc(16);
        font-style: normal;
        margin-top: rem-calc(14);
    }
}

.quote-carousel {
    padding-bottom: rem-calc(50);
    position: relative;

    @include media-breakpoint-up(xxl) {
        padding-bottom: rem-calc(80);
    }


    &__cell {
        width: 100%;

        & * a {
            text-decoration: underline;
        }
    }
}

.flickity-prev-next-button {

    .quote-carousel & {
        border-radius: 0;
        bottom: 0;
        height: 30px;
        top: auto;
        transform: none;
        width: 30px;

        &.previous {
            left: 0;
        }

        &.next {
            left: 60px;
            right: auto;
        }
    }
}
