

.featured-tile {
	position: relative;
	background-color: $color-entity-tile;
	overflow: hidden;
}

.featured-tile--prominent {
	$prominent-heading: 48px;
	.featured-tile-header {
		min-height: px-to-em($entity-tile-height-prominent);
		padding-right: 30%;
	}

	.featured-tile-heading { 
		font-size: px-to-em($prominent-heading);
		max-width: none;
	}

	.featured-tile-meta {
		$title-size: 26px;

		font-size: px-to-em($title-size);
		margin-bottom: px-to-em($section-spacing-small-px, $title-size);
	}

	.featured-tile-heading,
	.featured-tile-abstract {
		max-width: none;
	}
}

.featured-tile-header {
	height: 100%;
	min-height: px-to-em($entity-tile-height);
	display: block;
	color: $color-brand-white;
	position: relative;
	z-index: 5;
	padding: $element-spacing ($element-spacing-small * 2) $element-spacing;

	&:hover {
		.featured-tile-heading {
			color: $color-text-white-hover;
		}
	}

	&.is-external-link {
		&:after {
			display: none;
		}

		.featured-tile-heading {
			@include ico-icon-after($icon-external_link) {
				position: absolute;
		        font-size: 0.5em;
		        margin-left: 0.5em;
		        margin-top: 1em;
		        vertical-align: middle;
			}
		}

	}
}


.featured-tile-meta {
	$title-size: 20px;

	@include font-open-sans-light($title-size);

	line-height: 1.5;
	margin-bottom: px-to-em($section-spacing-small-px, $title-size);
	display: inline-block;
}

.featured-tile-type {
	font-weight: $font-weight-bold;


	&:after {
		content: '-';
		display: inline-block;
		margin-right: 0.25em;
		margin-left: 0.5em;
		font-weight: $font-weight-regular;
	}
}

.featured-tile-date {
	& ~ .featured-tile-location,
	& ~ .featured-tile-source {
		&:before {
			content: '|';
			display: inline-block;
			margin-right: 0.25em;
		}
	}
}

.featured-tile-abstract {
	margin-top: px-to-em($element-spacing-px, $font-size-copy-lead);
	max-width: px-to-em(545px,  $font-size-copy-lead);
}

.featured-tile-heading {
	$heading-size: 34px;

	@include font-open-sans-light($heading-size);
	@include transition(color);

	//margin-top: px-to-em($section-spacing-small-px, $heading-size);
	max-width: px-to-em(545px, $heading-size);
	display: block;
}

.featured-tile-subheading {
	$subheading-size: 26px;

	font-size: px-to-em($subheading-size);
	margin-top: px-to-em($element-spacing-small-px, $subheading-size);
}


.related-entity-tile-featured {
	@extend %uc;

	font-weight: $font-weight-extra-bold;
	background-color: $color-brand-red;
	padding: 0.25em 0.75em;
	margin-right: 1em;
}

@include screen($bp-desktop) {

	.featured-tile-header {
		padding-bottom: 18%;
		min-height: inherit;
	}
}


@include screen($bp-tablet) {

	.featured-tile-header {
		min-height: px-to-em($entity-tile-height-tablet);
		padding-bottom: $section-spacing-mobile;
		
	}

	.featured-tile-heading {
		$heading-size: 24px;

		font-size: px-to-em($heading-size);
		line-height: 1.35;
		max-width: none;
	}

	.feature-tile-abstract {
		max-width: none;
	}

	.featured-tile--prominent {

		$prominent-heading: 36px;
		
		.featured-tile-header {
			min-height: $entity-tile-height-prominent-tablet;
			padding-right: $element-spacing;
		}

		.featured-tile-meta {
			font-size: px-to-em(20px);
		}

		.featured-tile-heading { 
			font-size: px-to-em($prominent-heading);
		}
	}
}

@include screen($bp-mobile) {
	.featured-tile-header {
		min-height: px-to-em($entity-tile-height-mobile);
	}

	.featured-tile--prominent {

		$prominent-heading: 24px;
		
		.featured-tile-header {
			min-height: px-to-em($entity-tile-height-mobile * 1.4);
			padding-right: $element-spacing;
		}

		.featured-tile-heading { 
			font-size: px-to-em($prominent-heading);
		}
	}

	.featured-tile-abstract {
		display: none;
	}
}