.article-body {
	&__forecast {
		.article-body-key-takeaways {
			position: relative;
			color: $color-brand-white;
			margin-bottom: $section-spacing-large;
			margin-top: $section-spacing-large;
			font-size: px-to-em(24px);

			&__title {
				font-size: px-to-em($font-size-h4);
				padding: px-to-em($element-spacing-small-px) 0;
			}


			.rte {
				padding: px-to-em($element-spacing-px) px-to-em($element-spacing-px);

				ul {
					padding-left: 0;

					li {
						padding-bottom: px-to-em(15px);
						line-height: 1.3;
						&:before {
			                content: '';
			            }
			            &:not(:last-child) {
				            	&:after {
						  		content: '';
								position: absolute;
								left: 0;
								display: inline-block;
								height: 1em;
								width: 20%;
								border-bottom: 1px solid;
								margin-top: px-to-em(20px);
							}
						}
		            }
				}
			}

			//angled background
			&:after {
				content: '';
				width: 100%;
				height: 100%;
				position: absolute;
				background: inherit;
				z-index: -1;
				bottom: 0;
				background-color: $forecast-primary-red;			
				transform: skewY($angled-degree * -1);
			}

			&__button {
				width: 100%;
			}
		}

		.rte {
			strong {
				font-family: $font-family-primary;
			}
			blockquote {
				@include font-size-forecast-copy-text-2column;

				margin: 20px 0;
				padding: 60px 20px;
				background-color: $color-gray;

				&:after {
					top: 2em;
				}
				&:before {
					bottom: 2em;
				}
			}
		}

		.rte {
			@include font-size-forecast-copy-text-2column;
		}
		.rte--lead {
			@include font-size-forecast-copy-lead-2column;
		}

		&--right-column {
			.btn-ghost {
				margin-bottom: px-to-em($element-spacing-small-px);
			}
		}

		@include screen($bp-tablet, "min") {
			display: flex;

			&--left-column {
				padding-right: px-to-em($element-spacing-px);
				width: 60%;
			}

			&--right-column {
				padding-left: px-to-em($element-spacing-px);
				width: 40%;
			}

			.article-body-img-lead {
				margin: 0 0 0 (-1 * $site-padding);
			}	

			.article-body-key-takeaways {
				margin-top: $section-spacing-small;
			}		
		}
	}
}

.join-the-conversation {
    background-color: $color-gray;
    width: 100%;
    padding: px-to-em(20px) $element-spacing;    
    flex-direction: row;
    align-items: baseline;

    .silent-wrapper {
    	display: flex;
    }

    &__connect-title {
        @include font-open-sans-light($font-size-h4-mobile);
        @include transition(font-size);
        color: $color-gray-dark;
        padding-right: px-to-em(15px);
    }

    &__connect-icon-link {
        padding-right: px-to-em(8px);
        transition: opacity 0.2s ease-in-out;

        &:hover {
            opacity: 0.7;
        }

        &:before {
            font-family: 'reedsmith';
        }

        &:before {
            color: $forecast-primary-red;
        }        
    }	

    @include screen($bp-tablet, "min") {
    	padding: px-to-em(60px) 0;    
  
        &__connect-title {
            @include font-open-sans-light($font-size-h2-mobile);
        }

        &__connect-icon-link {
            font-size: 1.85em;
            padding-right: 1em;
        }    
    }
}

.article-body-details {
	padding-top: $section-spacing--custom;

	.article-intro + & {
		border-top: $border-list;
	}
}

.article-body-img-lead {
	margin: 0 (-1 * $site-padding);

	> img {
		width: 100%;
		display: block;
	}
}

@include screen($bp-tablet) {

	.article-body-details {
		padding-top: px-to-em($section-spacing-mobile-px, $font-size-copy-mobile) ;
	}

	.article-body-img-lead {
		margin: 0 (-1 * $site-padding-mobile);
	}

}

.page-article-detail__forecast {
	.breadcrumbs {
		font-size: 14px;
	}

	.article-tile-heading:focus {
		.article-tile-card {
			outline: $diversity-cyan solid 5px;
			outline-style: inset;
		}
	}

	.article-intro-abstract {
			@include font-open-sans-regular;

			font-family: $font-family-primary;
	}

	.article-intro-authors {
		@include font-open-sans-bold;
		font-family: $font-family-primary;
		font-size: $font-size-copy-lead;
	}

	.article-intro-body {
			font-family: $font-family-primary;
	}
	&.forecast-theme__melon {
		.rte a {
			color: $forecast-primary-melon;
		}
		.article-body-key-takeaways {
			&:after {
				background-color: $forecast-primary-melon;			
			}
	    }	    

	    .join-the-conversation {
		    &__connect-icon-link {
		        &:before {
		            color: $forecast-primary-melon;
		        }        
		    }		    
	    }

	    .related-areas-link,
		.attachments-list-link,
	    .article-intro-author {
			@include link-transition($forecast-primary-melon, $color-link-hover);
		} 	

		.scrollspy-nav-toggle {
			background-color: $forecast-primary-melon;			
		}

		blockquote,
		.blockquote {
				&:before,
				&:after {
						border-top-color: $forecast-primary-melon;
				}
		}
    }

	&.forecast-theme__marine {
		.rte a {
			color: $forecast-primary-marine;
		}

		.article-body-key-takeaways {
			&:after {
				background-color: $forecast-primary-marine;
			}
	    }	
	    
	    .join-the-conversation {
		    &__connect-icon-link {
		        &:before {
		            color: $forecast-primary-marine;
		        }        
		    }		    
	    }	

	    .related-areas-link,
		.attachments-list-link,
	    .article-intro-author {
			@include link-transition($forecast-primary-marine, $color-link-hover);
		} 	 

   		.scrollspy-nav-toggle {
			background-color: $forecast-primary-marine;			
		}

		blockquote,
	    .blockquote {
	        &:before,
	        &:after {
	            border-top-color: $forecast-primary-marine;
	        }
	    }		
    }    

	&.forecast-theme__plum {
		.rte a {
			color: $forecast-primary-plum;
		}
		.article-body-key-takeaways {
			&:after {
				background-color: $forecast-primary-plum;			
			}
	    }	    

	    .join-the-conversation {
		    &__connect-icon-link {
		        &:before {
		            color: $forecast-primary-plum;
		        }        
		    }		    
	    }	   

	    .related-areas-link,
		.attachments-list-link,
	    .article-intro-author {
			@include link-transition($forecast-primary-plum, $color-link-hover);
		} 

   		.scrollspy-nav-toggle {
			background-color: $forecast-primary-plum;			
		}	

		blockquote,
	    .blockquote {
	        &:before,
	        &:after {
	            border-top-color: $forecast-primary-plum;
	        }
	    }				
    }      

	&.forecast-theme__red {
		.rte a {
			color: $forecast-primary-red;
		}
		.article-body-key-takeaways {
			&:after {
				background-color: $forecast-primary-red;			
			}
	    }		    

	    .join-the-conversation {
		    &__connect-icon-link {
		        &:before {
		            color: $forecast-primary-red;
		        }        
		    }		    
	    }	    

	    .related-areas-link,
		.attachments-list-link,
	    .article-intro-author {
			@include link-transition($forecast-primary-red, $color-link-hover);
		}

   		.scrollspy-nav-toggle {
			background-color: $forecast-primary-red;			
		}	

		blockquote,
	    .blockquote {
	        &:before,
	        &:after {
	            border-top-color: $forecast-primary-red;
	        }
	    }
    }

	&.forecast-theme__olive {
		.rte a {
			color: $forecast-primary-olive;
		}
		.article-body-key-takeaways {
			&:after {
				background-color: $forecast-primary-olive;			
			}
	    }		    

	    .join-the-conversation {
		    &__connect-icon-link {
		        &:before {
		            color: $forecast-primary-olive;
		        }        
		    }		    
	    }	    

	    .related-areas-link,
		.attachments-list-link,
	    .article-intro-author {
			@include link-transition($forecast-primary-olive, $color-link-hover);
		}

   		.scrollspy-nav-toggle {
			background-color: $forecast-primary-olive;			
		}	

		blockquote,
	    .blockquote {
	        &:before,
	        &:after {
	            border-top-color: $forecast-primary-olive;
	        }
	    }
    }

	&.forecast-theme__brand-red {
		.rte a {
			color: $forecast-primary-brand-red;
		}
		.article-body-key-takeaways {
			&:after {
				background-color: $forecast-primary-brand-red;			
			}
	    }		    

	    .join-the-conversation {
		    &__connect-icon-link {
		        &:before {
		            color: $forecast-primary-brand-red;
		        }        
		    }		    
	    }	    

	    .related-areas-link,
		.attachments-list-link,
	    .article-intro-author {
			@include link-transition($forecast-primary-brand-red, $color-link-hover);
		}

   		.scrollspy-nav-toggle {
			background-color: $forecast-primary-brand-red;			
		}	

		blockquote,
	    .blockquote {
	        &:before,
	        &:after {
	            border-top-color: $forecast-primary-brand-red;
	        }
	    }
    }

	&.forecast-theme__royal {
		.rte a {
			color: $forecast-primary-royal;
		}
		.article-body-key-takeaways {
			&:after {
				background-color: $forecast-primary-royal;			
			}
	    }		    

	    .join-the-conversation {
		    &__connect-icon-link {
		        &:before {
		            color: $forecast-primary-royal;
		        }        
		    }		    
	    }	    

	    .related-areas-link,
		.attachments-list-link,
	    .article-intro-author {
			@include link-transition($forecast-primary-royal, $color-link-hover);
		}

   		.scrollspy-nav-toggle {
			background-color: $forecast-primary-royal;			
		}	

		blockquote,
	    .blockquote {
	        &:before,
	        &:after {
	            border-top-color: $forecast-primary-royal;
	        }
	    }
    }

	&.forecast-theme__tangerine {
		.rte a {
			color: $forecast-primary-tangerine;
		}
		.article-body-key-takeaways {
			&:after {
				background-color: $forecast-primary-tangerine;			
			}
	    }		    

	    .join-the-conversation {
		    &__connect-icon-link {
		        &:before {
		            color: $forecast-primary-tangerine;
		        }        
		    }		    
	    }	    

	    .related-areas-link,
		.attachments-list-link,
	    .article-intro-author {
			@include link-transition($forecast-primary-tangerine, $color-link-hover);
		}

   		.scrollspy-nav-toggle {
			background-color: $forecast-primary-tangerine;			
		}	

		blockquote,
	    .blockquote {
	        &:before,
	        &:after {
	            border-top-color: $forecast-primary-tangerine;
	        }
	    }
    }

	&.forecast-theme__dark {
		.rte a {
			color: $forecast-primary-dark;
		}
		.article-body-key-takeaways {
			&:after {
				background-color: $forecast-primary-dark;			
			}
	    }		    

	    .join-the-conversation {
		    &__connect-icon-link {
		        &:before {
		            color: $forecast-primary-dark;
		        }        
		    }		    
	    }	    

	    .related-areas-link,
		.attachments-list-link,
	    .article-intro-author {
			@include link-transition($forecast-primary-dark, $color-link-hover);
		}

   		.scrollspy-nav-toggle {
			background-color: $forecast-primary-dark;			
		}	

		blockquote,
	    .blockquote {
	        &:before,
	        &:after {
	            border-top-color: $forecast-primary-dark;
	        }
	    }
    }

	&.forecast-theme__light {
		.rte a {
			color: $forecast-primary-light;
		}
		.article-body-key-takeaways {
			&:after {
				background-color: $forecast-primary-light;			
			}
	    }		    

	    .join-the-conversation {
		    &__connect-icon-link {
		        &:before {
		            color: $forecast-primary-light;
		        }        
		    }		    
	    }	    

	    .related-areas-link,
		.attachments-list-link,
	    .article-intro-author {
			@include link-transition($forecast-primary-light, $color-link-hover);
		}

   		.scrollspy-nav-toggle {
			background-color: $forecast-primary-light;			
		}	

		blockquote,
	    .blockquote {
	        &:before,
	        &:after {
	            border-top-color: $forecast-primary-light;
	        }
	    }
    }
}