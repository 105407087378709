.probono {
    $section-padding: px-to-em(70px);
    $section-padding-lg: px-to-em(130px);


    &__call-to-action-section {
        width: 100%;
        height: 70%;
        padding: $section-padding 10%;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        margin-bottom: 5em;
        text-align: center;
    }

    &__call-to-action-title {
        @include font-open-sans-bold($font-size-h4-mobile);
        @include transition(font-size);
        color: $color-brand-white;
        text-align: center;
        padding-bottom: px-to-em(15px);
    }

    &__call-to-action-body {
        @include font-open-sans-regular($font-size-h4-mobile);
        @include transition(font-size);
        color: $color-brand-white;
        line-height: $font-size-h3-mobile;
        text-align: center;
        padding-bottom: px-to-em(30px);
    }

    &__call-to-action-container {
        display: block;

        &--hidden {
            display: none;
        }
    }

    @include screen($bp-mobile, "min") {

        &__button {
            margin-bottom: 0;

            &--hero {

                &:first-child {
                    margin-right: 15px;
                }
            }
        }
    }

    @include screen($bp-desktop, "min") {

        &__call-to-action-section {
            padding: $section-padding-lg 20%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            background-attachment: fixed;
        }

        &__call-to-action-title {
            @include font-open-sans-bold($font-size-h2-mobile);
        }

        &__call-to-action-body {
            @include font-open-sans-regular($font-size-h4);
            line-height: 34px;
        }
    }
}
