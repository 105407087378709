.page-articles-landing {

    .listing-articles-item {
        &:last-child {
            border-bottom: 0;
        }
    }

    .search-entity-landing {

        .listing-articles-item {
            &:last-child {
                padding-bottom: 0;
            }
        }
    }
}
