.professional-result {
	font-size: 0.8em;
	padding-bottom: $section-spacing-reduced;
	border-bottom: $border-list-heavy;

	& + & {
		padding-top: $section-spacing-reduced;
	}
}

.professional-result-position {
	$title-size: 24px;

	font-size: px-to-em($title-size);
}

.professional-result-name {
	$heading-size: 36px;

	@include font-open-sans-extra-bold($heading-size);
	@include link-transition;
	@include transition(color);

	display: inline;
	line-height: 1.1;

	color: $color-link;

	&:hover {
		color: $color-link-hover;
	}
}

.professional-result-details {
	margin-top: $result-item-spacing;
}

.professional-result-contact-option {
	$title-size: 24px;

	font-size: px-to-em($title-size);
	line-height: 1.4;
}

.professional-result-email {
	font-weight: $font-weight-bold;
	color: $color-link;
	@include link-transition;

	&:hover {
		color: $color-link-hover;
	}
}

.professional-result-office-link{
	$title-size: 28px;
	@include font-open-sans-bold($title-size);
	@include link-transition;

	line-height: 1.3;
	color: $color-link;

	&:hover {
		color: $color-link-hover;
	}
}

.professional-result-details {
	display: table;
	width: 100%;
}

.professional-result-contact,
.professional-result-offices {
	display: table-cell;
}

.professional-result-contact {
	width: 60%;
	padding-right: 5%;
}

.professional-result-offices  {
	vertical-align: bottom;
	text-align: right;
}

.professional-result-link {
	display: flex;
	flex-direction: column;

	.professional-result-position {
		order: -1;
	}
}

@include screen($bp-tablet) {
	.professional-result-position,
	.professional-result-contact-option,
	.professional-result-office-link{
		$title-size: 20px;

		font-size: px-to-em($title-size);
	}


	.professional-result-name {
		$heading-size: 34px;

		font-size: px-to-em($heading-size);	
	}

}

@include screen($bp-mobile) {
	.professional-result {
		padding-bottom: $section-spacing-mobile;

		& + & {
			padding-top: $section-spacing-mobile;
		}
	}

	.professional-result-details {
		margin-top: $section-spacing-mobile;
	}
}

@include screen($bp-mobile-landscape) {
	.professional-result-details {
		display: block;
	}

	.professional-result-contact,
	.professional-result-offices {
		display: block;
	}
	
	.professional-result-contact {
		width: auto;
	}

	.professional-result-offices {
		text-align: left;
		margin-top: $section-spacing-small;
	}

	.professional-result-office {
		display: inline;
		margin-right: 0.25em;

		&:last-child {
			.professional-result-office-link {
				&:after {
					content: '';
				}
			}
		}
	}
	.professional-result-office-link {
		&:after {
			content: ',';
			color: $color-text-copy;
		}
	}
}	