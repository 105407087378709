.video-header {
    $header-padding: $site-padding*1.5;
    $background-offset: px-to-em(40px);
    padding-top: $header-padding;
    position: relative;
    max-width: px-to-em(2000px);
    margin-left: auto;
    margin-right: auto;

    &.no-featured-people {
        margin-bottom: $section-spacing-large-px;
    }

    &-background {
        @include bg-cover;

        background-color: $color-black;
        background-position: center 0;
        position: absolute;
        top: 0;
        bottom: $background-offset * -1;
        left: 0;
        right: 0;
        z-index: -2;
    }

    &:before {
        content: '';

        display: block;
        position: absolute;
        top: 0;
        bottom: $background-offset * -1;
        left: 0;
        right: 0;
        z-index: -1;
        background: rgba(0,0,0,0.4);
    }

    &-body-content {
        @include content-wrapper;

        position: relative;
        z-index: $zindex-angled-hero + 5;
        padding-top: $section-spacing-largest * 1.25;
        padding-bottom: $section-spacing-largest * 1.5;
    }

    &-play-button {
        $size: 99px;

        display: block;
        margin: $section-spacing auto 0;
        width: px-to-em($size);
        height: px-to-em($size);
        border-radius: 50%;
        border: 1px solid $color-brand-white;
        background-color: rgba(0,0,0,0.43);
        color: $color-brand-gold;
        transition: $transition-default;

        &:before {
            @include ico-icon();

            font-size: px-to-em(30px);
        }

        &:hover {
            color: $color-brand-white;
            background-color: $color-opaque-black;
        }
    }

    &-featured-person-button {
        $size: 80px;
        width: px-to-em($size);
        height: px-to-em($size);
        border-radius: 50%;
        border: 1px solid $color-brand-white;
        background-color: rgba(0,0,0,0.43);
        color: $color-brand-gold;
        transition: $transition-default;

        &:before {
            @include ico-icon();
            font-size: px-to-em(30px);
        }

        &:hover {
            color: $color-brand-white;
            background-color: $color-opaque-black;
        }
    }

    @include screen($bp-tablet) {

        &-body-content {
            padding-top: $section-spacing-largest;
            padding-bottom: $section-spacing-largest;
        }

        &-play-button {
            $size: 65px;

            width: px-to-em($size);
            height: px-to-em($size);

            &:before {
                font-size: px-to-em(20px);
            }
        }
    }

    @include screen($bp-mobile) {
        &-body-content {
            padding-top: $section-spacing-module-mobile;
            padding-bottom: $section-spacing-module-mobile;
        }

        &-play-button {
           margin-top: $section-spacing-module-mobile;
        }
    }
}
