﻿$side-nav-edge-offset: px-to-em(10px);
$side-nav-edge-padding: px-to-em(20px);
$side-nav-icon-width: 65px;
$skew-degree: 8deg;





.side-nav {

}
.sidenav-section {
	& + & {
		margin-top: $section-spacing-small;
	}
}


.sidenav-section-header {
	position: relative;

}

.sidenav-section-title {
	position: relative;
	color: $color-brand-white;
	display: table;
	width: 100%;
	height: px-to-em($side-nav-icon-width);
	padding-right: px-to-em($side-nav-icon-width) + calc($side-nav-edge-padding/2);
	padding-left: $side-nav-edge-padding;

	&.is-current {
		cursor: default;
	}

	&:hover,
	&.is-current {
		.sidenav-section-skew {
			&:after {
				right: 0;
			}
		}
	}
}


.sidenav-section-skew {
    @include absolute-full;
    display: block;
    transform: skewX($skew-degree * -1);
    background-color: $color-brand-red;
    z-index: 1;
    backface-visibility: hidden;

    &:after {
        @include absolute-full;
        @include transition(right);
        content: '';
        display: block;
        background-color: $color-brand-black;
        right: 100%;
        backface-visibility: hidden;
    }

    &--diversity {
        background-color: $diversity-green;
    }

    &--winrs {
        background-color: $diversity-orange;
    }

    &--probono {
        background-color: $diversity-blue;
    }

    &--responsible-business {
        background-color: $diversity-orange;
    }
}



.sidenav-section-title-label  {
	$title-size: 20px;
	@include font-open-sans-extra-bold($title-size);

	display: table-cell;
	vertical-align: middle;
	position: relative;
	z-index: 3;
	overflow: hidden;
	padding: px-to-em(15px, $title-size) 0;
}


.sidenav-children-trigger {
    width: px-to-em($side-nav-icon-width);
    position: absolute;
    right: 0;
    top: 0;
    bottom: -1px;
    z-index: 4;
    color: $color-brand-white;
    overflow: visible;

    &:after {
        @include font-open-sans-extra-bold(48px);
        content: '+';
        line-height: 1;
        z-index: 6;
        position: relative;
    }

    .sidenav-section-skew {
        right: px-to-em(-1px);
        top: px-to-em(-1px);
        background-color: $color-brand-black;
        border: 1px solid $color-brand-black;

        &:after {
            background-color: $color-brand-white;
        }
    }

    &.is-current {
        .sidenav-section-skew {
            &--diversity,
            &--winrs,
            &--probono,
            &--responsible-business {
                background-color: $color-brand-white;
            }

            &--diversity::after {
                background-color: $diversity-green;
            }

            &--winrs::after {
                background: $diversity-orange;
            }

            &--probono::after {
                background: $diversity-blue;
            }

            &--winrs::after {
                background: $diversity-red;
            }

        }

        &:after {
            color: $color-brand-black;
        }
    }

    &:hover,
    &[aria-expanded="true"] {
        color: $color-brand-black;

        .sidenav-section-skew {
            &:after {
                right: 0;
            }
        }
    }
}
    //expanded node
    .sidenav-section {

        &.is-expanded {

            .sidenav-children-trigger {
                padding-bottom: 0.5em;

                &:after {
                    content: '–'
                }
            }
        }
    }
    //sublist styles
    .sidenav-children:not(.sidenav-grandchildren) {
        @include transition(height);
        padding: 0 ($side-nav-edge-offset - $side-nav-edge-padding);
        height: 0;
        overflow: hidden;


        &[aria-hidden="false"] {
            height: auto;
        }
    }

    .sidenav-children-option {
        padding: 1em 0;
        margin-left: 8%;
        margin-right: 1em;

        & + & {
            border-top: $border-list;
        }
    }

    .sidenav-grandchildren-option {
        @extend .sidenav-children-option;
    }

    .sidenav-children-title {

        @include font-open-sans-bold(18px);
        color: $color-link;

        &:hover {
            color: $color-link-hover;
        }

        &.sidenav-current-page-link {
            color: $color-text-gray;
        }

        &--diversity {
            color: $diversity-green;
        }

        &--winrs {
            color: $diversity-orange;
        }

        &--probono {
            color: $diversity-blue;
        }

        &--responsible-business {
            color: $diversity-red;
        }
    }

    .sitenav-mobile-toggle {
        @extend %uc;

        @include font-open-sans-extra-bold();
        padding: 1em;
        padding-right: 2.5em;
        background-color: $color-brand-black;
        display: block;
        color: $color-brand-white;
        width: 100%;
        text-align: left;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        position: relative;
        display: none;

        @include ico-icon-after($icon-chevron-down) {
            @include center(vertical);
            font-size: 0.5em;
            position: absolute;
            right: 1rem;
        }
    }


    @include screen($bp-tablet) {
        $side-nav-icon-width: 55px;

        .sidenav-section-title-label,
        .sidenav-children-title {
            font-size: 1em;
        }

        .sidenav-section-title {
            padding-right: px-to-em($side-nav-icon-width) + $side-nav-edge-padding;
        }


        .sidenav-children-trigger {
            width: px-to-em($side-nav-icon-width);


            &:after {
                font-size: px-to-em(30px);
            }
        }
    }

    @include screen($bp-mobile) {
        .sidenav-section {
            background-color: $color-brand-red;

            & + & {
                margin-top: 0;
                border-top: $border-white;
            }

            &--diversity {
                background-color: $diversity-green;
            }

            &--winrs {
                background-color: $diversity-orange;
            }

            &--probono {
                background-color: $diversity-blue;
            }
        }

        .sidenav-section-skew {
            display: none;
        }

        .sidenav-section-header {
            margin: 0;
            padding: 0;

            &:after,
            &:before {
                display: none;
            }
        }

        .sidenav-section-title {
            text-transform: uppercase;

            &:after {
                display: none;
            }

            &.is-current {
                color: $color-light-red;

                &--diversity,
                &--winrs,
                &--probono,
                &--responsible-business {
                    color: $color-brand-black;
                }
            }
        }

        .sidenav-section-title,
        .sidenav-children-title {
            padding-left: 1em;
            padding-right: 1em;
        }

        .sidenav-children-trigger {
            display: none;
        }

        .sidenav-children {
            padding: 0;
            overflow: auto;
            max-height: inherit;
            opacity: 1;
        }

        .sidenav-children-option {
            margin-right: 0;
            margin-left: 1em;
            padding: 0 1em 1em;

            & + & {
                border-top: 0;
            }
        }

        .sidenav-grandchildren-option {
            margin-right: 0;
            margin-left: 1em;
            line-height: normal;
            padding: 1em 1em 0;

            & + & {
                border-top: 0;
            }
        }

        .sidenav-children-title {
            color: $color-brand-white;
            padding: 0;
            // @include link-transition($color-brand-white, $color-brand-white);
            display: inline;

            &:hover {
                color: $color-brand-white;
            }
        }

        .sitenav-mobile-toggle {
            display: block;
            z-index: 5;
        }

        .sidenav {
            position: relative;
            z-index: 10;
            margin-bottom: $section-spacing-mobile;
        }

        .sidenav-list {
            position: absolute;
            top: 100%;
            width: 100%;
            opacity: 0;
            visibility: hidden;
            transform: translateY(-3em);
            transition: opacity $transition-ease $transition-timing, transform $transition-ease $transition-timing, visibility $transition-ease $transition-timing*2;

            .show-menu & {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
                transition: opacity $transition-ease $transition-timing, transform $transition-ease $transition-timing;
            }
        }
    }



