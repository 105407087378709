﻿.featuregroup {

    @include media-breakpoint-up(md) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @supports (display: grid) {
            display: grid;
            grid-auto-rows: 1fr;
            grid-template-columns: 1fr 1fr;
        }
    }


    &__cell {
        width: 100%;
    

        &--half {

            @include media-breakpoint-up(md) {
                width: 50%;

                @supports (display: grid) {
                    width: 100%;
                }
            }
        }
    }
}
