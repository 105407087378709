.page-location-detail {

	.scrollspy {

		.scrollspy-sections:last-child {
			margin-bottom: $angled-bg-margin;
		}
	}

	.read-more-toggle {
		margin-left: auto;
		margin-right: auto;
		display: block;
	}

	.perspectives-news-events {
		margin-bottom: 0;

		& + .impactful-cta  {
			margin-top: 0;
		}

		& + .around-office  {
			margin-top: $angled-bg-margin;
		}
	}
}

@include screen($bp-between-desktop) {
	.page-location-detail {

		.perspectives-news-events {
			
			& + .around-office  {
				margin-top: $angled-bg-padding ;
			}
		}
	}
}



@include screen($bp-mobile) {

	.page-location-detail {

		.perspectives-news-events {

			& + .around-office  {
				margin: $section-spacing-module-mobile 0;
			}
		}
	}
}