.featured-articles {
	.section-header {
		margin-bottom: $result-item-spacing;
	}
}

.featured-articles-grid {
	$grid-spacing: px-to-em(15px);

	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-left: calc($grid-spacing / -2);
	margin-right: calc($grid-spacing / -2);

	.article-tile {
		width: calc(50% - #{$grid-spacing});
		margin-left: calc($grid-spacing / 2);
		margin-right: calc($grid-spacing / 2);
		margin-bottom: $grid-spacing;
	}
}

@include screen($bp-tablet, min) {
	.featured-articles-grid {
		.article-tile.prominent .article-tile-heading {
			// Don't stretch the card to the bottom of the article-tile
			display: block;
		}

		.article-tile-card {
			margin-bottom: 8rem;
			width: 19em;
		}
		
		.featured-articles-grid {
			width: calc(50% - 1em);
			width: calc(50% - #{$grid-spacing});
			margin-left: calc($grid-spacing / 2);
			margin-right: calc($grid-spacing / 2);
			margin-bottom: $grid-spacing;
			flex-direction: column;
	
			.article-tile {
				width: auto;
				margin: 0;
				display: flex;
	
				.article-tile-heading {
					height: auto;
					width: 100%;
				}
	
				&:last-child {
					margin-top: $grid-spacing;
				}
			}
		}
	}
}

@include screen($bp-tablet) {
	.featured-articles {
		.section-header {
			margin-bottom: $section-spacing-mobile;
		}
	}

	.featured-articles-grid {
		display: block;
		margin-left: 0;
		margin-right: 0;

		.article-tile {
			width: 100%;
			margin-left: 0;
			margin-right: 0;
		}
	}
}