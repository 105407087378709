$related-entity-height: px-to-em(324px);

.section-card {
	position: relative;
	background-color: $color-entity-tile;
	overflow: hidden;
}

.section-card-header {
	height: 100%;
	min-height: px-to-em($entity-tile-height);
	display: block;
	color: $color-brand-white;
	position: relative;
	z-index: 5;
	padding: $element-spacing ($element-spacing-small * 2) $element-spacing;

	&:hover {
		.related-item-tile-heading {
			color: $color-text-white-hover;
		}

		.related-item-tile-subheading {
			color: $color-text-white-hover;
		}
	}
}


.section-card-heading {
	$heading-size: 34px;

	@include font-open-sans-light($heading-size);
	@include transition(color);

	margin-top: px-to-em($section-spacing-small-px, $heading-size);
	max-width: px-to-em(545px, $heading-size);
	display: block;
}

@include screen($bp-desktop) {

	.section-card-header {
		padding-bottom: 18%;
		min-height: px-to-em($entity-tile-height-tablet);
	}
}


@include screen($bp-tablet) {

	.section-card-header {
		padding-bottom: $section-spacing-mobile;
		
	}

	.section-card-heading {
		$heading-size: 24px;

		font-size: px-to-em($heading-size);
		line-height: 1.35;
		max-width: none;
	}
}


@include screen($bp-mobile) {
	.section-card-header {
		min-height: px-to-em($entity-tile-height-mobile);
	}	
}