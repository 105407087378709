$region-img-padding: 40%;
$region-min-height: px-to-em(400px);
$region-timing: 350ms;

.global-perspective-region {
	position: absolute;
	padding-left: $region-img-padding;
	visibility: hidden;
	opacity: 0;
	top: 0;
	left: 0;
	z-index: 1;
	min-height:$region-min-height;


	&.is-region-shown {
		visibility: visible;
		opacity: 1;
		position: relative;
		z-index: 10;
	}
}

.global-perspectives-video {
	margin: $section-spacing-small 0;
	.btn-ghost-label {
        @include ico-icon-after($icon-video-circle) {
            margin-left: 0.5em;
            margin-top: 0.1em;
        }
    }
}

.is-transitioning-out {
	.global-perspective-region-figure {
		 clip-path: inset(100% 0 0 0);
	}

	.global-perspective-region-title,
	.global-perspective-region-abstract {
		opacity: 0;
	}
}

.global-perspective-region-title {
	$heading-size: 36px;

	@include font-open-sans-light($heading-size);
	color:$color-text-gold;
	position: relative;
	z-index: 15;
	opacity: 1;
	transition: opacity $region-timing linear;


	&:hover {
		color: $color-link-hover;
	}
}

.global-perspective-region-abstract {
	opacity: 1;
	transition: opacity $region-timing linear;
	margin-top: px-to-em($element-spacing-px, $font-size-copy);
}

.global-perspective-region-figure {
	position: absolute;
	left: 0;
	top: 0;
	transform: translate(-25%, -25%);
	z-index: -1;
	width: 40%;
	transition: all $region-timing linear;
	clip-path: inset(0 0 0 0);


	> img {
		width: 100%;
		display: block;
	}
}

@include screen($bp-tablet) {
	$region-min-height: px-to-em(300px);
	.global-perspective-region {
		min-height: $region-min-height;
	}

	.global-perspective-region-figure {
		width: 50%;
	}

	.global-perspective-region-title {
		$heading-size: 24px;

		font-size: px-to-em($heading-size);
	}
}
@include screen($bp-mobile) {

	.global-perspective-region {
		padding-left: 0;
		min-height: px-to-em(150px);
	}

	.global-perspective-region-figure {
		display: none;
		// transform: translate($site-padding-mobile * -1, -25%);		
	}
}
