﻿.cta-group {
    padding: rem-calc(25 30);


    &--mobile {

        @include media-breakpoint-up(sm) {
            display: none;
        }
    }

    &--red {
        background-color: palette('red');
    }

    &__item {
        margin-bottom: rem-calc(25);

        &:last-child {
            margin-bottom: 0;
        }
    }
}