$award-tile-min-height: 460px;
$award-tile-min-height-mobile: 260px;

.awards-sections-copy {
	margin-bottom: px-to-em($section-spacing-px, $font-size-copy)
}

.awards-sections-tiles {
	margin-bottom: $section-spacing;


	.section-card {
		& + .section-card  {
			margin-top: $element-spacing;
		}
	}

	.section-card-header {
		min-height: px-to-em($award-tile-min-height);
	}
}

@include screen($bp-desktop) {


	.awards-sections-tiles {

		.section-card-header {
			
			min-height: 50vw;
		}
	}
}


@include screen($bp-tablet) {
	.awards-sections-copy {
		margin-bottom: px-to-em($section-spacing-mobile-px, $font-size-copy-mobile)
	}
	.awards-sections-tiles {
		margin-bottom: $section-spacing-mobile;
		.section-card {
			& + .section-card {
				margin-top: $element-spacing-small;
			}
		}
	}
}


@include screen($bp-mobile) {


	.awards-sections-tiles {

		.section-card-header {
			
			min-height: $entity-tile-height-mobile;
		}
	}
}
