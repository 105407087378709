.narrative-overview {
    &-actions {
        margin-top: $element-spacing;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .ico {
            padding-left: 0.75em;
            vertical-align: middle;
        }
    }

    &-cta {
        width: 48%;
        min-width: auto;
        margin-top: $element-spacing;
    }

    &__columns {
        display: flex;
        justify-content: space-between;
    }

    &__column {
        margin-bottom: 2em;
        &--main {
            width: 65%;
            .narrative-overview__button-container {
                text-align: left;
            }
        }
        &--side {
            width: 25%;
        }
        img {
            width: 100%;
            max-width: 500px;
            margin: 0 auto;
            display: block;
        }
    }
}

@include screen($bp-tablet) {
    .narrative-overview {
        &-actions {
            display: block;
            margin-top: $element-spacing;
        }

        &-cta {
            width: 80%;
            display: block;
            margin-left: 0;
            margin-right: 0;
        }
        &__columns {
            display: block;
        }
        &__column {
            &--main {
                width: 100%;
                .narrative-overview__button-container {
                    text-align: center;
                }
            }
            &--side {
                width: 100%;
            }
        }
    }
}

@include screen($bp-mobile) {
    .narrative-overview {
        &-cta {
            margin-left: auto;
            margin-right: auto;
        }
    }
}
