.site-footer {
	text-align: right;
	padding-bottom: $section-spacing;
	z-index: $zindex-site-footer;

	a {
		&:not(.site-footer-menu-link) {
			text-decoration: underline;
		}
	}

	.social-links-icon {
		color: $color-link;
		margin-left: 0.75em;

		&:before {
			@include transition(transform);

			font-size: px-to-em(26px);
			transform: translateZ(0) scale(1);

		}

		&:hover {
			color: $color-black;
			
			&:before {
				transform: scale(1.2);
			}
		}
	}

	.ico-google-plus {
		&:before {
			font-size: rem(34px);

		}
	}
}
.site-footer-menu {
	margin-top: $element-spacing;
}

.site-footer-menu-item {
	display: inline;
	margin-left: 0.75em;
	line-height: 1em;

	& + & {
		&:before {
			content: '|';
			display: inline-block;
			padding-right: 0.75em;
		}
	}
}

.site-footer-menu-link {
	@extend %uc;

	font-size: px-to-em(14px);
	font-weight: $font-weight-bold;
	color: $color-text-gray;

	&:hover {
		color: $color-black;

	}
}

.site-footer-copyright {
	font-size: px-to-em(14px);
	color: $color-text-gray-light;
	margin-top: $element-spacing-small;
}

@include screen($bp-mobile-landscape) {
	.site-footer {
		text-align: center;
		margin-top: $section-spacing-mobile;

		.social-links-icon {
			&:before {
				font-size: rem(24px);
			}
		}

		.ico-google-plus {
			&:before {
				font-size: rem(30px);

			}
		}
	}

	.site-footer-menu-link {
		display: inline-block;
	}

	.site-footer-menu-item {
		&:last-child {
			&:before {
				display: none;
			}
		}
	}
}