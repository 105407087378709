﻿.sitefooter {
    background-color: palette('dark_grey');
    color: #fff;
    margin-left: auto;
    margin-right: auto;
    max-width: rem-calc($max-site-width);
    overflow: hidden;
    position: relative;
    z-index: 1;

    &::before,
    &::after {
        bottom: 0;
        content: '';
        display: block;
        height: 100%;
        left: 0%;
        opacity: 0.9;
        position: absolute;
        right: 0;
        top: 0;
        transform: rotate(-10deg);
        transform-origin: top right;
        width: 100%;
        z-index: -1;
    }

    &::before {
        background-image: linear-gradient(70deg, rgba(0,0,0,0.00) 50%, rgba(0,0,0,1.00) 100%);
        opacity: 0.7;
        top: 50%;

        @include media-breakpoint-up(lg) {
            top: 35%;
        }
    }

    &::after {
        background-image: linear-gradient(70deg, rgba(0,0,0,0.00) 26%, rgba(0,0,0,1.00) 100%);
        top: calc(50% + 40px);

        @include media-breakpoint-up(lg) {
            top: calc(35% + 40px);
        }
    }


     &__sitelink {
        display: none;
        height: rem-calc(70);
        width: rem-calc(178);

        @include media-breakpoint-up(lg) {
            display: inline-block;
        }
    }

    &__content {
        padding: rem-calc(40);

        @include media-breakpoint-up(lg) {
            padding-bottom: rem-calc(100);
            padding-top: rem-calc(100);
        }

        @include media-breakpoint-up(xl) {
            padding: rem-calc(100 100 70);
        }
    }

    &__content__social {
        padding-top: rem-calc(60);

        @include media-breakpoint-up(lg) {
            display: flex;
            justify-content: flex-end;
        }
    }

    &__copyright {
        font-size: rem-calc(14);
    }

    &__row {

        @include media-breakpoint-up(lg) {
            display: flex;
        }
    }

    &__cell {

        @include media-breakpoint-up(lg) {
            flex: 1 1 auto;
            width: calc(100% / 3);
        }
    }

    &__cell--logo {

        @include media-breakpoint-up(lg) {
            flex-grow: unset;
            width: 25%;
        }
    }
}

.footermenu {
    list-style: none;
    margin: rem-calc(0 0 30);
    padding-left: 0;


    &--utility {
        margin-bottom: rem-calc(48);

        @include media-breakpoint-up(lg) {
            margin-bottom: rem-calc(16);
        }
    }

    &__item {
    }

    &__item--utility {
        display: inline-block;
        margin-right: rem-calc(10);
        padding-right: rem-calc(10);
        position: relative;

        &::after {
            border-right: 1px solid #fff;
            bottom: 6px;
            content: '';
            display: block;
            position: absolute;
            right: 0;
            top: 6px;
        }

        &:last-child::after {
            content: none;
        }
    }

    &__link {
        display: inline-block;
        font-weight: 600;
        line-height: 1.3;
        padding: rem-calc(6 0);

        &:focus,
        &:hover {
            text-decoration: underline;
        }
    }

    &__link--utility {
        padding: rem-calc(3 0);
    }
}
