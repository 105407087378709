
.keyword-search-grid {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
}

.keyword-search-grid-option {
	flex-grow: 1;
	width: 100%;
	position: relative;

	& + &  {
		margin-left: $keyword-spacing;
	}
}



@include screen($bp-mobile) {
	.keyword-search-grid {
		display: block;
	}
	.keyword-search-grid-option {
		& + & {
			margin-left: 0;
			margin-top: $keyword-spacing;
		}
	}
}