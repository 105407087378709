.cta-bucket {

  &__wrapper {
    max-width: 63.75em;
    padding-left: 1.875em;
    padding-right: 1.875em;
    margin-left: auto;
    margin-right: auto;
  }

  &__cta-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-top: px-to-em(45px);
    margin-bottom: 6.875em;
  }

  &__cta {
    flex: 1 0 auto;

    &:not(:last-of-type) {
      margin-right: 1em;
    }
  }

  @include screen($bp-desktop-large) {

    &__cta-container {
      flex-direction: column;
    }

    &__cta {
      width: 100%;
      margin-right: 0;
      
      &:not(:last-of-type) {
        margin-bottom: 1em;
        margin-right: 0;
      }
    }
  }
}