.page-global-solutions {
    .angled-hero {
        margin-bottom: 5em;
        padding-top: 10em;
        padding-bottom: 10em;
        position: relative;
        @include max-width-md() {
            margin-bottom: 0;
            padding-top: 3em;
            padding-bottom: 3em;
        }
        &__bg {
            position: absolute;
            background-size: cover;
            background-position: center 0px;
            background-repeat: no-repeat;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        &__overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,0.3);
        }
    }

    .scrollspy-nav {
        background: $diversity-orange;

        .scrollspy-nav-option.is-inview {
            .scrollspy-nav-label {
                color: $color-brand-white;
            }
        }

        .scrollspy-nav-link {
            &:hover {
                .scrollspy-nav-label {
                    color: $color-brand-white;
                }
            }
        }
        .scrollspy-nav-label {
            background: $diversity-orange;
            color: $color-brand-black;
        }
        @media screen and (max-width: 64em) {
            background: none;
            .scrollspy-nav-toggle {
                background: $diversity-orange;
            }
            .scrollspy-nav-label {
                background: $color-brand-black;
                color: $color-brand-gold;
            }
        }
    }

    .professional-card-link,
    .professional-card-office {
        color: $diversity-orange;
    }

    .read-more,
    .related-professionals {
        .btn-ghost {
            color: $diversity-orange;
            border-color: $diversity-orange;

            &:hover {
                color: white;
            }

            &:after {
                background-color: $diversity-orange;
            }
        }
    }

    .our-technology {
        padding: 3em 0;
        background-image: url("../images/Product_list_background.jpg");
        background-attachment: fixed;
        background-size: cover;
        background-position: center;
        color: #fff;
        text-align: center;
        &__header {
            font-size: 2.25em;
            margin-bottom: 1em;
        }
        &__description {
            font-family: $font-family-lora;
            margin-bottom: 1em;
        }
        &__links {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
        }
        &__link {
            font-size: 1.5em;
            width: calc(50% - 0.75em);
            padding: 1.25em 0;
            border-bottom: 2px solid #fff;
            text-align: left;
        }
        @include max-width-sm() {
            &__link {
                width: 100%;
            }
        }
    }

    .products {
        margin: 5em 0;
        &__item {
            background-color: #f3f3f3;
            padding: 2em;
            margin-bottom: 2.5em;
            border: none;
            .professional-card-link,
            .professional-card-office {
                color: $diversity-orange;
            }
        }
        &__header {
            margin-bottom: 2rem;
            .accordion-node-trigger {
                font-size: 2em;
                color: $color-brand-black;
                &::before {
                    font-size: 30px;
                    background-color: $diversity-orange;
                }
                &:hover {
                    &::before {
                        background-color: $color-brand-black;
                    }
                }
            }
        }
        &__subtitle {
            font-size: 1.5em;
            font-weight: 800;
            margin-bottom: 2rem;
        }
        &__view-more {
            line-height: 1.6em;
            margin-bottom: 2rem;
            .professional-card {
                margin-top: 2rem;
            }
        }
        &__description {
            font-family: $font-family-lora;
            margin-bottom: 2.5em;
        }
        &__button {
            background: #fff;
            margin-bottom: 2rem;
        }
        .products-dropdown {
            margin-bottom: 1.375em;
            display: flex;
            align-items: center;
            &__label {
                margin-right: 1em;
                font-weight: 800;
                width: 60%;
            }
            &__container {
                position: relative;
                width: 100%;
            }
            &__button {
                position: relative;
                width: 100%;
                text-align: left;
                background-color: $color-brand-gold;
                padding: 0.9375em;
                padding-right: 1.875em;
                font-weight: 800;
                @include ico-icon-after($icon-chevron-down) {
                    $icon-size: 10px;
                    @include center(vertical);
                    font-size: px-to-em($icon-size);
                    right: 1.5em;
                }
            }
            &__items {
                position: absolute;
                top: 100%;
                width: 100%;
                background-color: #3d3c3c;
                color: #fff;
                padding: 1.5625em;
                opacity: 0;
                visibility: hidden;
                max-height: 21.875em;
                overflow: auto;
                z-index: 10;
                &--active {
                    opacity: 1;
                    visibility: visible;
                }
            }
            &__item {
                &:hover {
                    cursor: pointer;
                }
                &:not(:first-of-type) {
                    padding-top: .625em;
                    margin-top: .625em;
                    border-top: 1px solid hsla(0,0%,100%,.65);
                }
            }
            @include max-width-sm() {
                display: block;
                &__label {
                    display: block;
                    width: 100%;
                    margin: 0 0 0.5em 0;
                }
            }
        }
    }

    .case-studies {
        background-color: #ececec;
        padding: 5em 0;
        &__flex {
            display: flex;
            &:not(:first-of-type) {
                border-top: 1px solid #ccc;
                margin-top: 5em;
                padding-top: 5em;
            }
        }
        &__header {
            margin-bottom: 2rem;
            text-align: center;
            font-weight: 800;
            font-size: 2em;
        }
        &__image {
            min-width: 0;
            width: 100%;
            height: auto;
            flex: 0 0 25%;
            align-self: flex-start;
            padding: 0 3em 0 0;
            &--tablet {
                display: none;
            }
        }
        &__content {
            display: flex;
            flex: 1 1 75%;
            flex-direction: column;
        }
        &__columns {
            display: flex;
            margin-bottom: auto;
            padding-bottom: 1.5em;
        }
        &__column {
            flex-grow: 1;
            flex-basis: 0;
            &:not(:last-of-type) {
                padding-right: 3em;
            }
        }
        &__name {
            font-size: 2em;
            margin-bottom: 0.5em;
        }
        &__label {
            font-size: 1.5em;
            font-weight: 800;
            margin-bottom: 1em;
        }
        &__text {
            font-family: $font-family-lora;
            line-height: 1.5em;
        }
        &__button {
            align-self: flex-start;
            background: #fff;
        }
        &--right {
            .case-studies__image {
                order: 2;
                padding: 0 0 0 3em;
            }
        }

        @include max-width-lg() {
            &--right .case-studies__image,
            &__image {
                &--tablet {
                    display: block;
                }
                &--desktop {
                    display: none;
                }
                order: 0;
            }
            &__columns {
                flex-flow: row wrap;
                justify-content: space-between;
            }
            &--right .case-studies__image,
            &__image,
            &__column {
                flex-basis: calc(50% - 1.5em);
                flex-grow: 0;
                margin-bottom: 2em;
                padding: 0;
                &:not(:last-of-type) {
                    padding-right: 0;
                }
            }
        }
        @include max-width-sm() {
            &__columns {
                display: block;
            }
        }
    }
    .services {
        margin-top: 5em;
        margin-bottom: 5em;
        .section-header {
            margin-bottom: 5em;
        }
        &__bg {
            background-image: url('../images/capabilities-lines.jpg');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }
        @include max-width-md() {
            &__bg {
                background-image: none !important;
            }
        }
    }

    .global-solutions__logo--mobile {
        display: none;
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
        padding-left: 1em;
        padding-right: 1em;
        padding-bottom: 1em;

        @media screen and (max-width: 64em) {
            display: block;
        }
    }

    .narrative-overview__column img {
        display: block;

        @media screen and (max-width: 64em) {
            display: none;
        }
    }
}

.global-solutions-tiles {
    .article-tile-card {
        background: rgba(231, 109, 37, 0.75);
        color: #fff;
        margin-bottom: 4rem;
    }
    .article-tile-title {
        font-weight: 600;
        margin-bottom: 1em;
    }
    .article-tile-abstract {
        font-family: $font-family-lora;
    }
    .article-tile-details {
        &::before {
            content: none;
        }
    }
    .article-tile-read-more {
        @include transition(background-color, .35s, ease);
        display: inline-block;
        border: 2px solid #fff;
        padding: 0.4em 1.2em;
        text-transform: uppercase;
        font-weight: 600;
    }

    .article-tile-heading {
        &:hover, &:focus {
            .article-tile-read-more {
                background-color: white;
                color: $diversity-orange;
            }
        }
    }

    &--is-active {
        opacity: 0.6;
        
        .article-tile-heading {
            cursor: default;

            &:hover, &:focus {

                .article-tile-read-more {
                    background-color: transparent;
                    color: #fff;
                }
            }
        }

        &:hover, &:focus {
            .hover-grow-fade-fx-img {
                transform: scale(1);
            }
        }
    }
}

.narrative-overview__button-container {
    text-align: center;
}
