.scrollspy {
	position: relative;

	&-detail {
		.angled-hero {
			margin-bottom: 5em;
		}
	}


	.scrollspy-nav-notice {
		
		@include font-open-sans-bold(16px);
		display: inline-block;
		// font-size: 16px;
		// font-weight: bold;
		text-transform: uppercase;
		color: $color-brand-white;

		@include screen($bp-mobile) {
			display: none;
		}
	}

	@include screen($bp-tablet-landscape) {
		.scrollspy-nav-notice{
			display: none;
		}

		&-detail {
			.angled-hero {
				margin-bottom: 1em;
			}
		}
	}
}

.scrollspy-wrapper {

	@include page-wrapper;

	padding-left: $scrollspy-nav-width + $site-padding;

	&--topics {
		padding-left: $site-padding;
	}

	& + & {
		margin-top: $section-spacing-large;
	}
}

.scrollspy-sections {
	.page-wrapper {
		padding-left: $scrollspy-nav-width + $site-padding;

		&--centered {
			padding-left: $site-padding;
		}
	}
}


.scrollspy-centered-modules {

	.silent-wrapper {
		@include content-wrapper;
		
		padding-left: 0;
		padding-right: 0;
	}
}

@include screen($bp-tablet-landscape) {
	.scrollspy-nav-notice{
		display: none;
	}
}

@include screen($bp-tablet) {

	.scrollspy-wrapper {
		padding-left: $scrollspy-nav-width - 1em;

		&--topics {
			padding-left: inherit;
		}

		& + & {
			margin-top: $section-spacing-large-mobile;
		}
	}

	.scrollspy-sections {

		.page-wrapper {
			padding-left: $scrollspy-nav-width - 1em;

			&--centered {
				padding-left: $site-padding-mobile;
			}
		}
	}
}

@include screen($bp-mobile) {
	.scrollspy-wrapper {
		padding-left: $site-padding-mobile;


	}
	.scrollspy-nav-column{
		margin-top: $section-spacing-large-mobile;
	}

	.scrollspy-sections {

		.page-wrapper {
			padding-left: $site-padding-mobile;
		}
	}
}
