
$angled-bg-padding: 12vw;
$angled-bg-margin: calc($angled-bg-padding/1.5);
$angled-bg-padding-topics: calc($angled-bg-padding/2);


$angled-bg-slender-padding: 5vw;
$angled-bg-slender-margin: $angled-bg-slender-padding;
$angled-bg-slender-margin-diversity: $angled-bg-slender-padding - 1;


.angled-background-container {
    position: relative;
    padding: $angled-bg-padding 0;
    margin: $angled-bg-margin 0;

    &--topics {
        padding: $angled-bg-padding-topics 0;
    }
}

.angled-background-container-slender {
    position: relative;
    padding: $angled-bg-slender-padding 0;
    margin: $angled-bg-slender-margin 0;

    &--diversity {
        margin: $angled-bg-slender-margin-diversity 0;
    }
}

.angled-background {
	@include absolute-full;
	
	z-index: 1;
	transform: skewY($angled-degree * -1) translate3d(0,0,0);
	overflow: hidden;

	&--topics {
		transform: none;
	}

	&:hover {
		.angled-background-image {
			will-change: transform;
		}
	}
}

.angled-background-image {
	$transition-timing-bg: 4s;

	@include bg-cover;

	position: absolute;
	width: 100%;
	transform: skewY($angled-degree) translate3d(0,0,0);
	top: $angled-bg-padding * -0.5;
	bottom: $angled-bg-padding * -0.5;

	transition: transform $transition-timing-bg linear;

	&--topics {
		transform: none;
	}
}


.angled-background--full-slender {

    .angled-background-image {
        top: $angled-bg-slender-padding * -1.25;
        bottom: $angled-bg-slender-padding * -1.25;

        &:before {
            @include absolute-full;
            content: '';
            display: block;
            z-index: 2;
        }

        &:before {
            background-color: rgba(0,0,0,0.75);
        }
    }

    &-diversity {
        .angled-background-image {
            top: $angled-bg-slender-padding * -1.45;
            bottom: $angled-bg-slender-padding * -1.45;
            background-attachment: fixed;
        }

        &:before {
            content: none;
        }
    }
}

.angled-background--pattern {

	.angled-background-image {

		&:after,
		&:before {
			@include absolute-full;
			content: '';
			display: block;
			z-index: 2;
		}

		&:before {
			background-color: rgba(0,0,0,0.75);

		}

		&:after {
			background: url($file-path-images + 'pattern-bg.png') no-repeat center top;
			
			z-index: 3;
		}
	}
}

.angled-background--gradient {

	.angled-background-image {
		background-position: center -15px;
		background-size: auto 100%;
		background-repeat: no-repeat;
		&:before {
			@include absolute-full;
			content: '';
			background-color: rgba(0,0,0,0.75);
		}
	}

	&:before {
		@include absolute-full;
		background-image: linear-gradient(180deg, rgba(61, 60, 60, 0.25) , $color-gray-dark  40em);
		content: '';
		display: block;
		z-index: 2;
	}
}


@include screen($bp-between-desktop) {
	.angled-background-container {
		margin: $angled-bg-padding 0;
	}
}



@include screen($bp-mobile) {

    .angled-background-container {
        margin: $section-spacing-module-mobile 0;
    }

    .angled-background-container-slender {
        position: relative;
        padding: 8vw 0;

        &--diversity {
            padding: 15vw 0;
        }
    }
}

//DIVERSITY
.angled-background--diversity {
    @include absolute-full;
    z-index: 1;
    transform: skewY($angled-degree-diversity * -1) translate3d(0,0,0);
    overflow: hidden;

    &:hover {
        .angled-background-image {
            will-change: transform;
        }
    }
}

.angled-background-container-diversity {
	position: relative;
	padding: $angled-bg-padding 0;
	margin: 0;
    &.notable-news {
        padding-left: 0;
        padding-top: 0;
        padding-right: 0;
        margin-bottom: 0;
    }
}

.angled-background-image-diversity {
	$transition-timing-bg: 4s;

	@include bg-cover;

	position: absolute;
	width: 100%;
	transform: skewY($angled-degree) translate3d(0,0,0);
	top: $angled-bg-padding * -0.5;
	bottom: $angled-bg-padding * -0.5;

	transition: transform $transition-timing-bg linear;

	@include screen($bp-between-tablet, "min") {
		background-attachment: fixed;
	}
}

.angled-background-diversity--pattern {
	z-index: -1;
	.angled-background-image-diversity {
	
		&:after,
		&:before {
			@include absolute-full;
			content: '';
			display: block;
			z-index: 2;
		}
	
		&:after {
			background: no-repeat center top;
			background-size: cover;
			z-index: 3;
		}
	}
}

.angled-background--full-slender-diversity {
    .angled-background-image {
        top: $angled-bg-slender-padding * -1.45;
        bottom: $angled-bg-slender-padding * -1.45;
    }
}

@include screen($bp-desktop, "min") {
    .angled-background--full-slender-diversity {
        .angled-background-image {
            background-attachment: fixed;
        }
    }
}

//Fixes scrolling bug in FF only
@-moz-document url-prefix() {
	
	.angled-background--diversity {
		@include absolute-full;
		z-index: 1;
		transform: none;
		overflow: hidden;
	
		&:hover {
			.angled-background-image {
				will-change: transform;
			}
		}
	}

	.angled-background-image-diversity {
		$transition-timing-bg: 4s;
	
		@include bg-cover;
	
		position: absolute;
		width: 100%;
		transform: none;
		top: $angled-bg-padding * -0.5;
		bottom: $angled-bg-padding * -0.5;
	
		transition: transform $transition-timing-bg linear;
	
		@include screen($bp-between-tablet, "min") {
			background-attachment: fixed;
		}
	}

	.angled-background-diversity--pattern {
		transform: none;
	}

}
