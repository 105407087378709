﻿$page-header-font: 50px;
$page-header-font-diversity: 66px;
$page-header-font-mobile: 28px;

.page-header {
    margin-bottom: $section-spacing;
    color: $color-brand-white;
    text-align: center;

    &--capabilities {
        margin-bottom: $section-spacing-reduced-more;
    }
}

.page-header-title {
	@include font-open-sans-extra-bold($page-header-font);

	line-height: 1.1;
	text-align: center;
}

.page-header-subtitle {
	
	font-size: px-to-em(26px);
	color: $color-brand-white;
	line-height: 1.1;
	text-align: center;
	margin-top: $element-spacing-small;
}

 .page-header-section-link {

 	width: 26%;
 	margin-top: $element-spacing-small-px *2;
 }


@include screen($bp-tablet) {

	.page-header {
		margin-bottom: $section-spacing-mobile;
	}

	.page-header-title {
		font-size: px-to-em($page-header-font-mobile);
		line-height: 1.35;
	}
	.page-header-subtitle {
		font-size: px-to-em(20px);
		line-height: 1.35;
	}

	.page-header-section-link {
		margin-top: $element-spacing;
	}
	
}

@include screen($bp-mobile) {

	.page-header {
		margin-bottom: $section-spacing-mobile;
	}

	.page-header-section-link {
		
		
		width: px-to-em(260px);
	}
	
}

// Diversity

.page-header-title--diversity {
    @include font-open-sans-extra-bold($page-header-font-diversity);
    line-height: 1.1;
    text-align: center;
}