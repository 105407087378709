.related-experience {
	margin-bottom: $section-spacing-large;
}

.related-experience-intro {
	margin-bottom: $section-spacing-small;
}

.related-experience-dropdown {
	margin-bottom: $section-spacing-small;

	display: flex;
	flex-wrap: nowrap;
	align-items: center;

	.dropdown-helper-label {
		vertical-align: middle;
		margin-right: 1em;
		flex-shrink: 1;
	}

	.dropdown {
		flex-grow:1;
		flex-shrink: 1;
		z-index: 10;
	}
}


@include screen($bp-tablet) {
	.related-experience-dropdown {
		display: block;
	}

}
