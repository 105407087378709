.page-about-landing {

	.angled-hero {
		// min-height: px-to-em(700px);
		// max-height: px-to-em($angled-polygon-height);
	}

	.angled-hero-intro {
		padding-bottom: px-to-em(20px);
	}


}

@include screen($angled-hero-desktop) {
	.page-about-landing {

		.angled-hero {
			min-height: inherit;
			max-height: none;
		}

		.angled-hero-intro {
			padding-bottom: 0;
		}


	}
}