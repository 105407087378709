.reader-progress {
	$marginTop: px-to-em(97px);
	width: 100vw;
	left: 0;
	margin-top: -1*$marginTop;
	z-index: $zindex-scrollspy;
  position: absolute;
  
  
  &__sticky-bar {
    position: absolute;
    width: 100%;
    height: 12px;
    background-color: $color-brand-white;
    position: absolute;
    border-top: thin solid $color-brand-white;
  }

	&--sticky & {
    &__sticky-bar {
      position: fixed;
      top: 64px; //
      margin-top: 0;
    }
	}		

	&__bar {
    height: 100%;
    background-color: $color-black;
    width: 20px;
    @include transition(width);
  }

  @include screen($bp-tablet-landscape) {
    margin-top: -40px;

    &__sticky-bar {
      position: fixed;
      top: 68px; // height of header;
      height: 10px;
    }

    .siteheader--unpinned ~ .scrollspy-detail &{
      &__sticky-bar {
        top:0
      } 
    }
    .scrollspy-nav.is-sticky ~  &--sticky &{
      &__sticky-bar {
        top:44px;
      } 
    }
  }
}
