﻿
.feature {
    background-color: palette('black');
    color: #fff;
    overflow: hidden;
    position: relative;

    @include media-breakpoint-up(md) {
        height: 100%;
        min-height: rem-calc(400);
        max-height: rem-calc(750);
    }


    &--block {
        background-color: #fff;
        color: inherit;
    }

    &__inner {
    }

    &__media {
        overflow: hidden;
        position: relative;
        padding-bottom: 50%;

        @include media-breakpoint-up(md) {
            bottom: 0;
            display: block;
            left: 0;
            padding-bottom: 0;
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
        }
    

        &__image {
            bottom: 0;
            display: block;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;

            @include media-breakpoint-up(md) {
                left: 50%;
                min-height: 100%;
                min-width: 100%;
                top: 50%;
                transform: translate3d(-50%, -50%, 0);
                width: auto;
                //check supports
                @supports (object-fit: cover) {
                    height: 100%;
                    left: 0;
                    min-height: 0;
                    min-width: 0;
                    object-fit: cover;
                    object-position: center;
                    top: 0;
                    transform: none;
                    width: 100%;
                }
            }
        }
    }

    &__content {
        padding: rem-calc(30);

        @include media-breakpoint-up(md) {

            .feature--block & {
                background-color: #fff;
                box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.50);
                margin: rem-calc(30);
                max-width: 50%;
                padding: rem-calc(20);
                position: relative;
                z-index: 1;
            }
        }

        @include media-breakpoint-up(xl) {

            .feature--block & {
                margin: rem-calc(60 60 60 100);
                max-width: calc(50% - 100px);
            }
        }

        @include media-breakpoint-up(xxl) {
            padding: rem-calc(60 100);

            .feature--block & {
                padding: rem-calc(30);
            }
        }
    

        &__body {
            font-size: rem-calc(20);
            font-weight: 300;
            line-height: 1.4;
            margin-bottom: rem-calc(42);

            .feature--block & {
                font-family: 'Lora';
                font-weight: 400;

                @include media-breakpoint-up(md) {
                    font-size: rem-calc(16);
                    margin-bottom: rem-calc(24);
                }
            }

            @include media-breakpoint-up(lg) {
                font-size: rem-calc(24);
            }
        }
    }

    &__title {
        font-size: rem-calc(26);
        font-weight: 600;
        line-height: 1.3;
        margin-bottom: rem-calc(24);
    }

    &--gradient {
        z-index: 1;

        &::before,
        &::after {
            bottom: 0;
            content: '';
            display: block;
            height: 100%;
            left: 0%;
            opacity: 0.25;
            position: absolute;
            right: 0;
            top: 0;
            transform: rotate(10deg);
            transform-origin: top left;
            width: 100%;
            z-index: -1;
        }

        &::before {
            background-image: linear-gradient(-70deg, rgba(0,0,0,0.00) 50%, rgba(0,0,0,1.00) 100%);
            opacity: 0.25;
            top: 40%;

            @include media-breakpoint-up(lg) {
                top: 35%;
            }
        }


        &::after {
            background-image: linear-gradient(-70deg, rgba(0,0,0,0.00) 26%, rgba(0,0,0,1.00) 100%);
            top: calc(40% + 40px);

            @include media-breakpoint-up(lg) {
                top: calc(35% + 40px);
            }
        }
    }

    @each $theme, $color in $brand-palette {

        &--#{$theme} {
            background-color: $color;
        }

        &__title--#{$theme} {
            color: $color;
        }
    }
}
