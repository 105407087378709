﻿.globalsearch {
    align-items: center;
    background-color: palette('mid_grey');
    display: flex;
    height: calc(100vh - #{$nav-height-small}px);
    justify-content: center;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: rem-calc($max-site-width);
    position: fixed;
    right: 0;
    top: #{$nav-height-small}px;
    transform: translate3d(0, -130%, 0);
    transition: transform .35s ease, visibility .35s ease .35s;
    visibility: hidden;
    width: 100%;
    z-index: 99;

    &--internal {
        max-width: none;
    }

    &.is-open {
        visibility: visible;
        transform: translate3d(0, -1px, 0);
        transition: transform .35s ease;
    }

    @include media-breakpoint-up(lg) {
        height: calc(100vh - #{$nav-height-large}px);
        top: #{$nav-height-large}px;
    }


    &__inner {
        padding: rem-calc(24);
        width: 100%;
    }

    &__form {
        margin-left: auto;
        margin-right: auto;
        max-width: rem-calc(768);

        @include media-breakpoint-up(lg) {
            max-width: rem-calc(1024);
        }
    }

    &__fieldset {
        border: 0;
        margin: 0;
        padding: 0;


        &--control {

            @include media-breakpoint-up(lg) {
                text-align: center;
            }
        }
    }

    &__label {
        display: block;
        font-size: rem-calc(22);
        margin-bottom: rem-calc(16);

        @include media-breakpoint-up(lg) {
            font-size: rem-calc(32);
        }
    }

    &__input[type=text] {
        background: transparent;
        border-color: palette('black');
        border-width: 0 0 1px 0;
        font-size: rem-calc(24);
        margin-bottom: rem-calc(48);
        padding: rem-calc(4 0);
        width: 100%;

        @include media-breakpoint-up(lg) {
            margin-bottom: rem-calc(72);
        }
    }

    &__mobile-close {
        position: absolute;
        top: 15px;
        left: 5px;

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
}
