.grey-wedge-background {
	position: relative;
	padding-bottom: 8%;
	min-height: px-to-em(590px);


	@include gradient-overlay($color-brand-white, #f9f9f9 200px) {


		@include center(horizontal);

		max-width: $max-width;
		width: 100%;
		right: auto;
		z-index: -7;
		display: block;
	}

	&:after {
		content: '';

		@include page-wrapper;
		@include center(horizontal);

		bottom: 0;
		margin-bottom: calc($section-spacing/-2);
		height: 100%;
		width: 100%;

		background: url($file-path-images + 'gray-wedge.jpg') no-repeat center bottom;
		background-position: center bottom;
		background-size: 100% auto;
		background-repeat: no-repeat;
		z-index: -5;
	}
}


@include screen($bp-between-desktop) {
	.grey-wedge-background {
		min-height: px-to-em(200px);
		padding-bottom: 20%;

	}
}

@include screen($bp-mobile) {

	.grey-wedge-background {
		min-height: inherit;

		&:after {
			margin-bottom:0;
		}
	}

}
