.nav {
    background-color: palette('dark_grey');
    color: #fff;
    height: calc(100vh - #{$nav-height-small}px);
    left: 0;
    overflow: auto;
    position: relative;
    right: 0;
    top: 100%;
    transform: translateY(-120%);
    transition: transform 0.3s $nav-easing;
    z-index: 10;

    .siteheader & {
        position: absolute;
    }

    &.is-open {
        transform: translateY(0);

        @include media-breakpoint-up(lg) {
            transform: none;
        }
    }

    @include media-breakpoint-up(md) {
        height: auto;
        overflow: visible;
    }

    @include media-breakpoint-up(lg) {
        background-color: transparent;
        color: palette('black');
        align-items: center;
        display: flex;
        justify-content: flex-end;
        left: auto;
        min-height: rem-calc($nav-height-large);
        position: static;
        right: auto;
        top: auto;
        transform: none;
        transition: none;

        .siteheader & {
            position: static;
        }
    }


    &__inner {
        padding: rem-calc(12 24 40);

        @include media-breakpoint-up(md) {
            display: flex;
        }

        @include media-breakpoint-up(lg) {
            display: block;
            padding: rem-calc(0);
        }
    }

    &__menu {
        list-style: none;
        margin-bottom: rem-calc(24);
        margin-top: 0;
    }

    &__sub {
        display: none;

        @include media-breakpoint-up(lg) {
            align-items: flex-start;
            background-color: palette('mid_grey');
            display: flex;
            min-height: rem-calc($subnav-height-large);
            left: 0;
            opacity: 0;
            padding: rem-calc(30 30);
            position: absolute;
            right: 0;
            transform: translate3d(0, -120%, 0);
            transition: opacity .2s ease, visibility .2s ease .2s, transform .2s ease .2s;
            visibility: hidden;

            &.is-open {
                opacity: 1;
                visibility: visible;
                transform: translate3d(0, -1px, 0);
                transition: opacity .2s ease;
            }

            &--full-width {
                width: 100%;
            }
        }

        @include media-breakpoint-up(xl) {
            padding-left: rem-calc(20);
            padding-right: rem-calc(20);
        }


        &__left {

            @include media-breakpoint-up(lg) {
                width: 75%;
            }
        }

        &__right {

            @include media-breakpoint-up(lg) {
                position: relative;
                width: 25%;

                &::before {
                    background-color: palette('black');
                    bottom: 0;
                    content: '';
                    display: block;
                    position: absolute;
                    left: rem-calc(-30);
                    top: 0;
                    width: 1px;
                }
            }

            @include media-breakpoint-up(xl) {

                &::before {
                    left: rem-calc(-60);
                }
            }
        }
    }

    &__row {

        @include media-breakpoint-up(lg) {
            display: flex;
        }


        &__item {

            @include media-breakpoint-up(lg) {
                flex: 1;
                padding-right: rem-calc(24);
            }

            @include media-breakpoint-up(xl) {
                padding-right: rem-calc(100);
            }
        }

        &__card {
            width: 10%;
            height: 100%;
            border-left: 1px solid black;
            padding-left: rem-calc(24);
            flex: 1;
        }
    }

    &__subnav {
        list-style: none;
        margin: 0;
        padding-left: 0;
    }
    /*
    * primary nav
    */
    &__menu {
        &--primary {
            display: flex;
            flex-direction: column;

            @include media-breakpoint-up(md) {
                // display: block;
                width: 25%;
            }

            @include media-breakpoint-up(lg) {
                // display: flex;
                flex-direction: row;
                margin-bottom: 0;
                width: auto;
            }
        }

        &--secondary {

            &:focus {
                outline: 0;
            }

            @include media-breakpoint-up(lg) {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                height: rem-calc(380);
                margin-bottom: 0;
                width: 100%;
            }

            @include media-breakpoint-up(xl) {
                width: 75%;
            }

            flex-direction: row;
            align-content: space-between;
            flex-wrap: nowrap;

            .nav__menu--column {
                display: flex;
                flex-direction: column;
                width: 100%;

                @include media-breakpoint-up(md) {
                    width: 33%;
                }

                .nav__item--secondary {
                    width: auto;
                }
            }
        }
    }
    /*
    * secondary nav
    */
    &__inner__secondary {

        align-items: start;

        @include media-breakpoint-up(md) {
            padding-left: rem-calc(30);
            flex: 1;
        }

        @include media-breakpoint-up(lg) {                        
            background-color: palette('dark_grey');
            display: flex;
            flex: unset;
            height: rem-calc($subnav-height-large);
            justify-content: flex-end;
            left: 0;
            opacity: 0;
            padding: 1.5rem;
            position: absolute;
            right: 0;
            transform: translate3d(0, -120%, 0);
            transition: opacity .2s ease, visibility .2s ease .2s, transform .2s ease .2s;
            visibility: hidden;
            width: 100%;

            .is-open & {
                opacity: 1;
                visibility: visible;
                transform: translate3d(0, 0, 0);
                transition: opacity .2s ease;
            }
        }
    }
    /*
    * menu item structure
    */
    &__item {
        border-bottom: 1px solid #fff;

        @include media-breakpoint-up(lg) {
            border-bottom: 0
        }

        &--secondary {

            @include media-breakpoint-up(lg) {
                display: inline-block;
                margin: rem-calc(20 0);
                min-height: rem-calc(60);
                padding-right: rem-calc(24);
                width: calc(100% / 3);
                // width: 25%;
            }
        }


        &--sub,
        &--secondary {
            border-bottom: 0
        }

        &--search {
            order: -1; //move search to top on mobile
            @include media-breakpoint-up(md) {
                order: 0;
            }
        }

        h2 {
            line-height: 0;
            padding-bottom: rem-calc(16);
        }
    }

    &__link {
        border: 0;
        color: #fff;
        cursor: pointer;
        display: inline-block;
        font-size: rem-calc(18);
        font-weight: 600;
        line-height: 2;
        padding: rem-calc(4 0);
        text-decoration: none;

        &:focus,
        &:hover {
            text-decoration: underline;
        }

        @include media-breakpoint-up(lg) {
            color: palette('black');
        }


        &--toplevel {

            &:focus,
            &:hover {
                text-decoration: none;
            }

            @include media-breakpoint-up(lg) {
                backface-visibility: hidden;
                height: rem-calc($nav-height-large);
                line-height: rem-calc($nav-height-large);
                padding: rem-calc(0 14);
                position: relative;
                z-index: 1;
                margin-left: -2px;

                &::before {
                    backface-visibility: hidden;
                    background-color: #fff;
                    bottom: 0;
                    content: '';
                    display: block;
                    left: 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                    z-index: -1;
                    transition: background-color 0.2s, color 0.2s ease;
                    transform: skewX(-10deg);
                }

                &:focus,
                &:hover,
                &.is-active,
                &.is-current-page {
                    outline: none;

                    &::before {
                        background-color: palette('mid_grey');
                    }
                }

                &:focus {
                    &::before {
                        border: $diversity-cyan solid 5px;
                    }
                }

                &.is-current-page--hidden {
                    &::before {
                        background-color: #fff;
                    }
                }
            }

            @include media-breakpoint-up(xl) {
                padding: rem-calc(0 18);
            }

            @include media-breakpoint-up(xxl) {
                padding: rem-calc(0 20);
            }
        }

        &--header {
            display: inline-block;
            font-weight: 700;
            letter-spacing: -0.46px;
            line-height: 0;

            @include media-breakpoint-up(lg) {
                font-size: rem-calc(20);
            }
        }

        &--sub,
        &--secondary {
            font-size: rem-calc(18);
            font-weight: 400;
            line-height: 1.3;
            padding: rem-calc(8 0);

            @include media-breakpoint-up(lg) {
                padding: rem-calc(6 0);
            }
        }

        &--search {
            overflow: visible;

            &:focus,
            &:hover {

                .svg-icon--cross {
                    color: palette('red');
                }
            }

            @include media-breakpoint-up(lg) {

                &::before {
                    right: -12px;
                }

                &.is-active,
                &.is-current-page {

                    .svg-icon--search {
                        display: none;
                    }

                    .svg-icon--cross {
                        display: inline-block;
                    }
                }
            }

            .svg-icon {
                display: none;

                @include media-breakpoint-up(lg) {
                    display: inline-block;
                }
            }

            .svg-icon--cross {

                @include media-breakpoint-up(lg) {
                    display: none;
                }
            }

            .nav__link__label {

                @include media-breakpoint-up(lg) {
                    border: 0;
                    clip: rect(0, 0, 0, 0);
                    height: 1px;
                    margin: -1px !important;
                    overflow: hidden;
                    padding: 0;
                    position: absolute;
                    width: 1px;
                }
            }
        }

        &--secondary {

            @include media-breakpoint-up(lg) {
                border-bottom: 2px solid transparent;
                color: #fff;
                font-size: rem-calc(32);
                font-weight: 600;
                transition: border-color 0.2s ease;

                &:focus,
                &:hover {
                    border-color: #fff;
                    text-decoration: none;
                }
            }
        }
    }

    &__capabilities-link {
        display: inline-block;
        margin-top: rem-calc(16);
        min-width: auto;
        font-size: rem-calc(16);
        font-weight: 400;

        &:focus,
        &:hover {
            text-decoration: underline;
        }
    }
}
