.experience-results-listing {
	.silent-wrapper {
		@include content-wrapper;
	}
	position: relative;
	
	.result {
		padding-bottom: $section-spacing;
		border-bottom: $border-list-heavy;
		+ .result {
			padding-top: $section-spacing;
		}
		@include font-copy-lora(20px);
		color: $color-brand-black;
		line-height: 1.4;
	}
}
