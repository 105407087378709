// tips taken from css tricks
// http://codepen.io/yoksel/pen/fsdbu

.svg-mask {
	// need to figure out how to prevent the gulp task from interferring with the url definition and prevent the addition of file path
	// image {
	// 	clip-path: url(#clipping);
	// }
	position: absolute;
	top: 0;
	left: 0;
}

.svg-mask-container {
	position: relative;
	height: 0;
	padding-bottom: 112.8%;
	width: 100%;
	display: block;
}

// .svg-mask-image {
// 	@include transition(opacity);

// 	opacity: 1;
// 	height: 100%;
// 	min-height: 100%;
// 	//width: 100% !important;
// }


.svg-clipping-path {
	position: absolute;
	width: 0;
	height: 0;
	z-index: -1
}
