$event-title-size: 20px;
.event-card {
	font-size: px-to-em($event-title-size);
	background-color: $color-gray-dark;
	color: $color-brand-white;
	padding: $section-spacing-small $element-spacing;
	margin-bottom: px-to-em($section-spacing-px,$event-title-size );
}


.event-card-item {
	@include clearfix;
	
	margin-bottom: $element-spacing-small;

}

.event-card-label {
	float: left;
	margin-right: 0.5em;
	display: inline-block;
	width: px-to-em(180px, $event-title-size);
}

.event-card-detail {
	font-style: normal;
	display: table;
}

a.event-card-detail {
	@include link-transition($color-brand-gold, $color-brand-white);
	
	font-weight: $font-weight-bold;
	display: table;
}

@include screen($bp-tablet) {
	$event-title-size: 16px;
	.event-card {
		font-size: $event-title-size;
		padding: $section-spacing-small;
		margin-bottom: px-to-em($section-spacing-small-px,$event-title-size );
	}
}


@include screen($bp-mobile-portrait) {
	.event-card-label {
		display: block;
		float: none;
		margin-right: 0;
		width: auto;
	}

	.event-card-detail {
		display: block;
	}
}