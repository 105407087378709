﻿
.alpha-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: rem-calc(24 0);
    padding-left: 0;


    &__item {
        padding-right: rem-calc(4);
    }

    &__link {
        background-color: transparent;
        display: block;
        font-size: rem-calc(24);
        padding: rem-calc(4 6);
        transition: color 0.35s, background-color 0.35s ease;

        &:focus,
        &:hover {
            background-color: palette('dark_grey');
            color: #fff;
        }
    }
}
.people-searchform {
    margin: 0;

    padding-bottom: rem-calc(20);

    &__header {
        // font-size: rem-calc(26);
        // font-weight: 700;
        // letter-spacing: -0.46px;
        // line-height: 2;
        // padding: rem-calc(4 0);
    }

    &__fieldset {
    }

    &__fieldgroup {
        display: flex;
        max-width: 90%;
    }

    &__field {
        flex: 1;

        &--button {
            flex: 0 0 auto;
            margin-left: rem-calc(24);

            .btn {
                height: 100%;
                width: rem-calc(64);
            }
        }
    }

    &__label {
        font-size: rem-calc(24);
    }

    &__input[type=text] {
        border: 0;
        font-size: rem-calc(18);
        padding: rem-calc(20 0 20 20);
        width: 100%;
    }

    &__button {
        background-color: palette('black');
        border: 0;
        color: #fff;
    }

    &__link {
        display: inline-block;
        margin-top: rem-calc(16);
        min-width: auto;
        font-size: rem-calc(16);
        font-weight: 400;

        &:focus,
        &:hover {
            text-decoration: underline;
        }
    }
}
