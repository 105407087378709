.page-careers-users,
.page-careers-offices,
.page-careers-profile,
.page-careers-gc {
  .silent-wrapper {
    @include content-wrapper;

    &--no-padding {
      padding-left: 0;
      padding-right: 0;
    }
  } 
}