﻿.hero-carousel {
    overflow: hidden;
    position: relative;

    &::before,
    &::after {
        bottom: 0;
        content: '';
        display: block;
        height: 100%;
        left: 0%;
        opacity: 0.3;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
        transform: rotate(-10deg);
        transform-origin: top right;
        width: 100%;
        z-index: 1;
    }

    &::before {
        background-image: linear-gradient(70deg, rgba(0, 0, 0, 0.00) 50%, rgba(0, 0, 0, 0.75) 100%);
        top: 75%;
    }

    &::after {
        background-image: linear-gradient(70deg, rgba(0, 0, 0, 0.00) 26%, rgba(0, 0, 0, 0.75) 100%);
        top: calc(75% + 40px);
    }


    &__cell {
        width: 100%;
    }

    &__button {
        // background-color: palette('black');
        background-color: transparent;
        border: 0;
        bottom: rem-calc(24);
        color: #fff;
        display: inline-block;
        padding: rem-calc(8);
        position: absolute;
        right: rem-calc(16);
        text-transform: uppercase;
        z-index: 3;

        @include media-breakpoint-up(lg) {
            bottom: rem-calc(37);
            padding: rem-calc(12 8);
            font-size: 22px;
        }

        @include media-breakpoint-up(xl) {
            right: rem-calc(58);
        }

        .svg-icon--play {
            display: none;
        }

        &.is-paused {

            .svg-icon--play {
                display: block;
            }

            .svg-icon--pause {
                display: none;
            }
        }


        &--hidden {
            display: none;
        }
    }
}

.hero-banner {
    background-color: palette('black');
    height: rem-calc(400);
    padding-top: rem-calc($nav-height-small);
    padding-left: rem-calc(20);
    padding-right: rem-calc(20);
    position: relative;

    @include media-breakpoint-up(sm) {
        padding-left: rem-calc(30);
        padding-right: rem-calc(30);
    }

    @include media-breakpoint-up(lg) {
        height: rem-calc(640);
        padding-top: rem-calc($nav-height-large);
    }

    @include media-breakpoint-up(xl) {
        padding-left: rem-calc(100);
        padding-right: rem-calc(100);
    }


    &__media {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
    

        &__image {
            display: block;
            height: 100%;
            width: 100%;

            @supports (object-fit: cover) {
                object-fit: cover;
                object-position: center;
            }
        }
    }
    &__content {
        color: #fff;
        margin-top: rem-calc(30);
        max-width: rem-calc(320);
        overflow: hidden;
        padding: rem-calc(15 15 40 15);
        position: relative;
        z-index: 1;

        &::after {
            background-color: palette('red');
            content: '';
            display: block;
            height: 100%;
            left: 0;
            opacity: 0.8;
            position: absolute;
            transform: skewY(-10deg);
            transform-origin: top left;
            transition: opacity 0.3s ease;
            top: 0;
            width: 100%;
            z-index: -1;
        }

        &:hover::after {
            opacity: 0.9;
        }

        @include media-breakpoint-up(sm) {
            max-width: rem-calc(375);
        }

        @include media-breakpoint-up(md) {
            max-width: calc(100% / 3);
            min-height: rem-calc(250);
            padding: rem-calc(30 30 40);
        }

        @include media-breakpoint-up(lg) {
            margin-top: rem-calc(90);
            min-height: rem-calc(350);
        }
    

        &__title {
            font-size: rem-calc(24);
            font-weight: 300;
            line-height: 1.2;
            margin-bottom: rem-calc(24);

            @include media-breakpoint-up(lg) {
                font-size: rem-calc(36);
                margin-bottom: rem-calc(36);
            }
        }
    }
}

.flickity-page-dots {

    .hero-carousel & {
        bottom: rem-calc(32);
        padding-right: rem-calc(56);
        text-align: right;
        z-index: 2;

        @include media-breakpoint-up(lg) {
            bottom: rem-calc(48);
        }

        @include media-breakpoint-up(xl) {
            padding: rem-calc(0 100);
        }

        .dot {
            border-color: #fff;
            box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.50);

            &:focus {
                outline: 5px solid $diversity-cyan;
            }

            &.is-selected {
                background: palette('red');
                border-color: palette('red');
            }
        }
    }
}
