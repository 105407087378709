﻿.focus-list {
    list-style: none;
    margin-top: rem-calc(50);
    padding: rem-calc(0 30);

    @include media-breakpoint-up(sm) {
        display: flex;
        flex-wrap: wrap;
    }

    @include media-breakpoint-up(lg) {
        margin-top: rem-calc(48);
    }

    @include media-breakpoint-up(xl) {
        padding: rem-calc(0 100);
    }


    &__item {
        margin-bottom: rem-calc(24);


        &:last-child {
            margin-bottom: 0;
        }

        @include media-breakpoint-up(sm) {
            padding-left: rem-calc(20);
            padding-right: rem-calc(20);
            width: 50%;
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: rem-calc(48);
            width: calc(100% / 3);
        }

        @include media-breakpoint-up(xl) {
            // padding-left: rem-calc(30);
            // padding-right: rem-calc(30);
        }
    }

    &__link {
        color: #fff;
        display: block;
        font-size: rem-calc(18);
        font-weight: 600;
        letter-spacing: -0.2px;
        text-align: center;
        padding: rem-calc(12);
        position: relative;
        transition: color 0.3s ease;
        z-index: 1;
        outline: none;

        &::before {
            background-color: transparent;
            border: 2px solid #d8d8d8;
            bottom: 0;
            content: '';
            display: block;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            transform: skewX(-30deg);
            transition: background-color 0.3s, border-color 0.3s ease;
            z-index: -1;
        }

        &:focus,
        &:hover {
            color: palette('dark_grey');

            &::before {
                background-color: #fff;
                border-color: #fff;
            }
        }

        &:focus {
            outline: none;
            
            &::before {
                border: $diversity-cyan solid 5px;
            }
        }

        @include media-breakpoint-up(lg) {
            font-size: rem-calc(26);
            padding: rem-calc(22 6);
        }
    }
}