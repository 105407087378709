﻿$screen-xs-min: 420px;
$screen-sm-min: 760px;
$screen-md-min: 1000px;
$screen-lg-min: 1300px;
$screen-xl-min: 1440px;
$screen-xxl-min: 1600px;
// breakpoints based on current RS site
$breakpoints: ( 'xs': ( min-width: #{$screen-xs-min}), 'sm': ( min-width: #{$screen-sm-min}), 'md': ( min-width: #{$screen-md-min}), 'lg': ( min-width: #{$screen-lg-min}), 'xl': ( min-width: #{$screen-xl-min}), 'xxl': ( min-width: #{$screen-xxl-min}));
$brand-palette: ( 'black' : #000, 'red': #cc092f, 'light_grey': #f3f3f3, 'mid_grey': #e2e2e2, 'grey': #4a4a4a, 'dark_grey': #3d3c3c, 'purple': #8d338a, 'orange': #e76d27);
$max-site-width: 1605; //based on current RS site
$nav-easing: cubic-bezier(.785, .135, .15, .86);
$nav-height-small: 68;
$nav-height-large: 110;
$subnav-height-large: 550;
$box-shadow-default: 1px 1px 3px 0 rgba(0, 0, 0, 0.7);
