$site-nav-list-spacing: px-to-em(25px);

.site-nav {
	@include transition(transform);

	position: absolute;
	top: $site-header-triangle-offset;
	left: $site-header-triangle-offset;
	right: $site-padding;
	background-color: $color-black;
	z-index: 5;
	color: $color-brand-gold;
	padding: px-to-em($site-header-height + $section-spacing-px) 6% 10%;
	transform: translateY(-110%);
	visibility: hidden;
	transition: transform $transition-timing $transition-ease, 
				visibility $transition-timing $transition-ease $transition-timing;

}

//headroom specific classes
.setup-fixed-header {
	.site-nav {
		top: 0;
		left: 0;
		right: 0;
	}
	
	.site-nav-wrapper {
		@include page-wrapper;
		padding-left: 0;
		padding-right: 0;
	}
}


.show-site-nav {
	.site-nav {
		visibility: visible;
		transform: translateY(0);

		@include transition(transform);
	}

	.site-nav-wrapper {
		opacity: 1;
	}
}

//structure
.site-nav-wrapper {
	@extend %uc;

	position: relative;
	opacity: 0;

	@include transition(opacity);

	transition-delay: $transition-timing;

	display: flex;
	flex-wrap: nowrap;
}

.site-nav-menu {
	width: 45%;
	min-width: px-to-em(420px);
}

.site-nav-additional {
	
	text-align: right;
	flex-grow: 1;
}

.site-nav-utility {
	margin-top: $section-spacing-small;
}

//list items
.site-nav-menu-item,
.site-nav-submenu-item {
	& + & {
		margin-top: $site-nav-list-spacing;
	}
}

.site-nav-tertiary-item {
	& + & {
		margin-top: 1em;
	}
}

.site-nav-utility-item {
	& + & {
		margin-top: calc($element-spacing-small/2);
	}
}

//typography
.site-nav-menu-link {
	$title-size: 48px;

	@include font-open-sans-extra-bold($title-size);
	@include link-transition($color-brand-gold, $color-brand-white);

	&:hover {
		color: $color-brand-white;
	}
}

.site-nav-submenu-link {
	$title-size: 48px;

	@include font-open-sans-light($title-size);
	@include link-transition($color-brand-gold, $color-brand-white);


	&:hover {
		color: $color-brand-white;
	}

	&.is-external-link {
		$icon-size: 22px;
		padding-right: px-to-em($icon-size * 1.5, $title-size);

		&:after {
			position: absolute;
			font-size: px-to-em($icon-size, $title-size);
			margin-left: 0.6em;
			margin-top: 0.9em;
		}
	}
}


.site-nav-tertiary-link {
	@include link-transition($color-brand-gold, $color-brand-white);

	$title-size: 26px;
	font-size: px-to-em($title-size);
	display: inline;

	&:hover {
		color: $color-brand-white;
	}

	&.is-external-link {
		$icon-size: 16px;
		padding-right: px-to-em($icon-size * 1.5, $title-size);

		&:after {
			margin-top: 0.75em;
		}
	}

	&.site-nav-tertiary-link--home-link {
		padding-right: 1em;

		@include ico-icon-after($icon-home) {
			font-size: px-to-em(16px, $title-size);
			position: absolute;
			margin-top: 0.65em;
			right: 0;
		}
	}
}

.site-nav-utility-link {
	$title-size: 20px;

	@include link-transition($color-text-white-hover, $color-brand-white);

	font-size: px-to-em($title-size);
	color: $color-text-white-hover;

	

	&:hover {
		color: $color-brand-white;
	}
}

html[lang="de"] {

	.site-nav-menu {
		width: 35%;
	}

	.site-nav-submenu-link {
		word-wrap: break-word;
    	word-break: break-word;
    }
}

@include screen($bp-tablet-landscape) {
	//typography
	.site-nav-menu-link {
		$title-size: 36px;

		font-size: px-to-em($title-size);

	}

	.site-nav-submenu-link {
		$title-size: 36px;

		font-size: px-to-em($title-size);
	}

	.site-nav-tertiary-link {
		$title-size: 24px;

		font-size: px-to-em($title-size);
	}

	.site-nav-utility-link {
		$title-size: 18px;

		font-size: px-to-em($title-size);	
	}

	html[lang="de"] {

		.site-nav-menu {
			width: auto;
			padding-right: 1em;
			min-width: 35%;
		}

		//typography
		.site-nav-menu-link {
			$title-size: 24px;

			font-size: px-to-em($title-size);
		}

		.site-nav-submenu-link {
			$title-size: 24px;

			font-size: px-to-em($title-size);
		}

		.site-nav-tertiary-link {
			$title-size: 18px;

			font-size: px-to-em($title-size);
		}

		.site-nav-utility-link {
			font-size: 1em;
		}
	}
}

@include screen($bp-between-desktop) {
	.site-nav {
		padding-left: $site-padding;
		padding-right: $site-padding;
	}

	.site-nav-menu {
		width: 50%;
		min-width: auto;
	}

	.setup-fixed-header {
		.site-nav-wrapper {
			padding-right: 0;
		}
	}
}

@include screen($bp-hero-intro-tablet) {
	.site-nav {
		top: $site-header-triangle-offset-tablet;
		left: $site-header-triangle-offset-tablet;
	}
}


@include screen($bp-tablet) {
	.site-nav {
		left: 0;
		right: $site-padding-mobile;
		padding: px-to-em($site-header-height-mobile + $section-spacing-small-px)  $site-padding ;
	}

	.site-nav-menu {
		width: 40%;
		min-width: auto;
	}

	//typography
	.site-nav-menu-link {
		$title-size: 24px;

		font-size: px-to-em($title-size);

	}

	.site-nav-submenu-link {
		$title-size: 24px;

		font-size: px-to-em($title-size);
	}

	.site-nav-tertiary-link {
		$title-size: 18px;

		font-size: px-to-em($title-size);

		&.is-external-link {

			&:after {
				font-size: 0.7rem;	
				margin-top:0.5em;
			}
		}
	}

	.site-nav-utility-link {
		font-size: 1em;
	}

	html[lang="de"] {

		.site-nav-menu {
			min-width: 30%;
		}
	}

}

@include screen($bp-mobile) {
	.site-nav {
		top: 0;
		right: 0;
		left: 0;
	}

	.site-nav-wrapper {
		.headroom--not-top &{
			padding-left: 0;
		}
	}
}

@include screen($bp-mobile-landscape) {
	.site-nav {
		padding: px-to-em($site-header-height-mobile ) $section-spacing-small ;
	}

	.site-nav-wrapper {
		display: block;
	}
	
	//list items
	.site-nav-menu-item,
	.site-nav-submenu-item {
		& + & {
			margin-top: $element-spacing-small;
		}
	}

	.site-nav-tertiary-item {
		& + & {
			margin-top: $element-spacing-small;
		}
	}

	.site-nav-utility-item {
		& + & {
			margin-top: calc($element-spacing-small/2);
		}
	}

	.site-nav-tertiary-menu,
	.site-nav-submenu {
		margin-top:  $element-spacing;
	}


	.site-nav-additional {
		text-align: left;
	}

	.site-nav-utility {
		position: static;
		bottom: auto;
		right: auto;
		margin-top:  $element-spacing;
	}
}