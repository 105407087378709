$snapshot-width: px-to-em(464px);

.capability-header {
	line-height: 1.6;

	$detail-header-width: 800px;
	.capability-header-info {
		margin: 0 auto;
		max-width: $detail-header-width;
		text-align: center;
		h1{
			line-height: 1.4;
			padding-bottom: px-to-em(30px);
		}
		@include screen($bp-mobile){
			h1{
				padding-bottom: px-to-em(20px);
			}
		};
	}

	.capability-header-main {
		margin-bottom: $section-spacing-small;
	}

	.capability-header-name {
		@include font-open-sans-extra-bold($page-header-font);
		line-height: 1.1;
	}

	@include screen($bp-mobile){
		.capability-header-name{
			@include font-open-sans-extra-bold($page-header-font-mobile);
		}
	};
}
