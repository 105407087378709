.searched-criteria {
	margin-bottom: $result-item-spacing;

	.applied-filters {
		padding-right: 1em;
	}

	.silent-wrapper {
		@include content-wrapper;

		display: flex;
		flex-wrap: nowrap;
		align-items: baseline;
	}
}

.searched-criteria-results {
	$title-size: 20px;

	flex-shrink:0;
	text-align: center;
	padding-right: 1em;
	font-size: px-to-em($title-size);
	font-weight: $font-weight-regular;
}

.searched-criteria-clear {
	@include  font-open-sans-bold();
	@include link-transition();

	flex-shrink:0;

	color: $color-brand-red;
}


@include screen($bp-tablet) {
	.searched-criteria-results {
		$title-size: 18px;

		font-size: px-to-em($title-size);
	}	
}

@include screen($bp-mobile-landscape) {
	.searched-criteria {
		.silent-wrapper {
			display: block;
		}
		.applied-filters {
			margin-bottom: $element-spacing-small;	
		}
	}
	.searched-criteria-results {
		display: inline-block;
		margin-bottom: $element-spacing-small;
	}
}