.industry-list{
    $transition-delay: 250ms;
    $font-size: 24px;
    margin-bottom: $section-spacing-large;
    .industry-item{
        margin-bottom: px-to-em(20px);
        &.is-expanded{
            .toggle{
                
                &:after{
                    content: "-";
                    line-height: 0.5;
                    
                }
                
            }
            .industry-item--children{
                max-height: px-to-em(4000px);
                opacity: 1;
                padding: px-to-em(20px, $font-size) px-to-em(30px, $font-size) px-to-em(20px, $font-size);
                transition: max-height $transition-timing*2 $transition-ease,
                            transform $transition-timing linear $transition-delay,
                            padding-bottom $transition-timing linear $transition-delay;
            }
        }
        &:last-child{
            margin-bottom: 0;
            .industry-item--children{
                padding-bottom: 0;
            }
            .industry-list ul li{
                margin-bottom: 0;
            }
        }
    }
    $button-width:80px;
    $skew-degree: 8deg;

    .industry-item--main{
        position: relative;
    }

    .industry-item-header {
        display: table;
        width: 100%;
        height: px-to-em($button-width);

        .industry-item-skew {
            background-color: $color-brand-black;
            border: 1px solid $color-brand-black;
        }

        &:hover {
            .industry-item-header-title {
                color: $color-brand-black;
            }
            .industry-item-skew {
                &:after {
                    right: 0;
                }
            }
        }
    }

    .industry-item-header-title {
        @include font-open-sans-light($font-size);
        @include transition(color);

        padding: px-to-em(30px, $font-size) px-to-em(34px, $font-size);
        padding-right: px-to-em($button-width + 20px, $font-size);
        display: table-cell;
        vertical-align: middle;
        color: $color-brand-white;
        z-index: 5;
    }

    .industry-item-skew {
        @include absolute-full;

        display: block;
        transform: skewX($skew-degree * -1);
        
        z-index: -1;
        backface-visibility: hidden;

        &:after {
            @include absolute-full;
            @include transition(right);

            content: '';
            display: block;
            background-color: $color-brand-white;
            right: 100%;
        }
    }
    .toggle{
        @include transition(color);

        position: absolute;
        right: -1px;
        top: -1px;
        bottom: -1px;
        width: $button-width;
        color: $color-brand-white;
        z-index: 2;
        overflow: visible;
        
        &:after {
            content: "+";
            @include font-open-sans-extra-bold(72px);
            font-size: 72px; //RS-289 - display issue
            line-height: 1;
        }

        .industry-item-skew {
            background-color: $color-brand-red;
            border: 1px solid $color-brand-red;
        }

        &:hover {
            color: $color-brand-red;

            .industry-item-skew {
                &:after {
                    right: 0;
                }
            }
        }
    }
    .industry-item--children{
        padding: 0 px-to-em(30px, $font-size);
        position: relative;
        left: -6px;
        max-height: 0;
        overflow: hidden;
        transition: max-height $transition-timing $transition-ease $transition-delay,
                    transform $transition-timing linear,
                    padding-bottom $transition-timing linear $transition-delay,
                    padding-top $transition-timing linear $transition-delay;
        a{
            color: $color-link;
            &:hover{
                color:$color-link-hover;
            }
        }

    }
    .industry-item--child{
        @include font-open-sans-light(20px);
    }
    .industry-item--main{

    }

    ul {
        padding-left: 18px;
        margin-top: 10px;

        li {
            padding-bottom: 10px;
            margin-bottom: 20px;
            border-bottom: solid 1px $color-border;
        }

        ul {
            margin-top: 20px;
            margin-bottom: 30px;

            .industry-item--child {
                font-size: 18px;
            }

            li {
                margin-bottom: 10px;
            }
        }
    }

    &.industry-list--reverse {

        .industry-item-header {

            &:hover {

                .industry-item-skew {

                    &:after {
                        left: 0;
                        right: 0;
                    }
                }
            }
        }

        .industry-item-header-title {
            padding-right: px-to-em(34px, $font-size);
            padding-left: px-to-em($button-width + 20px, $font-size);
        }

        .industry-item-skew {
            @include absolute-full;

            display: block;
            transform: skewX($skew-degree * -1);
            
            z-index: -1;

            &:after {
                @include transition(left);

                left: 100%;
                right:0;
            }
        }

        .toggle {
            right: auto;
            left: -1px;

            &:hover {

                .industry-item-skew {
                    &:after {
                        right: 0;
                        left: 0;
                    }
                }
            }
        }


    }

    @include screen($bp-tablet) {
        $button-width: 60px;
        $font-size: 20px;



        .industry-item-header {
            height: px-to-em($button-width);
        }

        .industry-item-header-title {
            @include font-open-sans-light($font-size);

            padding: px-to-em(14px, $font-size) px-to-em(24px, $font-size);
            padding-right: px-to-em($button-width + 15px, $font-size);
        }

        .toggle {
            width: px-to-em($button-width);

            &:after {
                font-size: 45px;
            }
        }

        &.industry-list--reverse {

            .industry-item-header-title {
                
                padding-right: px-to-em(24px, $font-size);
                padding-left: px-to-em($button-width + 15px, $font-size);
            }
        }
    }

    

}
