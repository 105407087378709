.listing-links-group {
  margin-top: $element-spacing;

  &__child:not(:last-child) {
    padding-bottom: $element-spacing;
  }

  &__link  {
    font-size: px-to-em(20px);
    color: $color-brand-red;
    
    &:hover {
      color: $color-brand-black;
    }
  }
}