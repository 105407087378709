.listing-experience {
	padding-bottom: px-to-em($element-spacing-px, $font-size-copy);
}

.listing-experience-item {
	background-color: $color-gray;
	padding: px-to-em($section-spacing-mobile-px, $font-size-copy);
	

	& + &  {
		margin-top: px-to-em($element-spacing-small-px, $font-size-copy);
	}
}