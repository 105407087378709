$applied-filters-spacing: 0.4em;
$applied-filters-remove-width: 26px;

.applied-filters {

}

.applied-filters-item {
	display: inline-block;
	margin-right: $applied-filters-spacing;
	padding-left: px-to-em($applied-filters-remove-width);
	margin-right: 0.5em;
	position: relative;
	font-weight: $font-weight-bold;
	cursor: pointer;
}

.applied-filters-remove {
	span{
		font-weight: $font-weight-bold;
		
	}
	&:hover { 
		color: $color-link-hover;
	}

	@include ico-icon-after($icon-close) {
		font-size: px-to-em(20px);
		color: $color-brand-red;
		position: absolute;
		left: 0;
		top: .1em;
		line-height: 1.1;
	}
}