
.attachments-list-option {
	& + & {
		margin-top: $section-spacing-small;
	}
}


.attachments-list-link {
	$title-size: 20px;

	@include link-transition;

	font-size: px-to-em($title-size);
	font-weight: $font-weight-bold;

}

@include screen($bp-tablet) {

	
	.attachments-list-link {
		$title-size: 18px;

		font-size: px-to-em($title-size);
	}
}
