﻿.careers-result {
    border-bottom: $border-list-heavy;

    &--header {
        border: none;
    }

    &--table {
        width: 100%;
        table-layout: fixed;
        margin-top: 3em;
    }

    &--left {
        text-align: left;
        padding-bottom: 1em;
        padding-top: 1em;
    }

    &--center {
        text-align: center;
        padding-bottom: 1em;
        padding-top: 1em;
    }

    &--right {
        text-align: right;
        padding-bottom: 1em;
        padding-top: 1em;
    }
}
