$ap-max-width: 1970px;
//https://www.triangle-calculator.com/?what=&q=%CE%B1%3D45+b%3D252+c%3D1425+a%3D1447&submit=Solve
.angled-polygon {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index:$zindex-angled-hero ;
	overflow: hidden;
	height: px-to-em($angled-polygon-height);
}


.angled-polygon-svg-wrapper {
	@include center(horizontal);

	width: px-to-em($ap-max-width);
	height: 100%;
	margin-top: -0.25em;	
}

#angled-polygon-svg { 
	position: absolute;
	left: 0;
	bottom: 0;
}

@include screen($angled-hero-desktop) {

	.angled-polygon-svg-wrapper {

		.full-width & {
			width: calc(100% + 2em);

		}
	}
}

@media screen  and (-ms-high-contrast: active), (max-width: 480px) {

	.angled-polygon-svg-wrapper {
		margin-top: -10%;
	}
}

