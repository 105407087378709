﻿.diversity-global-carousel {
  height: px-to-em(338px);
  width: px-to-em(338px);
  right: 6%;
  position: relative;
  z-index: 1;
  margin-bottom: 4.6em;

  &__slide {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-position-x: 80%;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
  }

  &__title {
    @include font-open-sans-bold($font-size-h4-mobile);
    @include transition(font-size);
    color: $color-brand-white;
    line-height: $font-line-height-2;
    text-align: center;
  }

  &__mobile-btn-container {
    display: flex;
    flex-direction: row;
    position: relative;
    bottom: -22.25em;
  }

  &__button-ghost {
    @extend %uc;

    display: inline-block;
    font-weight: $font-weight-bold;
    font-size: $font-size-h6-mobile;
    width: 100%;
    padding: 0.75em 1.5em;
    margin-top: px-to-em(30px);
    border: 1px solid rgba(255,255,255,0.3);
    border-radius: 0;
    overflow: hidden;
    position: relative;
    text-align: center;

    @include button-transition-fuschia();
  }

  &__button-ghost-orange {
    @extend %uc;

    display: inline-block;
    font-weight: $font-weight-bold;
    font-size: $font-size-h6-mobile;
    width: 100%;
    padding: 0.75em 1.5em;
    margin-top: px-to-em(30px);
    border: 1px solid rgba(255,255,255,0.3);
    border-radius: 0;
    overflow: hidden;
    position: relative;
    text-align: center;

    @include button-transition-orange();
  }

  &__trigger-btn {
    background-color: $diversity-light-gray;
    color: $color-brand-white;
    width: 30%;
    height: px-to-em(70px);
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    -webkit-border-radius: 0px;
    border-right: 1px solid $color-brand-white;
    transition: all ease-in-out .15s;

    &:last-child {
      border-right: 0;
    }
  }

  &__lg-trigger-btn {
    background-color: $diversity-light-gray;
    color: $color-brand-white;
    width: 33%;
    height: px-to-em(90px);
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    -webkit-border-radius: 0px;
    border-right: 1px solid $color-brand-white;
    transition: all ease-in-out .15s;
    display: none;

    &:last-child {
      border-right: 0;
    }

    &:hover {
      background-color: $color-border;
    }
  }

  @include screen($bp-mobile, "min") {
    margin: 0 auto;
    margin-bottom: 4.6em;
    right: 0;
  }

  @include screen($bp-between-tablet, "min") {
    height: 25em;
    width: 25em;
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &__mobile-btn-container {
      display: none;
    }

    &__button-ghost {
      width: px-to-em(205px);
    }

    &__lg-trigger-btn {
      display: block;
      position: relative;

      &--back {
        left: -9em;
      }

      &--forward {
        left: 14.2em;
      }
    }
  }

  @include screen($bp-desktop, "min") {
    height: 30em;
    width: 30em;

    &__title {
      width: 100%;
    }
  }
}
