.recognized-news-award {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;


	& + & {
		margin-top: $section-spacing-large;
	}


	&:nth-child(2n) {
		flex-direction: row-reverse;
	}
}

.recognized-news-award-thumbnail {
	width: px-to-em($svg-thumbnail-mask-width);
	flex-shrink: 0;

}

.recognized-news-award-info {
	flex-grow: 1;
	padding:0 $section-spacing;
}

.recognized-news-award-header {
	$heading-size: 36px;

	@include font-open-sans-light($heading-size);

	line-height: 1.2;

	color: $color-link;
	a{
		@include link-transition();
		display: inline;
	}
	// &:hover {
	// 	color: $color-link-hover;
	// }
}

.recognized-news-award-abstract {
	margin-top: px-to-em($element-spacing-small-px, $font-size-copy);
}


@include screen($bp-between-desktop) {
	.recognized-news-award-thumbnail {
		width: 35%;
	}
}

@include screen($bp-tablet) {
	.recognized-news-award {

		& + &  {
			margin-top: $section-spacing-mobile;
		}

	}
	.recognized-news-award-info {
			padding: 0 $section-spacing-small;
	}

	.recognized-news-award-thumbnail {
		width: 32%;
	}

	.recognized-news-award-header {
		$heading-size: 24px;

		font-size: $heading-size;
	}
}
@include screen($bp-mobile-landscape) {
	.recognized-news-award {
		display: block;
	}
	.recognized-news-award-info {
		padding: 0;
	}

	.recognized-news-award-thumbnail {
		width: 50%;
		margin: 0 auto $section-spacing-small;

	}
}
@include screen($bp-mobile-landscape) {

	.recognized-news-award-thumbnail {
		width: 70%;
	}
}
