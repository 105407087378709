.social-block {
	padding-bottom: $section-spacing-module;

	.inner-content {
		color: $color-brand-white;
	}

	.rte {
		a {
			@include link-transition($color-brand-white, $color-brand-white);
			font-weight: $font-weight-bold;

			&:before {
				width: 100%;
			}

		}
	}
}


@include screen($bp-tablet) {
	.social-block {
		padding-bottom: $section-spacing-module-mobile;	
	}
}