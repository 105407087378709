$bars-width-menu: px-to-em(26px);

.menu-toggle {
	color: $color-brand-gold;
	vertical-align: middle;
	position: relative;
	min-width: $bars-width-menu;
	min-height: $bars-width-menu;
}

.menu-toggle-label {
	@extend %uc;

	font-weight: $font-weight-extra-bold;
	font-size: px-to-em(20px);
	margin-left: 0.25em + $bars-width-menu;
	vertical-align: bottom;
}

.menu-toggle-bars {
	$bars-height: 2px;
	$bars-spacing:5px;

	@include transition(color);
	@include center(vertical);

	left: 0;
	display: inline-block;
	width: $bars-width-menu;
	height: $bars-height;
	background-color: $color-brand-gold;
	transform: translate3d(0, -50%, 0);


	&:before,
	&:after {
		@include transition(background-color);

		content: '';
		display: block;
		background-color:$color-brand-gold;
		height: 100%;
		width: 100%;

		position: absolute;
		transition: transform $transition-timing $transition-ease,
					background-color $transition-timing $transition-ease ;
	}
	
	&:before {
		top: $bars-spacing * -1  - $bars-height;
		transform: rotate(0deg) ;

	}

	&:after {
		bottom: $bars-spacing * -1 - $bars-height;
		transform: rotate(0deg) ;
	}
}


//menu shown
.show-site-nav {
	.menu-toggle {
		color: $color-brand-white;
	}

	.menu-toggle-bars {
		background-color: transparent;

		&:before,
		&:after {
			background-color:$color-brand-white;
		}


		&:before{
			top: 0;
			transform: rotate(135deg) ;
			
		}

		&:after {
			bottom: 0;
			transform: rotate(-135deg);
		}
	}
}

@include screen($bp-tablet) {
	.menu-toggle-label {
		font-size: 1em;

	}

	.menu-toggle-bars {
		margin-bottom: -0.1em;
	}
}

@include screen($bp-tablet) {
	.menu-toggle-label {
		display: none;
	}	
}