﻿.region-carousel {
    padding-bottom: rem-calc(50);

    &::after {
        content: 'flickity';
        display: none;

        @include media-breakpoint-up(lg) {
            content: '';
        }
    }

    @include media-breakpoint-up(lg) {
        display: flex;
        margin: rem-calc(0 15);
    }

    @include media-breakpoint-up(xl) {
        margin: rem-calc(0 85);
    }

    .flickity-page-dots {
        bottom: rem-calc(0);
    }


    &__cell {
        width: 100%;

        @include media-breakpoint-up(sm) {
            width: 50%;
        }

        @include media-breakpoint-up(lg) {
            width: 25%;
        }
    }
}

.flickity-prev-next-button {

    .region-carousel & {
        background-color: rgba(palette('dark_grey'), 0.8);
        border-radius: 0;
        bottom: 0;
        height: 30px;
        top: auto;
        transform: none;
        width: 30px;
        z-index: 1;

        .arrow {
            fill: #fff;
        }

        &.previous {
            left: 20px;
        }

        &.next {
            left: 80px;
            right: auto;
        }

        &:focus,
        &:hover {
            background-color: rgba(palette('dark_grey'), 1);
        }
    }
}
