$highlevel-view-angled-padding: 25%;

.highlevel-view {
	@include page-wrapper;

	margin-bottom: $section-spacing-large;
	padding-bottom: $highlevel-view-angled-padding;
	position: relative;
}

.highlevel-view-wrapper {
	@include content-wrapper;

	padding-top: $section-spacing;
	text-align: center;
}

.highlevel-view-title {
	$heading-size: 36px;

	@include font-open-sans-light($heading-size);

	color: $color-link;

	&:hover {
		color: $color-link-hover;
	}
}

.highlevel-view-abstract {
	margin-top: px-to-em($element-spacing-px, $font-size-copy);
}

.highlevel-view-download {
	margin: $section-spacing auto;
}

.highlevel-view-video {
	margin: $section-spacing auto;
	.btn-ghost-label {
        @include ico-icon-after($icon-video-circle) {
            margin-left: 0.5em;
            margin-top: 0.1em;
        }
    }
}

.highlevel-view-parallax {
	position: absolute;
	width: 100%;
	height: 100vh;
	left: 0;
}

.highlevel-view-parallax-frame {
	@include center(horizontal);

	
	width: 100%;
	z-index: -1;
	transform: translate(-50%, -110%);
	overflow: hidden;

}

.highlevel-view-parallax-img {
	@include center(horizontal, relative);

	max-width: 105%;
}


@include screen($bp-tablet) {
	.highlevel-view-title {
		$heading-size: 24px;

		font-size: $heading-size;
	}

	.highlevel-view-wrapper {
		padding-top:0;
	}
}

@include screen($bp-mobile) {
	.highlevel-view {
		padding-bottom: 20%;
	}

	.highlevel-view-bg {
		transform: translate(-50%, -107.5%);
	}
}
