.fadable {

  opacity: 0;
  transform: translateY(ft(1));
  transition: transform .6s ease-in-out, opacity .6s ease-in-out;

  &--ready {
    opacity: 1;
    transform: none;
  }

}