.page-forecast-landing {
    .btn-ghost {
        &-label {
            font-size: px-to-em(24px);
            text-transform: none;
        }
    }   
    
    .forecast-hero {
        @include page-wrapper;

        min-height: px-to-em(500px);
        padding-top: 6em;            

        &__left {
            padding: $element-spacing;

            img {
                max-height: px-to-em(400px);;
            }
        }
        &__right {
            padding: $element-spacing;

            &--header {                
                color: $color-text-copy;

                &-title {
                    padding-bottom: 0.2em;
                }            
                &-subtitle {
                    font-size: px-to-em($font-size-h1);
                    line-height: 1.1; 
                    font-weight: $font-weight-bold;
                    padding-bottom: 0.2em;
                }
                &-subtext {
                    font-size: px-to-em($font-size-copy-lead);
                    line-height: 1.1; 
                }            
            }
        }

        @include screen($bp-tablet, "min") {
            display: flex;
            flex-direction: row-reverse;

            &__left {
                width: 50%;
            }
            &__right {
                width: 50%;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }
    }

    &__section {
        @include page-wrapper;
    }

    .read-more-intro {
        @include font-open-sans-regular;

        font-family: $font-family-primary;

        strong {
            font-family: $font-family-primary;
        }
    }

    .narrative-overview {
        &__column {
            &--main {

            }
            &--buttons {
                text-align: center;

                .btn-ghost {
                    margin-bottom: px-to-em($element-spacing-small-px);
                }
            }   

            @include screen($bp-tablet, "min") {
                &--main {
                    padding-right: px-to-em(calc($element-spacing-px / 2));
                    width: 60%;
                }
                &--buttons {
                    padding-left: px-to-em(calc($element-spacing-px / 2));
                    width: 40%;
                    text-align: center;

                    .btn-ghost {
                        margin-bottom: px-to-em($element-spacing-small-px);
                    }
                }               
            }         
        }
    }

    .join-the-conversation {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 0;
    }

    .article-tile {
        &-heading.no-link + .hover-grow-fade-fx-img {
            transform: none !important;
        }


        .article-tile-title {
            font-weight: $font-weight-bold;
            font-size: 28px;
            line-height: 1.1;
        }

        .article-tile-card__list-link {
            font-size: 24px;

            &:not(:last-child) {
                padding-bottom: $element-spacing-small;
                margin-bottom: $element-spacing-small;
            }
        }

        .article-tile-card {
            justify-content: flex-start;
            max-width: 448px;
            width: 85%;
        }

        &.forecast-theme__melon {
            .article-tile-card {
                color: $forecast-primary-melon;
            }
        }

        &.forecast-theme__plum {
            .article-tile-card {
                color: $forecast-primary-plum;
            }
        }

        &.forecast-theme__red {
            .article-tile-card {
                color: $forecast-primary-red;
            }
        }

        &.forecast-theme__marine {
            .article-tile-card {
                color: $forecast-primary-marine;        
            }
        }

        &.forecast-theme__olive {
            .article-tile-card {
                color: $forecast-primary-olive;        
            }
        }

        &.forecast-theme__brand-red {
            .article-tile-card {
                color: $forecast-primary-brand-red;        
            }
        }

        &.forecast-theme__royal {
            .article-tile-card {
                color: $forecast-primary-royal;        
            }
        }

        &.forecast-theme__tangerine {
            .article-tile-card {
                color: $forecast-primary-tangerine;        
            }
        }

        &.forecast-theme__dark {
            .article-tile-card {
                color: $forecast-primary-dark;        
            }
        }

        &.forecast-theme__light {
            .article-tile-card {
                color: $forecast-primary-light;        
            }
        }
    }

    .register-section {
        &--panel {
            @include page-wrapper;
            color: $color-brand-white;
            background-color: $forecast-primary-red;
            .btn-ghost {
                @include button-transition(transparent,$color-brand-white, $color-brand-white, $forecast-primary-red);
            }

            padding-top: px-to-em(60px);   
            padding-bottom: px-to-em(60px);   
            width: 100%;
            display: flex; 
            flex-direction: column;

            &__label {          
                &--text {
                    font-size: px-to-em($font-size-h3);
                    line-height: 1.6;
                }

                &--abstract {

                }
            }

            &__button {
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-top: $element-spacing; 

                .btn-ghost {
                    border: thin solid $color-brand-white;
                    color: $color-brand-white;
                }
            }

            @include screen($bp-tablet, "min") { 
                flex-direction: row;

                &__label {
                    width: 70%;
                }

                &__button {
                    width: 30%;                 
                    padding-top: 0;
                }           
            }                        
        }
    }   
}

.forecast {
    &__red-background{
        border-color: $forecast-primary-red !important;
        & .is-selected{
            background: $forecast-primary-red !important;
        }
    }
    &__red{
        color: $forecast-primary-red;
    }
}
