﻿$angle-hero-padding: $site-padding*1.5;

.angled-hero {
	//min-height: px-to-em($angled-polygon-height);
	min-height: px-to-em(500px);
	color: $color-brand-white;
	padding:  $angle-hero-padding 0 px-to-em(60px);
	text-align: center;
	clear: both;
	position: relative;
}

.angled-hero-firm-identity {
	@include transition(z-index);

	position: relative;
	text-align: right;
	z-index: $zindex-site-header; //need to have this sit on top of header
	transition-delay: $transition-timing;

	 .show-site-nav ~ .main-content & {
	 	z-index: $zindex-angled-hero + 5;
	 	transition: none
	 }

	.svg-logo-link {
		position: absolute;
		top:  $angle-hero-padding;
		right: $site-padding;
		display: block;
		width: px-to-em($hero-logo-width);
		
		height: px-to-em(45px);
		.logo-img, .logo-img-mobile{
			width: 100%;
		}
		.logo-img-mobile{
			display: none;
		}
	}

	.svg-logo {
		position: absolute;
		top: 0;
		left: 0;
		path {
			fill:$color-brand-white;
		}
	}
	.hero-into-tagline{
		position: absolute;
		top:  $angle-hero-padding + 3.5em;
		right: $site-padding;
		color: $color-brand-white;
		width: px-to-em(180px);
	}
}

.angled-hero-firm-identity--black {

	.svg-logo {
		path {
			fill:$color-black;
		}
	}
}

.angled-hero-content {
	@include content-wrapper;

	position: relative;
	z-index: $zindex-angled-hero + 5;
	padding-top: px-to-em(120px);
	padding-left: 0;
	padding-right: 0;
}

@include screen($angled-hero-desktop) {
	.angled-hero {
		min-height: inherit;
		padding-bottom: px-to-em(72px); //make sure this lines up with padding from angled-polygon.js
	}
}

@include screen($bp-hero-intro-tablet) {
	
	.angled-hero-firm-identity {
		
		.svg-logo-link {
			width: px-to-em($hero-logo-width-tablet-mid);
			height: px-to-em(36.5px);
		}
	}

	.angled-hero-content {
		padding-top: px-to-em(90px)
	}
}

@include screen($bp-tablet-landscape) {
	.angled-hero {
		padding-bottom: px-to-em(54px);

	}
	.angled-hero-firm-identity {
		
		.svg-logo-link {
			width: px-to-em($hero-logo-width-tablet);
			height: px-to-em(29px);
			right: $site-padding-mobile;
		}
	}
	.angled-hero-content {
		padding-top: px-to-em(70px)
	}
}

@include screen($bp-mobile) {
	$angle-hero-padding: $site-padding ;
	.angled-hero {
		padding-top: $angle-hero-padding ; 
		padding-bottom: px-to-em(50px);
		margin-bottom: $section-spacing-mobile;
	}
	.angled-hero-firm-identity {
		.hero-into-tagline{
			display: none;
		}
			
		.svg-logo-link {
			top: $angle-hero-padding;
			width: px-to-em($hero-logo-width-mobile);
			height: px-to-em(25px);
			.logo-img{
				display: none;
			}
			.logo-img-mobile{
				display: block;
			}
		}
	}
	.angled-hero-content {
		padding-top: px-to-em(60px)
	}
}

.page-article-detail__forecast {
	.angled-hero {
		margin-bottom: 0 !important;

		.angled-hero-content-wrapper {
			display: flex;

			.angled-hero-content {	
				width: 75%;				
				display: flex;
				flex-direction: column;
				justify-content: center;
				padding-top: px-to-em(30px);
				padding-left: $site-padding;

				.article-header-meta {
					text-align: left;
					font-size: px-to-em(24px);
					margin-bottom: px-to-em(14px);
				}

				.page-header {
					display: flex;
					flex-direction: column-reverse;

					&-title {
						@include font-open-sans-regular;
						text-align: left;
						margin-top: px-to-em(calc($element-spacing-small-px / 2));
						font-size: px-to-em($font-size-subheading);
						line-height: 1.1;

						@include screen($bp-tablet-landscape) {
							font-size: px-to-em($font-size-subheading-mobile);
						}						
					}

					&-subtitle {
						font-size: px-to-em(26px);
						text-align: left;	
						margin-top: 0;	
					}
				}

				@include screen($bp-tablet-landscape) {
					width: 100%;
					padding: px-to-em(80px) $site-padding-mobile px-to-em(40px);
				}
			}

			.angled-hero-image {
				width: 25%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				padding-top: px-to-em(30px);

				@include screen($bp-tablet-landscape) {
					display: none;
				}				
			}
		}
	}
	
	&.forecast-theme__melon {
		.angled-hero-body {
			background-color: $forecast-primary-melon;
		}	    
    }

	&.forecast-theme__marine {
		.angled-hero-body {
			background-color: $forecast-primary-marine;
		}	    
    }      

	&.forecast-theme__plum {
		.angled-hero-body {
			background-color: $forecast-primary-plum;
		}	    
    }      

	&.forecast-theme__red {
		.angled-hero-body {
			background-color: $forecast-primary-red;
		}	
    }

	&.forecast-theme__olive {
		.angled-hero-body {
			background-color: $forecast-primary-olive;
		}	
    }

	&.forecast-theme__brand-red {
		.angled-hero-body {
			background-color: $forecast-primary-brand-red;
		}	
    }

	&.forecast-theme__royal {
		.angled-hero-body {
			background-color: $forecast-primary-royal;
		}	
    }

	&.forecast-theme__tangerine {
		.angled-hero-body {
			background-color: $forecast-primary-tangerine;
		}	
    }

	&.forecast-theme__dark {
		.angled-hero-body {
			background-color: $forecast-primary-dark;
		}	
    }

	&.forecast-theme__light {
		.angled-hero-body {
			background-color: $forecast-primary-light;
		}	
    }
}
