﻿$location-header-height: 450px;

.location-header {
	padding-bottom: 0.75em;
	min-height: inherit;
	margin-bottom: $section-spacing-module*1.5;
}

.location-header-card {
	@include clearfix;

	clear: both;
	margin:0 auto ; 
	max-width: $content-large-max-width - ($site-padding);
	background-color: $color-black;
	height: px-to-em($location-header-height);
	position: relative;
	margin-top: 9em;  //$section-spacing-module;


	//dropshadow
	&:after {
		@include absolute-full;

		content: '';
		display: block;
		box-shadow: 0 0px 15px 2px $color-opaque-black;
		z-index: -1;
	}

	// Accessibility
	.skip-to {
		color: #000000;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
	}
}

//structure 2 column base
.location-header-details,
.location-header-map-control {
	width: 50%;
	height: 100%;
	position: relative;
	float: left;
}


//location details
.location-header-details {
	@include bg-cover();

	text-align: left;
	color: $color-brand-white;
	padding: $element-spacing-small * 4;
	padding-right: $clock-di * 1.4;

	.clock {
		position: absolute;
		right:  $element-spacing-small * 4;
		top:  $element-spacing-small * 4;
		transform: translateY(0);
		left: auto;
	}

	&:before {
		@include absolute-full;

		content: '';
    	background-color:  $color-entity-tile-dim;
    	
	}
}

.location-header-name {
	$heading-size: 36px;
	@include font-open-sans-extra-bold($heading-size);

	position: relative;
	line-height: 1.2;
}

.location-header-address {
	$title-size: 26px;

	font-size: px-to-em($title-size);
	line-height: 1.4;
	font-style: normal;
	position: relative;

	.address-item {
		display:block;
	}
}

.location-header-address-contact {
	$title-size: 26px;

	position: absolute;
	font-size: px-to-em($title-size);
	line-height: 1.4;
	bottom: px-to-em($element-spacing-px, $title-size);

	.phone {
		&:after {
			content: '';
			display: block;
		}
	}
}


$get-direction-width: px-to-em(200px);
$map-search-height: px-to-em(90px);

.location-header-map-control {
	overflow: hidden;

	.map {
		height:  calc(100% - #{$map-search-height} ) ;
	}
}

.location-header-map-search {
	display: flex;
	flex-wrap: nowrap;
	padding: $element-spacing-small*2;
	height: $map-search-height;

	.location-searchbox-input {
		height: 100%;
	}

	.location-searchbox {
		margin-right: 1em;
		flex-grow: 1;
		height: 100%;
	}
}

.location-header-get-directions {
	width: $get-direction-width;
	padding-left: $element-spacing-small;
	padding-right: $element-spacing-small;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;

	.btn-ghost-label {
		display: table-cell;
		vertical-align: middle;

		@include ico-icon-after($icon-external_link) {
			margin-left: 0.5em;
			font-size: 0.85em;
		}
	}
}

@include screen($bp-between-desktop) {
	$location-header-height: 450px;

	.location-header-card {
		height: auto;
	}
	.location-header-details,
	.location-header-map-control {
		width: 100%;
		height: 100%;
		float: none;
		height: px-to-em($location-header-height);
	}
}

@include screen($bp-tablet) {
	.location-header {
		margin-bottom: $section-spacing-mobile;

		.svg-logo-link {
			margin-bottom: $section-spacing-mobile;
		}
	}

	.location-header-card {
		margin-top:$section-spacing-module-mobile;
	}


	.location-header-name {
		$heading-size: 28px;

		font-size: px-to-em($heading-size);
	}

	.location-header-details {
		padding: $element-spacing;
		padding-right: $clock-di * 1.4;
	}

	.location-header-address,
	.location-header-address-contact {
		$title-size: 20px;

		font-size: px-to-em($title-size);
	}

	.location-header-address-contact {
		$title-size: 20px;

		
		font-size: px-to-em($title-size);
		
		bottom: px-to-em($element-spacing-px, $title-size);
	}

}

@include screen(1300px) {        
	.location-header {
		&-details {
			padding-right: 175px;
		}
		&-address {
			font-size: 1.5em;
		}
	}
};

@include screen($bp-mobile) {
	$location-header-height: 400px;

	.location-header-card {
		margin-top: $section-spacing-module-mobile * .75;	
	}	

	.location-header-map-control {
		height: auto;
		
		.map, .location-searchbox {
			display: none;
		}

		.location-header-get-directions {
			width: 100%;
		}

		.location-header-map-search {
			display: block;
		}
	}

	.location-header-details {
		height: px-to-em($location-header-height)
	}
}

@include screen($bp-mobile-landscape) {
	.location-header {
		.clock {
			display: none;
		}

		&-details {
			padding-right: 25px;
		}
	}
}