.email-form{
    &__input-wrapper{
        margin-bottom: 2rem;
        text-align: left;
    }
    &__textarea{
        width: 100%;
        min-height: 200px;
        resize: none;
    }
    &__modal__close {
        margin: 0 0 px-to-em(5px) 95%;
    }

    &__modal__close__icon {
        width: 75%;
        height: 75%;
    }

    &__wrapper{
        &.hide{
            display: none !important;
        }
    }
    &__recaptcha{
        margin-bottom: 2rem;
    }
    &__error{
        color: $color-brand-red;
    }

    &__hidden {
        display: none;
    }

    @include screen($bp-tablet) {
        &__textarea{
            min-height: 100px;
        }
      }
}
