$global-perspective-head-zindex: 20;
$global-perspective-regions-zindex: 10;

.global-perspectives {
	//margin: $section-spacing-large 0 $section-spacing-largest;
	margin-bottom: $section-spacing-largest;
}
.global-perspectives-head {
	@include clearfix;

	position: relative;
	z-index: $global-perspective-head-zindex;
	margin-bottom: $section-spacing-largest * 0.75;

	display: flex;
	flex-wrap: nowrap;
	flex-direction: row-reverse;
	justify-content: space-between;


	 &:before {
        @include center(vertical);

        content: '';
        display: block;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #777;
        z-index: -1;
    }
}

.global-perspectives-header {

	margin-top: 0.25em;
	margin-bottom: 0;
	flex-grow: 1;
	text-align: right;

	.section-header-title {
		background-color: $color-brand-white;
		padding-left: $element-spacing-small;
	}
}



.global-perspectives-nav {
	display: table;
}

.global-perspectives-nav-region {
	display: table-cell;
	padding-right: 2vw; //need to fallback to viewport width since firefox/IE has trouble computing percentages

	&:last-child {
		padding-right: 0;
	}

	&.is-selected {
		.global-perspectives-btn {
			background-color: $color-text-gray;
		}
	}
}

.global-perspectives-nav-toggle {
	@extend %uc;

	@include font-open-sans-extra-bold();

	display: none;
	width: 100%;
	padding: 0.5em $section-spacing-small;
	background-color: $color-brand-gold;
	overflow: hidden;
	color: $color-brand-black;
	text-align: left;

	@include ico-icon-after($icon-chevron-down) {
		@include center(vertical);

		$title-size: 10px;
		font-size: px-to-em($title-size);
		right: px-to-em( $section-spacing-small-px, $title-size);
	}
}

.global-perspectives-btn {
	@extend %uc;

	@include font-open-sans-extra-bold();

	display: block;
	min-width: px-to-em(160px);
	padding: 0.5em 0.25em;
	background-color: $color-text-gold;
	overflow: hidden;
	position: relative;
	color: $color-brand-white;
	text-align: center;


	&:hover {

		&:before {
            transform: skew(-25deg) translate(75%,-50%);
		}
	}

	&:before {
        @include transition(transform);

        content: '';
        display: block;
        width: 200%;
        height: 200%;
        position: absolute;
        right: 110%;
        top: 50%;
        background-color: $color-text-gray;
        transform: skew(-25deg) translate(-5%,-50%);
        transition-duration: 0.5s;
        z-index: 1;
    }
}

.global-perspectives-btn-label {
	position: relative;
	z-index: 2;
}



.global-perspectives-regions {
	position: relative;
	z-index: $global-perspective-regions-zindex;
}

@include screen($bp-desktop) {
	.global-perspectives-header {
		float: none;

		margin-bottom: $section-spacing;
		text-align: center;

		.section-header-title {
			padding-left: 0;

	        //gray line
	        &:before {
	            @include center(vertical);

	            content: '';
	            display: block;
	            right: 100%;
	            margin-right: $site-padding-mobile;
	            width: px-to-em(1000px);
	            height: 1px;
	            background-color: #777;
	        }
	    }
	}

	.global-perspectives-head {
		display: block;

		&:before {
			display: none;
		}
	}

	.global-perspectives-nav {
		width: 100%;
	}

	.global-perspectives-nav-region {
		padding-right: 4%;
	}

}

@include screen($bp-tablet) {
	.global-perspectives {
		margin-bottom: $section-spacing-large;
	}

	.global-perspectives-head {
		margin-bottom: $section-spacing-large-mobile;
	}

	.global-perspectives-header {
			margin-bottom: px-to-em($section-header-spacing);
	}
}

@include screen($bp-mobile) {

	.global-perspectives-head {
		overflow: visible;
		margin-bottom: px-to-em($section-spacing-mobile-px);
	}

	.global-perspectives-header {
		margin-bottom: px-to-em($section-spacing-mobile-px);
	}

	.global-perspectives-menu {
		position: relative;
		z-index: 15;
	}

	.global-perspectives-btn,
	.global-perspectives-nav-toggle {
		display: block;
	}

	.global-perspectives-nav {
		position: absolute;
		width: 100%;
		top: 100%;
		max-height: 0;
		opacity: 0;
		display:block;
		overflow: hidden;


		transition: max-height $transition-timing $transition-ease,
					opacity $transition-timing $transition-ease calc($transition-timing/2);


		.show-regions-menu  & {
			max-height: px-to-em(500px);
			opacity: 1;
		}

	}

	.global-perspectives-nav-region {
		display: list-item;
		padding-right: 0;
	}

	.global-perspectives-btn {
		text-align: left;
		padding: 1em $section-spacing-small;
		width: 100%;

		.is-selected &  {
			display: none;
		}
	}

}
