
.search-landing-listing {
	margin-bottom: $section-spacing-module;
}



@include screen($bp-tablet) {	
	.search-landing-listing {
		margin-bottom: $section-spacing-module-mobile;
	}
}
