$search-landing-submit-width: 50px;
$search-landing-submit-width-mobile: 45px;

.search-landing-filters {
	position: relative;

	.advanced-filters {
		width: calc(100% - #{$search-landing-submit-width});
	}
}

.search-landing-filters-submit {
	position: absolute;
	right: 0;
	bottom:0;
	width: $search-landing-submit-width;
	height: $search-landing-submit-width;
	padding: 0;

	&--capabilities {
		position: relative;
		margin: auto 0 auto 5px;
	}

	.btn-ghost-label {
		padding-left: px-to-em( $search-landing-submit-width-mobile * 1.5);
	}
}

@include screen($bp-tablet) {
	.search-landing-filters {
		border-bottom: 2em solid transparent;
		.advanced-filters  {
			width: calc(100% - #{px-to-em($search-landing-submit-width-mobile)});
		}
	}

	.search-landing-filters-submit {
		width: px-to-em($search-landing-submit-width-mobile, $font-size-buttons-mobile);
		height:  px-to-em($search-landing-submit-width-mobile, $font-size-buttons-mobile);
	}
}

@include screen($bp-mobile) {
	.search-landing-filters {
		border-bottom: 0 none;
		.advanced-filters  {
			width: 100%;
			display: block;
		}
	}

	.search-landing-filters-submit {
		margin-top: $section-spacing-small;
		float: none;
		min-width: $search-landing-submit-width-mobile;
		width: 100%;
		height: auto;
		position: relative;
		right: auto;
		bottom: auto;
		padding: 0.75em 1em;
		text-indent: 0;

		.btn-ghost-label {
			padding-left: 0;
			display: inline;
		}

		&--capabilities {
			margin-left: 0;
		}

		&:before {
			position: relative;
			font-size: px-to-em(18px);
			margin-right: 0.25em;
			display: inline-block;
			vertical-align: middle;
			top: auto;
			left: auto;
			transform: translate(0);
		}	
		
	}
}
