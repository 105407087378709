.page-diversity-landing{
  &.blue-theme{
    $theme-color: $diversity-blue;
    .diversity__button--hero{
      border: 1px solid $theme-color;
      &:after{
        background-color:$theme-color;
      }
    }
    .diversity-carousel__lg-trigger-btn{
      background-color: $theme-color;
    }
    .diversity-carousel__trigger-btn{
      background-color: $theme-color;
    }
    .diversity-carousel__button-ghost{
      border: 1px solid $theme-color;
      color: $theme-color;
      &:after{
        background-color: $theme-color;
      }
      &:hover{
        color: $color-brand-white;
      }
    }
    .diversity__quote-bar{
      background-color: $theme-color !important;
    }
  }
  &.red-theme{
    $theme-color: $color-brand-red;
    .diversity__button--hero{
      border: 1px solid $theme-color;
      &:after{
        background-color:$theme-color;
      }
    }
    .diversity-carousel__lg-trigger-btn{
      background-color: $theme-color;
    }
    .diversity-carousel__trigger-btn{
      background-color: $theme-color;
    }
    .diversity-carousel__button-ghost{
      border: 1px solid $theme-color;
      color: $theme-color;
      &:after{
        background-color: $theme-color;
      }
      &:hover{
        color: $color-brand-white;
      }
    }
    .diversity__quote-bar{
      background-color: $theme-color !important;
    }
  }
  &.green-theme{
    $theme-color: $diversity-green;
    .diversity__button--hero{
      border: 1px solid $theme-color;
      &:after{
        background-color:$theme-color;
      }
    }
    .diversity-carousel__lg-trigger-btn{
      background-color: $theme-color;
    }
    .diversity-carousel__trigger-btn{
      background-color: $theme-color;
    }
    .diversity-carousel__button-ghost{
      border: 1px solid $theme-color;
      color: $theme-color;
      &:after{
        background-color: $theme-color;
      }
      &:hover{
        color: $color-brand-white;
      }
    }
    .diversity__quote-bar{
      background-color: $theme-color !important;
    }

  }
  &.orange-theme{
    $theme-color: $diversity-orange;
    .diversity__button--hero{
      border: 1px solid $theme-color;
      &:after{
        background-color:$theme-color;
      }
    }
    .diversity-carousel__lg-trigger-btn{
      background-color: $theme-color;
    }
    .diversity-carousel__trigger-btn{
      background-color: $theme-color;
    }
    .diversity-carousel__button-ghost{
      border: 1px solid $theme-color;
      color: $theme-color;
      &:after{
        background-color: $theme-color;
      }
      &:hover{
        color: $color-brand-white;
      }
    }
    .diversity__quote-bar{
      background-color: $theme-color !important;
    }
  }
  &.cyan-theme{
    $theme-color: $diversity-cyan;
    .diversity__button--hero{
      border: 1px solid $theme-color;
      &:after{
        background-color:$theme-color;
      }
    }
    .diversity-carousel__lg-trigger-btn{
      background-color: $theme-color;
    }
    .diversity-carousel__trigger-btn{
      background-color: $theme-color;
    }
    .diversity-carousel__button-ghost{
      border: 1px solid $theme-color;
      color: $theme-color;
      &:after{
        background-color: $theme-color;
      }
      &:hover{
        color: $color-brand-white;
      }
    }
    .diversity__quote-bar{
      background-color: $theme-color !important;
    }
  }
  &.light-green-theme{
    $theme-color: $diversity-light-green;
    .diversity__button--hero{
      border: 1px solid $theme-color;
      &:after{
        background-color:$theme-color;
      }
    }
    .diversity-carousel__lg-trigger-btn{
      background-color: $theme-color;
    }
    .diversity-carousel__trigger-btn{
      background-color: $theme-color;
    }
    .diversity-carousel__button-ghost{
      border: 1px solid $theme-color;
      color: $theme-color;
      &:after{
        background-color: $theme-color;
      }
      &:hover{
        color: $color-brand-white;
      }
    }
    .diversity__quote-bar{
      background-color: $theme-color !important;
    }
  }
  &.lime-theme{
    $theme-color: $diversity-lime;
    .diversity__button--hero{
      border: 1px solid $theme-color;
      &:after{
        background-color:$theme-color;
      }
    }
    .diversity-carousel__lg-trigger-btn{
      background-color: $theme-color;
    }
    .diversity-carousel__trigger-btn{
      background-color: $theme-color;
    }
    .diversity-carousel__button-ghost{
      border: 1px solid $theme-color;
      color: $theme-color;
      &:after{
        background-color: $theme-color;
      }
      &:hover{
        color: $color-brand-white;
      }
    }
    .diversity__quote-bar{
      background-color: $theme-color !important;
    }
  }
  &.fuschia-theme{
    $theme-color: $diversity-fuschia;
    .diversity__button--hero{
      border: 1px solid $theme-color;
      &:after{
        background-color:$theme-color;
      }
    }
    .diversity-carousel__trigger-btn{
      background-color: $theme-color;
    }
    .diversity-carousel__lg-trigger-btn{
      background-color: $theme-color;
    }
    .diversity-carousel__button-ghost{
      border: 1px solid $theme-color;
      color: $theme-color;
      &:after{
        background-color: $theme-color;
      }
      &:hover{
        color: $color-brand-white;
      }
    }
    .diversity__quote-bar{
      background-color: $theme-color !important;
    }
  }
}
