$title-size-cta: 36px;

.impactful-cta {
	position: relative;
	
	text-align: center;
	min-height: px-to-em(200px);
	padding-left: $site-padding * 2;
	padding-right:  $site-padding * 2;

	// .section-header {
	// 	margin-bottom: $section-spacing/1.5;
	// }

	& + .layout-module {
		 margin-top: $section-spacing-module;
	}

	&.is-hovered {

		.angled-background-image {
			transform: scale(1.2) skewY($angled-degree);
		}
	} 
}

.impactful-cta-intro {
	margin:0 auto px-to-em($element-spacing-px, $font-size-copy-lead);
	max-width: px-to-em($content-max-width-small,  $font-size-copy-lead);

}

.impactful-cta-link-external {
	$heading-size: 36px;
	$icon-size: 24px;

	@include font-open-sans-extra-bold($heading-size);
	@include link-transition();

	display: inline;
	padding-right: px-to-em($icon-size, $heading-size) + 0.75em; //fixed vlaue accounts for spacing next to icon

	@include ico-icon-after($icon-external_link) {
		margin-left: 0.75em;
		font-size: px-to-em($icon-size, $heading-size);
		position: absolute;
		margin-top: 0.4em;
	}
}

.impactful-cta-link {

	.ico {
		padding-left: 0.75em;
		vertical-align: middle;
	}
	&--external-small {
		margin-top: 1em;
		margin-right: 1em;		
	}
}

.impactful-cta-wrapper {
	@include content-large-wrapper;

	position: relative;
	z-index: 10;
	background-color: rgba(255, 255, 255, .9 );
	padding:$section-spacing $site-padding * 2;
}


.impactful-cta--small {
	padding-top: 6.7vw;
	padding-bottom: 6.7vw;

	.section-header {
		margin-bottom: px-to-em(25px);
	}

	.impactful-cta-wrapper {
		max-width: px-to-em(940px);
		padding: $element-spacing * 2 $element-spacing*1.5
	}

	.impactful-cta-intro  {
		margin-bottom: px-to-em(25px, $font-size-copy);
	}
}

@include screen($bp-tablet) {
	
	.impactful-cta {
		padding-left: $site-padding;
		padding-right:  $site-padding ;
	}

	.impactful-cta-wrapper {
		padding-left: $site-padding;
		padding-right: $site-padding ;
	}

	.impactful-cta-link-external {
		$heading-size: 26px;

		font-size: px-to-em($heading-size);
	}



	.impactful-cta-intro {
		margin:0 auto px-to-em($element-spacing-px, $font-size-copy-lead-mobile);
	}

	.impactful-cta--small {

		
		.impactful-cta-wrapper {
			padding-left: $site-padding;
			padding-right: $site-padding;
			
		}
	}
}


@include screen($bp-mobile) {
	
	.impactful-cta {
		padding-left: $site-padding-mobile;
		padding-right:  $site-padding-mobile;

		.section-header {
			margin-bottom: $section-spacing-mobile;
		}
	}

	.impactful-cta-wrapper {
		padding:$section-spacing-mobile $site-padding-mobile;
	}

	.impactful-cta--small {

		.section-header {
			margin-bottom: px-to-em(25px);
		}
	}
}