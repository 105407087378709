.location-searchbox {
	position: relative;
}

.location-searchbox-input[type="text"] {
	border-color: transparent;
	line-height: 1.1em;
	width: 100%;
}

.location-searchbox-nearest-office {
	position: absolute;
	right:  $form-input-padding-sides;
	top: 0;
	height: 100%;
	color: $color-brand-red;

	&:hover {
		color: $color-brand-black;		
	}

	@include ico-icon-before($icon-location) {

	}
}

@include screen($bp-mobile) {
	.searchbox-input {
		$title-size: 18px;
		font-size: px-to-em($title-size);
	}
}