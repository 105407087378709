$primary-tile-height: 625px;
.case-study-highlights{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-bottom: $section-spacing-large;
    .article-tile{
        width: 48%;
        &.primary-tile{
            width: 100%;
            height: $primary-tile-height;
            margin-bottom: px-to-em(40px);
            font-size: px-to-em(48px,36px);
        }
    }
    @include screen($bp-mobile){
        .article-tile{
            width: 100%;
            margin-bottom: px-to-em(36px,36px);
            &.primary-tile{
                height: auto;
                min-height: 48vw;
                margin-bottom: px-to-em(36px,36px);
            }
        }
    };
}
