.related-entity-panel {
	display: none;

	&.is-active-panel {
		display: block;
	}

	.btn-text-load-more {
		margin-top: $section-spacing;
	}
}

.related-entity-panel-tiles {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-left: calc($grid-spacing / -2);
	margin-right: calc($grid-spacing / -2);

	.article-tile {
		width: calc(50% - #{$grid-spacing});
		margin-left: calc($grid-spacing / 2);
		margin-right: calc($grid-spacing / 2);
		margin-bottom: $grid-spacing;
	}

	.article-tile-card {
		margin-bottom: 8rem;
		width: 19em;
	}
}

@include screen($bp-tablet-landscape) {
	.related-entity-panel-tiles {
		display: block;
		margin-left: 0;
		margin-right: 0;

		.article-tile {
			width: 100%;
			margin-left: 0;
			margin-right: 0;
		}
	}
}