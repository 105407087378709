$keyword-spacing: px-to-em(12px);

.keyword-search  {

	position: relative;
	margin-top: $section-spacing-mobile;

	.keyword-search-submit {
		margin-top: 0;
		margin-left: 1em;
	}
	.page-header-title{
		margin-right: px-to-em(20px);
	}
}

.keyword-search-options {
	display: flex;
	justify-content: space-between;
	flex-wrap: nowrap;
}
 
#search-typeahead-status {
	display: none;
}
 
.typeahead-results { 
  position: absolute;
  z-index: 500;
  color: black; 
  background-color: white; 
  width: 100%; 
  border: $border-input;
  border-top: 0;
} 
 
.typeahead-results li { 
  padding: 5px; 
  cursor: pointer; 
} 
 
.typeahead-results .active { 
  background-color: lightgray; 
} 

.ui-datepicker-div {
  z-index: 50 !important;
}

$compass-width: 10px;

.keyword-search-locations {
	position: relative;
	flex-grow: 1;
}
.current-location{
	position: absolute;
	right: $compass-width + 10px;
	color: $color-brand-red;
	cursor: pointer;
	@include center('vertical');
	@include ico-icon-before($icon-location);
}

.keyword-search-input[type="text"] {
	border-color: transparent;
	line-height: 1.2em;
	width: 100%;
	height: px-to-em(50px);

	& + & {
		margin-left: $keyword-spacing;
	}



}

.keyword-search-input-events {
	margin-left: $keyword-spacing;
	flex-grow: 1;
	width: 100%;
	position: relative;

	.keyword-search-input {
		padding-right: $element-spacing-small*2.5;
	}

	
}

.keyword-search-input-events-label {
	@include center(vertical);

	right:  $form-input-padding-sides;
	color: $color-brand-red;
	width: 1em;
	height: 1em;
	overflow: hidden;
	white-space: nowrap;
	padding-left: 1em;

	@include ico-icon-after($icon-calendar) {
		position: absolute;
		left: 0;
		top: 0;
		display: inline-block;
	}
}

.keyword-search-submit {
	margin-top: 0;
	float: right;
}

.keyword-search-primary {
	display: flex;
	flex-wrap: nowrap;

	.keyword-search-input {
		flex-grow: 1;
		width: auto;
	}
	.keyword-search-submit {
		flex-shrink: 0;
		float: none;
		position: relative;
		right: auto;
		bottom: auto;
	}
}

@include screen($bp-tablet) {

	.keyword-search-input[type="text"] {
		padding: $select-dropdown-padding;

		height: px-to-em(45px);
	}
}

@include screen($bp-mobile) {

	.keyword-search-primary{
		&.has-dropdown{
			display: block;
		
			.keyword-search-submit{
				margin-top: 1em;
				margin-left: 0;
			}
		}
	}

	.keyword-search-input-container {
		width: auto;
	}

	.keyword-search-options {
		flex-wrap: wrap;
	}
	.keyword-search-input[type="text"] {
		& + & {
			margin-top: $keyword-spacing;
			margin-left: 0;
		}
	}

	.keyword-search-input-events {
		margin-top: $keyword-spacing;
		margin-left: 0;
	}

	.keyword-search  {
		.page-header-title {
			width: 100%;
			margin-bottom: .5em;
		}
	}

	.keyword-search-locations {
		max-width: calc(100% - 4em );
	}

}
