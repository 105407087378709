.find-fit {
  $column-sidebar-width: 300px;
  $tabs-offset: $section-spacing-module;

  .section-header {
    margin-bottom: $section-spacing-module;
  }

  &-column {
    overflow: hidden;
    display: flex;
    flex-wrap: nowrap;
  }

  &-column-main {
    flex-grow: 1;
    padding-right: $section-spacing-small;
    padding-top: $tabs-offset;
  }

  &-column-sidebar {
    width: auto;
  }

  &-lead-img {
    margin-bottom: $section-spacing-small;
    text-align: center;
  }

  &-lead-img-main {
    max-width: 100%;
  }

  //custom design updates
  .gold-tabs-menu {
    position: absolute;
    width: 100%;
    padding-right: px-to-em($column-sidebar-width) + $section-spacing-small;
    z-index: 20;
  }

  .gold-tabs {
    margin-bottom: 0;

    .silent-wrapper {
      position: relative;
    }
  }

  .stacked-infographics {
    width: 100%;
  }

  @include screen($bp-desktop) {
    &-column { 
      display: block;
    }
    
    &-column-main {
      padding-right: 0;
      padding-top: 0;
    }

    &-column-sidebar {
      width: 100%;
    }

    .gold-tabs-menu {
      position: static;
      padding-right: 0;
    }
  
    .gold-tabs {
      margin-bottom: $element-spacing;
    }

    .stacked-infographics {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: stretch;
      margin-top:  $section-spacing-module;
    }

    .infographic {
      width: 32%;

      & + .infographic {
        margin-top: 0;
      }
    }
  }

  @include screen($bp-tablet) {
    .section-header {
      margin-bottom: $section-spacing-module-mobile;
    }
  }

  @include screen($bp-mobile) {

    &-column-sidebar {
      margin-top: px-to-em($section-spacing-mobile-px);
    }

    .stacked-infographics {
      margin-top: 0;
    }
    .infographic {
      // width: 48%;
      width: px-to-em(343px);
      min-height: px-to-em(343px);

      margin-top: $element-spacing;

      & + .infographic {
        margin-top: $element-spacing;
      }
    }
  }

  @include screen($bp-mobile-landscape) {
    .stacked-infographics {
      justify-content: center;
    }
    .infographic {
      width: px-to-em(343px);
    }
  }
}
