.infographic {
    background-color: $color-charcoal;
    color: $color-brand-white;
    text-align: center;
    padding: px-to-em($element-spacing-px);
    width: px-to-em(300px);
    min-height: px-to-em(300px);
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

    &-figure {
        $font-size: 64px;
        font-weight: $font-weight-extra-bold;
        font-size: px-to-em($font-size);
        width: 100%;

        &:after {
            content: '';
            display: block;
            margin: px-to-em($element-spacing-px, $font-size) auto;
            width: px-to-em(50px, $font-size);
            border-top: 1px solid $color-brand-gold;

        }
    }

    &-label  {
        font-weight: $font-weight-light;
        font-size: px-to-em(22px);
    }

    &--page {
        margin: px-to-em(10px);
    }

    @include screen($bp-tablet) {
        width: px-to-em(260px);
        min-height: px-to-em(260px);

        &-figure  {
            $font-size: 48px;
            font-size: px-to-em($font-size);
            &:after {
                margin: px-to-em($element-spacing-px, $font-size) auto;
                width: px-to-em(50px, $font-size);
            }
        }

        &-label  {
            font-size: px-to-em(18px);
        }
    }

    @include screen($bp-mobile) {
        &-label  {
            font-size: px-to-em(22px);
        }
    }
}
