.capabilities-compact {
	$gradient-offset: px-to-em(150px);


	position: relative;
	padding: 0 14% $section-spacing-large;
	color: $color-brand-white;

	@include angled-background();


	&:before {
		background: $color-brand-white;
		bottom:100%;
		margin-bottom: calc($gradient-offset /2);
		min-height: px-to-em(150px);
	}

	//red gradient
	&:after {
		@include gradient($color-gradient-red-start, $color-brand-red, 0deg);

		content: '';
		position: absolute;
		display: block;
		left: 0;
		right: 0;
		bottom: 0;
		height: calc(100% + #{$gradient-offset});
		z-index: -1;
	}


	.section-header {
		margin-right: -10%;
	}

	.section-header-title {
		&:after {
			background-color: $color-brand-white;
		}
	}

	.listing-capabilities-option {
		@include transition(transform);

		border-color: $color-light-red;

		transform: translateZ(0);
		padding: 0;

		&:hover{	
			transform:translateZ(0) scale(1.075);
		}

		&:last-child {
			border: 0 none;
		}
	}
	.listing-capabilities-link {
		padding: $section-spacing-small 0;
		display: inline-block;
		color: $color-brand-white;
	}

	.btn-text-load-more {
		display: none;
	}
}



@include screen($bp-tablet-landscape) {
	.capabilities-compact {

		padding: 8% 8% $section-spacing-large-mobile;
	}

}

@include screen($bp-mobile) {

	.capabilities-compact {


		overflow: visible;
		padding: 12vw $site-padding-mobile;
		margin-top: 10%;

		&:before {
			content: none;
		}

		&:after {
			top:0;
			bottom: 0;
			transform: skewY($angled-degree * -1) translate3d(0,0,0);
			height: auto;
		}

	    .section-header {
	    	margin-right: 0;
	    }

	    .listing-capabilities-option {
	    	transform:none;

			&:hover{	
				transform:none;
			}

			&:last-child {
				border: 0 none;
			}

			&__read-more {
				display: none;
			}
		}

	    .btn-text-load-more {
			display: block;
			width: 100%;
			margin-top: $section-spacing-large-mobile;
		}
	}
}
