.page-professionals-landing {
    .silent-wrapper {
        @include content-large-wrapper;
    }
    
    .criteria-shown {
        visibility: visible;
        opacity: initial;
    }

    .advanced-filters-category {
        width: calc(100% / 6);

        &:last-of-type {
            padding-right: 0;
        }
    }
    
    .search-landing-filters:focus {
		 outline: none;
     }
     
    .search-landing-filters .advanced-filters {
        width: 100%;
    }

    .search-landing-filters-submit {
        position: relative;
        margin-left: 0.75rem;
        padding: 12px 24px;
    }

    @include screen($bp-tablet) {
        .advanced-filters-category {
            width: 50%;
        }
    }

    @include screen($bp-mobile) {
        .advanced-filters-category {
            width: 100%;
        }

        .search-landing-filters-submit {
            margin-left: 0;
        }
    }
}
