.alpha-search {
	display: flex;
	justify-content: space-between;
	flex-wrap: nowrap;
}

.alpha-search-btn {
	$font-size-alpha: 30px;
	@include font-open-sans-bold($font-size-alpha);
	line-height: 1.1;
	color: $color-brand-white;
	cursor: pointer;

	&:hover,
	&.is-active-letter {
		color: $color-brand-gold;
	}
	&.disabled {
		color: $color-text-gray-light;
		cursor: default;
	}
}

// .alpha-search-item {
// 	$font-size-alpha: 30px;

// 	@include font-open-sans-bold($font-size-alpha);

// 	line-height: 1.1;

// 	color: $color-brand-gold;

// 	&:hover,
// 	&.is-active {
// 		color: $color-brand-white;
// 	}
// 	&.disabled {
// 		color: $color-text-gray-light;
// 		.alpha-search-btn {
// 			cursor: default;
// 		}
// 	}

// 	.alpha-search-btn {
// 		cursor: pointer;
// 	}
// }

@include screen($bp-tablet) {


	.alpha-search {
		flex-wrap: wrap;
	}

	.alpha-search-item {
		margin-top: $element-spacing-small;
		text-align: center;
	}

	.alpha-search-item {
		$font-size-alpha: 22px;

		font-size: px-to-em($font-size-alpha);

		flex-shrink: 0;
		width: calc(100/13) *  1%;
	}
}
@include screen($bp-mobile-landscape){
	.alpha-search-item {
		$font-size-alpha: 14px;
		font-size: px-to-em($font-size-alpha);
	}
}