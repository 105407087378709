
.site-search-results-listing {
	margin-bottom: $section-spacing-module;
}

.site-search-results-listing-approximation {
	margin-bottom: $section-spacing;
	display: block;
}



@include screen($bp-tablet) {	
	.site-search-results-listing {
		margin-bottom: $section-spacing-module-mobile;
	}

	.site-search-results-listing-approximation {
		margin-bottom: $section-spacing-mobile;
	}
}
