.page-responsible-business-detail{
  &.blue-theme{
    $theme-color: $diversity-blue;
    .responsible-business__button--hero{
      border: 1px solid $theme-color;
      &:after{
        background-color:$theme-color;
      }
    }
    .professional-card-link--diversity{
      color: $theme-color;
    }
    .responsible-business__quote-bar{
      background-color: $theme-color !important;
    }
    .sidenav-section-skew--responsible-business{
      background-color:$theme-color;
    }
  }
  &.red-theme{
    $theme-color: $color-brand-red;
    .responsible-business__button--hero{
      border: 1px solid $theme-color;
      &:after{
        background-color:$theme-color;
      }
    }
    .professional-card-link--diversity{
      color: $theme-color;
    }
    .responsible-business__quote-bar{
      background-color: $theme-color !important;
    }
    .sidenav-section-skew--responsible-business{
      background-color:$theme-color;
    }
  }
  &.green-theme{
    $theme-color: $diversity-green;
    .responsible-business__button--hero{
      border: 1px solid $theme-color;
      &:after{
        background-color:$theme-color;
      }
    }
    .professional-card-link--diversity{
      color: $theme-color;
    }
    .responsible-business__quote-bar{
      background-color: $theme-color !important;
    }
    .sidenav-section-skew--responsible-business{
      background-color:$theme-color;
    }
	
    .btn-ghost { 
      @include button-transition();
      &--responsible-business {
        border: 1px solid $theme-color;
        color: $theme-color;
        &:after {
            background-color: $theme-color;      
        }                
      }
    } 

  }
  &.orange-theme{
    $theme-color: $diversity-orange;
    .responsible-business__button--hero{
      border: 1px solid $theme-color;
      &:after{
        background-color:$theme-color;
      }
    }
    .professional-card-link--diversity{
      color: $theme-color;
    }
    .responsible-business__quote-bar{
      background-color: $theme-color !important;
    }
    .sidenav-section-skew--responsible-business{
      background-color:$theme-color;
    }

  }
  &.cyan-theme{
    $theme-color: $diversity-cyan;
    .responsible-business__button--hero{
      border: 1px solid $theme-color;
      &:after{
        background-color:$theme-color;
      }
    }
    .professional-card-link--diversity{
      color: $theme-color;
    }
    .responsible-business__quote-bar{
      background-color: $theme-color !important;
    }
    .sidenav-section-skew--responsible-business{
      background-color:$theme-color;
    }

  }
  &.light-green-theme{
    $theme-color: $diversity-light-green;
    .responsible-business__button--hero{
      border: 1px solid $theme-color;
      &:after{
        background-color:$theme-color;
      }
    }
    .professional-card-link--diversity{
      color: $theme-color;
    }
    .responsible-business__quote-bar{
      background-color: $theme-color !important;
    }
    .sidenav-section-skew--diversity{
      background-color:$theme-color;
    }

  }
  &.lime-theme{
    $theme-color: $diversity-lime;
    .responsible-business__button--hero{
      border: 1px solid $theme-color;
      &:after{
        background-color:$theme-color;
      }
    }
    .professional-card-link--diversity{
      color: $theme-color;
    }
    .responsible-business__quote-bar{
      background-color: $theme-color !important;
    }
    .sidenav-section-skew--diversity{
      background-color:$theme-color;
    }

  }
  &.fuschia-theme{
    $theme-color: $diversity-fuschia;
    .responsible-business__button--hero{
      border: 1px solid $theme-color;
      &:after{
        background-color:$theme-color;
      }
    }
    .professional-card-link--diversity{
      color: $theme-color;
    }
    .responsible-business__quote-bar{
      background-color: $theme-color !important;
    }
    .sidenav-section-skew--diversity{
      background-color:$theme-color;
    }

  }

}
