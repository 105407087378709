
.around-corner-tile {
	width: px-to-em($around-corner-tile-width);

}

.around-corner-tile-figure {
	max-width: px-to-em(625px);
	max-height: px-to-em(324px);
	overflow: hidden;
}


.around-corner-tile-img {
	min-width: 100%;
	max-width: 100%;
	display: block;
}

.around-corner-tile-abstract {
	padding: px-to-em($element-spacing-small-px * 2, $font-size-copy);
}


@include screen($bp-tablet-landscape) {
	$around-corner-tile-width: 80%;
	


	.around-corner-tile {
		width: $around-corner-tile-width;
		margin-left: auto;
		margin-right: auto;
	}

}


@include screen($bp-mobile) {

	.around-corner-tile-figure {
		max-width: none;
		max-height: none;
	}

	.around-corner-tile {
		width: 100%;
	}

	.around-corner-tile-figure {
		margin-left: $site-padding-mobile * -1;
		margin-right: $site-padding-mobile * -1;
		text-align: center;
	}

	.around-corner-tile-img {
		display: inline-block;
	}

	.around-corner-tile-abstract {
		padding: $site-padding-mobile 0;
	}
}

.page-wrapper-diversity {
	@include screen($bp-desktop, "min") {
		padding-top: 4rem;
	}
}