.listing-articles-item {
	font-size: 0.8em;
	padding-bottom: $section-spacing-reduced;
	border-bottom: $border-list-heavy;

	& + & {
		padding-top: $section-spacing-reduced;
	}
}

.listing-articles-meta {
	$title-size: 24px;

	font-size: px-to-em($title-size);
	line-height: 1.5;
	margin-bottom: px-to-em($element-spacing-small-px * 2 ,$title-size);
}

.listing-articles-type {
	font-weight: $font-weight-bold;


	&:after {
		content: '-';
		display: inline-block;
		margin-right: 0.25em;
		margin-left: 0.5em;
		font-weight: $font-weight-regular;
	}
}

.listing-articles-date {
	& ~ .listing-articles-location,
	& ~ .listing-articles-source {
		&:before {
			content: '|';
			display: inline-block;
			margin-right: 0.25em;
		}
	}
}

.listing-articles-title {
	$heading-size: 36px;
	$underline-color: $color-link-hover;

	@include font-open-sans-light($heading-size);
	

	
	color: $color-link;
	line-height: 1.3;
	overflow: hidden;
	background-image: linear-gradient(to bottom, $underline-color 0%, $underline-color 100%);
	
    background-repeat: no-repeat;
    background-position: 0 38px;
    background-size: 0  auto;
    transition: color $transition-timing $transition-ease,
    			background $transition-timing $transition-ease;

    &:hover {
    	 background-size: 100%  auto;
    	 color: $color-link-hover;
    }
}

//from http://browserhacks.com/ FIrefox media query hack
@media all and (min--moz-device-pixel-ratio:0) {
	.listing-articles-title {
		background-position: 0 38px;
	}
}

//from http://browserhacks.com/ IE EDge and up media query hack
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.listing-articles-title {
		background-image: none;
		@include underline-transition($color-link-hover)
	}
}

.listing-capabilities-link {
	@include font-open-sans-bold(16px);
	line-height: 1.2;
}



@include screen($bp-tablet) {
	.listing-articles-item {
		font-size: 1em;
		padding-bottom: $section-spacing-mobile;

		& + & {
			padding-top: $section-spacing-mobile;
		}
	}

	.listing-articles-meta {
		$title-size: 16px;

		font-size: px-to-em($title-size);
	}

	.listing-articles-title {
		$heading-size: 20px;

		font-size: px-to-em($heading-size);

		background-position: 0 26px;
	}
}