﻿.page-section {
    margin-left: auto;
    margin-right: auto;
    max-width: 100.3125rem;
    padding: 3.125rem 0;

    &__topics {
        max-width: none;
        margin-bottom: 3.125rem;
    }

    &--no-padding {
        padding: 0;
    }

    &__header {
        text-align: center;
    }

    &__title {
        font-weight: 800;
        font-size: 1.875rem;
        margin-bottom: 0;


        &--white {
            color: #fff;
        }
    }

    &__footer {

        & .btn {
            vertical-align: top;
        }

        &--center {
            text-align: center;
        }
    }

    &--full .page-section__footer {
        padding-left: 1.875rem;
        padding-right: 1.875rem;
    }

    &--focus {

        @include media-breakpoint-up(lg) {
            padding-top: rem-calc(150);
        }

        @include media-breakpoint-up(xl) {
            padding-top: rem-calc(250);
        }
    }

    &--gradient {
        overflow: hidden;
        position: relative;
        z-index: 1;
    }

    &__gradient {

        &::before,
        &::after {
            bottom: 0;
            content: '';
            display: block;
            height: 100%;
            left: 0%;
            opacity: 0.3;
            position: absolute;
            right: 0;
            top: 0;
            transform: rotate(-10deg);
            transform-origin: top right;
            width: 100%;
            z-index: -1;
        }


        &--bottom {

            &::before {
                background-image: linear-gradient(70deg, rgba(0, 0, 0, 0.00) 50%, rgba(0, 0, 0, 0.75) 100%);
                top: 75%;
            }

            &::after {
                background-image: linear-gradient(70deg, rgba(0, 0, 0, 0.00) 26%, rgba(0, 0, 0, 0.75) 100%);
                top: calc(75% + 40px);

                @include media-breakpoint-up(lg) {
                    top: calc(75% + 80px);
                }
            }
        }

        &--top {

            &::before,
            &::after {
                transform-origin: bottom left;
                top: auto;
            }

            &::before {
                background-image: linear-gradient(-70deg, rgba(0, 0, 0, 0.00) 50%, rgba(0, 0, 0, 0.75) 100%);
                bottom: 75%;

                @include media-breakpoint-up(lg) {
                    bottom: 45%;
                }
            }

            &::after {
                background-image: linear-gradient(-70deg, rgba(0, 0, 0, 0.00) 26%, rgba(0, 0, 0, 0.75) 100%);
                bottom: calc(75% + 40px);

                @include media-breakpoint-up(lg) {
                    bottom: calc(45% + 80px);
                }
            }
        }
    }

    &--region {
        position: relative;

        + .page-section--focus {

            @include media-breakpoint-up(lg) {
                margin-top: rem-calc(-120);
            }

            @include media-breakpoint-up(xl) {
                margin-top: rem-calc(-200);
            }
        }
    }

    @each $theme, $color in $brand-palette {

        &--#{$theme} {
            background-color: $color;
        }
    }
}
