.page-general-content {
	#ss-embed-frame {
		width: 1px;
		min-width: 100%;
		border: 0px;
	}
	
	.narrative-overview {
		margin-bottom: 0;
	}
}


@include screen($bp-mobile) {
	.page-general-content {
	
		.narrative-overview {
			margin-bottom: $section-spacing-module-mobile;
		}
	}
}
