$grid-offset: $site-padding;

.listing-articles-grid {
	@include clearfix;

	margin: 0 $grid-offset * -1;
	display: flex;
	flex-wrap: wrap;
}


.listing-articles-grid-item {
	flex-shrink: 0;
	width: 25%;
	padding: 0 $grid-offset;
	margin-bottom: $section-spacing-mobile;
}

.listing-articles-grid-title {
	$title-size: 22px;

	font-size: px-to-em($title-size);
	line-height: 1.5;

	display: inline-block;

	&:not(.no-link) {
		&:hover {
			color: $color-text-gray-light;
			
			&:after {
				transform: translateX(0.5em);	
				}
	}

	&:after {
		@include transition(transform);

		content: '➞';
		display: inline-block;
		margin-left: 0.5em;
	}
	}
}


@include screen($bp-desktop) {
	$grid-offset: $site-padding-mobile;

	.listing-articles-grid {
		margin-left: $grid-offset * -1;
		margin-right: $grid-offset * -1;
	}

	.listing-articles-grid-item {
		padding: 0 $grid-offset;
	}
}

@include screen($bp-tablet) {

	.listing-articles-grid-item {
		width: 50%;
		margin-bottom: $section-spacing-mobile;
	}

	.listing-articles-grid-title {
		$title-size: 16px;

		font-size: px-to-em($title-size);
	}
}


@include screen($bp-mobile-landscape) {
	.listing-articles-grid {
		display: block;
	}
	.listing-articles-grid-item {
		width: 100%;
	}
}
