.explore-locations {
  .section-header {
    margin-bottom: $section-spacing-module;
  }

  &-grid {
    $grid-spacing: px-to-em(15px);
  
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    
    margin-right: $grid-spacing * -1;
  
    .related-item-tile {
      margin-top: $grid-spacing;
      margin-right: $grid-spacing;
      width: calc(50% - #{$grid-spacing});
    }
  }

  &-search {
    margin-bottom: $section-spacing;
  }

  &-cta {
    text-align: center;
    margin-top: $section-spacing;
  }

  .related-item-tile-header {
    $icon-offset: 30px;
    padding-bottom: px-to-em($icon-offset * 2);

    &--external {
      @include ico-icon-after($icon-external_link) {
        $font-size: 30px;
        position: absolute;
        right: px-to-em($icon-offset, $font-size);
        bottom: px-to-em($icon-offset, $font-size);
        color: $color-brand-gold;
        font-size: px-to-em($font-size);
      }
    }
  }

  @include screen($bp-tablet) {
    .section-header {
      margin-bottom: 2em;
    }

    &-search {
      margin-bottom: $section-spacing-mobile;
    }
  
    &-cta {
      text-align: center;
      margin-top: $section-spacing-mobile;
    }

    .related-item-tile {
      width: 80%;
      height: 100%;
      margin-right: auto;
      margin-left: auto;
    }

    .related-item-tile-header {
      $icon-offset: 20px;
  
      padding-bottom: px-to-em($icon-offset * 2);

      &--external {
        @include ico-icon-after($icon-external_link) {
          $font-size: 20px;
          
          right: px-to-em($icon-offset, $font-size);
          bottom: px-to-em($icon-offset, $font-size);
          
          font-size: px-to-em($font-size);
        }
      }
    }
  }

  @include screen($bp-mobile) {
    .related-item-tile {
      width: 100%;
      margin-top: $grid-spacing;
      margin-right: $grid-spacing;
    }
  }
}
