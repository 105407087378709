.stacked-infographics {
    .infographic {
        & + .infographic {
            margin-top: calc($section-spacing/2);
        }
    }

    @include screen($bp-tablet) {
        .infographic {
            & + .infographic {
                margin-top: $section-spacing-small;
            }
        }
    }
}
