.notable-news {

	color: $color-brand-white;
	position: relative;
	padding: $section-spacing-module $site-padding 20vw;
	overflow: hidden;
	margin-bottom: px-to-em(-50px);


	.page-wrapper {
		position: relative;
		z-index: 5;
	}

	.listing-articles-grid {
		margin-top: $section-spacing-large;
	}

	.angled-background {
		top: -5.5vw;
		bottom: auto;
		height: 100%;
	}
}

.notable-news-view-more {
	margin-top: $section-spacing-mobile;
	text-align: center;
}

@include screen($bp-desktop) {
	.notable-news {
		padding-left: 0;
		padding-right: 0;
		margin-bottom: 0;
	}
}

@include screen($bp-tablet) {

	.notable-news {
		text-align: center;
		padding-top: $section-spacing-module-mobile;

		.listing-articles-grid {
			margin-top: $section-spacing-module-mobile;
		}
	}
}

@include screen($bp-mobile-landscape) {
	.notable-news {
		text-align: left;
	}
}
