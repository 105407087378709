$video-player-padding: 10%;
$video-player-max-width: px-to-em(640px);
$video-responsive-padding: 34.25%;

.video-player {
	opacity: 0;
	visibility: hidden;
	position: fixed;
	background-color: $color-opaque-black;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: $zindex-modal-bg;
	padding: $video-player-padding;
	transition: visibility $transition-timing $transition-ease $transition-timing,
				opacity $transition-timing $transition-ease;


	&.show-video-player {
		opacity: 1;
		visibility: visible;
		transition: visibility 10ms $transition-ease,
					opacity $transition-timing $transition-ease;
	}
}


.video-window {
	@include center(both);

	
	padding-bottom: $video-responsive-padding;
	margin: 0 auto;
	width: 60%;

	iframe,
	object {
		position: absolute;
		width: 100% !important;
		height: 100% !important;
	}
}

.video-player-close  {
	$close-dimen: 50px;
	position: absolute;
	top: calc($video-player-padding/2);
	right: calc($video-player-padding/2);

	&:hover {
		&:before {
			background-color: $color-opaque-black;
		}
	}

	@include ico-icon-before($icon-close) {
		$icon-size: 30px;

		@include transition(background-color);

		color: $color-brand-white;
		font-size: px-to-em($icon-size);
		width: px-to-em($close-dimen,$icon-size);
		height: px-to-em($close-dimen,$icon-size);
		border-radius: calc($close-dimen/2);
		border: 2px solid $color-brand-white;
		text-align: center;
		line-height: 1.5;
	}
}

@include screen($bp-tablet) {
	.video-window {
		width: 80%;
		padding-bottom: 45%;
	}
}