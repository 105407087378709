$snapshot-width: px-to-em(380px);
$snapshot-width-small: px-to-em(240px);


.professional-header {
	line-height: 1.6;
	position: relative;
	.angled-hero-content-slender {
		z-index: $zindex-angled-hero ;
		padding-bottom: 3.5vw;
	}

	.social-links-item {

		margin-right: 1.5em;
	}

	.social-links-icon {

		color: $color-brand-white;
		font-size: px-to-em(24px);
		transition: transform $transition-timing;
		&:hover {
			color: $color-brand-gold;
			transform: scale(1.35);
		}

		&.ico-print {
			font-size: px-to-em(20px);
		}

		&.ico-vcard {
			font-size: px-to-em(18px);
		}
	}
	.angled-hero-slender-image{
		background-position: left top;
		&:before{
			display: none;  
		}
	}
}

.professional-header-figure {

	float: left;
	width: $snapshot-width;
	margin-left: $section-spacing;
	&-image, img{
		width: 100%;
	}
}

.professional-header-info {
	margin-left: $snapshot-width + ($section-spacing * 2);
}

.professional-header--small {

	margin-bottom: 5em;
	padding-bottom: 0;

	.professional-header-figure {
		width: $snapshot-width-small;
	}

	.professional-header-info {
		margin-left: $snapshot-width-small + ($section-spacing * 2);
	}

	.angled-hero-content-slender:before, 
	.angled-hero-slender-polygon {
		top: $site-padding* -2.0;
	}

	@include screen($bp-tablet-landscape) {
		margin-bottom: 0;
	}
}

.professional-header-main {
	margin-bottom: $section-spacing-small;
}

.professional-header-name {
	@include font-open-sans-extra-bold($page-header-font);
	line-height: 1.1;
}

.professional-header-content  {
	@include clearfix;

	max-width: none;
	padding-left: 0;
	padding-right: 0;
	text-align: left;
	padding-bottom: 4em;

	&.angled-hero-content{
		z-index: inherit;
	}
}

.professional-header-contact {
	margin-bottom:$section-spacing-small;
}

.professional-header-email {
	$subtitle: 18px;
	@include font-open-sans-bold($subtitle);
	@include link-transition($color-brand-white, $color-brand-gold);
	margin-bottom: 5px;
	&::before{
		width: 100%;
		background-color: $color-brand-white;
	}
	&:hover{
		&::before{
			background-color: $color-brand-gold;			
		}
	}

}

.professional-header-offices {
	display: flex;
	flex-wrap: wrap;
}
.professional-header-office-item {
	$title-size: 20px;

	font-size: px-to-em($title-size);
	flex-grow: 1;
	max-width: px-to-em(265px, $title-size);
	padding-right: 0.75em;
}

.professional-header-office {
	font-weight: $font-weight-bold;
	@include link-transition($color-brand-white, $color-brand-gold);
	&::before{
		width: 100%;
		background-color: $color-brand-white;
	}
	&:hover{
		&::before{
			background-color: $color-brand-gold;			
		}
	}
}


@include screen($bp-desktop) {
	$snapshot-width: 40%;

	.professional-header-figure {
		width: $snapshot-width;
		margin-left: 0;

	}

	.professional-header-info {
		margin-left: $snapshot-width;
		padding-left: $section-spacing;
	}

	.professional-header--small {
		.professional-header-info {
			margin-left: $snapshot-width-small;
		}
	}
}

@include screen($bp-tablet) {
    .professional-header {

        .angled-hero-content-slender {
            padding-bottom: 8vw;
        }

        .social-links-item {
            margin-right: 1.25em;
        }

        .social-links-icon {
            font-size: px-to-em(22px);

            &:hover {
                color: $color-brand-white;
            }

            &.ico-print {
                font-size: px-to-em(20px);
            }

            &.ico-vcard {
                font-size: px-to-em(18px);
            }
        }
    }

    $snapshot-width: 36%;

    .professional-header-figure {
        width: $snapshot-width;
    }

    .professional-header-info {
        margin-left: $snapshot-width;
        padding-left: $section-spacing-mobile;
    }

    .professional-header--small {
        .professional-header-figure {
            width: $snapshot-width;
        }

        .professional-header-info {
            margin-left: $snapshot-width;
            padding-left: $section-spacing-mobile;
        }
    }

    .professional-header-name {
        font-size: px-to-em($page-header-font-mobile);
    }

    .professional-header-position {
        $title-size: 22px;
        font-size: px-to-em($title-size);
        line-height: 1;
    }

    .professional-pronouns-position {
		$sub-title-size: 16px;
		$margin-size: 8px;
		margin-top: px-to-em($margin-size);
        margin-bottom: px-to-em($margin-size);
        font-size: px-to-em($sub-title-size);
        line-height: 1;
    }

    .professional-header-email {
        font-size: 1em;
    }

    .professional-header-office-item {
        $title-size: 18px;

        font-size: px-to-em($title-size);
    }
}

@include screen($bp-mobile) {
	.professional-header-office-item {
		font-size: 1em;
	}



	.professional-header-name {
		font-size: px-to-em(28px);

	}
}


@include screen($bp-mobile-landscape) {

	.professional-header-info {
		margin-left: 0;
		padding-left: 0;
		text-align: center;
	}

	.professional-header-figure {
		margin:0 auto $section-spacing-mobile;
		width: 60%;
		float: none;
	}

	.professional-header--small {

		.professional-header-info {
			margin-left: 0;	
			padding-left: 0;
		}

		.professional-header-figure {
			margin:0 auto $section-spacing-mobile;
			width: 60%;
		}
	}

	.professional-header-main {
		margin-bottom: $element-spacing-small;
	}

	.professional-header-contact {
		padding-top: 0;
	}

	.professional-header-offices {
		display: block;
	}

	.professional-header-office-item {
		max-width: none;

		& + & {
			margin-top: $section-spacing-small;
		}
	}

}
