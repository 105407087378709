.page-topic-detail {
	.angled-hero {
		margin-bottom: 5em;
	}
	
	@include screen($bp-tablet-landscape) {
		.angled-hero {
			margin-bottom: 0;
		}
	}
}
