﻿
.nav-toggle {
    background-color: palette('dark_grey');
    border: 0;
    display: flex;
    height: rem-calc(44);
    padding: rem-calc(6);

    &:focus,
    &:hover {

        .nav-toggle__icon__inner {

            &,
            &::before,
            &::after {
                background-color: palette('red');
            }
        }
    }


    &__icon {
        align-items: center;
        display: flex;
        height: 19px;
        position: relative;
        width: 28px;

        @include media-breakpoint-up(lg) {
            height: 32px;
            width: 42px;
        }
    

        &__inner {
            display: block;

            &,
            &::before,
            &::after {
                background-color: #fff;
                height: 3px;
                transition-timing-function: cubic-bezier(.55,.055,.675,.19);
                transition-duration: 75ms;
                transition-property: transform;
                width: 28px;

                @include media-breakpoint-up(lg) {
                    height: 6px;
                    width: 42px;
                }
            }

            &::before,
            &::after {
                content: "";
                display: block;
                position: absolute;
            }

            &::before {
                top: -8px;
                transition: top 75ms ease .12s, opacity 75ms ease;

                @include media-breakpoint-up(lg) {
                    top: -13px;
                }

                .is-active & {
                    opacity: 0;
                    top: 0;
                    transition: top 75ms ease, opacity 75ms ease .12s;
                }
            }

            &::after {
                bottom: -8px;
                transition: bottom 75ms ease .12s, transform 75ms cubic-bezier(.55,.055,.675,.19);

                @include media-breakpoint-up(lg) {
                    bottom: -13px;
                }

                .is-active & {
                    bottom: 0;
                    transform: rotate(-90deg);
                    transition: bottom 75ms ease, transform 75ms cubic-bezier(.215,.61,.355,1) .12s;
                }
            }
        }


        &__inner {
            display: block;
            position: relative;

            .is-active & {
                transform: rotate(45deg);
                transition-delay: .12s;
                transition-timing-function: cubic-bezier(.215,.61,.355,1);
            }
        }
    }
}
