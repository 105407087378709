.page-infographics {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  max-width: 60em;
  margin-bottom: $section-spacing-module;

  @include screen($bp-mobile) {
    margin-bottom: $section-spacing-module-mobile;
  }
}