.sliced-box {
	padding: 0.1em;
	overflow: hidden;
	position: relative;
	margin-top: -10%;
	transform: translateZ(0);


	&:before { 
		content: ' ';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;
		border-top: 90px solid white;
		border-right: 810px solid transparent;
	}

	@include screen($bp-mobile) {
		margin-top: 0;
		padding: 0;

		&:before { 
			border-top-width: 40px;
    		border-right-width: 465px;
		}
	}
}




.self-angled-background {
	position: relative;
	padding: 5.5% 0;
	margin: 5.5% 0;

	> * { position: relative; z-index: 1; }

	&:before {
		content: " ";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 0;
		@include skew(0, -6deg);
	}


	&.red {

		&:before {
			background-color: $color-brand-red;
		}
	}
}