.featured-person-carousel {
  display: flex;
  overflow: hidden;
  width: calc(100% - 75px); //IE Fix

  &__wrapper {
    display: flex;
    flex-direction: row;
  }

  &__slide {
    min-width: 100%;
    transition: transform 0.3s ease-in-out;
  }

  &__btn {
    background-color: $color-text-gold;
    color: $color-brand-white;
    padding: px-to-em(20px) 0;
    height: px-to-em(275px);
    padding: 0 px-to-em(20px);
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    -webkit-border-radius: 0px;
    transition: all ease-in-out .15s;
    display: block;
    box-shadow: 0px 7px 20px 4px rgba(0,0,0,0.2);
    z-index: 1;

    &:hover {
      background-color: $color-brand-gold;
    }
  }

  @include screen($bp-mobile-landscape) {
    width: 100%;
    margin-bottom: px-to-em(40px);

    &__wrapper {
      flex-direction: column;
    }

    &__btn {
      padding: px-to-em(20px) 0;
      height: unset;
    }
  }
}