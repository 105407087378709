.page-experience-landing {
	.silent-wrapper {
		@include content-large-wrapper;
	}
	.experience-results-listing{
		.silent-wrapper{
			@include content-wrapper;
		}
	}
	.criteria-shown {
		opacity: initial;
		visibility: initial;
	}

	.advanced-filters-category {
		width: 33.3334%;
	}

	.search-entity-landing {
		margin-bottom: $section-spacing;
	}

	@include screen($bp-tablet) {

        .advanced-filters-category {
            width: 50%;
        }

    }

    @include screen($bp-mobile) {

        .advanced-filters-category {
            width: 100%;
        }

		.page-header {
			text-align: center;
		}
	}
}
