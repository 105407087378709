$tab-filter-spacing: 2em;

.tab-filters-menu {
	display: flex;
	align-items: stretch;
	height: 100%;	
}

.tab-filters-view {
	
	flex-basis: 25%;
	padding: 0 0.5em;

	&:last-child {
		padding-right: 0;
	}

	&.is-selected {
		.tab-filters-btn {
			background-color: $color-gray-dark;
		}
	}
}


.tab-filters-btn {
	@extend %uc;

	@include font-open-sans-extra-bold();

	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	
	padding: 0.75em 1em;
	overflow: hidden;
	position: relative;
	
	text-align: center;

	@include button-transition($background-color:$color-text-gold, $text-color: $color-brand-white, $hover-background-color: $color-brand-black)
}

.tab-filters-btn-label {
	position: relative;
	z-index: 2;
}


.tab-filters-mobile-toggle {
	@extend %uc;

	@include font-open-sans-extra-bold();

	display: none;
	width: 100%;
	padding: 0.5em $section-spacing-small;
	background-color: $color-brand-gold;
	overflow: hidden;
	color: $color-brand-black;
	text-align: left;

	@include ico-icon-after($icon-chevron-down) {
		@include center(vertical);

		$title-size: 10px;
		font-size: px-to-em($title-size);
		right: px-to-em( $section-spacing-small-px, $title-size);
	}
}


@include screen($bp-mobile) {
	.tab-filters {
		position: relative;
		z-index: 20;
	}

	.tab-filters-menu {
		display: block;
		position: absolute;
		top: 100%;
		width: 100%;
		height: auto;
		max-height: 0;
		overflow: hidden;
		transition: max-height $transition-timing $transition-ease;


		.show-menu &{
			max-height: px-to-em(600px);
		}
	}

	.tab-filters-view  {
		margin: 0;
		padding: 0;
		text-align: left;
		
		&.is-selected {
			display: none;
		}
	}

	.tab-filters-btn {
		display: block;
		text-align: left;
		padding:1em $section-spacing-small;
	}

	.tab-filters-mobile-toggle {
		display: block;
	}
}
