

.related-item-tile {
	position: relative;
	background-color: $color-entity-tile;
	overflow: hidden;
}

.related-item-tile--no-link {

	background-color: $color-gray-dark;
	border: 2px solid $color-entity-tile;

	
	.related-item-tile-header {
		color: $color-text-white-hover;
		cursor: default;
	}
}

.related-item-tile--multimedia {
	@include ico-icon-after($icon-video-play) {
		@include transition(color);

		background-color:$color-opaque-black;
		color: $color-brand-gold;
		font-size: px-to-em($icon-play-size);
		line-height: 3;
		text-align: center;
		display: block;
		padding-left: 0.25em;
		width: px-to-em($play-icon-dimen,$icon-play-size);
		height: px-to-em($play-icon-dimen,$icon-play-size);
		border-radius: calc($play-icon-dimen/2);
		border: $border-white;
		clear: both;
		transition: $transition-default;

		position: absolute;
		right: px-to-em(2 * $element-spacing-small-px, $icon-play-size);
		bottom: px-to-em($element-spacing-px, $icon-play-size);
		z-index: 2; // Display on top of background images
	}

	&:not(.related-item-tile--no-link):hover {
		&:after {
			color: $color-text-white-hover;
		}
	}
}

.related-item-tile-meta {
	$title-size: 20px;

	@include font-open-sans-light($title-size);
	line-height: 1.5;
}

.related-item-tile-type {
	font-weight: $font-weight-bold;


	&:after {
		content: '-';
		display: inline-block;
		margin-right: 0.25em;
		margin-left: 0.5em;
		font-weight: $font-weight-regular;
	}
}

.related-item-tile-date {
	& ~ .related-item-tile-location,
	& ~ .related-item-tile-source {
		&:before {
			content: '|';
			display: inline-block;
			margin-right: 0.25em;
		}
	}
}

.related-item-tile-type {
	font-weight: $font-weight-bold;

	&:after {
		content: '-';
		display: inline-block;
		margin-right: 0.25em;
		margin-left: 0.5em;
		font-weight: $font-weight-regular;
	}
}

.related-item-tile-header {
	height: 100%;
	min-height: px-to-em($entity-tile-height);
	display: block;
	color: $color-brand-white;
	position: relative;
	z-index: 5;
	padding: $element-spacing ($element-spacing-small * 2) $element-spacing;

	.related-item-tile--multimedia & {
		// Ensure text doesn't overlap multimedia icon
		padding-bottom: px-to-em($play-icon-dimen + (2 * $element-spacing-px));
		min-height: px-to-em($play-icon-dimen + (2 * $element-spacing-px));
	}

	&:hover {
		.related-item-tile-heading {
			color: $color-text-white-hover;
		}

		.related-item-tile-subheading {
			color: $color-text-white-hover;
		}
	}

	&.is-external-link {
		&:after {
			display: none;
		}

		.related-item-tile-heading {
			@include ico-icon-after($icon-external_link) {
				position: absolute;
		        font-size: 0.5em;
		        margin-left: 0.5em;
		        margin-top: 1em;
		        vertical-align: middle;
			}
		}

	}
}



.related-item-tile-heading {
	$heading-size: 34px;

	@include font-open-sans-light($heading-size);
	@include transition(color);

	margin-top: px-to-em($section-spacing-small-px, $heading-size);
	max-width: px-to-em(545px, $heading-size);

}

.related-item-tile-subheading {
	$subheading-size: 26px;

	@include transition(color);

	font-size: px-to-em($subheading-size);
	margin-top: px-to-em($element-spacing-small-px, $subheading-size);
}


.related-item-tile-featured {
	@extend %uc;

	font-weight: $font-weight-extra-bold;
	color: $color-brand-red;
	margin-bottom: 0.5em;
}

@include screen($bp-desktop) {

	.related-item-tile-header {
		padding-bottom: 18%;
		min-height: px-to-em($entity-tile-height-tablet);
	}
}


@include screen($bp-tablet) {

	.related-item-tile-header {
		padding-bottom: $section-spacing-mobile;
		
	}

	.related-item-tile-heading {
		$heading-size: 24px;

		font-size: px-to-em($heading-size);
		line-height: 1.35;
		max-width: none;
	}

	.related-item-tile-subheading {
		$subheading-size: 20px;

		font-size: px-to-em($subheading-size);
	}

	.related-item-tile-meta {
		$title-size: 18px;

		font-size: px-to-em($title-size);
	}

	.related-entity-tile-featured {
		font-size: px-to-em(14px);
	}
}

@include screen($bp-mobile) {
	.related-item-tile-header {
		min-height: px-to-em($entity-tile-height-mobile);
	}
}