.related-areas-list-option {
	$title-size: 20px;
	font-size: px-to-em($title-size);

	& + & {
		margin-top: px-to-em($element-spacing-small-px, $title-size);
	}
}

.related-areas-link {
	@include link-transition;

	font-weight: $font-weight-bold;

	&:after {
		content: ',';
		color: $color-text-copy;
		font-weight: $font-weight-regular;
	}

	&:last-child {
		&:after {
			content: '';
		}
	}
}

.related-areas-topic {

	&:after {
		content: ',';
	}

	&:last-child {
		&:after {
			content: '';
		}
	}
}

@include screen($bp-tablet) {

	.related-areas-list {
		margin-top: $section-spacing-mobile;
	}
	
	.related-areas-list-option {
		$title-size: 18px;

		font-size: px-to-em($title-size);
	}
}
