$title-size-cta: 36px;

.up-next {
	position: relative;
	
	text-align: center;
	min-height: px-to-em(200px);
}

.up-next-wrapper {
	position: relative;
	z-index: 9;
}

.up-next-label { 

	$label-size: 20px;
	font-size: px-to-em($label-size, $title-size-cta);
	font-weight: $font-weight-regular;
	line-height: 1.2;
	margin-bottom: $element-spacing-small;
	
	display: block;

	&:after {
		@include transition(transform);

		content: '➞';
		display: inline-block;
		margin-left: 0.5em;
	}
}

.up-next-cta { 
	@include font-open-sans-extra-bold($title-size-cta);

	display: inline-block;
	color: $color-brand-white;
	line-height: 1.1;

	@include link-transition($color-brand-white, $color-brand-white);

	&:hover {

		.up-next-label { 
			&:after {
				transform: translateX(0.5em);
			}
		}
	}
}

@include screen($bp-tablet) {
	$title-size-cta: 28px;

	.up-next {
		min-height: px-to-em(160px);
	}
	.up-next-cta { 
		font-size: px-to-em($title-size-cta);
	}

	.up-next-label { 
		$label-size: 18px;

		font-size: px-to-em($label-size, $title-size-cta);
	}
}