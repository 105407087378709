.search-entity-landing {
	position: relative;

	.silent-wrapper {
		@include content-wrapper;
	}
}

.search-entity-landing-promo {
	
	.impactful-cta + .grey-wedge {
		margin-top: $section-spacing-module;
	}
}

.search-entity-landing-results {
	display: none;
	margin-bottom: calc($section-spacing-module/2);
}


@include screen($bp-tablet) {
	.search-entity-landing-promo {
		margin-bottom: calc($section-spacing-module-mobile/2);

		.impactful-cta + .grey-wedge {
			margin-top: $section-spacing-module-mobile;
		}
	}

	.search-entity-landing-results {
		display: none;
		margin-bottom: calc($section-spacing-module-mobile/2);
	}
}