﻿$site-logo-width: 192px;
$site-logo-width-mobile: 120px;


$site-header-triangle-offset: $site-padding * .75;
$site-header-triangle-offset-tablet: $site-padding-mobile; //16px

.site-header {
	background-color: transparent;
	color: $color-black;
	width: 100%;
	height: px-to-em($site-header-height);
	position: absolute;
	z-index: $zindex-site-header;
	opacity: 1;
	top: 0;
	// transform: translate3d(0,0,0);

	//sliding background when searchbox is opened
	&:before {
		content: '';
		position: absolute;
		width: 0;
		height: 100%;
		left: 0;
		opacity: 0;
		
		@include transition(width);
	}	
}

.site-header-wrapper {
	@include page-wrapper;

	padding-top:  $site-padding*1.5;
	padding-left: $site-padding*2;

	height: 100%;
	position: relative;
	
	//triangle image
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: px-to-em(-245px);
		width: 0;
		height: 0;
		border-top: px-to-em(179px) solid $color-brand-white;
		border-right: px-to-em(1039px) solid transparent;
	}

	&:after {
		@include transition(border-color);

		content: '';
		position: absolute;
		top: $site-header-triangle-offset;
		left: $site-header-triangle-offset;
		width: 0;
		height: 0;
		border-top: px-to-em(110px) solid $color-wedge-gray;
		border-right: px-to-em(640px) solid transparent;
	}
}

.site-logo {
	$triangle-width: calc($site-header-height/2);

	width: px-to-em($site-logo-width + ($site-padding-px * 2) );
	display: inline-block;
	float: right;
	position: relative;
	height: 100%;
	background-color: $color-brand-white;
	padding: 1.25em px-to-em($site-padding-px) 0;
	opacity: 0;
	visibility: hidden;
	transform: translateZ(0);

	&:before {
		content: '';
		display: block;
		position: absolute;
		left: calc($triangle-width/2) * -1;
		top: 0;
		width: 0;
		height: 0;
		border-left: px-to-em(calc($triangle-width/2)) solid transparent;
		border-right: px-to-em($triangle-width) solid transparent;
		border-bottom: px-to-em($triangle-width*2) solid $color-brand-white;
	}

    &--diversity {
        padding-top: .25em;
    }
}

.site-logo-img {
	display: block;
	width: 100%;
	position: relative;
	z-index: 5;
}

.site-header-utility {
	@include center(vertical, relative);
	width: 25%;

	z-index: $zindex-site-header-utility;
	display: block;
	
	.hide-language-selection {
		border-right: 0px;
	}
	.ico-home{
		color: $color-brand-gold;
	}
}

.site-header-search {
	border: 1px solid $color-brand-gold;
	border-width: 0 1px;
	padding: 0 0.75em;
	margin:0 0.5em;
	vertical-align: middle;
	font-size: px-to-em(18px);
	line-height: 1em;
	color: $color-brand-gold;
	@include transition(transform);
	

	@include ico-icon-after($icon-search) {
	}
}

//headroom specific classes
.headroom--unpinned {
	transform: translate3d(0, $site-header-height * -1, 0);
}

.setup-fixed-header {
	@include transition(transform);

	position: fixed;
	background-color: $color-wedge-gray;
	will-change: position;

	&:before {
		background-color: $color-brand-black;
		opacity: 1;
	}

	.site-logo {
		opacity: 1;
		visibility: visible;
	}

	.site-header-wrapper {
		padding-top: 0;
		padding-right: 0;
		max-width: none;

		&:before,
		&:after {
			opacity: 0;
			visibility: hidden;
		}
	}
}

.headroom--pinned {
	transform: translate3d(0,0,0);
}

//menu shows
.show-site-nav {
	.menu-toggle {
		color: $color-brand-white;
	}

	.site-header-search,
	.language-menu {
		opacity: 0;
	}
}

.show-searchbox {
	&:before {
		width: 100%;
	}

	.site-header-search {
		color: $color-brand-white;
		transform: translateX(px-to-em(-110px));
		border-color: $color-brand-black;

		&:after {
			content: $icon-close;
			font-size: 1.5em;
		}
	}

	.menu-toggle,
	.language-menu {
		opacity: 0;
	}

	.site-header-wrapper {
		&:after {
			border-top-color: $color-brand-black;
		}
	}
}

@include screen($bp-hero-intro-tablet) {
	
	.site-header-wrapper {
		padding-top:  $site-padding;
		padding-left: $site-padding  * 1.5;

		//white triangle- numbers were taken from  eye balling triangle rendering
		&:before {
			border-top-width: px-to-em(171px);
			border-right-width: px-to-em(990px);
		}

		&:after {
			top: $site-header-triangle-offset-tablet;
			left: $site-header-triangle-offset-tablet;
		}
	}
}


@include screen($bp-tablet) {

	.site-header {
		padding-left: $site-padding-mobile;
		height: px-to-em($site-header-height-mobile);

		&.headroom--unpinned {
	    	transform: translate3d(0, $site-header-height-mobile * -1, 0);

		}

		&.setup-fixed-header {
			padding-left: 0;

			.site-header-wrapper {
				&:after {
					opacity: 1;	
				}
				
			}
		}
	}

	.site-header-wrapper {
		padding-left: $site-padding-mobile * 1.5;	
		padding-right: 0;

		//triangle image 
		&:before {
			border-top-width: px-to-em(127px);
			border-right-width: px-to-em(875px);
		}

		&:after {
			opacity: 1;
			left: 0;
			border-top-width: px-to-em(76px);
			border-right-width: px-to-em(525px);
		}
	}

	.site-header-search {
		font-size: 1em;
	}

	.site-logo {
		$triangle-width: calc($site-header-height-mobile/2);

		width: px-to-em($site-logo-width-mobile) + $site-padding-mobile * 2;
		padding: 1.25em px-to-em($site-padding-mobile-px) 0;


		&:before {
			left: calc($triangle-width/2) * -1;
			border-left: px-to-em(calc($triangle-width/2)) solid transparent;
			border-right: px-to-em($triangle-width) solid transparent;
			border-bottom: px-to-em($triangle-width*2) solid $color-brand-white;
		}
	}


	.show-searchbox {
		.site-header-search {
			transform: translateX(px-to-em(-55px));
			
		}

		&.setup-fixed-header {
			.site-header-search {
				transform: translateX(px-to-em(-55px));
				
			}
		}
	}
}

@include screen($bp-mobile) {
    .site-header {
        padding-left: 0;
        padding-right: 0;
        height: px-to-em(50px);
        transform: translate3d(0,0,0);


        &.headroom--not-top {
            height: px-to-em($site-header-height-mobile);
            // &:before {
            // 	z-index: 9;
            // }
        }

        .language-menu {
            &:before {
                content: '|';
                padding: 0 0.25em;
                display: inline-block;
                vertical-align: top;
            }
        }
    }

    .site-header-search {
        position: absolute;
        right: 0;
        color: $color-brand-white;
        border-width: 0;
        visibility: hidden;
        width: 0;
        opacity: 0;
        padding: 0;
        margin: 0;
        transition: right $transition-timing $transition-ease, opacity $transition-timing $transition-ease, visibility $transition-timing $transition-ease $transition-timing;
        z-index: 5;

        &:after {
            content: $icon-search;
            font-size: 1.25em;
        }
    }

    .site-header-wrapper {
        padding-top: 0;
        padding-left: $site-padding-mobile;
        //hide white trinagle
        &:before {
            content: none;
        }

        &:after {
            top: 0;
            border-top-width: px-to-em(55px);
            border-right-width: px-to-em(300px);
        }
    }

    .site-header-search {
        border-right: 0;
        padding-right: 0;
    }

    .show-searchbox {

        .site-header-search {
            right: calc(100% - 200px);
            transform: translateX(px-to-em(-14px));

            &:after {
                content: $icon-search;
                font-size: 1.25em;
            }
        }

        .site-header-wrapper {

            &:after {
                display: none;
                border-color: transparent;
            }
        }

        &.setup-fixed-header {
            .site-header-search {
                transform: translateX(px-to-em(-14px));
            }
        }
    }

    .show-site-nav {
        .site-header-search {
            opacity: 1;
            visibility: visible;
			margin: 0 .5em;
			top: 3px; 
			left: 40px;
			width: 20px;
			height: 22px;
		}
		&.show-searchbox{
			.site-header-search {
				left: 50px;
			}			
		}
    }
}