﻿$angled-polygon-slender-skew: 9deg;

.angled-hero-slender {
	padding-bottom: $section-spacing;
	//margin-bottom: 0;
	min-height: inherit;
	overflow: hidden;
	position: static;
	background-size: cover;
	background-position: center -15px;
	background-repeat: no-repeat;
 // margin-bottom: 3.5vw;
	.angled-hero-content {
		z-index: $zindex-angled-hero + 1;
		//padding-bottom: $section-spacing;
	}
	&.german-header{
		margin-bottom: 0;
		.angled-hero-content {
			padding-bottom: $section-spacing;
		}

		.angled-hero-content-slender {
			padding-bottom: 7vw;
			margin-bottom: 3.5vw;
	
			&:before {
				@include center(horizontal);
	
				content: '';
	
				top: $site-padding* -1.5;
				width: px-to-em($ap-max-width);
				height: 100%;
				transform: translateX(-50%) skewY($angled-polygon-slender-skew * -1);
				box-shadow: $box-shadow-polygon;
				backface-visibility: hidden;
			}
	
		}
		.angled-hero-slender-image{
			background-position: center top;
		}
	}
}

.angled-hero-content-slender {
	position: relative;
}

.angled-hero-slender-polygon {
    @include center(horizontal);
    top: $site-padding* -1.5;
    bottom: auto;
    width: px-to-em($ap-max-width);
    overflow: hidden;
    height: 100%;
    transform: translateX(-50%) skewY($angled-polygon-slender-skew * -1);
    backface-visibility: hidden;

    /*&--diversity {
        transform: none;
    }*/
}

.angled-hero-slender-image {
	@include bg-cover;
	@include absolute-full;

	width: 100%;
	background-color: $color-brand-black;
	background-position: center -15px;
	background-repeat: no-repeat;
	transform:skewY($angled-polygon-slender-skew);
	bottom: -9vw;


	&:before {
		@include absolute-full;
		content: '';
		background-color: rgba(0,0,0,0.75);
	}
}

@include screen($bp-tablet) {
	.angled-hero-slender {
		padding-bottom: $section-spacing-mobile;
		background-position: right;
	}

	.angled-hero-content-slender {
		padding-bottom: 8vw;
	}
}


@include screen($bp-mobile) {
	.angled-hero-slender {
		padding-bottom: 0.5em;
	}
}

//Diversity

.angled-hero-content-slender--diversity {
    position: relative;
    padding-bottom: 7vw;
    margin-bottom: 0;
}

.angled-hero-slender-image--diversity {
	@include bg-cover;
	@include absolute-full;

	width: 100%;
	background-color: $color-brand-black;
	background-position: center -15px;
	background-repeat: no-repeat;
	transform:skewY($angled-polygon-slender-skew);
	bottom: -9vw;


	&:before {
		@include absolute-full;
		content: '';
	}
}

.angled-hero-slender-polygon--diversity {
    @include center(horizontal);
    top: $site-padding* -1.5;
    bottom: auto;
    width: px-to-em($ap-max-width);
    overflow: hidden;
    height: 100%;
    backface-visibility: hidden;
}
