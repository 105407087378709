.hover-grow-fade-fx, .hover-grow-fade-fx-diversity {
	position: relative;
	overflow: hidden;

 	> * { z-index: 5; }

	&:hover {
		.hover-grow-fade-fx-img {
			transform: $entity-tile-scale-hover;
		}
	}
}

.hover-grow-fade-fx-img {
	@include bg-cover;
	@include transition(transform);

	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	transition: transform 2s;
}

.article-tile-grid {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	margin: 0 calc($site-padding / -2);

	.article-tile {
			margin: 0 calc($site-padding / 2) $site-padding;
	}
}

.article-tile {
	min-height: px-to-em($entity-tile-height);
	background: $color-brand-black;
}

.article-tile-heading {
	height: 100%;
	display: block;
	position: relative;
	overflow: hidden;
	z-index: 5;
	display: flex;

	&.is-multimedia {
		@include ico-icon-after($icon-video-play) {
			@include transition(color);
			@include center(both);

			background-color:$color-opaque-black;
			color: $color-brand-gold;
			font-size: $icon-play-size--small;
			line-height: 3;
			text-align: center;
			display: block;
			z-index: 5;
			padding-left: 0.25em;
			width: px-to-em($play-icon-dimen--small,$icon-play-size--small);
			height: px-to-em($play-icon-dimen--small,$icon-play-size--small);
			border-radius: calc($play-icon-dimen--small/2);
			border: $border-white;
			clear: both;
			transition: $transition-default;
			opacity: 0.5;
		}

		&:hover {
			.video-highlight-img {
				transform: $entity-tile-scale-hover translateX(-40%);
			}

			&:after {
				opacity: 1;
			}
		}
	}
}

.article-tile-card {
	width: px-to-em(260px);
	max-width: 100%;
	min-height: 1px;
	margin: 1.8rem;
	padding: 1.5em;
	background: $color-brand-white;
	color: $color-brand-black;
	text-align: left;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	box-shadow: 4px 4px 6px 0px rgba(0,0,0,0.5);

	&__list {
		&-link {
			display: block;

			&:hover {
				text-decoration: underline;	
			}
			
			&:not(:last-child) {
				border-bottom: 1px solid;
				padding-bottom: px-to-em($element-spacing-px);
				margin-bottom: px-to-em($element-spacing-px);
			}
		}
	}
}

.article-tile-title {
	font-size: px-to-em(22px);
	font-weight: 200;
	line-height: 1.4;
	margin-bottom: 0.5em;
}

.article-tile-abstract {
	font-weight: 200;
	line-height: 1.5;
	flex-grow: 1;
}

.article-tile-details {
	margin-top: px-to-em(22px);

	&:before {
		content: "";
		display: block;
		width: 4em;
		height: 1px;
		margin-bottom: 1em;
		background: $color-brand-black; 
	}

	.article-tile-source, .article-tile-type {
		font-size: px-to-em(14px);
		margin-bottom: px-to-em(8px);
	}

	.article-tile-date {
		font-size: px-to-em(12px);
		display: inline-block;
	}

	.article-tile-location {
		display: inline;
		font-size: px-to-em(12px);

		&:before {
			content: "|";
    	margin-right: 4px;
		}
	}
}

@include screen($bp-desktop) {
	.article-tile {
		margin-left: auto;
		margin-right: auto;
	}
}

@include screen($bp-tablet) {
	.article-tile {
		margin-left: auto;
		margin-right: auto;
	}
}

@include screen($bp-mobile) {
	.article-tile {
		width: 100%;
	}
}

@include screen($bp-mobile-portrait) {
	.article-tile {
		height: auto;
		min-height: auto;
	}

	.article-tile-card {
		min-height: px-to-em(155px);
		width: 100%;
	}
}