﻿.global-search {
	position: absolute;
	top: $site-header-triangle-offset;
	left: $site-padding * 3.25;
	right: $site-padding;
	padding-right: px-to-em($hero-logo-width) + $site-padding;
	z-index: $zindex-site-search-panel;
	visibility: hidden;
	transition: visibility $transition-timing $transition-ease $transition-timing;

	.searchbox-input {
		padding-top: px-to-em(10px);
		padding-bottom: px-to-em(10px);
	}

	.searchbox-btn {
		right: 0;
		width: px-to-em(45px);

		&:before {
			font-size: px-to-em(18px);
		}
	}
}

.global-search-wrapper {
	background-color: $color-brand-black;
	padding:  $site-padding-mobile;
	width: 0;
	opacity: 0;
	overflow: hidden;
	transition: opacity $transition-timing $transition-ease ,
				width $transition-timing $transition-ease ;
}


.site-search-panel-searchbox {
	@include center(vertical, relative);
	top: 45%;

	.searchbox-input {
		@include center(vertical, relative);
	}
}

.show-searchbox {

	.global-search {
		visibility: visible;
		transition: visibility 100ms $transition-ease;

		

	}

	.global-search-wrapper{
		width: 100%;
		opacity: 1;
		transition: opacity $transition-timing $transition-ease $transition-timing,
					width $transition-timing $transition-ease $transition-timing;

        &--diversity {
            width: 93%;
        }
	}
}


.setup-fixed-header { 
	.global-search {
		top: 0;
		padding-right: px-to-em($site-logo-width * 1.25);
	}
}

@include screen($bp-hero-intro-tablet) {
	
	.global-search {
		 top: $site-header-triangle-offset-tablet;
		 padding-right:  px-to-em($hero-logo-width-tablet-mid) + $section-spacing-small;
	}
}

@include screen($bp-tablet) {
	.global-search {
		left: $site-padding-mobile * 4;

		// .headroom--not-top  ~ &{
		// 	right: px-to-em(200px);
		// }
	}

	.global-search-wrapper { 
		padding: calc($site-padding-mobile/2);
	}

	.setup-fixed-header { 
		.global-search {
			left: $site-padding-mobile * 2.5;
			padding-right: px-to-em($site-logo-width-mobile * 1.25);
		}

		.global-search-wrapper {
			margin-top: 0.25em;
		}
	}

}

@include screen($bp-mobile) {
	.global-search {
		@include transition(left);

		top: 0;
		left: 100%;
		right: 0;
		padding-right: 0;
		z-index: $zindex-site-header-utility - 1;
		overflow: hidden;
		visibility: visible;

		.searchbox-btn {
			opacity: 0;
			visibility: hidden;
		}
	}

	.global-search-wrapper{
		background-color: transparent;
		width: 100%;
		transition-delay: 0s;
		opacity: 1;

	}


	.setup-fixed-header { 

		.global-search {
		
			left: 100%;
			padding-right: 0;
		}

	}

	.show-searchbox {

		.global-search {
			@include transition(left);

			left: px-to-em(150px);
			z-index: $zindex-site-header-utility + 2;
		}

		.menu-toggle {
			opacity: 1;
		}

		.global-search-wrapper{
			transition-delay: 0s;
			
		}

		&.setup-fixed-header { 
			&:before {
				display: none;
			}
		}
	}


	.global-search-wrapper {
		padding-left: $site-padding-mobile;
		padding-right: $site-padding-mobile;
	}
}

@include screen($bp-mobile-portrait) {
	// .global-search {
	// 	left: $site-padding-mobile + 1.5em;
	// }	
}