﻿$border-input: 1px solid $color-border-list;

$form-input-padding: 15px;
$form-input-padding-sides: 14px;

$form-label-font-size: 18px;
$form-input-font-size-mobile: 20px;

$form-field-spacing: 30px;

fieldset {
	border: 0 none;
	padding: 0;
}

//default styling for input boxes;
input[type="text"],
input[type="email"],
input[type="number"],
textarea {
	border: $border-input;
	padding: px-to-em($form-input-padding) px-to-em($form-input-padding-sides);
	width: 100%;

	@include placeholder {
		color: $color-gray-dark;
		opacity: 1;
	}
}

//Namespacing general form
.form-content {
	textarea {
		height: px-to-em(300px);
	}

	.select-dropdown-toggle {
		border: $border-input;
		border-color: $border-list;
		background-color: $color-brand-white;
	}

	//Submit Button

	.form-submit {
		display: block;
		margin: calc($section-spacing/2) auto 0;
	}
}

//checkbox
$form-checkbox-width: px-to-em(30px);

.form-checkbox {
	display: block;
	position: relative;
    margin: .75em 0;
	padding-left: $form-checkbox-width + $element-spacing;


	&:before {
        position: absolute;
        top: 0;
		content: '';
		left: 0;
		width: $form-checkbox-width;
		height: $form-checkbox-width;
		border: $border-input;
	}

	&:before {
		border: $border-input;
	}

	.form-checkbox-label {
		@include ico-icon-after($icon-check-mark) {
            position: absolute;
            top: 6px;
			left: 5px;
			color: $color-brand-red;
			opacity: 0;
		}

		a {
			@include link-transition();
		}
	}	

	input[type="checkbox"] {
		&:checked {

			& + .form-checkbox-label {
				&:after {
					opacity: 1;	
				}

			}
		}
	}
}

.form-checkbox-label {
	font-family: $font-family-copy;
	line-height: $base-line-height;
	display: inline-block;
}

.form-captcha {
    margin-top: .75em;
}

.form-message-send {
	.professional-card {
		margin-top: px-to-em($form-field-spacing);
	}
}

.form-message-send-title {
	font-size: px-to-em(20px);
}
.form-validation-error {
}
.form-content .form-validation-error {
	border: 2px solid $color-brand-red;
	&+.form-checkbox-label{
		border: 2px solid $color-brand-red;	
	}
}

.form-ending-message {
	text-align: center;
	font-weight: bold;
	margin-bottom: px-to-em(40px);
	.error-message {
		color: $color-brand-red;
	}
}

.form-success h4 {
	text-align: center;
	font-weight: 800;
}

//Actions Fieldset
.form-actions {
	margin-bottom: px-to-em($form-field-spacing);
}
.form-actions--with-terms {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
}

.form-terms-conditions {
	position: relative;
	padding-left: px-to-em(30px);
}

//Grid
$form-grid-spacing: px-to-em(15px);
.form-layout--halfs {
	@include clearfix;

	margin: 0 $form-grid-spacing * -1;

	.form-field {
		float: left;
		width: 50%;
		padding: 0 $form-grid-spacing $form-grid-spacing * 2;

		&:nth-child(2n+1) {
			clear: left;
		}


		&.form-field--full {
			width: 100%;
		}

		&--hidden {
			display: none;
		}
	}
}

@include screen($bp-tablet) {
	$form-grid-spacing: px-to-em(10px);

	.form-layout--halfs {
		margin: 0 $form-grid-spacing * -1;

		.form-field {
			padding: 0 $form-grid-spacing $form-grid-spacing * 2;
		}
	}

	.form-message-send-title {
		font-size: 1em;
	}

	.form-actions--with-terms {
		display: block;

		.form-captcha{
			text-align: center;
		}

	}

	.form-terms-conditions {
		padding-left: 0;
		margin: px-to-em($form-field-spacing) auto 0;
	}

	.form-layout--halfs {
		
		margin: 0;

		.form-field {
			float: none;
			width: 100%;
			padding: 0 0 px-to-em(20px);
		}
	}
}