.contact-methods {

}

.contact-methods-list {
	display: flex;
	flex-wrap: wrap;
	margin-right: $grid-spacing * -1;
}

.contact-methods-option {
	margin-top: $grid-spacing;
	padding-right: $grid-spacing;
	width: 33.3333%;
	flex-grow: 1;
}

.contact-methods-link {
	display: block;
	min-width: inherit;
}


@include screen($bp-tablet) {
	.contact-methods-option {
		width: 50%;
		flex-grow: 0;
	}
}


@include screen($bp-mobile) {
	.contact-methods-list {
		display: block;
		margin-right: 0;
	}

	.contact-methods-option {
		padding-right: 0;
		width: 100%;
	}
}
