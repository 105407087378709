.social-links {
	margin: 0;
}

.social-links-item {
	display: inline-block;
	vertical-align: middle;
}

.social-links-icon {
	display: inline-block;
	color: $color-brand-white;
}

.social-links-icon {
	&:before {
		@include ico-icon();
	}
}