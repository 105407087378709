.block-links {
  max-width: 100%;
  position: relative;

  .block-link {
    display: block;
    position: relative;
    min-height: 350px;
  }

  .content {
    padding: 3%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .title {
    color: $color-brand-white;
  }

}
