.practice-leader-right {
  margin-top: px-to-em(60px);
}

.practice-leaders-list {
  .position {
    margin-bottom: 1em;
  }
}

.leader-name-bright {
  color: $color-brand-red;
  font-weight: bold;
}
