.article-intro {
	padding-bottom: $section-spacing--custom;
}

.article-intro-abstract {
	margin-bottom: $section-spacing-small;
}

.article-intro-authors {
	margin-bottom: 0;
}

.article-intro-authors,
.content-categories {
	@include font-size-copy-text;

	margin-top: px-to-em($element-spacing-px, $font-size-copy);
}

.article-intro-author {
	@include link-transition();

	font-weight: $font-weight-bold;


	&:after {
		content: ',';
		color: $color-text-copy;
	}

	&:last-child {
		&:after {
			content: '';	
		}
	}
}

@include screen($bp-tablet) {
	.article-intro {
		padding-bottom: $section-spacing-mobile;
	}
}