$video-highlight-offset: 55%;

.flexible-panel-overview  {
	position: relative;

	//if sliced box image exist, we want to offset module from the top to prevent overlap of skewed background
	&.flexible-panel-overview--image {
		margin-top: $section-spacing-module * 2;
	}

	.social-block + .grey-wedge-background & {
		margin-top: 0;
	}

	.section-header {
		margin-right: $video-highlight-offset;
	}
}

.flexible-panel-overview-content  {
	@include clearfix;

	.buttons {
		margin-top: calc($section-spacing / 2);
	}
}

.flexible-panel-overview-copy {
	margin-right: $video-highlight-offset;
}

.flexible-panel-overview-highlight {
	$video-highlight-width: $video-highlight-offset - 3%;
	width: $video-highlight-width;
	float: right;
	margin-top: px-to-em(-120px);
	position: relative;

	img {
		max-width: 100%;
	}
}

@include screen($bp-mobile) {
	.flexible-panel-overview  {

		&.flexible-panel-overview--image {
			margin-top: 0;
		}

		.section-header {
			margin-right: 0;
		}
	}

	.flexible-panel-overview-copy {
		margin-right: 0;
	}

	.flexible-panel-overview-highlight {
		float: none;
		margin: 0 auto;
		width: 70%;
		margin-bottom: $section-spacing-mobile;
	}

	.flexible-panel-overview-content {
		.buttons  {
			text-align: center;
			margin-top: $section-spacing-mobile;
		}
	}
}

@include screen($bp-mobile-portrait) {
	.flexible-panel-overview-highlight {
		width: auto;
		margin-left: $site-padding-mobile * -1.25;
		margin-right: $site-padding-mobile * -1.25;
	}

	.flexible-panel-overview-content {
		.btn-ghost {
			display: block;
			width: 100%;

			& + .btn-ghost { 
				margin-top: $section-spacing-small;
			}
		}
	}
}