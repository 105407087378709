$sticky-share-tools-width: px-to-em(275px);

.sticky-share {
	@include page-wrapper;

	position: relative;
}

.sticky-share-sections {
	.silent-wrapper {
		@include content-wrapper;
		
		margin-left: $sticky-share-tools-width;

		padding-left: 0;
		padding-right: 0;

		&--centered {
			margin-left: auto;	
		}
	}
}

@include screen($bp-desktop) {
	$sticky-share-tools-width: px-to-em(180px);

	.sticky-share-sections {

		.silent-wrapper {
			margin-left: $sticky-share-tools-width;

			&--centered {
				margin-left: auto;	
			}
		}
		
	}

}


@include screen($bp-tablet) {

	$sticky-share-tools-width: px-to-em(120px);

	.sticky-share-sections {
		

		.silent-wrapper {
			margin-left: $sticky-share-tools-width;
			padding-left: 0;
			padding-right: 0;

			&--centered {
				margin-left: auto;	
			}
		}

	}
}

@include screen($bp-mobile) {
	.sticky-share {

		.share-tools {
			margin: $section-spacing-module-mobile 0;
		}
	}
	.sticky-share-sections {


		.silent-wrapper {
			margin-left: 0;
		}
	}


}

.page-article-detail__forecast {
	$sticky-share-tools-width: px-to-em(90px);

	.sticky-share-sections {
		.silent-wrapper {
			@include page-wrapper;
			margin-left: $sticky-share-tools-width;
		}
	}		

	@include screen($bp-desktop) {
		$sticky-share-tools-width: px-to-em(70px);

		.sticky-share-sections {

			.silent-wrapper {
				margin-left: $sticky-share-tools-width;
			}
			
		}

	}


	@include screen($bp-tablet) {

		$sticky-share-tools-width: px-to-em(70px);

		.sticky-share-sections {			

			.silent-wrapper {
				margin-left: $sticky-share-tools-width;
			}

		}
	}		

	@include screen($bp-mobile) {
		.sticky-share {

			.share-tools {
				margin: $section-spacing-module-mobile 0;
			}
		}
		.sticky-share-sections {


			.silent-wrapper {
				margin-left: 0;
			}
		}


	}	
}

