$newsletter-carousel-padding: $element-spacing;


.newsletter-carousel {
	@include transition(min-height);

	position: relative;
	padding-bottom: $section-spacing-largest;
	//transform: skewY($angled-degree * -1);
	z-index: 16;
	$top-margin: 120px;
	margin-top: -1*$top-margin;

	//angled background
	// &:before {
	// 	@include center(horizontal);
	// 	content: '';
	// 	background-color: $color-brand-white;
	// 	position: absolute;
	// 	//transform:translate(-50%, 0) skewY(-8deg);
	// 	transform:translate(-50%, 0) skewY(8deg); 
	// 	width: 100%;
	// 	height: 20%;
	// 	z-index: -1;
	// 	bottom: 92%; 
	// 	overflow: hidden;
	// }
	.svg-mask-container{
		height: 100%;
		width: 100%;
		padding-bottom: 0;
	}
	.svg-mask-image{
		height: 100%;
		width: 100%;
		background-size: cover;		
		background-position: center; 
	}
}

.newsletter-carousel-wrapper {
	@include page-wrapper;
	@include clearfix;


	position: relative;
	padding-top: 2vw;
	//transform: skewY($angled-degree);
}


.newsletter-carousel-header {
	margin-right: $newsletter-carousel-issues-width;
	margin-left: $newsletter-carousel-figure-width;
	padding-left: $newsletter-carousel-padding;
}



.newsletter-carousel-issues-label {
	$heading-size: 24px;

	font-size: px-to-em($heading-size);
	margin-bottom: px-to-em($element-spacing-small-px, $heading-size);
}

.newsletter-carousel-issues-view-all {
	@extend %uc;

	display: block;
	margin-top: $section-spacing-large;
	min-width: auto;

	&:hover {
		color: $color-brand-red;
	}

	&.no-results {
		margin-top: auto;
	}
}

.newsletter-carousel-nav-item {
	padding: px-to-em(15px) 0;
	@include transition(transform);

	transform: translateZ(0);

	&:hover{
		transform:translateZ(0) scale(1.075);
	}
	&:hover,
	&.is-selected{	
		a{
			color: $color-text-white-hover;  
		}
	}

	& + & {
		border-top: 1px solid $color-brand-red;
	}
}

.newsletter-carousel-btn {
	$title-size: 18px;
	font-size: px-to-em($title-size);
	color: $color-brand-white;
}

.newsletter-carousel-belt {
	@include transition(height);

	margin-right: $newsletter-carousel-issues-width;
	padding-left: $newsletter-carousel-figure-width;
}

@include screen($bp-desktop) {
	.newsletter-carousel-issues-view-all {
		margin-top: $section-spacing-large;
	}
}

@include screen($bp-newsletter-carousel-tablet) {


	.newsletter-carousel {
		padding-bottom: 0;
		margin-bottom: $section-spacing-largest;

		&:before {
			height: 10vw;
		}
	}

	.newsletter-carousel-wrapper {
		padding-left: 0;
		padding-right: 0;
	}

	.newsletter-carousel-belt {
		margin-top: -50%; // Tablet layout hack
		padding-left: 0;
		margin-right: 0;
		width: $newsletter-carousel-slide-width;
	}

	.newsletter-carousel-issues {
		width: 	 $newsletter-carousel-issues-width-tablet;
		//margin-top: $newsletter-carousel-slide-figure-height * -1;
		margin-top: 60% * -1;
		padding-left: 5.5%;
		padding-right: 2.5%;

		&:after {
			width: $newsletter-carousel-issues-width-tablet;
			height: 110%;
		}
	}

	.newsletter-carousel-header {
		width: $newsletter-carousel-slide-width;
		margin-right:0;
		margin-left: 0;
		padding-top: $newsletter-carousel-slide-figure-height;
		
		// Tablet layout hack
		pointer-events: none;
		.section-header-title {
			position: absolute;
			top: 50px;
		}
	}
}


@include screen($bp-tablet) {
	.newsletter-carousel{
		$top-margin: 100px;
		margin-top: -1*$top-margin;
	}
	.newsletter-carousel-issues-label {
		$heading-size: 20px;

		font-size: px-to-em($heading-size);
	}

	.newsletter-carousel-btn {
		font-size: 1em;
	}

	.newsletter-carousel-issues-label {

	}

}

@include screen($bp-mobile) {
	.newsletter-carousel {
		overflow: hidden;
		padding-top: 10%;
		margin-bottom: $section-spacing-large;
		$top-margin: 25vw;
		margin-top: -1*$top-margin;

		&:before {
			display: none;
		}
	}
	.newsletter-carousel-wrapper {
		padding:0 $site-padding-mobile ($section-spacing-mobile * 3.5);
	}
	.newsletter-carousel-header {
		padding-left: 0;
		padding-top: 0;
		width: auto;
		margin-right: auto;
		margin-bottom: 4em;
		overflow: visible;
		.section-header-title{
			position: static; // Undo absolute position from tablet layout hack
			color: $color-brand-white;
			font-weight: normal;
			display: block;
			text-align: center;
			&:after{
				//display: none;
				width: 1px;
				height: 50px;
				background-color: #A40F2C;
				position: absolute;
				left: 50%;
				top: 20px;
				margin-left: 0;
			}
		}
	}

	.newsletter-carousel-issues {
		float: none;
		width: auto;
		padding: 0;
		position: static;
		text-align: center;
		margin-top: 0;

		&:after {
			display: none;
		}
	}

	.newsletter-carousel-issues-label {
		display: none;
	}


	.newsletter-carousel-nav {
		@include center(horizontal);

		max-width: 75%;
		bottom: $section-spacing-large;
		z-index: 5;
	}

	.newsletter-carousel-nav-item {
		display: inline;
		margin-left: 0.5em;
		margin-right: 0.5em;
		text-align: left;
		
		& + & {
			margin-left: 0.5em;
			border-top: 0 none;
		}
	}

	.newsletter-carousel-btn {
		$circle-dimen: 15px;
		display: inline-block;
		overflow: hidden;
		text-indent: 200%;
		width: px-to-em($circle-dimen);
		height: px-to-em($circle-dimen);
		border: 1px solid $color-brand-red;
		border-radius: px-to-em(calc($circle-dimen/2));

		.is-selected & {
			background-color: $color-brand-red;
		}
	}

	.newsletter-carousel-issues-view-all {

		position: absolute;
		left: $site-padding-mobile;
		right: $site-padding-mobile;
		bottom: 0;
		display: inline-block;
		margin-top: 0;
		color: $color-brand-red;
		border-color: $color-brand-red;

	}

	.newsletter-carousel-belt {
		margin-top: auto; // Undo margin from tablet layout hack
		width: auto;
	}
}

@include screen($bp-mobile-portrait) {

}

.edge--top {
  @include angle(before, false,6deg);
	@include screen($bp-mobile) {
		position: static;
		&:before{
			display: none;
		}
	}
}

