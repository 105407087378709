// tips taken from css tricks
// http://codepen.io/yoksel/pen/fsdbu
$skew-degree-mask: 10deg;

.css-image-mask-link {
	&:hover {
		.css-image-mask-skew {
			&:after {

				background-color: $color-entity-tile-dim-hover;
			}
		}

		.css-image-mask-snapshot {
			transform: skewY($skew-degree-mask) $entity-tile-scale-hover;
		}
	}
}

.css-image-mask {
	$triangle-width: px-to-em(45px);
	position: relative;
	height: 0;
	padding-bottom: 120%;
	width: 100%;
	display: block;
	overflow: hidden;

	&:after {
		content: '';
		position: absolute;
		bottom: -1em;
		left: 0;
		display: block;
		border-bottom:$triangle-width solid $color-brand-white;
		border-right:$triangle-width*4 solid transparent;
		z-index: 2;
	}
}


.css-image-mask-skew {
	$angle-edge-offset: 8%;
	
	position: absolute;
	left: 0;
	right: 0;
	top: $angle-edge-offset;
	bottom: calc($angle-edge-offset/1.3);
	overflow: hidden;
	transform: skewY($skew-degree-mask * -1);
	
	&:after {
		
		@include transition(background-color);
		@include absolute-full;

		content: '';
    	background-color:  $color-entity-tile-dim;
	}
}

.css-image-mask-snapshot {
	position: absolute;
	top: -2em;
	left: 0;
	min-height: 120%;
	transform: skewY($skew-degree-mask);
	transition: transform 2s;
}
