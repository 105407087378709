.video-highlight {
	position: relative;
	overflow: hidden;
	cursor: pointer;

	&:before {
		@include absolute-full;

		content: '';
		display: block;
		background-color:  rgba(0,0,0, .5);
		z-index: 2;
	}

	&.newsletter-carousel-issue-figure {
		position: absolute;
	}

	@include ico-icon-after($icon-video-play) {
		@include transition(color);
		@include center(both);

		background-color:$color-opaque-black;
		color: $color-brand-gold;
		font-size: px-to-em($icon-play-size);
		line-height: 3;
		text-align: center;
		display: block;
		z-index: 5;
		padding-left: 0.25em;
		width: px-to-em($play-icon-dimen,$icon-play-size);
		height: px-to-em($play-icon-dimen,$icon-play-size);
		border-radius: calc($play-icon-dimen/2);
		border: $border-white;
		clear: both;
		transition: $transition-default;
	}

	&:hover {
		.video-highlight-img {
			transform: $entity-tile-scale-hover translateX(-40%);
		}

		&:after {
			color: $color-brand-white;
		}
	}
}

.video-highlight-img {
	@include center(horizontal, relative);

	z-index: 1;
	display: block;
	transition: transform 2s;
}

@include screen($bp-mobile) {
	.video-highlight {

		&:after {
			font-size: px-to-em($icon-play-size-mobile);
			width: px-to-em($play-icon-dimen-mobile,$icon-play-size-mobile);
			height: px-to-em($play-icon-dimen-mobile,$icon-play-size-mobile);
			border-radius: calc($play-icon-dimen-mobile/2);
			line-height: 2.75;
		}
	}
}
