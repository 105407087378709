﻿/*! Flickity v2.0.9
http://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
    position: relative;
}

.flickity-enabled:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
}

.flickity-viewport {
    overflow: hidden;
    position: relative;
    height: 100%;
}

.flickity-slider {
    position: absolute;
    width: 100%;
    height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
    position: absolute;
    top: 50%;
    width: 44px;
    height: 44px;
    border: none;
    border-radius: 50%;
    background: white;
    background: hsla(0, 0%, 100%, 0.75);
    cursor: pointer;
    /* vertically center */
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.flickity-prev-next-button {

    &:focus,
    &:hover {
        background: white;
        outline: none;
    }
}

.flickity-prev-next-button:active {
    opacity: 0.6;
}

.flickity-prev-next-button.previous {
    left: 10px;
}

.flickity-prev-next-button.next {
    right: 10px;
}
/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
    left: auto;
    right: 10px;
}

.flickity-rtl .flickity-prev-next-button.next {
    right: auto;
    left: 10px;
}

.flickity-prev-next-button:disabled {
    opacity: 0.3;
    cursor: auto;
}

.flickity-prev-next-button svg {
    position: absolute;
    left: 20%;
    top: 20%;
    width: 60%;
    height: 60%;
}

.flickity-prev-next-button .arrow {
    fill: #333;
}

/* ---- page dots ---- */

.flickity-page-dots {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    line-height: 1;
    list-style: none;
    margin: 0 auto;
    padding: rem-calc(0 30);
    right: 0;
    text-align: center;
}

.flickity-rtl .flickity-page-dots {
    direction: rtl;
}

.flickity-page-dots .dot {
    border: 2px solid palette('dark_grey');
    display: inline-block;
    width: 14px;
    height: 14px;
    margin: 0 8px;
    background: transparent;
    border-radius: 50%;
    opacity: 1;
    cursor: pointer;

    @include media-breakpoint-up(lg) {
        width: 20px;
        height: 20px;
    }
}

.flickity-page-dots .dot.is-selected {
    background: palette('dark_grey');
    border-color: palette('dark_grey');
}
