.winrs {
    $fadein: opacity 2s, visibility 2s;
    $slide: transform 1s ease-in-out;
    $section-padding: px-to-em(70px);
    $section-padding-lg: px-to-em(80px);

    //GLOBAL
    &__logo {
        position: absolute;
        right: .5em;
        top: 1.2em;
        width: 40%;
    }
    &__orange{
        color: $diversity-orange;
    }
    &__orange-background{
        border-color: $diversity-orange !important;
        & .is-selected{
            background: $diversity-orange !important;
        }
    }
    &__green{
        color: $diversity-green;
    }
    &__green-background{
        border-color: $diversity-green !important;
        & .is-selected{
            background: $diversity-green !important;
        }
    }
    &__button {
        @extend %uc;
        display: inline-block;
        font-weight: $font-weight-bold;
        font-size: $font-size-h6-mobile;
        width: 100%;
        padding: px-to-em(15px) 1em;
        border-radius: 0;
        overflow: hidden;
        position: relative;
        text-align: center;
        color: $color-brand-white;
        transition: all ease-in-out .15s;
        cursor: pointer;
        margin-bottom: px-to-em(10px);

        &:last-child {
            margin-bottom: 0;
        }

        &:hover {

            &:before {
                transform: skew(-25deg) translate(75%,-50%);
            }
        }

        &:before {
            @include transition(transform);
            content: '';
            display: block;
            width: 200%;
            height: 200%;
            position: absolute;
            right: 110%;
            top: 50%;
            transform: skew(-25deg) translate(-5%,-50%);
            transition-duration: 0.5s;
            z-index: 1;
        }

        &--blue {
            background-color: $diversity-blue;
            margin-bottom: 0;

            &:before {
                background-color: #0494de;
            }
        }

        &--orange {
            background-color: $diversity-orange;
            margin-bottom: 0;

            &:before {
                background-color: rgb(223, 179, 59);
            }
        }

        &--hero {
            border: 1px solid $diversity-lime;

            @include button-transition-hero();
        }

        &--detail {
            width: auto;
            min-width: 20em;
        }

        &--stats {
            margin-top: 20px;

            &:first-of-type {
                margin-top: 40px;
            }
        }

        &--video-icon {
            @include ico-icon-after($icon-video-play) {
                $icon-size: 10px;

                @include center(vertical);
                font-size: px-to-em($icon-size);
                right: 1.5em;
            }
        }

        &--centered {
            &:only-child {
                margin: auto;
            }
        }
    }
    //CAROUSEL
    &__carousel-backing {
        background-color: $diversity-light-gray;
        width: 100%;
        height: 15.5em;
    }
    //QUOTES SECTION
    &__quote-section {
        background-color: $diversity-light-gray;
        width: 100%;
        padding: $section-padding 10%;
        overflow-x: hidden;
    }

    &__quote-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__quote {
        @include font-open-sans-regular($font-size-h4-mobile);
        @include transition(font-size);
        color: $diversity-dark-gray;
        line-height: $font-size-h3-mobile;
        text-align: center;
        padding-bottom: px-to-em(30px);
        opacity: 0;
        visibility: hidden;
        transition: $fadein;

        &--visible {
            opacity: 1;
            visibility: visible;
        }
    }

    &__source {
        @include font-open-sans-regular($font-size-h5-mobile);
        @include transition(font-size);
        color: $diversity-dark-gray;
        font-style: italic;
        text-align: center;
        opacity: 0;
        visibility: hidden;
        transition: $fadein;

        &--visible {
            opacity: 1;
            visibility: visible;
        }
    }

    &__quote-bar {
        display: block;
        height: 3px;
        width: px-to-em(50px);
        margin-bottom: px-to-em(30px);

        &:last-child {
            margin-top: px-to-em(30px);
            margin-bottom: 0;
        }

        &--green {
            background-color: $diversity-green;
        }

        &--orange {
            background-color: $diversity-orange;
        }

        &--top {
            transform: translateX(-30em);
            transition: $slide;
        }

        &--bottom {
            transform: translateX(30em);
            transition: $slide;
        }

        &--slide {
            transform: translateX(0);
        }
    }
    //STATS
    &__stat-section {
        background-color: $color-brand-white;
        width: 100%;
        padding: $section-padding 0;
        padding-top: 3em;
        overflow-x: hidden;
    }

    &__stat-container {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        padding-bottom: px-to-em(20px);

        &:last-child {
            padding-bottom: 0;
        }
    }

    &__stat-lines {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__stat-line {
        height: 1px;
        background-color: $diversity-orange;
        width: 60px;
        transform: translateX(-300px);
        transition: $slide;

        &--visible {
            transform: translateX(0);
        }
    }

    &__stat-number {
        @include font-open-sans-light($font-quote-section-number);
        @include transition(font-size);
        color: $diversity-orange;
        padding-left: 30px;
    }

    &__stat-tagline {
        @include font-open-sans-regular($font-size-h5-mobile);
        @include transition(font-size);
        color: $diversity-dark-gray;
        line-height: $font-line-height-3;
        padding-left: 30px;
        opacity: 0;
        visibility: hidden;
        transition: $fadein;

        &--visible {
            opacity: 1;
            visibility: visible;
        }
    }

    &__stats-btn-container {
        display: flex;
        flex-direction: column;
        padding: 0 8%;
    }
    //AFFINITY GROUPS
    &__affinity-groups-section {
        width: 100%;
        padding: $section-padding 5%;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }

    &__affinity-groups-title {
        @include font-open-sans-bold($font-size-h4-mobile);
        @include transition(font-size);
        color: $color-brand-white;
        text-align: center;
        padding-bottom: px-to-em(15px);
    }

    &__affinity-groups-subtitle {
        @include font-open-sans-light($font-size-h3);
        @include transition(font-size);
        color: $color-brand-white;
        text-align: center;
        padding-bottom: px-to-em(15px);
    }

    &__affinity-groups-body {
        @include font-open-sans-regular($font-size-h4-mobile);
        @include transition(font-size);
        color: $color-brand-white;
        line-height: $font-size-h3-mobile;
        text-align: center;
        padding-bottom: px-to-em(30px);
    }

    &__affinity-groups-container {
        display: block;
        padding: 0 5%;

        &--hidden {
            display: none;
        }
    }

    &__affinity-groups-btn {
        display: inline-block;
        font-weight: $font-weight-bold;
        font-size: $font-size-h6-mobile;
        width: 100%;
        padding: px-to-em(15px) 1em;
        border-radius: 0;
        border: none;
        overflow: hidden;
        position: relative;
        text-align: center;
        background-color: $color-brand-white;
        color: $diversity-orange;
        transition: all ease-in-out .15s;
        cursor: pointer;
        margin-bottom: px-to-em(10px);

        &:hover {
            color: $color-brand-white;
            border: none;

            &:before {
                transform: skew(-25deg) translate(75%,-50%);
            }
        }

        &:before {
            @include transition(transform);
            content: '';
            display: block;
            width: 200%;
            height: 200%;
            position: absolute;
            right: 110%;
            top: 50%;
            background-color: $diversity-orange;
            transform: skew(-25deg) translate(-5%,-50%);
            transition-duration: 0.5s;
            border: none;
            z-index: 1;
        }
    }
    //MODAL
    &__modal {
        z-index: 1;
        width: 100%;
        overflow: auto;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s, visibility 0.3s;

        &--visible {
            opacity: 1;
            visibility: visible;
            background-color: rgba(0,0,0,0.5);
            border: 1px solid $color-brand-white;
        }
    }

    &__modal__content {
        padding: px-to-em(20px);
        padding-bottom: px-to-em(30px);
    }

    &__modal-contact {
        @include font-open-sans-regular($font-size-h4-mobile);
        @include transition(font-size);
        color: $color-brand-white;
        text-align: center;

        &--link {
            @include font-open-sans-bold($font-size-h4-mobile);
            @include transition(font-size);
            color: $diversity-orange;
            transition: all ease-in-out .15s;
            padding-left: px-to-em(5px);

            &:hover {
                color: $diversity-lime;
            }

            &:not(:last-child) {
                &::after {
                    content: ",";
                    padding-right: 0.5rem;
                }
            }
        }
    }

    &__modal__close {
        margin: 0 0 px-to-em(5px) 90%;
    }

    &__modal__close__icon {
        width: 75%;
        height: 75%;
    }

    &__modal-title {
        @include font-open-sans-bold($font-size-h4-mobile);
        @include transition(font-size);
        color: $color-brand-white;
        text-align: center;
        padding-bottom: px-to-em(15px);
    }

    &__modal-body {
        @include font-open-sans-regular($font-size-h6-mobile);
        @include transition(font-size);
        color: $color-brand-white;
        line-height: $font-size-h3-mobile;
        text-align: center;
        padding-bottom: px-to-em(15px);
    }

    &__modal-contact {
        padding-bottom: px-to-em(10px);
    }

    &__modal-contact-title {
        @include font-open-sans-regular($font-size-h6-mobile);
        @include transition(font-size);
        line-height: $font-size-h5;
        color: $color-brand-white;
        text-align: center;
    }
    //GLOBAL
    &__global-section {
        background-color: $color-brand-white;
        width: 100%;
        padding: $section-padding 10%;
    }

    &__global-title {
        @include font-open-sans-bold($font-size-h4-mobile);
        @include transition(font-size);
        color: $diversity-fuschia;
        text-align: center;
        padding-bottom: px-to-em(15px);

        &--orange {
            color: $diversity-orange;
        }
    }
    //CONNECT
    &__connect-section {
        background-color: $diversity-light-gray;
        width: 100%;
        padding: px-to-em(30px) 9%;
        display: flex;
        flex-direction: row;
        align-items: baseline;
    }

    &__connect-title {
        @include font-open-sans-light($font-size-h4-mobile);
        @include transition(font-size);
        color: $diversity-medium-gray;
        padding-right: px-to-em(15px);
    }

    &__connect-icon-link {
        padding-right: px-to-em(8px);
        transition: opacity 0.2s ease-in-out;

        &:hover {
            opacity: 0.7;
        }

        &:before {
            font-family: 'reedsmith';
        }

        &--orange {
            &:before {
                color: $diversity-orange;
            }
        }
    }
    //FORWARD THINKING
    &__forward-thinking-title {
        @include font-open-sans-bold($font-size-h4-mobile);
        @include transition(font-size);
        color: $color-brand-white;
        text-align: center;
        padding-bottom: px-to-em(15px);
    }

    &__forward-thinking-body {
        @include font-open-sans-regular($font-size-h4-mobile);
        @include transition(font-size);
        color: $color-brand-white;
        line-height: $font-size-h3-mobile;
        text-align: center;
        padding-bottom: 2.5em;
    }
    //GRID BOXES
    &__grid{
        $grid-spacing: px-to-em(30px);
        padding: $grid-spacing 0;
        background-color: $diversity-light-gray;
        &__container{
            max-width: 1100px;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
        }
    }

    &__box{
        width: 100%;
        display: flex;
        padding: 1.5em;
        &__content{
            display: flex;
            flex-direction: column;
            font-size: $font-size-copy;
            background: $color-brand-white;
            color: $diversity-orange;
            padding: 2em;
            width: 100%;
            border: 3px solid $diversity-orange;
            @include transition(all);

            &:hover{
                background: $diversity-orange;
                color: $color-brand-white;
                .diversity__box__body{
                    color: $color-brand-white;
                }
                .diversity__box__line{
                    background: $color-brand-white;
                }
            }
        }
        &__header{
            font-size: 2em;
            font-weight: $font-weight-semibold;
            margin: 0;
        }
        &__line{
            width: 100px;
            height: 3px;
            margin: 1em;
            background: $diversity-orange;
            @include transition(background);
        }
        &__body{
            font-size: 0.8em;
            line-height: 1.6em;
            margin-top: auto;
            color: #3d3c3c;
            @include transition(color);
        }
    }
    @include screen($bp-mobile, "min") {

        &__button {
            margin-bottom: 0;

            &--hero {
                margin: 0 px-to-em(15px) px-to-em(15px);
                width: calc(50% - 30px);
                flex-grow: 1;
            }

            &--blue {
                width: 50%;

                &:not(:last-child) {
                    margin-right: 10px;
                }
            }

            &--orange {
                width: 50%;

                &:not(:last-child) {
                    margin-right: 10px;
                }
            }

            &--stats {
                margin-top: 40px;

                &:first-of-type {
                    margin-top: 40px;
                }
            }
        }

        &__logo {
            right: 1.25em;
            top: 1.75em;
            width: 27%;
        }

        &__carousel-backing {
            height: 16.5em;
        }

        &__stats-btn-container {
            flex-direction: row;
        }

        &__modal__content {
            padding: px-to-em(20px) px-to-em(60px) px-to-em(70px);
        }
    }

    @include screen($bp-between-tablet, "min") {

        &__box{
            width: 50%;
            min-height: 400px;
        }
        &__carousel-backing {
            height: 20em;
        }

        &__quote-section {
            padding: $section-padding 13%;
        }

        &__stat-line {
            width: 130px;
        }

        &__stat-number {
            @include font-open-sans-light(65px);
        }

        &__stat-tagline {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        &__stats-btn-container {
            padding: 0 8%;
        }

        &__affinity-groups-section {
            padding: $section-padding 13%;
        }

        &__modal__content {
            padding: 1.25em 4.5em 4.375em;
        }

        &__modal-title {
            padding-bottom: px-to-em(30px);
        }

        &__modal-body {
            padding-bottom: px-to-em(30px);
        }

        &__global-section {
            padding: $section-padding 13%;
        }

        &__connect-section {
            padding: px-to-em(30px) 15%;
        }

        &__forward-thinking-body {
            width: 80%;
            margin: 0 auto;
        }
    }

    @include screen($bp-desktop, "min") {

        &__button {
            &--stats {
                margin-top: $section-padding;

                &:first-of-type {
                    margin-top: $section-padding;
                }
            }
        }

        &__logo {
            right: 2.5em;
            top: 2em;
            width: 20%;
        }

        &__carousel-backing {
            height: 17.5em;
        }

        &__quote-section {
            padding: $section-padding-lg 20%;

            &--first {
                padding: 0 20% $section-padding-lg;
            }
        }

        &__quote {
            @include font-open-sans-regular($font-size-subheader);
            line-height: 42px;
            padding-bottom: 30px;
            width: 100%;
        }

        &__stat-section {
            padding: $section-padding-lg 0;
        }

        &__stat-line {
            width: 260px;
        }

        &__stat-number {
            @include font-open-sans-light(122px);
        }

        &__stat-tagline {
            @include font-open-sans-regular($font-size-h4);
        }

        &__stats-btn-container {
            padding: 0;
            margin: 0 20%;
            margin-right: 16%;
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        &__affinity-groups-section {
            padding: $section-padding-lg 20%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            background-attachment: fixed;
        }

        &__affinity-groups-container {
            padding: 0;
        }

        &__affinity-groups-title {
            @include font-open-sans-bold($font-size-h2-mobile);
        }

        &__affinity-groups-subtitle {
            @include font-open-sans-light($font-size-h1);
        }

        &__affinity-groups-body {
            @include font-open-sans-regular($font-size-h4);
            line-height: 34px;
        }

        &__affinity-groups-btn-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
        }

        &__affinity-groups-btn {
            width: 30%;
            margin: 0 12px 25px;
        }

        &__modal-title {
            @include font-open-sans-bold($font-size-h2-mobile);
        }

        &__modal-body {
            @include font-open-sans-regular($font-size-h4);
            line-height: 34px;
        }

        &__modal__close {
            margin: 0 0 0.3125em 96%;
        }

        &__modal-contact {
            font-size: 24px;

            &--link {
                font-size: 24px;
            }
        }

        &__modal-contact-title {
            @include font-open-sans-regular($font-size-h4-mobile);
        }

        &__global-section {
            padding: 6.125em 10%;
        }

        &__global-title {
            @include font-open-sans-bold($font-size-h2-mobile);
        }

        &__connect-section {
            padding: 65px 20%;
        }

        &__connect-title {
            @include font-open-sans-light($font-size-h2-mobile);
        }

        &__connect-icon-link {
            font-size: 1.85em;
            padding-right: 1em;
        }

        &__forward-thinking-title {
            @include font-open-sans-bold($font-size-h2-mobile);
            text-align: left;
        }

        &__forward-thinking-body {
            @include font-open-sans-regular($font-size-h4);
            line-height: 34px;
            text-align: left;
            margin: 0;
            white-space: normal;
            width: 100%;
        }
    }

    @include screen($bp-desktop, "max") {
        &__forward-thinking-title {
            padding-top: 40px;
        }
    }
}
