﻿$searchbox-padding: $element-spacing-small;

.searchbox {
	position: relative;
	background-color: $color-brand-white;
	border: 1px solid $color-black;
}

.searchbox-input[type="textbox"] {
	border-color: transparent;
	line-height: 1.1em;
	width: 100%;
}

.searchbox-btn {
	position: absolute;
	right: $searchbox-padding;
	top: 0;
	height: 100%;
	color: $color-brand-red;

	@include ico-icon-before($icon-search) {

	}
}



@include screen($bp-mobile) {
	.searchbox-input {
		$title-size: 18px;
		font-size: px-to-em($title-size);

	}

}