.regional-business{
    .rte{
        max-width: 800px;
        text-align: center;
        margin: 0 auto $section-spacing-large-mobile;
    }
    $header-width: 50%;
    $item-padding: 10%;
    .section-header--right-aligned{
        width: $header-width;
        margin-left: (100% - $header-width);
    }
    .regional-business-listing{
        clear: both;
        display: flex;
        flex-flow: row wrap;
        margin-top: $section-spacing;
        //min-height: 40vw;
        min-height: 24vw;
    }
    $padding: 2%;
    .business-item{
        width: 33.333%;
        padding: 5px $padding;
        position: relative;
        &:nth-child(3n+2){
            padding: 5px calc($padding/2);
        }
        &:nth-child(3n+3){
            padding-right: 0;
        }
        &:nth-child(3n+1){
            padding-left: 0;
        }
        &.has-clock{
            .clock {
                top: 25%;
                transform: translateX(-50%);
            }
            .business-name{
                display: inline-block;
                width: (100% - $item-padding * 2 - $padding * 2);
                text-align: center;
            }
        }
    }
    img{
        position: relative;
    }
    .business-name{
        @include font-open-sans-extra-bold(28px);
        position: absolute;
        bottom: 20%;
        left: $item-padding;
        right: $item-padding;
        color: $color-brand-white;
    }

    @include screen($bp-tablet) {
        .business-name{
            font-size: px-to-em(22px);

            &.has-clock{
                .clock {
                    top: 20%;
                }
            }
        }
    }
    @include screen($bp-tablet-landscape){
        margin-top: 0;
    }

    @include screen($bp-mobile){

        .business-item{
            width: 50%;
            padding: 0 $padding;
            &:nth-child(even){
                padding-right: 0;
                padding-left: $padding;
            }
            &:nth-child(odd){
                padding-left: 0;
                padding-right: $padding;
            }
        }
    }

    @include screen($bp-mobile-landscape){
        margin-top: $section-spacing-large;
        .business-item{
            width: 100%;
            max-width: px-to-em(300px);
            margin-left: auto;
            margin-right: auto;
            padding: 0;

            &:nth-child(even){
                padding: 0;
            }
            &:nth-child(odd){
                padding: 0;
            }
        }
    }
}

.regional-business-control {
    position: relative;
    display: flex;
    align-items: center;

    .section-header {
        margin-right: 1em;
        margin-bottom: 0;
        flex-shrink: 0;
        background: $color-brand-white;
    }

    .tab-filters {
        flex-grow: 1;
    }

    &:before {
        @include center(vertical);

        content: '';
        display: block;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #777;
        z-index: -1;
    }   
}

@include screen($bp-desktop) {

    .regional-business-control {
        display: block;

        &:before {
            content: initial;
            display: none;
        }

        .section-header {
            margin-right: 0;
            background-color: transparent;
            margin-bottom: px-to-em($section-header-spacing);
            text-align: center;
        }

        .section-header-title {

            //gray line
            &:before {
                @include center(vertical);

                content: '';
                display: block;
                right: 100%;
                margin-right: $site-padding-mobile;
                width: px-to-em(1000px);
                height: 1px;
                background-color: #777;
            }
        }
    }

}
