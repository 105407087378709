﻿$filters-spacing: px-to-em(12px);

.advanced-filters  {
	position: relative;
	margin-top: $filters-spacing;
	text-align: left;
}

.advanced-filters-categories {
	@include clearfix;
}

.advanced-filters-trigger {
	$title-size: 19px;

	@include font-open-sans-extra-bold($title-size);
	@include link-transition($color-brand-gold, $color-brand-white);

	color: $color-brand-gold;
	padding-left: 0.75em;

	display: none;

	 &:after {
        content: '+';
        display: inline-block;
        position: absolute;
        left: 0;
        text-align: left;
    }

    &.is-expanded {
    	&:after {
			content: '–';
		}
    }

}

//need to increase this percentage based on the number of filters present
// AH - 4/14/2017 - changed width from 100/6 to 100/5 for temporary removal of topic filter
.advanced-filters-category {
	float: left;
	width: 20%; //based on the filters present for the perspectives landing page, adjust on a per template basis
	// width: 100/5 + %; //based on the filters present for the perspectives landing page, adjust on a per template basis
	
	padding-right: $filters-spacing;

    &--careers {
        width: 50%;
    }
}


@include screen($bp-tablet) {

	.advanced-filters-trigger {
		display: inline-block;
		margin-top: px-to-em(6px);
		padding-bottom: px-to-em(12px);

		&:before {
			bottom: px-to-em(10px);
		}

		 &.is-expanded {
		 	& + .advanced-filters-categories{
		 		 max-height: px-to-em(550px);
		 	}
		 }
	}

	.advanced-filters-categories {
		@include transition(max-height);

		margin-top: $filters-spacing;
	    max-height: 0;
	    overflow: hidden;

        &--careers {
            max-height: none;
            margin-top: 0;
        }
	}

	.advanced-filters-category {
		width: 50%;
		padding-top: $filters-spacing;
	}
	
	.filters-show {
		display: block;
	}

}

@include screen($bp-mobile) {
	.advanced-filters  {
		position: relative;
	}

	.advanced-filters-categories {
		
		margin-right: 0;
	}

	.advanced-filters-category {
		width: 100%;
		float: none;
		padding-right: 0;
	}
}
