$scrollspy-nav-width: px-to-em(90px);
$scrollspy-nav-width-with-label: px-to-em(48px);
$scrollspy-nav-notch: 15px;
$scrollspy-nav-notch-offset: 25px;

$scrollspy-nav-sticky-offset: $site-header-height + 20;

.scrollspy-nav {
	$marginTop: 10em;
	width: 100vw;
	background-color: $color-brand-black;
	left: 0;
	margin-top: -1*$marginTop;
	text-align: left;
	position: absolute;
	z-index: $zindex-scrollspy;
	&.is-sticky{
		position: fixed;
		top: $marginTop;
	}		

	&.is-sticky-bottom {
		bottom: 0;
	}
}

.scrollspy-nav-column {
	@include page-wrapper;

	position: relative;
	margin-top: 0 !important;
	// Pad like site-header-wrapper
	padding-left: $site-padding * 2;
}

.scrollspy-nav-list {
	white-space: nowrap;
	display: inline-block;
	position: relative;
	max-width: px-to-em($scrollspy-nav-notch);
	// overflow: hidden;
	padding: 0.5em 0;
	margin-top: px-to-em(5px);
	overflow: visible;
	@include transition(max-width);

	&:hover,
	&.is-exposed {
		max-width: px-to-em(500px);
	}
}

.scrollspy-nav-option {
	position: relative;
	display: inline-block;
//	.scrollspy-nav-option{

	& + & {
		.scrollspy-nav-label {
			border-top: $border-white;
		}
	}

	&:last-child {
		.scrollspy-nav-link {
			&:before {
				height: 103%;
				border-bottom-width: 1px;
			}
		}
	}

	&.is-inview {

		.scrollspy-nav-link {
			color: $color-brand-white;

			&:before {
				background-color: $color-brand-red;
			}
		}

		.scrollspy-nav-label {
			background-color: transparent;
			color: $color-brand-white;
		}
	}
	.scrollspy-nav-link{
		&:before{
			display: none;
		}
	}

}


.scrollspy-nav-link {
	@extend %uc;
	
	display: block;
	padding-left: px-to-em($scrollspy-nav-notch-offset);
	color: rgba(255, 255, 255, 0.5);
	text-align: left;

	&:hover {
		color: $color-brand-white;

		&:before {
			background-color: $color-brand-red;
		}
	}

	//notches

	&:before {
		@include transition(background-color);

		content: '';
		position: absolute;
		left:0;
		top: 0;
		width: px-to-em($scrollspy-nav-notch);
		height: 100%;
		background-color: $color-brand-black;
		border: $border-white;
		border-width: 1px 1px 0;
		z-index: 2;
		transform: skewY($angled-degree * -1);
	}
}

.scrollspy-nav-label {
	$title-size: 16px;

	@include font-open-sans-extra-bold($title-size);

	background-color: $color-brand-black;
	display: block;
	line-height: 1.1;
	//padding: px-to-em(15px, $title-size) 1em;

	font-weight: normal;
	text-transform: uppercase;
	border-top: none !important;
	font-size: 16px;
	color: $color-brand-gold;
	padding: 0.83333em .25em;
	&:hover{
		color: $color-brand-white;	 			
	}

}


.scrollspy-nav-toggle {
	@extend %uc;

	background-color: $color-brand-red;
	color: $color-brand-white;
	padding: $element-spacing-small $site-padding-mobile;
	padding-right: $section-spacing-small * 1.5;
	font-weight: $font-weight-extra-bold;
	position: relative;
	width: 100%;
	display: none;

	@include ico-icon-after($icon-chevron-down) {
        $icon-size: 10px;

        @include center(vertical);

        font-size: px-to-em($icon-size);
        right: 1.3em;
    }
}

// @include screen($bp-tablet){
// 	.scrollspy-nav {
// 		padding-left: $site-padding-mobile;
// 	}

// 	.scrollspy-nav-column {
// 		// Pad like site-header-wrapper
// 		padding-left: $site-padding-mobile * 1.5;
// 	}

// 	.scrollspy-nav-option{
// 		.scrollspy-nav-link{
// 			padding-left: 0.5em;
// 		}
// 	}
// }

@include screen($bp-tablet-landscape) {
	.setup-fixed-header.headroom--pinned {
		& ~ .main-content {
			.scrollspy-nav {
				&.is-sticky {
					transform: translateY(px-to-em($site-header-height-mobile) );
				}
			}
		}
	}

	.scrollspy-nav {
		width: 100%;
		background-color: transparent;
		position: relative;
		margin-bottom: $section-spacing-mobile;
		text-align: inherit;
		margin-top: 0;
		padding-left: $site-padding-mobile;
		padding-right: $site-padding-mobile;

		&.is-sticky {
			@include transition(transform);
			margin-top: 0;
			top: 0;
			left: 0;
			padding: 0;
		}

		&.is-sticky-bottom {
			position: relative;
		}
	}

	.scrollspy-nav-column{
		padding: 0;
	}

	.scrollspy-nav-toggle {
		display: block;
		text-align: left;
	}

	.scrollspy-nav-option {
		display: block;

		.scrollspy-nav-link{
			padding-left: 0;
		}

		&.is-inview {
			background-color: $color-brand-black;
			display: none;
		}
	}

	.scrollspy-nav-list {
		white-space: normal;
		position: absolute;
		top: 100%;
		width: 100%;
		max-width: none;
		overflow: hidden;
		max-height: 0;
		padding: 0;
		margin-top: 0;
		transition: max-height $transition-timing $transition-ease;

		&:hover {
			max-width: none;
		}

		.show-menu &{
			max-height: px-to-em(600px);
		}
	}

	.scrollspy-nav-link {
		padding-left: 0;

		&:before {
			display: none;
		}
	}

	.scrollspy-nav-label {
		font-size: 1em;
		padding: 0.83333em $site-padding-mobile;
	}
}