.page-locations-landing {

	.search-landing-filters-submit {
		position: relative;
		right: auto;
		bottom: auto;
		.btn-ghost-label {
			padding-left: 0;
		}
	}


	.silent-wrapper {
		@include content-large-wrapper;
	}


	@include screen($bp-mobile) {
		.search-landing-filters-submit {
			width: px-to-em($search-landing-submit-width-mobile, $font-size-buttons-mobile);
			padding-left: 0.2em;
			padding-right: 0.2em;
		}
	}
}