.content-block {

	.inner-content {
		padding: (px-to-em($section-spacing-px + 10px)) 0;
	}

	.rte {
		margin-bottom: 1.5em;
	}

	.top-spacing {
	 margin-top: 5%;
		 @include max-width-sm(){
	 		margin-top: 0;
	 	}
	}
	@include screen($bp-tablet){
		&.practice-leaders{
			.professional-image{
				max-width: 400px;
				margin: 0 auto;
			}
			// .svg-mask-container{
			// 	padding-bottom: 400px;
			// }
		}
	};
}
