﻿.embed-instagram {
    display: flex; 
    justify-content: center; 
    flex-wrap: wrap;
}

.post {
    margin-right: 16px;
    flex: 1 1 0%;
    margin-bottom: 2em;

    &:last-child {
        margin-right: 0;
      }
}

.ig-button {
    display: flex;
    justify-content: center;
    margin: 2em 0;         

    &-text {
        width: fit-content;
        border: 1px solid $color-brand-red;
        padding: 0.75em 1.5em;
        font-weight: $font-weight-extra-bold;
        font-size: $font-size-h5;
        text-transform: uppercase;
        color: $color-brand-red;
    }
}