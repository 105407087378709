﻿
.regioncard {
    margin: rem-calc(10 15);
    overflow: hidden;
    padding: rem-calc(1 5 5);
    position: relative;

    @include media-breakpoint-up(lg) {
        height: 100%;
    }


    &__content {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: rem-calc(30 30 60);
        position: relative;
        z-index: 2;

        &::before,
        &::after {
            box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.5);
            content: '';
            display: block;
            left: 0;
            position: absolute;
            width: 100%;
        }

        &::after {
            height: 1px;
            top: -3px;
        }

        &::before {
            background: palette('light_grey');
            bottom: 0;
            top: 0;
            transform: skewY(-10deg);
            transform-origin: top left;
            z-index: -1;
        }


        &__footer {
            margin-top: auto;
            position: relative;
            z-index: 2;
        }

        &__summary {
            font-family: 'Lora';
            line-height: 1.3;
            padding-bottom: rem-calc(24);
            position: relative;
            z-index: 1;

            @include media-breakpoint-up(md) {
                padding-bottom: rem-calc(48);
            }
        }
    }

    &__title {
        color: palette('red');
        font-size: rem-calc(30);
        font-weight: 900;
        margin-bottom: rem-calc(24)
    }

    &__subtitle {
        font-size: rem-calc(24);
        margin-bottom: rem-calc(16)
    }
}
