.jump-to {
    transition: opacity $transition-timing $transition-ease;

    &.is-disabled {
        visibility: hidden;
        opacity: 0;
        transition: opacity $transition-timing $transition-ease,
                    visibility $transition-timing $transition-ease $transition-timing;
    }
}
