﻿.card {
    margin-bottom: rem-calc(16);
    width: 100%;

    @include media-breakpoint-up(sm) {
        margin-bottom: rem-calc(30);
    }

    @include media-breakpoint-up(md) {
        flex: 1;
        flex-basis: auto;
        margin-bottom: 0
    }


    &--nested {

        @include media-breakpoint-up(md) {
            margin-bottom: rem-calc(15);

            &:last-child {
                margin-bottom: 0;
                margin-top: rem-calc(15);
            }
        }
    }

    &__link {
        display: block;
        overflow: hidden;
        position: relative;

        @include media-breakpoint-up(sm) {
            min-height: rem-calc(300);

            .card--event & {
                min-height: rem-calc(240);
            }
        }

        @include media-breakpoint-up(md) {
            height: 100%;
        }

        &:focus,
        &:hover {

            .card__title {
                text-decoration: underline;
            }

            .card__media__tag {
                box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.7);
            }

            .card__content {
                background-color: palette('light_grey');
            }
        }
    }

    &__media {
        bottom: 0;
        left: 0;
        overflow: hidden;
        position: absolute;
        padding-bottom: 0;
        right: 0;
        top: 0;
        width: 100%;

        .card--nav & {
            position: relative;
            padding-bottom: 50%;
        }
    

        &__image {
            bottom: 0;
            display: block;
            left: 50%;
            min-height: 100%;
            min-width: 100%;
            position: absolute;
            right: 0;
            top: 50%;
            transform: scale(1) translate3d(-50%, -50%, 0);
            transition: transform 0.8s ease 0.1s;

            @supports (object-fit: cover) {
                height: 100%;
                object-fit: cover;
                object-position: center;
                transform: scale(1) translate3d(-50%, -50%, 0);
                width: 100%;
            }

            .card__link:focus &,
            .card__link:hover & {
                transform: scale(1.1) translate3d(-50%, -50%, 0);
            }
        }

        &__tag {
            background-color: #fff;
            box-shadow: $box-shadow-default;
            display: block;
            font-weight: 700;
            left: rem-calc(16);
            padding: rem-calc(6 16);
            position: absolute;
            top: rem-calc(16);
            transition: box-shadow 0.2s ease;
            z-index: 1;
        }
    }

    &__content {
        background-color: #fff;
        padding: rem-calc(15 20);
        transition: background-color 0.3s ease;

        .card--nav & {
            margin: 0;
        }

        @include media-breakpoint-up(lg) {
            margin: rem-calc(30);

            .card--event & {
                margin: rem-calc(20);
            }
        }
    

        &--overlay {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin: rem-calc(20);
            max-width: rem-calc(300);
            min-height: rem-calc(240);
            position: relative;

            @include media-breakpoint-up(sm) {
                min-height: rem-calc(160);
            }
        }
    }

    &__title {
        display: inline-block;
        font-size: rem-calc(22);
        line-height: 1.3;
        margin-bottom: rem-calc(16);
        text-decoration: none;

        .card--event &,
        .card--nav & {
            font-size: rem-calc(16);
            font-weight: 700;
        }
    

        &--external-link {

            &::after {
                background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23000000' fill-rule='evenodd'%3E%3Cpath d='M19.728 5v1.325h5.007L10 21.06l.94.94L25.675 7.265v5.007H27V5z'/%3E%3Cpath d='M21.685 25.685H6.315v-15.37h9.467V9H5v18h18V16.214h-1.315z'/%3E%3C/g%3E%3C/svg%3E");
                background-size: cover;
                content: '';
                display: inline-block;
                height: rem-calc(20);
                margin-left: rem-calc(6);
                width: rem-calc(20);
            }
        }
    }

    &__category {
        font-size: rem-calc(12);
        font-weight: 600;
        margin-bottom: rem-calc(4);
        margin-top: rem-calc(10);
        padding-top: rem-calc(16);
        position: relative;

        @include media-breakpoint-up(lg) {
            font-size: rem-calc(14);
        }

        &::before {
            border-top: 1px solid palette('black');
            content: '';
            display: block;
            left: 0;
            position: absolute;
            top: 0;
            width: rem-calc(50);
        }
    

        &--event {
            margin-top: 0;
            padding-top: 0;

            &::before {
                content: none;
            }
        }
    }

    &__date {
        display: block;
        font-size: rem-calc(12);
    

        &--large {
            font-size: rem-calc(18);
            margin-bottom: rem-calc(20);
        }
    }
}
