.timeline {
    margin: 2em 0;
    font-family: 'Lora';

    &__image{
        width: 100%;

    }
}

.circle {
    width: 98px;
    height: 98px;
    border: 2px solid $color-border;
    line-height: 98px;
    text-align: center;   
    font-weight: 500;
    font-size: 64px;
    border-radius: 50%;
    color: $color-brand-red;
    margin-bottom: 20px;
}

.timeline-title {
    font-weight: 700;
    line-height: 30px;
    font-size: 20px;
    margin-bottom: 8px;
}

.timeline-description {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 31px;
}

.column {
    display: flex;
	flex-wrap: wrap;
    @include screen($bp-tablet, "max") { 
        flex-direction: column;
    }
}

.timeline-list-card {
    flex-grow: 0;
    width: 48%;
    padding-right: 20px;
    @include screen($bp-tablet, "max") { 
        width: 100%;
    }
}