.language-menu {
    background-color: palette('dark_grey');
    color: #fff;
    margin-right: rem-calc(8);
    position: relative;
    padding-right: rem-calc(8);

    @include media-breakpoint-up(sm) {
        margin-right: rem-calc(16);
        padding-right: rem-calc(10);
    }

    @include media-breakpoint-up(lg) {
        margin-right: rem-calc(24);
        padding-right: rem-calc(16);
    }

    &::after {
        background-color: #fff;
        content: '';
        display: block;
        height: rem-calc(26);
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;

        @include media-breakpoint-up(lg) {
            height: rem-calc(40);
        }
    }


    &__button {
        background: transparent;
        border: 0;
        color: #fff;
        padding: rem-calc(6);
        white-space: nowrap;

        &:focus,
        &:hover {
            color: palette('red');
        }

        .is-open > & {

            .language-menu__chevron {
                transform: rotate(180deg);
            }
        }
    }

    &__globe {
    }

    &__chevron {
        transform: rotate(0);
        transition: transform 0.35s ease;
    }

    &__options {
        left: 0;
        list-style: none;
        overflow: hidden;
        max-height: 0;
        padding-left: 0;
        width: calc(100% - 16px);
        position: absolute;
        right: 0;
        transition: max-height 0.3s ease, visibility 0.3s;
        z-index: 20;
        visibility: hidden;

        .is-open > & {
            max-height: rem-calc(300);
            visibility: visible;
        }
    }

    &__item {
        border-bottom: 1px solid #fff;
    }

    &__link {
        background-color: palette('dark_grey');
        display: block;
        font-weight: 700;
        padding: rem-calc(12);
        transition: background-color 0.35s ease;
        border: transparent solid 5px;

        .is-selected & {
            background-color: palette('red');
        }

        &:focus,
        &:hover {
            background-color: palette('light_grey');
            color: palette('dark_grey');
            outline: none;
            border: $diversity-cyan solid 5px;
        }
    }
}
