.listing-capabilities-option {
	padding: $section-spacing-small 0;
	border-bottom: $border-list;
}

.listing-capabilities-link {
	@include font-open-sans-bold(16px);
	line-height: 1.2;
}

.capabilities-toggle {
	margin: 45px 0;
	text-align: right;
	
	.expand {
		float: right;
	}
}

@include screen($bp-tablet) {
	.listing-capabilities-option {
		padding: $element-spacing-small*1.5 0;	
	}
}

@include screen($bp-mobile) {
	.capabilities-toggle {
		text-align: center;
	}
}