﻿$professional-card-thumbnail-width: px-to-em(266px);

.professional-card {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	margin-bottom: $element-spacing-small;


	&:hover {
		.professional-card-header {

			&:before{
				width: 100%;
			}
		}
	}

	
}

.professional-card-thumbnail {
	width: $professional-card-thumbnail-width;
	margin-right: 1em;
	flex-shrink: 0;

	& + .professional-card-info {
		margin-bottom: $element-spacing;
	}
}

.professional-card-info {
	flex-grow: 1;

	margin-bottom: $section-spacing;
}

.professional-card-office {
	display: inline-block;
	@include link-transition($color-link,$color-link-hover);
	margin-top: 10px;
	$title-size: 20px;
	font-size: px-to-em($title-size);

}

.professional-card-position {

	$title-size: 20px;

	@extend %uc;

	font-size: px-to-em($title-size);
	
}


.professional-card-link {
    $heading-size: 36px;
    $underline-color: $color-link-hover;

    @include font-open-sans-extra-bold($heading-size);
    line-height: 1.1;
    color: $color-link;
    background-image: linear-gradient(to bottom, $underline-color 0%, $underline-color 100%);
    background-repeat: no-repeat;
    background-position: 0 1.34em;
    background-size: 0 auto;
    transition: color $transition-timing $transition-ease, background $transition-timing $transition-ease;


    &:hover {
        background-size: 100% auto;
        color: $color-link-hover;
    }

    &--diversity {
        color: $diversity-green;
        font-weight: $font-weight-bold;
    }
}

.professional-office-position {
    margin-top: 5px;
}

//from http://browserhacks.com/ FIrefox media query hack
@media all and (min--moz-device-pixel-ratio:0) {
	.professional-card-link {
		background-position: 0 1.36em;
	}
}

//from http://browserhacks.com/ IE EDge and up media query hack
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.professional-card-link {
		background-image: none;
		@include underline-transition($color-link-hover)
	}
}



@include screen($bp-desktop) {
	$professional-card-thumbnail-width: 45%;
	.professional-card-thumbnail {
		width: $professional-card-thumbnail-width;
	}
}



@include screen($bp-tablet) {
	$professional-card-thumbnail-width: px-to-em(225px);

	.professional-card-position {
		$title-size: 16px;

		font-size: px-to-em($title-size);	
	}

	.professional-card-thumbnail {
		width: $professional-card-thumbnail-width;
	}


	.professional-card-link {
		$heading-size: 36px;

		font-size: px-to-em(28px);
	}

	.professional-card-info {
		max-width: px-to-em(600px);
	}
}



@include screen($bp-mobile-landscape) {
	$professional-card-thumbnail-width: px-to-em(225px);

	.professional-card {
		max-width: 90%;
		display: block;
		text-align: center;
		margin-bottom: $section-spacing-mobile;
	}


	.professional-card-thumbnail {
		width: $professional-card-thumbnail-width;
		margin: 0 auto $section-spacing-small;
	}
	
}	