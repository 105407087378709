.gold-tabs-panel {
    @include transition(opacity);
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;

    &--is-active {
        visibility: visible;
        opacity: 1;
        position: relative;
        z-index: 10;

    .is-transitioning-out & {
        &--is-active {
            opacity: 0;
        }
    }

    }

    &__copy {
        max-width: 1220px;
    }

    &-actions {
        padding-left:6px;
        margin-top: $section-spacing;
    }

    &-cta {
        min-width: px-to-em(280px);
        margin-right: 1em;
        &:last-child {
            margin-right: 0;
        }
    }

    @include screen($bp-mobile) {

        &-actions {
            margin-top: 2em;
        }

        &-cta {
            display: block;
            margin-right: 0;
            margin-bottom: 1em;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
