﻿.sociallinks {
    display: flex;
    list-style: none;
    margin: 0;
    padding-left: 0;


    &__item {
        margin-right: rem-calc(20);

        &:last-child {
            margin-right: 0;
        }

        @include media-breakpoint-up(lg) {
            margin-right: rem-calc(30);
        }
    }

    &__link {
        color: inherit;
        display: block;
        font-size: 30px;

        &:focus,
        &:hover {
            color: palette('red');
        }
    }
}
