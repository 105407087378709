.page-professional-detail {
	.capabilities-compact{
		&:before{
			display: none;
		}
		&:after{
			height: 100%;
		}
	}
	.narrative-overview,
	.related-experience {
		@extend .layout-column-main;
	}
	//these are the overriding features for scrollspy on the professionals page.
	//eventually, we can put these styles permenantly for all pages once it perpetuates
	//to those pages
	// .scrollspy-nav {
	// 	$marginTop: 8em;
	// 	width: 100vw;
	// 	background-color: $color-brand-black;
	// 	left: 0;
	// 	margin-top: -1*$marginTop;
	// 	text-align: left;
	// 	padding-left: $scrollspy-nav-width + $site-padding;
	// 	&.is-sticky{
	// 		top: $marginTop;
	// 	}		
	// }
	// .scrollspy-nav-list{
	// 	overflow: visible;
	// }
	// .scrollspy-nav-label{
	// 	font-weight: normal;
	// 	text-transform: none;
	// 	border-top: none !important;
	// 	font-size: 16px;
	// 	color: $color-brand-gold;
	// 	padding: 0.83333em .25em;
	// 	&:hover{
	// 		color: $color-brand-white;	 			
	// 	}
	// }
	// .scrollspy-nav-option{
	// 	display: inline-block;
	// 	.scrollspy-nav-link{
	// 		&:before{
	// 			display: none;
	// 		}
	// 	}
	// 	&.is-inview{
	// 		.scrollspy-nav-label{
	// 			background-color: transparent;
	// 			color: $color-brand-white;
	// 		}
	// 	}
	// }
	// .scrollspy .scrollspy-nav-notice{
	// 	display: inline-block;
	// 	font-size: 16px;
	// 	font-weight: bold;
	// 	text-transform: uppercase;
	// 	color: $color-brand-white;
	// }
}

@include screen($bp-tablet) {
	.page-professional-detail {
		// .scrollspy-nav {
		// 	padding-left: $site-padding-mobile;
		// 	&.is-sticky{
		// 	}		
		// }
		// .scrollspy-nav-list{
		// }
		// .scrollspy-nav-label{
		// }
		// .scrollspy-nav-option{
		// 	.scrollspy-nav-link{
		// 		padding-left: 0.5em;
		// 	}
		// 	&.is-inview{
		// 		.scrollspy-nav-label{
		// 		}
		// 	}
		// }
		
	}
}
@include screen($bp-mobile) {
	.page-professional-detail {
		.layout-column-wrapper {
			padding: 0;
		}

		.layout-column-main {
			padding-left: $site-padding-mobile;
			padding-right: $site-padding-mobile;
		}

		.perspectives-news-events {
			margin-top: 0;
		}

		.related-experience  {
			margin-top: $section-spacing-module-mobile;
		}
		///scrollspy styles
		// .scrollspy .scrollspy-nav-notice{
		// 	display: none;
		// }
		// .scrollspy-nav{
		// 	padding-left: 0;
		// }
		// .scrollspy-nav-column{
		// 	padding-left: 0;
		// }
		// .scrollspy-nav-list{
		// 	overflow: hidden;
		// }
		// .scrollspy-nav-label{
		// 	padding: 0.83333em .8333em;
		// }
		// .scrollspy-nav-option{
		// 	display: block;
		// 	.scrollspy-nav-link{
		// 		padding-left: 0;
		// 	}
		// 	&.is-inview{
		// 		background-color: $color-brand-black;
		// 	}
		// }

	}
}
