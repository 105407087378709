$prominent-card-thumbnail-width: px-to-em(150px);

.header {
 margin-top: 2em;
 font-size: 20px;
 line-height: 25.6px;
 font-weight: 700;
}

.prominent-card {
  display: flex;
  margin: 2em 0;
  border-bottom: 1px solid $color-brand-black;

  &:last-child {
    border-bottom: 0;
  }

  @include screen($bp-tablet, "max") { 
    flex-direction: column;
  }
}

.prominent-card-thumbnail {
	width: $prominent-card-thumbnail-width;
	margin-right: 2em;
	flex-shrink: 0;

	& + .prominent-card-info {
		margin-bottom: -3em;
	}

  @include screen($bp-tablet, "max") { 
    margin-bottom: 1em;
  }
}

.quotation {
  background: url('../images/quote.svg') no-repeat center;
  height: 42px;
  width: 45px;
  margin-bottom: 8px;
}

.quote {
  font-family: $font-family-lora;
  margin-bottom: 1em;
  font-size: 28px;
  line-height: 42px;
  font-weight: $font-weight-regular;
}


.prominent-card-office {
	display: inline-block;
	$title-size: 16px;
	font-size: px-to-em($title-size);
  margin-bottom: 2em;
  color: $color-link;
}

.prominent-card-position {
	$title-size: 16px;
	font-size: px-to-em($title-size);
  margin-bottom: 10px;
}

.prominent-card-link {
  color: $color-link;
  background-repeat: no-repeat;
  background-position: 0 1.34em;
  background-size: 0 auto;
  margin-bottom: 10px;
  font-weight: $font-weight-extra-bold;
  $title-size: 20px;
  font-size: px-to-em($title-size);
}




