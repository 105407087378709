.page-winrs-detail{
  &.blue-theme{
    $theme-color: $diversity-blue;
    .winrs__button--hero{
      border: 1px solid $theme-color;
      &:after{
        background-color:$theme-color;
      }
    }
    .professional-card-link--diversity{
      color: $theme-color;
    }
    .winrs__quote-bar{
      background-color: $theme-color !important;
    }
    .sidenav-section-skew--diversity{
      background-color:$theme-color;
    }
  }
  &.red-theme{
    $theme-color: $color-brand-red;
    .winrs__button--hero{
      border: 1px solid $theme-color;
      &:after{
        background-color:$theme-color;
      }
    }
    .professional-card-link--diversity{
      color: $theme-color;
    }
    .winrs__quote-bar{
      background-color: $theme-color !important;
    }
    .sidenav-section-skew--diversity{
      background-color:$theme-color;
    }
  }
  &.green-theme{
    $theme-color: $diversity-green;
    .winrs__button--hero{
      border: 1px solid $theme-color;
      &:after{
        background-color:$theme-color;
      }
    }
    .professional-card-link--diversity{
      color: $theme-color;
    }
    .winrs__quote-bar{
      background-color: $theme-color !important;
    }
    .sidenav-section-skew--diversity{
      background-color:$theme-color;
    }

  }
  &.orange-theme{
    $theme-color: $diversity-orange;
    .winrs__button--hero{
      border: 1px solid $theme-color;
      &:after{
        background-color:$theme-color;
      }
    }
    .professional-card-link--diversity{
      color: $theme-color;
    }
    .winrs__quote-bar{
      background-color: $theme-color !important;
    }
    .sidenav-section-skew--diversity{
      background-color:$theme-color;
    }

  }
  &.cyan-theme{
    $theme-color: $diversity-cyan;
    .winrs__button--hero{
      border: 1px solid $theme-color;
      &:after{
        background-color:$theme-color;
      }
    }
    .professional-card-link--diversity{
      color: $theme-color;
    }
    .winrs__quote-bar{
      background-color: $theme-color !important;
    }
    .sidenav-section-skew--diversity{
      background-color:$theme-color;
    }

  }
  &.light-green-theme{
    $theme-color: $diversity-light-green;
    .winrs__button--hero{
      border: 1px solid $theme-color;
      &:after{
        background-color:$theme-color;
      }
    }
    .professional-card-link--diversity{
      color: $theme-color;
    }
    .winrs__quote-bar{
      background-color: $theme-color !important;
    }
    .sidenav-section-skew--diversity{
      background-color:$theme-color;
    }

  }
  &.lime-theme{
    $theme-color: $diversity-lime;
    .winrs__button--hero{
      border: 1px solid $theme-color;
      &:after{
        background-color:$theme-color;
      }
    }
    .professional-card-link--diversity{
      color: $theme-color;
    }
    .winrs__quote-bar{
      background-color: $theme-color !important;
    }
    .sidenav-section-skew--diversity{
      background-color:$theme-color;
    }

  }
  &.fuschia-theme{
    $theme-color: $diversity-fuschia;
    .winrs__button--hero{
      border: 1px solid $theme-color;
      &:after{
        background-color:$theme-color;
      }
    }
    .professional-card-link--diversity{
      color: $theme-color;
    }
    .winrs__quote-bar{
      background-color: $theme-color !important;
    }
    .sidenav-section-skew--diversity{
      background-color:$theme-color;
    }

  }

}
