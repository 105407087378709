.capabilities-results-listing {
	
	.search-entity-landing-result {
		margin-bottom: 0;
	}
	.result {
		padding-bottom: $section-spacing;
		border-bottom: $border-list-heavy;
		+ .result {
			padding-top: $section-spacing;
		}
	}
	.result-link {
		$heading-size: 36px;

		@include font-open-sans-extra-bold($heading-size);
		@include transition(color);

		line-height: 1.1;
		color: $color-link;

		&:hover {
			color: $color-link-hover;
		}
	}

	@include screen($bp-mobile){
		.result-link {
			$heading-size: 28px;

			@include font-open-sans-extra-bold($heading-size);
		}
		.result {
			$section-spacing: 30px;
			padding-bottom: $section-spacing;
			+ .result {
				padding-top: $section-spacing;
			}
		}

	};
}
