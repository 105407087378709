$accordion-angled-button-width: 70px;
$accordion-skew-degree: 8deg;

.accordion-angled-node {
    margin-bottom: $section-spacing-small;
    position: relative;


    &.is-expanded {

        .accordion-angled-toggle {
            &:after{
                content: "-";
                line-height: 0.5;
                
            }
        }
    }
}

.accordion-angled-skew {
    @include absolute-full;

    display: block;
    transform: skewX($skew-degree * -1);
    
    z-index: -1;
    backface-visibility: hidden;

    &:after {
        @include absolute-full;
        @include transition(right);

        content: '';
        display: block;
        background-color: $color-brand-white;
        right: 100%;
    }
}
.accordion-angled-header {
    position: relative;
}

.accordion-angled-title {
    display: table;
    width: 100%;
    height: px-to-em($accordion-angled-button-width);

    .accordion-angled-skew {
        background-color: $color-brand-black;
        border: 1px solid $color-brand-black;

        // &:after  {
        //     background-color: $color-brand-black;

        // }
        
    }

    &:hover {
        .accordion-angled-title-label {
            color: $color-brand-black;
        }
        .accordion-angled-skew {
            &:after {
                right: 0;
            }
        }
    }
}
.accordion-angled-title-label {
    $title-size: 20px;

    @include font-open-sans-light($title-size);
    @include transition(color);

    padding: $element-spacing-small px-to-em(30px, $title-size);
    padding-right: px-to-em($accordion-angled-button-width + 20px, $title-size);
    display: table-cell;
    vertical-align: middle;
    color: $color-brand-white;
    z-index: 5;
}

//expand collapse button
.accordion-angled-toggle {
    $icon-size: 50px;
    @include transition(color);

    position: absolute;
    right: -1px;
    top: -1px;
    bottom: -1px;
    width: $accordion-angled-button-width;
    color: $color-brand-white;
    z-index: 2;
    overflow: visible;
    
    &:after {

        @include font-open-sans-extra-bold($icon-size);

        content: "+";
        font-size: rem($icon-size); 
        line-height: 1;
    }

    .accordion-angled-skew {
        background-color: $color-brand-red;
        border: 1px solid $color-brand-red;
    }

    &:hover {
        color: $color-brand-red;

        .accordion-angled-skew {
            &:after {
                right: 0;
            }
        }
    }
}


.accordion-angled-node-content {
    $transition-delay: 250ms;

    @include transition(height);

    height: 0;
    overflow: hidden;

    &[aria-hidden="false"] {
        height:auto;
    }

    &:before,
    &:after {
        content: '';
        display: block;
        padding-top: px-to-em(60px, $font-size-copy);
    }

    &:after {
        padding-top: px-to-em(30px, $font-size-copy);   
    }

    a {
        font-weight: $font-weight-bold;
    }

    .explore-more-cta {
        margin-top: $element-spacing
    }

    .additional-solutions {
        margin-top: $element-spacing * 2; 
    }

    &.no-summary {
        &:before {
            content: '';
            display: block;
            padding-top: px-to-em(30px, $font-size-copy);
        }
    }
}

@include screen($bp-tablet) {
    $accordion-angled-button-width: 60px;
    


    .accordion-angled-title {
        height: px-to-em($accordion-angled-button-width);
    }

    .accordion-angled-title-label {
        $title-size: 18px;

        @include font-open-sans-light($title-size);

        padding-left: px-to-em(24px, $title-size);
        padding-right: px-to-em($accordion-angled-button-width + 15px, $title-size);
    }

    .accordion-angled-toggle {
        width: px-to-em($accordion-angled-button-width);

        &:after {
            font-size: px-to-em(45px);
        }
    }

}