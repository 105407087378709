﻿.around-corner {
    overflow: hidden;
    margin-bottom: $section-spacing-largest;
    position: relative;

    .section-header {
        margin-bottom: $section-spacing-large;
    }

	&-diversity {
			margin-bottom: 0;
	}
	
	.article-tile {
		font-size: 0.8em; // Maintain a more rectangular aspect ratio with narrow horizontal space
		width: calc(33.333% - #{$site-padding});
		min-height: 14vw;
	}

	.article-tile-card {
		margin-bottom: 5rem;
	}
}

@include screen($bp-desktop) {
	.around-corner {
		.article-tile {
			width: calc(50% - #{$site-padding});
			min-height: 28vw;
		}
	}
}

@include screen($bp-tablet) {
	.around-corner {
		.section-header {
			margin-bottom: $section-spacing;
		}

		.article-tile {
			font-size: inherit;
		}
	}
}

@include screen($bp-mobile) {
	.around-corner {
		margin-bottom: $section-spacing-large-mobile;

		.section-header {
			margin-bottom: px-to-em($section-spacing-mobile-px);
		}
		
		.article-tile {
			width: 100%;
			min-height: 42vw;
		}

		.article-tile-card {
			margin-bottom: 2rem;
		}
	}
}

@include screen($bp-mobile-portrait) {
	.around-corner {
		.article-tile-card {
			max-width: none;
		}
	}
}