﻿
$section-header-spacing: 40px;

.section-header {
    @extend %uc;

    position: relative;
    overflow: hidden;
    margin-bottom: px-to-em($section-header-spacing);
}

.section-header--centered {
    text-align: center;

    .section-header-title {
        //gray line
        &:before {
            position: absolute;
            content: '';
            display: block;
            right: 100%;
            top: 50%;
            margin-right: $site-padding-mobile;
            width: px-to-em(1000px);
            margin-top: -1px;
            border-top: 1px solid $color-border-list;
            transform: translateZ(0);
        }
    }

    &--diversity {
        .section-header-title {
            &:before {
               content: none;
            }
        }
    }
}

.section-header-title-normal {
  margin-bottom: 1em;
}

.section-header--right-aligned {
    text-align: right;

    .section-header-title {
        &:after {
            right: 100%;
            left: auto;
            margin-left: 0;
            margin-right: $site-padding-mobile;
        }
    }
}

.section-header-title {
    display: inline-block;
    font-size: px-to-em($section-header-size);
    font-weight: $font-weight-extra-bold;
    line-height: 1.1;
    position: relative;


    &:after {
        position: absolute;
        content: '';
        display: block;
        left: 100%;
        margin-left: $site-padding-mobile;
        width: px-to-em(1000px);
        top: 50%;
        margin-top: -1px;
        border-top: 1px solid $color-border-list;
        transform: translateZ(0);
    }

    &--diversity {
        text-transform: none;
        font-weight: $font-weight-semibold;
        font-size: px-to-em($section-header-spacing);

        &:after {
            content: none;
        }
    }
}

.lawyer-header-inject {
    overflow: hidden;
    
    .lawyerHeader {
        @extend .section-header-title;
        font-family: $font-family-primary;
        font-size: px-to-em($section-header-size, $font-size-copy);
        line-height: 1.1;
        text-transform: uppercase;
    }
}

.section-header-minimal {
    margin-bottom: px-to-em($section-header-spacing);
}

.section-header-minimal-title {
    $heading-size: 36px;

    @include font-open-sans-light($heading-size);

    line-height: 1.1;
    text-align: center;
}

.recruitment-header-minimal-title {
    $heading-size: 36px;
    
    @include font-open-sans-light($heading-size);
    line-height: 1.1;
}

@include screen($bp-tablet) {

    .section-header,
    .section-header-minimal {
        margin-bottom: px-to-em($section-spacing-mobile-px);
    }

    .section-header-title{
        font-size: px-to-em($section-header-mobile-size);
    }

    .section-header-minimal-title {
        $heading-size: 32px;

        @include font-open-sans-light($heading-size);

    }
}

@include screen($bp-mobile-landscape) {



    .section-header-title{
        font-size: 1em;
    }
}
