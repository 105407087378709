﻿.btn {
    &--sm,
    &--md {
        display: inline-block;
        font-weight: 600;
        min-width: 0;
        white-space: nowrap;
    }

    &--sm {
        font-size: rem-calc(14);
        line-height: 1.3;
        padding: rem-calc(6 12);
    }

    &--md {
        font-size: rem-calc(16);
        padding: rem-calc(9 24);
    }

    &--icon {
        height: rem-calc(40);
        min-width: 0;
        padding: 0;
        width: rem-calc(40);

        &:focus,
        &:hover {
            color: #fff;
        }

        .svg-icon {
            position: relative;
            z-index: 1;
        }
    }

    &--search {
        font-size: 26px;
    }

    &--feature,
    &--banner {

        &:focus {
            color: palette('black');

            &::after {
                transform: skewX(-25deg) translateX(0);
            }
        }
    }

    &--black {
        border-color: palette('black');
        border-width: 1px;
        color: palette('black');

        &::after {
            border: 1px solid palette('black');
            background-color: palette('black');
        }

        &:focus {
            color: #fff;

            &::after {
                transform: skewX(-25deg) translateX(0);
            }
        }
    }
}
