.perspectives-news-events {
	position: relative;
	color: $color-brand-white;

	.page-wrapper {
		position: relative;
		z-index: 10;
	}
}

.perspectives-news-events-intro {
	text-align: center;
	max-width: $content-max-width;
	margin: 0 auto $section-spacing;
}

.perspectives-news-events-panels {
	margin-top: $element-spacing;
}

@include screen($bp-tablet-landscape) {
	.perspectives-news-events {
		padding: 20% 0;
	}
}
@include screen($bp-mobile) {
	.perspectives-news-events {
		padding: 25% 0;
	}
}
