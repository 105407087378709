@charset "UTF-8";
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden], template {
  display: none;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

html,
button,
input,
select,
textarea {
  font-family: sans-serif;
}

body {
  margin: 0;
}

a {
  background: transparent;
}
a:focus {
  outline: thin dotted;
}
a:hover, a:active {
  outline: 0;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

h2 {
  font-size: 1.5em;
  margin: 0.83em 0;
}

h3 {
  font-size: 1.17em;
  margin: 1em 0;
}

h4 {
  font-size: 1em;
  margin: 1.33em 0;
}

h5 {
  font-size: 0.83em;
  margin: 1.67em 0;
}

h6 {
  font-size: 0.75em;
  margin: 2.33em 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

mark {
  background: #ff0;
  color: #000;
}

code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1em;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

q {
  quotes: "“" "”" "‘" "’";
}

q:before, q:after {
  content: "";
  content: none;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
  white-space: normal;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
}

button, input {
  line-height: normal;
}

button,
select {
  text-transform: none;
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
input[disabled] {
  cursor: default;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type=search] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}
input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

/*==================================
=            Mixin Helpers             =
==================================*/
/*==================================
=            Load Fonts Here            =
==================================*/
/* FONT PATH
 * -------------------------- */
@font-face {
  font-family: "reedsmith";
  src: url("../fonts/reedsmith.eot?9l0j0j");
  src: url("../fonts/reedsmith.eot?9l0j0j#iefix") format("embedded-opentype"), url("../fonts/reedsmith.woff?9l0j0j") format("woff"), url("../fonts/reedsmith.ttf?9l0j0j") format("truetype"), url("../fonts/reedsmith.svg?9l0j0j#reedsmith") format("svg");
  font-weight: normal;
  font-style: normal;
}
.ico {
  font-family: "reedsmith" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-video-play:before {
  content: "\e903";
}

.ico-google-plus:before {
  content: "\ea8b";
}

.ico-facebook:before {
  content: "\ea90";
}

.ico-twitter:before {
  content: "\ea96";
}

.ico-youtube:before {
  content: "\ea9e";
}

.ico-instagram:before {
  content: "\e914";
}

.ico-vimeo:before {
  content: "\eaa0";
}

.ico-linkedin:before {
  content: "\eaca";
}

.ico-wechat:before {
  content: "\e90f";
}

.ico-print:before {
  content: "\e906";
}

.ico-vcard:before {
  content: "\e907";
}

.ico-mail:before {
  content: "\e90a";
}

.ico-mail-diversity:before {
  content: "\e910";
  color: #92338a;
}

.ico-facebook-diversity:before {
  content: "\e911";
  color: #92338a;
}

.ico-linkedin-diversity:before {
  content: "\e912";
  color: #92338a;
}

.ico-twitter-diversity:before {
  content: "\e913";
  color: #92338a;
}

.ico-external_link:before {
  content: "\e90b";
}

.ico-play:before {
  content: "\e903";
}

.ico-pause:before {
  content: "\ea1d";
}

.ico-globe-outline:before {
  content: "\e909";
}

.ico-chevron-down:before {
  content: "\e900";
}

.ico-search:before {
  content: "\e902";
}

.ico-close:before {
  content: "\e904";
}

.ico-location:before {
  content: "\e908";
}

.ico-home:before {
  content: "\e90d";
}

.ico-is-external-link:after {
  content: "\e90b";
}

.gold-tabs-btn, .gold-tabs-mobile-toggle, .forecast-carousel__button-ghost, .responsible-business-global-carousel__button-ghost-green, .responsible-business-global-carousel__button-ghost, .responsible-business-carousel__button-ghost, .responsible-business__button, .probono-global-carousel__button-ghost-blue, .probono-global-carousel__button-ghost, .probono-carousel__button-ghost, .probono__button, .winrs-global-carousel__button-ghost-orange, .winrs-global-carousel__button-ghost, .winrs-carousel__button-ghost, .winrs__button, .diversity-global-carousel__button-ghost-orange, .diversity-global-carousel__button-ghost, .diversity-carousel__button-ghost, .diversity__button, .newsletter-carousel-issues-view-all, .global-perspectives-btn, .global-perspectives-nav-toggle, .site-footer-menu-link, .site-nav-wrapper, .search-segments-toggle, .related-item-tile-featured, .tab-filters-mobile-toggle, .tab-filters-btn, .tabs-nav-mobile-toggle, .tabs-nav-btn, .scrollspy-nav-toggle, .scrollspy-nav-link, .sitenav-mobile-toggle, .professional-card-position, .related-entity-tile-featured, .dropdown-helper-label, .menu-toggle-label, .btn-ghost, .section-header {
  text-transform: uppercase;
}

body {
  font-size: 100%;
  font: 400 100% "Open Sans", sans-serif;
  line-height: 1.2;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: #3D3C3C;
}

h1, h2, h3, h4, h5, h6 {
  font-style: normal;
  font-weight: normal;
  line-height: 1.2;
  margin: 0;
}

h1 {
  font-size: 3.5em;
  line-height: 1.1;
}

h2 {
  font-size: 3.25em;
  line-height: 1.1;
}

h3 {
  font-size: 2em;
}

h4 {
  font-size: 1.5em;
}

h5 {
  font-size: 1.25em;
}

.is-disabled {
  cursor: auto;
  opacity: 0.5;
  user-select: none;
  color: #5B5B5B !important;
}

.is-active {
  color: #3D3C3C !important;
}

/*==========================================
=            Utility and States            =
==========================================*/
/*----------  Text Utilities  ----------*/
.text-center {
  text-align: center;
}

/*----------  Utilities  ----------*/
[v-cloak] {
  display: none;
}

.lock-scroll {
  overflow: hidden;
}

.hide-elem {
  display: none;
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.util-pull-left {
  float: left;
}

.util-pull-right {
  float: right;
}

.util-mobile-only {
  display: none;
}
@media screen and (max-width: 47.4375em) {
  .util-mobile-only {
    display: block;
  }
}

@media screen and (max-width: 47.4375em) {
  .util-mobile-hidden {
    display: none;
  }
}

.is-external-link:after {
  content: "\e90b";
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  font-size: 0.5em;
  margin-left: 0.5em;
  margin-top: 1em;
  vertical-align: middle;
}

.is-external-link-accordion:after {
  content: "\e90b";
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.5em;
  margin-left: 0.5em;
  vertical-align: middle;
}

.skip-to {
  height: 1px;
  width: 1px;
  clip: rect(0px, 0px, 0px, 0px);
  overflow: hidden;
  position: absolute;
  top: 0;
  margin: -1px;
}
.skip-to:focus {
  transition: background-color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  height: auto;
  width: auto;
  clip: auto;
  margin: 0;
  display: inline-block;
  overflow: visible;
  z-index: 510;
  background-color: #fff;
  padding: 0.5em 1em;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px !important;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

* {
  box-sizing: border-box;
}
*:before, *:after {
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
}

figure {
  margin: 0;
}

main {
  display: block;
}

a {
  color: inherit;
  text-decoration: none;
  transition: color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

fieldset {
  margin: 0;
}

ul, ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

p {
  margin: 0 0 1.5625em;
}
p:last-child {
  margin-bottom: 0;
}

button:focus, a:focus {
  outline: #18D0E4 solid 5px;
}
button:focus h3, a:focus h3 {
  outline: #18D0E4 solid 5px;
}

/*========================================
=            Layout Variables            =
========================================*/
.page-wrapper {
  max-width: 100.3125em;
  padding-left: 1.875em;
  padding-right: 1.875em;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 56.1875em) {
  .page-wrapper {
    padding-left: 1em;
    padding-right: 1em;
  }
}

.page-wrapper-careers {
  max-width: 100.3125em;
  padding-left: 1.875em;
  padding-right: 1.875em;
  margin-left: auto;
  margin-right: auto;
  padding: 0 10em;
}
@media screen and (max-width: 56.1875em) {
  .page-wrapper-careers {
    padding-left: 1em;
    padding-right: 1em;
  }
}

.page-wrapper--indented {
  padding-left: 10em;
  margin-bottom: 6.875em;
}

.page-wrapper--indented-nomargin {
  padding-left: 10em;
}

.content-wrapper {
  max-width: 63.75em;
  padding-left: 1.875em;
  padding-right: 1.875em;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 56.1875em) {
  .content-wrapper {
    padding-left: 1em;
    padding-right: 1em;
  }
}

.layout-column-wrapper:after {
  content: "";
  display: table;
  clear: both;
}

.layout-column-main, .page-professional-detail .narrative-overview,
.page-professional-detail .related-experience {
  float: left;
  width: 64%;
  padding-right: 8%;
}

.layout-column-sidebar {
  width: 36%;
  float: right;
}

.layout-module {
  margin-bottom: 6.875em;
}

.layout-column-wrapper--nomargin .layout-module {
  margin-bottom: 0;
}

@media screen and (max-width: 67.125em) {
  .page-wrapper--indented, .page-wrapper--indented-nomargin {
    padding-left: 1.875em;
  }
}
@media screen and (max-width: 56.1875em) {
  .layout-module {
    margin-bottom: 5em;
  }
  .layout-column-main, .page-professional-detail .narrative-overview,
  .page-professional-detail .related-experience {
    padding-right: 5%;
  }
}
@media screen and (max-width: 47.4375em) {
  .page-wrapper--indented, .page-wrapper--indented-nomargin {
    margin-bottom: 0;
    padding-left: 1em;
  }
  .layout-column-main, .page-professional-detail .narrative-overview,
  .page-professional-detail .related-experience,
  .layout-column-sidebar {
    float: none;
    width: auto;
  }
  .layout-column-main, .page-professional-detail .narrative-overview,
  .page-professional-detail .related-experience {
    padding-right: 0;
  }
}
.section-header {
  position: relative;
  overflow: hidden;
  margin-bottom: 2.5em;
}

.section-header--centered {
  text-align: center;
}
.section-header--centered .section-header-title:before, .section-header--centered .lawyer-header-inject .lawyerHeader:before, .lawyer-header-inject .section-header--centered .lawyerHeader:before {
  position: absolute;
  content: "";
  display: block;
  right: 100%;
  top: 50%;
  margin-right: 1em;
  width: 62.5em;
  margin-top: -1px;
  border-top: 1px solid #979797;
  transform: translateZ(0);
}
.section-header--centered--diversity .section-header-title:before, .section-header--centered--diversity .lawyer-header-inject .lawyerHeader:before, .lawyer-header-inject .section-header--centered--diversity .lawyerHeader:before {
  content: none;
}

.section-header-title-normal {
  margin-bottom: 1em;
}

.section-header--right-aligned {
  text-align: right;
}
.section-header--right-aligned .section-header-title:after, .section-header--right-aligned .lawyer-header-inject .lawyerHeader:after, .lawyer-header-inject .section-header--right-aligned .lawyerHeader:after {
  right: 100%;
  left: auto;
  margin-left: 0;
  margin-right: 1em;
}

.section-header-title, .lawyer-header-inject .lawyerHeader {
  display: inline-block;
  font-size: 1.5em;
  font-weight: 800;
  line-height: 1.1;
  position: relative;
}
.section-header-title:after, .lawyer-header-inject .lawyerHeader:after {
  position: absolute;
  content: "";
  display: block;
  left: 100%;
  margin-left: 1em;
  width: 62.5em;
  top: 50%;
  margin-top: -1px;
  border-top: 1px solid #979797;
  transform: translateZ(0);
}
.section-header-title--diversity {
  text-transform: none;
  font-weight: 600;
  font-size: 2.5em;
}
.section-header-title--diversity:after {
  content: none;
}

.lawyer-header-inject {
  overflow: hidden;
}
.lawyer-header-inject .lawyerHeader {
  font-family: "Open Sans", sans-serif;
  font-size: 1.2em;
  line-height: 1.1;
  text-transform: uppercase;
}

.section-header-minimal {
  margin-bottom: 2.5em;
}

.section-header-minimal-title {
  font-weight: 300;
  font-size: 2.25em;
  line-height: 1.1;
  text-align: center;
}

.recruitment-header-minimal-title {
  font-weight: 300;
  font-size: 2.25em;
  line-height: 1.1;
}

@media screen and (max-width: 56.1875em) {
  .section-header,
  .section-header-minimal {
    margin-bottom: 2.5em;
  }
  .section-header-title, .lawyer-header-inject .lawyerHeader {
    font-size: 1.25em;
  }
  .section-header-minimal-title {
    font-weight: 300;
    font-size: 2em;
  }
}
@media screen and (max-width: 39.3125em) {
  .section-header-title, .lawyer-header-inject .lawyerHeader {
    font-size: 1em;
  }
}
fieldset {
  border: 0 none;
  padding: 0;
}

input[type=text],
input[type=email],
input[type=number],
textarea {
  border: 1px solid #979797;
  padding: 0.9375em 0.875em;
  width: 100%;
}
input[type=text]::-webkit-input-placeholder,
input[type=email]::-webkit-input-placeholder,
input[type=number]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #181818;
  opacity: 1;
}
input[type=text]::-moz-placeholder,
input[type=email]::-moz-placeholder,
input[type=number]::-moz-placeholder,
textarea::-moz-placeholder {
  color: #181818;
  opacity: 1;
}
input[type=text]:-moz-placeholder,
input[type=email]:-moz-placeholder,
input[type=number]:-moz-placeholder,
textarea:-moz-placeholder {
  color: #181818;
  opacity: 1;
}
input[type=text]:-ms-input-placeholder,
input[type=email]:-ms-input-placeholder,
input[type=number]:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #181818;
  opacity: 1;
}

.form-content textarea {
  height: 18.75em;
}
.form-content .select-dropdown-toggle {
  border: 1px solid #979797;
  border-color: 1px solid #979797;
  background-color: #fff;
}
.form-content .form-submit {
  display: block;
  margin: 2.5em auto 0;
}

.form-checkbox {
  display: block;
  position: relative;
  margin: 0.75em 0;
  padding-left: 3.4375em;
}
.form-checkbox:before {
  position: absolute;
  top: 0;
  content: "";
  left: 0;
  width: 1.875em;
  height: 1.875em;
  border: 1px solid #979797;
}
.form-checkbox:before {
  border: 1px solid #979797;
}
.form-checkbox .form-checkbox-label:after {
  content: "\e905";
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 6px;
  left: 5px;
  color: #CC092F;
  opacity: 0;
}
.form-checkbox .form-checkbox-label a {
  position: relative;
  transition: none;
  color: #CC092F;
  display: inline-block;
}
.form-checkbox .form-checkbox-label a:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0%;
  bottom: -1px;
  left: 0;
  height: 1px;
  background-color: #5B5B5B;
  transition: 0.35s;
}
.form-checkbox .form-checkbox-label a:hover {
  color: #5B5B5B;
}
.form-checkbox .form-checkbox-label a:hover:before {
  width: 100%;
}
@media screen and (max-width: 47.4375em) {
  .form-checkbox .form-checkbox-label a:before {
    display: none;
  }
  .form-checkbox .form-checkbox-label a:hover:before {
    display: none;
  }
}
.form-checkbox input[type=checkbox]:checked + .form-checkbox-label:after {
  opacity: 1;
}

.form-checkbox-label {
  font-family: "Lora", serif;
  line-height: 1.5;
  display: inline-block;
}

.form-captcha {
  margin-top: 0.75em;
}

.form-message-send .professional-card {
  margin-top: 1.875em;
}

.form-message-send-title {
  font-size: 1.25em;
}

.form-content .form-validation-error {
  border: 2px solid #CC092F;
}
.form-content .form-validation-error + .form-checkbox-label {
  border: 2px solid #CC092F;
}

.form-ending-message {
  text-align: center;
  font-weight: bold;
  margin-bottom: 2.5em;
}
.form-ending-message .error-message {
  color: #CC092F;
}

.form-success h4 {
  text-align: center;
  font-weight: 800;
}

.form-actions {
  margin-bottom: 1.875em;
}

.form-actions--with-terms {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.form-terms-conditions {
  position: relative;
  padding-left: 1.875em;
}

.form-layout--halfs {
  margin: 0 -0.9375em;
}
.form-layout--halfs:after {
  content: "";
  display: table;
  clear: both;
}
.form-layout--halfs .form-field {
  float: left;
  width: 50%;
  padding: 0 0.9375em 1.875em;
}
.form-layout--halfs .form-field:nth-child(2n+1) {
  clear: left;
}
.form-layout--halfs .form-field.form-field--full {
  width: 100%;
}
.form-layout--halfs .form-field--hidden {
  display: none;
}

@media screen and (max-width: 56.1875em) {
  .form-layout--halfs {
    margin: 0 -0.625em;
  }
  .form-layout--halfs .form-field {
    padding: 0 0.625em 1.25em;
  }
  .form-message-send-title {
    font-size: 1em;
  }
  .form-actions--with-terms {
    display: block;
  }
  .form-actions--with-terms .form-captcha {
    text-align: center;
  }
  .form-terms-conditions {
    padding-left: 0;
    margin: 1.875em auto 0;
  }
  .form-layout--halfs {
    margin: 0;
  }
  .form-layout--halfs .form-field {
    float: none;
    width: 100%;
    padding: 0 0 1.25em;
  }
}
button,
.btn {
  display: inline-block;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0;
  padding: 0;
  margin: 0;
  transition: color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86), border-color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86), opacity 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86), background-color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.btn-primary {
  line-height: 1.1;
}
.btn-ghost {
  display: inline-block;
  font-weight: 700;
  padding: 0.75em 1.5em;
  min-width: 20em;
  border: thin solid #CC092F;
  overflow: hidden;
  position: relative;
  text-align: center;
  color: #CC092F;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
}
.btn-ghost:hover {
  color: #fff;
}
.btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #CC092F;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.btn-ghost--diversity {
  border: 1px solid #1e9f48;
  color: #1e9f48;
}
.btn-ghost--diversity:after {
  background-color: #1e9f48;
}
.btn-ghost--winrs {
  border: 1px solid #E76D25;
  color: #E76D25;
}
.btn-ghost--winrs:after {
  background-color: #E76D25;
}

.btn-ghost-label {
  position: relative;
  z-index: 2;
}

.btn-ghost--inverse {
  color: #fff;
  border-color: #fff;
}
.btn-ghost--inverse:hover {
  color: #3D3C3C;
}
.btn-ghost--inverse:after {
  background-color: #fff;
}

.btn-ghost--inverse-red {
  background-color: #CC092F;
  color: #fff;
  border-color: #CC092F;
}
.btn-ghost--inverse-red:hover {
  color: #CC092F;
}
.btn-ghost--inverse-red:after {
  background-color: #fff;
}

.btn-ghost--video .btn-ghost-label:after {
  content: "\e90e";
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-left: 0.5em;
  margin-top: 0.1em;
}

.btn-text-load-more {
  display: block;
  margin: 5em auto 0;
}
.btn-text-load-more .btn-ghost-label:after {
  content: "+";
  display: inline-block;
  margin-left: 0.25em;
}

.btn-ghost--submit, .btn-ghost--clear {
  padding-left: 1em;
  padding-right: 1em;
  border-color: #E5B522;
  color: #E5B522;
  min-width: auto;
  line-height: 1;
}
.btn-ghost--submit:hover, .btn-ghost--clear:hover {
  color: #3D3C3C;
}
.btn-ghost--submit:after, .btn-ghost--clear:after {
  background-color: #E5B522;
}
.btn-ghost--submit .btn-ghost-label:before, .btn-ghost--clear .btn-ghost-label:before {
  content: "\e902";
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.375em;
}

.btn-ghost--clear:hover {
  color: #3D3C3C;
}
.btn-ghost--clear:after {
  background-color: #E5B522;
}
.btn-ghost--clear .btn-ghost-label:before {
  content: "\e904";
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.375em;
}

.btn-ghost--gold {
  border-color: #E5B522;
  color: #E5B522;
  min-width: auto;
  line-height: 1;
}
.btn-ghost--gold:hover {
  color: #3D3C3C;
}
.btn-ghost--gold:after {
  background-color: #E5B522;
}

.btn-ghost--orange {
  border-color: #E76D25;
  color: #E76D25;
  min-width: auto;
  line-height: 1;
}
.btn-ghost--orange:hover {
  color: #3D3C3C;
}
.btn-ghost--orange:after {
  background-color: #E76D25;
  z-index: -1;
}

.btn-ghost--black {
  border-color: #3D3C3C;
  color: #3D3C3C;
  min-width: auto;
  line-height: 1;
}
.btn-ghost--black:hover {
  color: #fff;
}
.btn-ghost--black:after {
  background-color: #3D3C3C;
  z-index: -1;
}

.btn-ghost--submit-search {
  border-color: #E5B522;
  color: #E5B522;
  min-width: auto;
  line-height: 1;
}
.btn-ghost--submit-search:hover {
  color: #3D3C3C;
}
.btn-ghost--submit-search:after {
  background-color: #E5B522;
}
.btn-ghost--submit-search:before {
  content: "\e902";
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.375em;
  position: absolute;
  color: inherit;
  z-index: 2;
}

@media screen and (max-width: 56.1875em) {
  .btn-ghost {
    font-size: 0.9375em;
  }
  .btn-ghost--center {
    margin-left: auto;
    margin-right: auto;
  }
  .btn-text-load-more {
    margin-top: 5em;
  }
}
@media screen and (max-width: 26.1875em) {
  .btn-ghost {
    min-width: auto;
  }
}
.rte {
  font-family: "Lora", serif;
  font-size: 1.25em;
  line-height: 1.5;
}
@media screen and (max-width: 56.1875em) {
  .rte {
    font-size: 0.9375em;
  }
}
.rte strong {
  font-family: "Lora", serif;
  font-weight: bold;
}
.rte em {
  font-style: italic;
}
.rte ul, .rte ol, .rte p,
.rte img,
.rte address {
  margin-bottom: 1.25em;
}
.rte ul:last-child, .rte ol:last-child, .rte p:last-child,
.rte img:last-child,
.rte address:last-child {
  margin-bottom: 0;
}
.rte img {
  max-width: 100%;
  height: auto;
}
.rte ul, .rte ol {
  padding-left: 1.15em;
}
.rte li {
  margin-bottom: 1.25em;
}
.rte li:last-child {
  margin-bottom: 0;
}
.rte ul {
  list-style: none;
}
.rte ul li {
  position: relative;
}
.rte ul li:before {
  content: "•";
  color: #CC092F;
  line-height: 1.5;
  margin-left: -1em;
  position: absolute;
}
.rte ul ul li:before {
  color: #5B5B5B;
}
.rte ol {
  list-style-type: decimal;
}
.rte h2:first-child {
  margin-top: 0;
}
.rte blockquote,
.rte .blockquote {
  position: relative;
  line-height: 1.4;
  font-weight: 700;
  text-align: center;
  font-size: 1.4em;
  padding: 1.25em 0;
  margin: 2.8571428571em 0.7857142857em;
}
.rte blockquote:before, .rte blockquote:after,
.rte .blockquote:before,
.rte .blockquote:after {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  content: "";
  width: 3.2142857143em;
  border-top: 3px solid #CC092F;
}
.rte blockquote:before,
.rte .blockquote:before {
  bottom: 100%;
}
.rte blockquote:after,
.rte .blockquote:after {
  top: 100%;
}
.rte blockquote:first-child,
.rte .blockquote:first-child {
  margin-top: 0;
}
.rte blockquote:last-child,
.rte .blockquote:last-child {
  margin-bottom: 0;
}
.rte iframe,
.rte embed,
.rte object {
  max-width: 100% !important;
}
.rte a {
  color: #CC092F;
  background-image: linear-gradient(to bottom, #5B5B5B 0%, #5B5B5B 100%);
  background-repeat: no-repeat;
  background-position: 0 1.15em;
  background-size: 0 auto;
  transition: color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86), background 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.rte a:hover {
  background-size: 100% auto;
  color: #5B5B5B;
}
.rte a.btn-ghost {
  color: #CC092F;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
  font-size: 1rem;
  font-family: "Open Sans", sans-serif !important;
}
.rte a.btn-ghost:hover {
  color: #fff;
}
.rte a.btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.rte a.btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #CC092F;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.rte a.btn-ghost:hover {
  color: #fff;
}
.rte--diversity {
  font-family: inherit;
}
.rte--diversity--center {
  text-align: center;
}
.rte--normal-fonts {
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
}

@media all and (min--moz-device-pixel-ratio: 0) {
  .rte a {
    background-position: 0 1.2em;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rte a {
    background-image: none;
    display: inline;
    position: relative;
  }
  .rte a:before {
    content: "";
    position: absolute;
    display: inline-block;
    width: 0%;
    bottom: -1px;
    left: 0;
    height: 1px;
    background-color: #5B5B5B;
    transition: 0.35s;
  }
  .rte a:hover:before {
    width: 100%;
  }
}
.video-embed {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  width: 100%;
  max-width: 35em;
}
.video-embed iframe,
.video-embed embed,
.video-embed object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.rte-title {
  font-weight: 300;
  font-size: 2em;
  margin-bottom: 0.5em;
}

.rte--lead {
  font-family: "Lora", serif;
  font-size: 1.5em;
  line-height: 1.5;
}
@media screen and (max-width: 56.1875em) {
  .rte--lead {
    font-size: 1.25em;
  }
}

.alpha-search {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.alpha-search-btn {
  font-weight: 700;
  font-size: 1.875em;
  line-height: 1.1;
  color: #fff;
  cursor: pointer;
}
.alpha-search-btn:hover, .alpha-search-btn.is-active-letter {
  color: #E5B522;
}
.alpha-search-btn.disabled {
  color: #6E6E6E;
  cursor: default;
}

@media screen and (max-width: 56.1875em) {
  .alpha-search {
    flex-wrap: wrap;
  }
  .alpha-search-item {
    margin-top: 0.625em;
    text-align: center;
  }
  .alpha-search-item {
    font-size: 1.375em;
    flex-shrink: 0;
    width: 7.6923076923%;
  }
}
@media screen and (max-width: 39.3125em) {
  .alpha-search-item {
    font-size: 0.875em;
  }
}
.breadcrumbs {
  max-width: 100.3125em;
  padding-left: 1.875em;
  padding-right: 1.875em;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin: 1.5625em auto 2.34375em;
  padding-left: 4.125em;
  line-height: 1.4;
}
@media screen and (max-width: 56.1875em) {
  .breadcrumbs {
    padding-left: 1em;
    padding-right: 1em;
  }
}
.breadcrumbs__locations-landing {
  margin-bottom: 5em;
}
.breadcrumbs__capabilities-landing {
  margin-top: 4.6875em;
}
.breadcrumbs__item:after {
  content: "/";
  display: inline-block;
  padding-left: 0.4em;
  margin-right: 0.4em;
}
.breadcrumbs__item:last-child:after {
  content: none;
}
.breadcrumbs__item--current {
  font-weight: 700;
}
.breadcrumbs__link {
  text-decoration: underline;
}
@media screen and (max-width: 64em) {
  .breadcrumbs {
    padding-left: 1.5em;
  }
  .breadcrumbs__capabilities-landing {
    margin-top: unset;
  }
}
@media screen and (max-width: 47.4375em) {
  .breadcrumbs {
    padding-left: 1em;
  }
}

.menu-toggle {
  color: #E5B522;
  vertical-align: middle;
  position: relative;
  min-width: 1.625em;
  min-height: 1.625em;
}

.menu-toggle-label {
  font-weight: 800;
  font-size: 1.25em;
  margin-left: 1.875em;
  vertical-align: bottom;
}

.menu-toggle-bars {
  transition: color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  display: inline-block;
  width: 1.625em;
  height: 2px;
  background-color: #E5B522;
  transform: translate3d(0, -50%, 0);
}
.menu-toggle-bars:before, .menu-toggle-bars:after {
  transition: background-color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  background-color: #E5B522;
  height: 100%;
  width: 100%;
  position: absolute;
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86), background-color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.menu-toggle-bars:before {
  top: -7px;
  transform: rotate(0deg);
}
.menu-toggle-bars:after {
  bottom: -7px;
  transform: rotate(0deg);
}

.show-site-nav .menu-toggle {
  color: #fff;
}
.show-site-nav .menu-toggle-bars {
  background-color: transparent;
}
.show-site-nav .menu-toggle-bars:before, .show-site-nav .menu-toggle-bars:after {
  background-color: #fff;
}
.show-site-nav .menu-toggle-bars:before {
  top: 0;
  transform: rotate(135deg);
}
.show-site-nav .menu-toggle-bars:after {
  bottom: 0;
  transform: rotate(-135deg);
}

@media screen and (max-width: 56.1875em) {
  .menu-toggle-label {
    font-size: 1em;
  }
  .menu-toggle-bars {
    margin-bottom: -0.1em;
  }
}
@media screen and (max-width: 56.1875em) {
  .menu-toggle-label {
    display: none;
  }
}
.social-links {
  margin: 0;
}

.social-links-item {
  display: inline-block;
  vertical-align: middle;
}

.social-links-icon {
  display: inline-block;
  color: #fff;
}

.social-links-icon:before {
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.loading-screen {
  position: fixed;
  z-index: 490;
  background-color: rgba(217, 217, 217, 0.65);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  display: none;
  transition: opacity 1s cubic-bezier(0.785, 0.135, 0.15, 0.86), visibility 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86) 1s, display 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86) 1s;
}
.loading-screen.show-loading-screen {
  opacity: 1;
  visibility: visible;
  display: block;
  transition: opacity 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86), visibility 0.1s cubic-bezier(0.785, 0.135, 0.15, 0.86), display 0.1s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.loading-screen-body {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading-screen-icon {
  width: 0.625em;
  height: 3em;
  background: #CC092F;
  position: relative;
  animation: animation-bars 1s ease-in-out infinite;
  animation-delay: 0.4s;
  top: 50%;
  margin: -28px auto 0 auto;
}
.loading-screen-icon:before, .loading-screen-icon:after {
  content: "";
  position: absolute;
  width: 10px;
  height: 48px;
  background: #CC092F;
  animation: animation-bars 1s ease-in-out infinite;
}
.loading-screen-icon:before {
  right: 1.125em;
  animation-delay: 0.2s;
}
.loading-screen-icon:after {
  left: 1.125em;
  animation-delay: 0.6s;
}

@keyframes animation-bars {
  0%, 100% {
    box-shadow: 0 0 0 #CC092F, 0 0 0 #CC092F;
  }
  50% {
    box-shadow: 0 -8px 0 #CC092F, 0 8px 0 #CC092F;
  }
}
/*@import "language-menu";*/
.read-more-intro {
  padding-bottom: 1.25em;
}
.read-more-intro:last-child {
  padding-bottom: 0;
}

.read-more-full {
  display: none;
  overflow: hidden;
  padding-bottom: 1.25em;
  margin-left: -0.75em;
  padding-left: 0.75em;
}

.read-more-toggle {
  position: relative;
}
.read-more-toggle .btn-ghost-label:after {
  content: "+";
  display: inline-block;
  margin-left: 0.25em;
}
.read-more-toggle.is-expanded .btn-ghost-label:after {
  content: "–";
}

@media screen and (max-width: 47.4375em) {
  .read-more-toggle {
    width: 100%;
  }
}
.page-header {
  margin-bottom: 5em;
  color: #fff;
  text-align: center;
}
.page-header--capabilities {
  margin-bottom: 2.5em;
}

.page-header-title {
  font-weight: 800;
  font-size: 3.125em;
  line-height: 1.1;
  text-align: center;
}

.page-header-subtitle {
  font-size: 1.625em;
  color: #fff;
  line-height: 1.1;
  text-align: center;
  margin-top: 0.625em;
}

.page-header-section-link {
  width: 26%;
  margin-top: 20px;
}

@media screen and (max-width: 56.1875em) {
  .page-header {
    margin-bottom: 2.5em;
  }
  .page-header-title {
    font-size: 1.75em;
    line-height: 1.35;
  }
  .page-header-subtitle {
    font-size: 1.25em;
    line-height: 1.35;
  }
  .page-header-section-link {
    margin-top: 1.5625em;
  }
}
@media screen and (max-width: 47.4375em) {
  .page-header {
    margin-bottom: 2.5em;
  }
  .page-header-section-link {
    width: 16.25em;
  }
}
.page-header-title--diversity {
  font-weight: 800;
  font-size: 4.125em;
  line-height: 1.1;
  text-align: center;
}

.searchbox {
  position: relative;
  background-color: #fff;
  border: 1px solid #222;
}

.searchbox-input[type=textbox] {
  border-color: transparent;
  line-height: 1.1em;
  width: 100%;
}

.searchbox-btn {
  position: absolute;
  right: 0.625em;
  top: 0;
  height: 100%;
  color: #CC092F;
}
.searchbox-btn:before {
  content: "\e902";
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (max-width: 47.4375em) {
  .searchbox-input {
    font-size: 1.125em;
  }
}
.dropdown {
  position: relative;
}

.dropdown-helper-label {
  font-weight: 800;
}

.dropdown-toggle {
  position: relative;
  width: 100%;
  text-align: left;
  background-color: #E5B522;
  padding: 0.9375em;
  padding-right: 1.875em;
}
.dropdown-toggle:after {
  content: "\e900";
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.625em;
  right: 1.5em;
}

.dropdown-label {
  font-weight: 800;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  width: 100%;
  background-color: #3D3C3C;
  color: #fff;
  padding: 1.5625em;
  opacity: 0;
  visibility: hidden;
  max-height: 21.875em;
  overflow: auto;
}

.dropdown-show {
  opacity: 1;
  visibility: visible;
}

.dropdown-menu-option {
  font-weight: 700;
  color: #fff;
  cursor: pointer;
}
.dropdown-menu-option + .dropdown-menu-option {
  border-top: 1px solid rgba(255, 255, 255, 0.65);
  padding-top: 0.625em;
  margin-top: 0.625em;
}

.experience-group-dropdown {
  max-width: 80%;
}

@media screen and (max-width: 56.1875em) {
  .dropdown-helper-label {
    display: inline-block;
    margin-bottom: 0.625em;
  }
  .experience-group-dropdown {
    max-width: 100%;
  }
}
.select-dropdown {
  position: relative;
}

.select-dropdown-select {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  background: transparent;
  cursor: pointer;
  border: 0;
}

.select-dropdown-toggle {
  color: #181818;
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.1;
  padding: 1em 0.75em;
  padding-right: 1.5em;
  border-color: #d9d9d9;
  background-color: #d9d9d9;
  position: relative;
  transition: background-color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.select-dropdown-toggle:after {
  content: "\e900";
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.3125em;
  right: 1.6875em;
}
.select-dropdown-toggle.focus-highlight-select {
  outline: 0.125em solid #CC092F;
}

@media screen and (max-width: 56.1875em) {
  .select-dropdown-toggle {
    font-size: 1em;
    padding: 1.125em 0.75em;
    padding-right: 1.5em;
  }
}
.angled-background-container {
  position: relative;
  padding: 12vw 0;
  margin: 8vw 0;
}
.angled-background-container--topics {
  padding: 6vw 0;
}

.angled-background-container-slender {
  position: relative;
  padding: 5vw 0;
  margin: 5vw 0;
}
.angled-background-container-slender--diversity {
  margin: 4vw 0;
}

.angled-background {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
  transform: skewY(-6deg) translate3d(0, 0, 0);
  overflow: hidden;
}
.angled-background--topics {
  transform: none;
}
.angled-background:hover .angled-background-image {
  will-change: transform;
}

.angled-background-image {
  background: transparent no-repeat center center;
  background-size: cover;
  position: absolute;
  width: 100%;
  transform: skewY(6deg) translate3d(0, 0, 0);
  top: -6vw;
  bottom: -6vw;
  transition: transform 4s linear;
}
.angled-background-image--topics {
  transform: none;
}

.angled-background--full-slender .angled-background-image {
  top: -6.25vw;
  bottom: -6.25vw;
}
.angled-background--full-slender .angled-background-image:before {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  content: "";
  display: block;
  z-index: 2;
}
.angled-background--full-slender .angled-background-image:before {
  background-color: rgba(0, 0, 0, 0.75);
}
.angled-background--full-slender-diversity .angled-background-image {
  top: -7.25vw;
  bottom: -7.25vw;
  background-attachment: fixed;
}
.angled-background--full-slender-diversity:before {
  content: none;
}

.angled-background--pattern .angled-background-image:after, .angled-background--pattern .angled-background-image:before {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  content: "";
  display: block;
  z-index: 2;
}
.angled-background--pattern .angled-background-image:before {
  background-color: rgba(0, 0, 0, 0.75);
}
.angled-background--pattern .angled-background-image:after {
  background: url("./../images/pattern-bg.png") no-repeat center top;
  z-index: 3;
}

.angled-background--gradient .angled-background-image {
  background-position: center -15px;
  background-size: auto 100%;
  background-repeat: no-repeat;
}
.angled-background--gradient .angled-background-image:before {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  content: "";
  background-color: rgba(0, 0, 0, 0.75);
}
.angled-background--gradient:before {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-image: linear-gradient(180deg, rgba(61, 60, 60, 0.25), #181818 40em);
  content: "";
  display: block;
  z-index: 2;
}

@media screen and (max-width: 62.4375em) {
  .angled-background-container {
    margin: 12vw 0;
  }
}
@media screen and (max-width: 47.4375em) {
  .angled-background-container {
    margin: 5em 0;
  }
  .angled-background-container-slender {
    position: relative;
    padding: 8vw 0;
  }
  .angled-background-container-slender--diversity {
    padding: 15vw 0;
  }
}
.angled-background--diversity {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
  transform: skewY(-8deg) translate3d(0, 0, 0);
  overflow: hidden;
}
.angled-background--diversity:hover .angled-background-image {
  will-change: transform;
}

.angled-background-container-diversity {
  position: relative;
  padding: 12vw 0;
  margin: 0;
}
.angled-background-container-diversity.notable-news {
  padding-left: 0;
  padding-top: 0;
  padding-right: 0;
  margin-bottom: 0;
}

.angled-background-image-diversity {
  background: transparent no-repeat center center;
  background-size: cover;
  position: absolute;
  width: 100%;
  transform: skewY(6deg) translate3d(0, 0, 0);
  top: -6vw;
  bottom: -6vw;
  transition: transform 4s linear;
}
@media screen and (min-width: 64em) {
  .angled-background-image-diversity {
    background-attachment: fixed;
  }
}

.angled-background-diversity--pattern {
  z-index: -1;
}
.angled-background-diversity--pattern .angled-background-image-diversity:after, .angled-background-diversity--pattern .angled-background-image-diversity:before {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  content: "";
  display: block;
  z-index: 2;
}
.angled-background-diversity--pattern .angled-background-image-diversity:after {
  background: no-repeat center top;
  background-size: cover;
  z-index: 3;
}

.angled-background--full-slender-diversity .angled-background-image {
  top: -7.25vw;
  bottom: -7.25vw;
}

@media screen and (min-width: 67.1875em) {
  .angled-background--full-slender-diversity .angled-background-image {
    background-attachment: fixed;
  }
}
@-moz-document url-prefix() {
  .angled-background--diversity {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 1;
    transform: none;
    overflow: hidden;
  }
  .angled-background--diversity:hover .angled-background-image {
    will-change: transform;
  }
  .angled-background-image-diversity {
    background: transparent no-repeat center center;
    background-size: cover;
    position: absolute;
    width: 100%;
    transform: none;
    top: -6vw;
    bottom: -6vw;
    transition: transform 4s linear;
  }
  @media screen and (min-width: 64em) {
    .angled-background-image-diversity {
      background-attachment: fixed;
    }
  }
  .angled-background-diversity--pattern {
    transform: none;
  }
}
.video-player {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  padding: 10%;
  transition: visibility 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.35s, opacity 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.video-player.show-video-player {
  opacity: 1;
  visibility: visible;
  transition: visibility 10ms cubic-bezier(0.785, 0.135, 0.15, 0.86), opacity 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.video-window {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-bottom: 34.25%;
  margin: 0 auto;
  width: 60%;
}
.video-window iframe,
.video-window object {
  position: absolute;
  width: 100% !important;
  height: 100% !important;
}

.video-player-close {
  position: absolute;
  top: 5%;
  right: 5%;
}
.video-player-close:hover:before {
  background-color: rgba(0, 0, 0, 0.6);
}
.video-player-close:before {
  content: "\e904";
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: background-color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  font-size: 1.875em;
  width: 1.6666666667em;
  height: 1.6666666667em;
  border-radius: 25px;
  border: 2px solid #fff;
  text-align: center;
  line-height: 1.5;
}

@media screen and (max-width: 56.1875em) {
  .video-window {
    width: 80%;
    padding-bottom: 45%;
  }
}
.angled-hero {
  min-height: 31.25em;
  color: #fff;
  padding: 2.8125em 0 3.75em;
  text-align: center;
  clear: both;
  position: relative;
}

.angled-hero-firm-identity {
  transition: z-index 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  position: relative;
  text-align: right;
  z-index: 500;
  transition-delay: 0.35s;
}
.show-site-nav ~ .main-content .angled-hero-firm-identity {
  z-index: 55;
  transition: none;
}
.angled-hero-firm-identity .svg-logo-link {
  position: absolute;
  top: 2.8125em;
  right: 1.875em;
  display: block;
  width: 14.6875em;
  height: 2.8125em;
}
.angled-hero-firm-identity .svg-logo-link .logo-img, .angled-hero-firm-identity .svg-logo-link .logo-img-mobile {
  width: 100%;
}
.angled-hero-firm-identity .svg-logo-link .logo-img-mobile {
  display: none;
}
.angled-hero-firm-identity .svg-logo {
  position: absolute;
  top: 0;
  left: 0;
}
.angled-hero-firm-identity .svg-logo path {
  fill: #fff;
}
.angled-hero-firm-identity .hero-into-tagline {
  position: absolute;
  top: 6.3125em;
  right: 1.875em;
  color: #fff;
  width: 11.25em;
}

.angled-hero-firm-identity--black .svg-logo path {
  fill: #222;
}

.angled-hero-content {
  max-width: 63.75em;
  padding-left: 1.875em;
  padding-right: 1.875em;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 55;
  padding-top: 7.5em;
  padding-left: 0;
  padding-right: 0;
}
@media screen and (max-width: 56.1875em) {
  .angled-hero-content {
    padding-left: 1em;
    padding-right: 1em;
  }
}

@media screen and (max-width: 81.1875em) {
  .angled-hero {
    min-height: inherit;
    padding-bottom: 4.5em;
  }
}
@media screen and (max-width: 62.4375em) {
  .angled-hero-firm-identity .svg-logo-link {
    width: 12.5em;
    height: 2.28125em;
  }
  .angled-hero-content {
    padding-top: 5.625em;
  }
}
@media screen and (max-width: 64em) {
  .angled-hero {
    padding-bottom: 3.375em;
  }
  .angled-hero-firm-identity .svg-logo-link {
    width: 10em;
    height: 1.8125em;
    right: 1em;
  }
  .angled-hero-content {
    padding-top: 4.375em;
  }
}
@media screen and (max-width: 47.4375em) {
  .angled-hero {
    padding-top: 1.875em;
    padding-bottom: 3.125em;
    margin-bottom: 2.5em;
  }
  .angled-hero-firm-identity .hero-into-tagline {
    display: none;
  }
  .angled-hero-firm-identity .svg-logo-link {
    top: 1.875em;
    width: 8.75em;
    height: 1.5625em;
  }
  .angled-hero-firm-identity .svg-logo-link .logo-img {
    display: none;
  }
  .angled-hero-firm-identity .svg-logo-link .logo-img-mobile {
    display: block;
  }
  .angled-hero-content {
    padding-top: 3.75em;
  }
}
.page-article-detail__forecast .angled-hero {
  margin-bottom: 0 !important;
}
.page-article-detail__forecast .angled-hero .angled-hero-content-wrapper {
  display: flex;
}
.page-article-detail__forecast .angled-hero .angled-hero-content-wrapper .angled-hero-content {
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 1.875em;
  padding-left: 1.875em;
}
.page-article-detail__forecast .angled-hero .angled-hero-content-wrapper .angled-hero-content .article-header-meta {
  text-align: left;
  font-size: 1.5em;
  margin-bottom: 0.875em;
}
.page-article-detail__forecast .angled-hero .angled-hero-content-wrapper .angled-hero-content .page-header {
  display: flex;
  flex-direction: column-reverse;
}
.page-article-detail__forecast .angled-hero .angled-hero-content-wrapper .angled-hero-content .page-header-title {
  font-weight: 400;
  text-align: left;
  margin-top: 0.3125em;
  font-size: 2.5em;
  line-height: 1.1;
}
@media screen and (max-width: 64em) {
  .page-article-detail__forecast .angled-hero .angled-hero-content-wrapper .angled-hero-content .page-header-title {
    font-size: 1.875em;
  }
}
.page-article-detail__forecast .angled-hero .angled-hero-content-wrapper .angled-hero-content .page-header-subtitle {
  font-size: 1.625em;
  text-align: left;
  margin-top: 0;
}
@media screen and (max-width: 64em) {
  .page-article-detail__forecast .angled-hero .angled-hero-content-wrapper .angled-hero-content {
    width: 100%;
    padding: 5em 1em 2.5em;
  }
}
.page-article-detail__forecast .angled-hero .angled-hero-content-wrapper .angled-hero-image {
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 1.875em;
}
@media screen and (max-width: 64em) {
  .page-article-detail__forecast .angled-hero .angled-hero-content-wrapper .angled-hero-image {
    display: none;
  }
}
.page-article-detail__forecast.forecast-theme__melon .angled-hero-body {
  background-color: #1e9f48;
}
.page-article-detail__forecast.forecast-theme__marine .angled-hero-body {
  background-color: #0373ac;
}
.page-article-detail__forecast.forecast-theme__plum .angled-hero-body {
  background-color: #92338a;
}
.page-article-detail__forecast.forecast-theme__red .angled-hero-body {
  background-color: #cd122d;
}
.page-article-detail__forecast.forecast-theme__olive .angled-hero-body {
  background-color: #558033;
}
.page-article-detail__forecast.forecast-theme__brand-red .angled-hero-body {
  background-color: #CC092F;
}
.page-article-detail__forecast.forecast-theme__royal .angled-hero-body {
  background-color: #283584;
}
.page-article-detail__forecast.forecast-theme__tangerine .angled-hero-body {
  background-color: #E94B00;
}
.page-article-detail__forecast.forecast-theme__dark .angled-hero-body {
  background-color: #696969;
}
.page-article-detail__forecast.forecast-theme__light .angled-hero-body {
  background-color: #6A6A6A;
}

.angled-polygon {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 50;
  overflow: hidden;
  height: 53.6875em;
}

.angled-polygon-svg-wrapper {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 123.125em;
  height: 100%;
  margin-top: -0.25em;
}

#angled-polygon-svg {
  position: absolute;
  left: 0;
  bottom: 0;
}

@media screen and (max-width: 81.1875em) {
  .full-width .angled-polygon-svg-wrapper {
    width: calc(100% + 2em);
  }
}
@media screen and (-ms-high-contrast: active), (max-width: 480px) {
  .angled-polygon-svg-wrapper {
    margin-top: -10%;
  }
}
.angled-hero-slender {
  padding-bottom: 5em;
  min-height: inherit;
  overflow: hidden;
  position: static;
  background-size: cover;
  background-position: center -15px;
  background-repeat: no-repeat;
}
.angled-hero-slender .angled-hero-content {
  z-index: 51;
}
.angled-hero-slender.german-header {
  margin-bottom: 0;
}
.angled-hero-slender.german-header .angled-hero-content {
  padding-bottom: 5em;
}
.angled-hero-slender.german-header .angled-hero-content-slender {
  padding-bottom: 7vw;
  margin-bottom: 3.5vw;
}
.angled-hero-slender.german-header .angled-hero-content-slender:before {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  content: "";
  top: -2.8125em;
  width: 123.125em;
  height: 100%;
  transform: translateX(-50%) skewY(-9deg);
  box-shadow: 0 7px 20px 4px rgba(0, 0, 0, 0.5);
  backface-visibility: hidden;
}
.angled-hero-slender.german-header .angled-hero-slender-image {
  background-position: center top;
}

.angled-hero-content-slender {
  position: relative;
}

.angled-hero-slender-polygon {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -2.8125em;
  bottom: auto;
  width: 123.125em;
  overflow: hidden;
  height: 100%;
  transform: translateX(-50%) skewY(-9deg);
  backface-visibility: hidden;
  /*&--diversity {
      transform: none;
  }*/
}

.angled-hero-slender-image {
  background: transparent no-repeat center center;
  background-size: cover;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  background-color: #3D3C3C;
  background-position: center -15px;
  background-repeat: no-repeat;
  transform: skewY(9deg);
  bottom: -9vw;
}
.angled-hero-slender-image:before {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  content: "";
  background-color: rgba(0, 0, 0, 0.75);
}

@media screen and (max-width: 56.1875em) {
  .angled-hero-slender {
    padding-bottom: 2.5em;
    background-position: right;
  }
  .angled-hero-content-slender {
    padding-bottom: 8vw;
  }
}
@media screen and (max-width: 47.4375em) {
  .angled-hero-slender {
    padding-bottom: 0.5em;
  }
}
.angled-hero-content-slender--diversity {
  position: relative;
  padding-bottom: 7vw;
  margin-bottom: 0;
}

.angled-hero-slender-image--diversity {
  background: transparent no-repeat center center;
  background-size: cover;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  background-color: #3D3C3C;
  background-position: center -15px;
  background-repeat: no-repeat;
  transform: skewY(9deg);
  bottom: -9vw;
}
.angled-hero-slender-image--diversity:before {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  content: "";
}

.angled-hero-slender-polygon--diversity {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -2.8125em;
  bottom: auto;
  width: 123.125em;
  overflow: hidden;
  height: 100%;
  backface-visibility: hidden;
}

.detail-angled-hero {
  margin-bottom: 15vw;
  min-height: 25.3125em;
  height: auto;
  color: #fff;
  position: relative;
  z-index: 50;
}
.detail-angled-hero .angled-hero-background-container {
  position: absolute;
  width: 100%;
  max-width: 100.3125em;
  height: 135%;
  top: 0;
  left: 0;
  overflow: hidden;
}
@media screen and (min-width: 96.5625em) {
  .detail-angled-hero .angled-hero-background-container {
    left: 50%;
    transform: translateX(-50%);
  }
}
.detail-angled-hero .angled-hero-reference {
  position: relative;
  height: 100%;
  width: 100%;
  position: relative;
  background-color: white;
}
.detail-angled-hero .angled-hero-reference:after {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background: inherit;
  content: "";
  display: block;
  height: 50%;
  left: 50%;
  width: 101%;
  padding-bottom: 10%;
  position: absolute;
  z-index: 5;
  transform: translate(-50%, 0) skewY(-8deg);
}
.detail-angled-hero .angled-hero-reference:before {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  transform: translate(-50%, 0) skewY(-8deg);
  top: 100%;
}
.detail-angled-hero .angled-hero-reference:after {
  bottom: -27.5%;
}
.detail-angled-hero .angled-hero-design-bg {
  height: 100%;
  width: 100%;
}
.detail-angled-hero .angled-hero-firm-identity,
.detail-angled-hero .angled-hero-content {
  position: relative;
  z-index: 5;
}
.detail-angled-hero .angled-hero-firm-identity {
  color: #fff;
  text-align: right;
  float: right;
  width: 235px;
  margin-top: 2em;
}
.detail-angled-hero .angled-hero-firm-identity .svg-logo {
  width: 100%;
}
.detail-angled-hero .angled-hero-firm-identity .svg-logo path {
  fill: #fff;
}
.detail-angled-hero .angled-hero-content {
  clear: both;
  padding-top: 5em;
}
@media screen and (max-width: 64em) {
  .detail-angled-hero .angled-hero {
    margin-bottom: 0;
    height: auto;
    min-height: 45.3125em;
  }
  .detail-angled-hero .angled-hero .page-wrapper {
    padding-bottom: 26%;
  }
}
@media screen and (max-width: 62.4375em) {
  .detail-angled-hero .angled-hero-firm-identity {
    width: 200px;
  }
}
@media screen and (max-width: 56.1875em) {
  .detail-angled-hero .angled-hero {
    min-height: inherit;
  }
  .detail-angled-hero .angled-hero-content {
    padding-top: 5em;
  }
  .detail-angled-hero .angled-hero-firm-identity {
    width: 11.25em;
  }
}
@media screen and (max-width: 47.4375em) {
  .detail-angled-hero .angled-hero .page-wrapper {
    padding-bottom: 30%;
  }
  .detail-angled-hero .angled-hero-content {
    padding-top: 2.5em;
  }
  .detail-angled-hero .angled-hero-firm-identity {
    width: 8.125em;
  }
}
@media screen and (max-width: 47.4375em) {
  .detail-angled-hero {
    min-height: 19.0625em;
  }
}
@media screen and (max-width: 39.3125em) {
  .detail-angled-hero .angled-hero .page-wrapper {
    padding-bottom: 120px;
  }
}
.columns {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.columns:after {
  content: " ";
  display: block;
  clear: both;
}
.columns .col {
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .columns .col {
    clear: both !important;
    width: 100% !important;
    float: left !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
.columns.columns-2 .col {
  width: 48.93625%;
  float: left;
  margin-right: 2.1275%;
}
.columns.columns-2 .col:nth-child(even) {
  margin-right: 0;
}

.listing-articles-grid {
  margin: 0 -1.875em;
  display: flex;
  flex-wrap: wrap;
}
.listing-articles-grid:after {
  content: "";
  display: table;
  clear: both;
}

.listing-articles-grid-item {
  flex-shrink: 0;
  width: 25%;
  padding: 0 1.875em;
  margin-bottom: 2.5em;
}

.listing-articles-grid-title {
  font-size: 1.375em;
  line-height: 1.5;
  display: inline-block;
}
.listing-articles-grid-title:not(.no-link):hover {
  color: #6E6E6E;
}
.listing-articles-grid-title:not(.no-link):hover:after {
  transform: translateX(0.5em);
}
.listing-articles-grid-title:not(.no-link):after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "➞";
  display: inline-block;
  margin-left: 0.5em;
}

@media screen and (max-width: 67.125em) {
  .listing-articles-grid {
    margin-left: -1em;
    margin-right: -1em;
  }
  .listing-articles-grid-item {
    padding: 0 1em;
  }
}
@media screen and (max-width: 56.1875em) {
  .listing-articles-grid-item {
    width: 50%;
    margin-bottom: 2.5em;
  }
  .listing-articles-grid-title {
    font-size: 1em;
  }
}
@media screen and (max-width: 39.3125em) {
  .listing-articles-grid {
    display: block;
  }
  .listing-articles-grid-item {
    width: 100%;
  }
}
.listing-capabilities-option {
  padding: 1.375em 0;
  border-bottom: 1px solid #979797;
}

.listing-capabilities-link {
  font-weight: 700;
  font-size: 1em;
  line-height: 1.2;
}

.capabilities-toggle {
  margin: 45px 0;
  text-align: right;
}
.capabilities-toggle .expand {
  float: right;
}

@media screen and (max-width: 56.1875em) {
  .listing-capabilities-option {
    padding: 0.9375em 0;
  }
}
@media screen and (max-width: 47.4375em) {
  .capabilities-toggle {
    text-align: center;
  }
}
.listing-experience {
  padding-bottom: 1.25em;
}

.listing-experience-item {
  background-color: #f3f3f3;
  padding: 2em;
}
.listing-experience-item + .listing-experience-item {
  margin-top: 0.5em;
}

.listing-articles-item {
  font-size: 0.8em;
  padding-bottom: 3em;
  border-bottom: 2px solid #979797;
}
.listing-articles-item + .listing-articles-item {
  padding-top: 3em;
}

.listing-articles-meta {
  font-size: 1.5em;
  line-height: 1.5;
  margin-bottom: 0.8333333333em;
}

.listing-articles-type {
  font-weight: 700;
}
.listing-articles-type:after {
  content: "-";
  display: inline-block;
  margin-right: 0.25em;
  margin-left: 0.5em;
  font-weight: 400;
}

.listing-articles-date ~ .listing-articles-location:before, .listing-articles-date ~ .listing-articles-source:before {
  content: "|";
  display: inline-block;
  margin-right: 0.25em;
}

.listing-articles-title {
  font-weight: 300;
  font-size: 2.25em;
  color: #CC092F;
  line-height: 1.3;
  overflow: hidden;
  background-image: linear-gradient(to bottom, #5B5B5B 0%, #5B5B5B 100%);
  background-repeat: no-repeat;
  background-position: 0 38px;
  background-size: 0 auto;
  transition: color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86), background 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.listing-articles-title:hover {
  background-size: 100% auto;
  color: #5B5B5B;
}

@media all and (min--moz-device-pixel-ratio: 0) {
  .listing-articles-title {
    background-position: 0 38px;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .listing-articles-title {
    background-image: none;
    position: relative;
  }
  .listing-articles-title:before {
    content: "";
    position: absolute;
    display: inline-block;
    width: 0%;
    bottom: -1px;
    left: 0;
    height: 1px;
    background-color: #5B5B5B;
    transition: 0.35s;
  }
  .listing-articles-title:hover:before {
    width: 100%;
  }
}
.listing-capabilities-link {
  font-weight: 700;
  font-size: 1em;
  line-height: 1.2;
}

@media screen and (max-width: 56.1875em) {
  .listing-articles-item {
    font-size: 1em;
    padding-bottom: 2.5em;
  }
  .listing-articles-item + .listing-articles-item {
    padding-top: 2.5em;
  }
  .listing-articles-meta {
    font-size: 1em;
  }
  .listing-articles-title {
    font-size: 1.25em;
    background-position: 0 26px;
  }
}
.listing-links-group {
  margin-top: 1.5625em;
}
.listing-links-group__child:not(:last-child) {
  padding-bottom: 1.5625em;
}
.listing-links-group__link {
  font-size: 1.25em;
  color: #CC092F;
}
.listing-links-group__link:hover {
  color: #3D3C3C;
}

.hover-grow-fade-fx, .hover-grow-fade-fx-diversity {
  position: relative;
  overflow: hidden;
}
.hover-grow-fade-fx > *, .hover-grow-fade-fx-diversity > * {
  z-index: 5;
}
.hover-grow-fade-fx:hover .hover-grow-fade-fx-img, .hover-grow-fade-fx-diversity:hover .hover-grow-fade-fx-img {
  transform: scale(1.25);
}

.hover-grow-fade-fx-img {
  background: transparent no-repeat center center;
  background-size: cover;
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: transform 2s;
}

.article-tile-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 -0.9375em;
}
.article-tile-grid .article-tile {
  margin: 0 0.9375em 1.875em;
}

.article-tile {
  min-height: 20.25em;
  background: #3D3C3C;
}

.article-tile-heading {
  height: 100%;
  display: block;
  position: relative;
  overflow: hidden;
  z-index: 5;
  display: flex;
}
.article-tile-heading.is-multimedia:after {
  content: "\e903";
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.6);
  color: #E5B522;
  font-size: 20px;
  line-height: 3;
  text-align: center;
  display: block;
  z-index: 5;
  padding-left: 0.25em;
  width: 3em;
  height: 3em;
  border-radius: 30px;
  border: 1px solid #fff;
  clear: both;
  transition: color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86), border-color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86), opacity 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86), background-color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  opacity: 0.5;
}
.article-tile-heading.is-multimedia:hover .video-highlight-img {
  transform: scale(1.25) translateX(-40%);
}
.article-tile-heading.is-multimedia:hover:after {
  opacity: 1;
}

.article-tile-card {
  width: 16.25em;
  max-width: 100%;
  min-height: 1px;
  margin: 1.8rem;
  padding: 1.5em;
  background: #fff;
  color: #3D3C3C;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.5);
}
.article-tile-card__list-link {
  display: block;
}
.article-tile-card__list-link:hover {
  text-decoration: underline;
}
.article-tile-card__list-link:not(:last-child) {
  border-bottom: 1px solid;
  padding-bottom: 1.5625em;
  margin-bottom: 1.5625em;
}

.article-tile-title {
  font-size: 1.375em;
  font-weight: 200;
  line-height: 1.4;
  margin-bottom: 0.5em;
}

.article-tile-abstract {
  font-weight: 200;
  line-height: 1.5;
  flex-grow: 1;
}

.article-tile-details {
  margin-top: 1.375em;
}
.article-tile-details:before {
  content: "";
  display: block;
  width: 4em;
  height: 1px;
  margin-bottom: 1em;
  background: #3D3C3C;
}
.article-tile-details .article-tile-source, .article-tile-details .article-tile-type {
  font-size: 0.875em;
  margin-bottom: 0.5em;
}
.article-tile-details .article-tile-date {
  font-size: 0.75em;
  display: inline-block;
}
.article-tile-details .article-tile-location {
  display: inline;
  font-size: 0.75em;
}
.article-tile-details .article-tile-location:before {
  content: "|";
  margin-right: 4px;
}

@media screen and (max-width: 67.125em) {
  .article-tile {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 56.1875em) {
  .article-tile {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 47.4375em) {
  .article-tile {
    width: 100%;
  }
}
@media screen and (max-width: 26.1875em) {
  .article-tile {
    height: auto;
    min-height: auto;
  }
  .article-tile-card {
    min-height: 9.6875em;
    width: 100%;
  }
}
.section-card {
  position: relative;
  background-color: #373737;
  overflow: hidden;
}

.section-card-header {
  height: 100%;
  min-height: 20.25em;
  display: block;
  color: #fff;
  position: relative;
  z-index: 5;
  padding: 1.5625em 1.25em 1.5625em;
}
.section-card-header:hover .related-item-tile-heading {
  color: #9d9d9d;
}
.section-card-header:hover .related-item-tile-subheading {
  color: #9d9d9d;
}

.section-card-heading {
  font-weight: 300;
  font-size: 2.125em;
  transition: color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  margin-top: 0.6470588235em;
  max-width: 16.0294117647em;
  display: block;
}

@media screen and (max-width: 67.125em) {
  .section-card-header {
    padding-bottom: 18%;
    min-height: 20em;
  }
}
@media screen and (max-width: 56.1875em) {
  .section-card-header {
    padding-bottom: 2.5em;
  }
  .section-card-heading {
    font-size: 1.5em;
    line-height: 1.35;
    max-width: none;
  }
}
@media screen and (max-width: 47.4375em) {
  .section-card-header {
    min-height: 13.4375em;
  }
}
.featured-tile {
  position: relative;
  background-color: #373737;
  overflow: hidden;
}

.featured-tile--prominent .featured-tile-header {
  min-height: 39em;
  padding-right: 30%;
}
.featured-tile--prominent .featured-tile-heading {
  font-size: 3em;
  max-width: none;
}
.featured-tile--prominent .featured-tile-meta {
  font-size: 1.625em;
  margin-bottom: 0.8461538462em;
}
.featured-tile--prominent .featured-tile-heading,
.featured-tile--prominent .featured-tile-abstract {
  max-width: none;
}

.featured-tile-header {
  height: 100%;
  min-height: 20.25em;
  display: block;
  color: #fff;
  position: relative;
  z-index: 5;
  padding: 1.5625em 1.25em 1.5625em;
}
.featured-tile-header:hover .featured-tile-heading {
  color: #9d9d9d;
}
.featured-tile-header.is-external-link:after {
  display: none;
}
.featured-tile-header.is-external-link .featured-tile-heading:after {
  content: "\e90b";
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  font-size: 0.5em;
  margin-left: 0.5em;
  margin-top: 1em;
  vertical-align: middle;
}

.featured-tile-meta {
  font-weight: 300;
  font-size: 1.25em;
  line-height: 1.5;
  margin-bottom: 1.1em;
  display: inline-block;
}

.featured-tile-type {
  font-weight: 700;
}
.featured-tile-type:after {
  content: "-";
  display: inline-block;
  margin-right: 0.25em;
  margin-left: 0.5em;
  font-weight: 400;
}

.featured-tile-date ~ .featured-tile-location:before, .featured-tile-date ~ .featured-tile-source:before {
  content: "|";
  display: inline-block;
  margin-right: 0.25em;
}

.featured-tile-abstract {
  margin-top: 1.0416666667em;
  max-width: 22.7083333333em;
}

.featured-tile-heading {
  font-weight: 300;
  font-size: 2.125em;
  transition: color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  max-width: 16.0294117647em;
  display: block;
}

.featured-tile-subheading {
  font-size: 1.625em;
  margin-top: 0.3846153846em;
}

.related-entity-tile-featured {
  font-weight: 800;
  background-color: #CC092F;
  padding: 0.25em 0.75em;
  margin-right: 1em;
}

@media screen and (max-width: 67.125em) {
  .featured-tile-header {
    padding-bottom: 18%;
    min-height: inherit;
  }
}
@media screen and (max-width: 56.1875em) {
  .featured-tile-header {
    min-height: 20em;
    padding-bottom: 2.5em;
  }
  .featured-tile-heading {
    font-size: 1.5em;
    line-height: 1.35;
    max-width: none;
  }
  .feature-tile-abstract {
    max-width: none;
  }
  .featured-tile--prominent .featured-tile-header {
    min-height: 60vw;
    padding-right: 1.5625em;
  }
  .featured-tile--prominent .featured-tile-meta {
    font-size: 1.25em;
  }
  .featured-tile--prominent .featured-tile-heading {
    font-size: 2.25em;
  }
}
@media screen and (max-width: 47.4375em) {
  .featured-tile-header {
    min-height: 13.4375em;
  }
  .featured-tile--prominent .featured-tile-header {
    min-height: 18.8125em;
    padding-right: 1.5625em;
  }
  .featured-tile--prominent .featured-tile-heading {
    font-size: 1.5em;
  }
  .featured-tile-abstract {
    display: none;
  }
}
.recognized-news-award {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.recognized-news-award + .recognized-news-award {
  margin-top: 5em;
}
.recognized-news-award:nth-child(2n) {
  flex-direction: row-reverse;
}

.recognized-news-award-thumbnail {
  width: 24.375em;
  flex-shrink: 0;
}

.recognized-news-award-info {
  flex-grow: 1;
  padding: 0 5em;
}

.recognized-news-award-header {
  font-weight: 300;
  font-size: 2.25em;
  line-height: 1.2;
  color: #CC092F;
}
.recognized-news-award-header a {
  position: relative;
  transition: none;
  color: #CC092F;
  display: inline-block;
  display: inline;
}
.recognized-news-award-header a:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0%;
  bottom: -1px;
  left: 0;
  height: 1px;
  background-color: #5B5B5B;
  transition: 0.35s;
}
.recognized-news-award-header a:hover {
  color: #5B5B5B;
}
.recognized-news-award-header a:hover:before {
  width: 100%;
}
@media screen and (max-width: 47.4375em) {
  .recognized-news-award-header a:before {
    display: none;
  }
  .recognized-news-award-header a:hover:before {
    display: none;
  }
}

.recognized-news-award-abstract {
  margin-top: 0.5em;
}

@media screen and (max-width: 62.4375em) {
  .recognized-news-award-thumbnail {
    width: 35%;
  }
}
@media screen and (max-width: 56.1875em) {
  .recognized-news-award + .recognized-news-award {
    margin-top: 2.5em;
  }
  .recognized-news-award-info {
    padding: 0 1.375em;
  }
  .recognized-news-award-thumbnail {
    width: 32%;
  }
  .recognized-news-award-header {
    font-size: 24px;
  }
}
@media screen and (max-width: 39.3125em) {
  .recognized-news-award {
    display: block;
  }
  .recognized-news-award-info {
    padding: 0;
  }
  .recognized-news-award-thumbnail {
    width: 50%;
    margin: 0 auto 1.375em;
  }
}
@media screen and (max-width: 39.3125em) {
  .recognized-news-award-thumbnail {
    width: 70%;
  }
}
.professional-card {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 0.625em;
}
.professional-card:hover .professional-card-header:before {
  width: 100%;
}

.professional-card-thumbnail {
  width: 16.625em;
  margin-right: 1em;
  flex-shrink: 0;
}
.professional-card-thumbnail + .professional-card-info {
  margin-bottom: 1.5625em;
}

.professional-card-info {
  flex-grow: 1;
  margin-bottom: 5em;
}

.professional-card-office {
  display: inline-block;
  position: relative;
  transition: none;
  color: #CC092F;
  display: inline-block;
  margin-top: 10px;
  font-size: 1.25em;
}
.professional-card-office:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0%;
  bottom: -1px;
  left: 0;
  height: 1px;
  background-color: #5B5B5B;
  transition: 0.35s;
}
.professional-card-office:hover {
  color: #5B5B5B;
}
.professional-card-office:hover:before {
  width: 100%;
}
@media screen and (max-width: 47.4375em) {
  .professional-card-office:before {
    display: none;
  }
  .professional-card-office:hover:before {
    display: none;
  }
}

.professional-card-position {
  font-size: 1.25em;
}

.professional-card-link {
  font-weight: 800;
  font-size: 2.25em;
  line-height: 1.1;
  color: #CC092F;
  background-image: linear-gradient(to bottom, #5B5B5B 0%, #5B5B5B 100%);
  background-repeat: no-repeat;
  background-position: 0 1.34em;
  background-size: 0 auto;
  transition: color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86), background 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.professional-card-link:hover {
  background-size: 100% auto;
  color: #5B5B5B;
}
.professional-card-link--diversity {
  color: #1e9f48;
  font-weight: 700;
}

.professional-office-position {
  margin-top: 5px;
}

@media all and (min--moz-device-pixel-ratio: 0) {
  .professional-card-link {
    background-position: 0 1.36em;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .professional-card-link {
    background-image: none;
    position: relative;
  }
  .professional-card-link:before {
    content: "";
    position: absolute;
    display: inline-block;
    width: 0%;
    bottom: -1px;
    left: 0;
    height: 1px;
    background-color: #5B5B5B;
    transition: 0.35s;
  }
  .professional-card-link:hover:before {
    width: 100%;
  }
}
@media screen and (max-width: 67.125em) {
  .professional-card-thumbnail {
    width: 45%;
  }
}
@media screen and (max-width: 56.1875em) {
  .professional-card-position {
    font-size: 1em;
  }
  .professional-card-thumbnail {
    width: 14.0625em;
  }
  .professional-card-link {
    font-size: 1.75em;
  }
  .professional-card-info {
    max-width: 37.5em;
  }
}
@media screen and (max-width: 39.3125em) {
  .professional-card {
    max-width: 90%;
    display: block;
    text-align: center;
    margin-bottom: 2.5em;
  }
  .professional-card-thumbnail {
    width: 14.0625em;
    margin: 0 auto 1.375em;
  }
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 40%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: "Open Sans", sans-serif;
}
@media screen and (max-width: 56.1875em) {
  .modal-container {
    z-index: 501;
    width: 100%;
    padding: 5px;
  }
}

.modal-header h4 {
  margin-top: 0;
  color: #3D3C3C;
  font-weight: 700;
}

.modal-body {
  margin: 20px 0;
  color: #3D3C3C;
}

.modal-footer {
  padding-bottom: 20px;
  color: #3D3C3C;
}

.modal-default-button {
  float: right;
}
.modal-default-button.btn-left {
  padding-right: 10px;
}

.modal-lock {
  overflow: hidden;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.email-form__input-wrapper {
  margin-bottom: 2rem;
  text-align: left;
}
.email-form__textarea {
  width: 100%;
  min-height: 200px;
  resize: none;
}
.email-form__modal__close {
  margin: 0 0 0.3125em 95%;
}
.email-form__modal__close__icon {
  width: 75%;
  height: 75%;
}
.email-form__wrapper.hide {
  display: none !important;
}
.email-form__recaptcha {
  margin-bottom: 2rem;
}
.email-form__error {
  color: #CC092F;
}
.email-form__hidden {
  display: none;
}
@media screen and (max-width: 56.1875em) {
  .email-form__textarea {
    min-height: 100px;
  }
}

.v-card__input-wrapper {
  margin-bottom: 2rem;
  text-align: left;
}
.v-card__modal__close {
  margin: 0 0 0.3125em 95%;
}
.v-card__modal__close__icon {
  width: 75%;
  height: 75%;
}
.v-card__wrapper.hide {
  display: none !important;
}
.v-card__recaptcha {
  margin-bottom: 2rem;
}
.v-card__error {
  color: #CC092F;
}

.global-perspective-region {
  position: absolute;
  padding-left: 40%;
  visibility: hidden;
  opacity: 0;
  top: 0;
  left: 0;
  z-index: 1;
  min-height: 25em;
}
.global-perspective-region.is-region-shown {
  visibility: visible;
  opacity: 1;
  position: relative;
  z-index: 10;
}

.global-perspectives-video {
  margin: 1.375em 0;
}
.global-perspectives-video .btn-ghost-label:after {
  content: "\e90e";
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-left: 0.5em;
  margin-top: 0.1em;
}

.is-transitioning-out .global-perspective-region-figure {
  clip-path: inset(100% 0 0 0);
}
.is-transitioning-out .global-perspective-region-title,
.is-transitioning-out .global-perspective-region-abstract {
  opacity: 0;
}

.global-perspective-region-title {
  font-weight: 300;
  font-size: 2.25em;
  color: #B58F1B;
  position: relative;
  z-index: 15;
  opacity: 1;
  transition: opacity 350ms linear;
}
.global-perspective-region-title:hover {
  color: #5B5B5B;
}

.global-perspective-region-abstract {
  opacity: 1;
  transition: opacity 350ms linear;
  margin-top: 1.25em;
}

.global-perspective-region-figure {
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-25%, -25%);
  z-index: -1;
  width: 40%;
  transition: all 350ms linear;
  clip-path: inset(0 0 0 0);
}
.global-perspective-region-figure > img {
  width: 100%;
  display: block;
}

@media screen and (max-width: 56.1875em) {
  .global-perspective-region {
    min-height: 18.75em;
  }
  .global-perspective-region-figure {
    width: 50%;
  }
  .global-perspective-region-title {
    font-size: 1.5em;
  }
}
@media screen and (max-width: 47.4375em) {
  .global-perspective-region {
    padding-left: 0;
    min-height: 9.375em;
  }
  .global-perspective-region-figure {
    display: none;
  }
}
.newsletter-carousel-issue {
  padding: 0 1.5625em;
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  transition: all 350ms linear;
  z-index: -1;
}
.newsletter-carousel-issue.is-issue-shown {
  opacity: 1;
  position: static;
  z-index: 10;
  width: auto;
}

.newsletter-carousel-issue-title {
  font-weight: 300;
  font-size: 2.25em;
  color: #CC092F;
  transition: all 350ms linear;
}
.newsletter-carousel-issue-title:hover {
  color: #5B5B5B;
}
.newsletter-carousel-issue-title .is-external-link:after {
  position: absolute;
  margin-left: 0.25em;
  margin-top: 0.65em;
  font-size: 0.5em;
}

.newsletter-carousel-issue-abstract {
  margin-top: 1.25em;
  transition: all 350ms linear;
}

.newsletter-carousel-issues {
  height: calc(100% + 100px);
  margin-top: -100px;
  float: right;
  width: 31%;
  padding: 0 2% 5em 2%;
  color: #fff;
  background-color: #3D3C3C;
}

.newsletter-carousel-issue-figure {
  position: absolute;
  height: calc(100% + 160px);
  top: calc(-1 * 160px);
  width: 29%;
  bottom: 0;
  left: 0;
  overflow: hidden;
  transition: all 350ms linear;
  transform: translateZ(0);
}
@media screen and (max-width: 74.9375em) {
  .newsletter-carousel-issue-figure {
    height: calc(100% + 120px);
    top: calc(-1 * 120px);
  }
}

.is-transitioning-out .newsletter-carousel-issue-title,
.is-transitioning-out .newsletter-carousel-issue-abstract,
.is-transitioning-out .newsletter-carousel-issue-figure {
  opacity: 0;
}

@media screen and (max-width: 62.4375em) {
  .newsletter-carousel-issue {
    width: 50%;
  }
  .newsletter-carousel-issue-figure {
    display: none;
  }
}
@media screen and (max-width: 56.1875em) {
  .newsletter-carousel-issue {
    left: 1em;
    right: 1em;
  }
  .newsletter-carousel-issue-title {
    font-size: 1.5em;
  }
}
@media screen and (max-width: 47.4375em) {
  .newsletter-carousel-issue {
    padding: 0;
    width: auto;
  }
  .newsletter-carousel-issue-abstract {
    margin-top: 1.25em;
  }
  .is-transitioning-out .newsletter-carousel-issue-figure {
    opacity: 0;
  }
}
@media screen and (max-width: 47.4375em) and (max-width: 81.1875em) {
  .newsletter-carousel-issue-figure {
    top: -160px;
  }
}
.article-intro {
  padding-bottom: 3em;
}

.article-intro-abstract {
  margin-bottom: 1.375em;
}

.article-intro-authors {
  margin-bottom: 0;
}

.article-intro-authors,
.content-categories {
  font-family: "Lora", serif;
  font-size: 1.25em;
  line-height: 1.5;
  margin-top: 1.25em;
}
@media screen and (max-width: 56.1875em) {
  .article-intro-authors,
  .content-categories {
    font-size: 0.9375em;
  }
}

.article-intro-author {
  position: relative;
  transition: none;
  color: #CC092F;
  display: inline-block;
  font-weight: 700;
}
.article-intro-author:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0%;
  bottom: -1px;
  left: 0;
  height: 1px;
  background-color: #5B5B5B;
  transition: 0.35s;
}
.article-intro-author:hover {
  color: #5B5B5B;
}
.article-intro-author:hover:before {
  width: 100%;
}
@media screen and (max-width: 47.4375em) {
  .article-intro-author:before {
    display: none;
  }
  .article-intro-author:hover:before {
    display: none;
  }
}
.article-intro-author:after {
  content: ",";
  color: #3D3C3C;
}
.article-intro-author:last-child:after {
  content: "";
}

@media screen and (max-width: 56.1875em) {
  .article-intro {
    padding-bottom: 2.5em;
  }
}
.location-searchbox {
  position: relative;
}

.location-searchbox-input[type=text] {
  border-color: transparent;
  line-height: 1.1em;
  width: 100%;
}

.location-searchbox-nearest-office {
  position: absolute;
  right: 14px;
  top: 0;
  height: 100%;
  color: #CC092F;
}
.location-searchbox-nearest-office:hover {
  color: #3D3C3C;
}
.location-searchbox-nearest-office:before {
  content: "\e908";
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (max-width: 47.4375em) {
  .searchbox-input {
    font-size: 1.125em;
  }
}
.sidenav-section + .sidenav-section {
  margin-top: 1.375em;
}

.sidenav-section-header {
  position: relative;
}

.sidenav-section-title {
  position: relative;
  color: #fff;
  display: table;
  width: 100%;
  height: 4.0625em;
  padding-right: 4.6875em;
  padding-left: 1.25em;
}
.sidenav-section-title.is-current {
  cursor: default;
}
.sidenav-section-title:hover .sidenav-section-skew:after, .sidenav-section-title.is-current .sidenav-section-skew:after {
  right: 0;
}

.sidenav-section-skew {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: block;
  transform: skewX(-8deg);
  background-color: #CC092F;
  z-index: 1;
  backface-visibility: hidden;
}
.sidenav-section-skew:after {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  transition: right 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  background-color: #3D3C3C;
  right: 100%;
  backface-visibility: hidden;
}
.sidenav-section-skew--diversity {
  background-color: #1e9f48;
}
.sidenav-section-skew--winrs {
  background-color: #E76D25;
}
.sidenav-section-skew--probono {
  background-color: #0373ac;
}
.sidenav-section-skew--responsible-business {
  background-color: #E76D25;
}

.sidenav-section-title-label {
  font-weight: 800;
  font-size: 1.25em;
  display: table-cell;
  vertical-align: middle;
  position: relative;
  z-index: 3;
  overflow: hidden;
  padding: 0.75em 0;
}

.sidenav-children-trigger {
  width: 4.0625em;
  position: absolute;
  right: 0;
  top: 0;
  bottom: -1px;
  z-index: 4;
  color: #fff;
  overflow: visible;
}
.sidenav-children-trigger:after {
  font-weight: 800;
  font-size: 3em;
  content: "+";
  line-height: 1;
  z-index: 6;
  position: relative;
}
.sidenav-children-trigger .sidenav-section-skew {
  right: -0.0625em;
  top: -0.0625em;
  background-color: #3D3C3C;
  border: 1px solid #3D3C3C;
}
.sidenav-children-trigger .sidenav-section-skew:after {
  background-color: #fff;
}
.sidenav-children-trigger.is-current .sidenav-section-skew--diversity, .sidenav-children-trigger.is-current .sidenav-section-skew--winrs, .sidenav-children-trigger.is-current .sidenav-section-skew--probono, .sidenav-children-trigger.is-current .sidenav-section-skew--responsible-business {
  background-color: #fff;
}
.sidenav-children-trigger.is-current .sidenav-section-skew--diversity::after {
  background-color: #1e9f48;
}
.sidenav-children-trigger.is-current .sidenav-section-skew--winrs::after {
  background: #E76D25;
}
.sidenav-children-trigger.is-current .sidenav-section-skew--probono::after {
  background: #0373ac;
}
.sidenav-children-trigger.is-current .sidenav-section-skew--winrs::after {
  background: #e51937;
}
.sidenav-children-trigger.is-current:after {
  color: #3D3C3C;
}
.sidenav-children-trigger:hover, .sidenav-children-trigger[aria-expanded=true] {
  color: #3D3C3C;
}
.sidenav-children-trigger:hover .sidenav-section-skew:after, .sidenav-children-trigger[aria-expanded=true] .sidenav-section-skew:after {
  right: 0;
}

.sidenav-section.is-expanded .sidenav-children-trigger {
  padding-bottom: 0.5em;
}
.sidenav-section.is-expanded .sidenav-children-trigger:after {
  content: "–";
}

.sidenav-children:not(.sidenav-grandchildren) {
  transition: height 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  padding: 0 -0.625em;
  height: 0;
  overflow: hidden;
}
.sidenav-children:not(.sidenav-grandchildren)[aria-hidden=false] {
  height: auto;
}

.sidenav-children-option, .sidenav-grandchildren-option {
  padding: 1em 0;
  margin-left: 8%;
  margin-right: 1em;
}
.sidenav-children-option + .sidenav-children-option, .sidenav-grandchildren-option + .sidenav-children-option, .sidenav-children-option + .sidenav-grandchildren-option, .sidenav-grandchildren-option + .sidenav-grandchildren-option {
  border-top: 1px solid #979797;
}

.sidenav-children-title {
  font-weight: 700;
  font-size: 1.125em;
  color: #CC092F;
}
.sidenav-children-title:hover {
  color: #5B5B5B;
}
.sidenav-children-title.sidenav-current-page-link {
  color: #5B5B5B;
}
.sidenav-children-title--diversity {
  color: #1e9f48;
}
.sidenav-children-title--winrs {
  color: #E76D25;
}
.sidenav-children-title--probono {
  color: #0373ac;
}
.sidenav-children-title--responsible-business {
  color: #e51937;
}

.sitenav-mobile-toggle {
  font-weight: 800;
  padding: 1em;
  padding-right: 2.5em;
  background-color: #3D3C3C;
  display: block;
  color: #fff;
  width: 100%;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  display: none;
}
.sitenav-mobile-toggle:after {
  content: "\e900";
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.5em;
  position: absolute;
  right: 1rem;
}

@media screen and (max-width: 56.1875em) {
  .sidenav-section-title-label,
  .sidenav-children-title {
    font-size: 1em;
  }
  .sidenav-section-title {
    padding-right: 4.6875em;
  }
  .sidenav-children-trigger {
    width: 3.4375em;
  }
  .sidenav-children-trigger:after {
    font-size: 1.875em;
  }
}
@media screen and (max-width: 47.4375em) {
  .sidenav-section {
    background-color: #CC092F;
  }
  .sidenav-section + .sidenav-section {
    margin-top: 0;
    border-top: 1px solid #fff;
  }
  .sidenav-section--diversity {
    background-color: #1e9f48;
  }
  .sidenav-section--winrs {
    background-color: #E76D25;
  }
  .sidenav-section--probono {
    background-color: #0373ac;
  }
  .sidenav-section-skew {
    display: none;
  }
  .sidenav-section-header {
    margin: 0;
    padding: 0;
  }
  .sidenav-section-header:after, .sidenav-section-header:before {
    display: none;
  }
  .sidenav-section-title {
    text-transform: uppercase;
  }
  .sidenav-section-title:after {
    display: none;
  }
  .sidenav-section-title.is-current {
    color: #E68497;
  }
  .sidenav-section-title.is-current--diversity, .sidenav-section-title.is-current--winrs, .sidenav-section-title.is-current--probono, .sidenav-section-title.is-current--responsible-business {
    color: #3D3C3C;
  }
  .sidenav-section-title,
  .sidenav-children-title {
    padding-left: 1em;
    padding-right: 1em;
  }
  .sidenav-children-trigger {
    display: none;
  }
  .sidenav-children {
    padding: 0;
    overflow: auto;
    max-height: inherit;
    opacity: 1;
  }
  .sidenav-children-option, .sidenav-grandchildren-option {
    margin-right: 0;
    margin-left: 1em;
    padding: 0 1em 1em;
  }
  .sidenav-children-option + .sidenav-children-option, .sidenav-grandchildren-option + .sidenav-children-option, .sidenav-children-option + .sidenav-grandchildren-option, .sidenav-grandchildren-option + .sidenav-grandchildren-option {
    border-top: 0;
  }
  .sidenav-grandchildren-option {
    margin-right: 0;
    margin-left: 1em;
    line-height: normal;
    padding: 1em 1em 0;
  }
  .sidenav-grandchildren-option + .sidenav-grandchildren-option {
    border-top: 0;
  }
  .sidenav-children-title {
    color: #fff;
    padding: 0;
    display: inline;
  }
  .sidenav-children-title:hover {
    color: #fff;
  }
  .sitenav-mobile-toggle {
    display: block;
    z-index: 5;
  }
  .sidenav {
    position: relative;
    z-index: 10;
    margin-bottom: 2.5em;
  }
  .sidenav-list {
    position: absolute;
    top: 100%;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-3em);
    transition: opacity cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.35s, transform cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.35s, visibility cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.7s;
  }
  .show-menu .sidenav-list {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: opacity cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.35s, transform cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.35s;
  }
}
.svg-mask {
  position: absolute;
  top: 0;
  left: 0;
}

.svg-mask-container {
  position: relative;
  height: 0;
  padding-bottom: 112.8%;
  width: 100%;
  display: block;
}

.svg-clipping-path {
  position: absolute;
  width: 0;
  height: 0;
  z-index: -1;
}

.css-image-mask-link:hover .css-image-mask-skew:after {
  background-color: rgba(0, 0, 0, 0.7);
}
.css-image-mask-link:hover .css-image-mask-snapshot {
  transform: skewY(10deg) scale(1.25);
}

.css-image-mask {
  position: relative;
  height: 0;
  padding-bottom: 120%;
  width: 100%;
  display: block;
  overflow: hidden;
}
.css-image-mask:after {
  content: "";
  position: absolute;
  bottom: -1em;
  left: 0;
  display: block;
  border-bottom: 2.8125em solid #fff;
  border-right: 11.25em solid transparent;
  z-index: 2;
}

.css-image-mask-skew {
  position: absolute;
  left: 0;
  right: 0;
  top: 8%;
  bottom: 6.1538461538%;
  overflow: hidden;
  transform: skewY(-10deg);
}
.css-image-mask-skew:after {
  transition: background-color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  content: "";
  background-color: rgba(0, 0, 0, 0.6);
}

.css-image-mask-snapshot {
  position: absolute;
  top: -2em;
  left: 0;
  min-height: 120%;
  transform: skewY(10deg);
  transition: transform 2s;
}

.grey-wedge-background {
  position: relative;
  padding-bottom: 8%;
  min-height: 36.875em;
}
.grey-wedge-background:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to bottom, #fff, #f9f9f9 200px);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  max-width: 100.3125em;
  width: 100%;
  right: auto;
  z-index: -7;
  display: block;
}
.grey-wedge-background:after {
  content: "";
  max-width: 100.3125em;
  padding-left: 1.875em;
  padding-right: 1.875em;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  margin-bottom: -2.5em;
  height: 100%;
  width: 100%;
  background: url("./../images/gray-wedge.jpg") no-repeat center bottom;
  background-position: center bottom;
  background-size: 100% auto;
  background-repeat: no-repeat;
  z-index: -5;
}
@media screen and (max-width: 56.1875em) {
  .grey-wedge-background:after {
    padding-left: 1em;
    padding-right: 1em;
  }
}

@media screen and (max-width: 62.4375em) {
  .grey-wedge-background {
    min-height: 12.5em;
    padding-bottom: 20%;
  }
}
@media screen and (max-width: 47.4375em) {
  .grey-wedge-background {
    min-height: inherit;
  }
  .grey-wedge-background:after {
    margin-bottom: 0;
  }
}
.video-highlight {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.video-highlight:before {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  content: "";
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}
.video-highlight.newsletter-carousel-issue-figure {
  position: absolute;
}
.video-highlight:after {
  content: "\e903";
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.6);
  color: #E5B522;
  font-size: 1.875em;
  line-height: 3;
  text-align: center;
  display: block;
  z-index: 5;
  padding-left: 0.25em;
  width: 3em;
  height: 3em;
  border-radius: 45px;
  border: 1px solid #fff;
  clear: both;
  transition: color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86), border-color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86), opacity 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86), background-color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.video-highlight:hover .video-highlight-img {
  transform: scale(1.25) translateX(-40%);
}
.video-highlight:hover:after {
  color: #fff;
}

.video-highlight-img {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  display: block;
  transition: transform 2s;
}

@media screen and (max-width: 47.4375em) {
  .video-highlight:after {
    font-size: 1.5em;
    width: 2.75em;
    height: 2.75em;
    border-radius: 33px;
    line-height: 2.75;
  }
}
.professional-result {
  font-size: 0.8em;
  padding-bottom: 3em;
  border-bottom: 2px solid #979797;
}
.professional-result + .professional-result {
  padding-top: 3em;
}

.professional-result-position {
  font-size: 1.5em;
}

.professional-result-name {
  font-weight: 800;
  font-size: 2.25em;
  position: relative;
  transition: none;
  color: #CC092F;
  display: inline-block;
  transition: color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  display: inline;
  line-height: 1.1;
  color: #CC092F;
}
.professional-result-name:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0%;
  bottom: -1px;
  left: 0;
  height: 1px;
  background-color: #5B5B5B;
  transition: 0.35s;
}
.professional-result-name:hover {
  color: #5B5B5B;
}
.professional-result-name:hover:before {
  width: 100%;
}
@media screen and (max-width: 47.4375em) {
  .professional-result-name:before {
    display: none;
  }
  .professional-result-name:hover:before {
    display: none;
  }
}
.professional-result-name:hover {
  color: #5B5B5B;
}

.professional-result-details {
  margin-top: 2.1875rem;
}

.professional-result-contact-option {
  font-size: 1.5em;
  line-height: 1.4;
}

.professional-result-email {
  font-weight: 700;
  color: #CC092F;
  position: relative;
  transition: none;
  color: #CC092F;
  display: inline-block;
}
.professional-result-email:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0%;
  bottom: -1px;
  left: 0;
  height: 1px;
  background-color: #5B5B5B;
  transition: 0.35s;
}
.professional-result-email:hover {
  color: #5B5B5B;
}
.professional-result-email:hover:before {
  width: 100%;
}
@media screen and (max-width: 47.4375em) {
  .professional-result-email:before {
    display: none;
  }
  .professional-result-email:hover:before {
    display: none;
  }
}
.professional-result-email:hover {
  color: #5B5B5B;
}

.professional-result-office-link {
  font-weight: 700;
  font-size: 1.75em;
  position: relative;
  transition: none;
  color: #CC092F;
  display: inline-block;
  line-height: 1.3;
  color: #CC092F;
}
.professional-result-office-link:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0%;
  bottom: -1px;
  left: 0;
  height: 1px;
  background-color: #5B5B5B;
  transition: 0.35s;
}
.professional-result-office-link:hover {
  color: #5B5B5B;
}
.professional-result-office-link:hover:before {
  width: 100%;
}
@media screen and (max-width: 47.4375em) {
  .professional-result-office-link:before {
    display: none;
  }
  .professional-result-office-link:hover:before {
    display: none;
  }
}
.professional-result-office-link:hover {
  color: #5B5B5B;
}

.professional-result-details {
  display: table;
  width: 100%;
}

.professional-result-contact,
.professional-result-offices {
  display: table-cell;
}

.professional-result-contact {
  width: 60%;
  padding-right: 5%;
}

.professional-result-offices {
  vertical-align: bottom;
  text-align: right;
}

.professional-result-link {
  display: flex;
  flex-direction: column;
}
.professional-result-link .professional-result-position {
  order: -1;
}

@media screen and (max-width: 56.1875em) {
  .professional-result-position,
  .professional-result-contact-option,
  .professional-result-office-link {
    font-size: 1.25em;
  }
  .professional-result-name {
    font-size: 2.125em;
  }
}
@media screen and (max-width: 47.4375em) {
  .professional-result {
    padding-bottom: 2.5em;
  }
  .professional-result + .professional-result {
    padding-top: 2.5em;
  }
  .professional-result-details {
    margin-top: 2.5em;
  }
}
@media screen and (max-width: 39.3125em) {
  .professional-result-details {
    display: block;
  }
  .professional-result-contact,
  .professional-result-offices {
    display: block;
  }
  .professional-result-contact {
    width: auto;
  }
  .professional-result-offices {
    text-align: left;
    margin-top: 1.375em;
  }
  .professional-result-office {
    display: inline;
    margin-right: 0.25em;
  }
  .professional-result-office:last-child .professional-result-office-link:after {
    content: "";
  }
  .professional-result-office-link:after {
    content: ",";
    color: #3D3C3C;
  }
}
.keyword-search {
  position: relative;
  margin-top: 2.5em;
}
.keyword-search .keyword-search-submit {
  margin-top: 0;
  margin-left: 1em;
}
.keyword-search .page-header-title {
  margin-right: 1.25em;
}

.keyword-search-options {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

#search-typeahead-status {
  display: none;
}

.typeahead-results {
  position: absolute;
  z-index: 500;
  color: black;
  background-color: white;
  width: 100%;
  border: 1px solid #979797;
  border-top: 0;
}

.typeahead-results li {
  padding: 5px;
  cursor: pointer;
}

.typeahead-results .active {
  background-color: lightgray;
}

.ui-datepicker-div {
  z-index: 50 !important;
}

.keyword-search-locations {
  position: relative;
  flex-grow: 1;
}

.current-location {
  position: absolute;
  right: 20px;
  color: #CC092F;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.current-location:before {
  content: "\e908";
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.keyword-search-input[type=text] {
  border-color: transparent;
  line-height: 1.2em;
  width: 100%;
  height: 3.125em;
}
.keyword-search-input[type=text] + .keyword-search-input[type=text] {
  margin-left: 0.75em;
}

.keyword-search-input-events {
  margin-left: 0.75em;
  flex-grow: 1;
  width: 100%;
  position: relative;
}
.keyword-search-input-events .keyword-search-input {
  padding-right: 1.5625em;
}

.keyword-search-input-events-label {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 14px;
  color: #CC092F;
  width: 1em;
  height: 1em;
  overflow: hidden;
  white-space: nowrap;
  padding-left: 1em;
}
.keyword-search-input-events-label:after {
  content: "\e90c";
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
}

.keyword-search-submit {
  margin-top: 0;
  float: right;
}

.keyword-search-primary {
  display: flex;
  flex-wrap: nowrap;
}
.keyword-search-primary .keyword-search-input {
  flex-grow: 1;
  width: auto;
}
.keyword-search-primary .keyword-search-submit {
  flex-shrink: 0;
  float: none;
  position: relative;
  right: auto;
  bottom: auto;
}

@media screen and (max-width: 56.1875em) {
  .keyword-search-input[type=text] {
    padding: 0.75em;
    height: 2.8125em;
  }
}
@media screen and (max-width: 47.4375em) {
  .keyword-search-primary.has-dropdown {
    display: block;
  }
  .keyword-search-primary.has-dropdown .keyword-search-submit {
    margin-top: 1em;
    margin-left: 0;
  }
  .keyword-search-input-container {
    width: auto;
  }
  .keyword-search-options {
    flex-wrap: wrap;
  }
  .keyword-search-input[type=text] + .keyword-search-input[type=text] {
    margin-top: 0.75em;
    margin-left: 0;
  }
  .keyword-search-input-events {
    margin-top: 0.75em;
    margin-left: 0;
  }
  .keyword-search .page-header-title {
    width: 100%;
    margin-bottom: 0.5em;
  }
  .keyword-search-locations {
    max-width: calc(100% - 4em);
  }
}
.keyword-search-grid {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.keyword-search-grid-option {
  flex-grow: 1;
  width: 100%;
  position: relative;
}
.keyword-search-grid-option + .keyword-search-grid-option {
  margin-left: 0.75em;
}

@media screen and (max-width: 47.4375em) {
  .keyword-search-grid {
    display: block;
  }
  .keyword-search-grid-option + .keyword-search-grid-option {
    margin-left: 0;
    margin-top: 0.75em;
  }
}
.advanced-filters {
  position: relative;
  margin-top: 0.75em;
  text-align: left;
}

.advanced-filters-categories:after {
  content: "";
  display: table;
  clear: both;
}

.advanced-filters-trigger {
  font-weight: 800;
  font-size: 1.1875em;
  position: relative;
  transition: none;
  color: #E5B522;
  display: inline-block;
  color: #E5B522;
  padding-left: 0.75em;
  display: none;
}
.advanced-filters-trigger:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0%;
  bottom: -1px;
  left: 0;
  height: 1px;
  background-color: #fff;
  transition: 0.35s;
}
.advanced-filters-trigger:hover {
  color: #fff;
}
.advanced-filters-trigger:hover:before {
  width: 100%;
}
@media screen and (max-width: 47.4375em) {
  .advanced-filters-trigger:before {
    display: none;
  }
  .advanced-filters-trigger:hover:before {
    display: none;
  }
}
.advanced-filters-trigger:after {
  content: "+";
  display: inline-block;
  position: absolute;
  left: 0;
  text-align: left;
}
.advanced-filters-trigger.is-expanded:after {
  content: "–";
}

.advanced-filters-category {
  float: left;
  width: 20%;
  padding-right: 0.75em;
}
.advanced-filters-category--careers {
  width: 50%;
}

@media screen and (max-width: 56.1875em) {
  .advanced-filters-trigger {
    display: inline-block;
    margin-top: 0.375em;
    padding-bottom: 0.75em;
  }
  .advanced-filters-trigger:before {
    bottom: 0.625em;
  }
  .advanced-filters-trigger.is-expanded + .advanced-filters-categories {
    max-height: 34.375em;
  }
  .advanced-filters-categories {
    transition: max-height 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    margin-top: 0.75em;
    max-height: 0;
    overflow: hidden;
  }
  .advanced-filters-categories--careers {
    max-height: none;
    margin-top: 0;
  }
  .advanced-filters-category {
    width: 50%;
    padding-top: 0.75em;
  }
  .filters-show {
    display: block;
  }
}
@media screen and (max-width: 47.4375em) {
  .advanced-filters {
    position: relative;
  }
  .advanced-filters-categories {
    margin-right: 0;
  }
  .advanced-filters-category {
    width: 100%;
    float: none;
    padding-right: 0;
  }
}
.jump-to {
  transition: opacity 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.jump-to.is-disabled {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86), visibility 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.35s;
}

.scrollspy-nav {
  width: 100vw;
  background-color: #3D3C3C;
  left: 0;
  margin-top: -10em;
  text-align: left;
  position: absolute;
  z-index: 54;
}
.scrollspy-nav.is-sticky {
  position: fixed;
  top: 10em;
}
.scrollspy-nav.is-sticky-bottom {
  bottom: 0;
}

.scrollspy-nav-column {
  max-width: 100.3125em;
  padding-left: 1.875em;
  padding-right: 1.875em;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-top: 0 !important;
  padding-left: 3.75em;
}
@media screen and (max-width: 56.1875em) {
  .scrollspy-nav-column {
    padding-left: 1em;
    padding-right: 1em;
  }
}

.scrollspy-nav-list {
  white-space: nowrap;
  display: inline-block;
  position: relative;
  max-width: 0.9375em;
  padding: 0.5em 0;
  margin-top: 0.3125em;
  overflow: visible;
  transition: max-width 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.scrollspy-nav-list:hover, .scrollspy-nav-list.is-exposed {
  max-width: 31.25em;
}

.scrollspy-nav-option {
  position: relative;
  display: inline-block;
}
.scrollspy-nav-option + .scrollspy-nav-option .scrollspy-nav-label {
  border-top: 1px solid #fff;
}
.scrollspy-nav-option:last-child .scrollspy-nav-link:before {
  height: 103%;
  border-bottom-width: 1px;
}
.scrollspy-nav-option.is-inview .scrollspy-nav-link {
  color: #fff;
}
.scrollspy-nav-option.is-inview .scrollspy-nav-link:before {
  background-color: #CC092F;
}
.scrollspy-nav-option.is-inview .scrollspy-nav-label {
  background-color: transparent;
  color: #fff;
}
.scrollspy-nav-option .scrollspy-nav-link:before {
  display: none;
}

.scrollspy-nav-link {
  display: block;
  padding-left: 1.5625em;
  color: rgba(255, 255, 255, 0.5);
  text-align: left;
}
.scrollspy-nav-link:hover {
  color: #fff;
}
.scrollspy-nav-link:hover:before {
  background-color: #CC092F;
}
.scrollspy-nav-link:before {
  transition: background-color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0.9375em;
  height: 100%;
  background-color: #3D3C3C;
  border: 1px solid #fff;
  border-width: 1px 1px 0;
  z-index: 2;
  transform: skewY(-6deg);
}

.scrollspy-nav-label {
  font-weight: 800;
  font-size: 1em;
  background-color: #3D3C3C;
  display: block;
  line-height: 1.1;
  font-weight: normal;
  text-transform: uppercase;
  border-top: none !important;
  font-size: 16px;
  color: #E5B522;
  padding: 0.83333em 0.25em;
}
.scrollspy-nav-label:hover {
  color: #fff;
}

.scrollspy-nav-toggle {
  background-color: #CC092F;
  color: #fff;
  padding: 0.625em 1em;
  padding-right: 2.0625em;
  font-weight: 800;
  position: relative;
  width: 100%;
  display: none;
}
.scrollspy-nav-toggle:after {
  content: "\e900";
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.625em;
  right: 1.3em;
}

@media screen and (max-width: 64em) {
  .setup-fixed-header.headroom--pinned ~ .main-content .scrollspy-nav.is-sticky {
    transform: translateY(4.0625em);
  }
  .scrollspy-nav {
    width: 100%;
    background-color: transparent;
    position: relative;
    margin-bottom: 2.5em;
    text-align: inherit;
    margin-top: 0;
    padding-left: 1em;
    padding-right: 1em;
  }
  .scrollspy-nav.is-sticky {
    transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    margin-top: 0;
    top: 0;
    left: 0;
    padding: 0;
  }
  .scrollspy-nav.is-sticky-bottom {
    position: relative;
  }
  .scrollspy-nav-column {
    padding: 0;
  }
  .scrollspy-nav-toggle {
    display: block;
    text-align: left;
  }
  .scrollspy-nav-option {
    display: block;
  }
  .scrollspy-nav-option .scrollspy-nav-link {
    padding-left: 0;
  }
  .scrollspy-nav-option.is-inview {
    background-color: #3D3C3C;
    display: none;
  }
  .scrollspy-nav-list {
    white-space: normal;
    position: absolute;
    top: 100%;
    width: 100%;
    max-width: none;
    overflow: hidden;
    max-height: 0;
    padding: 0;
    margin-top: 0;
    transition: max-height 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }
  .scrollspy-nav-list:hover {
    max-width: none;
  }
  .show-menu .scrollspy-nav-list {
    max-height: 37.5em;
  }
  .scrollspy-nav-link {
    padding-left: 0;
  }
  .scrollspy-nav-link:before {
    display: none;
  }
  .scrollspy-nav-label {
    font-size: 1em;
    padding: 0.83333em 1em;
  }
}
.tabs-nav {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  position: relative;
}

.tabs-nav-item {
  flex-basis: 25%;
  padding-right: 0.9375em;
}
.tabs-nav-item:last-child {
  padding-right: 0;
}

.tabs-nav-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: #E5B522;
  font-weight: 800;
  color: #3D3C3C;
  padding: 0.5em 0.3125em;
  cursor: pointer;
  height: 100%;
  transition: background-color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.is-selected .tabs-nav-btn {
  background-color: #fff;
  cursor: text;
}
.tabs-nav-btn:hover {
  background-color: #fff;
}
.tabs-nav-btn.link-hover {
  cursor: pointer;
}

.tabs-nav-mobile-toggle {
  background-color: #E5B522;
  font-weight: 800;
  color: #3D3C3C;
  padding: 0.625em 0.625em;
  padding-right: 2.0625em;
  position: relative;
  width: 100%;
  text-align: left;
  display: none;
}
.tabs-nav-mobile-toggle:after {
  content: "\e900";
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.3125em;
  right: 1.3em;
}

@media screen and (max-width: 47.4375em) {
  .tabs-nav-wrapper {
    position: relative;
    z-index: 10;
  }
  .tabs-nav {
    display: block;
    position: absolute;
    top: 100%;
    width: 100%;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }
  .show-menu .tabs-nav {
    max-height: 37.5em;
  }
  .tabs-nav-item {
    margin: 0;
    padding-right: 0;
    text-align: left;
  }
  .tabs-nav-btn {
    text-align: left;
    padding: 0.625em;
  }
  .tabs-nav-mobile-toggle {
    display: block;
  }
}
.tab-filters-menu {
  display: flex;
  align-items: stretch;
  height: 100%;
}

.tab-filters-view {
  flex-basis: 25%;
  padding: 0 0.5em;
}
.tab-filters-view:last-child {
  padding-right: 0;
}
.tab-filters-view.is-selected .tab-filters-btn {
  background-color: #181818;
}

.tab-filters-btn {
  font-weight: 800;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0.75em 1em;
  overflow: hidden;
  position: relative;
  text-align: center;
  color: #fff;
  background-color: #B58F1B;
  backface-visibility: hidden;
  transform: translateZ(0);
}
.tab-filters-btn:hover {
  color: #fff;
}
.tab-filters-btn:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.tab-filters-btn:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #3D3C3C;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}

.tab-filters-btn-label {
  position: relative;
  z-index: 2;
}

.tab-filters-mobile-toggle {
  font-weight: 800;
  display: none;
  width: 100%;
  padding: 0.5em 1.375em;
  background-color: #E5B522;
  overflow: hidden;
  color: #3D3C3C;
  text-align: left;
}
.tab-filters-mobile-toggle:after {
  content: "\e900";
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.625em;
  right: 2.2em;
}

@media screen and (max-width: 47.4375em) {
  .tab-filters {
    position: relative;
    z-index: 20;
  }
  .tab-filters-menu {
    display: block;
    position: absolute;
    top: 100%;
    width: 100%;
    height: auto;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }
  .show-menu .tab-filters-menu {
    max-height: 37.5em;
  }
  .tab-filters-view {
    margin: 0;
    padding: 0;
    text-align: left;
  }
  .tab-filters-view.is-selected {
    display: none;
  }
  .tab-filters-btn {
    display: block;
    text-align: left;
    padding: 1em 1.375em;
  }
  .tab-filters-mobile-toggle {
    display: block;
  }
}
.related-item-tile {
  position: relative;
  background-color: #373737;
  overflow: hidden;
}

.related-item-tile--no-link {
  background-color: #181818;
  border: 2px solid #373737;
}
.related-item-tile--no-link .related-item-tile-header {
  color: #9d9d9d;
  cursor: default;
}

.related-item-tile--multimedia:after {
  content: "\e903";
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  background-color: rgba(0, 0, 0, 0.6);
  color: #E5B522;
  font-size: 1.875em;
  line-height: 3;
  text-align: center;
  display: block;
  padding-left: 0.25em;
  width: 3em;
  height: 3em;
  border-radius: 45px;
  border: 1px solid #fff;
  clear: both;
  transition: color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86), border-color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86), opacity 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86), background-color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  position: absolute;
  right: 0.6666666667em;
  bottom: 0.8333333333em;
  z-index: 2;
}
.related-item-tile--multimedia:not(.related-item-tile--no-link):hover:after {
  color: #9d9d9d;
}

.related-item-tile-meta {
  font-weight: 300;
  font-size: 1.25em;
  line-height: 1.5;
}

.related-item-tile-type {
  font-weight: 700;
}
.related-item-tile-type:after {
  content: "-";
  display: inline-block;
  margin-right: 0.25em;
  margin-left: 0.5em;
  font-weight: 400;
}

.related-item-tile-date ~ .related-item-tile-location:before, .related-item-tile-date ~ .related-item-tile-source:before {
  content: "|";
  display: inline-block;
  margin-right: 0.25em;
}

.related-item-tile-type {
  font-weight: 700;
}
.related-item-tile-type:after {
  content: "-";
  display: inline-block;
  margin-right: 0.25em;
  margin-left: 0.5em;
  font-weight: 400;
}

.related-item-tile-header {
  height: 100%;
  min-height: 20.25em;
  display: block;
  color: #fff;
  position: relative;
  z-index: 5;
  padding: 1.5625em 1.25em 1.5625em;
}
.related-item-tile--multimedia .related-item-tile-header {
  padding-bottom: 8.75em;
  min-height: 8.75em;
}
.related-item-tile-header:hover .related-item-tile-heading {
  color: #9d9d9d;
}
.related-item-tile-header:hover .related-item-tile-subheading {
  color: #9d9d9d;
}
.related-item-tile-header.is-external-link:after {
  display: none;
}
.related-item-tile-header.is-external-link .related-item-tile-heading:after {
  content: "\e90b";
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  font-size: 0.5em;
  margin-left: 0.5em;
  margin-top: 1em;
  vertical-align: middle;
}

.related-item-tile-heading {
  font-weight: 300;
  font-size: 2.125em;
  transition: color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  margin-top: 0.6470588235em;
  max-width: 16.0294117647em;
}

.related-item-tile-subheading {
  transition: color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  font-size: 1.625em;
  margin-top: 0.3846153846em;
}

.related-item-tile-featured {
  font-weight: 800;
  color: #CC092F;
  margin-bottom: 0.5em;
}

@media screen and (max-width: 67.125em) {
  .related-item-tile-header {
    padding-bottom: 18%;
    min-height: 20em;
  }
}
@media screen and (max-width: 56.1875em) {
  .related-item-tile-header {
    padding-bottom: 2.5em;
  }
  .related-item-tile-heading {
    font-size: 1.5em;
    line-height: 1.35;
    max-width: none;
  }
  .related-item-tile-subheading {
    font-size: 1.25em;
  }
  .related-item-tile-meta {
    font-size: 1.125em;
  }
  .related-entity-tile-featured {
    font-size: 0.875em;
  }
}
@media screen and (max-width: 47.4375em) {
  .related-item-tile-header {
    min-height: 13.4375em;
  }
}
.clock {
  width: 150px;
  height: 150px;
  background-color: transparent;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 10;
  max-width: 10em;
  max-height: 10em;
}
.clock div {
  position: absolute;
  transform-origin: 50% 0;
}
.clock::after {
  position: absolute;
  content: "";
  width: 12px;
  padding-bottom: 12px;
  background-color: white;
  border-radius: 50%;
  top: calc(50% - 6px);
  left: calc(50% - 6px);
  z-index: 999;
}
.clock #second-hand {
  height: 45%;
  top: 50%;
  left: calc(50% - 1px);
  z-index: 1;
  border-left: 1px solid white;
  border-right: 1px solid white;
  border-radius: 0 0 1px 1px;
}
.clock #minute-hand {
  height: 40%;
  top: 50%;
  left: calc(50% - 2px);
  z-index: 1;
  border-left: 2px solid white;
  border-right: 2px solid white;
  border-radius: 0 0 2px 2px;
}
.clock #hour-hand {
  height: 30%;
  top: 50%;
  left: calc(50% - 3px);
  z-index: 1;
  border-left: 3px solid white;
  border-right: 3px solid white;
  border-radius: 0 0 3px 3px;
}
.clock .ticks span {
  position: absolute;
  display: block;
  height: 2px;
  width: 4%;
  background: rgba(255, 255, 255, 0.65);
  transform-origin: 50% 0%;
  left: calc(50% - 2%);
  top: 50%;
}
.clock .ticks span:nth-child(1) {
  transform: rotate(6deg) translateX(75px);
}
.clock .ticks span:nth-child(2) {
  transform: rotate(12deg) translateX(75px);
}
.clock .ticks span:nth-child(3) {
  transform: rotate(18deg) translateX(75px);
}
.clock .ticks span:nth-child(4) {
  transform: rotate(24deg) translateX(75px);
}
.clock .ticks span:nth-child(5) {
  transform: rotate(30deg) translateX(75px);
  background: #fff;
}
.clock .ticks span:nth-child(6) {
  transform: rotate(36deg) translateX(75px);
}
.clock .ticks span:nth-child(7) {
  transform: rotate(42deg) translateX(75px);
}
.clock .ticks span:nth-child(8) {
  transform: rotate(48deg) translateX(75px);
}
.clock .ticks span:nth-child(9) {
  transform: rotate(54deg) translateX(75px);
}
.clock .ticks span:nth-child(10) {
  transform: rotate(60deg) translateX(75px);
  background: #fff;
}
.clock .ticks span:nth-child(11) {
  transform: rotate(66deg) translateX(75px);
}
.clock .ticks span:nth-child(12) {
  transform: rotate(72deg) translateX(75px);
}
.clock .ticks span:nth-child(13) {
  transform: rotate(78deg) translateX(75px);
}
.clock .ticks span:nth-child(14) {
  transform: rotate(84deg) translateX(75px);
}
.clock .ticks span:nth-child(15) {
  transform: rotate(90deg) translateX(75px);
  background: #fff;
}
.clock .ticks span:nth-child(16) {
  transform: rotate(96deg) translateX(75px);
}
.clock .ticks span:nth-child(17) {
  transform: rotate(102deg) translateX(75px);
}
.clock .ticks span:nth-child(18) {
  transform: rotate(108deg) translateX(75px);
}
.clock .ticks span:nth-child(19) {
  transform: rotate(114deg) translateX(75px);
}
.clock .ticks span:nth-child(20) {
  transform: rotate(120deg) translateX(75px);
  background: #fff;
}
.clock .ticks span:nth-child(21) {
  transform: rotate(126deg) translateX(75px);
}
.clock .ticks span:nth-child(22) {
  transform: rotate(132deg) translateX(75px);
}
.clock .ticks span:nth-child(23) {
  transform: rotate(138deg) translateX(75px);
}
.clock .ticks span:nth-child(24) {
  transform: rotate(144deg) translateX(75px);
}
.clock .ticks span:nth-child(25) {
  transform: rotate(150deg) translateX(75px);
  background: #fff;
}
.clock .ticks span:nth-child(26) {
  transform: rotate(156deg) translateX(75px);
}
.clock .ticks span:nth-child(27) {
  transform: rotate(162deg) translateX(75px);
}
.clock .ticks span:nth-child(28) {
  transform: rotate(168deg) translateX(75px);
}
.clock .ticks span:nth-child(29) {
  transform: rotate(174deg) translateX(75px);
}
.clock .ticks span:nth-child(30) {
  transform: rotate(180deg) translateX(75px);
  background: #fff;
}
.clock .ticks span:nth-child(31) {
  transform: rotate(186deg) translateX(75px);
}
.clock .ticks span:nth-child(32) {
  transform: rotate(192deg) translateX(75px);
}
.clock .ticks span:nth-child(33) {
  transform: rotate(198deg) translateX(75px);
}
.clock .ticks span:nth-child(34) {
  transform: rotate(204deg) translateX(75px);
}
.clock .ticks span:nth-child(35) {
  transform: rotate(210deg) translateX(75px);
  background: #fff;
}
.clock .ticks span:nth-child(36) {
  transform: rotate(216deg) translateX(75px);
}
.clock .ticks span:nth-child(37) {
  transform: rotate(222deg) translateX(75px);
}
.clock .ticks span:nth-child(38) {
  transform: rotate(228deg) translateX(75px);
}
.clock .ticks span:nth-child(39) {
  transform: rotate(234deg) translateX(75px);
}
.clock .ticks span:nth-child(40) {
  transform: rotate(240deg) translateX(75px);
  background: #fff;
}
.clock .ticks span:nth-child(41) {
  transform: rotate(246deg) translateX(75px);
}
.clock .ticks span:nth-child(42) {
  transform: rotate(252deg) translateX(75px);
}
.clock .ticks span:nth-child(43) {
  transform: rotate(258deg) translateX(75px);
}
.clock .ticks span:nth-child(44) {
  transform: rotate(264deg) translateX(75px);
}
.clock .ticks span:nth-child(45) {
  transform: rotate(270deg) translateX(75px);
  background: #fff;
}
.clock .ticks span:nth-child(46) {
  transform: rotate(276deg) translateX(75px);
}
.clock .ticks span:nth-child(47) {
  transform: rotate(282deg) translateX(75px);
}
.clock .ticks span:nth-child(48) {
  transform: rotate(288deg) translateX(75px);
}
.clock .ticks span:nth-child(49) {
  transform: rotate(294deg) translateX(75px);
}
.clock .ticks span:nth-child(50) {
  transform: rotate(300deg) translateX(75px);
  background: #fff;
}
.clock .ticks span:nth-child(51) {
  transform: rotate(306deg) translateX(75px);
}
.clock .ticks span:nth-child(52) {
  transform: rotate(312deg) translateX(75px);
}
.clock .ticks span:nth-child(53) {
  transform: rotate(318deg) translateX(75px);
}
.clock .ticks span:nth-child(54) {
  transform: rotate(324deg) translateX(75px);
}
.clock .ticks span:nth-child(55) {
  transform: rotate(330deg) translateX(75px);
  background: #fff;
}
.clock .ticks span:nth-child(56) {
  transform: rotate(336deg) translateX(75px);
}
.clock .ticks span:nth-child(57) {
  transform: rotate(342deg) translateX(75px);
}
.clock .ticks span:nth-child(58) {
  transform: rotate(348deg) translateX(75px);
}
.clock .ticks span:nth-child(59) {
  transform: rotate(354deg) translateX(75px);
}
.clock .ticks span:nth-child(60) {
  transform: rotate(360deg) translateX(75px);
  background: #fff;
}

@media screen and (max-width: 81.1875em) {
  .clock {
    width: 125px;
    height: 125px;
  }
  .clock .ticks span:nth-child(1) {
    transform: rotate(6deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(2) {
    transform: rotate(12deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(3) {
    transform: rotate(18deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(4) {
    transform: rotate(24deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(5) {
    transform: rotate(30deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(6) {
    transform: rotate(36deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(7) {
    transform: rotate(42deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(8) {
    transform: rotate(48deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(9) {
    transform: rotate(54deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(10) {
    transform: rotate(60deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(11) {
    transform: rotate(66deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(12) {
    transform: rotate(72deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(13) {
    transform: rotate(78deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(14) {
    transform: rotate(84deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(15) {
    transform: rotate(90deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(16) {
    transform: rotate(96deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(17) {
    transform: rotate(102deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(18) {
    transform: rotate(108deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(19) {
    transform: rotate(114deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(20) {
    transform: rotate(120deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(21) {
    transform: rotate(126deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(22) {
    transform: rotate(132deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(23) {
    transform: rotate(138deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(24) {
    transform: rotate(144deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(25) {
    transform: rotate(150deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(26) {
    transform: rotate(156deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(27) {
    transform: rotate(162deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(28) {
    transform: rotate(168deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(29) {
    transform: rotate(174deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(30) {
    transform: rotate(180deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(31) {
    transform: rotate(186deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(32) {
    transform: rotate(192deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(33) {
    transform: rotate(198deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(34) {
    transform: rotate(204deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(35) {
    transform: rotate(210deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(36) {
    transform: rotate(216deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(37) {
    transform: rotate(222deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(38) {
    transform: rotate(228deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(39) {
    transform: rotate(234deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(40) {
    transform: rotate(240deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(41) {
    transform: rotate(246deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(42) {
    transform: rotate(252deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(43) {
    transform: rotate(258deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(44) {
    transform: rotate(264deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(45) {
    transform: rotate(270deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(46) {
    transform: rotate(276deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(47) {
    transform: rotate(282deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(48) {
    transform: rotate(288deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(49) {
    transform: rotate(294deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(50) {
    transform: rotate(300deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(51) {
    transform: rotate(306deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(52) {
    transform: rotate(312deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(53) {
    transform: rotate(318deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(54) {
    transform: rotate(324deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(55) {
    transform: rotate(330deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(56) {
    transform: rotate(336deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(57) {
    transform: rotate(342deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(58) {
    transform: rotate(348deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(59) {
    transform: rotate(354deg) translateX(62.5px);
  }
  .clock .ticks span:nth-child(60) {
    transform: rotate(360deg) translateX(62.5px);
  }
}
@keyframes animate-hand-0 {
  from {
    transform: rotate(-180deg);
  }
  to {
    transform: rotate(180deg);
  }
}
@keyframes animate-hour-hand-0 {
  from {
    transform: rotate(-180deg);
  }
  to {
    transform: rotate(180deg);
  }
}
.sh-0 {
  animation: animate-hand-0 60s linear infinite;
}

.mh-0 {
  animation: animate-hand-0 3600s linear infinite;
}

.hh-0 {
  animation: animate-hour-hand-0 43200s linear infinite;
}

@keyframes animate-hand-1 {
  from {
    transform: rotate(-174deg);
  }
  to {
    transform: rotate(186deg);
  }
}
@keyframes animate-hour-hand-1 {
  from {
    transform: rotate(-174deg);
  }
  to {
    transform: rotate(186deg);
  }
}
.sh-1 {
  animation: animate-hand-1 60s linear infinite;
}

.mh-1 {
  animation: animate-hand-1 3600s linear infinite;
}

.hh-1 {
  animation: animate-hour-hand-1 43200s linear infinite;
}

@keyframes animate-hand-2 {
  from {
    transform: rotate(-168deg);
  }
  to {
    transform: rotate(192deg);
  }
}
@keyframes animate-hour-hand-2 {
  from {
    transform: rotate(-168deg);
  }
  to {
    transform: rotate(192deg);
  }
}
.sh-2 {
  animation: animate-hand-2 60s linear infinite;
}

.mh-2 {
  animation: animate-hand-2 3600s linear infinite;
}

.hh-2 {
  animation: animate-hour-hand-2 43200s linear infinite;
}

@keyframes animate-hand-3 {
  from {
    transform: rotate(-162deg);
  }
  to {
    transform: rotate(198deg);
  }
}
@keyframes animate-hour-hand-3 {
  from {
    transform: rotate(-162deg);
  }
  to {
    transform: rotate(198deg);
  }
}
.sh-3 {
  animation: animate-hand-3 60s linear infinite;
}

.mh-3 {
  animation: animate-hand-3 3600s linear infinite;
}

.hh-3 {
  animation: animate-hour-hand-3 43200s linear infinite;
}

@keyframes animate-hand-4 {
  from {
    transform: rotate(-156deg);
  }
  to {
    transform: rotate(204deg);
  }
}
@keyframes animate-hour-hand-4 {
  from {
    transform: rotate(-156deg);
  }
  to {
    transform: rotate(204deg);
  }
}
.sh-4 {
  animation: animate-hand-4 60s linear infinite;
}

.mh-4 {
  animation: animate-hand-4 3600s linear infinite;
}

.hh-4 {
  animation: animate-hour-hand-4 43200s linear infinite;
}

@keyframes animate-hand-5 {
  from {
    transform: rotate(-150deg);
  }
  to {
    transform: rotate(210deg);
  }
}
@keyframes animate-hour-hand-5 {
  from {
    transform: rotate(-150deg);
  }
  to {
    transform: rotate(210deg);
  }
}
.sh-5 {
  animation: animate-hand-5 60s linear infinite;
}

.mh-5 {
  animation: animate-hand-5 3600s linear infinite;
}

.hh-5 {
  animation: animate-hour-hand-5 43200s linear infinite;
}

@keyframes animate-hand-6 {
  from {
    transform: rotate(-144deg);
  }
  to {
    transform: rotate(216deg);
  }
}
@keyframes animate-hour-hand-6 {
  from {
    transform: rotate(-144deg);
  }
  to {
    transform: rotate(216deg);
  }
}
.sh-6 {
  animation: animate-hand-6 60s linear infinite;
}

.mh-6 {
  animation: animate-hand-6 3600s linear infinite;
}

.hh-6 {
  animation: animate-hour-hand-6 43200s linear infinite;
}

@keyframes animate-hand-7 {
  from {
    transform: rotate(-138deg);
  }
  to {
    transform: rotate(222deg);
  }
}
@keyframes animate-hour-hand-7 {
  from {
    transform: rotate(-138deg);
  }
  to {
    transform: rotate(222deg);
  }
}
.sh-7 {
  animation: animate-hand-7 60s linear infinite;
}

.mh-7 {
  animation: animate-hand-7 3600s linear infinite;
}

.hh-7 {
  animation: animate-hour-hand-7 43200s linear infinite;
}

@keyframes animate-hand-8 {
  from {
    transform: rotate(-132deg);
  }
  to {
    transform: rotate(228deg);
  }
}
@keyframes animate-hour-hand-8 {
  from {
    transform: rotate(-132deg);
  }
  to {
    transform: rotate(228deg);
  }
}
.sh-8 {
  animation: animate-hand-8 60s linear infinite;
}

.mh-8 {
  animation: animate-hand-8 3600s linear infinite;
}

.hh-8 {
  animation: animate-hour-hand-8 43200s linear infinite;
}

@keyframes animate-hand-9 {
  from {
    transform: rotate(-126deg);
  }
  to {
    transform: rotate(234deg);
  }
}
@keyframes animate-hour-hand-9 {
  from {
    transform: rotate(-126deg);
  }
  to {
    transform: rotate(234deg);
  }
}
.sh-9 {
  animation: animate-hand-9 60s linear infinite;
}

.mh-9 {
  animation: animate-hand-9 3600s linear infinite;
}

.hh-9 {
  animation: animate-hour-hand-9 43200s linear infinite;
}

@keyframes animate-hand-10 {
  from {
    transform: rotate(-120deg);
  }
  to {
    transform: rotate(240deg);
  }
}
@keyframes animate-hour-hand-10 {
  from {
    transform: rotate(-120deg);
  }
  to {
    transform: rotate(240deg);
  }
}
.sh-10 {
  animation: animate-hand-10 60s linear infinite;
}

.mh-10 {
  animation: animate-hand-10 3600s linear infinite;
}

.hh-10 {
  animation: animate-hour-hand-10 43200s linear infinite;
}

@keyframes animate-hand-11 {
  from {
    transform: rotate(-114deg);
  }
  to {
    transform: rotate(246deg);
  }
}
@keyframes animate-hour-hand-11 {
  from {
    transform: rotate(-114deg);
  }
  to {
    transform: rotate(246deg);
  }
}
.sh-11 {
  animation: animate-hand-11 60s linear infinite;
}

.mh-11 {
  animation: animate-hand-11 3600s linear infinite;
}

.hh-11 {
  animation: animate-hour-hand-11 43200s linear infinite;
}

@keyframes animate-hand-12 {
  from {
    transform: rotate(-108deg);
  }
  to {
    transform: rotate(252deg);
  }
}
@keyframes animate-hour-hand-12 {
  from {
    transform: rotate(-108deg);
  }
  to {
    transform: rotate(252deg);
  }
}
.sh-12 {
  animation: animate-hand-12 60s linear infinite;
}

.mh-12 {
  animation: animate-hand-12 3600s linear infinite;
}

.hh-12 {
  animation: animate-hour-hand-12 43200s linear infinite;
}

@keyframes animate-hand-13 {
  from {
    transform: rotate(-102deg);
  }
  to {
    transform: rotate(258deg);
  }
}
@keyframes animate-hour-hand-13 {
  from {
    transform: rotate(-102deg);
  }
  to {
    transform: rotate(258deg);
  }
}
.sh-13 {
  animation: animate-hand-13 60s linear infinite;
}

.mh-13 {
  animation: animate-hand-13 3600s linear infinite;
}

.hh-13 {
  animation: animate-hour-hand-13 43200s linear infinite;
}

@keyframes animate-hand-14 {
  from {
    transform: rotate(-96deg);
  }
  to {
    transform: rotate(264deg);
  }
}
@keyframes animate-hour-hand-14 {
  from {
    transform: rotate(-96deg);
  }
  to {
    transform: rotate(264deg);
  }
}
.sh-14 {
  animation: animate-hand-14 60s linear infinite;
}

.mh-14 {
  animation: animate-hand-14 3600s linear infinite;
}

.hh-14 {
  animation: animate-hour-hand-14 43200s linear infinite;
}

@keyframes animate-hand-15 {
  from {
    transform: rotate(-90deg);
  }
  to {
    transform: rotate(270deg);
  }
}
@keyframes animate-hour-hand-15 {
  from {
    transform: rotate(-90deg);
  }
  to {
    transform: rotate(270deg);
  }
}
.sh-15 {
  animation: animate-hand-15 60s linear infinite;
}

.mh-15 {
  animation: animate-hand-15 3600s linear infinite;
}

.hh-15 {
  animation: animate-hour-hand-15 43200s linear infinite;
}

@keyframes animate-hand-16 {
  from {
    transform: rotate(-84deg);
  }
  to {
    transform: rotate(276deg);
  }
}
@keyframes animate-hour-hand-16 {
  from {
    transform: rotate(-84deg);
  }
  to {
    transform: rotate(276deg);
  }
}
.sh-16 {
  animation: animate-hand-16 60s linear infinite;
}

.mh-16 {
  animation: animate-hand-16 3600s linear infinite;
}

.hh-16 {
  animation: animate-hour-hand-16 43200s linear infinite;
}

@keyframes animate-hand-17 {
  from {
    transform: rotate(-78deg);
  }
  to {
    transform: rotate(282deg);
  }
}
@keyframes animate-hour-hand-17 {
  from {
    transform: rotate(-78deg);
  }
  to {
    transform: rotate(282deg);
  }
}
.sh-17 {
  animation: animate-hand-17 60s linear infinite;
}

.mh-17 {
  animation: animate-hand-17 3600s linear infinite;
}

.hh-17 {
  animation: animate-hour-hand-17 43200s linear infinite;
}

@keyframes animate-hand-18 {
  from {
    transform: rotate(-72deg);
  }
  to {
    transform: rotate(288deg);
  }
}
@keyframes animate-hour-hand-18 {
  from {
    transform: rotate(-72deg);
  }
  to {
    transform: rotate(288deg);
  }
}
.sh-18 {
  animation: animate-hand-18 60s linear infinite;
}

.mh-18 {
  animation: animate-hand-18 3600s linear infinite;
}

.hh-18 {
  animation: animate-hour-hand-18 43200s linear infinite;
}

@keyframes animate-hand-19 {
  from {
    transform: rotate(-66deg);
  }
  to {
    transform: rotate(294deg);
  }
}
@keyframes animate-hour-hand-19 {
  from {
    transform: rotate(-66deg);
  }
  to {
    transform: rotate(294deg);
  }
}
.sh-19 {
  animation: animate-hand-19 60s linear infinite;
}

.mh-19 {
  animation: animate-hand-19 3600s linear infinite;
}

.hh-19 {
  animation: animate-hour-hand-19 43200s linear infinite;
}

@keyframes animate-hand-20 {
  from {
    transform: rotate(-60deg);
  }
  to {
    transform: rotate(300deg);
  }
}
@keyframes animate-hour-hand-20 {
  from {
    transform: rotate(-60deg);
  }
  to {
    transform: rotate(300deg);
  }
}
.sh-20 {
  animation: animate-hand-20 60s linear infinite;
}

.mh-20 {
  animation: animate-hand-20 3600s linear infinite;
}

.hh-20 {
  animation: animate-hour-hand-20 43200s linear infinite;
}

@keyframes animate-hand-21 {
  from {
    transform: rotate(-54deg);
  }
  to {
    transform: rotate(306deg);
  }
}
@keyframes animate-hour-hand-21 {
  from {
    transform: rotate(-54deg);
  }
  to {
    transform: rotate(306deg);
  }
}
.sh-21 {
  animation: animate-hand-21 60s linear infinite;
}

.mh-21 {
  animation: animate-hand-21 3600s linear infinite;
}

.hh-21 {
  animation: animate-hour-hand-21 43200s linear infinite;
}

@keyframes animate-hand-22 {
  from {
    transform: rotate(-48deg);
  }
  to {
    transform: rotate(312deg);
  }
}
@keyframes animate-hour-hand-22 {
  from {
    transform: rotate(-48deg);
  }
  to {
    transform: rotate(312deg);
  }
}
.sh-22 {
  animation: animate-hand-22 60s linear infinite;
}

.mh-22 {
  animation: animate-hand-22 3600s linear infinite;
}

.hh-22 {
  animation: animate-hour-hand-22 43200s linear infinite;
}

@keyframes animate-hand-23 {
  from {
    transform: rotate(-42deg);
  }
  to {
    transform: rotate(318deg);
  }
}
@keyframes animate-hour-hand-23 {
  from {
    transform: rotate(-42deg);
  }
  to {
    transform: rotate(318deg);
  }
}
.sh-23 {
  animation: animate-hand-23 60s linear infinite;
}

.mh-23 {
  animation: animate-hand-23 3600s linear infinite;
}

.hh-23 {
  animation: animate-hour-hand-23 43200s linear infinite;
}

@keyframes animate-hand-24 {
  from {
    transform: rotate(-36deg);
  }
  to {
    transform: rotate(324deg);
  }
}
@keyframes animate-hour-hand-24 {
  from {
    transform: rotate(-36deg);
  }
  to {
    transform: rotate(324deg);
  }
}
.sh-24 {
  animation: animate-hand-24 60s linear infinite;
}

.mh-24 {
  animation: animate-hand-24 3600s linear infinite;
}

.hh-24 {
  animation: animate-hour-hand-24 43200s linear infinite;
}

@keyframes animate-hand-25 {
  from {
    transform: rotate(-30deg);
  }
  to {
    transform: rotate(330deg);
  }
}
@keyframes animate-hour-hand-25 {
  from {
    transform: rotate(-30deg);
  }
  to {
    transform: rotate(330deg);
  }
}
.sh-25 {
  animation: animate-hand-25 60s linear infinite;
}

.mh-25 {
  animation: animate-hand-25 3600s linear infinite;
}

.hh-25 {
  animation: animate-hour-hand-25 43200s linear infinite;
}

@keyframes animate-hand-26 {
  from {
    transform: rotate(-24deg);
  }
  to {
    transform: rotate(336deg);
  }
}
@keyframes animate-hour-hand-26 {
  from {
    transform: rotate(-24deg);
  }
  to {
    transform: rotate(336deg);
  }
}
.sh-26 {
  animation: animate-hand-26 60s linear infinite;
}

.mh-26 {
  animation: animate-hand-26 3600s linear infinite;
}

.hh-26 {
  animation: animate-hour-hand-26 43200s linear infinite;
}

@keyframes animate-hand-27 {
  from {
    transform: rotate(-18deg);
  }
  to {
    transform: rotate(342deg);
  }
}
@keyframes animate-hour-hand-27 {
  from {
    transform: rotate(-18deg);
  }
  to {
    transform: rotate(342deg);
  }
}
.sh-27 {
  animation: animate-hand-27 60s linear infinite;
}

.mh-27 {
  animation: animate-hand-27 3600s linear infinite;
}

.hh-27 {
  animation: animate-hour-hand-27 43200s linear infinite;
}

@keyframes animate-hand-28 {
  from {
    transform: rotate(-12deg);
  }
  to {
    transform: rotate(348deg);
  }
}
@keyframes animate-hour-hand-28 {
  from {
    transform: rotate(-12deg);
  }
  to {
    transform: rotate(348deg);
  }
}
.sh-28 {
  animation: animate-hand-28 60s linear infinite;
}

.mh-28 {
  animation: animate-hand-28 3600s linear infinite;
}

.hh-28 {
  animation: animate-hour-hand-28 43200s linear infinite;
}

@keyframes animate-hand-29 {
  from {
    transform: rotate(-6deg);
  }
  to {
    transform: rotate(354deg);
  }
}
@keyframes animate-hour-hand-29 {
  from {
    transform: rotate(-6deg);
  }
  to {
    transform: rotate(354deg);
  }
}
.sh-29 {
  animation: animate-hand-29 60s linear infinite;
}

.mh-29 {
  animation: animate-hand-29 3600s linear infinite;
}

.hh-29 {
  animation: animate-hour-hand-29 43200s linear infinite;
}

@keyframes animate-hand-30 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes animate-hour-hand-30 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.sh-30 {
  animation: animate-hand-30 60s linear infinite;
}

.mh-30 {
  animation: animate-hand-30 3600s linear infinite;
}

.hh-30 {
  animation: animate-hour-hand-30 43200s linear infinite;
}

@keyframes animate-hand-31 {
  from {
    transform: rotate(6deg);
  }
  to {
    transform: rotate(366deg);
  }
}
@keyframes animate-hour-hand-31 {
  from {
    transform: rotate(6deg);
  }
  to {
    transform: rotate(366deg);
  }
}
.sh-31 {
  animation: animate-hand-31 60s linear infinite;
}

.mh-31 {
  animation: animate-hand-31 3600s linear infinite;
}

.hh-31 {
  animation: animate-hour-hand-31 43200s linear infinite;
}

@keyframes animate-hand-32 {
  from {
    transform: rotate(12deg);
  }
  to {
    transform: rotate(372deg);
  }
}
@keyframes animate-hour-hand-32 {
  from {
    transform: rotate(12deg);
  }
  to {
    transform: rotate(372deg);
  }
}
.sh-32 {
  animation: animate-hand-32 60s linear infinite;
}

.mh-32 {
  animation: animate-hand-32 3600s linear infinite;
}

.hh-32 {
  animation: animate-hour-hand-32 43200s linear infinite;
}

@keyframes animate-hand-33 {
  from {
    transform: rotate(18deg);
  }
  to {
    transform: rotate(378deg);
  }
}
@keyframes animate-hour-hand-33 {
  from {
    transform: rotate(18deg);
  }
  to {
    transform: rotate(378deg);
  }
}
.sh-33 {
  animation: animate-hand-33 60s linear infinite;
}

.mh-33 {
  animation: animate-hand-33 3600s linear infinite;
}

.hh-33 {
  animation: animate-hour-hand-33 43200s linear infinite;
}

@keyframes animate-hand-34 {
  from {
    transform: rotate(24deg);
  }
  to {
    transform: rotate(384deg);
  }
}
@keyframes animate-hour-hand-34 {
  from {
    transform: rotate(24deg);
  }
  to {
    transform: rotate(384deg);
  }
}
.sh-34 {
  animation: animate-hand-34 60s linear infinite;
}

.mh-34 {
  animation: animate-hand-34 3600s linear infinite;
}

.hh-34 {
  animation: animate-hour-hand-34 43200s linear infinite;
}

@keyframes animate-hand-35 {
  from {
    transform: rotate(30deg);
  }
  to {
    transform: rotate(390deg);
  }
}
@keyframes animate-hour-hand-35 {
  from {
    transform: rotate(30deg);
  }
  to {
    transform: rotate(390deg);
  }
}
.sh-35 {
  animation: animate-hand-35 60s linear infinite;
}

.mh-35 {
  animation: animate-hand-35 3600s linear infinite;
}

.hh-35 {
  animation: animate-hour-hand-35 43200s linear infinite;
}

@keyframes animate-hand-36 {
  from {
    transform: rotate(36deg);
  }
  to {
    transform: rotate(396deg);
  }
}
@keyframes animate-hour-hand-36 {
  from {
    transform: rotate(36deg);
  }
  to {
    transform: rotate(396deg);
  }
}
.sh-36 {
  animation: animate-hand-36 60s linear infinite;
}

.mh-36 {
  animation: animate-hand-36 3600s linear infinite;
}

.hh-36 {
  animation: animate-hour-hand-36 43200s linear infinite;
}

@keyframes animate-hand-37 {
  from {
    transform: rotate(42deg);
  }
  to {
    transform: rotate(402deg);
  }
}
@keyframes animate-hour-hand-37 {
  from {
    transform: rotate(42deg);
  }
  to {
    transform: rotate(402deg);
  }
}
.sh-37 {
  animation: animate-hand-37 60s linear infinite;
}

.mh-37 {
  animation: animate-hand-37 3600s linear infinite;
}

.hh-37 {
  animation: animate-hour-hand-37 43200s linear infinite;
}

@keyframes animate-hand-38 {
  from {
    transform: rotate(48deg);
  }
  to {
    transform: rotate(408deg);
  }
}
@keyframes animate-hour-hand-38 {
  from {
    transform: rotate(48deg);
  }
  to {
    transform: rotate(408deg);
  }
}
.sh-38 {
  animation: animate-hand-38 60s linear infinite;
}

.mh-38 {
  animation: animate-hand-38 3600s linear infinite;
}

.hh-38 {
  animation: animate-hour-hand-38 43200s linear infinite;
}

@keyframes animate-hand-39 {
  from {
    transform: rotate(54deg);
  }
  to {
    transform: rotate(414deg);
  }
}
@keyframes animate-hour-hand-39 {
  from {
    transform: rotate(54deg);
  }
  to {
    transform: rotate(414deg);
  }
}
.sh-39 {
  animation: animate-hand-39 60s linear infinite;
}

.mh-39 {
  animation: animate-hand-39 3600s linear infinite;
}

.hh-39 {
  animation: animate-hour-hand-39 43200s linear infinite;
}

@keyframes animate-hand-40 {
  from {
    transform: rotate(60deg);
  }
  to {
    transform: rotate(420deg);
  }
}
@keyframes animate-hour-hand-40 {
  from {
    transform: rotate(60deg);
  }
  to {
    transform: rotate(420deg);
  }
}
.sh-40 {
  animation: animate-hand-40 60s linear infinite;
}

.mh-40 {
  animation: animate-hand-40 3600s linear infinite;
}

.hh-40 {
  animation: animate-hour-hand-40 43200s linear infinite;
}

@keyframes animate-hand-41 {
  from {
    transform: rotate(66deg);
  }
  to {
    transform: rotate(426deg);
  }
}
@keyframes animate-hour-hand-41 {
  from {
    transform: rotate(66deg);
  }
  to {
    transform: rotate(426deg);
  }
}
.sh-41 {
  animation: animate-hand-41 60s linear infinite;
}

.mh-41 {
  animation: animate-hand-41 3600s linear infinite;
}

.hh-41 {
  animation: animate-hour-hand-41 43200s linear infinite;
}

@keyframes animate-hand-42 {
  from {
    transform: rotate(72deg);
  }
  to {
    transform: rotate(432deg);
  }
}
@keyframes animate-hour-hand-42 {
  from {
    transform: rotate(72deg);
  }
  to {
    transform: rotate(432deg);
  }
}
.sh-42 {
  animation: animate-hand-42 60s linear infinite;
}

.mh-42 {
  animation: animate-hand-42 3600s linear infinite;
}

.hh-42 {
  animation: animate-hour-hand-42 43200s linear infinite;
}

@keyframes animate-hand-43 {
  from {
    transform: rotate(78deg);
  }
  to {
    transform: rotate(438deg);
  }
}
@keyframes animate-hour-hand-43 {
  from {
    transform: rotate(78deg);
  }
  to {
    transform: rotate(438deg);
  }
}
.sh-43 {
  animation: animate-hand-43 60s linear infinite;
}

.mh-43 {
  animation: animate-hand-43 3600s linear infinite;
}

.hh-43 {
  animation: animate-hour-hand-43 43200s linear infinite;
}

@keyframes animate-hand-44 {
  from {
    transform: rotate(84deg);
  }
  to {
    transform: rotate(444deg);
  }
}
@keyframes animate-hour-hand-44 {
  from {
    transform: rotate(84deg);
  }
  to {
    transform: rotate(444deg);
  }
}
.sh-44 {
  animation: animate-hand-44 60s linear infinite;
}

.mh-44 {
  animation: animate-hand-44 3600s linear infinite;
}

.hh-44 {
  animation: animate-hour-hand-44 43200s linear infinite;
}

@keyframes animate-hand-45 {
  from {
    transform: rotate(90deg);
  }
  to {
    transform: rotate(450deg);
  }
}
@keyframes animate-hour-hand-45 {
  from {
    transform: rotate(90deg);
  }
  to {
    transform: rotate(450deg);
  }
}
.sh-45 {
  animation: animate-hand-45 60s linear infinite;
}

.mh-45 {
  animation: animate-hand-45 3600s linear infinite;
}

.hh-45 {
  animation: animate-hour-hand-45 43200s linear infinite;
}

@keyframes animate-hand-46 {
  from {
    transform: rotate(96deg);
  }
  to {
    transform: rotate(456deg);
  }
}
@keyframes animate-hour-hand-46 {
  from {
    transform: rotate(96deg);
  }
  to {
    transform: rotate(456deg);
  }
}
.sh-46 {
  animation: animate-hand-46 60s linear infinite;
}

.mh-46 {
  animation: animate-hand-46 3600s linear infinite;
}

.hh-46 {
  animation: animate-hour-hand-46 43200s linear infinite;
}

@keyframes animate-hand-47 {
  from {
    transform: rotate(102deg);
  }
  to {
    transform: rotate(462deg);
  }
}
@keyframes animate-hour-hand-47 {
  from {
    transform: rotate(102deg);
  }
  to {
    transform: rotate(462deg);
  }
}
.sh-47 {
  animation: animate-hand-47 60s linear infinite;
}

.mh-47 {
  animation: animate-hand-47 3600s linear infinite;
}

.hh-47 {
  animation: animate-hour-hand-47 43200s linear infinite;
}

@keyframes animate-hand-48 {
  from {
    transform: rotate(108deg);
  }
  to {
    transform: rotate(468deg);
  }
}
@keyframes animate-hour-hand-48 {
  from {
    transform: rotate(108deg);
  }
  to {
    transform: rotate(468deg);
  }
}
.sh-48 {
  animation: animate-hand-48 60s linear infinite;
}

.mh-48 {
  animation: animate-hand-48 3600s linear infinite;
}

.hh-48 {
  animation: animate-hour-hand-48 43200s linear infinite;
}

@keyframes animate-hand-49 {
  from {
    transform: rotate(114deg);
  }
  to {
    transform: rotate(474deg);
  }
}
@keyframes animate-hour-hand-49 {
  from {
    transform: rotate(114deg);
  }
  to {
    transform: rotate(474deg);
  }
}
.sh-49 {
  animation: animate-hand-49 60s linear infinite;
}

.mh-49 {
  animation: animate-hand-49 3600s linear infinite;
}

.hh-49 {
  animation: animate-hour-hand-49 43200s linear infinite;
}

@keyframes animate-hand-50 {
  from {
    transform: rotate(120deg);
  }
  to {
    transform: rotate(480deg);
  }
}
@keyframes animate-hour-hand-50 {
  from {
    transform: rotate(120deg);
  }
  to {
    transform: rotate(480deg);
  }
}
.sh-50 {
  animation: animate-hand-50 60s linear infinite;
}

.mh-50 {
  animation: animate-hand-50 3600s linear infinite;
}

.hh-50 {
  animation: animate-hour-hand-50 43200s linear infinite;
}

@keyframes animate-hand-51 {
  from {
    transform: rotate(126deg);
  }
  to {
    transform: rotate(486deg);
  }
}
@keyframes animate-hour-hand-51 {
  from {
    transform: rotate(126deg);
  }
  to {
    transform: rotate(486deg);
  }
}
.sh-51 {
  animation: animate-hand-51 60s linear infinite;
}

.mh-51 {
  animation: animate-hand-51 3600s linear infinite;
}

.hh-51 {
  animation: animate-hour-hand-51 43200s linear infinite;
}

@keyframes animate-hand-52 {
  from {
    transform: rotate(132deg);
  }
  to {
    transform: rotate(492deg);
  }
}
@keyframes animate-hour-hand-52 {
  from {
    transform: rotate(132deg);
  }
  to {
    transform: rotate(492deg);
  }
}
.sh-52 {
  animation: animate-hand-52 60s linear infinite;
}

.mh-52 {
  animation: animate-hand-52 3600s linear infinite;
}

.hh-52 {
  animation: animate-hour-hand-52 43200s linear infinite;
}

@keyframes animate-hand-53 {
  from {
    transform: rotate(138deg);
  }
  to {
    transform: rotate(498deg);
  }
}
@keyframes animate-hour-hand-53 {
  from {
    transform: rotate(138deg);
  }
  to {
    transform: rotate(498deg);
  }
}
.sh-53 {
  animation: animate-hand-53 60s linear infinite;
}

.mh-53 {
  animation: animate-hand-53 3600s linear infinite;
}

.hh-53 {
  animation: animate-hour-hand-53 43200s linear infinite;
}

@keyframes animate-hand-54 {
  from {
    transform: rotate(144deg);
  }
  to {
    transform: rotate(504deg);
  }
}
@keyframes animate-hour-hand-54 {
  from {
    transform: rotate(144deg);
  }
  to {
    transform: rotate(504deg);
  }
}
.sh-54 {
  animation: animate-hand-54 60s linear infinite;
}

.mh-54 {
  animation: animate-hand-54 3600s linear infinite;
}

.hh-54 {
  animation: animate-hour-hand-54 43200s linear infinite;
}

@keyframes animate-hand-55 {
  from {
    transform: rotate(150deg);
  }
  to {
    transform: rotate(510deg);
  }
}
@keyframes animate-hour-hand-55 {
  from {
    transform: rotate(150deg);
  }
  to {
    transform: rotate(510deg);
  }
}
.sh-55 {
  animation: animate-hand-55 60s linear infinite;
}

.mh-55 {
  animation: animate-hand-55 3600s linear infinite;
}

.hh-55 {
  animation: animate-hour-hand-55 43200s linear infinite;
}

@keyframes animate-hand-56 {
  from {
    transform: rotate(156deg);
  }
  to {
    transform: rotate(516deg);
  }
}
@keyframes animate-hour-hand-56 {
  from {
    transform: rotate(156deg);
  }
  to {
    transform: rotate(516deg);
  }
}
.sh-56 {
  animation: animate-hand-56 60s linear infinite;
}

.mh-56 {
  animation: animate-hand-56 3600s linear infinite;
}

.hh-56 {
  animation: animate-hour-hand-56 43200s linear infinite;
}

@keyframes animate-hand-57 {
  from {
    transform: rotate(162deg);
  }
  to {
    transform: rotate(522deg);
  }
}
@keyframes animate-hour-hand-57 {
  from {
    transform: rotate(162deg);
  }
  to {
    transform: rotate(522deg);
  }
}
.sh-57 {
  animation: animate-hand-57 60s linear infinite;
}

.mh-57 {
  animation: animate-hand-57 3600s linear infinite;
}

.hh-57 {
  animation: animate-hour-hand-57 43200s linear infinite;
}

@keyframes animate-hand-58 {
  from {
    transform: rotate(168deg);
  }
  to {
    transform: rotate(528deg);
  }
}
@keyframes animate-hour-hand-58 {
  from {
    transform: rotate(168deg);
  }
  to {
    transform: rotate(528deg);
  }
}
.sh-58 {
  animation: animate-hand-58 60s linear infinite;
}

.mh-58 {
  animation: animate-hand-58 3600s linear infinite;
}

.hh-58 {
  animation: animate-hour-hand-58 43200s linear infinite;
}

@keyframes animate-hand-59 {
  from {
    transform: rotate(174deg);
  }
  to {
    transform: rotate(534deg);
  }
}
@keyframes animate-hour-hand-59 {
  from {
    transform: rotate(174deg);
  }
  to {
    transform: rotate(534deg);
  }
}
.sh-59 {
  animation: animate-hand-59 60s linear infinite;
}

.mh-59 {
  animation: animate-hand-59 3600s linear infinite;
}

.hh-59 {
  animation: animate-hour-hand-59 43200s linear infinite;
}

.applied-filters-item {
  display: inline-block;
  margin-right: 0.4em;
  padding-left: 1.625em;
  margin-right: 0.5em;
  position: relative;
  font-weight: 700;
  cursor: pointer;
}

.applied-filters-remove span {
  font-weight: 700;
}
.applied-filters-remove:hover {
  color: #5B5B5B;
}
.applied-filters-remove:after {
  content: "\e904";
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.25em;
  color: #CC092F;
  position: absolute;
  left: 0;
  top: 0.1em;
  line-height: 1.1;
}

.explore-more-cta {
  text-align: center;
}
.explore-more-cta .btn-ghost {
  max-width: 40.625em;
}

.additional-solutions-note {
  margin-bottom: 0;
}

.additional-solutions-list {
  padding-bottom: 0.625em;
}
.additional-solutions-list .additional-solution-option {
  margin-bottom: 0.625em;
}
.additional-solutions-list .additional-solutions-link {
  background-position: 0 1.215em;
}

@media all and (min--moz-device-pixel-ratio: 0) {
  .additional-solutions-list .additional-solutions-link {
    background-position: 0 1.26em;
  }
}
.share-tools {
  position: absolute;
  top: 0;
  width: 4.0625em;
}
.share-tools.is-sticky {
  position: fixed;
  top: 6.5625em;
}
.share-tools.is-anchored-bottom {
  position: absolute;
  bottom: 0;
  top: auto;
}

.share-tools-list {
  padding-left: 5%;
  padding-top: 0.625em;
}

.share-tools-option {
  position: relative;
}
.share-tools-option:first-child .share-tools-link:before {
  border-top: 0 none;
}

.share-tools-link {
  display: block;
  height: 4.375em;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 0.0625em;
  text-align: center;
}
.share-tools-link:hover {
  color: #fff;
}
.share-tools-link:hover:after {
  background-color: #CC092F;
}
.share-tools-link:after {
  transition: background-color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #181818;
  z-index: 1;
  transform: skewY(-6deg);
}
.share-tools-link:before {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: 2;
  font-size: 1.875em;
}
.share-tools-link.ico-mail:before {
  font-size: 1.375rem;
}

@media screen and (max-width: 67.125em) {
  .share-tools-list {
    padding-left: 0;
  }
}
@media screen and (max-width: 56.1875em) {
  .share-tools {
    width: 3.125em;
  }
  .share-tools-link {
    height: 3.4375em;
  }
  .share-tools-link:hover {
    color: #fff;
  }
  .share-tools-link:hover:after {
    background-color: #CC092F;
  }
  .share-tools-link:before {
    font-size: 1.5em;
  }
  .share-tools-link.ico-mail:before {
    font-size: 1em;
  }
}
@media screen and (max-width: 47.4375em) {
  .share-tools {
    position: relative;
    width: auto;
    text-align: center;
  }
  .share-tools.is-sticky {
    position: static;
  }
  .share-tools.is-anchored-bottom {
    position: static;
  }
  .share-tools-list {
    display: inline-block;
    padding-top: 0;
    margin-top: -1.25em;
  }
  .share-tools-list:after {
    content: "";
    display: table;
    clear: both;
  }
  .share-tools-option {
    float: left;
    margin-right: 0.0625em;
    width: 3.125em;
  }
  .share-tools-link:after {
    transform: skewX(-6deg);
  }
}
.fadable {
  opacity: 0;
  transform: translateY(ft(1));
  transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
}
.fadable--ready {
  opacity: 1;
  transform: none;
}

.social-block {
  padding-bottom: 6.875em;
}
.social-block .inner-content {
  color: #fff;
}
.social-block .rte a {
  position: relative;
  transition: none;
  color: #fff;
  display: inline-block;
  font-weight: 700;
}
.social-block .rte a:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0%;
  bottom: -1px;
  left: 0;
  height: 1px;
  background-color: #fff;
  transition: 0.35s;
}
.social-block .rte a:hover {
  color: #fff;
}
.social-block .rte a:hover:before {
  width: 100%;
}
@media screen and (max-width: 47.4375em) {
  .social-block .rte a:before {
    display: none;
  }
  .social-block .rte a:hover:before {
    display: none;
  }
}
.social-block .rte a:before {
  width: 100%;
}

@media screen and (max-width: 56.1875em) {
  .social-block {
    padding-bottom: 5em;
  }
}
.content-block .inner-content {
  padding: 5.625em 0;
}
.content-block .rte {
  margin-bottom: 1.5em;
}
.content-block .top-spacing {
  margin-top: 5%;
}
@media screen and (max-width: 768px) {
  .content-block .top-spacing {
    margin-top: 0;
  }
}
@media screen and (max-width: 56.1875em) {
  .content-block.practice-leaders .professional-image {
    max-width: 400px;
    margin: 0 auto;
  }
}

.sliced-box {
  padding: 0.1em;
  overflow: hidden;
  position: relative;
  margin-top: -10%;
  transform: translateZ(0);
}
.sliced-box:before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  border-top: 90px solid white;
  border-right: 810px solid transparent;
}
@media screen and (max-width: 47.4375em) {
  .sliced-box {
    margin-top: 0;
    padding: 0;
  }
  .sliced-box:before {
    border-top-width: 40px;
    border-right-width: 465px;
  }
}

.self-angled-background {
  position: relative;
  padding: 5.5% 0;
  margin: 5.5% 0;
}
.self-angled-background > * {
  position: relative;
  z-index: 1;
}
.self-angled-background:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  -webkit-transform: skewX(0) skewY(-6deg);
  -ms-transform: skewX(0) skewY(-6deg);
  -o-transform: skewX(0) skewY(-6deg);
  transform: skewX(0) skewY(-6deg);
}
.self-angled-background.red:before {
  background-color: #CC092F;
}

.js-feature-hover .js-feature-hover-target {
  position: relative;
  overflow: hidden;
}
.js-feature-hover .js-feature-hover-target > * {
  z-index: 0;
}
.js-feature-hover .js-feature-hover-target > .background-image {
  -webkit-transition: all 0.6s ease-in;
  transition: all 0.6s ease-in;
  background-size: cover;
  background-position: center;
}
.js-feature-hover .js-feature-hover-target > .background-image:before {
  opacity: 0;
  transition: all 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  content: " ";
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 3;
}
.js-feature-hover .js-feature-hover-target.active > .background-image {
  transform: scale(1.2);
}
.js-feature-hover .js-feature-hover-target.active > .background-image:before {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.25);
}

.event-card {
  font-size: 1.25em;
  background-color: #181818;
  color: #fff;
  padding: 1.375em 1.5625em;
  margin-bottom: 4em;
}

.event-card-item {
  margin-bottom: 0.625em;
}
.event-card-item:after {
  content: "";
  display: table;
  clear: both;
}

.event-card-label {
  float: left;
  margin-right: 0.5em;
  display: inline-block;
  width: 9em;
}

.event-card-detail {
  font-style: normal;
  display: table;
}

a.event-card-detail {
  position: relative;
  transition: none;
  color: #E5B522;
  display: inline-block;
  font-weight: 700;
  display: table;
}
a.event-card-detail:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0%;
  bottom: -1px;
  left: 0;
  height: 1px;
  background-color: #fff;
  transition: 0.35s;
}
a.event-card-detail:hover {
  color: #fff;
}
a.event-card-detail:hover:before {
  width: 100%;
}
@media screen and (max-width: 47.4375em) {
  a.event-card-detail:before {
    display: none;
  }
  a.event-card-detail:hover:before {
    display: none;
  }
}

@media screen and (max-width: 56.1875em) {
  .event-card {
    font-size: 16px;
    padding: 1.375em;
    margin-bottom: 1.375em;
  }
}
@media screen and (max-width: 26.1875em) {
  .event-card-label {
    display: block;
    float: none;
    margin-right: 0;
    width: auto;
  }
  .event-card-detail {
    display: block;
  }
}
.up-next {
  position: relative;
  text-align: center;
  min-height: 12.5em;
}

.up-next-wrapper {
  position: relative;
  z-index: 9;
}

.up-next-label {
  font-size: 0.5555555556em;
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 0.625em;
  display: block;
}
.up-next-label:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "➞";
  display: inline-block;
  margin-left: 0.5em;
}

.up-next-cta {
  font-weight: 800;
  font-size: 2.25em;
  display: inline-block;
  color: #fff;
  line-height: 1.1;
  position: relative;
  transition: none;
  color: #fff;
  display: inline-block;
}
.up-next-cta:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0%;
  bottom: -1px;
  left: 0;
  height: 1px;
  background-color: #fff;
  transition: 0.35s;
}
.up-next-cta:hover {
  color: #fff;
}
.up-next-cta:hover:before {
  width: 100%;
}
@media screen and (max-width: 47.4375em) {
  .up-next-cta:before {
    display: none;
  }
  .up-next-cta:hover:before {
    display: none;
  }
}
.up-next-cta:hover .up-next-label:after {
  transform: translateX(0.5em);
}

@media screen and (max-width: 56.1875em) {
  .up-next {
    min-height: 10em;
  }
  .up-next-cta {
    font-size: 1.75em;
  }
  .up-next-label {
    font-size: 0.6428571429em;
  }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 1.5s;
}

.fade-leave-active {
  display: none;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.list-enter-active, .list-leave-active {
  transition: all 1s;
}

.list-enter, .list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}

.around-corner-tile {
  width: 39.0625em;
}

.around-corner-tile-figure {
  max-width: 39.0625em;
  max-height: 20.25em;
  overflow: hidden;
}

.around-corner-tile-img {
  min-width: 100%;
  max-width: 100%;
  display: block;
}

.around-corner-tile-abstract {
  padding: 1em;
}

@media screen and (max-width: 64em) {
  .around-corner-tile {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 47.4375em) {
  .around-corner-tile-figure {
    max-width: none;
    max-height: none;
  }
  .around-corner-tile {
    width: 100%;
  }
  .around-corner-tile-figure {
    margin-left: -1em;
    margin-right: -1em;
    text-align: center;
  }
  .around-corner-tile-img {
    display: inline-block;
  }
  .around-corner-tile-abstract {
    padding: 1em 0;
  }
}
@media screen and (min-width: 67.1875em) {
  .page-wrapper-diversity {
    padding-top: 4rem;
  }
}

.search-segments {
  position: relative;
  width: 50%;
  margin-left: 20px;
}

.search-segments-nav {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-right: -0.75em;
}

.search-segments-item {
  flex-basis: 20%;
  flex-shrink: 0;
  padding-right: 0.75em;
  padding-top: 0.75em;
}
.search-segments-item.is-selected .search-segments-link {
  background-color: #fff;
}

.search-segments-link {
  transition: background-color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  background-color: #E5B522;
  display: inline-block;
  font-weight: 800;
  color: #3D3C3C;
  width: 100%;
  padding: 1em;
  text-align: center;
  cursor: pointer;
}
.search-segments-link:hover {
  background-color: #fff;
}

.search-segments-toggle {
  background-color: #E5B522;
  color: #3D3C3C;
  padding: 0.94em 0.625em;
  font-weight: 800;
  position: relative;
  width: 100%;
  text-align: left;
  display: none;
}
.search-segments-toggle:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  width: 1em;
  display: inline-block;
  margin-left: 0.3em;
  background: url("./../images/chevron-mobile.svg") no-repeat center;
  background-size: 100% auto;
  height: 0.9em;
  position: absolute;
  right: 0.625em;
}

.search-segments-toggle {
  display: block;
}

.search-segments-nav {
  position: absolute;
  top: 100%;
  width: 100%;
  display: block;
  overflow: hidden;
  max-height: 0;
  padding: 0;
  margin-top: 0;
  transition: max-height 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.show-menu .search-segments-nav {
  max-height: 37.5em;
}

.search-segments-item {
  padding-right: 0;
  padding-top: 0;
}
.search-segments-item.is-selected {
  display: none;
}

.search-segments-link {
  text-align: left;
  padding: 0.625em 0.625em;
  border-top: 1px solid #3D3C3C;
}

@media screen and (max-width: 47.4375em) {
  .search-segments {
    margin-top: 1em;
    z-index: 3;
    width: 100%;
    margin-left: 0;
  }
}
.listing-site-search-item {
  font-size: 0.8em;
  padding-bottom: 3em;
  border-bottom: 2px solid #979797;
}
.listing-site-search-item + .listing-site-search-item {
  padding-top: 3em;
}

.listing-site-search-meta:before {
  content: "|";
  display: inline-block;
  margin-right: 0.25em;
}

.listing-site-search-title {
  font-weight: 300;
  font-size: 2.25em;
  display: inline;
  color: #CC092F;
  line-height: 1.3;
  background-image: linear-gradient(to bottom, #5B5B5B 0%, #5B5B5B 100%);
  background-repeat: no-repeat;
  background-position: 0 38px;
  background-size: 0 auto;
  transition: color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86), background 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.listing-site-search-title:hover {
  background-size: 100% auto;
  color: #5B5B5B;
}

.listing-site-search-abstract {
  margin-top: 1.375em;
}

@media all and (min--moz-device-pixel-ratio: 0) {
  .listing-site-search-title {
    background-position: 0 38px;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .listing-site-search-title {
    background-image: none;
    position: relative;
  }
  .listing-site-search-title:before {
    content: "";
    position: absolute;
    display: inline-block;
    width: 0%;
    bottom: -1px;
    left: 0;
    height: 1px;
    background-color: #5B5B5B;
    transition: 0.35s;
  }
  .listing-site-search-title:hover:before {
    width: 100%;
  }
}
@media screen and (max-width: 56.1875em) {
  .listing-site-search-item {
    font-size: 1em;
    padding-bottom: 2.5em;
  }
  .listing-site-search-item + .listing-site-search-item {
    padding-top: 2.5em;
  }
  .listing-site-search-title {
    font-size: 1.5em;
    background-position: 0 32px;
  }
}
.grecaptcha-badge {
  visibility: hidden;
}

.article-document-section__forecast--panel {
  border-bottom: 0.1875em solid #fff;
  padding: 1.5625em 0;
}
.article-document-section__forecast--panel__this-article {
  color: #fff;
  background-color: #cd122d;
}
.article-document-section__forecast--panel__this-article .btn-ghost {
  color: #fff;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
}
.article-document-section__forecast--panel__this-article .btn-ghost:hover {
  color: #cd122d;
}
.article-document-section__forecast--panel__this-article .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.article-document-section__forecast--panel__this-article .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #fff;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.article-document-section__forecast--panel__full-article {
  color: #fff;
  background-color: #d74157;
}
.article-document-section__forecast--panel__full-article .btn-ghost {
  color: #fff;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
}
.article-document-section__forecast--panel__full-article .btn-ghost:hover {
  color: #d74157;
}
.article-document-section__forecast--panel__full-article .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.article-document-section__forecast--panel__full-article .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #fff;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.article-document-section__forecast--panel__subscribe {
  color: #fff;
  background-color: #404040;
}
.article-document-section__forecast--panel__subscribe .btn-ghost {
  color: #fff;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
}
.article-document-section__forecast--panel__subscribe .btn-ghost:hover {
  color: #404040;
}
.article-document-section__forecast--panel__subscribe .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.article-document-section__forecast--panel__subscribe .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #fff;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.article-document-section__forecast--panel__up {
  color: #5B5B5B;
  background-color: #f3f3f3;
  margin-top: 3em;
}
.article-document-section__forecast--panel__up-previous--link {
  display: inline-block;
}
.article-document-section__forecast--panel__up-previous--link-icon {
  font-weight: 700;
}
.article-document-section__forecast--panel__up-previous--link-icon:before {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "⟵";
  display: inline-block;
  margin-right: 0.5em;
}
.article-document-section__forecast--panel__up-previous--link-title {
  padding-top: 0.625em;
  font-size: 1.25em;
}
.article-document-section__forecast--panel__up-previous--link:hover {
  text-decoration: underline;
}
.article-document-section__forecast--panel__up-next {
  text-align: right;
  padding-top: 1.5625em;
}
.article-document-section__forecast--panel__up-next--link {
  display: inline-block;
}
.article-document-section__forecast--panel__up-next--link-icon {
  font-weight: 700;
}
.article-document-section__forecast--panel__up-next--link-icon:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "⟶";
  display: inline-block;
  margin-left: 0.5em;
}
.article-document-section__forecast--panel__up-next--link-title {
  padding-top: 0.625em;
  font-size: 1.25em;
}
.article-document-section__forecast--panel__up-next--link:hover {
  text-decoration: underline;
}
@media screen and (min-width: 56.25em) {
  .article-document-section__forecast--panel__up-previous {
    width: 50%;
  }
  .article-document-section__forecast--panel__up-next {
    width: 50%;
    padding-top: 0;
  }
}
.article-document-section__forecast--panel__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media screen and (min-width: 56.25em) {
  .article-document-section__forecast--panel__wrapper {
    flex-direction: row;
  }
}
.article-document-section__forecast--panel__label--text {
  font-size: 2em;
  line-height: 1.6;
}
.article-document-section__forecast--panel__button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 1.5625em;
}
.article-document-section__forecast--panel__button .btn-ghost {
  border: thin solid #fff;
  color: #fff;
}
@media screen and (min-width: 56.25em) {
  .article-document-section__forecast--panel__label {
    width: 70%;
  }
  .article-document-section__forecast--panel__button {
    width: 30%;
    padding-top: 0;
  }
}

.page-article-detail__forecast .btn-ghost-label {
  font-size: 1.5em;
  text-transform: none;
}
.page-article-detail__forecast.forecast-theme__melon .btn-ghost {
  color: #1e9f48;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
  border-color: #1e9f48;
}
.page-article-detail__forecast.forecast-theme__melon .btn-ghost:hover {
  color: #fff;
}
.page-article-detail__forecast.forecast-theme__melon .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-article-detail__forecast.forecast-theme__melon .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #1e9f48;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-article-detail__forecast.forecast-theme__melon .article-document-section__forecast--panel__this-article {
  background-color: #1e9f48;
}
.page-article-detail__forecast.forecast-theme__melon .article-document-section__forecast--panel__this-article .btn-ghost {
  color: #fff;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
  border-color: #fff;
}
.page-article-detail__forecast.forecast-theme__melon .article-document-section__forecast--panel__this-article .btn-ghost:hover {
  color: #1e9f48;
}
.page-article-detail__forecast.forecast-theme__melon .article-document-section__forecast--panel__this-article .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-article-detail__forecast.forecast-theme__melon .article-document-section__forecast--panel__this-article .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #fff;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-article-detail__forecast.forecast-theme__melon .article-document-section__forecast--panel__full-article {
  background-color: #45A565;
}
.page-article-detail__forecast.forecast-theme__melon .article-document-section__forecast--panel__full-article .btn-ghost {
  color: #fff;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
  border-color: #fff;
}
.page-article-detail__forecast.forecast-theme__melon .article-document-section__forecast--panel__full-article .btn-ghost:hover {
  color: #45A565;
}
.page-article-detail__forecast.forecast-theme__melon .article-document-section__forecast--panel__full-article .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-article-detail__forecast.forecast-theme__melon .article-document-section__forecast--panel__full-article .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #fff;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-article-detail__forecast.forecast-theme__melon .article-document-section__forecast--panel__subscribe .btn-ghost {
  color: #fff;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
  border-color: #fff;
}
.page-article-detail__forecast.forecast-theme__melon .article-document-section__forecast--panel__subscribe .btn-ghost:hover {
  color: #404040;
}
.page-article-detail__forecast.forecast-theme__melon .article-document-section__forecast--panel__subscribe .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-article-detail__forecast.forecast-theme__melon .article-document-section__forecast--panel__subscribe .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #fff;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-article-detail__forecast.forecast-theme__melon .reader-progress__bar {
  background-color: #1e9f48;
}
.page-article-detail__forecast.forecast-theme__marine .btn-ghost {
  color: #0373ac;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
  border-color: #0373ac;
}
.page-article-detail__forecast.forecast-theme__marine .btn-ghost:hover {
  color: #fff;
}
.page-article-detail__forecast.forecast-theme__marine .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-article-detail__forecast.forecast-theme__marine .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #0373ac;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-article-detail__forecast.forecast-theme__marine .article-document-section__forecast--panel__this-article {
  background-color: #0373ac;
}
.page-article-detail__forecast.forecast-theme__marine .article-document-section__forecast--panel__this-article .btn-ghost {
  color: #fff;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
  border-color: #fff;
}
.page-article-detail__forecast.forecast-theme__marine .article-document-section__forecast--panel__this-article .btn-ghost:hover {
  color: #0373ac;
}
.page-article-detail__forecast.forecast-theme__marine .article-document-section__forecast--panel__this-article .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-article-detail__forecast.forecast-theme__marine .article-document-section__forecast--panel__this-article .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #fff;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-article-detail__forecast.forecast-theme__marine .article-document-section__forecast--panel__full-article {
  background-color: #358fbd;
}
.page-article-detail__forecast.forecast-theme__marine .article-document-section__forecast--panel__full-article .btn-ghost {
  color: #fff;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
  border-color: #fff;
}
.page-article-detail__forecast.forecast-theme__marine .article-document-section__forecast--panel__full-article .btn-ghost:hover {
  color: #358fbd;
}
.page-article-detail__forecast.forecast-theme__marine .article-document-section__forecast--panel__full-article .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-article-detail__forecast.forecast-theme__marine .article-document-section__forecast--panel__full-article .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #fff;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-article-detail__forecast.forecast-theme__marine .article-document-section__forecast--panel__subscribe .btn-ghost {
  color: #fff;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
  border-color: #fff;
}
.page-article-detail__forecast.forecast-theme__marine .article-document-section__forecast--panel__subscribe .btn-ghost:hover {
  color: #404040;
}
.page-article-detail__forecast.forecast-theme__marine .article-document-section__forecast--panel__subscribe .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-article-detail__forecast.forecast-theme__marine .article-document-section__forecast--panel__subscribe .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #fff;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-article-detail__forecast.forecast-theme__marine .reader-progress__bar {
  background-color: #0373ac;
}
.page-article-detail__forecast.forecast-theme__plum .btn-ghost {
  color: #92338a;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
  border-color: #92338a;
}
.page-article-detail__forecast.forecast-theme__plum .btn-ghost:hover {
  color: #fff;
}
.page-article-detail__forecast.forecast-theme__plum .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-article-detail__forecast.forecast-theme__plum .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #92338a;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-article-detail__forecast.forecast-theme__plum .article-document-section__forecast--panel__this-article {
  background-color: #92338a;
}
.page-article-detail__forecast.forecast-theme__plum .article-document-section__forecast--panel__this-article .btn-ghost {
  color: #fff;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
  border-color: #fff;
}
.page-article-detail__forecast.forecast-theme__plum .article-document-section__forecast--panel__this-article .btn-ghost:hover {
  color: #92338a;
}
.page-article-detail__forecast.forecast-theme__plum .article-document-section__forecast--panel__this-article .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-article-detail__forecast.forecast-theme__plum .article-document-section__forecast--panel__this-article .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #fff;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-article-detail__forecast.forecast-theme__plum .article-document-section__forecast--panel__full-article {
  background-color: #a85ca1;
}
.page-article-detail__forecast.forecast-theme__plum .article-document-section__forecast--panel__full-article .btn-ghost {
  color: #fff;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
  border-color: #fff;
}
.page-article-detail__forecast.forecast-theme__plum .article-document-section__forecast--panel__full-article .btn-ghost:hover {
  color: #a85ca1;
}
.page-article-detail__forecast.forecast-theme__plum .article-document-section__forecast--panel__full-article .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-article-detail__forecast.forecast-theme__plum .article-document-section__forecast--panel__full-article .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #fff;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-article-detail__forecast.forecast-theme__plum .article-document-section__forecast--panel__subscribe .btn-ghost {
  color: #fff;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
  border-color: #fff;
}
.page-article-detail__forecast.forecast-theme__plum .article-document-section__forecast--panel__subscribe .btn-ghost:hover {
  color: #404040;
}
.page-article-detail__forecast.forecast-theme__plum .article-document-section__forecast--panel__subscribe .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-article-detail__forecast.forecast-theme__plum .article-document-section__forecast--panel__subscribe .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #fff;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-article-detail__forecast.forecast-theme__plum .reader-progress__bar {
  background-color: #92338a;
}
.page-article-detail__forecast.forecast-theme__red .btn-ghost {
  color: #cd122d;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
  border-color: #cd122d;
}
.page-article-detail__forecast.forecast-theme__red .btn-ghost:hover {
  color: #fff;
}
.page-article-detail__forecast.forecast-theme__red .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-article-detail__forecast.forecast-theme__red .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #cd122d;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-article-detail__forecast.forecast-theme__red .article-document-section__forecast--panel__this-article {
  background-color: #cd122d;
}
.page-article-detail__forecast.forecast-theme__red .article-document-section__forecast--panel__this-article .btn-ghost {
  color: #fff;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
  border-color: #fff;
}
.page-article-detail__forecast.forecast-theme__red .article-document-section__forecast--panel__this-article .btn-ghost:hover {
  color: #cd122d;
}
.page-article-detail__forecast.forecast-theme__red .article-document-section__forecast--panel__this-article .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-article-detail__forecast.forecast-theme__red .article-document-section__forecast--panel__this-article .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #fff;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-article-detail__forecast.forecast-theme__red .article-document-section__forecast--panel__full-article {
  background-color: #d74157;
}
.page-article-detail__forecast.forecast-theme__red .article-document-section__forecast--panel__full-article .btn-ghost {
  color: #fff;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
  border-color: #fff;
}
.page-article-detail__forecast.forecast-theme__red .article-document-section__forecast--panel__full-article .btn-ghost:hover {
  color: #d74157;
}
.page-article-detail__forecast.forecast-theme__red .article-document-section__forecast--panel__full-article .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-article-detail__forecast.forecast-theme__red .article-document-section__forecast--panel__full-article .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #fff;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-article-detail__forecast.forecast-theme__red .article-document-section__forecast--panel__subscribe .btn-ghost {
  color: #fff;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
  border-color: #fff;
}
.page-article-detail__forecast.forecast-theme__red .article-document-section__forecast--panel__subscribe .btn-ghost:hover {
  color: #404040;
}
.page-article-detail__forecast.forecast-theme__red .article-document-section__forecast--panel__subscribe .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-article-detail__forecast.forecast-theme__red .article-document-section__forecast--panel__subscribe .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #fff;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-article-detail__forecast.forecast-theme__red .reader-progress__bar {
  background-color: #cd122d;
}
.page-article-detail__forecast.forecast-theme__olive .btn-ghost {
  color: #558033;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
  border-color: #558033;
}
.page-article-detail__forecast.forecast-theme__olive .btn-ghost:hover {
  color: #fff;
}
.page-article-detail__forecast.forecast-theme__olive .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-article-detail__forecast.forecast-theme__olive .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #558033;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-article-detail__forecast.forecast-theme__olive .article-document-section__forecast--panel__this-article {
  background-color: #558033;
}
.page-article-detail__forecast.forecast-theme__olive .article-document-section__forecast--panel__this-article .btn-ghost {
  color: #fff;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
  border-color: #fff;
}
.page-article-detail__forecast.forecast-theme__olive .article-document-section__forecast--panel__this-article .btn-ghost:hover {
  color: #558033;
}
.page-article-detail__forecast.forecast-theme__olive .article-document-section__forecast--panel__this-article .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-article-detail__forecast.forecast-theme__olive .article-document-section__forecast--panel__this-article .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #fff;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-article-detail__forecast.forecast-theme__olive .article-document-section__forecast--panel__full-article {
  background-color: #64963c;
}
.page-article-detail__forecast.forecast-theme__olive .article-document-section__forecast--panel__full-article .btn-ghost {
  color: #fff;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
  border-color: #fff;
}
.page-article-detail__forecast.forecast-theme__olive .article-document-section__forecast--panel__full-article .btn-ghost:hover {
  color: #64963c;
}
.page-article-detail__forecast.forecast-theme__olive .article-document-section__forecast--panel__full-article .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-article-detail__forecast.forecast-theme__olive .article-document-section__forecast--panel__full-article .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #fff;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-article-detail__forecast.forecast-theme__olive .article-document-section__forecast--panel__subscribe .btn-ghost {
  color: #fff;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
  border-color: #fff;
}
.page-article-detail__forecast.forecast-theme__olive .article-document-section__forecast--panel__subscribe .btn-ghost:hover {
  color: #404040;
}
.page-article-detail__forecast.forecast-theme__olive .article-document-section__forecast--panel__subscribe .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-article-detail__forecast.forecast-theme__olive .article-document-section__forecast--panel__subscribe .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #fff;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-article-detail__forecast.forecast-theme__olive .reader-progress__bar {
  background-color: #558033;
}
.page-article-detail__forecast.forecast-theme__brand-red .btn-ghost {
  color: #CC092F;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
  border-color: #CC092F;
}
.page-article-detail__forecast.forecast-theme__brand-red .btn-ghost:hover {
  color: #fff;
}
.page-article-detail__forecast.forecast-theme__brand-red .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-article-detail__forecast.forecast-theme__brand-red .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #CC092F;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-article-detail__forecast.forecast-theme__brand-red .article-document-section__forecast--panel__this-article {
  background-color: #CC092F;
}
.page-article-detail__forecast.forecast-theme__brand-red .article-document-section__forecast--panel__this-article .btn-ghost {
  color: #fff;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
  border-color: #fff;
}
.page-article-detail__forecast.forecast-theme__brand-red .article-document-section__forecast--panel__this-article .btn-ghost:hover {
  color: #CC092F;
}
.page-article-detail__forecast.forecast-theme__brand-red .article-document-section__forecast--panel__this-article .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-article-detail__forecast.forecast-theme__brand-red .article-document-section__forecast--panel__this-article .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #fff;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-article-detail__forecast.forecast-theme__brand-red .article-document-section__forecast--panel__full-article {
  background-color: #d63a59;
}
.page-article-detail__forecast.forecast-theme__brand-red .article-document-section__forecast--panel__full-article .btn-ghost {
  color: #fff;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
  border-color: #fff;
}
.page-article-detail__forecast.forecast-theme__brand-red .article-document-section__forecast--panel__full-article .btn-ghost:hover {
  color: #d63a59;
}
.page-article-detail__forecast.forecast-theme__brand-red .article-document-section__forecast--panel__full-article .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-article-detail__forecast.forecast-theme__brand-red .article-document-section__forecast--panel__full-article .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #fff;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-article-detail__forecast.forecast-theme__brand-red .article-document-section__forecast--panel__subscribe .btn-ghost {
  color: #fff;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
  border-color: #fff;
}
.page-article-detail__forecast.forecast-theme__brand-red .article-document-section__forecast--panel__subscribe .btn-ghost:hover {
  color: #404040;
}
.page-article-detail__forecast.forecast-theme__brand-red .article-document-section__forecast--panel__subscribe .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-article-detail__forecast.forecast-theme__brand-red .article-document-section__forecast--panel__subscribe .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #fff;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-article-detail__forecast.forecast-theme__brand-red .reader-progress__bar {
  background-color: #CC092F;
}
.page-article-detail__forecast.forecast-theme__royal .btn-ghost {
  color: #283584;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
  border-color: #283584;
}
.page-article-detail__forecast.forecast-theme__royal .btn-ghost:hover {
  color: #fff;
}
.page-article-detail__forecast.forecast-theme__royal .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-article-detail__forecast.forecast-theme__royal .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #283584;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-article-detail__forecast.forecast-theme__royal .article-document-section__forecast--panel__this-article {
  background-color: #283584;
}
.page-article-detail__forecast.forecast-theme__royal .article-document-section__forecast--panel__this-article .btn-ghost {
  color: #fff;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
  border-color: #fff;
}
.page-article-detail__forecast.forecast-theme__royal .article-document-section__forecast--panel__this-article .btn-ghost:hover {
  color: #283584;
}
.page-article-detail__forecast.forecast-theme__royal .article-document-section__forecast--panel__this-article .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-article-detail__forecast.forecast-theme__royal .article-document-section__forecast--panel__this-article .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #fff;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-article-detail__forecast.forecast-theme__royal .article-document-section__forecast--panel__full-article {
  background-color: #535d9d;
}
.page-article-detail__forecast.forecast-theme__royal .article-document-section__forecast--panel__full-article .btn-ghost {
  color: #fff;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
  border-color: #fff;
}
.page-article-detail__forecast.forecast-theme__royal .article-document-section__forecast--panel__full-article .btn-ghost:hover {
  color: #535d9d;
}
.page-article-detail__forecast.forecast-theme__royal .article-document-section__forecast--panel__full-article .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-article-detail__forecast.forecast-theme__royal .article-document-section__forecast--panel__full-article .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #fff;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-article-detail__forecast.forecast-theme__royal .article-document-section__forecast--panel__subscribe .btn-ghost {
  color: #fff;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
  border-color: #fff;
}
.page-article-detail__forecast.forecast-theme__royal .article-document-section__forecast--panel__subscribe .btn-ghost:hover {
  color: #404040;
}
.page-article-detail__forecast.forecast-theme__royal .article-document-section__forecast--panel__subscribe .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-article-detail__forecast.forecast-theme__royal .article-document-section__forecast--panel__subscribe .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #fff;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-article-detail__forecast.forecast-theme__royal .reader-progress__bar {
  background-color: #283584;
}
.page-article-detail__forecast.forecast-theme__tangerine .btn-ghost {
  color: #E94B00;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
  border-color: #E94B00;
}
.page-article-detail__forecast.forecast-theme__tangerine .btn-ghost:hover {
  color: #fff;
}
.page-article-detail__forecast.forecast-theme__tangerine .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-article-detail__forecast.forecast-theme__tangerine .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #E94B00;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-article-detail__forecast.forecast-theme__tangerine .article-document-section__forecast--panel__this-article {
  background-color: #E94B00;
}
.page-article-detail__forecast.forecast-theme__tangerine .article-document-section__forecast--panel__this-article .btn-ghost {
  color: #fff;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
  border-color: #fff;
}
.page-article-detail__forecast.forecast-theme__tangerine .article-document-section__forecast--panel__this-article .btn-ghost:hover {
  color: #E94B00;
}
.page-article-detail__forecast.forecast-theme__tangerine .article-document-section__forecast--panel__this-article .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-article-detail__forecast.forecast-theme__tangerine .article-document-section__forecast--panel__this-article .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #fff;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-article-detail__forecast.forecast-theme__tangerine .article-document-section__forecast--panel__full-article {
  background-color: #ed6f33;
}
.page-article-detail__forecast.forecast-theme__tangerine .article-document-section__forecast--panel__full-article .btn-ghost {
  color: #fff;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
  border-color: #fff;
}
.page-article-detail__forecast.forecast-theme__tangerine .article-document-section__forecast--panel__full-article .btn-ghost:hover {
  color: #ed6f33;
}
.page-article-detail__forecast.forecast-theme__tangerine .article-document-section__forecast--panel__full-article .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-article-detail__forecast.forecast-theme__tangerine .article-document-section__forecast--panel__full-article .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #fff;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-article-detail__forecast.forecast-theme__tangerine .article-document-section__forecast--panel__subscribe .btn-ghost {
  color: #fff;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
  border-color: #fff;
}
.page-article-detail__forecast.forecast-theme__tangerine .article-document-section__forecast--panel__subscribe .btn-ghost:hover {
  color: #404040;
}
.page-article-detail__forecast.forecast-theme__tangerine .article-document-section__forecast--panel__subscribe .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-article-detail__forecast.forecast-theme__tangerine .article-document-section__forecast--panel__subscribe .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #fff;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-article-detail__forecast.forecast-theme__tangerine .reader-progress__bar {
  background-color: #E94B00;
}
.page-article-detail__forecast.forecast-theme__dark .btn-ghost {
  color: #696969;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
  border-color: #696969;
}
.page-article-detail__forecast.forecast-theme__dark .btn-ghost:hover {
  color: #fff;
}
.page-article-detail__forecast.forecast-theme__dark .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-article-detail__forecast.forecast-theme__dark .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #696969;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-article-detail__forecast.forecast-theme__dark .article-document-section__forecast--panel__this-article {
  background-color: #696969;
}
.page-article-detail__forecast.forecast-theme__dark .article-document-section__forecast--panel__this-article .btn-ghost {
  color: #fff;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
  border-color: #fff;
}
.page-article-detail__forecast.forecast-theme__dark .article-document-section__forecast--panel__this-article .btn-ghost:hover {
  color: #696969;
}
.page-article-detail__forecast.forecast-theme__dark .article-document-section__forecast--panel__this-article .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-article-detail__forecast.forecast-theme__dark .article-document-section__forecast--panel__this-article .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #fff;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-article-detail__forecast.forecast-theme__dark .article-document-section__forecast--panel__full-article {
  background-color: #878787;
}
.page-article-detail__forecast.forecast-theme__dark .article-document-section__forecast--panel__full-article .btn-ghost {
  color: #fff;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
  border-color: #fff;
}
.page-article-detail__forecast.forecast-theme__dark .article-document-section__forecast--panel__full-article .btn-ghost:hover {
  color: #878787;
}
.page-article-detail__forecast.forecast-theme__dark .article-document-section__forecast--panel__full-article .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-article-detail__forecast.forecast-theme__dark .article-document-section__forecast--panel__full-article .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #fff;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-article-detail__forecast.forecast-theme__dark .article-document-section__forecast--panel__subscribe .btn-ghost {
  color: #fff;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
  border-color: #fff;
}
.page-article-detail__forecast.forecast-theme__dark .article-document-section__forecast--panel__subscribe .btn-ghost:hover {
  color: #404040;
}
.page-article-detail__forecast.forecast-theme__dark .article-document-section__forecast--panel__subscribe .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-article-detail__forecast.forecast-theme__dark .article-document-section__forecast--panel__subscribe .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #fff;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-article-detail__forecast.forecast-theme__dark .reader-progress__bar {
  background-color: #696969;
}
.page-article-detail__forecast.forecast-theme__light .btn-ghost {
  color: #6A6A6A;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
  border-color: #6A6A6A;
}
.page-article-detail__forecast.forecast-theme__light .btn-ghost:hover {
  color: #fff;
}
.page-article-detail__forecast.forecast-theme__light .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-article-detail__forecast.forecast-theme__light .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #6A6A6A;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-article-detail__forecast.forecast-theme__light .article-document-section__forecast--panel__this-article {
  background-color: #6A6A6A;
}
.page-article-detail__forecast.forecast-theme__light .article-document-section__forecast--panel__this-article .btn-ghost {
  color: #fff;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
  border-color: #fff;
}
.page-article-detail__forecast.forecast-theme__light .article-document-section__forecast--panel__this-article .btn-ghost:hover {
  color: #6A6A6A;
}
.page-article-detail__forecast.forecast-theme__light .article-document-section__forecast--panel__this-article .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-article-detail__forecast.forecast-theme__light .article-document-section__forecast--panel__this-article .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #fff;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-article-detail__forecast.forecast-theme__light .article-document-section__forecast--panel__full-article {
  background-color: #888888;
}
.page-article-detail__forecast.forecast-theme__light .article-document-section__forecast--panel__full-article .btn-ghost {
  color: #fff;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
  border-color: #fff;
}
.page-article-detail__forecast.forecast-theme__light .article-document-section__forecast--panel__full-article .btn-ghost:hover {
  color: #888888;
}
.page-article-detail__forecast.forecast-theme__light .article-document-section__forecast--panel__full-article .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-article-detail__forecast.forecast-theme__light .article-document-section__forecast--panel__full-article .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #fff;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-article-detail__forecast.forecast-theme__light .article-document-section__forecast--panel__subscribe .btn-ghost {
  color: #fff;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
  border-color: #fff;
}
.page-article-detail__forecast.forecast-theme__light .article-document-section__forecast--panel__subscribe .btn-ghost:hover {
  color: #404040;
}
.page-article-detail__forecast.forecast-theme__light .article-document-section__forecast--panel__subscribe .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-article-detail__forecast.forecast-theme__light .article-document-section__forecast--panel__subscribe .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #fff;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-article-detail__forecast.forecast-theme__light .reader-progress__bar {
  background-color: #6A6A6A;
}

.site-header {
  background-color: transparent;
  color: #222;
  width: 100%;
  height: 5em;
  position: absolute;
  z-index: 500;
  opacity: 1;
  top: 0;
}
.site-header:before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  left: 0;
  opacity: 0;
  transition: width 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.site-header-wrapper {
  max-width: 100.3125em;
  padding-left: 1.875em;
  padding-right: 1.875em;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2.8125em;
  padding-left: 3.75em;
  height: 100%;
  position: relative;
}
@media screen and (max-width: 56.1875em) {
  .site-header-wrapper {
    padding-left: 1em;
    padding-right: 1em;
  }
}
.site-header-wrapper:before {
  content: "";
  position: absolute;
  top: 0;
  left: -15.3125em;
  width: 0;
  height: 0;
  border-top: 11.1875em solid #fff;
  border-right: 64.9375em solid transparent;
}
.site-header-wrapper:after {
  transition: border-color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  position: absolute;
  top: 1.40625em;
  left: 1.40625em;
  width: 0;
  height: 0;
  border-top: 6.875em solid #3C3B3B;
  border-right: 40em solid transparent;
}

.site-logo {
  width: 15.75em;
  display: inline-block;
  float: right;
  position: relative;
  height: 100%;
  background-color: #fff;
  padding: 1.25em 1.875em 0;
  opacity: 0;
  visibility: hidden;
  transform: translateZ(0);
}
.site-logo:before {
  content: "";
  display: block;
  position: absolute;
  left: -20px;
  top: 0;
  width: 0;
  height: 0;
  border-left: 1.25em solid transparent;
  border-right: 2.5em solid transparent;
  border-bottom: 5em solid #fff;
}
.site-logo--diversity {
  padding-top: 0.25em;
}

.site-logo-img {
  display: block;
  width: 100%;
  position: relative;
  z-index: 5;
}

.site-header-utility {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 25%;
  z-index: 510;
  display: block;
}
.site-header-utility .hide-language-selection {
  border-right: 0px;
}
.site-header-utility .ico-home {
  color: #E5B522;
}

.site-header-search {
  border: 1px solid #E5B522;
  border-width: 0 1px;
  padding: 0 0.75em;
  margin: 0 0.5em;
  vertical-align: middle;
  font-size: 1.125em;
  line-height: 1em;
  color: #E5B522;
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.site-header-search:after {
  content: "\e902";
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.headroom--unpinned {
  transform: translate3d(0, -80px, 0);
}

.setup-fixed-header {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  position: fixed;
  background-color: #3C3B3B;
  will-change: position;
}
.setup-fixed-header:before {
  background-color: #3D3C3C;
  opacity: 1;
}
.setup-fixed-header .site-logo {
  opacity: 1;
  visibility: visible;
}
.setup-fixed-header .site-header-wrapper {
  padding-top: 0;
  padding-right: 0;
  max-width: none;
}
.setup-fixed-header .site-header-wrapper:before, .setup-fixed-header .site-header-wrapper:after {
  opacity: 0;
  visibility: hidden;
}

.headroom--pinned {
  transform: translate3d(0, 0, 0);
}

.show-site-nav .menu-toggle {
  color: #fff;
}
.show-site-nav .site-header-search,
.show-site-nav .language-menu {
  opacity: 0;
}

.show-searchbox:before {
  width: 100%;
}
.show-searchbox .site-header-search {
  color: #fff;
  transform: translateX(-6.875em);
  border-color: #3D3C3C;
}
.show-searchbox .site-header-search:after {
  content: "\e904";
  font-size: 1.5em;
}
.show-searchbox .menu-toggle,
.show-searchbox .language-menu {
  opacity: 0;
}
.show-searchbox .site-header-wrapper:after {
  border-top-color: #3D3C3C;
}

@media screen and (max-width: 62.4375em) {
  .site-header-wrapper {
    padding-top: 1.875em;
    padding-left: 2.8125em;
  }
  .site-header-wrapper:before {
    border-top-width: 10.6875em;
    border-right-width: 61.875em;
  }
  .site-header-wrapper:after {
    top: 1em;
    left: 1em;
  }
}
@media screen and (max-width: 56.1875em) {
  .site-header {
    padding-left: 1em;
    height: 4.0625em;
  }
  .site-header.headroom--unpinned {
    transform: translate3d(0, -65px, 0);
  }
  .site-header.setup-fixed-header {
    padding-left: 0;
  }
  .site-header.setup-fixed-header .site-header-wrapper:after {
    opacity: 1;
  }
  .site-header-wrapper {
    padding-left: 1.5em;
    padding-right: 0;
  }
  .site-header-wrapper:before {
    border-top-width: 7.9375em;
    border-right-width: 54.6875em;
  }
  .site-header-wrapper:after {
    opacity: 1;
    left: 0;
    border-top-width: 4.75em;
    border-right-width: 32.8125em;
  }
  .site-header-search {
    font-size: 1em;
  }
  .site-logo {
    width: 9.5em;
    padding: 1.25em 1em 0;
  }
  .site-logo:before {
    left: -16.25px;
    border-left: 1.015625em solid transparent;
    border-right: 2.03125em solid transparent;
    border-bottom: 4.0625em solid #fff;
  }
  .show-searchbox .site-header-search {
    transform: translateX(-3.4375em);
  }
  .show-searchbox.setup-fixed-header .site-header-search {
    transform: translateX(-3.4375em);
  }
}
@media screen and (max-width: 47.4375em) {
  .site-header {
    padding-left: 0;
    padding-right: 0;
    height: 3.125em;
    transform: translate3d(0, 0, 0);
  }
  .site-header.headroom--not-top {
    height: 4.0625em;
  }
  .site-header .language-menu:before {
    content: "|";
    padding: 0 0.25em;
    display: inline-block;
    vertical-align: top;
  }
  .site-header-search {
    position: absolute;
    right: 0;
    color: #fff;
    border-width: 0;
    visibility: hidden;
    width: 0;
    opacity: 0;
    padding: 0;
    margin: 0;
    transition: right 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86), opacity 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86), visibility 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.35s;
    z-index: 5;
  }
  .site-header-search:after {
    content: "\e902";
    font-size: 1.25em;
  }
  .site-header-wrapper {
    padding-top: 0;
    padding-left: 1em;
  }
  .site-header-wrapper:before {
    content: none;
  }
  .site-header-wrapper:after {
    top: 0;
    border-top-width: 3.4375em;
    border-right-width: 18.75em;
  }
  .site-header-search {
    border-right: 0;
    padding-right: 0;
  }
  .show-searchbox .site-header-search {
    right: calc(100% - 200px);
    transform: translateX(-0.875em);
  }
  .show-searchbox .site-header-search:after {
    content: "\e902";
    font-size: 1.25em;
  }
  .show-searchbox .site-header-wrapper:after {
    display: none;
    border-color: transparent;
  }
  .show-searchbox.setup-fixed-header .site-header-search {
    transform: translateX(-0.875em);
  }
  .show-site-nav .site-header-search {
    opacity: 1;
    visibility: visible;
    margin: 0 0.5em;
    top: 3px;
    left: 40px;
    width: 20px;
    height: 22px;
  }
  .show-site-nav.show-searchbox .site-header-search {
    left: 50px;
  }
}
.site-nav {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  position: absolute;
  top: 1.40625em;
  left: 1.40625em;
  right: 1.875em;
  background-color: #222;
  z-index: 5;
  color: #E5B522;
  padding: 10em 6% 10%;
  transform: translateY(-110%);
  visibility: hidden;
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86), visibility 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.35s;
}

.setup-fixed-header .site-nav {
  top: 0;
  left: 0;
  right: 0;
}
.setup-fixed-header .site-nav-wrapper {
  max-width: 100.3125em;
  padding-left: 1.875em;
  padding-right: 1.875em;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
}
@media screen and (max-width: 56.1875em) {
  .setup-fixed-header .site-nav-wrapper {
    padding-left: 1em;
    padding-right: 1em;
  }
}

.show-site-nav .site-nav {
  visibility: visible;
  transform: translateY(0);
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.show-site-nav .site-nav-wrapper {
  opacity: 1;
}

.site-nav-wrapper {
  position: relative;
  opacity: 0;
  transition: opacity 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-delay: 0.35s;
  display: flex;
  flex-wrap: nowrap;
}

.site-nav-menu {
  width: 45%;
  min-width: 26.25em;
}

.site-nav-additional {
  text-align: right;
  flex-grow: 1;
}

.site-nav-utility {
  margin-top: 1.375em;
}

.site-nav-menu-item + .site-nav-menu-item,
.site-nav-menu-item + .site-nav-submenu-item,
.site-nav-submenu-item + .site-nav-menu-item,
.site-nav-submenu-item + .site-nav-submenu-item {
  margin-top: 1.5625em;
}

.site-nav-tertiary-item + .site-nav-tertiary-item {
  margin-top: 1em;
}

.site-nav-utility-item + .site-nav-utility-item {
  margin-top: 0.3125em;
}

.site-nav-menu-link {
  font-weight: 800;
  font-size: 3em;
  position: relative;
  transition: none;
  color: #E5B522;
  display: inline-block;
}
.site-nav-menu-link:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0%;
  bottom: -1px;
  left: 0;
  height: 1px;
  background-color: #fff;
  transition: 0.35s;
}
.site-nav-menu-link:hover {
  color: #fff;
}
.site-nav-menu-link:hover:before {
  width: 100%;
}
@media screen and (max-width: 47.4375em) {
  .site-nav-menu-link:before {
    display: none;
  }
  .site-nav-menu-link:hover:before {
    display: none;
  }
}
.site-nav-menu-link:hover {
  color: #fff;
}

.site-nav-submenu-link {
  font-weight: 300;
  font-size: 3em;
  position: relative;
  transition: none;
  color: #E5B522;
  display: inline-block;
}
.site-nav-submenu-link:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0%;
  bottom: -1px;
  left: 0;
  height: 1px;
  background-color: #fff;
  transition: 0.35s;
}
.site-nav-submenu-link:hover {
  color: #fff;
}
.site-nav-submenu-link:hover:before {
  width: 100%;
}
@media screen and (max-width: 47.4375em) {
  .site-nav-submenu-link:before {
    display: none;
  }
  .site-nav-submenu-link:hover:before {
    display: none;
  }
}
.site-nav-submenu-link:hover {
  color: #fff;
}
.site-nav-submenu-link.is-external-link {
  padding-right: 0.6875em;
}
.site-nav-submenu-link.is-external-link:after {
  position: absolute;
  font-size: 0.4583333333em;
  margin-left: 0.6em;
  margin-top: 0.9em;
}

.site-nav-tertiary-link {
  position: relative;
  transition: none;
  color: #E5B522;
  display: inline-block;
  font-size: 1.625em;
  display: inline;
}
.site-nav-tertiary-link:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0%;
  bottom: -1px;
  left: 0;
  height: 1px;
  background-color: #fff;
  transition: 0.35s;
}
.site-nav-tertiary-link:hover {
  color: #fff;
}
.site-nav-tertiary-link:hover:before {
  width: 100%;
}
@media screen and (max-width: 47.4375em) {
  .site-nav-tertiary-link:before {
    display: none;
  }
  .site-nav-tertiary-link:hover:before {
    display: none;
  }
}
.site-nav-tertiary-link:hover {
  color: #fff;
}
.site-nav-tertiary-link.is-external-link {
  padding-right: 0.9230769231em;
}
.site-nav-tertiary-link.is-external-link:after {
  margin-top: 0.75em;
}
.site-nav-tertiary-link.site-nav-tertiary-link--home-link {
  padding-right: 1em;
}
.site-nav-tertiary-link.site-nav-tertiary-link--home-link:after {
  content: "\e90d";
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.6153846154em;
  position: absolute;
  margin-top: 0.65em;
  right: 0;
}

.site-nav-utility-link {
  position: relative;
  transition: none;
  color: #9d9d9d;
  display: inline-block;
  font-size: 1.25em;
  color: #9d9d9d;
}
.site-nav-utility-link:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0%;
  bottom: -1px;
  left: 0;
  height: 1px;
  background-color: #fff;
  transition: 0.35s;
}
.site-nav-utility-link:hover {
  color: #fff;
}
.site-nav-utility-link:hover:before {
  width: 100%;
}
@media screen and (max-width: 47.4375em) {
  .site-nav-utility-link:before {
    display: none;
  }
  .site-nav-utility-link:hover:before {
    display: none;
  }
}
.site-nav-utility-link:hover {
  color: #fff;
}

html[lang=de] .site-nav-menu {
  width: 35%;
}
html[lang=de] .site-nav-submenu-link {
  word-wrap: break-word;
  word-break: break-word;
}

@media screen and (max-width: 64em) {
  .site-nav-menu-link {
    font-size: 2.25em;
  }
  .site-nav-submenu-link {
    font-size: 2.25em;
  }
  .site-nav-tertiary-link {
    font-size: 1.5em;
  }
  .site-nav-utility-link {
    font-size: 1.125em;
  }
  html[lang=de] .site-nav-menu {
    width: auto;
    padding-right: 1em;
    min-width: 35%;
  }
  html[lang=de] .site-nav-menu-link {
    font-size: 1.5em;
  }
  html[lang=de] .site-nav-submenu-link {
    font-size: 1.5em;
  }
  html[lang=de] .site-nav-tertiary-link {
    font-size: 1.125em;
  }
  html[lang=de] .site-nav-utility-link {
    font-size: 1em;
  }
}
@media screen and (max-width: 62.4375em) {
  .site-nav {
    padding-left: 1.875em;
    padding-right: 1.875em;
  }
  .site-nav-menu {
    width: 50%;
    min-width: auto;
  }
  .setup-fixed-header .site-nav-wrapper {
    padding-right: 0;
  }
}
@media screen and (max-width: 62.4375em) {
  .site-nav {
    top: 1em;
    left: 1em;
  }
}
@media screen and (max-width: 56.1875em) {
  .site-nav {
    left: 0;
    right: 1em;
    padding: 5.4375em 1.875em;
  }
  .site-nav-menu {
    width: 40%;
    min-width: auto;
  }
  .site-nav-menu-link {
    font-size: 1.5em;
  }
  .site-nav-submenu-link {
    font-size: 1.5em;
  }
  .site-nav-tertiary-link {
    font-size: 1.125em;
  }
  .site-nav-tertiary-link.is-external-link:after {
    font-size: 0.7rem;
    margin-top: 0.5em;
  }
  .site-nav-utility-link {
    font-size: 1em;
  }
  html[lang=de] .site-nav-menu {
    min-width: 30%;
  }
}
@media screen and (max-width: 47.4375em) {
  .site-nav {
    top: 0;
    right: 0;
    left: 0;
  }
  .headroom--not-top .site-nav-wrapper {
    padding-left: 0;
  }
}
@media screen and (max-width: 39.3125em) {
  .site-nav {
    padding: 4.0625em 1.375em;
  }
  .site-nav-wrapper {
    display: block;
  }
  .site-nav-menu-item + .site-nav-menu-item,
  .site-nav-menu-item + .site-nav-submenu-item,
  .site-nav-submenu-item + .site-nav-menu-item,
  .site-nav-submenu-item + .site-nav-submenu-item {
    margin-top: 0.625em;
  }
  .site-nav-tertiary-item + .site-nav-tertiary-item {
    margin-top: 0.625em;
  }
  .site-nav-utility-item + .site-nav-utility-item {
    margin-top: 0.3125em;
  }
  .site-nav-tertiary-menu,
  .site-nav-submenu {
    margin-top: 1.5625em;
  }
  .site-nav-additional {
    text-align: left;
  }
  .site-nav-utility {
    position: static;
    bottom: auto;
    right: auto;
    margin-top: 1.5625em;
  }
}
.global-search {
  position: absolute;
  top: 1.40625em;
  left: 6.09375em;
  right: 1.875em;
  padding-right: 16.5625em;
  z-index: 515;
  visibility: hidden;
  transition: visibility 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.35s;
}
.global-search .searchbox-input {
  padding-top: 0.625em;
  padding-bottom: 0.625em;
}
.global-search .searchbox-btn {
  right: 0;
  width: 2.8125em;
}
.global-search .searchbox-btn:before {
  font-size: 1.125em;
}

.global-search-wrapper {
  background-color: #3D3C3C;
  padding: 1em;
  width: 0;
  opacity: 0;
  overflow: hidden;
  transition: opacity 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86), width 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.site-search-panel-searchbox {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  top: 45%;
}
.site-search-panel-searchbox .searchbox-input {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.show-searchbox .global-search {
  visibility: visible;
  transition: visibility 100ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.show-searchbox .global-search-wrapper {
  width: 100%;
  opacity: 1;
  transition: opacity 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.35s, width 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.35s;
}
.show-searchbox .global-search-wrapper--diversity {
  width: 93%;
}

.setup-fixed-header .global-search {
  top: 0;
  padding-right: 15em;
}

@media screen and (max-width: 62.4375em) {
  .global-search {
    top: 1em;
    padding-right: 13.875em;
  }
}
@media screen and (max-width: 56.1875em) {
  .global-search {
    left: 4em;
  }
  .global-search-wrapper {
    padding: 0.5em;
  }
  .setup-fixed-header .global-search {
    left: 2.5em;
    padding-right: 9.375em;
  }
  .setup-fixed-header .global-search-wrapper {
    margin-top: 0.25em;
  }
}
@media screen and (max-width: 47.4375em) {
  .global-search {
    transition: left 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    top: 0;
    left: 100%;
    right: 0;
    padding-right: 0;
    z-index: 509;
    overflow: hidden;
    visibility: visible;
  }
  .global-search .searchbox-btn {
    opacity: 0;
    visibility: hidden;
  }
  .global-search-wrapper {
    background-color: transparent;
    width: 100%;
    transition-delay: 0s;
    opacity: 1;
  }
  .setup-fixed-header .global-search {
    left: 100%;
    padding-right: 0;
  }
  .show-searchbox .global-search {
    transition: left 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    left: 9.375em;
    z-index: 512;
  }
  .show-searchbox .menu-toggle {
    opacity: 1;
  }
  .show-searchbox .global-search-wrapper {
    transition-delay: 0s;
  }
  .show-searchbox.setup-fixed-header:before {
    display: none;
  }
  .global-search-wrapper {
    padding-left: 1em;
    padding-right: 1em;
  }
}
.site-footer {
  text-align: right;
  padding-bottom: 5em;
  z-index: 100;
}
.site-footer a:not(.site-footer-menu-link) {
  text-decoration: underline;
}
.site-footer .social-links-icon {
  color: #CC092F;
  margin-left: 0.75em;
}
.site-footer .social-links-icon:before {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  font-size: 1.625em;
  transform: translateZ(0) scale(1);
}
.site-footer .social-links-icon:hover {
  color: #222;
}
.site-footer .social-links-icon:hover:before {
  transform: scale(1.2);
}
.site-footer .ico-google-plus:before {
  font-size: 2.125rem;
}

.site-footer-menu {
  margin-top: 1.5625em;
}

.site-footer-menu-item {
  display: inline;
  margin-left: 0.75em;
  line-height: 1em;
}
.site-footer-menu-item + .site-footer-menu-item:before {
  content: "|";
  display: inline-block;
  padding-right: 0.75em;
}

.site-footer-menu-link {
  font-size: 0.875em;
  font-weight: 700;
  color: #5B5B5B;
}
.site-footer-menu-link:hover {
  color: #222;
}

.site-footer-copyright {
  font-size: 0.875em;
  color: #6E6E6E;
  margin-top: 0.625em;
}

@media screen and (max-width: 39.3125em) {
  .site-footer {
    text-align: center;
    margin-top: 2.5em;
  }
  .site-footer .social-links-icon:before {
    font-size: 1.5rem;
  }
  .site-footer .ico-google-plus:before {
    font-size: 1.875rem;
  }
  .site-footer-menu-link {
    display: inline-block;
  }
  .site-footer-menu-item:last-child:before {
    display: none;
  }
}
.notable-news {
  color: #fff;
  position: relative;
  padding: 6.875em 1.875em 20vw;
  overflow: hidden;
  margin-bottom: -3.125em;
}
.notable-news .page-wrapper {
  position: relative;
  z-index: 5;
}
.notable-news .listing-articles-grid {
  margin-top: 5em;
}
.notable-news .angled-background {
  top: -5.5vw;
  bottom: auto;
  height: 100%;
}

.notable-news-view-more {
  margin-top: 2.5em;
  text-align: center;
}

@media screen and (max-width: 67.125em) {
  .notable-news {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 0;
  }
}
@media screen and (max-width: 56.1875em) {
  .notable-news {
    text-align: center;
    padding-top: 5em;
  }
  .notable-news .listing-articles-grid {
    margin-top: 5em;
  }
}
@media screen and (max-width: 39.3125em) {
  .notable-news {
    text-align: left;
  }
}
.around-corner {
  overflow: hidden;
  margin-bottom: 9.375em;
  position: relative;
}
.around-corner .section-header {
  margin-bottom: 5em;
}
.around-corner-diversity {
  margin-bottom: 0;
}
.around-corner .article-tile {
  font-size: 0.8em;
  width: calc(33.333% - 1.875em);
  min-height: 14vw;
}
.around-corner .article-tile-card {
  margin-bottom: 5rem;
}

@media screen and (max-width: 67.125em) {
  .around-corner .article-tile {
    width: calc(50% - 1.875em);
    min-height: 28vw;
  }
}
@media screen and (max-width: 56.1875em) {
  .around-corner .section-header {
    margin-bottom: 5em;
  }
  .around-corner .article-tile {
    font-size: inherit;
  }
}
@media screen and (max-width: 47.4375em) {
  .around-corner {
    margin-bottom: 3.125em;
  }
  .around-corner .section-header {
    margin-bottom: 2.5em;
  }
  .around-corner .article-tile {
    width: 100%;
    min-height: 42vw;
  }
  .around-corner .article-tile-card {
    margin-bottom: 2rem;
  }
}
@media screen and (max-width: 26.1875em) {
  .around-corner .article-tile-card {
    max-width: none;
  }
}
.around-office {
  overflow: hidden;
  position: relative;
  margin-bottom: 6.875em;
}

.around-office-reel {
  white-space: nowrap;
  margin-top: 5em;
}
.around-office-reel .around-corner-tile {
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.around-office-reel .around-corner-tile + .around-corner-tile {
  margin-left: 5em;
}
.around-office-reel .article-tile {
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.around-office-reel .article-tile + .article-tile {
  margin-left: 5em;
}

@media screen and (max-width: 64em) {
  .around-office-reel {
    white-space: normal;
  }
  .around-office-reel .around-corner-tile {
    display: block;
  }
  .around-office-reel .around-corner-tile + .around-corner-tile {
    margin-left: auto;
    margin-top: 2.5em;
  }
  .around-office-reel .article-tile {
    display: block;
  }
  .around-office-reel .article-tile + .around-corner-tile, .around-office-reel .article-tile + .article-tile {
    margin-left: auto;
    margin-top: 2.5em;
  }
}
@media screen and (max-width: 56.1875em) {
  .around-office-reel {
    margin-top: 2.5em;
  }
}
@media screen and (max-width: 47.4375em) {
  .around-office {
    margin-bottom: 5em;
  }
  .around-office .around-corner-tile + .around-corner-tile {
    margin-top: 2.5em;
  }
  .around-office .article-tile + .article-tile {
    margin-top: 2.5em;
  }
  .around-office-reel {
    overflow: visible;
  }
}
.global-perspectives {
  margin-bottom: 9.375em;
}

.global-perspectives-head {
  position: relative;
  z-index: 20;
  margin-bottom: 7.03125em;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.global-perspectives-head:after {
  content: "";
  display: table;
  clear: both;
}
.global-perspectives-head:before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  display: block;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #777;
  z-index: -1;
}

.global-perspectives-header {
  margin-top: 0.25em;
  margin-bottom: 0;
  flex-grow: 1;
  text-align: right;
}
.global-perspectives-header .section-header-title, .global-perspectives-header .lawyer-header-inject .lawyerHeader, .lawyer-header-inject .global-perspectives-header .lawyerHeader {
  background-color: #fff;
  padding-left: 0.625em;
}

.global-perspectives-nav {
  display: table;
}

.global-perspectives-nav-region {
  display: table-cell;
  padding-right: 2vw;
}
.global-perspectives-nav-region:last-child {
  padding-right: 0;
}
.global-perspectives-nav-region.is-selected .global-perspectives-btn {
  background-color: #5B5B5B;
}

.global-perspectives-nav-toggle {
  font-weight: 800;
  display: none;
  width: 100%;
  padding: 0.5em 1.375em;
  background-color: #E5B522;
  overflow: hidden;
  color: #3D3C3C;
  text-align: left;
}
.global-perspectives-nav-toggle:after {
  content: "\e900";
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.625em;
  right: 2.2em;
}

.global-perspectives-btn {
  font-weight: 800;
  display: block;
  min-width: 10em;
  padding: 0.5em 0.25em;
  background-color: #B58F1B;
  overflow: hidden;
  position: relative;
  color: #fff;
  text-align: center;
}
.global-perspectives-btn:hover:before {
  transform: skew(-25deg) translate(75%, -50%);
}
.global-perspectives-btn:before {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  width: 200%;
  height: 200%;
  position: absolute;
  right: 110%;
  top: 50%;
  background-color: #5B5B5B;
  transform: skew(-25deg) translate(-5%, -50%);
  transition-duration: 0.5s;
  z-index: 1;
}

.global-perspectives-btn-label {
  position: relative;
  z-index: 2;
}

.global-perspectives-regions {
  position: relative;
  z-index: 10;
}

@media screen and (max-width: 67.125em) {
  .global-perspectives-header {
    float: none;
    margin-bottom: 5em;
    text-align: center;
  }
  .global-perspectives-header .section-header-title, .global-perspectives-header .lawyer-header-inject .lawyerHeader, .lawyer-header-inject .global-perspectives-header .lawyerHeader {
    padding-left: 0;
  }
  .global-perspectives-header .section-header-title:before, .global-perspectives-header .lawyer-header-inject .lawyerHeader:before, .lawyer-header-inject .global-perspectives-header .lawyerHeader:before {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    content: "";
    display: block;
    right: 100%;
    margin-right: 1em;
    width: 62.5em;
    height: 1px;
    background-color: #777;
  }
  .global-perspectives-head {
    display: block;
  }
  .global-perspectives-head:before {
    display: none;
  }
  .global-perspectives-nav {
    width: 100%;
  }
  .global-perspectives-nav-region {
    padding-right: 4%;
  }
}
@media screen and (max-width: 56.1875em) {
  .global-perspectives {
    margin-bottom: 5em;
  }
  .global-perspectives-head {
    margin-bottom: 3.125em;
  }
  .global-perspectives-header {
    margin-bottom: 2.5em;
  }
}
@media screen and (max-width: 47.4375em) {
  .global-perspectives-head {
    overflow: visible;
    margin-bottom: 2.5em;
  }
  .global-perspectives-header {
    margin-bottom: 2.5em;
  }
  .global-perspectives-menu {
    position: relative;
    z-index: 15;
  }
  .global-perspectives-btn,
  .global-perspectives-nav-toggle {
    display: block;
  }
  .global-perspectives-nav {
    position: absolute;
    width: 100%;
    top: 100%;
    max-height: 0;
    opacity: 0;
    display: block;
    overflow: hidden;
    transition: max-height 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86), opacity 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.175s;
  }
  .show-regions-menu .global-perspectives-nav {
    max-height: 31.25em;
    opacity: 1;
  }
  .global-perspectives-nav-region {
    display: list-item;
    padding-right: 0;
  }
  .global-perspectives-btn {
    text-align: left;
    padding: 1em 1.375em;
    width: 100%;
  }
  .is-selected .global-perspectives-btn {
    display: none;
  }
}
.newsletter-carousel {
  transition: min-height 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  position: relative;
  padding-bottom: 9.375em;
  z-index: 16;
  margin-top: -120px;
}
.newsletter-carousel .svg-mask-container {
  height: 100%;
  width: 100%;
  padding-bottom: 0;
}
.newsletter-carousel .svg-mask-image {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.newsletter-carousel-wrapper {
  max-width: 100.3125em;
  padding-left: 1.875em;
  padding-right: 1.875em;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding-top: 2vw;
}
@media screen and (max-width: 56.1875em) {
  .newsletter-carousel-wrapper {
    padding-left: 1em;
    padding-right: 1em;
  }
}
.newsletter-carousel-wrapper:after {
  content: "";
  display: table;
  clear: both;
}

.newsletter-carousel-header {
  margin-right: 31%;
  margin-left: 29%;
  padding-left: 1.5625em;
}

.newsletter-carousel-issues-label {
  font-size: 1.5em;
  margin-bottom: 0.4166666667em;
}

.newsletter-carousel-issues-view-all {
  display: block;
  margin-top: 5em;
  min-width: auto;
}
.newsletter-carousel-issues-view-all:hover {
  color: #CC092F;
}
.newsletter-carousel-issues-view-all.no-results {
  margin-top: auto;
}

.newsletter-carousel-nav-item {
  padding: 0.9375em 0;
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transform: translateZ(0);
}
.newsletter-carousel-nav-item:hover {
  transform: translateZ(0) scale(1.075);
}
.newsletter-carousel-nav-item:hover a, .newsletter-carousel-nav-item.is-selected a {
  color: #9d9d9d;
}
.newsletter-carousel-nav-item + .newsletter-carousel-nav-item {
  border-top: 1px solid #CC092F;
}

.newsletter-carousel-btn {
  font-size: 1.125em;
  color: #fff;
}

.newsletter-carousel-belt {
  transition: height 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  margin-right: 31%;
  padding-left: 29%;
}

@media screen and (max-width: 67.125em) {
  .newsletter-carousel-issues-view-all {
    margin-top: 5em;
  }
}
@media screen and (max-width: 62.4375em) {
  .newsletter-carousel {
    padding-bottom: 0;
    margin-bottom: 9.375em;
  }
  .newsletter-carousel:before {
    height: 10vw;
  }
  .newsletter-carousel-wrapper {
    padding-left: 0;
    padding-right: 0;
  }
  .newsletter-carousel-belt {
    margin-top: -50%;
    padding-left: 0;
    margin-right: 0;
    width: 50%;
  }
  .newsletter-carousel-issues {
    width: 42%;
    margin-top: -60%;
    padding-left: 5.5%;
    padding-right: 2.5%;
  }
  .newsletter-carousel-issues:after {
    width: 42%;
    height: 110%;
  }
  .newsletter-carousel-header {
    width: 50%;
    margin-right: 0;
    margin-left: 0;
    padding-top: 58%;
    pointer-events: none;
  }
  .newsletter-carousel-header .section-header-title, .newsletter-carousel-header .lawyer-header-inject .lawyerHeader, .lawyer-header-inject .newsletter-carousel-header .lawyerHeader {
    position: absolute;
    top: 50px;
  }
}
@media screen and (max-width: 56.1875em) {
  .newsletter-carousel {
    margin-top: -100px;
  }
  .newsletter-carousel-issues-label {
    font-size: 1.25em;
  }
  .newsletter-carousel-btn {
    font-size: 1em;
  }
}
@media screen and (max-width: 47.4375em) {
  .newsletter-carousel {
    overflow: hidden;
    padding-top: 10%;
    margin-bottom: 5em;
    margin-top: -25vw;
  }
  .newsletter-carousel:before {
    display: none;
  }
  .newsletter-carousel-wrapper {
    padding: 0 1em 8.75em;
  }
  .newsletter-carousel-header {
    padding-left: 0;
    padding-top: 0;
    width: auto;
    margin-right: auto;
    margin-bottom: 4em;
    overflow: visible;
  }
  .newsletter-carousel-header .section-header-title, .newsletter-carousel-header .lawyer-header-inject .lawyerHeader, .lawyer-header-inject .newsletter-carousel-header .lawyerHeader {
    position: static;
    color: #fff;
    font-weight: normal;
    display: block;
    text-align: center;
  }
  .newsletter-carousel-header .section-header-title:after, .newsletter-carousel-header .lawyer-header-inject .lawyerHeader:after, .lawyer-header-inject .newsletter-carousel-header .lawyerHeader:after {
    width: 1px;
    height: 50px;
    background-color: #A40F2C;
    position: absolute;
    left: 50%;
    top: 20px;
    margin-left: 0;
  }
  .newsletter-carousel-issues {
    float: none;
    width: auto;
    padding: 0;
    position: static;
    text-align: center;
    margin-top: 0;
  }
  .newsletter-carousel-issues:after {
    display: none;
  }
  .newsletter-carousel-issues-label {
    display: none;
  }
  .newsletter-carousel-nav {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    max-width: 75%;
    bottom: 5em;
    z-index: 5;
  }
  .newsletter-carousel-nav-item {
    display: inline;
    margin-left: 0.5em;
    margin-right: 0.5em;
    text-align: left;
  }
  .newsletter-carousel-nav-item + .newsletter-carousel-nav-item {
    margin-left: 0.5em;
    border-top: 0 none;
  }
  .newsletter-carousel-btn {
    display: inline-block;
    overflow: hidden;
    text-indent: 200%;
    width: 0.9375em;
    height: 0.9375em;
    border: 1px solid #CC092F;
    border-radius: 0.46875em;
  }
  .is-selected .newsletter-carousel-btn {
    background-color: #CC092F;
  }
  .newsletter-carousel-issues-view-all {
    position: absolute;
    left: 1em;
    right: 1em;
    bottom: 0;
    display: inline-block;
    margin-top: 0;
    color: #CC092F;
    border-color: #CC092F;
  }
  .newsletter-carousel-belt {
    margin-top: auto;
    width: auto;
  }
}
.edge--top {
  position: relative;
  z-index: 1;
}
.edge--top:before {
  background: inherit;
  content: "";
  display: block;
  height: 50%;
  left: 0;
  position: absolute;
  right: 0;
  z-index: -1;
}
.edge--top:before {
  top: 0;
  transform: skewY(6deg);
  transform-origin: 100% 0;
}
@media screen and (max-width: 47.4375em) {
  .edge--top {
    position: static;
  }
  .edge--top:before {
    display: none;
  }
}

.highlevel-view {
  max-width: 100.3125em;
  padding-left: 1.875em;
  padding-right: 1.875em;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5em;
  padding-bottom: 25%;
  position: relative;
}
@media screen and (max-width: 56.1875em) {
  .highlevel-view {
    padding-left: 1em;
    padding-right: 1em;
  }
}

.highlevel-view-wrapper {
  max-width: 63.75em;
  padding-left: 1.875em;
  padding-right: 1.875em;
  margin-left: auto;
  margin-right: auto;
  padding-top: 5em;
  text-align: center;
}
@media screen and (max-width: 56.1875em) {
  .highlevel-view-wrapper {
    padding-left: 1em;
    padding-right: 1em;
  }
}

.highlevel-view-title {
  font-weight: 300;
  font-size: 2.25em;
  color: #CC092F;
}
.highlevel-view-title:hover {
  color: #5B5B5B;
}

.highlevel-view-abstract {
  margin-top: 1.25em;
}

.highlevel-view-download {
  margin: 5em auto;
}

.highlevel-view-video {
  margin: 5em auto;
}
.highlevel-view-video .btn-ghost-label:after {
  content: "\e90e";
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-left: 0.5em;
  margin-top: 0.1em;
}

.highlevel-view-parallax {
  position: absolute;
  width: 100%;
  height: 100vh;
  left: 0;
}

.highlevel-view-parallax-frame {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  z-index: -1;
  transform: translate(-50%, -110%);
  overflow: hidden;
}

.highlevel-view-parallax-img {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  max-width: 105%;
}

@media screen and (max-width: 56.1875em) {
  .highlevel-view-title {
    font-size: 24px;
  }
  .highlevel-view-wrapper {
    padding-top: 0;
  }
}
@media screen and (max-width: 47.4375em) {
  .highlevel-view {
    padding-bottom: 20%;
  }
  .highlevel-view-bg {
    transform: translate(-50%, -107.5%);
  }
}
.hero-intro {
  height: 47.5em;
  color: #fff;
  position: relative;
  z-index: 15;
  max-width: 1800px;
  margin: 0 auto 5em;
}

.hero-intro-bg, .hero-intro-bg-mobile {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  width: 100%;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  z-index: 1;
  max-width: 1800px;
}

.hero-intro-bg-mobile {
  display: none;
}

.hero-intro-header {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 1.25em;
  display: block;
  text-transform: uppercase;
}

.hero-intro-title {
  font-size: 2.5em;
  font-weight: 300;
  margin-bottom: 0.9em;
}

.hero-intro-message {
  position: relative;
  z-index: 5;
  width: 50%;
}

.hero-intro-logo {
  transition: z-index 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  position: relative;
  text-align: right;
  z-index: 500;
  transition-delay: 0.35s;
}
.show-site-nav ~ .main-content .hero-intro-logo {
  z-index: 55;
  transition: none;
}
.hero-intro-logo .svg-logo-link {
  position: absolute;
  top: 2.3125em;
  right: 0.875em;
  display: block;
  width: 16.5625em;
  height: 3.75em;
}
.hero-intro-logo .logo-img, .hero-intro-logo .logo-img-mobile {
  width: 100%;
}
.hero-intro-logo .logo-img-mobile {
  display: none;
}
.hero-intro-logo .svg-logo {
  width: 100%;
}
.hero-intro-logo .svg-logo path {
  fill: #fff;
}

.hero-intro-message {
  position: absolute;
  left: 105px;
  width: calc(50% - 160px);
  transform: translateY(12.5em);
  text-align: left;
  z-index: 5;
}
.hero-intro-message .hero-intro-video {
  opacity: 1;
}

.hero-intro-tagline {
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  position: absolute;
  top: 5.8125em;
  right: 1.875em;
  display: block;
  color: white;
}
.hero-intro-tagline .word {
  position: relative;
  line-height: 1;
  perspective: 1000px;
}
.hero-intro-tagline .word-break:after {
  content: " ";
}
.hero-intro-tagline .word-break ~ .word-break {
  display: block;
}
.hero-intro-tagline .word-break ~ .word-break:after {
  content: "";
}
.hero-intro-tagline .word-break ~ .word-break ~ .word-break {
  display: inline;
}
.hero-intro-tagline .word-break ~ .word-break ~ .word-break:after {
  content: " ";
}
.hero-intro-tagline .letter {
  position: relative;
  display: inline-block;
  opacity: 0;
  transform-origin: 0% 50%;
  transform: rotateY(45deg);
  transition: opacity 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86), transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.hero-intro-tagline .letter:nth-child(38) {
  transition-delay: 1900ms;
}
.hero-intro-tagline .letter:nth-child(37) {
  transition-delay: 1850ms;
}
.hero-intro-tagline .letter:nth-child(36) {
  transition-delay: 1800ms;
}
.hero-intro-tagline .letter:nth-child(35) {
  transition-delay: 1750ms;
}
.hero-intro-tagline .letter:nth-child(34) {
  transition-delay: 1700ms;
}
.hero-intro-tagline .letter:nth-child(33) {
  transition-delay: 1650ms;
}
.hero-intro-tagline .letter:nth-child(32) {
  transition-delay: 1600ms;
}
.hero-intro-tagline .letter:nth-child(31) {
  transition-delay: 1550ms;
}
.hero-intro-tagline .letter:nth-child(30) {
  transition-delay: 1500ms;
}
.hero-intro-tagline .letter:nth-child(29) {
  transition-delay: 1450ms;
}
.hero-intro-tagline .letter:nth-child(28) {
  transition-delay: 1400ms;
}
.hero-intro-tagline .letter:nth-child(27) {
  transition-delay: 1350ms;
}
.hero-intro-tagline .letter:nth-child(26) {
  transition-delay: 1300ms;
}
.hero-intro-tagline .letter:nth-child(25) {
  transition-delay: 1250ms;
}
.hero-intro-tagline .letter:nth-child(24) {
  transition-delay: 1200ms;
}
.hero-intro-tagline .letter:nth-child(23) {
  transition-delay: 1150ms;
}
.hero-intro-tagline .letter:nth-child(22) {
  transition-delay: 1100ms;
}
.hero-intro-tagline .letter:nth-child(21) {
  transition-delay: 1050ms;
}
.hero-intro-tagline .letter:nth-child(20) {
  transition-delay: 1000ms;
}
.hero-intro-tagline .letter:nth-child(19) {
  transition-delay: 950ms;
}
.hero-intro-tagline .letter:nth-child(18) {
  transition-delay: 900ms;
}
.hero-intro-tagline .letter:nth-child(17) {
  transition-delay: 850ms;
}
.hero-intro-tagline .letter:nth-child(16) {
  transition-delay: 800ms;
}
.hero-intro-tagline .letter:nth-child(15) {
  transition-delay: 750ms;
}
.hero-intro-tagline .letter:nth-child(14) {
  transition-delay: 700ms;
}
.hero-intro-tagline .letter:nth-child(13) {
  transition-delay: 650ms;
}
.hero-intro-tagline .letter:nth-child(12) {
  transition-delay: 600ms;
}
.hero-intro-tagline .letter:nth-child(11) {
  transition-delay: 550ms;
}
.hero-intro-tagline .letter:nth-child(10) {
  transition-delay: 500ms;
}
.hero-intro-tagline .letter:nth-child(9) {
  transition-delay: 450ms;
}
.hero-intro-tagline .letter:nth-child(8) {
  transition-delay: 400ms;
}
.hero-intro-tagline .letter:nth-child(7) {
  transition-delay: 350ms;
}
.hero-intro-tagline .letter:nth-child(6) {
  transition-delay: 300ms;
}
.hero-intro-tagline .letter:nth-child(5) {
  transition-delay: 250ms;
}
.hero-intro-tagline .letter:nth-child(4) {
  transition-delay: 200ms;
}
.hero-intro-tagline .letter:nth-child(3) {
  transition-delay: 150ms;
}
.hero-intro-tagline .letter:nth-child(2) {
  transition-delay: 100ms;
}
.hero-intro-tagline .letter:nth-child(1) {
  transition-delay: 50ms;
}
.hero-intro-tagline.animate-in .letter {
  opacity: 1;
}

html[lang=es] .hero-intro-tagline .letter {
  transition: opacity 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86), transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
html[lang=es] .hero-intro-tagline .letter:nth-child(45) {
  transition-delay: 2250ms;
}
html[lang=es] .hero-intro-tagline .letter:nth-child(44) {
  transition-delay: 2200ms;
}
html[lang=es] .hero-intro-tagline .letter:nth-child(43) {
  transition-delay: 2150ms;
}
html[lang=es] .hero-intro-tagline .letter:nth-child(42) {
  transition-delay: 2100ms;
}
html[lang=es] .hero-intro-tagline .letter:nth-child(41) {
  transition-delay: 2050ms;
}
html[lang=es] .hero-intro-tagline .letter:nth-child(40) {
  transition-delay: 2000ms;
}
html[lang=es] .hero-intro-tagline .letter:nth-child(39) {
  transition-delay: 1950ms;
}
html[lang=es] .hero-intro-tagline .letter:nth-child(38) {
  transition-delay: 1900ms;
}
html[lang=es] .hero-intro-tagline .letter:nth-child(37) {
  transition-delay: 1850ms;
}
html[lang=es] .hero-intro-tagline .letter:nth-child(36) {
  transition-delay: 1800ms;
}
html[lang=es] .hero-intro-tagline .letter:nth-child(35) {
  transition-delay: 1750ms;
}
html[lang=es] .hero-intro-tagline .letter:nth-child(34) {
  transition-delay: 1700ms;
}
html[lang=es] .hero-intro-tagline .letter:nth-child(33) {
  transition-delay: 1650ms;
}
html[lang=es] .hero-intro-tagline .letter:nth-child(32) {
  transition-delay: 1600ms;
}
html[lang=es] .hero-intro-tagline .letter:nth-child(31) {
  transition-delay: 1550ms;
}
html[lang=es] .hero-intro-tagline .letter:nth-child(30) {
  transition-delay: 1500ms;
}
html[lang=es] .hero-intro-tagline .letter:nth-child(29) {
  transition-delay: 1450ms;
}
html[lang=es] .hero-intro-tagline .letter:nth-child(28) {
  transition-delay: 1400ms;
}
html[lang=es] .hero-intro-tagline .letter:nth-child(27) {
  transition-delay: 1350ms;
}
html[lang=es] .hero-intro-tagline .letter:nth-child(26) {
  transition-delay: 1300ms;
}
html[lang=es] .hero-intro-tagline .letter:nth-child(25) {
  transition-delay: 1250ms;
}
html[lang=es] .hero-intro-tagline .letter:nth-child(24) {
  transition-delay: 1200ms;
}
html[lang=es] .hero-intro-tagline .letter:nth-child(23) {
  transition-delay: 1150ms;
}
html[lang=es] .hero-intro-tagline .letter:nth-child(22) {
  transition-delay: 1100ms;
}
html[lang=es] .hero-intro-tagline .letter:nth-child(21) {
  transition-delay: 1050ms;
}
html[lang=es] .hero-intro-tagline .letter:nth-child(20) {
  transition-delay: 1000ms;
}
html[lang=es] .hero-intro-tagline .letter:nth-child(19) {
  transition-delay: 950ms;
}
html[lang=es] .hero-intro-tagline .letter:nth-child(18) {
  transition-delay: 900ms;
}
html[lang=es] .hero-intro-tagline .letter:nth-child(17) {
  transition-delay: 850ms;
}
html[lang=es] .hero-intro-tagline .letter:nth-child(16) {
  transition-delay: 800ms;
}
html[lang=es] .hero-intro-tagline .letter:nth-child(15) {
  transition-delay: 750ms;
}
html[lang=es] .hero-intro-tagline .letter:nth-child(14) {
  transition-delay: 700ms;
}
html[lang=es] .hero-intro-tagline .letter:nth-child(13) {
  transition-delay: 650ms;
}
html[lang=es] .hero-intro-tagline .letter:nth-child(12) {
  transition-delay: 600ms;
}
html[lang=es] .hero-intro-tagline .letter:nth-child(11) {
  transition-delay: 550ms;
}
html[lang=es] .hero-intro-tagline .letter:nth-child(10) {
  transition-delay: 500ms;
}
html[lang=es] .hero-intro-tagline .letter:nth-child(9) {
  transition-delay: 450ms;
}
html[lang=es] .hero-intro-tagline .letter:nth-child(8) {
  transition-delay: 400ms;
}
html[lang=es] .hero-intro-tagline .letter:nth-child(7) {
  transition-delay: 350ms;
}
html[lang=es] .hero-intro-tagline .letter:nth-child(6) {
  transition-delay: 300ms;
}
html[lang=es] .hero-intro-tagline .letter:nth-child(5) {
  transition-delay: 250ms;
}
html[lang=es] .hero-intro-tagline .letter:nth-child(4) {
  transition-delay: 200ms;
}
html[lang=es] .hero-intro-tagline .letter:nth-child(3) {
  transition-delay: 150ms;
}
html[lang=es] .hero-intro-tagline .letter:nth-child(2) {
  transition-delay: 100ms;
}
html[lang=es] .hero-intro-tagline .letter:nth-child(1) {
  transition-delay: 50ms;
}

@media (max-width: 1200px) {
  .hero-intro-header {
    font-size: 0.875em;
  }
  .hero-intro-title {
    font-size: 2em;
    margin-bottom: 1.25em;
  }
}
@media screen and (max-width: 62.4375em) {
  .hero-intro {
    margin-bottom: 0.625em;
  }
  .hero-intro,
  .hero-intro-bg {
    height: 56.25em;
  }
  .hero-intro-bg {
    background-size: auto 100%;
  }
  .hero-intro-logo .svg-logo-link {
    width: 12.5em;
    height: 2.28125em;
  }
  .hero-intro-message {
    line-height: 1.1;
    transform: translateY(11.25em);
    left: 25px;
  }
}
@media screen and (max-width: 56.1875em) {
  .hero-intro {
    overflow: hidden;
  }
  .hero-intro-logo .svg-logo-link {
    width: 11em;
    height: 2.8125em;
    right: 0.5em;
  }
  .hero-intro-tagline {
    font-size: 0.625em;
    top: 7.8em;
  }
  .hero-intro-video {
    margin: 1.5625em auto;
  }
  .hero-intro,
  .hero-intro-bg {
    height: 32.8125em;
  }
  .hero-intro-header {
    font-size: 0.875em;
  }
  .hero-intro-title {
    font-size: 1.25em;
    margin-bottom: 1.6666666667em;
  }
  .hero-intro-message {
    line-height: 1.1;
    transform: translateY(11.25em);
    left: 25px;
    width: calc(50% - 60px);
  }
}
@media screen and (max-width: 47.4375em) {
  .hero-intro {
    margin-bottom: 0;
  }
  .hero-intro,
  .hero-intro-bg {
    height: 30em;
    background-position: center -15px;
    background-repeat: no-repeat;
  }
  .hero-intro-bg {
    display: none;
  }
  .hero-intro-bg-mobile {
    display: block;
  }
  .hero-intro-logo .logo-img {
    display: none;
  }
  .hero-intro-logo .logo-img-mobile {
    display: block;
  }
  .btn-ghost {
    display: block;
  }
  .hero-intro-tagline {
    font-size: 0.625em;
  }
  .hero-intro-tagline .word-break ~ .word-break ~ .word-break {
    display: block;
  }
  .hero-intro-logo .svg-logo-link {
    top: 1.875em;
    width: 8.75em;
    height: 1.5625em;
  }
  .hero-intro-header {
    font-size: 0.875em;
  }
  .hero-intro-title {
    font-size: 1.5em;
    margin-bottom: 1.6666666667em;
  }
  .hero-intro-message {
    line-height: 1.1;
    transform: translateY(6.25em);
    left: 15px;
    width: calc(100% - 30px);
  }
  .hero-intro-message .word-break {
    display: block;
  }
  .hero-intro-message br {
    display: none;
  }
}
.hero-intro-diversity {
  min-height: 75vh;
  color: #fff;
  position: relative;
  z-index: 15;
  padding-bottom: 21.875em;
}
@media screen and (min-width: 47.5em) {
  .hero-intro-diversity {
    min-height: inherit;
  }
}
.hero-intro-diversity--detail {
  height: auto;
  padding-top: 10em;
  padding-bottom: 3em;
  background-size: cover;
  background-position: left;
}
@media screen and (max-width: 56.1875em) {
  .hero-intro-diversity--detail {
    padding-top: 9em;
    padding-bottom: 7em;
    background-position: right;
  }
}
@media screen and (max-width: 47.4375em) {
  .hero-intro-diversity--detail {
    padding-top: 7em;
    padding-bottom: 6em;
    min-height: unset;
  }
}

.hero-intro-tagline-diversity {
  font-weight: 800;
  font-size: 1.5em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  padding-bottom: 0.8em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
@media screen and (min-width: 47.5em) {
  .hero-intro-tagline-diversity {
    font-weight: 800;
    font-size: 2em;
  }
}
@media screen and (min-width: 64em) {
  .hero-intro-tagline-diversity {
    font-weight: 800;
    font-size: 3.25em;
    padding-bottom: 0.5em;
  }
}
@media screen and (min-width: 75em) {
  .hero-intro-tagline-diversity {
    font-weight: 800;
    font-size: 4.5em;
    padding-bottom: 60px;
  }
}

.hero-intro-tagline-diversity--detail {
  font-weight: 800;
  font-size: 3.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  padding-bottom: 0.8em;
}

.hero-intro-tagline-diversity-light {
  font-weight: 300;
  font-size: 1.5em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  padding-bottom: 0.5em;
}
@media screen and (min-width: 47.5em) {
  .hero-intro-tagline-diversity-light {
    font-weight: 300;
    font-size: 2em;
  }
}
@media screen and (min-width: 64em) {
  .hero-intro-tagline-diversity-light {
    font-weight: 300;
    font-size: 3.25em;
    text-transform: uppercase;
    padding-bottom: 0.5em;
  }
}
@media screen and (min-width: 75em) {
  .hero-intro-tagline-diversity-light {
    font-weight: 300;
    font-size: 4.5em;
    text-transform: uppercase;
    padding-bottom: 0;
  }
}

.hero-intro-bg-diversity {
  position: absolute;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  z-index: 1;
  top: 0;
}
@media screen and (min-width: 64em) {
  .hero-intro-bg-diversity {
    background-attachment: fixed;
  }
}
.hero-intro-bg-diversity--detail {
  top: 0;
}

.hero-intro-message-diversity {
  position: relative;
  width: 100%;
  padding-top: 7em;
  text-align: center;
  z-index: 5;
}
@media screen and (min-width: 47.5em) {
  .hero-intro-message-diversity {
    padding-top: 9em;
  }
}
@media screen and (min-width: 64em) {
  .hero-intro-message-diversity {
    padding-top: 14em;
  }
}
@media screen and (min-width: 75em) {
  .hero-intro-message-diversity {
    padding-top: 16em;
  }
}

@media screen and (min-width: 39.375em) {
  .hero-intro-wrapper {
    width: 82%;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-width: 64em) {
  .hero-intro-wrapper {
    width: 75%;
  }
}

.hero-intro-detail-wrapper {
  position: relative;
  z-index: 2;
  text-align: center;
}

.hero-intro-subheader {
  font-weight: 400;
  font-size: 1em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  line-height: 22px;
}
@media screen and (min-width: 64em) {
  .hero-intro-subheader {
    font-weight: 400;
    font-size: 1.25em;
    line-height: 30px;
  }
}
@media screen and (min-width: 75em) {
  .hero-intro-subheader {
    font-weight: 400;
    font-size: 1.875em;
    line-height: 1.3em;
    width: 100%;
  }
}

.hero-intro-btn-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
@media screen and (min-width: 47.5em) {
  .hero-intro-btn-container {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
  }
}
@media screen and (min-width: 64em) {
  .hero-intro-btn-container {
    width: 65%;
    margin-left: auto;
    margin-right: auto;
  }
}

.industry-list {
  margin-bottom: 5em;
}
.industry-list .industry-item {
  margin-bottom: 1.25em;
}
.industry-list .industry-item.is-expanded .toggle:after {
  content: "-";
  line-height: 0.5;
}
.industry-list .industry-item.is-expanded .industry-item--children {
  max-height: 250em;
  opacity: 1;
  padding: 0.8333333333em 1.25em 0.8333333333em;
  transition: max-height 0.7s cubic-bezier(0.785, 0.135, 0.15, 0.86), transform 0.35s linear 250ms, padding-bottom 0.35s linear 250ms;
}
.industry-list .industry-item:last-child {
  margin-bottom: 0;
}
.industry-list .industry-item:last-child .industry-item--children {
  padding-bottom: 0;
}
.industry-list .industry-item:last-child .industry-list ul li {
  margin-bottom: 0;
}
.industry-list .industry-item--main {
  position: relative;
}
.industry-list .industry-item-header {
  display: table;
  width: 100%;
  height: 5em;
}
.industry-list .industry-item-header .industry-item-skew {
  background-color: #3D3C3C;
  border: 1px solid #3D3C3C;
}
.industry-list .industry-item-header:hover .industry-item-header-title {
  color: #3D3C3C;
}
.industry-list .industry-item-header:hover .industry-item-skew:after {
  right: 0;
}
.industry-list .industry-item-header-title {
  font-weight: 300;
  font-size: 1.5em;
  transition: color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  padding: 1.25em 1.4166666667em;
  padding-right: 4.1666666667em;
  display: table-cell;
  vertical-align: middle;
  color: #fff;
  z-index: 5;
}
.industry-list .industry-item-skew {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: block;
  transform: skewX(-8deg);
  z-index: -1;
  backface-visibility: hidden;
}
.industry-list .industry-item-skew:after {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  transition: right 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  background-color: #fff;
  right: 100%;
}
.industry-list .toggle {
  transition: color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  position: absolute;
  right: -1px;
  top: -1px;
  bottom: -1px;
  width: 80px;
  color: #fff;
  z-index: 2;
  overflow: visible;
}
.industry-list .toggle:after {
  content: "+";
  font-weight: 800;
  font-size: 4.5em;
  font-size: 72px;
  line-height: 1;
}
.industry-list .toggle .industry-item-skew {
  background-color: #CC092F;
  border: 1px solid #CC092F;
}
.industry-list .toggle:hover {
  color: #CC092F;
}
.industry-list .toggle:hover .industry-item-skew:after {
  right: 0;
}
.industry-list .industry-item--children {
  padding: 0 1.25em;
  position: relative;
  left: -6px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86) 250ms, transform 0.35s linear, padding-bottom 0.35s linear 250ms, padding-top 0.35s linear 250ms;
}
.industry-list .industry-item--children a {
  color: #CC092F;
}
.industry-list .industry-item--children a:hover {
  color: #5B5B5B;
}
.industry-list .industry-item--child {
  font-weight: 300;
  font-size: 1.25em;
}
.industry-list ul {
  padding-left: 18px;
  margin-top: 10px;
}
.industry-list ul li {
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: solid 1px #AEAEAE;
}
.industry-list ul ul {
  margin-top: 20px;
  margin-bottom: 30px;
}
.industry-list ul ul .industry-item--child {
  font-size: 18px;
}
.industry-list ul ul li {
  margin-bottom: 10px;
}
.industry-list.industry-list--reverse .industry-item-header:hover .industry-item-skew:after {
  left: 0;
  right: 0;
}
.industry-list.industry-list--reverse .industry-item-header-title {
  padding-right: 1.4166666667em;
  padding-left: 4.1666666667em;
}
.industry-list.industry-list--reverse .industry-item-skew {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: block;
  transform: skewX(-8deg);
  z-index: -1;
}
.industry-list.industry-list--reverse .industry-item-skew:after {
  transition: left 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  left: 100%;
  right: 0;
}
.industry-list.industry-list--reverse .toggle {
  right: auto;
  left: -1px;
}
.industry-list.industry-list--reverse .toggle:hover .industry-item-skew:after {
  right: 0;
  left: 0;
}
@media screen and (max-width: 56.1875em) {
  .industry-list .industry-item-header {
    height: 3.75em;
  }
  .industry-list .industry-item-header-title {
    font-weight: 300;
    font-size: 1.25em;
    padding: 0.7em 1.2em;
    padding-right: 3.75em;
  }
  .industry-list .toggle {
    width: 3.75em;
  }
  .industry-list .toggle:after {
    font-size: 45px;
  }
  .industry-list.industry-list--reverse .industry-item-header-title {
    padding-right: 1.2em;
    padding-left: 3.75em;
  }
}

.accordion-angled-node {
  margin-bottom: 1.375em;
  position: relative;
}
.accordion-angled-node.is-expanded .accordion-angled-toggle:after {
  content: "-";
  line-height: 0.5;
}

.accordion-angled-skew {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: block;
  transform: skewX(-8deg);
  z-index: -1;
  backface-visibility: hidden;
}
.accordion-angled-skew:after {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  transition: right 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  background-color: #fff;
  right: 100%;
}

.accordion-angled-header {
  position: relative;
}

.accordion-angled-title {
  display: table;
  width: 100%;
  height: 4.375em;
}
.accordion-angled-title .accordion-angled-skew {
  background-color: #3D3C3C;
  border: 1px solid #3D3C3C;
}
.accordion-angled-title:hover .accordion-angled-title-label {
  color: #3D3C3C;
}
.accordion-angled-title:hover .accordion-angled-skew:after {
  right: 0;
}

.accordion-angled-title-label {
  font-weight: 300;
  font-size: 1.25em;
  transition: color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  padding: 0.625em 1.5em;
  padding-right: 4.5em;
  display: table-cell;
  vertical-align: middle;
  color: #fff;
  z-index: 5;
}

.accordion-angled-toggle {
  transition: color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  position: absolute;
  right: -1px;
  top: -1px;
  bottom: -1px;
  width: 70px;
  color: #fff;
  z-index: 2;
  overflow: visible;
}
.accordion-angled-toggle:after {
  font-weight: 800;
  font-size: 3.125em;
  content: "+";
  font-size: 3.125rem;
  line-height: 1;
}
.accordion-angled-toggle .accordion-angled-skew {
  background-color: #CC092F;
  border: 1px solid #CC092F;
}
.accordion-angled-toggle:hover {
  color: #CC092F;
}
.accordion-angled-toggle:hover .accordion-angled-skew:after {
  right: 0;
}

.accordion-angled-node-content {
  transition: height 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  height: 0;
  overflow: hidden;
}
.accordion-angled-node-content[aria-hidden=false] {
  height: auto;
}
.accordion-angled-node-content:before, .accordion-angled-node-content:after {
  content: "";
  display: block;
  padding-top: 3em;
}
.accordion-angled-node-content:after {
  padding-top: 1.5em;
}
.accordion-angled-node-content a {
  font-weight: 700;
}
.accordion-angled-node-content .explore-more-cta {
  margin-top: 1.5625em;
}
.accordion-angled-node-content .additional-solutions {
  margin-top: 3.125em;
}
.accordion-angled-node-content.no-summary:before {
  content: "";
  display: block;
  padding-top: 1.5em;
}

@media screen and (max-width: 56.1875em) {
  .accordion-angled-title {
    height: 3.75em;
  }
  .accordion-angled-title-label {
    font-weight: 300;
    font-size: 1.125em;
    padding-left: 1.3333333333em;
    padding-right: 4.1666666667em;
  }
  .accordion-angled-toggle {
    width: 3.75em;
  }
  .accordion-angled-toggle:after {
    font-size: 2.8125em;
  }
}
.practices-list {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 5em;
}
.practices-list .practices-column {
  width: 50%;
  padding-right: 1.25em;
}
.practices-list .practices-column:last-child {
  padding: 0 0 0 1.25em;
}
.practices-list .practices-item {
  padding: 1.25em 0;
  border-bottom: solid 2px #AEAEAE;
}
.practices-list .practices-item.is-expanded .toggle:before {
  content: "-";
  line-height: 0.5;
}
.practices-list .practices-item.is-expanded .practices-item--children {
  margin-top: 10px;
  max-height: 125em;
  opacity: 1;
  transition: max-height 0.7s cubic-bezier(0.785, 0.135, 0.15, 0.86), opacity 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86) 250ms, transform 0.35s linear 250ms;
}
.practices-list .toggle {
  background-color: #CC092F;
  color: #fff;
  width: 35px;
  height: 35px;
  float: right;
}
.practices-list .toggle:before {
  font-weight: 300;
  font-size: 1.5em;
  content: "+";
  line-height: 32px;
}
.practices-list .practices-item--main {
  font-weight: 300;
  font-size: 1.5em;
  color: #CC092F;
}
.practices-list .practices-item--main .no-link {
  color: #3D3C3C;
}
.practices-list .practices-item--children {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86) 250ms, opacity 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86), transform 0.35s linear;
}
.practices-list .practices-item--child {
  color: #CC092F;
  font-weight: 300;
  font-size: 1.25em;
}
.practices-list ul {
  padding-left: 18px;
  margin-top: 10px;
}
.practices-list ul li {
  margin-bottom: 20px;
}
.practices-list ul ul {
  margin-top: 20px;
  margin-bottom: 30px;
}
.practices-list ul ul .practices-item--child {
  font-size: 18px;
}
.practices-list ul ul li {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: solid 1px #AEAEAE;
}
.practices-list a {
  color: #CC092F;
}
.practices-list a:hover {
  color: #5B5B5B;
}
@media screen and (max-width: 56.1875em) {
  .practices-list .practices-item--main {
    font-size: 1.125em;
  }
}
@media screen and (max-width: 39.3125em) {
  .practices-list .practices-column {
    width: 100%;
    padding-right: 0;
  }
  .practices-list .practices-column:last-child {
    padding-left: 0;
  }
}

.regional-business .rte {
  max-width: 800px;
  text-align: center;
  margin: 0 auto 3.125em;
}
.regional-business .section-header--right-aligned {
  width: 50%;
  margin-left: 50%;
}
.regional-business .regional-business-listing {
  clear: both;
  display: flex;
  flex-flow: row wrap;
  margin-top: 5em;
  min-height: 24vw;
}
.regional-business .business-item {
  width: 33.333%;
  padding: 5px 2%;
  position: relative;
}
.regional-business .business-item:nth-child(3n+2) {
  padding: 5px 1%;
}
.regional-business .business-item:nth-child(3n+3) {
  padding-right: 0;
}
.regional-business .business-item:nth-child(3n+1) {
  padding-left: 0;
}
.regional-business .business-item.has-clock .clock {
  top: 25%;
  transform: translateX(-50%);
}
.regional-business .business-item.has-clock .business-name {
  display: inline-block;
  width: 76%;
  text-align: center;
}
.regional-business img {
  position: relative;
}
.regional-business .business-name {
  font-weight: 800;
  font-size: 1.75em;
  position: absolute;
  bottom: 20%;
  left: 10%;
  right: 10%;
  color: #fff;
}
@media screen and (max-width: 56.1875em) {
  .regional-business .business-name {
    font-size: 1.375em;
  }
  .regional-business .business-name.has-clock .clock {
    top: 20%;
  }
}
@media screen and (max-width: 64em) {
  .regional-business {
    margin-top: 0;
  }
}
@media screen and (max-width: 47.4375em) {
  .regional-business .business-item {
    width: 50%;
    padding: 0 2%;
  }
  .regional-business .business-item:nth-child(even) {
    padding-right: 0;
    padding-left: 2%;
  }
  .regional-business .business-item:nth-child(odd) {
    padding-left: 0;
    padding-right: 2%;
  }
}
@media screen and (max-width: 39.3125em) {
  .regional-business {
    margin-top: 5em;
  }
  .regional-business .business-item {
    width: 100%;
    max-width: 18.75em;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
  }
  .regional-business .business-item:nth-child(even) {
    padding: 0;
  }
  .regional-business .business-item:nth-child(odd) {
    padding: 0;
  }
}

.regional-business-control {
  position: relative;
  display: flex;
  align-items: center;
}
.regional-business-control .section-header {
  margin-right: 1em;
  margin-bottom: 0;
  flex-shrink: 0;
  background: #fff;
}
.regional-business-control .tab-filters {
  flex-grow: 1;
}
.regional-business-control:before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  display: block;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #777;
  z-index: -1;
}

@media screen and (max-width: 67.125em) {
  .regional-business-control {
    display: block;
  }
  .regional-business-control:before {
    content: initial;
    display: none;
  }
  .regional-business-control .section-header {
    margin-right: 0;
    background-color: transparent;
    margin-bottom: 2.5em;
    text-align: center;
  }
  .regional-business-control .section-header-title:before, .regional-business-control .lawyer-header-inject .lawyerHeader:before, .lawyer-header-inject .regional-business-control .lawyerHeader:before {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    content: "";
    display: block;
    right: 100%;
    margin-right: 1em;
    width: 62.5em;
    height: 1px;
    background-color: #777;
  }
}
.recently-recognized-nodes {
  margin-top: 5em;
}

@media screen and (max-width: 56.1875em) {
  .recently-recognized-nodes {
    margin-top: 3.125em;
  }
}
.flexible-panel-overview {
  position: relative;
}
.flexible-panel-overview.flexible-panel-overview--image {
  margin-top: 13.75em;
}
.social-block + .grey-wedge-background .flexible-panel-overview {
  margin-top: 0;
}
.flexible-panel-overview .section-header {
  margin-right: 55%;
}

.flexible-panel-overview-content:after {
  content: "";
  display: table;
  clear: both;
}
.flexible-panel-overview-content .buttons {
  margin-top: 2.5em;
}

.flexible-panel-overview-copy {
  margin-right: 55%;
}

.flexible-panel-overview-highlight {
  width: 52%;
  float: right;
  margin-top: -7.5em;
  position: relative;
}
.flexible-panel-overview-highlight img {
  max-width: 100%;
}

@media screen and (max-width: 47.4375em) {
  .flexible-panel-overview.flexible-panel-overview--image {
    margin-top: 0;
  }
  .flexible-panel-overview .section-header {
    margin-right: 0;
  }
  .flexible-panel-overview-copy {
    margin-right: 0;
  }
  .flexible-panel-overview-highlight {
    float: none;
    margin: 0 auto;
    width: 70%;
    margin-bottom: 2.5em;
  }
  .flexible-panel-overview-content .buttons {
    text-align: center;
    margin-top: 2.5em;
  }
}
@media screen and (max-width: 26.1875em) {
  .flexible-panel-overview-highlight {
    width: auto;
    margin-left: -1.25em;
    margin-right: -1.25em;
  }
  .flexible-panel-overview-content .btn-ghost {
    display: block;
    width: 100%;
  }
  .flexible-panel-overview-content .btn-ghost + .btn-ghost {
    margin-top: 1.375em;
  }
}
.professional-results-listing {
  margin-bottom: 5em;
}

@media screen and (max-width: 56.1875em) {
  .professional-results-listing {
    margin-bottom: 5em;
  }
}
.search-entity-landing {
  position: relative;
}
.search-entity-landing .silent-wrapper {
  max-width: 63.75em;
  padding-left: 1.875em;
  padding-right: 1.875em;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 56.1875em) {
  .search-entity-landing .silent-wrapper {
    padding-left: 1em;
    padding-right: 1em;
  }
}

.search-entity-landing-promo .impactful-cta + .grey-wedge {
  margin-top: 6.875em;
}

.search-entity-landing-results {
  display: none;
  margin-bottom: 3.4375em;
}

@media screen and (max-width: 56.1875em) {
  .search-entity-landing-promo {
    margin-bottom: 2.5em;
  }
  .search-entity-landing-promo .impactful-cta + .grey-wedge {
    margin-top: 5em;
  }
  .search-entity-landing-results {
    display: none;
    margin-bottom: 2.5em;
  }
}
.search-landing-hero {
  background-size: cover;
  background-position: center -15px;
  min-height: 0;
  background-repeat: no-repeat;
  margin-bottom: 2.1875rem;
}
.search-landing-hero .btn-ghost--submit-search, .search-landing-hero .btn-ghost--submit, .search-landing-hero .btn-ghost--clear {
  color: #fff;
  border-color: #fff;
}
.search-landing-hero .btn-ghost--submit-search:hover, .search-landing-hero .btn-ghost--submit:hover, .search-landing-hero .btn-ghost--clear:hover {
  color: #3D3C3C;
}
.search-landing-hero .btn-ghost--submit-search:after, .search-landing-hero .btn-ghost--submit:after, .search-landing-hero .btn-ghost--clear:after {
  background-color: #fff;
}

.search-landing-hero-content {
  max-width: 65.625em;
  text-align: left;
  margin: 0 auto;
}

.search-landing-filters {
  position: relative;
}
.search-landing-filters .advanced-filters {
  width: calc(100% - 50px);
}

.search-landing-filters-submit {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 50px;
  height: 50px;
  padding: 0;
}
.search-landing-filters-submit--capabilities {
  position: relative;
  margin: auto 0 auto 5px;
}
.search-landing-filters-submit .btn-ghost-label {
  padding-left: 4.21875em;
}

@media screen and (max-width: 56.1875em) {
  .search-landing-filters {
    border-bottom: 2em solid transparent;
  }
  .search-landing-filters .advanced-filters {
    width: calc(100% - 2.8125em);
  }
  .search-landing-filters-submit {
    width: 3em;
    height: 3em;
  }
}
@media screen and (max-width: 47.4375em) {
  .search-landing-filters {
    border-bottom: 0 none;
  }
  .search-landing-filters .advanced-filters {
    width: 100%;
    display: block;
  }
  .search-landing-filters-submit {
    margin-top: 1.375em;
    float: none;
    min-width: 45px;
    width: 100%;
    height: auto;
    position: relative;
    right: auto;
    bottom: auto;
    padding: 0.75em 1em;
    text-indent: 0;
  }
  .search-landing-filters-submit .btn-ghost-label {
    padding-left: 0;
    display: inline;
  }
  .search-landing-filters-submit--capabilities {
    margin-left: 0;
  }
  .search-landing-filters-submit:before {
    position: relative;
    font-size: 1.125em;
    margin-right: 0.25em;
    display: inline-block;
    vertical-align: middle;
    top: auto;
    left: auto;
    transform: translate(0);
  }
}
.professional-header {
  line-height: 1.6;
  position: relative;
}
.professional-header .angled-hero-content-slender {
  z-index: 50;
  padding-bottom: 3.5vw;
}
.professional-header .social-links-item {
  margin-right: 1.5em;
}
.professional-header .social-links-icon {
  color: #fff;
  font-size: 1.5em;
  transition: transform 0.35s;
}
.professional-header .social-links-icon:hover {
  color: #E5B522;
  transform: scale(1.35);
}
.professional-header .social-links-icon.ico-print {
  font-size: 1.25em;
}
.professional-header .social-links-icon.ico-vcard {
  font-size: 1.125em;
}
.professional-header .angled-hero-slender-image {
  background-position: left top;
}
.professional-header .angled-hero-slender-image:before {
  display: none;
}

.professional-header-figure {
  float: left;
  width: 23.75em;
  margin-left: 5em;
}
.professional-header-figure-image, .professional-header-figure img {
  width: 100%;
}

.professional-header-info {
  margin-left: 33.75em;
}

.professional-header--small {
  margin-bottom: 5em;
  padding-bottom: 0;
}
.professional-header--small .professional-header-figure {
  width: 15em;
}
.professional-header--small .professional-header-info {
  margin-left: 25em;
}
.professional-header--small .angled-hero-content-slender:before,
.professional-header--small .angled-hero-slender-polygon {
  top: -3.75em;
}
@media screen and (max-width: 64em) {
  .professional-header--small {
    margin-bottom: 0;
  }
}

.professional-header-main {
  margin-bottom: 1.375em;
}

.professional-header-name {
  font-weight: 800;
  font-size: 3.125em;
  line-height: 1.1;
}

.professional-header-content {
  max-width: none;
  padding-left: 0;
  padding-right: 0;
  text-align: left;
  padding-bottom: 4em;
}
.professional-header-content:after {
  content: "";
  display: table;
  clear: both;
}
.professional-header-content.angled-hero-content {
  z-index: inherit;
}

.professional-header-contact {
  margin-bottom: 1.375em;
}

.professional-header-email {
  font-weight: 700;
  font-size: 1.125em;
  position: relative;
  transition: none;
  color: #fff;
  display: inline-block;
  margin-bottom: 5px;
}
.professional-header-email:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0%;
  bottom: -1px;
  left: 0;
  height: 1px;
  background-color: #E5B522;
  transition: 0.35s;
}
.professional-header-email:hover {
  color: #E5B522;
}
.professional-header-email:hover:before {
  width: 100%;
}
@media screen and (max-width: 47.4375em) {
  .professional-header-email:before {
    display: none;
  }
  .professional-header-email:hover:before {
    display: none;
  }
}
.professional-header-email::before {
  width: 100%;
  background-color: #fff;
}
.professional-header-email:hover::before {
  background-color: #E5B522;
}

.professional-header-offices {
  display: flex;
  flex-wrap: wrap;
}

.professional-header-office-item {
  font-size: 1.25em;
  flex-grow: 1;
  max-width: 13.25em;
  padding-right: 0.75em;
}

.professional-header-office {
  font-weight: 700;
  position: relative;
  transition: none;
  color: #fff;
  display: inline-block;
}
.professional-header-office:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0%;
  bottom: -1px;
  left: 0;
  height: 1px;
  background-color: #E5B522;
  transition: 0.35s;
}
.professional-header-office:hover {
  color: #E5B522;
}
.professional-header-office:hover:before {
  width: 100%;
}
@media screen and (max-width: 47.4375em) {
  .professional-header-office:before {
    display: none;
  }
  .professional-header-office:hover:before {
    display: none;
  }
}
.professional-header-office::before {
  width: 100%;
  background-color: #fff;
}
.professional-header-office:hover::before {
  background-color: #E5B522;
}

@media screen and (max-width: 67.125em) {
  .professional-header-figure {
    width: 40%;
    margin-left: 0;
  }
  .professional-header-info {
    margin-left: 40%;
    padding-left: 5em;
  }
  .professional-header--small .professional-header-info {
    margin-left: 15em;
  }
}
@media screen and (max-width: 56.1875em) {
  .professional-header .angled-hero-content-slender {
    padding-bottom: 8vw;
  }
  .professional-header .social-links-item {
    margin-right: 1.25em;
  }
  .professional-header .social-links-icon {
    font-size: 1.375em;
  }
  .professional-header .social-links-icon:hover {
    color: #fff;
  }
  .professional-header .social-links-icon.ico-print {
    font-size: 1.25em;
  }
  .professional-header .social-links-icon.ico-vcard {
    font-size: 1.125em;
  }
  .professional-header-figure {
    width: 36%;
  }
  .professional-header-info {
    margin-left: 36%;
    padding-left: 2.5em;
  }
  .professional-header--small .professional-header-figure {
    width: 36%;
  }
  .professional-header--small .professional-header-info {
    margin-left: 36%;
    padding-left: 2.5em;
  }
  .professional-header-name {
    font-size: 1.75em;
  }
  .professional-header-position {
    font-size: 1.375em;
    line-height: 1;
  }
  .professional-pronouns-position {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    font-size: 1em;
    line-height: 1;
  }
  .professional-header-email {
    font-size: 1em;
  }
  .professional-header-office-item {
    font-size: 1.125em;
  }
}
@media screen and (max-width: 47.4375em) {
  .professional-header-office-item {
    font-size: 1em;
  }
  .professional-header-name {
    font-size: 1.75em;
  }
}
@media screen and (max-width: 39.3125em) {
  .professional-header-info {
    margin-left: 0;
    padding-left: 0;
    text-align: center;
  }
  .professional-header-figure {
    margin: 0 auto 2.5em;
    width: 60%;
    float: none;
  }
  .professional-header--small .professional-header-info {
    margin-left: 0;
    padding-left: 0;
  }
  .professional-header--small .professional-header-figure {
    margin: 0 auto 2.5em;
    width: 60%;
  }
  .professional-header-main {
    margin-bottom: 0.625em;
  }
  .professional-header-contact {
    padding-top: 0;
  }
  .professional-header-offices {
    display: block;
  }
  .professional-header-office-item {
    max-width: none;
  }
  .professional-header-office-item + .professional-header-office-item {
    margin-top: 1.375em;
  }
}
.capabilities-compact {
  position: relative;
  padding: 0 14% 5em;
  color: #fff;
  position: relative;
  padding-top: 8%;
}
.capabilities-compact:before {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background: inherit;
  content: "";
  display: block;
  height: 50%;
  left: 50%;
  width: 101%;
  padding-bottom: 10%;
  position: absolute;
  z-index: 5;
  transform: translate(-50%, 0) skewY(-8deg);
}
.capabilities-compact:before {
  transform: translate(-50%, 0) skewY(-8deg);
  bottom: 101%;
}
.capabilities-compact:before {
  background: #fff;
  bottom: 100%;
  margin-bottom: 4.6875em;
  min-height: 9.375em;
}
.capabilities-compact:after {
  background-repeat: repeat-x;
  background-image: linear-gradient(0deg, #AE0828, #CC092F 100%);
  content: "";
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  bottom: 0;
  height: calc(100% + 9.375em);
  z-index: -1;
}
.capabilities-compact .section-header {
  margin-right: -10%;
}
.capabilities-compact .section-header-title:after, .capabilities-compact .lawyer-header-inject .lawyerHeader:after, .lawyer-header-inject .capabilities-compact .lawyerHeader:after {
  background-color: #fff;
}
.capabilities-compact .listing-capabilities-option {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  border-color: #E68497;
  transform: translateZ(0);
  padding: 0;
}
.capabilities-compact .listing-capabilities-option:hover {
  transform: translateZ(0) scale(1.075);
}
.capabilities-compact .listing-capabilities-option:last-child {
  border: 0 none;
}
.capabilities-compact .listing-capabilities-link {
  padding: 1.375em 0;
  display: inline-block;
  color: #fff;
}
.capabilities-compact .btn-text-load-more {
  display: none;
}

@media screen and (max-width: 64em) {
  .capabilities-compact {
    padding: 8% 8% 3.125em;
  }
}
@media screen and (max-width: 47.4375em) {
  .capabilities-compact {
    overflow: visible;
    padding: 12vw 1em;
    margin-top: 10%;
  }
  .capabilities-compact:before {
    content: none;
  }
  .capabilities-compact:after {
    top: 0;
    bottom: 0;
    transform: skewY(-6deg) translate3d(0, 0, 0);
    height: auto;
  }
  .capabilities-compact .section-header {
    margin-right: 0;
  }
  .capabilities-compact .listing-capabilities-option {
    transform: none;
  }
  .capabilities-compact .listing-capabilities-option:hover {
    transform: none;
  }
  .capabilities-compact .listing-capabilities-option:last-child {
    border: 0 none;
  }
  .capabilities-compact .listing-capabilities-option__read-more {
    display: none;
  }
  .capabilities-compact .btn-text-load-more {
    display: block;
    width: 100%;
    margin-top: 3.125em;
  }
}
.capabilities-results-listing .search-entity-landing-result {
  margin-bottom: 0;
}
.capabilities-results-listing .result {
  padding-bottom: 5em;
  border-bottom: 2px solid #979797;
}
.capabilities-results-listing .result + .result {
  padding-top: 5em;
}
.capabilities-results-listing .result-link {
  font-weight: 800;
  font-size: 2.25em;
  transition: color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  line-height: 1.1;
  color: #CC092F;
}
.capabilities-results-listing .result-link:hover {
  color: #5B5B5B;
}
@media screen and (max-width: 47.4375em) {
  .capabilities-results-listing .result-link {
    font-weight: 800;
    font-size: 1.75em;
  }
  .capabilities-results-listing .result {
    padding-bottom: 30px;
  }
  .capabilities-results-listing .result + .result {
    padding-top: 30px;
  }
}

.capability-header {
  line-height: 1.6;
}
.capability-header .capability-header-info {
  margin: 0 auto;
  max-width: 800px;
  text-align: center;
}
.capability-header .capability-header-info h1 {
  line-height: 1.4;
  padding-bottom: 1.875em;
}
@media screen and (max-width: 47.4375em) {
  .capability-header .capability-header-info h1 {
    padding-bottom: 1.25em;
  }
}
.capability-header .capability-header-main {
  margin-bottom: 1.375em;
}
.capability-header .capability-header-name {
  font-weight: 800;
  font-size: 3.125em;
  line-height: 1.1;
}
@media screen and (max-width: 47.4375em) {
  .capability-header .capability-header-name {
    font-weight: 800;
    font-size: 1.75em;
  }
}

.experience-results-listing {
  position: relative;
}
.experience-results-listing .silent-wrapper {
  max-width: 63.75em;
  padding-left: 1.875em;
  padding-right: 1.875em;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 56.1875em) {
  .experience-results-listing .silent-wrapper {
    padding-left: 1em;
    padding-right: 1em;
  }
}
.experience-results-listing .result {
  padding-bottom: 5em;
  border-bottom: 2px solid #979797;
  font-weight: 300;
  font-family: "Lora", serif;
  font-size: 1.25em;
  color: #3D3C3C;
  line-height: 1.4;
}
.experience-results-listing .result + .result {
  padding-top: 5em;
}

.related-experience {
  margin-bottom: 5em;
}

.related-experience-intro {
  margin-bottom: 1.375em;
}

.related-experience-dropdown {
  margin-bottom: 1.375em;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.related-experience-dropdown .dropdown-helper-label {
  vertical-align: middle;
  margin-right: 1em;
  flex-shrink: 1;
}
.related-experience-dropdown .dropdown {
  flex-grow: 1;
  flex-shrink: 1;
  z-index: 10;
}

@media screen and (max-width: 56.1875em) {
  .related-experience-dropdown {
    display: block;
  }
}
.related-capabilities .rte > * {
  background-color: transparent !important;
}

.related-capabilities .related-capabilities-intro {
  text-align: center;
}

.scrollspy {
  position: relative;
}
.scrollspy-detail .angled-hero {
  margin-bottom: 5em;
}
.scrollspy .scrollspy-nav-notice {
  font-weight: 700;
  font-size: 1em;
  display: inline-block;
  text-transform: uppercase;
  color: #fff;
}
@media screen and (max-width: 47.4375em) {
  .scrollspy .scrollspy-nav-notice {
    display: none;
  }
}
@media screen and (max-width: 64em) {
  .scrollspy .scrollspy-nav-notice {
    display: none;
  }
  .scrollspy-detail .angled-hero {
    margin-bottom: 1em;
  }
}

.scrollspy-wrapper {
  max-width: 100.3125em;
  padding-left: 1.875em;
  padding-right: 1.875em;
  margin-left: auto;
  margin-right: auto;
  padding-left: 7.5em;
}
@media screen and (max-width: 56.1875em) {
  .scrollspy-wrapper {
    padding-left: 1em;
    padding-right: 1em;
  }
}
.scrollspy-wrapper--topics {
  padding-left: 1.875em;
}
.scrollspy-wrapper + .scrollspy-wrapper {
  margin-top: 5em;
}

.scrollspy-sections .page-wrapper {
  padding-left: 7.5em;
}
.scrollspy-sections .page-wrapper--centered {
  padding-left: 1.875em;
}

.scrollspy-centered-modules .silent-wrapper {
  max-width: 63.75em;
  padding-left: 1.875em;
  padding-right: 1.875em;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
}
@media screen and (max-width: 56.1875em) {
  .scrollspy-centered-modules .silent-wrapper {
    padding-left: 1em;
    padding-right: 1em;
  }
}

@media screen and (max-width: 64em) {
  .scrollspy-nav-notice {
    display: none;
  }
}
@media screen and (max-width: 56.1875em) {
  .scrollspy-wrapper {
    padding-left: 4.625em;
  }
  .scrollspy-wrapper--topics {
    padding-left: inherit;
  }
  .scrollspy-wrapper + .scrollspy-wrapper {
    margin-top: 3.125em;
  }
  .scrollspy-sections .page-wrapper {
    padding-left: 4.625em;
  }
  .scrollspy-sections .page-wrapper--centered {
    padding-left: 1em;
  }
}
@media screen and (max-width: 47.4375em) {
  .scrollspy-wrapper {
    padding-left: 1em;
  }
  .scrollspy-nav-column {
    margin-top: 3.125em;
  }
  .scrollspy-sections .page-wrapper {
    padding-left: 1em;
  }
}
.accordions {
  margin-bottom: 5em;
  height: 100%;
}

.accordion-node {
  padding: 1.875em 0;
  border-bottom: 2px solid #979797;
}
.accordion-node:first-child {
  padding-top: 0;
}
.accordion-node .lawyerHeader {
  display: inline-block;
  font-weight: 800;
  position: relative;
  font-family: "Open Sans", sans-serif;
  font-size: 1.2em;
  line-height: 1.1;
  text-transform: uppercase;
}
.accordion-node .lawyerHeader:after {
  position: absolute;
  content: "";
  display: block;
  left: 100%;
  margin-left: 1em;
  width: 62.5em;
  top: 50%;
  margin-top: -1px;
  border-top: 1px solid #979797;
  transform: translateZ(0);
}

.accordion-node-title {
  font-size: 1em;
  color: #CC092F;
}
.accordion-node-title button {
  color: #CC092F;
}
.accordion-node-title button:hover {
  color: #3D3C3C;
}

.accordion-node-trigger {
  font-weight: 300;
  font-size: 1.5em;
  line-height: 1.3;
  position: relative;
  width: 100%;
  padding-right: 3.28125em;
  text-align: left;
}
.accordion-node-trigger:hover:before {
  background-color: #3D3C3C;
}
.accordion-node-trigger:before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: background-color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "+";
  font-weight: 400;
  background-color: #CC092F;
  color: #fff;
  text-align: center;
  font-size: 1.25em;
  width: 30px;
  height: 30px;
  line-height: 1;
  position: absolute;
  right: 0;
}
.is-expanded .accordion-node-trigger:before {
  content: "–";
  line-height: 0.8;
}

.accordion-node-content {
  transition: height 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  height: 0;
  overflow: hidden;
}
.accordion-node-content[aria-hidden=false] {
  height: auto;
}
.accordion-node-content:before {
  content: "";
  display: block;
  padding-top: 1.5em;
}
.accordion-node-content a {
  font-weight: bold;
}
.accordion-node-content .explore-more-cta {
  margin-top: 1.5625em;
}
.accordion-node-content .additional-solutions {
  margin-top: 3.125em;
}

.credentials-intro {
  margin-bottom: 5em;
}

@media screen and (max-width: 56.1875em) {
  .credentials-intro {
    margin-bottom: 2.5em;
  }
}
.areas-focus-intro {
  margin-bottom: 5em;
}

@media screen and (max-width: 56.1875em) {
  .areas-focus-intro {
    margin-bottom: 2.5em;
  }
}
.case-study-highlights {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: 5em;
}
.case-study-highlights .article-tile {
  width: 48%;
}
.case-study-highlights .article-tile.primary-tile {
  width: 100%;
  height: 625px;
  margin-bottom: 2.5em;
  font-size: 1.3333333333em;
}
@media screen and (max-width: 47.4375em) {
  .case-study-highlights .article-tile {
    width: 100%;
    margin-bottom: 1em;
  }
  .case-study-highlights .article-tile.primary-tile {
    height: auto;
    min-height: 48vw;
    margin-bottom: 1em;
  }
}

.perspectives-news-events {
  position: relative;
  color: #fff;
}
.perspectives-news-events .page-wrapper {
  position: relative;
  z-index: 10;
}

.perspectives-news-events-intro {
  text-align: center;
  max-width: 63.75em;
  margin: 0 auto 5em;
}

.perspectives-news-events-panels {
  margin-top: 1.5625em;
}

@media screen and (max-width: 64em) {
  .perspectives-news-events {
    padding: 20% 0;
  }
}
@media screen and (max-width: 47.4375em) {
  .perspectives-news-events {
    padding: 25% 0;
  }
}
.locations-landing-header {
  background-size: cover;
  background-position: center -15px;
  background-repeat: no-repeat;
  padding-bottom: 2.5em;
}
.locations-landing-header .btn-ghost--submit-search, .locations-landing-header .btn-ghost--submit, .locations-landing-header .btn-ghost--clear {
  color: #fff;
  border-color: #fff;
}
.locations-landing-header .btn-ghost--submit-search:hover, .locations-landing-header .btn-ghost--submit:hover, .locations-landing-header .btn-ghost--clear:hover {
  color: #3D3C3C;
}
.locations-landing-header .btn-ghost--submit-search:after, .locations-landing-header .btn-ghost--submit:after, .locations-landing-header .btn-ghost--clear:after {
  background-color: #fff;
}
.locations-landing-header .locations-landing-header-content {
  max-width: 65.625em;
  margin: 0 auto;
  z-index: 52;
  padding-bottom: 0;
  padding-top: 5.5em;
}
.locations-landing-header .angled-hero-slender-polygon {
  height: 34.375em;
}
@media screen and (max-width: 47.4375em) {
  .locations-landing-header .angled-hero-slender-polygon {
    height: 25em;
  }
  .locations-landing-header .locations-landing-header-content {
    max-width: 65.625em;
    margin: 0 auto;
    z-index: 52;
    padding-bottom: 0;
    padding-top: 2.6em;
  }
}

.overflow {
  overflow-y: auto;
}
.overflow--hidden {
  overflow-y: hidden;
}

.related-entity-panel {
  display: none;
}
.related-entity-panel.is-active-panel {
  display: block;
}
.related-entity-panel .btn-text-load-more {
  margin-top: 5em;
}

.related-entity-panel-tiles {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -0.46875em;
  margin-right: -0.46875em;
}
.related-entity-panel-tiles .article-tile {
  width: calc(50% - 0.9375em);
  margin-left: 0.46875em;
  margin-right: 0.46875em;
  margin-bottom: 0.9375em;
}
.related-entity-panel-tiles .article-tile-card {
  margin-bottom: 8rem;
  width: 19em;
}

@media screen and (max-width: 64em) {
  .related-entity-panel-tiles {
    display: block;
    margin-left: 0;
    margin-right: 0;
  }
  .related-entity-panel-tiles .article-tile {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}
.searched-criteria {
  margin-bottom: 2.1875rem;
}
.searched-criteria .applied-filters {
  padding-right: 1em;
}
.searched-criteria .silent-wrapper {
  max-width: 63.75em;
  padding-left: 1.875em;
  padding-right: 1.875em;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: nowrap;
  align-items: baseline;
}
@media screen and (max-width: 56.1875em) {
  .searched-criteria .silent-wrapper {
    padding-left: 1em;
    padding-right: 1em;
  }
}

.searched-criteria-results {
  flex-shrink: 0;
  text-align: center;
  padding-right: 1em;
  font-size: 1.25em;
  font-weight: 400;
}

.searched-criteria-clear {
  font-weight: 700;
  position: relative;
  transition: none;
  color: #CC092F;
  display: inline-block;
  flex-shrink: 0;
  color: #CC092F;
}
.searched-criteria-clear:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0%;
  bottom: -1px;
  left: 0;
  height: 1px;
  background-color: #5B5B5B;
  transition: 0.35s;
}
.searched-criteria-clear:hover {
  color: #5B5B5B;
}
.searched-criteria-clear:hover:before {
  width: 100%;
}
@media screen and (max-width: 47.4375em) {
  .searched-criteria-clear:before {
    display: none;
  }
  .searched-criteria-clear:hover:before {
    display: none;
  }
}

@media screen and (max-width: 56.1875em) {
  .searched-criteria-results {
    font-size: 1.125em;
  }
}
@media screen and (max-width: 39.3125em) {
  .searched-criteria .silent-wrapper {
    display: block;
  }
  .searched-criteria .applied-filters {
    margin-bottom: 0.625em;
  }
  .searched-criteria-results {
    display: inline-block;
    margin-bottom: 0.625em;
  }
}
.global-locations {
  width: 100vw;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 230px 0;
}
.global-locations .skew-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.global-locations .skew-container:before, .global-locations .skew-container:after {
  content: " ";
  position: absolute;
  z-index: 100;
  left: 0;
  height: 300px;
  background: #fff;
  width: 100%;
  transform: skewY(-6deg);
}
.global-locations .skew-container:before {
  top: 0.255;
  top: -230px;
}
.global-locations .skew-container:after {
  bottom: -230px;
}
.global-locations .skew-container .background-image {
  position: relative;
  z-index: 0;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
}
.global-locations .global-hero {
  position: relative;
  width: 90%;
  margin: 5% auto;
}
.global-locations .silent-wrapper {
  position: relative;
  background-color: rgba(255, 255, 255, 0.9);
  text-align: center;
  padding: 5em;
}
.global-locations .section-header {
  margin-bottom: 2.5em;
}
.global-locations .link-external {
  font-weight: 800;
  font-size: 2.25em;
  text-transform: uppercase;
  position: relative;
  transition: none;
  color: #CC092F;
  display: inline-block;
}
.global-locations .link-external:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0%;
  bottom: -1px;
  left: 0;
  height: 1px;
  background-color: #3D3C3C;
  transition: 0.35s;
}
.global-locations .link-external:hover {
  color: #3D3C3C;
}
.global-locations .link-external:hover:before {
  width: 100%;
}
@media screen and (max-width: 47.4375em) {
  .global-locations .link-external:before {
    display: none;
  }
  .global-locations .link-external:hover:before {
    display: none;
  }
}
.global-locations .link-external .icon-external_link {
  display: inline-block;
}
.global-locations .link-external .icon-external_link:before {
  content: "\e90b";
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media screen and (max-width: 47.4375em) {
  .global-locations .section-header .section-header-title, .global-locations .section-header .lawyer-header-inject .lawyerHeader, .lawyer-header-inject .global-locations .section-header .lawyerHeader {
    display: inline-block;
  }
  .global-locations .silent-wrapper {
    position: relative;
    background-color: rgba(255, 255, 255, 0.9);
    text-align: center;
    padding: 2.5em;
  }
  .global-locations .link-external {
    font-weight: 800;
    font-size: 1.625em;
  }
  .global-locations .link-external .icon-external_link:before {
    content: "\e90b";
    display: inline-block;
    font-family: "reedsmith";
    font-size: inherit;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.slider-carousel {
  overflow: hidden;
  width: 100%;
  position: relative;
  height: 270px;
}
.slider-carousel .slider-container {
  display: flex;
  position: relative;
}
.slider-carousel .carousel-slide {
  font-weight: 300;
  font-size: 1.25em;
  background-color: #3D3C3C;
  color: #fff;
  height: 210px;
  display: inline-block;
  margin-right: 20px;
  padding: 10px 20px;
}
.slider-carousel .carousel-slide span {
  color: #fff;
}
.slider-carousel .carousel-slide:hover {
  color: #9d9d9d;
}
.slider-carousel .carousel-dots {
  display: inline-block;
  height: 30px;
  margin-top: 30px;
  width: 100%;
  text-align: center;
}
.slider-carousel .slider-dot {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  margin-right: 10px;
  display: inline-block;
  border: solid 1px #CC092F;
  cursor: pointer;
}
.slider-carousel .slider-dot.active {
  background-color: #CC092F;
}
.slider-carousel .slider-dot:last-child {
  margin-right: 0;
}
@media screen and (max-width: 56.1875em) {
  .slider-carousel .carousel-slide {
    min-height: 20em;
  }
}
@media screen and (max-width: 47.4375em) {
  .slider-carousel .carousel-slide {
    min-height: 215px;
  }
}

.what-others-say {
  margin-bottom: 5em;
}

.location-map {
  width: 100%;
  height: 380px;
  padding-top: 1.875em;
  position: relative;
}
.location-map .map {
  height: 100%;
  text-align: left;
}
.location-map .location-image {
  width: 50%;
  display: none;
  background-size: cover;
  height: 380px;
  color: #fff;
  padding: 20px;
  position: relative;
}
.location-map .location-image:before {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  content: "";
  background-color: rgba(0, 0, 0, 0.6);
}
.location-map .location-image-info {
  position: relative;
  z-index: 1;
}
.location-map .nearest-label {
  font-weight: 800;
  font-size: 1.125em;
  text-transform: uppercase;
  display: block;
}
.location-map .city-name {
  font-weight: 800;
  font-size: 2.25em;
  position: relative;
  transition: none;
  color: #E5B522;
  display: inline-block;
  margin-top: 0.5555555556em;
  display: inline-block;
}
.location-map .city-name:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0%;
  bottom: -1px;
  left: 0;
  height: 1px;
  background-color: #fff;
  transition: 0.35s;
}
.location-map .city-name:hover {
  color: #fff;
}
.location-map .city-name:hover:before {
  width: 100%;
}
@media screen and (max-width: 47.4375em) {
  .location-map .city-name:before {
    display: none;
  }
  .location-map .city-name:hover:before {
    display: none;
  }
}
.location-map .addresses {
  margin-top: 1.25em;
}
.location-map .clear-map {
  margin-top: 1.25em;
}
.location-map .address-item {
  font-size: 1.25em;
  display: block;
}
.location-map .contact-info {
  margin-top: 2em;
  font-size: 1.25em;
}
.location-map .phone {
  display: block;
}
.location-map .phone:before {
  content: "T:";
  margin-right: 6px;
}
.location-map .fax {
  display: block;
}
.location-map .fax:before {
  content: "F:";
  margin-right: 6px;
}
.location-map .marker-info-window {
  color: #CC092F;
  font-weight: 700;
}
.location-map .marker-info-window:hover {
  text-decoration: underline;
}
.location-map .map-search {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 50%;
  background-color: #222;
  color: #E5B522;
  padding: 10px;
}
.location-map .map-search-container {
  position: relative;
  display: flex;
}
.location-map .search-input-container {
  position: relative;
  width: 60%;
}
.location-map .current-location {
  position: absolute;
  right: 20px;
  color: #CC092F;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.location-map .current-location:before {
  content: "\e908";
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.location-map .keyword-search-input {
  margin-right: 0.625em;
  width: 100%;
}
.location-map .get-directions {
  display: inline-block;
  border-color: #E5B522;
  color: #E5B522;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
  width: 40%;
  min-width: 30%;
}
.location-map .get-directions:hover {
  color: #222;
}
.location-map .get-directions:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.location-map .get-directions:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #E5B522;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.location-map.show-image, .location-map.location-detail {
  display: flex;
  width: 100%;
}
.location-map.show-image .location-image, .location-map.location-detail .location-image {
  display: block;
  height: 100%;
}
.location-map.show-image .map, .location-map.location-detail .map {
  width: 50%;
}
.location-map.location-detail {
  padding-top: 1.875em;
  height: 530px;
}
.location-map.location-detail .map {
  height: 100%;
}
.location-map.location-detail .location-image {
  position: relative;
}
.location-map.location-detail .clock {
  right: 20px;
  top: 20px;
  left: auto;
  transform: none;
}
.location-map.location-detail .city-name {
  color: #fff;
  margin: 0;
}
.location-map.location-detail .city-name:hover:before {
  display: none;
}
.location-map.location-detail .addresses {
  margin-top: 0;
}
.location-map.location-detail .contact-info {
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: 100%;
}
.location-map .skip-to {
  color: #000000;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
@media screen and (max-width: 47.4375em) {
  .location-map .location-image {
    font-size: 14px;
  }
  .location-map .clear-map {
    display: block;
  }
  .location-map .clear-map .btn-ghost {
    width: 100%;
  }
  .location-map.show-image {
    height: auto;
  }
  .location-map.show-image .map {
    display: none;
  }
  .location-map.show-image .map-search {
    display: none;
  }
  .location-map.show-image .location-image {
    width: 100%;
    height: 80vw;
    min-height: 340px;
  }
  .location-map.show-image .clock {
    display: none;
  }
}

.article-header {
  text-align: center;
}
.article-header .page-header {
  margin-bottom: 0;
}
.article-header .angled-hero-slender-content {
  max-width: 78.125em;
  margin: 0 auto;
}

.article-header-meta {
  font-size: 1.5em;
  margin-bottom: 1.0416666667em;
}

.article-header-date + .article-header-source:before {
  content: "|";
  display: inline-block;
  margin-left: 0.1em;
  margin-right: 0.25em;
}

.artice-header-source {
  position: relative;
  transition: none;
  color: #CC092F;
  display: inline-block;
}
.artice-header-source:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0%;
  bottom: -1px;
  left: 0;
  height: 1px;
  background-color: #5B5B5B;
  transition: 0.35s;
}
.artice-header-source:hover {
  color: #5B5B5B;
}
.artice-header-source:hover:before {
  width: 100%;
}
@media screen and (max-width: 47.4375em) {
  .artice-header-source:before {
    display: none;
  }
  .artice-header-source:hover:before {
    display: none;
  }
}

@media screen and (max-width: 56.1875em) {
  .article-header-meta {
    font-size: 1.25em;
    margin-bottom: 1.25em;
  }
}
.block-links {
  max-width: 100%;
  position: relative;
}
.block-links .block-link {
  display: block;
  position: relative;
  min-height: 350px;
}
.block-links .content {
  padding: 3%;
  position: absolute;
  top: 0;
  left: 0;
}
.block-links .title {
  color: #fff;
}

.general-header {
  text-align: center;
}
.general-header .page-header {
  margin-bottom: 0;
}
.general-header--error {
  margin-bottom: 4.375em;
}

.general-header-intro {
  max-width: 40em;
  margin: 1.5625em auto 0;
}

.general-header-cta {
  margin-top: 2.5em;
}
.general-header-cta .btn-ghost {
  min-width: 20em;
}

@media screen and (max-width: 56.1875em) {
  .general-header {
    text-align: center;
  }
  .general-header .page-header {
    margin-bottom: 0;
  }
  .general-header--error {
    margin-bottom: 1.875em;
  }
}
.related-professionals-intro {
  max-width: 50em;
  margin: 0 auto 5em;
}

.related-professionals-grid {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.9375em;
}
.related-professionals-grid .professional-card {
  margin-right: 0.9375em;
  width: 48%;
  flex-grow: 0;
}

.related-professionals-team-view {
  text-align: center;
  margin-top: 5em;
}

@media screen and (max-width: 56.1875em) {
  .related-professionals-grid {
    display: block;
    margin-right: auto;
  }
  .related-professionals-grid .professional-card {
    margin-right: auto;
    margin-left: auto;
    width: 90%;
  }
  .related-professionals-team-view {
    margin-top: 2.5em;
  }
}
@media screen and (max-width: 47.4375em) {
  .related-professionals-grid .professional-card {
    width: auto;
  }
}
.practice-leader-right {
  margin-top: 3.75em;
}

.practice-leaders-list .position {
  margin-bottom: 1em;
}

.leader-name-bright {
  color: #CC092F;
  font-weight: bold;
}

.awards-intro {
  margin-bottom: 5em;
}

@media screen and (max-width: 56.1875em) {
  .awards-intro {
    margin-bottom: 2.5em;
  }
}
.narrative-overview-actions {
  margin-top: 1.5625em;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.narrative-overview-actions .ico {
  padding-left: 0.75em;
  vertical-align: middle;
}
.narrative-overview-cta {
  width: 48%;
  min-width: auto;
  margin-top: 1.5625em;
}
.narrative-overview__columns {
  display: flex;
  justify-content: space-between;
}
.narrative-overview__column {
  margin-bottom: 2em;
}
.narrative-overview__column--main {
  width: 65%;
}
.narrative-overview__column--main .narrative-overview__button-container {
  text-align: left;
}
.narrative-overview__column--side {
  width: 25%;
}
.narrative-overview__column img {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  display: block;
}

@media screen and (max-width: 56.1875em) {
  .narrative-overview-actions {
    display: block;
    margin-top: 1.5625em;
  }
  .narrative-overview-cta {
    width: 80%;
    display: block;
    margin-left: 0;
    margin-right: 0;
  }
  .narrative-overview__columns {
    display: block;
  }
  .narrative-overview__column--main {
    width: 100%;
  }
  .narrative-overview__column--main .narrative-overview__button-container {
    text-align: center;
  }
  .narrative-overview__column--side {
    width: 100%;
  }
}
@media screen and (max-width: 47.4375em) {
  .narrative-overview-cta {
    margin-left: auto;
    margin-right: auto;
  }
}
.diversity__call-to-action-section {
  width: 100%;
  height: 70%;
  padding: 4.375em 10%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin-bottom: 5em;
  text-align: center;
}
.diversity__call-to-action-title {
  font-weight: 700;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  text-align: center;
  padding-bottom: 0.9375em;
}
.diversity__call-to-action-body {
  font-weight: 400;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  line-height: 24px;
  text-align: center;
  padding-bottom: 1.875em;
}
.diversity__call-to-action-container {
  display: block;
}
.diversity__call-to-action-container--hidden {
  display: none;
}
@media screen and (min-width: 47.5em) {
  .diversity__button {
    margin-bottom: 0;
  }
  .diversity__button--hero:first-child {
    margin-right: 15px;
  }
}
@media screen and (min-width: 67.1875em) {
  .diversity__call-to-action-section {
    padding: 8.125em 20%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
  }
  .diversity__call-to-action-title {
    font-weight: 700;
    font-size: 2.25em;
  }
  .diversity__call-to-action-body {
    font-weight: 400;
    font-size: 1.5em;
    line-height: 34px;
  }
}

.diversity__logo {
  position: absolute;
  right: 0.5em;
  top: 1.2em;
  width: 40%;
}
.diversity__orange {
  color: #E76D25;
}
.diversity__orange-background {
  border-color: #E76D25 !important;
}
.diversity__orange-background .is-selected {
  background: #E76D25 !important;
}
.diversity__red-background {
  border-color: #CC092F !important;
}
.diversity__red-background .is-selected {
  background: #CC092F !important;
}
.diversity__red {
  color: #CC092F;
}
.diversity__green {
  color: #1e9f48;
}
.diversity__green-background {
  border-color: #1e9f48 !important;
}
.diversity__green-background .is-selected {
  background: #1e9f48 !important;
}
.diversity__button {
  display: inline-block;
  font-weight: 700;
  font-size: 14px;
  width: 100%;
  padding: 0.9375em 1em;
  border-radius: 0;
  overflow: hidden;
  position: relative;
  text-align: center;
  color: #fff;
  transition: all ease-in-out 0.15s;
  cursor: pointer;
  margin-bottom: 0.625em;
}
.diversity__button:last-child {
  margin-bottom: 0;
}
.diversity__button:hover:before {
  transform: skew(-25deg) translate(75%, -50%);
}
.diversity__button:before {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  width: 200%;
  height: 200%;
  position: absolute;
  right: 110%;
  top: 50%;
  transform: skew(-25deg) translate(-5%, -50%);
  transition-duration: 0.5s;
  z-index: 1;
}
.diversity__button--blue {
  background-color: #0373ac;
  margin-bottom: 0;
}
.diversity__button--blue:before {
  background-color: #0494de;
}
.diversity__button--green {
  background-color: #1e9f48;
  margin-bottom: 0;
}
.diversity__button--green:before {
  background-color: #1ed148;
}
.diversity__button--hero {
  border: 1px solid #A5CF4D;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
  backface-visibility: hidden;
  transform: translateZ(0);
}
.diversity__button--hero:hover {
  color: #3D3C3C;
}
.diversity__button--hero:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.diversity__button--hero:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #A5CF4D;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.diversity__button--detail {
  width: auto;
  min-width: 20em;
}
.diversity__button--stats {
  margin-top: 20px;
}
.diversity__button--stats:first-of-type {
  margin-top: 40px;
}
.diversity__button--video-icon:after {
  content: "\e903";
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.625em;
  right: 1.5em;
}
.diversity__button--centered:only-child {
  margin: auto;
}
.diversity__carousel-backing {
  background-color: #ececec;
  width: 100%;
  height: 15.5em;
}
.diversity__quote-section {
  background-color: #ececec;
  width: 100%;
  padding: 4.375em 10%;
  overflow-x: hidden;
}
.diversity__quote-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.diversity__quote {
  font-weight: 400;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #3d3c3c;
  line-height: 24px;
  text-align: center;
  padding-bottom: 1.875em;
  opacity: 0;
  visibility: hidden;
  transition: opacity 2s, visibility 2s;
}
.diversity__quote--visible {
  opacity: 1;
  visibility: visible;
}
.diversity__source {
  font-weight: 400;
  font-size: 1em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #3d3c3c;
  font-style: italic;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 2s, visibility 2s;
}
.diversity__source--visible {
  opacity: 1;
  visibility: visible;
}
.diversity__quote-bar {
  display: block;
  height: 3px;
  width: 3.125em;
  margin-bottom: 1.875em;
}
.diversity__quote-bar:last-child {
  margin-top: 1.875em;
  margin-bottom: 0;
}
.diversity__quote-bar--green {
  background-color: #1e9f48;
}
.diversity__quote-bar--orange {
  background-color: #E76D25;
}
.diversity__quote-bar--top {
  transform: translateX(-30em);
  transition: transform 1s ease-in-out;
}
.diversity__quote-bar--bottom {
  transform: translateX(30em);
  transition: transform 1s ease-in-out;
}
.diversity__quote-bar--slide {
  transform: translateX(0);
}
.diversity__stat-section {
  background-color: #fff;
  width: 100%;
  padding: 4.375em 0;
  padding-top: 3em;
  overflow-x: hidden;
}
.diversity__stat-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  padding-bottom: 1.25em;
}
.diversity__stat-container:last-child {
  padding-bottom: 0;
}
.diversity__stat-lines {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.diversity__stat-line {
  height: 1px;
  background-color: #1e9f48;
  width: 60px;
  transform: translateX(-300px);
  transition: transform 1s ease-in-out;
}
.diversity__stat-line--visible {
  transform: translateX(0);
}
.diversity__stat-number {
  font-weight: 300;
  font-size: 3em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #1e9f48;
  padding-left: 30px;
}
.diversity__stat-tagline {
  font-weight: 400;
  font-size: 1em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #3d3c3c;
  line-height: 22px;
  padding-left: 30px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 2s, visibility 2s;
}
.diversity__stat-tagline--visible {
  opacity: 1;
  visibility: visible;
}
.diversity__stats-btn-container {
  display: flex;
  flex-direction: column;
  padding: 0 8%;
}
.diversity__affinity-groups-section {
  width: 100%;
  padding: 4.375em 5%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.diversity__affinity-groups-title {
  font-weight: 700;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  text-align: center;
  padding-bottom: 0.9375em;
}
.diversity__affinity-groups-subtitle {
  font-weight: 300;
  font-size: 2em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  text-align: center;
  padding-bottom: 0.9375em;
}
.diversity__affinity-groups-body {
  font-weight: 400;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  line-height: 24px;
  text-align: center;
  padding-bottom: 1.875em;
}
.diversity__affinity-groups-container {
  display: block;
  padding: 0 5%;
}
.diversity__affinity-groups-container--hidden {
  display: none;
}
.diversity__affinity-groups-btn {
  display: inline-block;
  font-weight: 700;
  font-size: 14px;
  width: 100%;
  padding: 0.9375em 1em;
  border-radius: 0;
  border: none;
  overflow: hidden;
  position: relative;
  text-align: center;
  background-color: #fff;
  color: #E76D25;
  transition: all ease-in-out 0.15s;
  cursor: pointer;
  margin-bottom: 0.625em;
}
.diversity__affinity-groups-btn:hover {
  color: #fff;
  border: none;
}
.diversity__affinity-groups-btn:hover:before {
  transform: skew(-25deg) translate(75%, -50%);
}
.diversity__affinity-groups-btn:before {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  width: 200%;
  height: 200%;
  position: absolute;
  right: 110%;
  top: 50%;
  background-color: #E76D25;
  transform: skew(-25deg) translate(-5%, -50%);
  transition-duration: 0.5s;
  border: none;
  z-index: 1;
}
.diversity__modal {
  z-index: 1;
  width: 100%;
  overflow: auto;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}
.diversity__modal--visible {
  opacity: 1;
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid #fff;
}
.diversity__modal__content {
  padding: 1.25em;
  padding-bottom: 1.875em;
}
.diversity__modal-contact {
  font-weight: 400;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  text-align: center;
}
.diversity__modal-contact--link {
  font-weight: 700;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #E76D25;
  transition: all ease-in-out 0.15s;
  padding-left: 0.3125em;
}
.diversity__modal-contact--link:hover {
  color: #A5CF4D;
}
.diversity__modal-contact--link:not(:last-child)::after {
  content: ",";
  padding-right: 0.5rem;
}
.diversity__modal__close {
  margin: 0 0 0.3125em 90%;
}
.diversity__modal__close__icon {
  width: 75%;
  height: 75%;
}
.diversity__modal-title {
  font-weight: 700;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  text-align: center;
  padding-bottom: 0.9375em;
}
.diversity__modal-body {
  font-weight: 400;
  font-size: 0.875em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  line-height: 24px;
  text-align: center;
  padding-bottom: 0.9375em;
}
.diversity__modal-contact {
  padding-bottom: 0.625em;
}
.diversity__modal-contact-title {
  font-weight: 400;
  font-size: 0.875em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  line-height: 20px;
  color: #fff;
  text-align: center;
}
.diversity__global-section {
  background-color: #fff;
  width: 100%;
  padding: 4.375em 10%;
}
.diversity__global-title {
  font-weight: 700;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #92338a;
  text-align: center;
  padding-bottom: 0.9375em;
}
.diversity__global-title--orange {
  color: #E76D25;
}
.diversity__connect-section {
  background-color: #ececec;
  width: 100%;
  padding: 1.875em 9%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
.diversity__connect-title {
  font-weight: 300;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #656565;
  padding-right: 0.9375em;
}
.diversity__connect-icon-link {
  padding-right: 0.5em;
  transition: opacity 0.2s ease-in-out;
}
.diversity__connect-icon-link:hover {
  opacity: 0.7;
}
.diversity__connect-icon-link:before {
  font-family: "reedsmith";
}
.diversity__connect-icon-link--orange:before {
  color: #E76D25;
}
.diversity__forward-thinking-title {
  font-weight: 700;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  text-align: center;
  padding-bottom: 0.9375em;
}
.diversity__forward-thinking-body {
  font-weight: 400;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  line-height: 24px;
  text-align: center;
  padding-bottom: 2.5em;
}
.diversity__grid {
  padding: 1.875em 0;
  background-color: #ececec;
}
.diversity__grid__container {
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}
.diversity__box {
  width: 100%;
  display: flex;
  padding: 1.5em;
}
.diversity__box__content {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  background: #fff;
  color: #E76D25;
  padding: 2em;
  width: 100%;
  border: 3px solid #E76D25;
  transition: all 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.diversity__box__content:hover {
  background: #E76D25;
  color: #fff;
}
.diversity__box__content:hover .diversity__box__body {
  color: #fff;
}
.diversity__box__content:hover .diversity__box__line {
  background: #fff;
}
.diversity__box__header {
  font-size: 2em;
  font-weight: 600;
  margin: 0;
}
.diversity__box__line {
  width: 100px;
  height: 3px;
  margin: 1em;
  background: #E76D25;
  transition: background 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.diversity__box__body {
  font-size: 0.8em;
  line-height: 1.6em;
  margin-top: auto;
  color: #3d3c3c;
  transition: color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
@media screen and (min-width: 47.5em) {
  .diversity__button {
    margin-bottom: 0;
  }
  .diversity__button--hero {
    margin: 0 0.9375em 0.9375em;
    width: calc(50% - 30px);
    flex-grow: 1;
  }
  .diversity__button--blue {
    width: 50%;
  }
  .diversity__button--blue:not(:last-child) {
    margin-right: 10px;
  }
  .diversity__button--green {
    width: 50%;
  }
  .diversity__button--green:not(:last-child) {
    margin-right: 10px;
  }
  .diversity__button--stats {
    margin-top: 40px;
  }
  .diversity__button--stats:first-of-type {
    margin-top: 40px;
  }
  .diversity__logo {
    right: 1.25em;
    top: 1.75em;
    width: 27%;
  }
  .diversity__carousel-backing {
    height: 16.5em;
  }
  .diversity__stats-btn-container {
    flex-direction: row;
  }
  .diversity__modal__content {
    padding: 1.25em 3.75em 4.375em;
  }
}
@media screen and (min-width: 64em) {
  .diversity__box {
    width: 50%;
    min-height: 400px;
  }
  .diversity__carousel-backing {
    height: 20em;
  }
  .diversity__quote-section {
    padding: 4.375em 13%;
  }
  .diversity__stat-line {
    width: 130px;
  }
  .diversity__stat-number {
    font-weight: 300;
    font-size: 4.0625em;
  }
  .diversity__stat-tagline {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .diversity__stats-btn-container {
    padding: 0 8%;
  }
  .diversity__affinity-groups-section {
    padding: 4.375em 13%;
  }
  .diversity__modal__content {
    padding: 1.25em 4.5em 4.375em;
  }
  .diversity__modal-title {
    padding-bottom: 1.875em;
  }
  .diversity__modal-body {
    padding-bottom: 1.875em;
  }
  .diversity__global-section {
    padding: 4.375em 13%;
  }
  .diversity__connect-section {
    padding: 1.875em 15%;
  }
  .diversity__forward-thinking-body {
    width: 80%;
    margin: 0 auto;
  }
}
@media screen and (min-width: 67.1875em) {
  .diversity__button--stats {
    margin-top: 4.375em;
  }
  .diversity__button--stats:first-of-type {
    margin-top: 4.375em;
  }
  .diversity__logo {
    right: 2.5em;
    top: 2em;
    width: 20%;
  }
  .diversity__carousel-backing {
    height: 17.5em;
  }
  .diversity__quote-section {
    padding: 5em 20%;
  }
  .diversity__quote-section--first {
    padding: 0 20% 5em;
  }
  .diversity__quote {
    font-weight: 400;
    font-size: 1.875em;
    line-height: 42px;
    padding-bottom: 30px;
    width: 100%;
  }
  .diversity__stat-section {
    padding: 5em 0;
  }
  .diversity__stat-line {
    width: 260px;
  }
  .diversity__stat-number {
    font-weight: 300;
    font-size: 7.625em;
  }
  .diversity__stat-tagline {
    font-weight: 400;
    font-size: 1.5em;
  }
  .diversity__stats-btn-container {
    padding: 0;
    margin: 0 20%;
    margin-right: 16%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .diversity__affinity-groups-section {
    padding: 5em 20%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
  }
  .diversity__affinity-groups-container {
    padding: 0;
  }
  .diversity__affinity-groups-title {
    font-weight: 700;
    font-size: 2.25em;
  }
  .diversity__affinity-groups-subtitle {
    font-weight: 300;
    font-size: 3.5em;
  }
  .diversity__affinity-groups-body {
    font-weight: 400;
    font-size: 1.5em;
    line-height: 34px;
  }
  .diversity__affinity-groups-btn-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  .diversity__affinity-groups-btn {
    width: 30%;
    margin: 0 12px 25px;
  }
  .diversity__modal-title {
    font-weight: 700;
    font-size: 2.25em;
  }
  .diversity__modal-body {
    font-weight: 400;
    font-size: 1.5em;
    line-height: 34px;
  }
  .diversity__modal__close {
    margin: 0 0 0.3125em 96%;
  }
  .diversity__modal-contact {
    font-size: 24px;
  }
  .diversity__modal-contact--link {
    font-size: 24px;
  }
  .diversity__modal-contact-title {
    font-weight: 400;
    font-size: 1.125em;
  }
  .diversity__global-section {
    padding: 6.125em 10%;
  }
  .diversity__global-title {
    font-weight: 700;
    font-size: 2.25em;
  }
  .diversity__connect-section {
    padding: 65px 20%;
  }
  .diversity__connect-title {
    font-weight: 300;
    font-size: 2.25em;
  }
  .diversity__connect-icon-link {
    font-size: 1.85em;
    padding-right: 1em;
  }
  .diversity__forward-thinking-title {
    font-weight: 700;
    font-size: 2.25em;
    text-align: left;
  }
  .diversity__forward-thinking-body {
    font-weight: 400;
    font-size: 1.5em;
    line-height: 34px;
    text-align: left;
    margin: 0;
    white-space: normal;
    width: 100%;
  }
}
@media screen and (max-width: 67.125em) {
  .diversity__forward-thinking-title {
    padding-top: 40px;
  }
}

.diversity-carousel {
  height: 30em;
  width: 90%;
  margin: 0 auto;
  position: absolute;
  z-index: 1;
  bottom: -11.25em;
  left: 50%;
  transform: translateX(-50%);
  -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
}
.diversity-carousel .hero-carousel__button {
  position: initial !important;
  right: 0 !important;
}
.diversity-carousel .hero-carousel__button {
  right: auto;
}
.diversity-carousel .flickity-page-dots {
  padding-top: 1rem;
  position: initial;
  width: auto;
}
.diversity-carousel .flickity-page-dots .is-selected.diversity__green {
  background: #1e9f48;
}
.diversity-carousel .flickity-page-dots .is-selected.diversity__orange {
  background: #E76D25;
}
.diversity-carousel .flickity-page-dots .is-selected.diversity__red {
  background: #CC092F;
}
.diversity-carousel__controls {
  bottom: 0.75rem;
  display: none;
  height: 2.75rem;
  position: absolute;
  right: 3.75em;
}
.diversity-carousel__controls .is-paused .svg-icon--play.svg-icon--play {
  display: inline-block;
}
.diversity-carousel__controls .is-active .svg-icon--play.svg-icon--play {
  display: none;
}
.diversity-carousel__controls .svg-icon {
  vertical-align: text-top;
}
.diversity-carousel__slide {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-position-x: 80%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.diversity-carousel__text-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 75%;
  background: transparent; /* For browsers that do not support gradients */
  background: linear-gradient(to top, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0));
  padding: 2.1875em;
}
.diversity-carousel__title {
  font-weight: 400;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  line-height: 28px;
  text-align: center;
}
.diversity-carousel__body {
  font-weight: 400;
  font-size: 1em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  line-height: 28px;
  width: 55%;
  display: none;
}
.diversity-carousel__button-ghost {
  display: inline-block;
  font-weight: 700;
  font-size: 14px;
  width: 100%;
  padding: 0.75em 1.5em;
  margin-top: 1.875em;
  border: 1px solid #1e9f48;
  border-radius: 0;
  overflow: hidden;
  position: relative;
  text-align: center;
  color: #1e9f48;
  background-color: #fff;
  backface-visibility: hidden;
  transform: translateZ(0);
}
.diversity-carousel__button-ghost:hover {
  color: #fff;
}
.diversity-carousel__button-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.diversity-carousel__button-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #1e9f48;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.diversity-carousel__button-ghost .btn-ghost-label--video:after {
  content: "\e90e";
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-left: 0.5em;
  margin-top: 0.1em;
}
.diversity-carousel__button-ghost--topics {
  border: 1px solid #CC092F;
  color: #CC092F;
}
.diversity-carousel__button-ghost--topics:after {
  background-color: #CC092F;
}
.diversity-carousel__slides-belt {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.diversity-carousel__mobile-btn-container {
  display: flex;
  flex-direction: row;
  position: relative;
}
.diversity-carousel__trigger-btn {
  background-color: #1e9f48;
  color: #fff;
  width: 33%;
  height: 3.4375em;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -webkit-border-radius: 0px;
  border-right: 1px solid #fff;
  transition: all ease-in-out 0.15s;
}
.diversity-carousel__trigger-btn .hidden {
  display: none;
}
.diversity-carousel__trigger-btn:last-child {
  border-right: 0;
}
.diversity-carousel__trigger-btn:focus {
  z-index: 2;
}
.diversity-carousel__trigger-btn.diversity-carousel__trigger-btn--pause {
  background-color: #fff !important;
}
.diversity-carousel__lg-trigger-btn {
  display: none;
  background-color: #1e9f48;
  color: #fff;
  width: 75px;
  height: 210px;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -webkit-border-radius: 0px;
  border: none;
  transition: all ease-in-out 0.15s;
  -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
}
.diversity-carousel__lg-trigger-btn:hover {
  background-color: #23bc55;
}
.diversity-carousel__lg-trigger-btn--topics {
  background-color: #CC092F;
}
.diversity-carousel__lg-trigger-btn--topics:hover {
  background-color: #f70938;
}
.diversity-carousel--topics {
  position: relative;
  transform: none;
  left: auto;
  bottom: 0;
  margin-bottom: 7.1875em;
}
@media screen and (min-width: 47.5em) {
  .diversity-carousel {
    bottom: -12.125em;
  }
  .diversity-carousel--topics {
    bottom: 1.5625em;
  }
}
@media screen and (min-width: 64em) {
  .diversity-carousel {
    width: 75%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .diversity-carousel__slides-belt {
    position: absolute;
  }
  .diversity-carousel__mobile-btn-container {
    display: none;
  }
  .diversity-carousel__controls {
    display: inline-flex;
  }
  .diversity-carousel__button-ghost {
    width: 12.8125em;
  }
  .diversity-carousel__text-container {
    height: 100%;
    justify-content: space-between;
    align-items: flex-start;
    padding: 3.75em;
    background: transparent;
  }
  .diversity-carousel__title-body {
    width: 60%;
  }
  .diversity-carousel__body {
    width: 100%;
  }
  .diversity-carousel__title {
    font-weight: 300;
    font-size: 2em;
    color: #3d3c3c;
    line-height: 42px;
    text-align: left;
    width: 90%;
    padding-bottom: 15px;
  }
  .diversity-carousel__body {
    display: block;
    color: #3d3c3c;
  }
  .diversity-carousel__lg-trigger-btn {
    display: block;
    position: relative;
  }
  .diversity-carousel__lg-trigger-btn--back {
    left: -4.4em;
    z-index: -1;
  }
  .diversity-carousel__lg-trigger-btn--forward {
    left: 4.5em;
    z-index: -1;
  }
}
@media screen and (min-width: 75em) {
  .diversity-carousel {
    width: 64%;
  }
}

.slide-enter-active {
  transition: 400ms;
}

.slide-enter.forward {
  transform: translateX(100px);
  opacity: 0;
}

.slide-enter.backward {
  transform: translateX(-100px);
  opacity: 0;
}

.diversity-global-carousel {
  height: 21.125em;
  width: 21.125em;
  right: 6%;
  position: relative;
  z-index: 1;
  margin-bottom: 4.6em;
}
.diversity-global-carousel__slide {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.diversity-global-carousel__container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-position-x: 80%;
  background-size: cover;
  background-repeat: no-repeat;
}
.diversity-global-carousel__text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
}
.diversity-global-carousel__title {
  font-weight: 700;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  line-height: 28px;
  text-align: center;
}
.diversity-global-carousel__mobile-btn-container {
  display: flex;
  flex-direction: row;
  position: relative;
  bottom: -22.25em;
}
.diversity-global-carousel__button-ghost {
  display: inline-block;
  font-weight: 700;
  font-size: 14px;
  width: 100%;
  padding: 0.75em 1.5em;
  margin-top: 1.875em;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 0;
  overflow: hidden;
  position: relative;
  text-align: center;
  color: #fff;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
}
.diversity-global-carousel__button-ghost:hover {
  color: #92338a;
}
.diversity-global-carousel__button-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.diversity-global-carousel__button-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #fff;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.diversity-global-carousel__button-ghost-orange {
  display: inline-block;
  font-weight: 700;
  font-size: 14px;
  width: 100%;
  padding: 0.75em 1.5em;
  margin-top: 1.875em;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 0;
  overflow: hidden;
  position: relative;
  text-align: center;
  color: #fff;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
}
.diversity-global-carousel__button-ghost-orange:hover {
  color: #E76D25;
}
.diversity-global-carousel__button-ghost-orange:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.diversity-global-carousel__button-ghost-orange:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #fff;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.diversity-global-carousel__trigger-btn {
  background-color: #ececec;
  color: #fff;
  width: 30%;
  height: 4.375em;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -webkit-border-radius: 0px;
  border-right: 1px solid #fff;
  transition: all ease-in-out 0.15s;
}
.diversity-global-carousel__trigger-btn:last-child {
  border-right: 0;
}
.diversity-global-carousel__lg-trigger-btn {
  background-color: #ececec;
  color: #fff;
  width: 33%;
  height: 5.625em;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -webkit-border-radius: 0px;
  border-right: 1px solid #fff;
  transition: all ease-in-out 0.15s;
  display: none;
}
.diversity-global-carousel__lg-trigger-btn:last-child {
  border-right: 0;
}
.diversity-global-carousel__lg-trigger-btn:hover {
  background-color: #AEAEAE;
}
@media screen and (min-width: 47.5em) {
  .diversity-global-carousel {
    margin: 0 auto;
    margin-bottom: 4.6em;
    right: 0;
  }
}
@media screen and (min-width: 64em) {
  .diversity-global-carousel {
    height: 25em;
    width: 25em;
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .diversity-global-carousel__mobile-btn-container {
    display: none;
  }
  .diversity-global-carousel__button-ghost {
    width: 12.8125em;
  }
  .diversity-global-carousel__lg-trigger-btn {
    display: block;
    position: relative;
  }
  .diversity-global-carousel__lg-trigger-btn--back {
    left: -9em;
  }
  .diversity-global-carousel__lg-trigger-btn--forward {
    left: 14.2em;
  }
}
@media screen and (min-width: 67.1875em) {
  .diversity-global-carousel {
    height: 30em;
    width: 30em;
  }
  .diversity-global-carousel__title {
    width: 100%;
  }
}

.winrs__call-to-action-section {
  width: 100%;
  height: 70%;
  padding: 4.375em 10%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin-bottom: 5em;
  text-align: center;
}
.winrs__call-to-action-title {
  font-weight: 700;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  text-align: center;
  padding-bottom: 0.9375em;
}
.winrs__call-to-action-body {
  font-weight: 400;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  line-height: 24px;
  text-align: center;
  padding-bottom: 1.875em;
}
.winrs__call-to-action-container {
  display: block;
}
.winrs__call-to-action-container--hidden {
  display: none;
}
@media screen and (min-width: 47.5em) {
  .winrs__button {
    margin-bottom: 0;
  }
  .winrs__button--hero:first-child {
    margin-right: 15px;
  }
}
@media screen and (min-width: 67.1875em) {
  .winrs__call-to-action-section {
    padding: 8.125em 20%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
  }
  .winrs__call-to-action-title {
    font-weight: 700;
    font-size: 2.25em;
  }
  .winrs__call-to-action-body {
    font-weight: 400;
    font-size: 1.5em;
    line-height: 34px;
  }
}

.winrs__logo {
  position: absolute;
  right: 0.5em;
  top: 1.2em;
  width: 40%;
}
.winrs__orange {
  color: #E76D25;
}
.winrs__orange-background {
  border-color: #E76D25 !important;
}
.winrs__orange-background .is-selected {
  background: #E76D25 !important;
}
.winrs__green {
  color: #1e9f48;
}
.winrs__green-background {
  border-color: #1e9f48 !important;
}
.winrs__green-background .is-selected {
  background: #1e9f48 !important;
}
.winrs__button {
  display: inline-block;
  font-weight: 700;
  font-size: 14px;
  width: 100%;
  padding: 0.9375em 1em;
  border-radius: 0;
  overflow: hidden;
  position: relative;
  text-align: center;
  color: #fff;
  transition: all ease-in-out 0.15s;
  cursor: pointer;
  margin-bottom: 0.625em;
}
.winrs__button:last-child {
  margin-bottom: 0;
}
.winrs__button:hover:before {
  transform: skew(-25deg) translate(75%, -50%);
}
.winrs__button:before {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  width: 200%;
  height: 200%;
  position: absolute;
  right: 110%;
  top: 50%;
  transform: skew(-25deg) translate(-5%, -50%);
  transition-duration: 0.5s;
  z-index: 1;
}
.winrs__button--blue {
  background-color: #0373ac;
  margin-bottom: 0;
}
.winrs__button--blue:before {
  background-color: #0494de;
}
.winrs__button--orange {
  background-color: #E76D25;
  margin-bottom: 0;
}
.winrs__button--orange:before {
  background-color: rgb(223, 179, 59);
}
.winrs__button--hero {
  border: 1px solid #A5CF4D;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
  backface-visibility: hidden;
  transform: translateZ(0);
}
.winrs__button--hero:hover {
  color: #3D3C3C;
}
.winrs__button--hero:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.winrs__button--hero:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #A5CF4D;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.winrs__button--detail {
  width: auto;
  min-width: 20em;
}
.winrs__button--stats {
  margin-top: 20px;
}
.winrs__button--stats:first-of-type {
  margin-top: 40px;
}
.winrs__button--video-icon:after {
  content: "\e903";
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.625em;
  right: 1.5em;
}
.winrs__button--centered:only-child {
  margin: auto;
}
.winrs__carousel-backing {
  background-color: #ececec;
  width: 100%;
  height: 15.5em;
}
.winrs__quote-section {
  background-color: #ececec;
  width: 100%;
  padding: 4.375em 10%;
  overflow-x: hidden;
}
.winrs__quote-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.winrs__quote {
  font-weight: 400;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #3d3c3c;
  line-height: 24px;
  text-align: center;
  padding-bottom: 1.875em;
  opacity: 0;
  visibility: hidden;
  transition: opacity 2s, visibility 2s;
}
.winrs__quote--visible {
  opacity: 1;
  visibility: visible;
}
.winrs__source {
  font-weight: 400;
  font-size: 1em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #3d3c3c;
  font-style: italic;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 2s, visibility 2s;
}
.winrs__source--visible {
  opacity: 1;
  visibility: visible;
}
.winrs__quote-bar {
  display: block;
  height: 3px;
  width: 3.125em;
  margin-bottom: 1.875em;
}
.winrs__quote-bar:last-child {
  margin-top: 1.875em;
  margin-bottom: 0;
}
.winrs__quote-bar--green {
  background-color: #1e9f48;
}
.winrs__quote-bar--orange {
  background-color: #E76D25;
}
.winrs__quote-bar--top {
  transform: translateX(-30em);
  transition: transform 1s ease-in-out;
}
.winrs__quote-bar--bottom {
  transform: translateX(30em);
  transition: transform 1s ease-in-out;
}
.winrs__quote-bar--slide {
  transform: translateX(0);
}
.winrs__stat-section {
  background-color: #fff;
  width: 100%;
  padding: 4.375em 0;
  padding-top: 3em;
  overflow-x: hidden;
}
.winrs__stat-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  padding-bottom: 1.25em;
}
.winrs__stat-container:last-child {
  padding-bottom: 0;
}
.winrs__stat-lines {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.winrs__stat-line {
  height: 1px;
  background-color: #E76D25;
  width: 60px;
  transform: translateX(-300px);
  transition: transform 1s ease-in-out;
}
.winrs__stat-line--visible {
  transform: translateX(0);
}
.winrs__stat-number {
  font-weight: 300;
  font-size: 3em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #E76D25;
  padding-left: 30px;
}
.winrs__stat-tagline {
  font-weight: 400;
  font-size: 1em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #3d3c3c;
  line-height: 22px;
  padding-left: 30px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 2s, visibility 2s;
}
.winrs__stat-tagline--visible {
  opacity: 1;
  visibility: visible;
}
.winrs__stats-btn-container {
  display: flex;
  flex-direction: column;
  padding: 0 8%;
}
.winrs__affinity-groups-section {
  width: 100%;
  padding: 4.375em 5%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.winrs__affinity-groups-title {
  font-weight: 700;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  text-align: center;
  padding-bottom: 0.9375em;
}
.winrs__affinity-groups-subtitle {
  font-weight: 300;
  font-size: 2em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  text-align: center;
  padding-bottom: 0.9375em;
}
.winrs__affinity-groups-body {
  font-weight: 400;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  line-height: 24px;
  text-align: center;
  padding-bottom: 1.875em;
}
.winrs__affinity-groups-container {
  display: block;
  padding: 0 5%;
}
.winrs__affinity-groups-container--hidden {
  display: none;
}
.winrs__affinity-groups-btn {
  display: inline-block;
  font-weight: 700;
  font-size: 14px;
  width: 100%;
  padding: 0.9375em 1em;
  border-radius: 0;
  border: none;
  overflow: hidden;
  position: relative;
  text-align: center;
  background-color: #fff;
  color: #E76D25;
  transition: all ease-in-out 0.15s;
  cursor: pointer;
  margin-bottom: 0.625em;
}
.winrs__affinity-groups-btn:hover {
  color: #fff;
  border: none;
}
.winrs__affinity-groups-btn:hover:before {
  transform: skew(-25deg) translate(75%, -50%);
}
.winrs__affinity-groups-btn:before {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  width: 200%;
  height: 200%;
  position: absolute;
  right: 110%;
  top: 50%;
  background-color: #E76D25;
  transform: skew(-25deg) translate(-5%, -50%);
  transition-duration: 0.5s;
  border: none;
  z-index: 1;
}
.winrs__modal {
  z-index: 1;
  width: 100%;
  overflow: auto;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}
.winrs__modal--visible {
  opacity: 1;
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid #fff;
}
.winrs__modal__content {
  padding: 1.25em;
  padding-bottom: 1.875em;
}
.winrs__modal-contact {
  font-weight: 400;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  text-align: center;
}
.winrs__modal-contact--link {
  font-weight: 700;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #E76D25;
  transition: all ease-in-out 0.15s;
  padding-left: 0.3125em;
}
.winrs__modal-contact--link:hover {
  color: #A5CF4D;
}
.winrs__modal-contact--link:not(:last-child)::after {
  content: ",";
  padding-right: 0.5rem;
}
.winrs__modal__close {
  margin: 0 0 0.3125em 90%;
}
.winrs__modal__close__icon {
  width: 75%;
  height: 75%;
}
.winrs__modal-title {
  font-weight: 700;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  text-align: center;
  padding-bottom: 0.9375em;
}
.winrs__modal-body {
  font-weight: 400;
  font-size: 0.875em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  line-height: 24px;
  text-align: center;
  padding-bottom: 0.9375em;
}
.winrs__modal-contact {
  padding-bottom: 0.625em;
}
.winrs__modal-contact-title {
  font-weight: 400;
  font-size: 0.875em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  line-height: 20px;
  color: #fff;
  text-align: center;
}
.winrs__global-section {
  background-color: #fff;
  width: 100%;
  padding: 4.375em 10%;
}
.winrs__global-title {
  font-weight: 700;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #92338a;
  text-align: center;
  padding-bottom: 0.9375em;
}
.winrs__global-title--orange {
  color: #E76D25;
}
.winrs__connect-section {
  background-color: #ececec;
  width: 100%;
  padding: 1.875em 9%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
.winrs__connect-title {
  font-weight: 300;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #656565;
  padding-right: 0.9375em;
}
.winrs__connect-icon-link {
  padding-right: 0.5em;
  transition: opacity 0.2s ease-in-out;
}
.winrs__connect-icon-link:hover {
  opacity: 0.7;
}
.winrs__connect-icon-link:before {
  font-family: "reedsmith";
}
.winrs__connect-icon-link--orange:before {
  color: #E76D25;
}
.winrs__forward-thinking-title {
  font-weight: 700;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  text-align: center;
  padding-bottom: 0.9375em;
}
.winrs__forward-thinking-body {
  font-weight: 400;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  line-height: 24px;
  text-align: center;
  padding-bottom: 2.5em;
}
.winrs__grid {
  padding: 1.875em 0;
  background-color: #ececec;
}
.winrs__grid__container {
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}
.winrs__box {
  width: 100%;
  display: flex;
  padding: 1.5em;
}
.winrs__box__content {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  background: #fff;
  color: #E76D25;
  padding: 2em;
  width: 100%;
  border: 3px solid #E76D25;
  transition: all 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.winrs__box__content:hover {
  background: #E76D25;
  color: #fff;
}
.winrs__box__content:hover .diversity__box__body {
  color: #fff;
}
.winrs__box__content:hover .diversity__box__line {
  background: #fff;
}
.winrs__box__header {
  font-size: 2em;
  font-weight: 600;
  margin: 0;
}
.winrs__box__line {
  width: 100px;
  height: 3px;
  margin: 1em;
  background: #E76D25;
  transition: background 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.winrs__box__body {
  font-size: 0.8em;
  line-height: 1.6em;
  margin-top: auto;
  color: #3d3c3c;
  transition: color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
@media screen and (min-width: 47.5em) {
  .winrs__button {
    margin-bottom: 0;
  }
  .winrs__button--hero {
    margin: 0 0.9375em 0.9375em;
    width: calc(50% - 30px);
    flex-grow: 1;
  }
  .winrs__button--blue {
    width: 50%;
  }
  .winrs__button--blue:not(:last-child) {
    margin-right: 10px;
  }
  .winrs__button--orange {
    width: 50%;
  }
  .winrs__button--orange:not(:last-child) {
    margin-right: 10px;
  }
  .winrs__button--stats {
    margin-top: 40px;
  }
  .winrs__button--stats:first-of-type {
    margin-top: 40px;
  }
  .winrs__logo {
    right: 1.25em;
    top: 1.75em;
    width: 27%;
  }
  .winrs__carousel-backing {
    height: 16.5em;
  }
  .winrs__stats-btn-container {
    flex-direction: row;
  }
  .winrs__modal__content {
    padding: 1.25em 3.75em 4.375em;
  }
}
@media screen and (min-width: 64em) {
  .winrs__box {
    width: 50%;
    min-height: 400px;
  }
  .winrs__carousel-backing {
    height: 20em;
  }
  .winrs__quote-section {
    padding: 4.375em 13%;
  }
  .winrs__stat-line {
    width: 130px;
  }
  .winrs__stat-number {
    font-weight: 300;
    font-size: 4.0625em;
  }
  .winrs__stat-tagline {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .winrs__stats-btn-container {
    padding: 0 8%;
  }
  .winrs__affinity-groups-section {
    padding: 4.375em 13%;
  }
  .winrs__modal__content {
    padding: 1.25em 4.5em 4.375em;
  }
  .winrs__modal-title {
    padding-bottom: 1.875em;
  }
  .winrs__modal-body {
    padding-bottom: 1.875em;
  }
  .winrs__global-section {
    padding: 4.375em 13%;
  }
  .winrs__connect-section {
    padding: 1.875em 15%;
  }
  .winrs__forward-thinking-body {
    width: 80%;
    margin: 0 auto;
  }
}
@media screen and (min-width: 67.1875em) {
  .winrs__button--stats {
    margin-top: 4.375em;
  }
  .winrs__button--stats:first-of-type {
    margin-top: 4.375em;
  }
  .winrs__logo {
    right: 2.5em;
    top: 2em;
    width: 20%;
  }
  .winrs__carousel-backing {
    height: 17.5em;
  }
  .winrs__quote-section {
    padding: 5em 20%;
  }
  .winrs__quote-section--first {
    padding: 0 20% 5em;
  }
  .winrs__quote {
    font-weight: 400;
    font-size: 1.875em;
    line-height: 42px;
    padding-bottom: 30px;
    width: 100%;
  }
  .winrs__stat-section {
    padding: 5em 0;
  }
  .winrs__stat-line {
    width: 260px;
  }
  .winrs__stat-number {
    font-weight: 300;
    font-size: 7.625em;
  }
  .winrs__stat-tagline {
    font-weight: 400;
    font-size: 1.5em;
  }
  .winrs__stats-btn-container {
    padding: 0;
    margin: 0 20%;
    margin-right: 16%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .winrs__affinity-groups-section {
    padding: 5em 20%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
  }
  .winrs__affinity-groups-container {
    padding: 0;
  }
  .winrs__affinity-groups-title {
    font-weight: 700;
    font-size: 2.25em;
  }
  .winrs__affinity-groups-subtitle {
    font-weight: 300;
    font-size: 3.5em;
  }
  .winrs__affinity-groups-body {
    font-weight: 400;
    font-size: 1.5em;
    line-height: 34px;
  }
  .winrs__affinity-groups-btn-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  .winrs__affinity-groups-btn {
    width: 30%;
    margin: 0 12px 25px;
  }
  .winrs__modal-title {
    font-weight: 700;
    font-size: 2.25em;
  }
  .winrs__modal-body {
    font-weight: 400;
    font-size: 1.5em;
    line-height: 34px;
  }
  .winrs__modal__close {
    margin: 0 0 0.3125em 96%;
  }
  .winrs__modal-contact {
    font-size: 24px;
  }
  .winrs__modal-contact--link {
    font-size: 24px;
  }
  .winrs__modal-contact-title {
    font-weight: 400;
    font-size: 1.125em;
  }
  .winrs__global-section {
    padding: 6.125em 10%;
  }
  .winrs__global-title {
    font-weight: 700;
    font-size: 2.25em;
  }
  .winrs__connect-section {
    padding: 65px 20%;
  }
  .winrs__connect-title {
    font-weight: 300;
    font-size: 2.25em;
  }
  .winrs__connect-icon-link {
    font-size: 1.85em;
    padding-right: 1em;
  }
  .winrs__forward-thinking-title {
    font-weight: 700;
    font-size: 2.25em;
    text-align: left;
  }
  .winrs__forward-thinking-body {
    font-weight: 400;
    font-size: 1.5em;
    line-height: 34px;
    text-align: left;
    margin: 0;
    white-space: normal;
    width: 100%;
  }
}
@media screen and (max-width: 67.125em) {
  .winrs__forward-thinking-title {
    padding-top: 40px;
  }
}

.winrs-carousel {
  height: 30em;
  width: 90%;
  margin: 0 auto;
  position: absolute;
  z-index: 1;
  bottom: -11.25em;
  left: 50%;
  transform: translateX(-50%);
  -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
}
.winrs-carousel .hero-carousel__button {
  position: initial !important;
  right: 0 !important;
}
.winrs-carousel .hero-carousel__button {
  right: auto;
}
.winrs-carousel .flickity-page-dots {
  padding-top: 1rem;
  position: initial;
  width: auto;
}
.winrs-carousel .flickity-page-dots .is-selected.diversity__green {
  background: #1e9f48;
}
.winrs-carousel .flickity-page-dots .is-selected.diversity__orange {
  background: #E76D25;
}
.winrs-carousel__controls {
  bottom: 0.75rem;
  display: none;
  height: 2.75rem;
  position: absolute;
  right: 3.75em;
}
.winrs-carousel__controls .is-paused .svg-icon--play.svg-icon--play {
  display: inline-block;
}
.winrs-carousel__controls .is-active .svg-icon--play.svg-icon--play {
  display: none;
}
.winrs-carousel__controls .svg-icon {
  vertical-align: text-top;
}
.winrs-carousel__slide {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-position-x: 80%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.winrs-carousel__text-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 75%;
  background: transparent; /* For browsers that do not support gradients */
  background: linear-gradient(to top, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0));
  padding: 2.1875em;
}
.winrs-carousel__title {
  font-weight: 400;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  line-height: 28px;
  text-align: center;
}
.winrs-carousel__body {
  font-weight: 400;
  font-size: 1em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  line-height: 28px;
  width: 55%;
  display: none;
}
.winrs-carousel__button-ghost {
  display: inline-block;
  font-weight: 700;
  font-size: 14px;
  width: 100%;
  padding: 0.75em 1.5em;
  margin-top: 1.875em;
  border: 1px solid #1e9f48;
  border-radius: 0;
  overflow: hidden;
  position: relative;
  text-align: center;
  color: #1e9f48;
  background-color: #fff;
  backface-visibility: hidden;
  transform: translateZ(0);
}
.winrs-carousel__button-ghost:hover {
  color: #fff;
}
.winrs-carousel__button-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.winrs-carousel__button-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #1e9f48;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.winrs-carousel__button-ghost .btn-ghost-label--video:after {
  content: "\e90e";
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-left: 0.5em;
  margin-top: 0.1em;
}
.winrs-carousel__slides-belt {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.winrs-carousel__mobile-btn-container {
  display: flex;
  flex-direction: row;
  position: relative;
}
.winrs-carousel__mobile-btn-container .svg-icon.diversity__orange {
  color: #fff;
}
.winrs-carousel__trigger-btn {
  background-color: #1e9f48;
  color: #fff;
  width: 33%;
  height: 3.4375em;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -webkit-border-radius: 0px;
  border-right: 1px solid #fff;
  transition: all ease-in-out 0.15s;
}
.winrs-carousel__trigger-btn .hidden {
  display: none;
}
.winrs-carousel__trigger-btn:last-child {
  border-right: 0;
}
.winrs-carousel__trigger-btn:focus {
  z-index: 2;
}
.winrs-carousel__trigger-btn.diversity-carousel__trigger-btn--pause {
  background-color: #fff !important;
}
.winrs-carousel__lg-trigger-btn {
  display: none;
  background-color: #1e9f48;
  color: #fff;
  width: 75px;
  height: 210px;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -webkit-border-radius: 0px;
  border: none;
  transition: all ease-in-out 0.15s;
  -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
}
.winrs-carousel__lg-trigger-btn:hover {
  background-color: #23bc55;
}
.winrs-carousel--topics {
  position: relative;
  transform: none;
  left: unset;
  bottom: 0;
  margin-bottom: 7.1875em;
}
@media screen and (min-width: 47.5em) {
  .winrs-carousel {
    bottom: -12.125em;
  }
  .winrs-carousel--topics {
    bottom: 1.5625em;
  }
}
@media screen and (min-width: 64em) {
  .winrs-carousel {
    width: 75%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .winrs-carousel__slides-belt {
    position: absolute;
  }
  .winrs-carousel__mobile-btn-container {
    display: none;
  }
  .winrs-carousel__controls {
    display: inline-flex;
  }
  .winrs-carousel__button-ghost {
    width: 12.8125em;
  }
  .winrs-carousel__text-container {
    height: 100%;
    justify-content: space-between;
    align-items: flex-start;
    padding: 3.75em;
    background: transparent;
  }
  .winrs-carousel__title-body {
    width: 60%;
  }
  .winrs-carousel__body {
    width: 100%;
  }
  .winrs-carousel__title {
    font-weight: 300;
    font-size: 2em;
    color: #3d3c3c;
    line-height: 42px;
    text-align: left;
    width: 90%;
    padding-bottom: 15px;
  }
  .winrs-carousel__body {
    display: block;
    color: #3d3c3c;
  }
  .winrs-carousel__lg-trigger-btn {
    display: block;
    position: relative;
  }
  .winrs-carousel__lg-trigger-btn--back {
    left: -4.4em;
    z-index: -1;
  }
  .winrs-carousel__lg-trigger-btn--forward {
    left: 4.5em;
    z-index: -1;
  }
}
@media screen and (min-width: 75em) {
  .winrs-carousel {
    width: 64%;
  }
}

.slide-enter-active {
  transition: 400ms;
}

.slide-enter.forward {
  transform: translateX(100px);
  opacity: 0;
}

.slide-enter.backward {
  transform: translateX(-100px);
  opacity: 0;
}

.winrs-global-carousel {
  height: 21.125em;
  width: 21.125em;
  right: 6%;
  position: relative;
  z-index: 1;
  margin-bottom: 4.6em;
}
.winrs-global-carousel__slide {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.winrs-global-carousel__container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-position-x: 80%;
  background-size: cover;
  background-repeat: no-repeat;
}
.winrs-global-carousel__text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
}
.winrs-global-carousel__title {
  font-weight: 700;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  line-height: 28px;
  text-align: center;
}
.winrs-global-carousel__mobile-btn-container {
  display: flex;
  flex-direction: row;
  position: relative;
  bottom: -22.25em;
  justify-content: center;
}
.winrs-global-carousel__button-ghost {
  display: inline-block;
  font-weight: 700;
  font-size: 14px;
  width: 100%;
  padding: 0.75em 1.5em;
  margin-top: 1.875em;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 0;
  overflow: hidden;
  position: relative;
  text-align: center;
  color: #fff;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
}
.winrs-global-carousel__button-ghost:hover {
  color: #92338a;
}
.winrs-global-carousel__button-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.winrs-global-carousel__button-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #fff;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.winrs-global-carousel__button-ghost-orange {
  display: inline-block;
  font-weight: 700;
  font-size: 14px;
  width: 100%;
  padding: 0.75em 1.5em;
  margin-top: 1.875em;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 0;
  overflow: hidden;
  position: relative;
  text-align: center;
  color: #fff;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
}
.winrs-global-carousel__button-ghost-orange:hover {
  color: #E76D25;
}
.winrs-global-carousel__button-ghost-orange:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.winrs-global-carousel__button-ghost-orange:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #fff;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.winrs-global-carousel__trigger-btn {
  background-color: #ececec;
  color: #fff;
  width: 30%;
  height: 4.375em;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -webkit-border-radius: 0px;
  border-right: 1px solid #fff;
  transition: all ease-in-out 0.15s;
}
.winrs-global-carousel__trigger-btn:last-child {
  border-right: 0;
}
.winrs-global-carousel__lg-trigger-btn {
  background-color: #ececec;
  color: #fff;
  width: 33%;
  height: 5.625em;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -webkit-border-radius: 0px;
  border-right: 1px solid #fff;
  transition: all ease-in-out 0.15s;
  display: none;
}
.winrs-global-carousel__lg-trigger-btn:last-child {
  border-right: 0;
}
.winrs-global-carousel__lg-trigger-btn:hover {
  background-color: #AEAEAE;
}
@media screen and (min-width: 47.5em) {
  .winrs-global-carousel {
    margin: 0 auto;
    margin-bottom: 4.6em;
    right: 0;
  }
}
@media screen and (min-width: 64em) {
  .winrs-global-carousel {
    height: 25em;
    width: 25em;
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .winrs-global-carousel__mobile-btn-container {
    display: none;
  }
  .winrs-global-carousel__button-ghost {
    width: 12.8125em;
  }
  .winrs-global-carousel__lg-trigger-btn {
    display: block;
    position: relative;
  }
  .winrs-global-carousel__lg-trigger-btn--back {
    left: -9em;
  }
  .winrs-global-carousel__lg-trigger-btn--forward {
    left: 14.2em;
  }
}
@media screen and (min-width: 67.1875em) {
  .winrs-global-carousel {
    height: 30em;
    width: 30em;
  }
  .winrs-global-carousel__title {
    width: 100%;
  }
}

.probono__call-to-action-section {
  width: 100%;
  height: 70%;
  padding: 4.375em 10%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin-bottom: 5em;
  text-align: center;
}
.probono__call-to-action-title {
  font-weight: 700;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  text-align: center;
  padding-bottom: 0.9375em;
}
.probono__call-to-action-body {
  font-weight: 400;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  line-height: 24px;
  text-align: center;
  padding-bottom: 1.875em;
}
.probono__call-to-action-container {
  display: block;
}
.probono__call-to-action-container--hidden {
  display: none;
}
@media screen and (min-width: 47.5em) {
  .probono__button {
    margin-bottom: 0;
  }
  .probono__button--hero:first-child {
    margin-right: 15px;
  }
}
@media screen and (min-width: 67.1875em) {
  .probono__call-to-action-section {
    padding: 8.125em 20%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
  }
  .probono__call-to-action-title {
    font-weight: 700;
    font-size: 2.25em;
  }
  .probono__call-to-action-body {
    font-weight: 400;
    font-size: 1.5em;
    line-height: 34px;
  }
}

.probono__logo {
  position: absolute;
  right: 0.5em;
  top: 1.2em;
  width: 40%;
}
.probono__blue {
  color: #0373ac;
}
.probono__blue-background {
  border-color: #0373ac !important;
}
.probono__blue-background .is-selected {
  background: #0373ac !important;
}
.probono__green {
  color: #1e9f48;
}
.probono__green-background {
  border-color: #1e9f48 !important;
}
.probono__green-background .is-selected {
  background: #1e9f48 !important;
}
.probono__button {
  display: inline-block;
  font-weight: 700;
  font-size: 14px;
  width: 100%;
  padding: 0.9375em 1em;
  border-radius: 0;
  overflow: hidden;
  position: relative;
  text-align: center;
  color: #fff;
  transition: all ease-in-out 0.15s;
  cursor: pointer;
  margin-bottom: 0.625em;
}
.probono__button:last-child {
  margin-bottom: 0;
}
.probono__button:hover:before {
  transform: skew(-25deg) translate(75%, -50%);
}
.probono__button:before {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  width: 200%;
  height: 200%;
  position: absolute;
  right: 110%;
  top: 50%;
  transform: skew(-25deg) translate(-5%, -50%);
  transition-duration: 0.5s;
  z-index: 1;
}
.probono__button--blue {
  background-color: #0373ac;
  margin-bottom: 0;
}
.probono__button--blue:before {
  background-color: #0494de;
}
.probono__button--hero {
  border: 1px solid #A5CF4D;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
  backface-visibility: hidden;
  transform: translateZ(0);
}
.probono__button--hero:hover {
  color: #3D3C3C;
}
.probono__button--hero:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.probono__button--hero:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #A5CF4D;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.probono__button--detail {
  width: auto;
  min-width: 20em;
}
.probono__button--stats {
  margin-top: 20px;
}
.probono__button--stats:first-of-type {
  margin-top: 40px;
}
.probono__button--video-icon:after {
  content: "\e903";
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.625em;
  right: 1.5em;
}
.probono__button--centered:only-child {
  margin: auto;
}
.probono__carousel-backing {
  background-color: #ececec;
  width: 100%;
  height: 15.5em;
}
.probono__quote-section {
  background-color: #ececec;
  width: 100%;
  padding: 4.375em 10%;
  overflow-x: hidden;
}
.probono__quote-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.probono__quote {
  font-weight: 400;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #3d3c3c;
  line-height: 24px;
  text-align: center;
  padding-bottom: 1.875em;
  opacity: 0;
  visibility: hidden;
  transition: opacity 2s, visibility 2s;
}
.probono__quote--visible {
  opacity: 1;
  visibility: visible;
}
.probono__source {
  font-weight: 400;
  font-size: 1em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #3d3c3c;
  font-style: italic;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 2s, visibility 2s;
}
.probono__source--visible {
  opacity: 1;
  visibility: visible;
}
.probono__quote-bar {
  display: block;
  height: 3px;
  width: 3.125em;
  margin-bottom: 1.875em;
}
.probono__quote-bar:last-child {
  margin-top: 1.875em;
  margin-bottom: 0;
}
.probono__quote-bar--green {
  background-color: #1e9f48;
}
.probono__quote-bar--blue {
  background-color: #0373ac;
}
.probono__quote-bar--top {
  transform: translateX(-30em);
  transition: transform 1s ease-in-out;
}
.probono__quote-bar--bottom {
  transform: translateX(30em);
  transition: transform 1s ease-in-out;
}
.probono__quote-bar--slide {
  transform: translateX(0);
}
.probono__stat-section {
  background-color: #fff;
  width: 100%;
  padding: 4.375em 0;
  padding-top: 3em;
  overflow-x: hidden;
}
.probono__stat-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  padding-bottom: 1.25em;
}
.probono__stat-container:last-child {
  padding-bottom: 0;
}
.probono__stat-lines {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.probono__stat-line {
  height: 1px;
  background-color: #0373ac;
  width: 60px;
  transform: translateX(-300px);
  transition: transform 1s ease-in-out;
}
.probono__stat-line--visible {
  transform: translateX(0);
}
.probono__stat-number {
  font-weight: 300;
  font-size: 3em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #0373ac;
  padding-left: 30px;
}
.probono__stat-tagline {
  font-weight: 400;
  font-size: 1em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #3d3c3c;
  line-height: 22px;
  padding-left: 30px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 2s, visibility 2s;
}
.probono__stat-tagline--visible {
  opacity: 1;
  visibility: visible;
}
.probono__stats-btn-container {
  display: flex;
  flex-direction: column;
  padding: 0 8%;
}
.probono__affinity-groups-section {
  width: 100%;
  padding: 4.375em 5%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.probono__affinity-groups-title {
  font-weight: 700;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  text-align: center;
  padding-bottom: 0.9375em;
}
.probono__affinity-groups-subtitle {
  font-weight: 300;
  font-size: 2em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  text-align: center;
  padding-bottom: 0.9375em;
}
.probono__affinity-groups-body {
  font-weight: 400;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  line-height: 24px;
  text-align: center;
  padding-bottom: 1.875em;
}
.probono__affinity-groups-container {
  display: block;
  padding: 0 5%;
}
.probono__affinity-groups-container--hidden {
  display: none;
}
.probono__affinity-groups-btn {
  display: inline-block;
  font-weight: 700;
  font-size: 14px;
  width: 100%;
  padding: 0.9375em 1em;
  border-radius: 0;
  border: none;
  overflow: hidden;
  position: relative;
  text-align: center;
  background-color: #fff;
  color: #0373ac;
  transition: all ease-in-out 0.15s;
  cursor: pointer;
  margin-bottom: 0.625em;
}
.probono__affinity-groups-btn:hover {
  color: #fff;
  border: none;
}
.probono__affinity-groups-btn:hover:before {
  transform: skew(-25deg) translate(75%, -50%);
}
.probono__affinity-groups-btn:before {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  width: 200%;
  height: 200%;
  position: absolute;
  right: 110%;
  top: 50%;
  background-color: #0373ac;
  transform: skew(-25deg) translate(-5%, -50%);
  transition-duration: 0.5s;
  border: none;
  z-index: 1;
}
.probono__modal {
  z-index: 1;
  width: 100%;
  overflow: auto;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}
.probono__modal--visible {
  opacity: 1;
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid #fff;
}
.probono__modal__content {
  padding: 1.25em;
  padding-bottom: 1.875em;
}
.probono__modal-contact {
  font-weight: 400;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  text-align: center;
}
.probono__modal-contact--link {
  font-weight: 700;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #0373ac;
  transition: all ease-in-out 0.15s;
  padding-left: 0.3125em;
}
.probono__modal-contact--link:hover {
  color: #A5CF4D;
}
.probono__modal-contact--link:not(:last-child)::after {
  content: ",";
  padding-right: 0.5rem;
}
.probono__modal__close {
  margin: 0 0 0.3125em 90%;
}
.probono__modal__close__icon {
  width: 75%;
  height: 75%;
}
.probono__modal-title {
  font-weight: 700;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  text-align: center;
  padding-bottom: 0.9375em;
}
.probono__modal-body {
  font-weight: 400;
  font-size: 0.875em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  line-height: 24px;
  text-align: center;
  padding-bottom: 0.9375em;
}
.probono__modal-contact {
  padding-bottom: 0.625em;
}
.probono__modal-contact-title {
  font-weight: 400;
  font-size: 0.875em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  line-height: 20px;
  color: #fff;
  text-align: center;
}
.probono__global-section {
  background-color: #fff;
  width: 100%;
  padding: 4.375em 10%;
}
.probono__global-title {
  font-weight: 700;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #92338a;
  text-align: center;
  padding-bottom: 0.9375em;
}
.probono__global-title--blue {
  color: #0373ac;
}
.probono__connect-section {
  background-color: #ececec;
  width: 100%;
  padding: 1.875em 9%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
.probono__connect-title {
  font-weight: 300;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #656565;
  padding-right: 0.9375em;
}
.probono__connect-icon-link {
  padding-right: 0.5em;
  transition: opacity 0.2s ease-in-out;
}
.probono__connect-icon-link:hover {
  opacity: 0.7;
}
.probono__connect-icon-link:before {
  font-family: "reedsmith";
}
.probono__connect-icon-link--blue:before {
  color: #0373ac;
}
.probono__forward-thinking-title {
  font-weight: 700;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  text-align: center;
  padding-bottom: 0.9375em;
}
.probono__forward-thinking-body {
  font-weight: 400;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  line-height: 24px;
  text-align: center;
  padding-bottom: 2.5em;
}
.probono__grid {
  padding: 1.875em 0;
  background-color: #ececec;
}
.probono__grid__container {
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}
.probono__box {
  width: 100%;
  display: flex;
  padding: 1.5em;
}
.probono__box__content {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  background: #fff;
  color: #0373ac;
  padding: 2em;
  width: 100%;
  border: 3px solid #0373ac;
  transition: all 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.probono__box__content:hover {
  background: #0373ac;
  color: #fff;
}
.probono__box__content:hover .diversity__box__body {
  color: #fff;
}
.probono__box__content:hover .diversity__box__line {
  background: #fff;
}
.probono__box__content:hover p {
  color: #fff;
}
.probono__box__header {
  font-size: 2em;
  font-weight: 600;
  margin: 0;
}
.probono__box__line {
  width: 100px;
  height: 3px;
  margin: 1em;
  background: #0373ac;
  transition: background 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.probono__box__body {
  font-size: 0.8em;
  line-height: 1.6em;
  margin-top: auto;
  color: #3d3c3c;
  transition: color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
@media screen and (min-width: 47.5em) {
  .probono__button {
    margin-bottom: 0;
  }
  .probono__button--hero {
    margin: 0 0.9375em 0.9375em;
    width: calc(50% - 30px);
    flex-grow: 1;
  }
  .probono__button--blue {
    width: 50%;
  }
  .probono__button--blue:not(:last-child) {
    margin-right: 10px;
  }
  .probono__button--stats {
    margin-top: 40px;
  }
  .probono__button--stats:first-of-type {
    margin-top: 40px;
  }
  .probono__logo {
    right: 1.25em;
    top: 1.75em;
    width: 27%;
  }
  .probono__carousel-backing {
    height: 16.5em;
  }
  .probono__stats-btn-container {
    flex-direction: row;
  }
  .probono__modal__content {
    padding: 1.25em 3.75em 4.375em;
  }
}
@media screen and (min-width: 64em) {
  .probono__box {
    width: 50%;
    min-height: 400px;
  }
  .probono__carousel-backing {
    height: 20em;
  }
  .probono__quote-section {
    padding: 4.375em 13%;
  }
  .probono__stat-line {
    width: 130px;
  }
  .probono__stat-number {
    font-weight: 300;
    font-size: 4.0625em;
  }
  .probono__stat-tagline {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .probono__stats-btn-container {
    padding: 0 8%;
  }
  .probono__affinity-groups-section {
    padding: 4.375em 13%;
  }
  .probono__modal__content {
    padding: 1.25em 4.5em 4.375em;
  }
  .probono__modal-title {
    padding-bottom: 1.875em;
  }
  .probono__modal-body {
    padding-bottom: 1.875em;
  }
  .probono__global-section {
    padding: 4.375em 13%;
  }
  .probono__connect-section {
    padding: 1.875em 15%;
  }
  .probono__forward-thinking-body {
    width: 80%;
    margin: 0 auto;
  }
}
@media screen and (min-width: 67.1875em) {
  .probono__button--stats {
    margin-top: 4.375em;
  }
  .probono__button--stats:first-of-type {
    margin-top: 4.375em;
  }
  .probono__logo {
    right: 2.5em;
    top: 2em;
    width: 20%;
  }
  .probono__carousel-backing {
    height: 17.5em;
  }
  .probono__quote-section {
    padding: 5em 20%;
  }
  .probono__quote-section--first {
    padding: 0 20% 5em;
  }
  .probono__quote {
    font-weight: 400;
    font-size: 1.875em;
    line-height: 42px;
    padding-bottom: 30px;
    width: 100%;
  }
  .probono__stat-section {
    padding: 5em 0;
  }
  .probono__stat-line {
    width: 260px;
  }
  .probono__stat-number {
    font-weight: 300;
    font-size: 7.625em;
  }
  .probono__stat-tagline {
    font-weight: 400;
    font-size: 1.5em;
  }
  .probono__stats-btn-container {
    padding: 0;
    margin: 0 20%;
    margin-right: 16%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .probono__affinity-groups-section {
    padding: 5em 20%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
  }
  .probono__affinity-groups-container {
    padding: 0;
  }
  .probono__affinity-groups-title {
    font-weight: 700;
    font-size: 2.25em;
  }
  .probono__affinity-groups-subtitle {
    font-weight: 300;
    font-size: 3.5em;
  }
  .probono__affinity-groups-body {
    font-weight: 400;
    font-size: 1.5em;
    line-height: 34px;
  }
  .probono__affinity-groups-btn-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  .probono__affinity-groups-btn {
    width: 30%;
    margin: 0 12px 25px;
  }
  .probono__modal-title {
    font-weight: 700;
    font-size: 2.25em;
  }
  .probono__modal-body {
    font-weight: 400;
    font-size: 1.5em;
    line-height: 34px;
  }
  .probono__modal__close {
    margin: 0 0 0.3125em 96%;
  }
  .probono__modal-contact {
    font-size: 24px;
  }
  .probono__modal-contact--link {
    font-size: 24px;
  }
  .probono__modal-contact-title {
    font-weight: 400;
    font-size: 1.125em;
  }
  .probono__global-section {
    padding: 6.125em 10%;
  }
  .probono__global-title {
    font-weight: 700;
    font-size: 2.25em;
  }
  .probono__connect-section {
    padding: 65px 20%;
  }
  .probono__connect-title {
    font-weight: 300;
    font-size: 2.25em;
  }
  .probono__connect-icon-link {
    font-size: 1.85em;
    padding-right: 1em;
  }
  .probono__forward-thinking-title {
    font-weight: 700;
    font-size: 2.25em;
    text-align: left;
  }
  .probono__forward-thinking-body {
    font-weight: 400;
    font-size: 1.5em;
    line-height: 34px;
    text-align: left;
    margin: 0;
    white-space: normal;
    width: 100%;
  }
}
@media screen and (max-width: 67.125em) {
  .probono__forward-thinking-title {
    padding-top: 40px;
  }
}

.probono-carousel {
  height: 30em;
  width: 90%;
  margin: 0 auto;
  position: absolute;
  z-index: 1;
  bottom: -11.25em;
  left: 50%;
  transform: translateX(-50%);
  -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
}
.probono-carousel .hero-carousel__button {
  position: initial !important;
  right: 0 !important;
}
.probono-carousel .hero-carousel__button {
  right: auto;
}
.probono-carousel .flickity-page-dots {
  padding-top: 1rem;
  position: initial;
  width: auto;
}
.probono-carousel .flickity-page-dots .is-selected.diversity__green {
  background: #1e9f48;
}
.probono-carousel .flickity-page-dots .is-selected.diversity__blue {
  background: #0373ac;
}
.probono-carousel__controls {
  bottom: 0.75rem;
  display: none;
  height: 2.75rem;
  position: absolute;
  right: 3.75em;
}
.probono-carousel__controls .is-paused .svg-icon--play.svg-icon--play {
  display: inline-block;
}
.probono-carousel__controls .is-active .svg-icon--play.svg-icon--play {
  display: none;
}
.probono-carousel__controls .svg-icon {
  vertical-align: text-top;
}
.probono-carousel__slide {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-position-x: 80%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.probono-carousel__text-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 75%;
  background: transparent; /* For browsers that do not support gradients */
  background: linear-gradient(to top, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0));
  padding: 2.1875em;
}
.probono-carousel__title {
  font-weight: 400;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  line-height: 28px;
  text-align: center;
  /*&-body {
    background-color: rgba(255, 255, 255, 0.65);
    padding: 20px; 
  }*/
}
.probono-carousel__body {
  font-weight: 400;
  font-size: 1em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  line-height: 28px;
  width: 55%;
  display: none;
}
.probono-carousel__button-ghost {
  display: inline-block;
  font-weight: 700;
  font-size: 14px;
  width: 100%;
  padding: 0.75em 1.5em;
  margin-top: 1.875em;
  border: 1px solid #1e9f48;
  border-radius: 0;
  overflow: hidden;
  position: relative;
  text-align: center;
  color: #1e9f48;
  background-color: #fff;
  backface-visibility: hidden;
  transform: translateZ(0);
}
.probono-carousel__button-ghost:hover {
  color: #fff;
}
.probono-carousel__button-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.probono-carousel__button-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #1e9f48;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.probono-carousel__button-ghost .btn-ghost-label--video:after {
  content: "\e90e";
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-left: 0.5em;
  margin-top: 0.1em;
}
.probono-carousel__mobile-btn-container {
  display: flex;
  flex-direction: row;
  position: relative;
  top: 30em;
}
.probono-carousel__trigger-btn {
  background-color: #1e9f48;
  color: #fff;
  width: 33%;
  height: 3.4375em;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -webkit-border-radius: 0px;
  border-right: 1px solid #fff;
  transition: all ease-in-out 0.15s;
}
.probono-carousel__trigger-btn .hidden {
  display: none;
}
.probono-carousel__trigger-btn:last-child {
  border-right: 0;
}
.probono-carousel__trigger-btn:focus {
  z-index: 2;
}
.probono-carousel__trigger-btn.diversity-carousel__trigger-btn--pause {
  background-color: #fff !important;
}
.probono-carousel__lg-trigger-btn {
  display: none;
  background-color: #1e9f48;
  color: #fff;
  width: 75px;
  height: 210px;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -webkit-border-radius: 0px;
  border: none;
  transition: all ease-in-out 0.15s;
  -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
}
.probono-carousel__lg-trigger-btn:hover {
  background-color: #23bc55;
}
.probono-carousel--topics {
  position: relative;
  transform: none;
  left: unset;
  bottom: 0;
  margin-bottom: 7.1875em;
}
@media screen and (min-width: 47.5em) {
  .probono-carousel {
    bottom: -12.125em;
  }
  .probono-carousel--topics {
    bottom: 1.5625em;
  }
}
@media screen and (min-width: 64em) {
  .probono-carousel {
    width: 75%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .probono-carousel__mobile-btn-container {
    display: none;
  }
  .probono-carousel__controls {
    display: inline-flex;
  }
  .probono-carousel__button-ghost {
    width: 12.8125em;
  }
  .probono-carousel__text-container {
    height: 100%;
    justify-content: space-between;
    align-items: flex-start;
    padding: 3.75em;
    background: transparent;
  }
  .probono-carousel__title-body {
    width: 60%;
  }
  .probono-carousel__body {
    width: 100%;
  }
  .probono-carousel__title {
    font-weight: 300;
    font-size: 2em;
    color: #3d3c3c;
    line-height: 42px;
    text-align: left;
    width: 90%;
    padding-bottom: 15px;
  }
  .probono-carousel__body {
    display: block;
    color: #3d3c3c;
  }
  .probono-carousel__lg-trigger-btn {
    display: block;
    position: relative;
  }
  .probono-carousel__lg-trigger-btn--back {
    left: -4.4em;
    z-index: -1;
  }
  .probono-carousel__lg-trigger-btn--forward {
    left: 4.5em;
    z-index: -1;
  }
}
@media screen and (min-width: 75em) {
  .probono-carousel {
    width: 64%;
  }
}

.slide-enter-active {
  transition: 400ms;
}

.slide-enter.forward {
  transform: translateX(100px);
  opacity: 0;
}

.slide-enter.backward {
  transform: translateX(-100px);
  opacity: 0;
}

.probono-global-carousel {
  height: 21.125em;
  width: 21.125em;
  right: 6%;
  position: relative;
  z-index: 1;
  margin-bottom: 4.6em;
}
.probono-global-carousel__slide {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.probono-global-carousel__container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-position-x: 80%;
  background-size: cover;
  background-repeat: no-repeat;
}
.probono-global-carousel__text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
}
.probono-global-carousel__title {
  font-weight: 700;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  line-height: 28px;
  text-align: center;
}
.probono-global-carousel__mobile-btn-container {
  display: flex;
  flex-direction: row;
  position: relative;
  bottom: -22.25em;
}
.probono-global-carousel__button-ghost {
  display: inline-block;
  font-weight: 700;
  font-size: 14px;
  width: 100%;
  padding: 0.75em 1.5em;
  margin-top: 1.875em;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 0;
  overflow: hidden;
  position: relative;
  text-align: center;
  color: #fff;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
}
.probono-global-carousel__button-ghost:hover {
  color: #92338a;
}
.probono-global-carousel__button-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.probono-global-carousel__button-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #fff;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.probono-global-carousel__button-ghost-blue {
  display: inline-block;
  font-weight: 700;
  font-size: 14px;
  width: 100%;
  padding: 0.75em 1.5em;
  margin-top: 1.875em;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 0;
  overflow: hidden;
  position: relative;
  text-align: center;
  color: #fff;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
}
.probono-global-carousel__button-ghost-blue:hover {
  color: #0373ac;
}
.probono-global-carousel__button-ghost-blue:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.probono-global-carousel__button-ghost-blue:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #fff;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.probono-global-carousel__trigger-btn {
  background-color: #ececec;
  color: #fff;
  width: 30%;
  height: 4.375em;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -webkit-border-radius: 0px;
  border-right: 1px solid #fff;
  transition: all ease-in-out 0.15s;
}
.probono-global-carousel__trigger-btn:last-child {
  border-right: 0;
}
.probono-global-carousel__lg-trigger-btn {
  background-color: #ececec;
  color: #fff;
  width: 33%;
  height: 5.625em;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -webkit-border-radius: 0px;
  border-right: 1px solid #fff;
  transition: all ease-in-out 0.15s;
  display: none;
}
.probono-global-carousel__lg-trigger-btn:last-child {
  border-right: 0;
}
.probono-global-carousel__lg-trigger-btn:hover {
  background-color: #AEAEAE;
}
@media screen and (min-width: 47.5em) {
  .probono-global-carousel {
    margin: 0 auto;
    margin-bottom: 4.6em;
    right: 0;
  }
}
@media screen and (min-width: 64em) {
  .probono-global-carousel {
    height: 25em;
    width: 25em;
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .probono-global-carousel__mobile-btn-container {
    display: none;
  }
  .probono-global-carousel__button-ghost {
    width: 12.8125em;
  }
  .probono-global-carousel__lg-trigger-btn {
    display: block;
    position: relative;
  }
  .probono-global-carousel__lg-trigger-btn--back {
    left: -9em;
  }
  .probono-global-carousel__lg-trigger-btn--forward {
    left: 14.2em;
  }
}
@media screen and (min-width: 67.1875em) {
  .probono-global-carousel {
    height: 30em;
    width: 30em;
  }
  .probono-global-carousel__title {
    width: 100%;
  }
}

.responsible-business__call-to-action-section {
  width: 100%;
  height: 70%;
  padding: 4.375em 10%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin-bottom: 5em;
  text-align: center;
}
.responsible-business__call-to-action-title {
  font-weight: 700;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  text-align: center;
  padding-bottom: 0.9375em;
}
.responsible-business__call-to-action-body {
  font-weight: 400;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  line-height: 24px;
  text-align: center;
  padding-bottom: 1.875em;
}
.responsible-business__call-to-action-container {
  display: block;
}
.responsible-business__call-to-action-container--hidden {
  display: none;
}
@media screen and (min-width: 47.5em) {
  .responsible-business__button {
    margin-bottom: 0;
  }
  .responsible-business__button--hero:first-child {
    margin-right: 15px;
  }
}
@media screen and (min-width: 67.1875em) {
  .responsible-business__call-to-action-section {
    padding: 8.125em 20%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
  }
  .responsible-business__call-to-action-title {
    font-weight: 700;
    font-size: 2.25em;
  }
  .responsible-business__call-to-action-body {
    font-weight: 400;
    font-size: 1.5em;
    line-height: 34px;
  }
}

.responsible-business__logo {
  position: absolute;
  right: 0.5em;
  top: 1.2em;
  width: 40%;
}
.responsible-business__orange {
  color: #E76D25;
}
.responsible-business__orange-background {
  border-color: #E76D25 !important;
}
.responsible-business__orange-background .is-selected {
  background: #E76D25 !important;
}
.responsible-business__green {
  color: #1e9f48;
}
.responsible-business__green-background {
  border-color: #1e9f48 !important;
}
.responsible-business__green-background .is-selected {
  background: #1e9f48 !important;
}
.responsible-business__button {
  display: inline-block;
  font-weight: 700;
  font-size: 14px;
  width: 100%;
  padding: 0.9375em 1em;
  border-radius: 0;
  overflow: hidden;
  position: relative;
  text-align: center;
  color: #fff;
  transition: all ease-in-out 0.15s;
  cursor: pointer;
  margin-bottom: 0.625em;
}
.responsible-business__button:last-child {
  margin-bottom: 0;
}
.responsible-business__button:hover:before {
  transform: skew(-25deg) translate(75%, -50%);
}
.responsible-business__button:before {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  width: 200%;
  height: 200%;
  position: absolute;
  right: 110%;
  top: 50%;
  transform: skew(-25deg) translate(-5%, -50%);
  transition-duration: 0.5s;
  z-index: 1;
}
.responsible-business__button--blue {
  background-color: #0373ac;
  margin-bottom: 0;
}
.responsible-business__button--blue:before {
  background-color: #0494de;
}
.responsible-business__button--green {
  background-color: #1e9f48;
  margin-bottom: 0;
}
.responsible-business__button--green:before {
  background-color: #1ed148;
}
.responsible-business__button--hero {
  border: 1px solid #A5CF4D;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
  backface-visibility: hidden;
  transform: translateZ(0);
}
.responsible-business__button--hero:hover {
  color: #3D3C3C;
}
.responsible-business__button--hero:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.responsible-business__button--hero:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #A5CF4D;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.responsible-business__button--detail {
  width: auto;
  min-width: 20em;
}
.responsible-business__button--stats {
  margin-top: 20px;
}
.responsible-business__button--stats:first-of-type {
  margin-top: 40px;
}
.responsible-business__button--video-icon:after {
  content: "\e903";
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.625em;
  right: 1.5em;
}
.responsible-business__button--centered:only-child {
  margin: auto;
}
.responsible-business__carousel-backing {
  background-color: #ececec;
  width: 100%;
  height: 15.5em;
}
.responsible-business__quote-section {
  background-color: #ececec;
  width: 100%;
  padding: 4.375em 10%;
  overflow-x: hidden;
}
.responsible-business__quote-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.responsible-business__quote {
  font-weight: 400;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #3d3c3c;
  line-height: 24px;
  text-align: center;
  padding-bottom: 1.875em;
  opacity: 0;
  visibility: hidden;
  transition: opacity 2s, visibility 2s;
}
.responsible-business__quote--visible {
  opacity: 1;
  visibility: visible;
}
.responsible-business__source {
  font-weight: 400;
  font-size: 1em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #3d3c3c;
  font-style: italic;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 2s, visibility 2s;
}
.responsible-business__source--visible {
  opacity: 1;
  visibility: visible;
}
.responsible-business__quote-bar {
  display: block;
  height: 3px;
  width: 3.125em;
  margin-bottom: 1.875em;
}
.responsible-business__quote-bar:last-child {
  margin-top: 1.875em;
  margin-bottom: 0;
}
.responsible-business__quote-bar--green {
  background-color: #1e9f48;
}
.responsible-business__quote-bar--orange {
  background-color: #E76D25;
}
.responsible-business__quote-bar--top {
  transform: translateX(-30em);
  transition: transform 1s ease-in-out;
}
.responsible-business__quote-bar--bottom {
  transform: translateX(30em);
  transition: transform 1s ease-in-out;
}
.responsible-business__quote-bar--slide {
  transform: translateX(0);
}
.responsible-business__stat-section {
  background-color: #fff;
  width: 100%;
  padding: 4.375em 0;
  padding-top: 3em;
  overflow-x: hidden;
}
.responsible-business__stat-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  padding-bottom: 1.25em;
}
.responsible-business__stat-container:last-child {
  padding-bottom: 0;
}
.responsible-business__stat-lines {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.responsible-business__stat-line {
  height: 1px;
  background-color: #1e9f48;
  width: 60px;
  transform: translateX(-300px);
  transition: transform 1s ease-in-out;
}
.responsible-business__stat-line--visible {
  transform: translateX(0);
}
.responsible-business__stat-number {
  font-weight: 300;
  font-size: 3em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #1e9f48;
  padding-left: 30px;
}
.responsible-business__stat-tagline {
  font-weight: 400;
  font-size: 1em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #3d3c3c;
  line-height: 22px;
  padding-left: 30px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 2s, visibility 2s;
}
.responsible-business__stat-tagline--visible {
  opacity: 1;
  visibility: visible;
}
.responsible-business__stats-btn-container {
  display: flex;
  flex-direction: column;
  padding: 0 8%;
}
.responsible-business__affinity-groups-section {
  width: 100%;
  padding: 4.375em 5%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.responsible-business__affinity-groups-title {
  font-weight: 700;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  text-align: center;
  padding-bottom: 0.9375em;
}
.responsible-business__affinity-groups-subtitle {
  font-weight: 300;
  font-size: 2em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  text-align: center;
  padding-bottom: 0.9375em;
}
.responsible-business__affinity-groups-body {
  font-weight: 400;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  line-height: 24px;
  text-align: center;
  padding-bottom: 1.875em;
}
.responsible-business__affinity-groups-container {
  display: block;
  padding: 0 5%;
}
.responsible-business__affinity-groups-container--hidden {
  display: none;
}
.responsible-business__affinity-groups-btn {
  display: inline-block;
  font-weight: 700;
  font-size: 14px;
  width: 100%;
  padding: 0.9375em 1em;
  border-radius: 0;
  border: none;
  overflow: hidden;
  position: relative;
  text-align: center;
  background-color: #fff;
  color: #1e9f48;
  transition: all ease-in-out 0.15s;
  cursor: pointer;
  margin-bottom: 0.625em;
}
.responsible-business__affinity-groups-btn:hover {
  color: #fff;
  border: none;
}
.responsible-business__affinity-groups-btn:hover:before {
  transform: skew(-25deg) translate(75%, -50%);
}
.responsible-business__affinity-groups-btn:before {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  width: 200%;
  height: 200%;
  position: absolute;
  right: 110%;
  top: 50%;
  background-color: #1e9f48;
  transform: skew(-25deg) translate(-5%, -50%);
  transition-duration: 0.5s;
  border: none;
  z-index: 1;
}
.responsible-business__modal {
  z-index: 1;
  width: 100%;
  overflow: auto;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}
.responsible-business__modal--visible {
  opacity: 1;
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid #fff;
}
.responsible-business__modal__content {
  padding: 1.25em;
  padding-bottom: 1.875em;
}
.responsible-business__modal-contact {
  font-weight: 400;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  text-align: center;
}
.responsible-business__modal-contact--link {
  font-weight: 700;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #1e9f48;
  transition: all ease-in-out 0.15s;
  padding-left: 0.3125em;
}
.responsible-business__modal-contact--link:hover {
  color: #A5CF4D;
}
.responsible-business__modal-contact--link:not(:last-child)::after {
  content: ",";
  padding-right: 0.5rem;
}
.responsible-business__modal__close {
  margin: 0 0 0.3125em 90%;
}
.responsible-business__modal__close__icon {
  width: 75%;
  height: 75%;
}
.responsible-business__modal-title {
  font-weight: 700;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  text-align: center;
  padding-bottom: 0.9375em;
}
.responsible-business__modal-body {
  font-weight: 400;
  font-size: 0.875em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  line-height: 24px;
  text-align: center;
  padding-bottom: 0.9375em;
}
.responsible-business__modal-contact {
  padding-bottom: 0.625em;
}
.responsible-business__modal-contact-title {
  font-weight: 400;
  font-size: 0.875em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  line-height: 20px;
  color: #fff;
  text-align: center;
}
.responsible-business__global-section {
  background-color: #fff;
  width: 100%;
  padding: 4.375em 10%;
}
.responsible-business__global-title {
  font-weight: 700;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #92338a;
  text-align: center;
  padding-bottom: 0.9375em;
}
.responsible-business__global-title--green {
  color: #1e9f48;
}
.responsible-business__connect-section {
  background-color: #ececec;
  width: 100%;
  padding: 1.875em 9%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
.responsible-business__connect-title {
  font-weight: 300;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #656565;
  padding-right: 0.9375em;
}
.responsible-business__connect-icon-link {
  padding-right: 0.5em;
  transition: opacity 0.2s ease-in-out;
}
.responsible-business__connect-icon-link:hover {
  opacity: 0.7;
}
.responsible-business__connect-icon-link:before {
  font-family: "reedsmith";
}
.responsible-business__connect-icon-link--green:before {
  color: #1e9f48;
}
.responsible-business__forward-thinking-title {
  font-weight: 700;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  text-align: center;
  padding-bottom: 0.9375em;
}
.responsible-business__forward-thinking-body {
  font-weight: 400;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  line-height: 24px;
  text-align: center;
  padding-bottom: 2.5em;
}
.responsible-business__grid {
  padding: 1.875em 0;
  background-color: #ececec;
}
.responsible-business__grid__container {
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}
.responsible-business__box {
  width: 100%;
  display: flex;
  padding: 1.5em;
}
.responsible-business__box__content {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  background: #fff;
  color: #1e9f48;
  padding: 2em;
  width: 100%;
  border: 3px solid #1e9f48;
  transition: all 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.responsible-business__box__content:hover {
  background: #1e9f48;
  color: #fff;
}
.responsible-business__box__content:hover .diversity__box__body {
  color: #fff;
}
.responsible-business__box__content:hover .diversity__box__line {
  background: #fff;
}
.responsible-business__box__header {
  font-size: 2em;
  font-weight: 600;
  margin: 0;
}
.responsible-business__box__line {
  width: 100px;
  height: 3px;
  margin: 1em;
  background: #1e9f48;
  transition: background 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.responsible-business__box__body {
  font-size: 0.8em;
  line-height: 1.6em;
  margin-top: auto;
  color: #3d3c3c;
  transition: color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
@media screen and (min-width: 47.5em) {
  .responsible-business__button {
    margin-bottom: 0;
  }
  .responsible-business__button--hero {
    margin: 0 0.9375em 0.9375em;
    width: calc(50% - 30px);
    flex-grow: 1;
  }
  .responsible-business__button--blue {
    width: 50%;
  }
  .responsible-business__button--blue:not(:last-child) {
    margin-right: 10px;
  }
  .responsible-business__button--green {
    width: 50%;
  }
  .responsible-business__button--green:not(:last-child) {
    margin-right: 10px;
  }
  .responsible-business__button--stats {
    margin-top: 40px;
  }
  .responsible-business__button--stats:first-of-type {
    margin-top: 40px;
  }
  .responsible-business__logo {
    right: 1.25em;
    top: 1.75em;
    width: 27%;
  }
  .responsible-business__carousel-backing {
    height: 16.5em;
  }
  .responsible-business__stats-btn-container {
    flex-direction: row;
  }
  .responsible-business__modal__content {
    padding: 1.25em 3.75em 4.375em;
  }
}
@media screen and (min-width: 64em) {
  .responsible-business__box {
    width: 50%;
    min-height: 400px;
  }
  .responsible-business__carousel-backing {
    height: 20em;
  }
  .responsible-business__quote-section {
    padding: 4.375em 13%;
  }
  .responsible-business__stat-line {
    width: 130px;
  }
  .responsible-business__stat-number {
    font-weight: 300;
    font-size: 4.0625em;
  }
  .responsible-business__stat-tagline {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .responsible-business__stats-btn-container {
    padding: 0 8%;
  }
  .responsible-business__affinity-groups-section {
    padding: 4.375em 13%;
  }
  .responsible-business__modal__content {
    padding: 1.25em 4.5em 4.375em;
  }
  .responsible-business__modal-title {
    padding-bottom: 1.875em;
  }
  .responsible-business__modal-body {
    padding-bottom: 1.875em;
  }
  .responsible-business__global-section {
    padding: 4.375em 13%;
  }
  .responsible-business__connect-section {
    padding: 1.875em 15%;
  }
  .responsible-business__forward-thinking-body {
    width: 80%;
    margin: 0 auto;
  }
}
@media screen and (min-width: 67.1875em) {
  .responsible-business__button--stats {
    margin-top: 4.375em;
  }
  .responsible-business__button--stats:first-of-type {
    margin-top: 4.375em;
  }
  .responsible-business__logo {
    right: 2.5em;
    top: 2em;
    width: 20%;
  }
  .responsible-business__carousel-backing {
    height: 17.5em;
  }
  .responsible-business__quote-section {
    padding: 5em 20%;
  }
  .responsible-business__quote-section--first {
    padding: 0 20% 5em;
  }
  .responsible-business__quote {
    font-weight: 400;
    font-size: 1.875em;
    line-height: 42px;
    padding-bottom: 30px;
    width: 100%;
  }
  .responsible-business__stat-section {
    padding: 5em 0;
  }
  .responsible-business__stat-line {
    width: 260px;
  }
  .responsible-business__stat-number {
    font-weight: 300;
    font-size: 7.625em;
  }
  .responsible-business__stat-tagline {
    font-weight: 400;
    font-size: 1.5em;
  }
  .responsible-business__stats-btn-container {
    padding: 0;
    margin: 0 20%;
    margin-right: 16%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .responsible-business__affinity-groups-section {
    padding: 5em 20%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
  }
  .responsible-business__affinity-groups-container {
    padding: 0;
  }
  .responsible-business__affinity-groups-title {
    font-weight: 700;
    font-size: 2.25em;
  }
  .responsible-business__affinity-groups-subtitle {
    font-weight: 300;
    font-size: 3.5em;
  }
  .responsible-business__affinity-groups-body {
    font-weight: 400;
    font-size: 1.5em;
    line-height: 34px;
  }
  .responsible-business__affinity-groups-btn-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  .responsible-business__affinity-groups-btn {
    width: 30%;
    margin: 0 12px 25px;
  }
  .responsible-business__modal-title {
    font-weight: 700;
    font-size: 2.25em;
  }
  .responsible-business__modal-body {
    font-weight: 400;
    font-size: 1.5em;
    line-height: 34px;
  }
  .responsible-business__modal__close {
    margin: 0 0 0.3125em 96%;
  }
  .responsible-business__modal-contact {
    font-size: 24px;
  }
  .responsible-business__modal-contact--link {
    font-size: 24px;
  }
  .responsible-business__modal-contact-title {
    font-weight: 400;
    font-size: 1.125em;
  }
  .responsible-business__global-section {
    padding: 6.125em 10%;
  }
  .responsible-business__global-title {
    font-weight: 700;
    font-size: 2.25em;
  }
  .responsible-business__connect-section {
    padding: 65px 20%;
  }
  .responsible-business__connect-title {
    font-weight: 300;
    font-size: 2.25em;
  }
  .responsible-business__connect-icon-link {
    font-size: 1.85em;
    padding-right: 1em;
  }
  .responsible-business__forward-thinking-title {
    font-weight: 700;
    font-size: 2.25em;
    text-align: left;
  }
  .responsible-business__forward-thinking-body {
    font-weight: 400;
    font-size: 1.5em;
    line-height: 34px;
    text-align: left;
    margin: 0;
    white-space: normal;
    width: 100%;
  }
}
@media screen and (max-width: 67.125em) {
  .responsible-business__forward-thinking-title {
    padding-top: 40px;
  }
}

.responsible-business-carousel {
  height: 30em;
  width: 90%;
  margin: 0 auto;
  position: absolute;
  z-index: 1;
  bottom: -11.25em;
  left: 50%;
  transform: translateX(-50%);
  -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
}
.responsible-business-carousel .hero-carousel__button {
  position: initial !important;
  right: 0 !important;
}
.responsible-business-carousel .hero-carousel__button {
  right: auto;
}
.responsible-business-carousel .flickity-page-dots {
  padding-top: 1rem;
  position: initial;
  width: auto;
}
.responsible-business-carousel .flickity-page-dots .is-selected.diversity__green {
  background: #1e9f48;
}
.responsible-business-carousel .flickity-page-dots .is-selected.diversity__orange {
  background: #E76D25;
}
.responsible-business-carousel__controls {
  bottom: 0.75rem;
  display: none;
  height: 2.75rem;
  position: absolute;
  right: 3.75em;
}
.responsible-business-carousel__controls .is-paused .svg-icon--play.svg-icon--play {
  display: inline-block;
}
.responsible-business-carousel__controls .is-active .svg-icon--play.svg-icon--play {
  display: none;
}
.responsible-business-carousel__controls .svg-icon {
  vertical-align: text-top;
}
.responsible-business-carousel__slide {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-position-x: 80%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.responsible-business-carousel__text-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 75%;
  background: transparent; /* For browsers that do not support gradients */
  background: linear-gradient(to top, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0));
  padding: 2.1875em;
}
.responsible-business-carousel__title {
  font-weight: 400;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  line-height: 28px;
  text-align: center;
}
.responsible-business-carousel__body {
  font-weight: 400;
  font-size: 1em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  line-height: 28px;
  width: 55%;
  display: none;
}
.responsible-business-carousel__button-ghost {
  display: inline-block;
  font-weight: 700;
  font-size: 14px;
  width: 100%;
  padding: 0.75em 1.5em;
  margin-top: 1.875em;
  border: 1px solid #1e9f48;
  border-radius: 0;
  overflow: hidden;
  position: relative;
  text-align: center;
  color: #1e9f48;
  background-color: #fff;
  backface-visibility: hidden;
  transform: translateZ(0);
}
.responsible-business-carousel__button-ghost:hover {
  color: #fff;
}
.responsible-business-carousel__button-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.responsible-business-carousel__button-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #1e9f48;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.responsible-business-carousel__button-ghost .btn-ghost-label--video:after {
  content: "\e90e";
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-left: 0.5em;
  margin-top: 0.1em;
}
.responsible-business-carousel__slides-belt {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.responsible-business-carousel__mobile-btn-container {
  display: flex;
  flex-direction: row;
  position: relative;
}
.responsible-business-carousel__mobile-btn-container .svg-icon.diversity__orange {
  color: #fff;
}
.responsible-business-carousel__trigger-btn {
  background-color: #1e9f48;
  color: #fff;
  width: 33%;
  height: 3.4375em;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -webkit-border-radius: 0px;
  border-right: 1px solid #fff;
  transition: all ease-in-out 0.15s;
}
.responsible-business-carousel__trigger-btn .hidden {
  display: none;
}
.responsible-business-carousel__trigger-btn:last-child {
  border-right: 0;
}
.responsible-business-carousel__trigger-btn:focus {
  z-index: 2;
}
.responsible-business-carousel__trigger-btn.diversity-carousel__trigger-btn--pause {
  background-color: #fff !important;
}
.responsible-business-carousel__lg-trigger-btn {
  display: none;
  background-color: #1e9f48;
  color: #fff;
  width: 75px;
  height: 210px;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -webkit-border-radius: 0px;
  border: none;
  transition: all ease-in-out 0.15s;
  -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
}
.responsible-business-carousel__lg-trigger-btn:hover {
  background-color: #23bc55;
}
.responsible-business-carousel--topics {
  position: relative;
  transform: none;
  left: unset;
  bottom: 0;
  margin-bottom: 7.1875em;
}
@media screen and (min-width: 47.5em) {
  .responsible-business-carousel {
    bottom: -12.125em;
  }
  .responsible-business-carousel--topics {
    bottom: 1.5625em;
  }
}
@media screen and (min-width: 64em) {
  .responsible-business-carousel {
    width: 75%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .responsible-business-carousel__slides-belt {
    position: absolute;
  }
  .responsible-business-carousel__mobile-btn-container {
    display: none;
  }
  .responsible-business-carousel__controls {
    display: inline-flex;
  }
  .responsible-business-carousel__button-ghost {
    width: 12.8125em;
  }
  .responsible-business-carousel__text-container {
    height: 100%;
    justify-content: space-between;
    align-items: flex-start;
    padding: 3.75em;
    background: transparent;
  }
  .responsible-business-carousel__title-body {
    width: 60%;
  }
  .responsible-business-carousel__body {
    width: 100%;
  }
  .responsible-business-carousel__title {
    font-weight: 300;
    font-size: 2em;
    color: #3d3c3c;
    line-height: 42px;
    text-align: left;
    width: 90%;
    padding-bottom: 15px;
  }
  .responsible-business-carousel__body {
    display: block;
    color: #3d3c3c;
  }
  .responsible-business-carousel__lg-trigger-btn {
    display: block;
    position: relative;
  }
  .responsible-business-carousel__lg-trigger-btn--back {
    left: -4.4em;
    z-index: -1;
  }
  .responsible-business-carousel__lg-trigger-btn--forward {
    left: 4.5em;
    z-index: -1;
  }
}
@media screen and (min-width: 75em) {
  .responsible-business-carousel {
    width: 64%;
  }
}

.slide-enter-active {
  transition: 400ms;
}

.slide-enter.forward {
  transform: translateX(100px);
  opacity: 0;
}

.slide-enter.backward {
  transform: translateX(-100px);
  opacity: 0;
}

.responsible-business-global-carousel {
  height: 21.125em;
  width: 21.125em;
  right: 6%;
  position: relative;
  z-index: 1;
  margin-bottom: 4.6em;
}
.responsible-business-global-carousel__slide {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.responsible-business-global-carousel__container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-position-x: 80%;
  background-size: cover;
  background-repeat: no-repeat;
}
.responsible-business-global-carousel__text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
}
.responsible-business-global-carousel__title {
  font-weight: 700;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  line-height: 28px;
  text-align: center;
}
.responsible-business-global-carousel__mobile-btn-container {
  display: flex;
  flex-direction: row;
  position: relative;
  bottom: -22.25em;
  justify-content: center;
}
.responsible-business-global-carousel__button-ghost {
  display: inline-block;
  font-weight: 700;
  font-size: 14px;
  width: 100%;
  padding: 0.75em 1.5em;
  margin-top: 1.875em;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 0;
  overflow: hidden;
  position: relative;
  text-align: center;
  color: #fff;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
}
.responsible-business-global-carousel__button-ghost:hover {
  color: #92338a;
}
.responsible-business-global-carousel__button-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.responsible-business-global-carousel__button-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #fff;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.responsible-business-global-carousel__button-ghost-green {
  display: inline-block;
  font-weight: 700;
  font-size: 14px;
  width: 100%;
  padding: 0.75em 1.5em;
  margin-top: 1.875em;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 0;
  overflow: hidden;
  position: relative;
  text-align: center;
  color: #1e9f48;
  background-color: #fff;
  backface-visibility: hidden;
  transform: translateZ(0);
}
.responsible-business-global-carousel__button-ghost-green:hover {
  color: #fff;
}
.responsible-business-global-carousel__button-ghost-green:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.responsible-business-global-carousel__button-ghost-green:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #1e9f48;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.responsible-business-global-carousel__trigger-btn {
  background-color: #ececec;
  color: #fff;
  width: 30%;
  height: 4.375em;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -webkit-border-radius: 0px;
  border-right: 1px solid #fff;
  transition: all ease-in-out 0.15s;
}
.responsible-business-global-carousel__trigger-btn:last-child {
  border-right: 0;
}
.responsible-business-global-carousel__lg-trigger-btn {
  background-color: #ececec;
  color: #fff;
  width: 33%;
  height: 5.625em;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -webkit-border-radius: 0px;
  border-right: 1px solid #fff;
  transition: all ease-in-out 0.15s;
  display: none;
}
.responsible-business-global-carousel__lg-trigger-btn:last-child {
  border-right: 0;
}
.responsible-business-global-carousel__lg-trigger-btn:hover {
  background-color: #AEAEAE;
}
@media screen and (min-width: 47.5em) {
  .responsible-business-global-carousel {
    margin: 0 auto;
    margin-bottom: 4.6em;
    right: 0;
  }
}
@media screen and (min-width: 64em) {
  .responsible-business-global-carousel {
    height: 25em;
    width: 25em;
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .responsible-business-global-carousel__mobile-btn-container {
    display: none;
  }
  .responsible-business-global-carousel__button-ghost {
    width: 12.8125em;
  }
  .responsible-business-global-carousel__lg-trigger-btn {
    display: block;
    position: relative;
  }
  .responsible-business-global-carousel__lg-trigger-btn--back {
    left: -9em;
  }
  .responsible-business-global-carousel__lg-trigger-btn--forward {
    left: 14.2em;
  }
}
@media screen and (min-width: 67.1875em) {
  .responsible-business-global-carousel {
    height: 30em;
    width: 30em;
  }
  .responsible-business-global-carousel__title {
    width: 100%;
  }
}

.article-header {
  text-align: center;
}
.article-header .page-header {
  margin-bottom: 0;
}
.article-header .angled-hero-slender-content {
  max-width: 78.125em;
  margin: 0 auto;
}

.article-header-meta {
  font-size: 1.5em;
  margin-bottom: 1.0416666667em;
}

.article-header-date + .article-header-source:before {
  content: "|";
  display: inline-block;
  margin-left: 0.1em;
  margin-right: 0.25em;
}

.artice-header-source {
  position: relative;
  transition: none;
  color: #CC092F;
  display: inline-block;
}
.artice-header-source:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0%;
  bottom: -1px;
  left: 0;
  height: 1px;
  background-color: #5B5B5B;
  transition: 0.35s;
}
.artice-header-source:hover {
  color: #5B5B5B;
}
.artice-header-source:hover:before {
  width: 100%;
}
@media screen and (max-width: 47.4375em) {
  .artice-header-source:before {
    display: none;
  }
  .artice-header-source:hover:before {
    display: none;
  }
}

@media screen and (max-width: 56.1875em) {
  .article-header-meta {
    font-size: 1.25em;
    margin-bottom: 1.25em;
  }
}
.article-body__forecast .article-body-key-takeaways {
  position: relative;
  color: #fff;
  margin-bottom: 5em;
  margin-top: 5em;
  font-size: 1.5em;
}
.article-body__forecast .article-body-key-takeaways__title {
  font-size: 1.5em;
  padding: 0.625em 0;
}
.article-body__forecast .article-body-key-takeaways .rte {
  padding: 1.5625em 1.5625em;
}
.article-body__forecast .article-body-key-takeaways .rte ul {
  padding-left: 0;
}
.article-body__forecast .article-body-key-takeaways .rte ul li {
  padding-bottom: 0.9375em;
  line-height: 1.3;
}
.article-body__forecast .article-body-key-takeaways .rte ul li:before {
  content: "";
}
.article-body__forecast .article-body-key-takeaways .rte ul li:not(:last-child):after {
  content: "";
  position: absolute;
  left: 0;
  display: inline-block;
  height: 1em;
  width: 20%;
  border-bottom: 1px solid;
  margin-top: 1.25em;
}
.article-body__forecast .article-body-key-takeaways:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background: inherit;
  z-index: -1;
  bottom: 0;
  background-color: #cd122d;
  transform: skewY(-6deg);
}
.article-body__forecast .article-body-key-takeaways__button {
  width: 100%;
}
.article-body__forecast .rte strong {
  font-family: "Open Sans", sans-serif;
}
.article-body__forecast .rte blockquote {
  font-weight: 400;
  font-size: 1em;
  font-family: "Open Sans", sans-serif;
  line-height: 1.5;
  margin: 20px 0;
  padding: 60px 20px;
  background-color: #f3f3f3;
}
@media screen and (max-width: 56.1875em) {
  .article-body__forecast .rte blockquote {
    font-size: 0.9375em;
  }
}
.article-body__forecast .rte blockquote:after {
  top: 2em;
}
.article-body__forecast .rte blockquote:before {
  bottom: 2em;
}
.article-body__forecast .rte {
  font-weight: 400;
  font-size: 1em;
  font-family: "Open Sans", sans-serif;
  line-height: 1.5;
}
@media screen and (max-width: 56.1875em) {
  .article-body__forecast .rte {
    font-size: 0.9375em;
  }
}
.article-body__forecast .rte--lead {
  font-family: "Open Sans", sans-serif;
  font-size: 1.25em;
  line-height: 1.5;
}
@media screen and (max-width: 56.1875em) {
  .article-body__forecast .rte--lead {
    font-size: 1.25em;
  }
}
.article-body__forecast--right-column .btn-ghost {
  margin-bottom: 0.625em;
}
@media screen and (min-width: 56.25em) {
  .article-body__forecast {
    display: flex;
  }
  .article-body__forecast--left-column {
    padding-right: 1.5625em;
    width: 60%;
  }
  .article-body__forecast--right-column {
    padding-left: 1.5625em;
    width: 40%;
  }
  .article-body__forecast .article-body-img-lead {
    margin: 0 0 0 -1.875em;
  }
  .article-body__forecast .article-body-key-takeaways {
    margin-top: 1.375em;
  }
}

.join-the-conversation {
  background-color: #f3f3f3;
  width: 100%;
  padding: 1.25em 1.5625em;
  flex-direction: row;
  align-items: baseline;
}
.join-the-conversation .silent-wrapper {
  display: flex;
}
.join-the-conversation__connect-title {
  font-weight: 300;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #181818;
  padding-right: 0.9375em;
}
.join-the-conversation__connect-icon-link {
  padding-right: 0.5em;
  transition: opacity 0.2s ease-in-out;
}
.join-the-conversation__connect-icon-link:hover {
  opacity: 0.7;
}
.join-the-conversation__connect-icon-link:before {
  font-family: "reedsmith";
}
.join-the-conversation__connect-icon-link:before {
  color: #cd122d;
}
@media screen and (min-width: 56.25em) {
  .join-the-conversation {
    padding: 3.75em 0;
  }
  .join-the-conversation__connect-title {
    font-weight: 300;
    font-size: 2.25em;
  }
  .join-the-conversation__connect-icon-link {
    font-size: 1.85em;
    padding-right: 1em;
  }
}

.article-body-details {
  padding-top: 3em;
}
.article-intro + .article-body-details {
  border-top: 1px solid #979797;
}

.article-body-img-lead {
  margin: 0 -1.875em;
}
.article-body-img-lead > img {
  width: 100%;
  display: block;
}

@media screen and (max-width: 56.1875em) {
  .article-body-details {
    padding-top: 2.6666666667em;
  }
  .article-body-img-lead {
    margin: 0 -1em;
  }
}
.page-article-detail__forecast .breadcrumbs {
  font-size: 14px;
}
.page-article-detail__forecast .article-tile-heading:focus .article-tile-card {
  outline: #18D0E4 solid 5px;
  outline-style: inset;
}
.page-article-detail__forecast .article-intro-abstract {
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
}
.page-article-detail__forecast .article-intro-authors {
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
}
.page-article-detail__forecast .article-intro-body {
  font-family: "Open Sans", sans-serif;
}
.page-article-detail__forecast.forecast-theme__melon .rte a {
  color: #1e9f48;
}
.page-article-detail__forecast.forecast-theme__melon .article-body-key-takeaways:after {
  background-color: #1e9f48;
}
.page-article-detail__forecast.forecast-theme__melon .join-the-conversation__connect-icon-link:before {
  color: #1e9f48;
}
.page-article-detail__forecast.forecast-theme__melon .related-areas-link,
.page-article-detail__forecast.forecast-theme__melon .attachments-list-link,
.page-article-detail__forecast.forecast-theme__melon .article-intro-author {
  position: relative;
  transition: none;
  color: #1e9f48;
  display: inline-block;
}
.page-article-detail__forecast.forecast-theme__melon .related-areas-link:before,
.page-article-detail__forecast.forecast-theme__melon .attachments-list-link:before,
.page-article-detail__forecast.forecast-theme__melon .article-intro-author:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0%;
  bottom: -1px;
  left: 0;
  height: 1px;
  background-color: #5B5B5B;
  transition: 0.35s;
}
.page-article-detail__forecast.forecast-theme__melon .related-areas-link:hover,
.page-article-detail__forecast.forecast-theme__melon .attachments-list-link:hover,
.page-article-detail__forecast.forecast-theme__melon .article-intro-author:hover {
  color: #5B5B5B;
}
.page-article-detail__forecast.forecast-theme__melon .related-areas-link:hover:before,
.page-article-detail__forecast.forecast-theme__melon .attachments-list-link:hover:before,
.page-article-detail__forecast.forecast-theme__melon .article-intro-author:hover:before {
  width: 100%;
}
@media screen and (max-width: 47.4375em) {
  .page-article-detail__forecast.forecast-theme__melon .related-areas-link:before,
  .page-article-detail__forecast.forecast-theme__melon .attachments-list-link:before,
  .page-article-detail__forecast.forecast-theme__melon .article-intro-author:before {
    display: none;
  }
  .page-article-detail__forecast.forecast-theme__melon .related-areas-link:hover:before,
  .page-article-detail__forecast.forecast-theme__melon .attachments-list-link:hover:before,
  .page-article-detail__forecast.forecast-theme__melon .article-intro-author:hover:before {
    display: none;
  }
}
.page-article-detail__forecast.forecast-theme__melon .scrollspy-nav-toggle {
  background-color: #1e9f48;
}
.page-article-detail__forecast.forecast-theme__melon blockquote:before, .page-article-detail__forecast.forecast-theme__melon blockquote:after,
.page-article-detail__forecast.forecast-theme__melon .blockquote:before,
.page-article-detail__forecast.forecast-theme__melon .blockquote:after {
  border-top-color: #1e9f48;
}
.page-article-detail__forecast.forecast-theme__marine .rte a {
  color: #0373ac;
}
.page-article-detail__forecast.forecast-theme__marine .article-body-key-takeaways:after {
  background-color: #0373ac;
}
.page-article-detail__forecast.forecast-theme__marine .join-the-conversation__connect-icon-link:before {
  color: #0373ac;
}
.page-article-detail__forecast.forecast-theme__marine .related-areas-link,
.page-article-detail__forecast.forecast-theme__marine .attachments-list-link,
.page-article-detail__forecast.forecast-theme__marine .article-intro-author {
  position: relative;
  transition: none;
  color: #0373ac;
  display: inline-block;
}
.page-article-detail__forecast.forecast-theme__marine .related-areas-link:before,
.page-article-detail__forecast.forecast-theme__marine .attachments-list-link:before,
.page-article-detail__forecast.forecast-theme__marine .article-intro-author:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0%;
  bottom: -1px;
  left: 0;
  height: 1px;
  background-color: #5B5B5B;
  transition: 0.35s;
}
.page-article-detail__forecast.forecast-theme__marine .related-areas-link:hover,
.page-article-detail__forecast.forecast-theme__marine .attachments-list-link:hover,
.page-article-detail__forecast.forecast-theme__marine .article-intro-author:hover {
  color: #5B5B5B;
}
.page-article-detail__forecast.forecast-theme__marine .related-areas-link:hover:before,
.page-article-detail__forecast.forecast-theme__marine .attachments-list-link:hover:before,
.page-article-detail__forecast.forecast-theme__marine .article-intro-author:hover:before {
  width: 100%;
}
@media screen and (max-width: 47.4375em) {
  .page-article-detail__forecast.forecast-theme__marine .related-areas-link:before,
  .page-article-detail__forecast.forecast-theme__marine .attachments-list-link:before,
  .page-article-detail__forecast.forecast-theme__marine .article-intro-author:before {
    display: none;
  }
  .page-article-detail__forecast.forecast-theme__marine .related-areas-link:hover:before,
  .page-article-detail__forecast.forecast-theme__marine .attachments-list-link:hover:before,
  .page-article-detail__forecast.forecast-theme__marine .article-intro-author:hover:before {
    display: none;
  }
}
.page-article-detail__forecast.forecast-theme__marine .scrollspy-nav-toggle {
  background-color: #0373ac;
}
.page-article-detail__forecast.forecast-theme__marine blockquote:before, .page-article-detail__forecast.forecast-theme__marine blockquote:after,
.page-article-detail__forecast.forecast-theme__marine .blockquote:before,
.page-article-detail__forecast.forecast-theme__marine .blockquote:after {
  border-top-color: #0373ac;
}
.page-article-detail__forecast.forecast-theme__plum .rte a {
  color: #92338a;
}
.page-article-detail__forecast.forecast-theme__plum .article-body-key-takeaways:after {
  background-color: #92338a;
}
.page-article-detail__forecast.forecast-theme__plum .join-the-conversation__connect-icon-link:before {
  color: #92338a;
}
.page-article-detail__forecast.forecast-theme__plum .related-areas-link,
.page-article-detail__forecast.forecast-theme__plum .attachments-list-link,
.page-article-detail__forecast.forecast-theme__plum .article-intro-author {
  position: relative;
  transition: none;
  color: #92338a;
  display: inline-block;
}
.page-article-detail__forecast.forecast-theme__plum .related-areas-link:before,
.page-article-detail__forecast.forecast-theme__plum .attachments-list-link:before,
.page-article-detail__forecast.forecast-theme__plum .article-intro-author:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0%;
  bottom: -1px;
  left: 0;
  height: 1px;
  background-color: #5B5B5B;
  transition: 0.35s;
}
.page-article-detail__forecast.forecast-theme__plum .related-areas-link:hover,
.page-article-detail__forecast.forecast-theme__plum .attachments-list-link:hover,
.page-article-detail__forecast.forecast-theme__plum .article-intro-author:hover {
  color: #5B5B5B;
}
.page-article-detail__forecast.forecast-theme__plum .related-areas-link:hover:before,
.page-article-detail__forecast.forecast-theme__plum .attachments-list-link:hover:before,
.page-article-detail__forecast.forecast-theme__plum .article-intro-author:hover:before {
  width: 100%;
}
@media screen and (max-width: 47.4375em) {
  .page-article-detail__forecast.forecast-theme__plum .related-areas-link:before,
  .page-article-detail__forecast.forecast-theme__plum .attachments-list-link:before,
  .page-article-detail__forecast.forecast-theme__plum .article-intro-author:before {
    display: none;
  }
  .page-article-detail__forecast.forecast-theme__plum .related-areas-link:hover:before,
  .page-article-detail__forecast.forecast-theme__plum .attachments-list-link:hover:before,
  .page-article-detail__forecast.forecast-theme__plum .article-intro-author:hover:before {
    display: none;
  }
}
.page-article-detail__forecast.forecast-theme__plum .scrollspy-nav-toggle {
  background-color: #92338a;
}
.page-article-detail__forecast.forecast-theme__plum blockquote:before, .page-article-detail__forecast.forecast-theme__plum blockquote:after,
.page-article-detail__forecast.forecast-theme__plum .blockquote:before,
.page-article-detail__forecast.forecast-theme__plum .blockquote:after {
  border-top-color: #92338a;
}
.page-article-detail__forecast.forecast-theme__red .rte a {
  color: #cd122d;
}
.page-article-detail__forecast.forecast-theme__red .article-body-key-takeaways:after {
  background-color: #cd122d;
}
.page-article-detail__forecast.forecast-theme__red .join-the-conversation__connect-icon-link:before {
  color: #cd122d;
}
.page-article-detail__forecast.forecast-theme__red .related-areas-link,
.page-article-detail__forecast.forecast-theme__red .attachments-list-link,
.page-article-detail__forecast.forecast-theme__red .article-intro-author {
  position: relative;
  transition: none;
  color: #cd122d;
  display: inline-block;
}
.page-article-detail__forecast.forecast-theme__red .related-areas-link:before,
.page-article-detail__forecast.forecast-theme__red .attachments-list-link:before,
.page-article-detail__forecast.forecast-theme__red .article-intro-author:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0%;
  bottom: -1px;
  left: 0;
  height: 1px;
  background-color: #5B5B5B;
  transition: 0.35s;
}
.page-article-detail__forecast.forecast-theme__red .related-areas-link:hover,
.page-article-detail__forecast.forecast-theme__red .attachments-list-link:hover,
.page-article-detail__forecast.forecast-theme__red .article-intro-author:hover {
  color: #5B5B5B;
}
.page-article-detail__forecast.forecast-theme__red .related-areas-link:hover:before,
.page-article-detail__forecast.forecast-theme__red .attachments-list-link:hover:before,
.page-article-detail__forecast.forecast-theme__red .article-intro-author:hover:before {
  width: 100%;
}
@media screen and (max-width: 47.4375em) {
  .page-article-detail__forecast.forecast-theme__red .related-areas-link:before,
  .page-article-detail__forecast.forecast-theme__red .attachments-list-link:before,
  .page-article-detail__forecast.forecast-theme__red .article-intro-author:before {
    display: none;
  }
  .page-article-detail__forecast.forecast-theme__red .related-areas-link:hover:before,
  .page-article-detail__forecast.forecast-theme__red .attachments-list-link:hover:before,
  .page-article-detail__forecast.forecast-theme__red .article-intro-author:hover:before {
    display: none;
  }
}
.page-article-detail__forecast.forecast-theme__red .scrollspy-nav-toggle {
  background-color: #cd122d;
}
.page-article-detail__forecast.forecast-theme__red blockquote:before, .page-article-detail__forecast.forecast-theme__red blockquote:after,
.page-article-detail__forecast.forecast-theme__red .blockquote:before,
.page-article-detail__forecast.forecast-theme__red .blockquote:after {
  border-top-color: #cd122d;
}
.page-article-detail__forecast.forecast-theme__olive .rte a {
  color: #558033;
}
.page-article-detail__forecast.forecast-theme__olive .article-body-key-takeaways:after {
  background-color: #558033;
}
.page-article-detail__forecast.forecast-theme__olive .join-the-conversation__connect-icon-link:before {
  color: #558033;
}
.page-article-detail__forecast.forecast-theme__olive .related-areas-link,
.page-article-detail__forecast.forecast-theme__olive .attachments-list-link,
.page-article-detail__forecast.forecast-theme__olive .article-intro-author {
  position: relative;
  transition: none;
  color: #558033;
  display: inline-block;
}
.page-article-detail__forecast.forecast-theme__olive .related-areas-link:before,
.page-article-detail__forecast.forecast-theme__olive .attachments-list-link:before,
.page-article-detail__forecast.forecast-theme__olive .article-intro-author:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0%;
  bottom: -1px;
  left: 0;
  height: 1px;
  background-color: #5B5B5B;
  transition: 0.35s;
}
.page-article-detail__forecast.forecast-theme__olive .related-areas-link:hover,
.page-article-detail__forecast.forecast-theme__olive .attachments-list-link:hover,
.page-article-detail__forecast.forecast-theme__olive .article-intro-author:hover {
  color: #5B5B5B;
}
.page-article-detail__forecast.forecast-theme__olive .related-areas-link:hover:before,
.page-article-detail__forecast.forecast-theme__olive .attachments-list-link:hover:before,
.page-article-detail__forecast.forecast-theme__olive .article-intro-author:hover:before {
  width: 100%;
}
@media screen and (max-width: 47.4375em) {
  .page-article-detail__forecast.forecast-theme__olive .related-areas-link:before,
  .page-article-detail__forecast.forecast-theme__olive .attachments-list-link:before,
  .page-article-detail__forecast.forecast-theme__olive .article-intro-author:before {
    display: none;
  }
  .page-article-detail__forecast.forecast-theme__olive .related-areas-link:hover:before,
  .page-article-detail__forecast.forecast-theme__olive .attachments-list-link:hover:before,
  .page-article-detail__forecast.forecast-theme__olive .article-intro-author:hover:before {
    display: none;
  }
}
.page-article-detail__forecast.forecast-theme__olive .scrollspy-nav-toggle {
  background-color: #558033;
}
.page-article-detail__forecast.forecast-theme__olive blockquote:before, .page-article-detail__forecast.forecast-theme__olive blockquote:after,
.page-article-detail__forecast.forecast-theme__olive .blockquote:before,
.page-article-detail__forecast.forecast-theme__olive .blockquote:after {
  border-top-color: #558033;
}
.page-article-detail__forecast.forecast-theme__brand-red .rte a {
  color: #CC092F;
}
.page-article-detail__forecast.forecast-theme__brand-red .article-body-key-takeaways:after {
  background-color: #CC092F;
}
.page-article-detail__forecast.forecast-theme__brand-red .join-the-conversation__connect-icon-link:before {
  color: #CC092F;
}
.page-article-detail__forecast.forecast-theme__brand-red .related-areas-link,
.page-article-detail__forecast.forecast-theme__brand-red .attachments-list-link,
.page-article-detail__forecast.forecast-theme__brand-red .article-intro-author {
  position: relative;
  transition: none;
  color: #CC092F;
  display: inline-block;
}
.page-article-detail__forecast.forecast-theme__brand-red .related-areas-link:before,
.page-article-detail__forecast.forecast-theme__brand-red .attachments-list-link:before,
.page-article-detail__forecast.forecast-theme__brand-red .article-intro-author:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0%;
  bottom: -1px;
  left: 0;
  height: 1px;
  background-color: #5B5B5B;
  transition: 0.35s;
}
.page-article-detail__forecast.forecast-theme__brand-red .related-areas-link:hover,
.page-article-detail__forecast.forecast-theme__brand-red .attachments-list-link:hover,
.page-article-detail__forecast.forecast-theme__brand-red .article-intro-author:hover {
  color: #5B5B5B;
}
.page-article-detail__forecast.forecast-theme__brand-red .related-areas-link:hover:before,
.page-article-detail__forecast.forecast-theme__brand-red .attachments-list-link:hover:before,
.page-article-detail__forecast.forecast-theme__brand-red .article-intro-author:hover:before {
  width: 100%;
}
@media screen and (max-width: 47.4375em) {
  .page-article-detail__forecast.forecast-theme__brand-red .related-areas-link:before,
  .page-article-detail__forecast.forecast-theme__brand-red .attachments-list-link:before,
  .page-article-detail__forecast.forecast-theme__brand-red .article-intro-author:before {
    display: none;
  }
  .page-article-detail__forecast.forecast-theme__brand-red .related-areas-link:hover:before,
  .page-article-detail__forecast.forecast-theme__brand-red .attachments-list-link:hover:before,
  .page-article-detail__forecast.forecast-theme__brand-red .article-intro-author:hover:before {
    display: none;
  }
}
.page-article-detail__forecast.forecast-theme__brand-red .scrollspy-nav-toggle {
  background-color: #CC092F;
}
.page-article-detail__forecast.forecast-theme__brand-red blockquote:before, .page-article-detail__forecast.forecast-theme__brand-red blockquote:after,
.page-article-detail__forecast.forecast-theme__brand-red .blockquote:before,
.page-article-detail__forecast.forecast-theme__brand-red .blockquote:after {
  border-top-color: #CC092F;
}
.page-article-detail__forecast.forecast-theme__royal .rte a {
  color: #283584;
}
.page-article-detail__forecast.forecast-theme__royal .article-body-key-takeaways:after {
  background-color: #283584;
}
.page-article-detail__forecast.forecast-theme__royal .join-the-conversation__connect-icon-link:before {
  color: #283584;
}
.page-article-detail__forecast.forecast-theme__royal .related-areas-link,
.page-article-detail__forecast.forecast-theme__royal .attachments-list-link,
.page-article-detail__forecast.forecast-theme__royal .article-intro-author {
  position: relative;
  transition: none;
  color: #283584;
  display: inline-block;
}
.page-article-detail__forecast.forecast-theme__royal .related-areas-link:before,
.page-article-detail__forecast.forecast-theme__royal .attachments-list-link:before,
.page-article-detail__forecast.forecast-theme__royal .article-intro-author:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0%;
  bottom: -1px;
  left: 0;
  height: 1px;
  background-color: #5B5B5B;
  transition: 0.35s;
}
.page-article-detail__forecast.forecast-theme__royal .related-areas-link:hover,
.page-article-detail__forecast.forecast-theme__royal .attachments-list-link:hover,
.page-article-detail__forecast.forecast-theme__royal .article-intro-author:hover {
  color: #5B5B5B;
}
.page-article-detail__forecast.forecast-theme__royal .related-areas-link:hover:before,
.page-article-detail__forecast.forecast-theme__royal .attachments-list-link:hover:before,
.page-article-detail__forecast.forecast-theme__royal .article-intro-author:hover:before {
  width: 100%;
}
@media screen and (max-width: 47.4375em) {
  .page-article-detail__forecast.forecast-theme__royal .related-areas-link:before,
  .page-article-detail__forecast.forecast-theme__royal .attachments-list-link:before,
  .page-article-detail__forecast.forecast-theme__royal .article-intro-author:before {
    display: none;
  }
  .page-article-detail__forecast.forecast-theme__royal .related-areas-link:hover:before,
  .page-article-detail__forecast.forecast-theme__royal .attachments-list-link:hover:before,
  .page-article-detail__forecast.forecast-theme__royal .article-intro-author:hover:before {
    display: none;
  }
}
.page-article-detail__forecast.forecast-theme__royal .scrollspy-nav-toggle {
  background-color: #283584;
}
.page-article-detail__forecast.forecast-theme__royal blockquote:before, .page-article-detail__forecast.forecast-theme__royal blockquote:after,
.page-article-detail__forecast.forecast-theme__royal .blockquote:before,
.page-article-detail__forecast.forecast-theme__royal .blockquote:after {
  border-top-color: #283584;
}
.page-article-detail__forecast.forecast-theme__tangerine .rte a {
  color: #E94B00;
}
.page-article-detail__forecast.forecast-theme__tangerine .article-body-key-takeaways:after {
  background-color: #E94B00;
}
.page-article-detail__forecast.forecast-theme__tangerine .join-the-conversation__connect-icon-link:before {
  color: #E94B00;
}
.page-article-detail__forecast.forecast-theme__tangerine .related-areas-link,
.page-article-detail__forecast.forecast-theme__tangerine .attachments-list-link,
.page-article-detail__forecast.forecast-theme__tangerine .article-intro-author {
  position: relative;
  transition: none;
  color: #E94B00;
  display: inline-block;
}
.page-article-detail__forecast.forecast-theme__tangerine .related-areas-link:before,
.page-article-detail__forecast.forecast-theme__tangerine .attachments-list-link:before,
.page-article-detail__forecast.forecast-theme__tangerine .article-intro-author:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0%;
  bottom: -1px;
  left: 0;
  height: 1px;
  background-color: #5B5B5B;
  transition: 0.35s;
}
.page-article-detail__forecast.forecast-theme__tangerine .related-areas-link:hover,
.page-article-detail__forecast.forecast-theme__tangerine .attachments-list-link:hover,
.page-article-detail__forecast.forecast-theme__tangerine .article-intro-author:hover {
  color: #5B5B5B;
}
.page-article-detail__forecast.forecast-theme__tangerine .related-areas-link:hover:before,
.page-article-detail__forecast.forecast-theme__tangerine .attachments-list-link:hover:before,
.page-article-detail__forecast.forecast-theme__tangerine .article-intro-author:hover:before {
  width: 100%;
}
@media screen and (max-width: 47.4375em) {
  .page-article-detail__forecast.forecast-theme__tangerine .related-areas-link:before,
  .page-article-detail__forecast.forecast-theme__tangerine .attachments-list-link:before,
  .page-article-detail__forecast.forecast-theme__tangerine .article-intro-author:before {
    display: none;
  }
  .page-article-detail__forecast.forecast-theme__tangerine .related-areas-link:hover:before,
  .page-article-detail__forecast.forecast-theme__tangerine .attachments-list-link:hover:before,
  .page-article-detail__forecast.forecast-theme__tangerine .article-intro-author:hover:before {
    display: none;
  }
}
.page-article-detail__forecast.forecast-theme__tangerine .scrollspy-nav-toggle {
  background-color: #E94B00;
}
.page-article-detail__forecast.forecast-theme__tangerine blockquote:before, .page-article-detail__forecast.forecast-theme__tangerine blockquote:after,
.page-article-detail__forecast.forecast-theme__tangerine .blockquote:before,
.page-article-detail__forecast.forecast-theme__tangerine .blockquote:after {
  border-top-color: #E94B00;
}
.page-article-detail__forecast.forecast-theme__dark .rte a {
  color: #696969;
}
.page-article-detail__forecast.forecast-theme__dark .article-body-key-takeaways:after {
  background-color: #696969;
}
.page-article-detail__forecast.forecast-theme__dark .join-the-conversation__connect-icon-link:before {
  color: #696969;
}
.page-article-detail__forecast.forecast-theme__dark .related-areas-link,
.page-article-detail__forecast.forecast-theme__dark .attachments-list-link,
.page-article-detail__forecast.forecast-theme__dark .article-intro-author {
  position: relative;
  transition: none;
  color: #696969;
  display: inline-block;
}
.page-article-detail__forecast.forecast-theme__dark .related-areas-link:before,
.page-article-detail__forecast.forecast-theme__dark .attachments-list-link:before,
.page-article-detail__forecast.forecast-theme__dark .article-intro-author:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0%;
  bottom: -1px;
  left: 0;
  height: 1px;
  background-color: #5B5B5B;
  transition: 0.35s;
}
.page-article-detail__forecast.forecast-theme__dark .related-areas-link:hover,
.page-article-detail__forecast.forecast-theme__dark .attachments-list-link:hover,
.page-article-detail__forecast.forecast-theme__dark .article-intro-author:hover {
  color: #5B5B5B;
}
.page-article-detail__forecast.forecast-theme__dark .related-areas-link:hover:before,
.page-article-detail__forecast.forecast-theme__dark .attachments-list-link:hover:before,
.page-article-detail__forecast.forecast-theme__dark .article-intro-author:hover:before {
  width: 100%;
}
@media screen and (max-width: 47.4375em) {
  .page-article-detail__forecast.forecast-theme__dark .related-areas-link:before,
  .page-article-detail__forecast.forecast-theme__dark .attachments-list-link:before,
  .page-article-detail__forecast.forecast-theme__dark .article-intro-author:before {
    display: none;
  }
  .page-article-detail__forecast.forecast-theme__dark .related-areas-link:hover:before,
  .page-article-detail__forecast.forecast-theme__dark .attachments-list-link:hover:before,
  .page-article-detail__forecast.forecast-theme__dark .article-intro-author:hover:before {
    display: none;
  }
}
.page-article-detail__forecast.forecast-theme__dark .scrollspy-nav-toggle {
  background-color: #696969;
}
.page-article-detail__forecast.forecast-theme__dark blockquote:before, .page-article-detail__forecast.forecast-theme__dark blockquote:after,
.page-article-detail__forecast.forecast-theme__dark .blockquote:before,
.page-article-detail__forecast.forecast-theme__dark .blockquote:after {
  border-top-color: #696969;
}
.page-article-detail__forecast.forecast-theme__light .rte a {
  color: #6A6A6A;
}
.page-article-detail__forecast.forecast-theme__light .article-body-key-takeaways:after {
  background-color: #6A6A6A;
}
.page-article-detail__forecast.forecast-theme__light .join-the-conversation__connect-icon-link:before {
  color: #6A6A6A;
}
.page-article-detail__forecast.forecast-theme__light .related-areas-link,
.page-article-detail__forecast.forecast-theme__light .attachments-list-link,
.page-article-detail__forecast.forecast-theme__light .article-intro-author {
  position: relative;
  transition: none;
  color: #6A6A6A;
  display: inline-block;
}
.page-article-detail__forecast.forecast-theme__light .related-areas-link:before,
.page-article-detail__forecast.forecast-theme__light .attachments-list-link:before,
.page-article-detail__forecast.forecast-theme__light .article-intro-author:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0%;
  bottom: -1px;
  left: 0;
  height: 1px;
  background-color: #5B5B5B;
  transition: 0.35s;
}
.page-article-detail__forecast.forecast-theme__light .related-areas-link:hover,
.page-article-detail__forecast.forecast-theme__light .attachments-list-link:hover,
.page-article-detail__forecast.forecast-theme__light .article-intro-author:hover {
  color: #5B5B5B;
}
.page-article-detail__forecast.forecast-theme__light .related-areas-link:hover:before,
.page-article-detail__forecast.forecast-theme__light .attachments-list-link:hover:before,
.page-article-detail__forecast.forecast-theme__light .article-intro-author:hover:before {
  width: 100%;
}
@media screen and (max-width: 47.4375em) {
  .page-article-detail__forecast.forecast-theme__light .related-areas-link:before,
  .page-article-detail__forecast.forecast-theme__light .attachments-list-link:before,
  .page-article-detail__forecast.forecast-theme__light .article-intro-author:before {
    display: none;
  }
  .page-article-detail__forecast.forecast-theme__light .related-areas-link:hover:before,
  .page-article-detail__forecast.forecast-theme__light .attachments-list-link:hover:before,
  .page-article-detail__forecast.forecast-theme__light .article-intro-author:hover:before {
    display: none;
  }
}
.page-article-detail__forecast.forecast-theme__light .scrollspy-nav-toggle {
  background-color: #6A6A6A;
}
.page-article-detail__forecast.forecast-theme__light blockquote:before, .page-article-detail__forecast.forecast-theme__light blockquote:after,
.page-article-detail__forecast.forecast-theme__light .blockquote:before,
.page-article-detail__forecast.forecast-theme__light .blockquote:after {
  border-top-color: #6A6A6A;
}

.attachments-list-option + .attachments-list-option {
  margin-top: 1.375em;
}

.attachments-list-link {
  position: relative;
  transition: none;
  color: #CC092F;
  display: inline-block;
  font-size: 1.25em;
  font-weight: 700;
}
.attachments-list-link:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0%;
  bottom: -1px;
  left: 0;
  height: 1px;
  background-color: #5B5B5B;
  transition: 0.35s;
}
.attachments-list-link:hover {
  color: #5B5B5B;
}
.attachments-list-link:hover:before {
  width: 100%;
}
@media screen and (max-width: 47.4375em) {
  .attachments-list-link:before {
    display: none;
  }
  .attachments-list-link:hover:before {
    display: none;
  }
}

@media screen and (max-width: 56.1875em) {
  .attachments-list-link {
    font-size: 1.125em;
  }
}
.related-areas-list-option {
  font-size: 1.25em;
}
.related-areas-list-option + .related-areas-list-option {
  margin-top: 0.5em;
}

.related-areas-link {
  position: relative;
  transition: none;
  color: #CC092F;
  display: inline-block;
  font-weight: 700;
}
.related-areas-link:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0%;
  bottom: -1px;
  left: 0;
  height: 1px;
  background-color: #5B5B5B;
  transition: 0.35s;
}
.related-areas-link:hover {
  color: #5B5B5B;
}
.related-areas-link:hover:before {
  width: 100%;
}
@media screen and (max-width: 47.4375em) {
  .related-areas-link:before {
    display: none;
  }
  .related-areas-link:hover:before {
    display: none;
  }
}
.related-areas-link:after {
  content: ",";
  color: #3D3C3C;
  font-weight: 400;
}
.related-areas-link:last-child:after {
  content: "";
}

.related-areas-topic:after {
  content: ",";
}
.related-areas-topic:last-child:after {
  content: "";
}

@media screen and (max-width: 56.1875em) {
  .related-areas-list {
    margin-top: 2.5em;
  }
  .related-areas-list-option {
    font-size: 1.125em;
  }
}
.sticky-share {
  max-width: 100.3125em;
  padding-left: 1.875em;
  padding-right: 1.875em;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
@media screen and (max-width: 56.1875em) {
  .sticky-share {
    padding-left: 1em;
    padding-right: 1em;
  }
}

.sticky-share-sections .silent-wrapper {
  max-width: 63.75em;
  padding-left: 1.875em;
  padding-right: 1.875em;
  margin-left: auto;
  margin-right: auto;
  margin-left: 17.1875em;
  padding-left: 0;
  padding-right: 0;
}
@media screen and (max-width: 56.1875em) {
  .sticky-share-sections .silent-wrapper {
    padding-left: 1em;
    padding-right: 1em;
  }
}
.sticky-share-sections .silent-wrapper--centered {
  margin-left: auto;
}

@media screen and (max-width: 67.125em) {
  .sticky-share-sections .silent-wrapper {
    margin-left: 11.25em;
  }
  .sticky-share-sections .silent-wrapper--centered {
    margin-left: auto;
  }
}
@media screen and (max-width: 56.1875em) {
  .sticky-share-sections .silent-wrapper {
    margin-left: 7.5em;
    padding-left: 0;
    padding-right: 0;
  }
  .sticky-share-sections .silent-wrapper--centered {
    margin-left: auto;
  }
}
@media screen and (max-width: 47.4375em) {
  .sticky-share .share-tools {
    margin: 5em 0;
  }
  .sticky-share-sections .silent-wrapper {
    margin-left: 0;
  }
}
.page-article-detail__forecast .sticky-share-sections .silent-wrapper {
  max-width: 100.3125em;
  padding-left: 1.875em;
  padding-right: 1.875em;
  margin-left: auto;
  margin-right: auto;
  margin-left: 5.625em;
}
@media screen and (max-width: 56.1875em) {
  .page-article-detail__forecast .sticky-share-sections .silent-wrapper {
    padding-left: 1em;
    padding-right: 1em;
  }
}
@media screen and (max-width: 67.125em) {
  .page-article-detail__forecast .sticky-share-sections .silent-wrapper {
    margin-left: 4.375em;
  }
}
@media screen and (max-width: 56.1875em) {
  .page-article-detail__forecast .sticky-share-sections .silent-wrapper {
    margin-left: 4.375em;
  }
}
@media screen and (max-width: 47.4375em) {
  .page-article-detail__forecast .sticky-share .share-tools {
    margin: 5em 0;
  }
  .page-article-detail__forecast .sticky-share-sections .silent-wrapper {
    margin-left: 0;
  }
}

.impactful-cta {
  position: relative;
  text-align: center;
  min-height: 12.5em;
  padding-left: 3.75em;
  padding-right: 3.75em;
}
.impactful-cta + .layout-module {
  margin-top: 6.875em;
}
.impactful-cta.is-hovered .angled-background-image {
  transform: scale(1.2) skewY(6deg);
}

.impactful-cta-intro {
  margin: 0 auto 1.0416666667em;
  max-width: 33.3333333333em;
}

.impactful-cta-link-external {
  font-weight: 800;
  font-size: 2.25em;
  position: relative;
  transition: none;
  color: #CC092F;
  display: inline-block;
  display: inline;
  padding-right: 1.4166666667em;
}
.impactful-cta-link-external:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0%;
  bottom: -1px;
  left: 0;
  height: 1px;
  background-color: #5B5B5B;
  transition: 0.35s;
}
.impactful-cta-link-external:hover {
  color: #5B5B5B;
}
.impactful-cta-link-external:hover:before {
  width: 100%;
}
@media screen and (max-width: 47.4375em) {
  .impactful-cta-link-external:before {
    display: none;
  }
  .impactful-cta-link-external:hover:before {
    display: none;
  }
}
.impactful-cta-link-external:after {
  content: "\e90b";
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-left: 0.75em;
  font-size: 0.6666666667em;
  position: absolute;
  margin-top: 0.4em;
}

.impactful-cta-link .ico {
  padding-left: 0.75em;
  vertical-align: middle;
}
.impactful-cta-link--external-small {
  margin-top: 1em;
  margin-right: 1em;
}

.impactful-cta-wrapper {
  max-width: 81.875em;
  padding-left: 2.8125em;
  padding-right: 2.8125em;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 5em 3.75em;
}
@media screen and (max-width: 56.1875em) {
  .impactful-cta-wrapper {
    padding-left: 1.25em;
    padding-right: 1.25em;
  }
}

.impactful-cta--small {
  padding-top: 6.7vw;
  padding-bottom: 6.7vw;
}
.impactful-cta--small .section-header {
  margin-bottom: 1.5625em;
}
.impactful-cta--small .impactful-cta-wrapper {
  max-width: 58.75em;
  padding: 3.125em 2.34375em;
}
.impactful-cta--small .impactful-cta-intro {
  margin-bottom: 1.25em;
}

@media screen and (max-width: 56.1875em) {
  .impactful-cta {
    padding-left: 1.875em;
    padding-right: 1.875em;
  }
  .impactful-cta-wrapper {
    padding-left: 1.875em;
    padding-right: 1.875em;
  }
  .impactful-cta-link-external {
    font-size: 1.625em;
  }
  .impactful-cta-intro {
    margin: 0 auto 1.25em;
  }
  .impactful-cta--small .impactful-cta-wrapper {
    padding-left: 1.875em;
    padding-right: 1.875em;
  }
}
@media screen and (max-width: 47.4375em) {
  .impactful-cta {
    padding-left: 1em;
    padding-right: 1em;
  }
  .impactful-cta .section-header {
    margin-bottom: 2.5em;
  }
  .impactful-cta-wrapper {
    padding: 2.5em 1em;
  }
  .impactful-cta--small .section-header {
    margin-bottom: 1.5625em;
  }
}
.awards-sections-copy {
  margin-bottom: 4em;
}

.awards-sections-tiles {
  margin-bottom: 5em;
}
.awards-sections-tiles .section-card + .section-card {
  margin-top: 1.5625em;
}
.awards-sections-tiles .section-card-header {
  min-height: 28.75em;
}

@media screen and (max-width: 67.125em) {
  .awards-sections-tiles .section-card-header {
    min-height: 50vw;
  }
}
@media screen and (max-width: 56.1875em) {
  .awards-sections-copy {
    margin-bottom: 2.6666666667em;
  }
  .awards-sections-tiles {
    margin-bottom: 2.5em;
  }
  .awards-sections-tiles .section-card + .section-card {
    margin-top: 0.625em;
  }
}
@media screen and (max-width: 47.4375em) {
  .awards-sections-tiles .section-card-header {
    min-height: 215px;
  }
}
.featured-articles .section-header {
  margin-bottom: 2.1875rem;
}

.featured-articles-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -0.46875em;
  margin-right: -0.46875em;
}
.featured-articles-grid .article-tile {
  width: calc(50% - 0.9375em);
  margin-left: 0.46875em;
  margin-right: 0.46875em;
  margin-bottom: 0.9375em;
}

@media screen and (min-width: 56.25em) {
  .featured-articles-grid .article-tile.prominent .article-tile-heading {
    display: block;
  }
  .featured-articles-grid .article-tile-card {
    margin-bottom: 8rem;
    width: 19em;
  }
  .featured-articles-grid .featured-articles-grid {
    width: calc(50% - 1em);
    width: calc(50% - 0.9375em);
    margin-left: 0.46875em;
    margin-right: 0.46875em;
    margin-bottom: 0.9375em;
    flex-direction: column;
  }
  .featured-articles-grid .featured-articles-grid .article-tile {
    width: auto;
    margin: 0;
    display: flex;
  }
  .featured-articles-grid .featured-articles-grid .article-tile .article-tile-heading {
    height: auto;
    width: 100%;
  }
  .featured-articles-grid .featured-articles-grid .article-tile:last-child {
    margin-top: 0.9375em;
  }
}
@media screen and (max-width: 56.1875em) {
  .featured-articles .section-header {
    margin-bottom: 2.5em;
  }
  .featured-articles-grid {
    display: block;
    margin-left: 0;
    margin-right: 0;
  }
  .featured-articles-grid .article-tile {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}
.search-landing-listing {
  margin-bottom: 6.875em;
}

@media screen and (max-width: 56.1875em) {
  .search-landing-listing {
    margin-bottom: 5em;
  }
}
.location-header {
  padding-bottom: 0.75em;
  min-height: inherit;
  margin-bottom: 10.3125em;
}

.location-header-card {
  clear: both;
  margin: 0 auto;
  max-width: 80em;
  background-color: #222;
  height: 28.125em;
  position: relative;
  margin-top: 9em;
}
.location-header-card:after {
  content: "";
  display: table;
  clear: both;
}
.location-header-card:after {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  content: "";
  display: block;
  box-shadow: 0 0px 15px 2px rgba(0, 0, 0, 0.6);
  z-index: -1;
}
.location-header-card .skip-to {
  color: #000000;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.location-header-details,
.location-header-map-control {
  width: 50%;
  height: 100%;
  position: relative;
  float: left;
}

.location-header-details {
  background: transparent no-repeat center center;
  background-size: cover;
  text-align: left;
  color: #fff;
  padding: 2.5em;
  padding-right: 210px;
}
.location-header-details .clock {
  position: absolute;
  right: 2.5em;
  top: 2.5em;
  transform: translateY(0);
  left: auto;
}
.location-header-details:before {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  content: "";
  background-color: rgba(0, 0, 0, 0.6);
}

.location-header-name {
  font-weight: 800;
  font-size: 2.25em;
  position: relative;
  line-height: 1.2;
}

.location-header-address {
  font-size: 1.625em;
  line-height: 1.4;
  font-style: normal;
  position: relative;
}
.location-header-address .address-item {
  display: block;
}

.location-header-address-contact {
  position: absolute;
  font-size: 1.625em;
  line-height: 1.4;
  bottom: 0.9615384615em;
}
.location-header-address-contact .phone:after {
  content: "";
  display: block;
}

.location-header-map-control {
  overflow: hidden;
}
.location-header-map-control .map {
  height: calc(100% - 5.625em);
}

.location-header-map-search {
  display: flex;
  flex-wrap: nowrap;
  padding: 1.25em;
  height: 5.625em;
}
.location-header-map-search .location-searchbox-input {
  height: 100%;
}
.location-header-map-search .location-searchbox {
  margin-right: 1em;
  flex-grow: 1;
  height: 100%;
}

.location-header-get-directions {
  width: 12.5em;
  padding-left: 0.625em;
  padding-right: 0.625em;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.location-header-get-directions .btn-ghost-label {
  display: table-cell;
  vertical-align: middle;
}
.location-header-get-directions .btn-ghost-label:after {
  content: "\e90b";
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-left: 0.5em;
  font-size: 0.85em;
}

@media screen and (max-width: 62.4375em) {
  .location-header-card {
    height: auto;
  }
  .location-header-details,
  .location-header-map-control {
    width: 100%;
    height: 100%;
    float: none;
    height: 28.125em;
  }
}
@media screen and (max-width: 56.1875em) {
  .location-header {
    margin-bottom: 2.5em;
  }
  .location-header .svg-logo-link {
    margin-bottom: 2.5em;
  }
  .location-header-card {
    margin-top: 5em;
  }
  .location-header-name {
    font-size: 1.75em;
  }
  .location-header-details {
    padding: 1.5625em;
    padding-right: 210px;
  }
  .location-header-address,
  .location-header-address-contact {
    font-size: 1.25em;
  }
  .location-header-address-contact {
    font-size: 1.25em;
    bottom: 1.25em;
  }
}
@media screen and (max-width: 81.1875em) {
  .location-header-details {
    padding-right: 175px;
  }
  .location-header-address {
    font-size: 1.5em;
  }
}
@media screen and (max-width: 47.4375em) {
  .location-header-card {
    margin-top: 3.75em;
  }
  .location-header-map-control {
    height: auto;
  }
  .location-header-map-control .map, .location-header-map-control .location-searchbox {
    display: none;
  }
  .location-header-map-control .location-header-get-directions {
    width: 100%;
  }
  .location-header-map-control .location-header-map-search {
    display: block;
  }
  .location-header-details {
    height: 25em;
  }
}
@media screen and (max-width: 39.3125em) {
  .location-header .clock {
    display: none;
  }
  .location-header-details {
    padding-right: 25px;
  }
}
.reader-progress {
  width: 100vw;
  left: 0;
  margin-top: -6.0625em;
  z-index: 54;
  position: absolute;
}
.reader-progress__sticky-bar {
  position: absolute;
  width: 100%;
  height: 12px;
  background-color: #fff;
  position: absolute;
  border-top: thin solid #fff;
}
.reader-progress--sticky .reader-progress__sticky-bar {
  position: fixed;
  top: 64px;
  margin-top: 0;
}
.reader-progress__bar {
  height: 100%;
  background-color: #222;
  width: 20px;
  transition: width 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
@media screen and (max-width: 64em) {
  .reader-progress {
    margin-top: -40px;
  }
  .reader-progress__sticky-bar {
    position: fixed;
    top: 68px;
    height: 10px;
  }
  .siteheader--unpinned ~ .scrollspy-detail .reader-progress__sticky-bar {
    top: 0;
  }
  .scrollspy-nav.is-sticky ~ .reader-progress--sticky .reader-progress__sticky-bar {
    top: 44px;
  }
}

.forecast-carousel {
  height: 30em;
  width: 90%;
  margin: 0 auto;
  position: absolute;
  z-index: 1;
  margin-top: 8em;
  left: 50%;
  transform: translateX(-50%);
  -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
}
.forecast-carousel .hero-carousel__button {
  position: initial !important;
  right: 0 !important;
}
.forecast-carousel .hero-carousel__button {
  right: auto;
}
.forecast-carousel .flickity-page-dots {
  padding-top: 1rem;
  position: initial;
  width: auto;
}
.forecast-carousel .flickity-page-dots .is-selected.forecast__red {
  background: #cd122d;
}
.forecast-carousel__controls {
  bottom: 0.75rem;
  display: none;
  height: 2.75rem;
  position: absolute;
  right: 3.75em;
}
.forecast-carousel__controls .is-paused .svg-icon--play.svg-icon--play {
  display: inline-block;
}
.forecast-carousel__controls .is-active .svg-icon--play.svg-icon--play {
  display: none;
}
.forecast-carousel__controls .svg-icon {
  vertical-align: text-top;
}
.forecast-carousel__slide {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-position-x: 80%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.forecast-carousel__text-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 75%;
  background: transparent; /* For browsers that do not support gradients */
  background: linear-gradient(to top, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0));
  padding: 2.1875em;
}
.forecast-carousel__title {
  font-weight: 400;
  font-size: 1.125em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  line-height: 28px;
  text-align: center;
}
.forecast-carousel__body {
  font-weight: 400;
  font-size: 1em;
  transition: font-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  line-height: 28px;
  width: 55%;
  display: none;
}
.forecast-carousel__button-ghost {
  display: inline-block;
  font-weight: 700;
  font-size: 14px;
  width: 100%;
  padding: 0.75em 1.5em;
  margin-top: 1.875em;
  border: 1px solid #cd122d;
  border-radius: 0;
  overflow: hidden;
  position: relative;
  text-align: center;
  color: #cd122d;
  background-color: #fff;
  backface-visibility: hidden;
  transform: translateZ(0);
}
.forecast-carousel__button-ghost:hover {
  color: #fff;
}
.forecast-carousel__button-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.forecast-carousel__button-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #cd122d;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.forecast-carousel__button-ghost .btn-ghost-label--video:after {
  content: "\e90e";
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-left: 0.5em;
  margin-top: 0.1em;
}
.forecast-carousel__slides-belt {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.forecast-carousel__mobile-btn-container {
  display: flex;
  flex-direction: row;
  position: relative;
}
.forecast-carousel__mobile-btn-container .svg-icon.forecast__red {
  color: #fff;
}
.forecast-carousel__trigger-btn {
  background-color: #cd122d;
  color: #fff;
  width: 33%;
  height: 3.4375em;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -webkit-border-radius: 0px;
  border-right: 1px solid #fff;
  transition: all ease-in-out 0.15s;
}
.forecast-carousel__trigger-btn .hidden {
  display: none;
}
.forecast-carousel__trigger-btn:last-child {
  border-right: 0;
}
.forecast-carousel__trigger-btn:focus {
  z-index: 2;
}
.forecast-carousel__trigger-btn.diversity-carousel__trigger-btn--pause {
  background-color: #fff !important;
}
.forecast-carousel__lg-trigger-btn {
  display: none;
  background-color: #cd122d;
  color: #fff;
  width: 75px;
  height: 210px;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -webkit-border-radius: 0px;
  border: none;
  transition: all ease-in-out 0.15s;
  -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
}
.forecast-carousel__lg-trigger-btn:hover {
  background-color: #d74157;
}
.forecast-carousel--topics {
  position: relative;
  transform: none;
  left: unset;
  bottom: 0;
  margin-bottom: 7.1875em;
}
@media screen and (min-width: 47.5em) {
  .forecast-carousel {
    margin-top: 10em;
  }
  .forecast-carousel--topics {
    bottom: 1.5625em;
  }
}
@media screen and (min-width: 64em) {
  .forecast-carousel {
    width: 75%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .forecast-carousel__slides-belt {
    position: absolute;
  }
  .forecast-carousel__mobile-btn-container {
    display: none;
  }
  .forecast-carousel__controls {
    display: inline-flex;
  }
  .forecast-carousel__button-ghost {
    width: 12.8125em;
  }
  .forecast-carousel__text-container {
    height: 100%;
    justify-content: space-between;
    align-items: flex-start;
    padding: 3.75em;
    background: transparent;
  }
  .forecast-carousel__title-body {
    width: 60%;
  }
  .forecast-carousel__body {
    width: 100%;
  }
  .forecast-carousel__title {
    font-weight: 300;
    font-size: 2em;
    color: #181818;
    line-height: 42px;
    text-align: left;
    width: 90%;
    padding-bottom: 15px;
  }
  .forecast-carousel__body {
    display: block;
    color: #181818;
  }
  .forecast-carousel__lg-trigger-btn {
    display: block;
    position: relative;
  }
  .forecast-carousel__lg-trigger-btn--back {
    left: -4.4em;
    z-index: -1;
  }
  .forecast-carousel__lg-trigger-btn--forward {
    left: 4.5em;
    z-index: -1;
  }
}
@media screen and (min-width: 75em) {
  .forecast-carousel {
    width: 64%;
  }
}
.forecast-carousel__backing {
  max-width: 100.3125em;
  padding-left: 1.875em;
  padding-right: 1.875em;
  margin-left: auto;
  margin-right: auto;
  background-color: #404040;
  height: 44em;
}
@media screen and (max-width: 56.1875em) {
  .forecast-carousel__backing {
    padding-left: 1em;
    padding-right: 1em;
  }
}
.forecast-carousel__backing-title {
  font-size: 2.25em;
  color: #fff;
  text-align: center;
  padding: 0.78125em 0;
}
@media screen and (min-width: 56.25em) {
  .forecast-carousel__backing-title {
    padding: 1.5625em 0;
  }
}
.forecast-carousel__backing-image {
  position: absolute;
  height: 42em;
  margin-top: -9em;
  right: 0;
  opacity: 0.05;
}

.slide-enter-active {
  transition: 400ms;
}

.slide-enter.forward {
  transform: translateX(100px);
  opacity: 0;
}

.slide-enter.backward {
  transform: translateX(-100px);
  opacity: 0;
}

.contact-methods-list {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.9375em;
}

.contact-methods-option {
  margin-top: 0.9375em;
  padding-right: 0.9375em;
  width: 33.3333%;
  flex-grow: 1;
}

.contact-methods-link {
  display: block;
  min-width: inherit;
}

@media screen and (max-width: 56.1875em) {
  .contact-methods-option {
    width: 50%;
    flex-grow: 0;
  }
}
@media screen and (max-width: 47.4375em) {
  .contact-methods-list {
    display: block;
    margin-right: 0;
  }
  .contact-methods-option {
    padding-right: 0;
    width: 100%;
  }
}
.site-search-results-listing {
  margin-bottom: 6.875em;
}

.site-search-results-listing-approximation {
  margin-bottom: 5em;
  display: block;
}

@media screen and (max-width: 56.1875em) {
  .site-search-results-listing {
    margin-bottom: 5em;
  }
  .site-search-results-listing-approximation {
    margin-bottom: 2.5em;
  }
}
.hide-cookie {
  display: none;
}

.page-homepage main {
  overflow-x: visible;
}

.page-professionals-landing .silent-wrapper {
  max-width: 81.875em;
  padding-left: 2.8125em;
  padding-right: 2.8125em;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 56.1875em) {
  .page-professionals-landing .silent-wrapper {
    padding-left: 1.25em;
    padding-right: 1.25em;
  }
}
.page-professionals-landing .criteria-shown {
  visibility: visible;
  opacity: initial;
}
.page-professionals-landing .advanced-filters-category {
  width: 16.6666666667%;
}
.page-professionals-landing .advanced-filters-category:last-of-type {
  padding-right: 0;
}
.page-professionals-landing .search-landing-filters:focus {
  outline: none;
}
.page-professionals-landing .search-landing-filters .advanced-filters {
  width: 100%;
}
.page-professionals-landing .search-landing-filters-submit {
  position: relative;
  margin-left: 0.75rem;
  padding: 12px 24px;
}
@media screen and (max-width: 56.1875em) {
  .page-professionals-landing .advanced-filters-category {
    width: 50%;
  }
}
@media screen and (max-width: 47.4375em) {
  .page-professionals-landing .advanced-filters-category {
    width: 100%;
  }
  .page-professionals-landing .search-landing-filters-submit {
    margin-left: 0;
  }
}

.page-capabilities-landing .silent-wrapper {
  max-width: 81.875em;
  padding-left: 2.8125em;
  padding-right: 2.8125em;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 56.1875em) {
  .page-capabilities-landing .silent-wrapper {
    padding-left: 1.25em;
    padding-right: 1.25em;
  }
}
.page-capabilities-landing .criteria-shown {
  opacity: initial;
  visibility: initial;
}
.page-capabilities-landing .industry-list--reverse {
  padding-bottom: 4%;
}
.page-capabilities-landing .global-solutions {
  padding-top: 5em;
}
.page-capabilities-landing .global-solutions .services__bg {
  background-image: url("../images/capabilities-lines.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.page-capabilities-landing .global-solutions__header {
  display: flex;
  align-items: center;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
}
.page-capabilities-landing .global-solutions__image {
  width: 30%;
  min-width: 230px;
}
.page-capabilities-landing .global-solutions__description {
  max-width: 900px;
  margin: 3rem auto;
  text-align: center;
  line-height: 1.4em;
  font-size: 18px;
}
.page-capabilities-landing .global-solutions__button-wrapper {
  margin: 3rem 0;
  text-align: center;
  max-width: 100%;
}
.page-capabilities-landing .global-solutions__button {
  display: inline-block;
}
.page-capabilities-landing .global-solutions__line {
  flex: 1 1 auto;
  height: 1px;
  background: #000;
}
@media screen and (max-width: 992px) {
  .page-capabilities-landing .global-solutions {
    background: none;
  }
}
@media screen and (min-width: 47.5em) {
  .page-capabilities-landing #search-top {
    margin-top: 3em;
  }
}
@media screen and (max-width: 56.1875em) {
  .page-capabilities-landing .industry-list--reverse {
    padding-bottom: 0;
  }
  .page-capabilities-landing .global-solutions__description {
    text-align: left;
  }
}
@media screen and (max-width: 47.4375em) {
  .page-capabilities-landing .page-header {
    text-align: center;
  }
}

.page-professional-detail .capabilities-compact:before {
  display: none;
}
.page-professional-detail .capabilities-compact:after {
  height: 100%;
}
@media screen and (max-width: 47.4375em) {
  .page-professional-detail .layout-column-wrapper {
    padding: 0;
  }
  .page-professional-detail .layout-column-main, .page-professional-detail .narrative-overview,
  .page-professional-detail .related-experience {
    padding-left: 1em;
    padding-right: 1em;
  }
  .page-professional-detail .perspectives-news-events {
    margin-top: 0;
  }
  .page-professional-detail .related-experience {
    margin-top: 5em;
  }
}
.page-experience-landing .silent-wrapper {
  max-width: 81.875em;
  padding-left: 2.8125em;
  padding-right: 2.8125em;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 56.1875em) {
  .page-experience-landing .silent-wrapper {
    padding-left: 1.25em;
    padding-right: 1.25em;
  }
}
.page-experience-landing .experience-results-listing .silent-wrapper {
  max-width: 63.75em;
  padding-left: 1.875em;
  padding-right: 1.875em;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 56.1875em) {
  .page-experience-landing .experience-results-listing .silent-wrapper {
    padding-left: 1em;
    padding-right: 1em;
  }
}
.page-experience-landing .criteria-shown {
  opacity: initial;
  visibility: initial;
}
.page-experience-landing .advanced-filters-category {
  width: 33.3334%;
}
.page-experience-landing .search-entity-landing {
  margin-bottom: 5em;
}
@media screen and (max-width: 56.1875em) {
  .page-experience-landing .advanced-filters-category {
    width: 50%;
  }
}
@media screen and (max-width: 47.4375em) {
  .page-experience-landing .advanced-filters-category {
    width: 100%;
  }
  .page-experience-landing .page-header {
    text-align: center;
  }
}

.page-capability-detail .read-more-toggle {
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.page-capability-detail .inner-content {
  padding-bottom: 0;
}
.page-capability-detail .angled-hero {
  margin-bottom: 5em;
}
.page-capability-detail .flexible-panel-overview .silent-wrapper {
  max-width: 100.3125em;
  padding-left: 1.875em;
  padding-right: 1.875em;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 56.1875em) {
  .page-capability-detail .flexible-panel-overview .silent-wrapper {
    padding-left: 1em;
    padding-right: 1em;
  }
}
@media screen and (max-width: 64em) {
  .page-capability-detail .angled-hero {
    margin-bottom: 0;
  }
}
.page-locations-landing .search-landing-filters-submit {
  position: relative;
  right: auto;
  bottom: auto;
}
.page-locations-landing .search-landing-filters-submit .btn-ghost-label {
  padding-left: 0;
}
.page-locations-landing .silent-wrapper {
  max-width: 81.875em;
  padding-left: 2.8125em;
  padding-right: 2.8125em;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 56.1875em) {
  .page-locations-landing .silent-wrapper {
    padding-left: 1.25em;
    padding-right: 1.25em;
  }
}
@media screen and (max-width: 47.4375em) {
  .page-locations-landing .search-landing-filters-submit {
    width: 3em;
    padding-left: 0.2em;
    padding-right: 0.2em;
  }
}

.page-location-detail .scrollspy .scrollspy-sections:last-child {
  margin-bottom: 8vw;
}
.page-location-detail .read-more-toggle {
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.page-location-detail .perspectives-news-events {
  margin-bottom: 0;
}
.page-location-detail .perspectives-news-events + .impactful-cta {
  margin-top: 0;
}
.page-location-detail .perspectives-news-events + .around-office {
  margin-top: 8vw;
}

@media screen and (max-width: 62.4375em) {
  .page-location-detail .perspectives-news-events + .around-office {
    margin-top: 12vw;
  }
}
@media screen and (max-width: 47.4375em) {
  .page-location-detail .perspectives-news-events + .around-office {
    margin: 5em 0;
  }
}
.page-general-content #ss-embed-frame {
  width: 1px;
  min-width: 100%;
  border: 0px;
}
.page-general-content .narrative-overview {
  margin-bottom: 0;
}

@media screen and (max-width: 47.4375em) {
  .page-general-content .narrative-overview {
    margin-bottom: 5em;
  }
}
.page-articles-landing .listing-articles-item:last-child {
  border-bottom: 0;
}
.page-articles-landing .search-entity-landing .listing-articles-item:last-child {
  padding-bottom: 0;
}

.page-site-search-results .listing-articles-item:last-child {
  border-bottom: 0;
}
.page-site-search-results .keyword-search-grid-option {
  width: 50%;
}
@media screen and (max-width: 47.4375em) {
  .page-site-search-results .keyword-search-grid-option.has-dropdown {
    width: 100%;
  }
}

.page-german-recruitment-detail #accordion-section .flexible-accordions-rich-text {
  padding-bottom: 2.5rem;
}

.page-global-solutions .angled-hero {
  margin-bottom: 5em;
  padding-top: 10em;
  padding-bottom: 10em;
  position: relative;
}
@media screen and (max-width: 992px) {
  .page-global-solutions .angled-hero {
    margin-bottom: 0;
    padding-top: 3em;
    padding-bottom: 3em;
  }
}
.page-global-solutions .angled-hero__bg {
  position: absolute;
  background-size: cover;
  background-position: center 0px;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.page-global-solutions .angled-hero__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}
.page-global-solutions .scrollspy-nav {
  background: #E76D25;
}
.page-global-solutions .scrollspy-nav .scrollspy-nav-option.is-inview .scrollspy-nav-label {
  color: #fff;
}
.page-global-solutions .scrollspy-nav .scrollspy-nav-link:hover .scrollspy-nav-label {
  color: #fff;
}
.page-global-solutions .scrollspy-nav .scrollspy-nav-label {
  background: #E76D25;
  color: #3D3C3C;
}
@media screen and (max-width: 64em) {
  .page-global-solutions .scrollspy-nav {
    background: none;
  }
  .page-global-solutions .scrollspy-nav .scrollspy-nav-toggle {
    background: #E76D25;
  }
  .page-global-solutions .scrollspy-nav .scrollspy-nav-label {
    background: #3D3C3C;
    color: #E5B522;
  }
}
.page-global-solutions .professional-card-link,
.page-global-solutions .professional-card-office {
  color: #E76D25;
}
.page-global-solutions .read-more .btn-ghost,
.page-global-solutions .related-professionals .btn-ghost {
  color: #E76D25;
  border-color: #E76D25;
}
.page-global-solutions .read-more .btn-ghost:hover,
.page-global-solutions .related-professionals .btn-ghost:hover {
  color: white;
}
.page-global-solutions .read-more .btn-ghost:after,
.page-global-solutions .related-professionals .btn-ghost:after {
  background-color: #E76D25;
}
.page-global-solutions .our-technology {
  padding: 3em 0;
  background-image: url("../images/Product_list_background.jpg");
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  color: #fff;
  text-align: center;
}
.page-global-solutions .our-technology__header {
  font-size: 2.25em;
  margin-bottom: 1em;
}
.page-global-solutions .our-technology__description {
  font-family: "Lora", serif;
  margin-bottom: 1em;
}
.page-global-solutions .our-technology__links {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
.page-global-solutions .our-technology__link {
  font-size: 1.5em;
  width: calc(50% - 0.75em);
  padding: 1.25em 0;
  border-bottom: 2px solid #fff;
  text-align: left;
}
@media screen and (max-width: 768px) {
  .page-global-solutions .our-technology__link {
    width: 100%;
  }
}
.page-global-solutions .products {
  margin: 5em 0;
}
.page-global-solutions .products__item {
  background-color: #f3f3f3;
  padding: 2em;
  margin-bottom: 2.5em;
  border: none;
}
.page-global-solutions .products__item .professional-card-link,
.page-global-solutions .products__item .professional-card-office {
  color: #E76D25;
}
.page-global-solutions .products__header {
  margin-bottom: 2rem;
}
.page-global-solutions .products__header .accordion-node-trigger {
  font-size: 2em;
  color: #3D3C3C;
}
.page-global-solutions .products__header .accordion-node-trigger::before {
  font-size: 30px;
  background-color: #E76D25;
}
.page-global-solutions .products__header .accordion-node-trigger:hover::before {
  background-color: #3D3C3C;
}
.page-global-solutions .products__subtitle {
  font-size: 1.5em;
  font-weight: 800;
  margin-bottom: 2rem;
}
.page-global-solutions .products__view-more {
  line-height: 1.6em;
  margin-bottom: 2rem;
}
.page-global-solutions .products__view-more .professional-card {
  margin-top: 2rem;
}
.page-global-solutions .products__description {
  font-family: "Lora", serif;
  margin-bottom: 2.5em;
}
.page-global-solutions .products__button {
  background: #fff;
  margin-bottom: 2rem;
}
.page-global-solutions .products .products-dropdown {
  margin-bottom: 1.375em;
  display: flex;
  align-items: center;
}
.page-global-solutions .products .products-dropdown__label {
  margin-right: 1em;
  font-weight: 800;
  width: 60%;
}
.page-global-solutions .products .products-dropdown__container {
  position: relative;
  width: 100%;
}
.page-global-solutions .products .products-dropdown__button {
  position: relative;
  width: 100%;
  text-align: left;
  background-color: #E5B522;
  padding: 0.9375em;
  padding-right: 1.875em;
  font-weight: 800;
}
.page-global-solutions .products .products-dropdown__button:after {
  content: "\e900";
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.625em;
  right: 1.5em;
}
.page-global-solutions .products .products-dropdown__items {
  position: absolute;
  top: 100%;
  width: 100%;
  background-color: #3d3c3c;
  color: #fff;
  padding: 1.5625em;
  opacity: 0;
  visibility: hidden;
  max-height: 21.875em;
  overflow: auto;
  z-index: 10;
}
.page-global-solutions .products .products-dropdown__items--active {
  opacity: 1;
  visibility: visible;
}
.page-global-solutions .products .products-dropdown__item:hover {
  cursor: pointer;
}
.page-global-solutions .products .products-dropdown__item:not(:first-of-type) {
  padding-top: 0.625em;
  margin-top: 0.625em;
  border-top: 1px solid hsla(0, 0%, 100%, 0.65);
}
@media screen and (max-width: 768px) {
  .page-global-solutions .products .products-dropdown {
    display: block;
  }
  .page-global-solutions .products .products-dropdown__label {
    display: block;
    width: 100%;
    margin: 0 0 0.5em 0;
  }
}
.page-global-solutions .case-studies {
  background-color: #ececec;
  padding: 5em 0;
}
.page-global-solutions .case-studies__flex {
  display: flex;
}
.page-global-solutions .case-studies__flex:not(:first-of-type) {
  border-top: 1px solid #ccc;
  margin-top: 5em;
  padding-top: 5em;
}
.page-global-solutions .case-studies__header {
  margin-bottom: 2rem;
  text-align: center;
  font-weight: 800;
  font-size: 2em;
}
.page-global-solutions .case-studies__image {
  min-width: 0;
  width: 100%;
  height: auto;
  flex: 0 0 25%;
  align-self: flex-start;
  padding: 0 3em 0 0;
}
.page-global-solutions .case-studies__image--tablet {
  display: none;
}
.page-global-solutions .case-studies__content {
  display: flex;
  flex: 1 1 75%;
  flex-direction: column;
}
.page-global-solutions .case-studies__columns {
  display: flex;
  margin-bottom: auto;
  padding-bottom: 1.5em;
}
.page-global-solutions .case-studies__column {
  flex-grow: 1;
  flex-basis: 0;
}
.page-global-solutions .case-studies__column:not(:last-of-type) {
  padding-right: 3em;
}
.page-global-solutions .case-studies__name {
  font-size: 2em;
  margin-bottom: 0.5em;
}
.page-global-solutions .case-studies__label {
  font-size: 1.5em;
  font-weight: 800;
  margin-bottom: 1em;
}
.page-global-solutions .case-studies__text {
  font-family: "Lora", serif;
  line-height: 1.5em;
}
.page-global-solutions .case-studies__button {
  align-self: flex-start;
  background: #fff;
}
.page-global-solutions .case-studies--right .case-studies__image {
  order: 2;
  padding: 0 0 0 3em;
}
@media screen and (max-width: 1200px) {
  .page-global-solutions .case-studies--right .case-studies__image, .page-global-solutions .case-studies__image {
    order: 0;
  }
  .page-global-solutions .case-studies--right .case-studies__image--tablet, .page-global-solutions .case-studies__image--tablet {
    display: block;
  }
  .page-global-solutions .case-studies--right .case-studies__image--desktop, .page-global-solutions .case-studies__image--desktop {
    display: none;
  }
  .page-global-solutions .case-studies__columns {
    flex-flow: row wrap;
    justify-content: space-between;
  }
  .page-global-solutions .case-studies--right .case-studies__image, .page-global-solutions .case-studies__image, .page-global-solutions .case-studies__column {
    flex-basis: calc(50% - 1.5em);
    flex-grow: 0;
    margin-bottom: 2em;
    padding: 0;
  }
  .page-global-solutions .case-studies--right .case-studies__image:not(:last-of-type), .page-global-solutions .case-studies__image:not(:last-of-type), .page-global-solutions .case-studies__column:not(:last-of-type) {
    padding-right: 0;
  }
}
@media screen and (max-width: 768px) {
  .page-global-solutions .case-studies__columns {
    display: block;
  }
}
.page-global-solutions .services {
  margin-top: 5em;
  margin-bottom: 5em;
}
.page-global-solutions .services .section-header {
  margin-bottom: 5em;
}
.page-global-solutions .services__bg {
  background-image: url("../images/capabilities-lines.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media screen and (max-width: 992px) {
  .page-global-solutions .services__bg {
    background-image: none !important;
  }
}
.page-global-solutions .global-solutions__logo--mobile {
  display: none;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 1em;
}
@media screen and (max-width: 64em) {
  .page-global-solutions .global-solutions__logo--mobile {
    display: block;
  }
}
.page-global-solutions .narrative-overview__column img {
  display: block;
}
@media screen and (max-width: 64em) {
  .page-global-solutions .narrative-overview__column img {
    display: none;
  }
}

.global-solutions-tiles .article-tile-card {
  background: rgba(231, 109, 37, 0.75);
  color: #fff;
  margin-bottom: 4rem;
}
.global-solutions-tiles .article-tile-title {
  font-weight: 600;
  margin-bottom: 1em;
}
.global-solutions-tiles .article-tile-abstract {
  font-family: "Lora", serif;
}
.global-solutions-tiles .article-tile-details::before {
  content: none;
}
.global-solutions-tiles .article-tile-read-more {
  transition: background-color 0.35s ease;
  display: inline-block;
  border: 2px solid #fff;
  padding: 0.4em 1.2em;
  text-transform: uppercase;
  font-weight: 600;
}
.global-solutions-tiles .article-tile-heading:hover .article-tile-read-more, .global-solutions-tiles .article-tile-heading:focus .article-tile-read-more {
  background-color: white;
  color: #E76D25;
}
.global-solutions-tiles--is-active {
  opacity: 0.6;
}
.global-solutions-tiles--is-active .article-tile-heading {
  cursor: default;
}
.global-solutions-tiles--is-active .article-tile-heading:hover .article-tile-read-more, .global-solutions-tiles--is-active .article-tile-heading:focus .article-tile-read-more {
  background-color: transparent;
  color: #fff;
}
.global-solutions-tiles--is-active:hover .hover-grow-fade-fx-img, .global-solutions-tiles--is-active:focus .hover-grow-fade-fx-img {
  transform: scale(1);
}

.narrative-overview__button-container {
  text-align: center;
}

.page-about-landing .angled-hero-intro {
  padding-bottom: 1.25em;
}

@media screen and (max-width: 81.1875em) {
  .page-about-landing .angled-hero {
    min-height: inherit;
    max-height: none;
  }
  .page-about-landing .angled-hero-intro {
    padding-bottom: 0;
  }
}
.page-careers-users .silent-wrapper,
.page-careers-offices .silent-wrapper,
.page-careers-profile .silent-wrapper,
.page-careers-gc .silent-wrapper {
  max-width: 63.75em;
  padding-left: 1.875em;
  padding-right: 1.875em;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 56.1875em) {
  .page-careers-users .silent-wrapper,
  .page-careers-offices .silent-wrapper,
  .page-careers-profile .silent-wrapper,
  .page-careers-gc .silent-wrapper {
    padding-left: 1em;
    padding-right: 1em;
  }
}
.page-careers-users .silent-wrapper--no-padding,
.page-careers-offices .silent-wrapper--no-padding,
.page-careers-profile .silent-wrapper--no-padding,
.page-careers-gc .silent-wrapper--no-padding {
  padding-left: 0;
  padding-right: 0;
}

.page-winrs-landing.blue-theme .winrs__button--hero {
  border: 1px solid #0373ac;
}
.page-winrs-landing.blue-theme .winrs__button--hero:after {
  background-color: #0373ac;
}
.page-winrs-landing.blue-theme .winrs-carousel__lg-trigger-btn {
  background-color: #0373ac;
}
.page-winrs-landing.blue-theme .winrs-carousel__trigger-btn {
  background-color: #0373ac;
}
.page-winrs-landing.blue-theme .winrs-carousel__button-ghost {
  border: 1px solid #0373ac;
  color: #0373ac;
}
.page-winrs-landing.blue-theme .winrs-carousel__button-ghost:after {
  background-color: #0373ac;
}
.page-winrs-landing.blue-theme .winrs-carousel__button-ghost:hover {
  color: #fff;
}
.page-winrs-landing.blue-theme .winrs__quote-bar {
  background-color: #0373ac !important;
}
.page-winrs-landing.red-theme .winrs__button--hero {
  border: 1px solid #CC092F;
}
.page-winrs-landing.red-theme .winrs__button--hero:after {
  background-color: #CC092F;
}
.page-winrs-landing.red-theme .winrs-carousel__lg-trigger-btn {
  background-color: #CC092F;
}
.page-winrs-landing.red-theme .winrs-carousel__trigger-btn {
  background-color: #CC092F;
}
.page-winrs-landing.red-theme .winrs-carousel__button-ghost {
  border: 1px solid #CC092F;
  color: #CC092F;
}
.page-winrs-landing.red-theme .winrs-carousel__button-ghost:after {
  background-color: #CC092F;
}
.page-winrs-landing.red-theme .winrs-carousel__button-ghost:hover {
  color: #fff;
}
.page-winrs-landing.red-theme .winrs__quote-bar {
  background-color: #CC092F !important;
}
.page-winrs-landing.green-theme .winrs__button--hero {
  border: 1px solid #1e9f48;
}
.page-winrs-landing.green-theme .winrs__button--hero:after {
  background-color: #1e9f48;
}
.page-winrs-landing.green-theme .winrs-carousel__lg-trigger-btn {
  background-color: #1e9f48;
}
.page-winrs-landing.green-theme .winrs-carousel__trigger-btn {
  background-color: #1e9f48;
}
.page-winrs-landing.green-theme .winrs-carousel__button-ghost {
  border: 1px solid #1e9f48;
  color: #1e9f48;
}
.page-winrs-landing.green-theme .winrs-carousel__button-ghost:after {
  background-color: #1e9f48;
}
.page-winrs-landing.green-theme .winrs-carousel__button-ghost:hover {
  color: #fff;
}
.page-winrs-landing.green-theme .winrs__quote-bar {
  background-color: #1e9f48 !important;
}
.page-winrs-landing.orange-theme .winrs__button--hero {
  border: 1px solid #E76D25;
}
.page-winrs-landing.orange-theme .winrs__button--hero:after {
  background-color: #E76D25;
}
.page-winrs-landing.orange-theme .winrs-carousel__lg-trigger-btn {
  background-color: #E76D25;
}
.page-winrs-landing.orange-theme .winrs-carousel__trigger-btn {
  background-color: #E76D25;
}
.page-winrs-landing.orange-theme .winrs-carousel__button-ghost {
  border: 1px solid #E76D25;
  color: #E76D25;
}
.page-winrs-landing.orange-theme .winrs-carousel__button-ghost:after {
  background-color: #E76D25;
}
.page-winrs-landing.orange-theme .winrs-carousel__button-ghost:hover {
  color: #fff;
}
.page-winrs-landing.orange-theme .winrs__quote-bar {
  background-color: #E76D25 !important;
}
.page-winrs-landing.cyan-theme .winrs__button--hero {
  border: 1px solid #18D0E4;
}
.page-winrs-landing.cyan-theme .winrs__button--hero:after {
  background-color: #18D0E4;
}
.page-winrs-landing.cyan-theme .winrs-carousel__lg-trigger-btn {
  background-color: #18D0E4;
}
.page-winrs-landing.cyan-theme .winrs-carousel__trigger-btn {
  background-color: #18D0E4;
}
.page-winrs-landing.cyan-theme .winrs-carousel__button-ghost {
  border: 1px solid #18D0E4;
  color: #18D0E4;
}
.page-winrs-landing.cyan-theme .winrs-carousel__button-ghost:after {
  background-color: #18D0E4;
}
.page-winrs-landing.cyan-theme .winrs-carousel__button-ghost:hover {
  color: #fff;
}
.page-winrs-landing.cyan-theme .winrs__quote-bar {
  background-color: #18D0E4 !important;
}
.page-winrs-landing.light-green-theme .winrs__button--hero {
  border: 1px solid #b7f3c6;
}
.page-winrs-landing.light-green-theme .winrs__button--hero:after {
  background-color: #b7f3c6;
}
.page-winrs-landing.light-green-theme .winrs-carousel__lg-trigger-btn {
  background-color: #b7f3c6;
}
.page-winrs-landing.light-green-theme .winrs-carousel__trigger-btn {
  background-color: #b7f3c6;
}
.page-winrs-landing.light-green-theme .winrs-carousel__button-ghost {
  border: 1px solid #b7f3c6;
  color: #b7f3c6;
}
.page-winrs-landing.light-green-theme .winrs-carousel__button-ghost:after {
  background-color: #b7f3c6;
}
.page-winrs-landing.light-green-theme .winrs-carousel__button-ghost:hover {
  color: #fff;
}
.page-winrs-landing.light-green-theme .winrs__quote-bar {
  background-color: #b7f3c6 !important;
}
.page-winrs-landing.lime-theme .winrs__button--hero {
  border: 1px solid #A5CF4D;
}
.page-winrs-landing.lime-theme .winrs__button--hero:after {
  background-color: #A5CF4D;
}
.page-winrs-landing.lime-theme .winrs-carousel__lg-trigger-btn {
  background-color: #A5CF4D;
}
.page-winrs-landing.lime-theme .winrs-carousel__trigger-btn {
  background-color: #A5CF4D;
}
.page-winrs-landing.lime-theme .winrs-carousel__button-ghost {
  border: 1px solid #A5CF4D;
  color: #A5CF4D;
}
.page-winrs-landing.lime-theme .winrs-carousel__button-ghost:after {
  background-color: #A5CF4D;
}
.page-winrs-landing.lime-theme .winrs-carousel__button-ghost:hover {
  color: #fff;
}
.page-winrs-landing.lime-theme .winrs__quote-bar {
  background-color: #A5CF4D !important;
}
.page-winrs-landing.fuschia-theme .winrs__button--hero {
  border: 1px solid #92338a;
}
.page-winrs-landing.fuschia-theme .winrs__button--hero:after {
  background-color: #92338a;
}
.page-winrs-landing.fuschia-theme .winrs-carousel__trigger-btn {
  background-color: #92338a;
}
.page-winrs-landing.fuschia-theme .winrs-carousel__lg-trigger-btn {
  background-color: #92338a;
}
.page-winrs-landing.fuschia-theme .winrs-carousel__button-ghost {
  border: 1px solid #92338a;
  color: #92338a;
}
.page-winrs-landing.fuschia-theme .winrs-carousel__button-ghost:after {
  background-color: #92338a;
}
.page-winrs-landing.fuschia-theme .winrs-carousel__button-ghost:hover {
  color: #fff;
}
.page-winrs-landing.fuschia-theme .winrs__quote-bar {
  background-color: #92338a !important;
}

.page-winrs-detail.blue-theme .winrs__button--hero {
  border: 1px solid #0373ac;
}
.page-winrs-detail.blue-theme .winrs__button--hero:after {
  background-color: #0373ac;
}
.page-winrs-detail.blue-theme .professional-card-link--diversity {
  color: #0373ac;
}
.page-winrs-detail.blue-theme .winrs__quote-bar {
  background-color: #0373ac !important;
}
.page-winrs-detail.blue-theme .sidenav-section-skew--diversity {
  background-color: #0373ac;
}
.page-winrs-detail.red-theme .winrs__button--hero {
  border: 1px solid #CC092F;
}
.page-winrs-detail.red-theme .winrs__button--hero:after {
  background-color: #CC092F;
}
.page-winrs-detail.red-theme .professional-card-link--diversity {
  color: #CC092F;
}
.page-winrs-detail.red-theme .winrs__quote-bar {
  background-color: #CC092F !important;
}
.page-winrs-detail.red-theme .sidenav-section-skew--diversity {
  background-color: #CC092F;
}
.page-winrs-detail.green-theme .winrs__button--hero {
  border: 1px solid #1e9f48;
}
.page-winrs-detail.green-theme .winrs__button--hero:after {
  background-color: #1e9f48;
}
.page-winrs-detail.green-theme .professional-card-link--diversity {
  color: #1e9f48;
}
.page-winrs-detail.green-theme .winrs__quote-bar {
  background-color: #1e9f48 !important;
}
.page-winrs-detail.green-theme .sidenav-section-skew--diversity {
  background-color: #1e9f48;
}
.page-winrs-detail.orange-theme .winrs__button--hero {
  border: 1px solid #E76D25;
}
.page-winrs-detail.orange-theme .winrs__button--hero:after {
  background-color: #E76D25;
}
.page-winrs-detail.orange-theme .professional-card-link--diversity {
  color: #E76D25;
}
.page-winrs-detail.orange-theme .winrs__quote-bar {
  background-color: #E76D25 !important;
}
.page-winrs-detail.orange-theme .sidenav-section-skew--diversity {
  background-color: #E76D25;
}
.page-winrs-detail.cyan-theme .winrs__button--hero {
  border: 1px solid #18D0E4;
}
.page-winrs-detail.cyan-theme .winrs__button--hero:after {
  background-color: #18D0E4;
}
.page-winrs-detail.cyan-theme .professional-card-link--diversity {
  color: #18D0E4;
}
.page-winrs-detail.cyan-theme .winrs__quote-bar {
  background-color: #18D0E4 !important;
}
.page-winrs-detail.cyan-theme .sidenav-section-skew--diversity {
  background-color: #18D0E4;
}
.page-winrs-detail.light-green-theme .winrs__button--hero {
  border: 1px solid #b7f3c6;
}
.page-winrs-detail.light-green-theme .winrs__button--hero:after {
  background-color: #b7f3c6;
}
.page-winrs-detail.light-green-theme .professional-card-link--diversity {
  color: #b7f3c6;
}
.page-winrs-detail.light-green-theme .winrs__quote-bar {
  background-color: #b7f3c6 !important;
}
.page-winrs-detail.light-green-theme .sidenav-section-skew--diversity {
  background-color: #b7f3c6;
}
.page-winrs-detail.lime-theme .winrs__button--hero {
  border: 1px solid #A5CF4D;
}
.page-winrs-detail.lime-theme .winrs__button--hero:after {
  background-color: #A5CF4D;
}
.page-winrs-detail.lime-theme .professional-card-link--diversity {
  color: #A5CF4D;
}
.page-winrs-detail.lime-theme .winrs__quote-bar {
  background-color: #A5CF4D !important;
}
.page-winrs-detail.lime-theme .sidenav-section-skew--diversity {
  background-color: #A5CF4D;
}
.page-winrs-detail.fuschia-theme .winrs__button--hero {
  border: 1px solid #92338a;
}
.page-winrs-detail.fuschia-theme .winrs__button--hero:after {
  background-color: #92338a;
}
.page-winrs-detail.fuschia-theme .professional-card-link--diversity {
  color: #92338a;
}
.page-winrs-detail.fuschia-theme .winrs__quote-bar {
  background-color: #92338a !important;
}
.page-winrs-detail.fuschia-theme .sidenav-section-skew--diversity {
  background-color: #92338a;
}

.page-responsible-business-landing.blue-theme .responsible-business__button--hero {
  border: 1px solid #0373ac;
}
.page-responsible-business-landing.blue-theme .responsible-business__button--hero:after {
  background-color: #0373ac;
}
.page-responsible-business-landing.blue-theme .responsible-business-carousel__lg-trigger-btn {
  background-color: #0373ac;
}
.page-responsible-business-landing.blue-theme .responsible-business-carousel__trigger-btn {
  background-color: #0373ac;
}
.page-responsible-business-landing.blue-theme .responsible-business-carousel__button-ghost {
  border: 1px solid #0373ac;
  color: #0373ac;
}
.page-responsible-business-landing.blue-theme .responsible-business-carousel__button-ghost:after {
  background-color: #0373ac;
}
.page-responsible-business-landing.blue-theme .responsible-business-carousel__button-ghost:hover {
  color: #fff;
}
.page-responsible-business-landing.blue-theme .responsible-business__quote-bar {
  background-color: #0373ac !important;
}
.page-responsible-business-landing.red-theme .responsible-business__button--hero {
  border: 1px solid #CC092F;
}
.page-responsible-business-landing.red-theme .responsible-business__button--hero:after {
  background-color: #CC092F;
}
.page-responsible-business-landing.red-theme .responsible-business-carousel__lg-trigger-btn {
  background-color: #CC092F;
}
.page-responsible-business-landing.red-theme .responsible-business-carousel__trigger-btn {
  background-color: #CC092F;
}
.page-responsible-business-landing.red-theme .responsible-business-carousel__button-ghost {
  border: 1px solid #CC092F;
  color: #CC092F;
}
.page-responsible-business-landing.red-theme .responsible-business-carousel__button-ghost:after {
  background-color: #CC092F;
}
.page-responsible-business-landing.red-theme .responsible-business-carousel__button-ghost:hover {
  color: #fff;
}
.page-responsible-business-landing.red-theme .responsible-business__quote-bar {
  background-color: #CC092F !important;
}
.page-responsible-business-landing.green-theme .responsible-business__button--hero {
  border: 1px solid #1e9f48;
}
.page-responsible-business-landing.green-theme .responsible-business__button--hero:after {
  background-color: #1e9f48;
}
.page-responsible-business-landing.green-theme .responsible-business-carousel__lg-trigger-btn {
  background-color: #1e9f48;
}
.page-responsible-business-landing.green-theme .responsible-business-carousel__trigger-btn {
  background-color: #1e9f48;
}
.page-responsible-business-landing.green-theme .responsible-business-carousel__button-ghost {
  border: 1px solid #1e9f48;
  color: #1e9f48;
}
.page-responsible-business-landing.green-theme .responsible-business-carousel__button-ghost:after {
  background-color: #1e9f48;
}
.page-responsible-business-landing.green-theme .responsible-business-carousel__button-ghost:hover {
  color: #fff;
}
.page-responsible-business-landing.green-theme .responsible-business__quote-bar {
  background-color: #1e9f48 !important;
}
.page-responsible-business-landing.orange-theme .responsible-business__button--hero {
  border: 1px solid #E76D25;
}
.page-responsible-business-landing.orange-theme .responsible-business__button--hero:after {
  background-color: #E76D25;
}
.page-responsible-business-landing.orange-theme .responsible-business-carousel__lg-trigger-btn {
  background-color: #E76D25;
}
.page-responsible-business-landing.orange-theme .responsible-business-carousel__trigger-btn {
  background-color: #E76D25;
}
.page-responsible-business-landing.orange-theme .responsible-business-carousel__button-ghost {
  border: 1px solid #E76D25;
  color: #E76D25;
}
.page-responsible-business-landing.orange-theme .responsible-business-carousel__button-ghost:after {
  background-color: #E76D25;
}
.page-responsible-business-landing.orange-theme .responsible-business-carousel__button-ghost:hover {
  color: #fff;
}
.page-responsible-business-landing.orange-theme .responsible-business__quote-bar {
  background-color: #E76D25 !important;
}
.page-responsible-business-landing.cyan-theme .responsible-business__button--hero {
  border: 1px solid #18D0E4;
}
.page-responsible-business-landing.cyan-theme .responsible-business__button--hero:after {
  background-color: #18D0E4;
}
.page-responsible-business-landing.cyan-theme .responsible-business-carousel__lg-trigger-btn {
  background-color: #18D0E4;
}
.page-responsible-business-landing.cyan-theme .responsible-business-carousel__trigger-btn {
  background-color: #18D0E4;
}
.page-responsible-business-landing.cyan-theme .responsible-business-carousel__button-ghost {
  border: 1px solid #18D0E4;
  color: #18D0E4;
}
.page-responsible-business-landing.cyan-theme .responsible-business-carousel__button-ghost:after {
  background-color: #18D0E4;
}
.page-responsible-business-landing.cyan-theme .responsible-business-carousel__button-ghost:hover {
  color: #fff;
}
.page-responsible-business-landing.cyan-theme .responsible-business__quote-bar {
  background-color: #18D0E4 !important;
}
.page-responsible-business-landing.light-green-theme .responsible-business__button--hero {
  border: 1px solid #b7f3c6;
}
.page-responsible-business-landing.light-green-theme .responsible-business__button--hero:after {
  background-color: #b7f3c6;
}
.page-responsible-business-landing.light-green-theme .responsible-business-carousel__lg-trigger-btn {
  background-color: #b7f3c6;
}
.page-responsible-business-landing.light-green-theme .responsible-business-carousel__trigger-btn {
  background-color: #b7f3c6;
}
.page-responsible-business-landing.light-green-theme .responsible-business-carousel__button-ghost {
  border: 1px solid #b7f3c6;
  color: #b7f3c6;
}
.page-responsible-business-landing.light-green-theme .responsible-business-carousel__button-ghost:after {
  background-color: #b7f3c6;
}
.page-responsible-business-landing.light-green-theme .responsible-business-carousel__button-ghost:hover {
  color: #fff;
}
.page-responsible-business-landing.light-green-theme .responsible-business__quote-bar {
  background-color: #b7f3c6 !important;
}
.page-responsible-business-landing.lime-theme .responsible-business__button--hero {
  border: 1px solid #A5CF4D;
}
.page-responsible-business-landing.lime-theme .responsible-business__button--hero:after {
  background-color: #A5CF4D;
}
.page-responsible-business-landing.lime-theme .responsible-business-carousel__lg-trigger-btn {
  background-color: #A5CF4D;
}
.page-responsible-business-landing.lime-theme .responsible-business-carousel__trigger-btn {
  background-color: #A5CF4D;
}
.page-responsible-business-landing.lime-theme .responsible-business-carousel__button-ghost {
  border: 1px solid #A5CF4D;
  color: #A5CF4D;
}
.page-responsible-business-landing.lime-theme .responsible-business-carousel__button-ghost:after {
  background-color: #A5CF4D;
}
.page-responsible-business-landing.lime-theme .responsible-business-carousel__button-ghost:hover {
  color: #fff;
}
.page-responsible-business-landing.lime-theme .responsible-business__quote-bar {
  background-color: #A5CF4D !important;
}
.page-responsible-business-landing.fuschia-theme .responsible-business__button--hero {
  border: 1px solid #92338a;
}
.page-responsible-business-landing.fuschia-theme .responsible-business__button--hero:after {
  background-color: #92338a;
}
.page-responsible-business-landing.fuschia-theme .responsible-business-carousel__trigger-btn {
  background-color: #92338a;
}
.page-responsible-business-landing.fuschia-theme .responsible-business-carousel__lg-trigger-btn {
  background-color: #92338a;
}
.page-responsible-business-landing.fuschia-theme .responsible-business-carousel__button-ghost {
  border: 1px solid #92338a;
  color: #92338a;
}
.page-responsible-business-landing.fuschia-theme .responsible-business-carousel__button-ghost:after {
  background-color: #92338a;
}
.page-responsible-business-landing.fuschia-theme .responsible-business-carousel__button-ghost:hover {
  color: #fff;
}
.page-responsible-business-landing.fuschia-theme .responsible-business__quote-bar {
  background-color: #92338a !important;
}

.page-responsible-business-detail.blue-theme .responsible-business__button--hero {
  border: 1px solid #0373ac;
}
.page-responsible-business-detail.blue-theme .responsible-business__button--hero:after {
  background-color: #0373ac;
}
.page-responsible-business-detail.blue-theme .professional-card-link--diversity {
  color: #0373ac;
}
.page-responsible-business-detail.blue-theme .responsible-business__quote-bar {
  background-color: #0373ac !important;
}
.page-responsible-business-detail.blue-theme .sidenav-section-skew--responsible-business {
  background-color: #0373ac;
}
.page-responsible-business-detail.red-theme .responsible-business__button--hero {
  border: 1px solid #CC092F;
}
.page-responsible-business-detail.red-theme .responsible-business__button--hero:after {
  background-color: #CC092F;
}
.page-responsible-business-detail.red-theme .professional-card-link--diversity {
  color: #CC092F;
}
.page-responsible-business-detail.red-theme .responsible-business__quote-bar {
  background-color: #CC092F !important;
}
.page-responsible-business-detail.red-theme .sidenav-section-skew--responsible-business {
  background-color: #CC092F;
}
.page-responsible-business-detail.green-theme .responsible-business__button--hero {
  border: 1px solid #1e9f48;
}
.page-responsible-business-detail.green-theme .responsible-business__button--hero:after {
  background-color: #1e9f48;
}
.page-responsible-business-detail.green-theme .professional-card-link--diversity {
  color: #1e9f48;
}
.page-responsible-business-detail.green-theme .responsible-business__quote-bar {
  background-color: #1e9f48 !important;
}
.page-responsible-business-detail.green-theme .sidenav-section-skew--responsible-business {
  background-color: #1e9f48;
}
.page-responsible-business-detail.green-theme .btn-ghost {
  color: #CC092F;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
}
.page-responsible-business-detail.green-theme .btn-ghost:hover {
  color: #fff;
}
.page-responsible-business-detail.green-theme .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-responsible-business-detail.green-theme .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #CC092F;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-responsible-business-detail.green-theme .btn-ghost--responsible-business {
  border: 1px solid #1e9f48;
  color: #1e9f48;
}
.page-responsible-business-detail.green-theme .btn-ghost--responsible-business:after {
  background-color: #1e9f48;
}
.page-responsible-business-detail.orange-theme .responsible-business__button--hero {
  border: 1px solid #E76D25;
}
.page-responsible-business-detail.orange-theme .responsible-business__button--hero:after {
  background-color: #E76D25;
}
.page-responsible-business-detail.orange-theme .professional-card-link--diversity {
  color: #E76D25;
}
.page-responsible-business-detail.orange-theme .responsible-business__quote-bar {
  background-color: #E76D25 !important;
}
.page-responsible-business-detail.orange-theme .sidenav-section-skew--responsible-business {
  background-color: #E76D25;
}
.page-responsible-business-detail.cyan-theme .responsible-business__button--hero {
  border: 1px solid #18D0E4;
}
.page-responsible-business-detail.cyan-theme .responsible-business__button--hero:after {
  background-color: #18D0E4;
}
.page-responsible-business-detail.cyan-theme .professional-card-link--diversity {
  color: #18D0E4;
}
.page-responsible-business-detail.cyan-theme .responsible-business__quote-bar {
  background-color: #18D0E4 !important;
}
.page-responsible-business-detail.cyan-theme .sidenav-section-skew--responsible-business {
  background-color: #18D0E4;
}
.page-responsible-business-detail.light-green-theme .responsible-business__button--hero {
  border: 1px solid #b7f3c6;
}
.page-responsible-business-detail.light-green-theme .responsible-business__button--hero:after {
  background-color: #b7f3c6;
}
.page-responsible-business-detail.light-green-theme .professional-card-link--diversity {
  color: #b7f3c6;
}
.page-responsible-business-detail.light-green-theme .responsible-business__quote-bar {
  background-color: #b7f3c6 !important;
}
.page-responsible-business-detail.light-green-theme .sidenav-section-skew--diversity {
  background-color: #b7f3c6;
}
.page-responsible-business-detail.lime-theme .responsible-business__button--hero {
  border: 1px solid #A5CF4D;
}
.page-responsible-business-detail.lime-theme .responsible-business__button--hero:after {
  background-color: #A5CF4D;
}
.page-responsible-business-detail.lime-theme .professional-card-link--diversity {
  color: #A5CF4D;
}
.page-responsible-business-detail.lime-theme .responsible-business__quote-bar {
  background-color: #A5CF4D !important;
}
.page-responsible-business-detail.lime-theme .sidenav-section-skew--diversity {
  background-color: #A5CF4D;
}
.page-responsible-business-detail.fuschia-theme .responsible-business__button--hero {
  border: 1px solid #92338a;
}
.page-responsible-business-detail.fuschia-theme .responsible-business__button--hero:after {
  background-color: #92338a;
}
.page-responsible-business-detail.fuschia-theme .professional-card-link--diversity {
  color: #92338a;
}
.page-responsible-business-detail.fuschia-theme .responsible-business__quote-bar {
  background-color: #92338a !important;
}
.page-responsible-business-detail.fuschia-theme .sidenav-section-skew--diversity {
  background-color: #92338a;
}

.page-diversity-landing.blue-theme .diversity__button--hero {
  border: 1px solid #0373ac;
}
.page-diversity-landing.blue-theme .diversity__button--hero:after {
  background-color: #0373ac;
}
.page-diversity-landing.blue-theme .diversity-carousel__lg-trigger-btn {
  background-color: #0373ac;
}
.page-diversity-landing.blue-theme .diversity-carousel__trigger-btn {
  background-color: #0373ac;
}
.page-diversity-landing.blue-theme .diversity-carousel__button-ghost {
  border: 1px solid #0373ac;
  color: #0373ac;
}
.page-diversity-landing.blue-theme .diversity-carousel__button-ghost:after {
  background-color: #0373ac;
}
.page-diversity-landing.blue-theme .diversity-carousel__button-ghost:hover {
  color: #fff;
}
.page-diversity-landing.blue-theme .diversity__quote-bar {
  background-color: #0373ac !important;
}
.page-diversity-landing.red-theme .diversity__button--hero {
  border: 1px solid #CC092F;
}
.page-diversity-landing.red-theme .diversity__button--hero:after {
  background-color: #CC092F;
}
.page-diversity-landing.red-theme .diversity-carousel__lg-trigger-btn {
  background-color: #CC092F;
}
.page-diversity-landing.red-theme .diversity-carousel__trigger-btn {
  background-color: #CC092F;
}
.page-diversity-landing.red-theme .diversity-carousel__button-ghost {
  border: 1px solid #CC092F;
  color: #CC092F;
}
.page-diversity-landing.red-theme .diversity-carousel__button-ghost:after {
  background-color: #CC092F;
}
.page-diversity-landing.red-theme .diversity-carousel__button-ghost:hover {
  color: #fff;
}
.page-diversity-landing.red-theme .diversity__quote-bar {
  background-color: #CC092F !important;
}
.page-diversity-landing.green-theme .diversity__button--hero {
  border: 1px solid #1e9f48;
}
.page-diversity-landing.green-theme .diversity__button--hero:after {
  background-color: #1e9f48;
}
.page-diversity-landing.green-theme .diversity-carousel__lg-trigger-btn {
  background-color: #1e9f48;
}
.page-diversity-landing.green-theme .diversity-carousel__trigger-btn {
  background-color: #1e9f48;
}
.page-diversity-landing.green-theme .diversity-carousel__button-ghost {
  border: 1px solid #1e9f48;
  color: #1e9f48;
}
.page-diversity-landing.green-theme .diversity-carousel__button-ghost:after {
  background-color: #1e9f48;
}
.page-diversity-landing.green-theme .diversity-carousel__button-ghost:hover {
  color: #fff;
}
.page-diversity-landing.green-theme .diversity__quote-bar {
  background-color: #1e9f48 !important;
}
.page-diversity-landing.orange-theme .diversity__button--hero {
  border: 1px solid #E76D25;
}
.page-diversity-landing.orange-theme .diversity__button--hero:after {
  background-color: #E76D25;
}
.page-diversity-landing.orange-theme .diversity-carousel__lg-trigger-btn {
  background-color: #E76D25;
}
.page-diversity-landing.orange-theme .diversity-carousel__trigger-btn {
  background-color: #E76D25;
}
.page-diversity-landing.orange-theme .diversity-carousel__button-ghost {
  border: 1px solid #E76D25;
  color: #E76D25;
}
.page-diversity-landing.orange-theme .diversity-carousel__button-ghost:after {
  background-color: #E76D25;
}
.page-diversity-landing.orange-theme .diversity-carousel__button-ghost:hover {
  color: #fff;
}
.page-diversity-landing.orange-theme .diversity__quote-bar {
  background-color: #E76D25 !important;
}
.page-diversity-landing.cyan-theme .diversity__button--hero {
  border: 1px solid #18D0E4;
}
.page-diversity-landing.cyan-theme .diversity__button--hero:after {
  background-color: #18D0E4;
}
.page-diversity-landing.cyan-theme .diversity-carousel__lg-trigger-btn {
  background-color: #18D0E4;
}
.page-diversity-landing.cyan-theme .diversity-carousel__trigger-btn {
  background-color: #18D0E4;
}
.page-diversity-landing.cyan-theme .diversity-carousel__button-ghost {
  border: 1px solid #18D0E4;
  color: #18D0E4;
}
.page-diversity-landing.cyan-theme .diversity-carousel__button-ghost:after {
  background-color: #18D0E4;
}
.page-diversity-landing.cyan-theme .diversity-carousel__button-ghost:hover {
  color: #fff;
}
.page-diversity-landing.cyan-theme .diversity__quote-bar {
  background-color: #18D0E4 !important;
}
.page-diversity-landing.light-green-theme .diversity__button--hero {
  border: 1px solid #b7f3c6;
}
.page-diversity-landing.light-green-theme .diversity__button--hero:after {
  background-color: #b7f3c6;
}
.page-diversity-landing.light-green-theme .diversity-carousel__lg-trigger-btn {
  background-color: #b7f3c6;
}
.page-diversity-landing.light-green-theme .diversity-carousel__trigger-btn {
  background-color: #b7f3c6;
}
.page-diversity-landing.light-green-theme .diversity-carousel__button-ghost {
  border: 1px solid #b7f3c6;
  color: #b7f3c6;
}
.page-diversity-landing.light-green-theme .diversity-carousel__button-ghost:after {
  background-color: #b7f3c6;
}
.page-diversity-landing.light-green-theme .diversity-carousel__button-ghost:hover {
  color: #fff;
}
.page-diversity-landing.light-green-theme .diversity__quote-bar {
  background-color: #b7f3c6 !important;
}
.page-diversity-landing.lime-theme .diversity__button--hero {
  border: 1px solid #A5CF4D;
}
.page-diversity-landing.lime-theme .diversity__button--hero:after {
  background-color: #A5CF4D;
}
.page-diversity-landing.lime-theme .diversity-carousel__lg-trigger-btn {
  background-color: #A5CF4D;
}
.page-diversity-landing.lime-theme .diversity-carousel__trigger-btn {
  background-color: #A5CF4D;
}
.page-diversity-landing.lime-theme .diversity-carousel__button-ghost {
  border: 1px solid #A5CF4D;
  color: #A5CF4D;
}
.page-diversity-landing.lime-theme .diversity-carousel__button-ghost:after {
  background-color: #A5CF4D;
}
.page-diversity-landing.lime-theme .diversity-carousel__button-ghost:hover {
  color: #fff;
}
.page-diversity-landing.lime-theme .diversity__quote-bar {
  background-color: #A5CF4D !important;
}
.page-diversity-landing.fuschia-theme .diversity__button--hero {
  border: 1px solid #92338a;
}
.page-diversity-landing.fuschia-theme .diversity__button--hero:after {
  background-color: #92338a;
}
.page-diversity-landing.fuschia-theme .diversity-carousel__trigger-btn {
  background-color: #92338a;
}
.page-diversity-landing.fuschia-theme .diversity-carousel__lg-trigger-btn {
  background-color: #92338a;
}
.page-diversity-landing.fuschia-theme .diversity-carousel__button-ghost {
  border: 1px solid #92338a;
  color: #92338a;
}
.page-diversity-landing.fuschia-theme .diversity-carousel__button-ghost:after {
  background-color: #92338a;
}
.page-diversity-landing.fuschia-theme .diversity-carousel__button-ghost:hover {
  color: #fff;
}
.page-diversity-landing.fuschia-theme .diversity__quote-bar {
  background-color: #92338a !important;
}

.page-diversity-detail.blue-theme .diversity__button--hero {
  border: 1px solid #0373ac;
}
.page-diversity-detail.blue-theme .diversity__button--hero:after {
  background-color: #0373ac;
}
.page-diversity-detail.blue-theme .professional-card-link--diversity {
  color: #0373ac;
}
.page-diversity-detail.blue-theme .diversity__quote-bar {
  background-color: #0373ac !important;
}
.page-diversity-detail.blue-theme .sidenav-section-skew--diversity {
  background-color: #0373ac;
}
.page-diversity-detail.red-theme .diversity__button--hero {
  border: 1px solid #CC092F;
}
.page-diversity-detail.red-theme .diversity__button--hero:after {
  background-color: #CC092F;
}
.page-diversity-detail.red-theme .professional-card-link--diversity {
  color: #CC092F;
}
.page-diversity-detail.red-theme .diversity__quote-bar {
  background-color: #CC092F !important;
}
.page-diversity-detail.red-theme .sidenav-section-skew--diversity {
  background-color: #CC092F;
}
.page-diversity-detail.green-theme .diversity__button--hero {
  border: 1px solid #1e9f48;
}
.page-diversity-detail.green-theme .diversity__button--hero:after {
  background-color: #1e9f48;
}
.page-diversity-detail.green-theme .professional-card-link--diversity {
  color: #1e9f48;
}
.page-diversity-detail.green-theme .diversity__quote-bar {
  background-color: #1e9f48 !important;
}
.page-diversity-detail.green-theme .sidenav-section-skew--diversity {
  background-color: #1e9f48;
}
.page-diversity-detail.orange-theme .diversity__button--hero {
  border: 1px solid #E76D25;
}
.page-diversity-detail.orange-theme .diversity__button--hero:after {
  background-color: #E76D25;
}
.page-diversity-detail.orange-theme .professional-card-link--diversity {
  color: #E76D25;
}
.page-diversity-detail.orange-theme .diversity__quote-bar {
  background-color: #E76D25 !important;
}
.page-diversity-detail.orange-theme .sidenav-section-skew--diversity {
  background-color: #E76D25;
}
.page-diversity-detail.cyan-theme .diversity__button--hero {
  border: 1px solid #18D0E4;
}
.page-diversity-detail.cyan-theme .diversity__button--hero:after {
  background-color: #18D0E4;
}
.page-diversity-detail.cyan-theme .professional-card-link--diversity {
  color: #18D0E4;
}
.page-diversity-detail.cyan-theme .diversity__quote-bar {
  background-color: #18D0E4 !important;
}
.page-diversity-detail.cyan-theme .sidenav-section-skew--diversity {
  background-color: #18D0E4;
}
.page-diversity-detail.light-green-theme .diversity__button--hero {
  border: 1px solid #b7f3c6;
}
.page-diversity-detail.light-green-theme .diversity__button--hero:after {
  background-color: #b7f3c6;
}
.page-diversity-detail.light-green-theme .professional-card-link--diversity {
  color: #b7f3c6;
}
.page-diversity-detail.light-green-theme .diversity__quote-bar {
  background-color: #b7f3c6 !important;
}
.page-diversity-detail.light-green-theme .sidenav-section-skew--diversity {
  background-color: #b7f3c6;
}
.page-diversity-detail.lime-theme .diversity__button--hero {
  border: 1px solid #A5CF4D;
}
.page-diversity-detail.lime-theme .diversity__button--hero:after {
  background-color: #A5CF4D;
}
.page-diversity-detail.lime-theme .professional-card-link--diversity {
  color: #A5CF4D;
}
.page-diversity-detail.lime-theme .diversity__quote-bar {
  background-color: #A5CF4D !important;
}
.page-diversity-detail.lime-theme .sidenav-section-skew--diversity {
  background-color: #A5CF4D;
}
.page-diversity-detail.fuschia-theme .diversity__button--hero {
  border: 1px solid #92338a;
}
.page-diversity-detail.fuschia-theme .diversity__button--hero:after {
  background-color: #92338a;
}
.page-diversity-detail.fuschia-theme .professional-card-link--diversity {
  color: #92338a;
}
.page-diversity-detail.fuschia-theme .diversity__quote-bar {
  background-color: #92338a !important;
}
.page-diversity-detail.fuschia-theme .sidenav-section-skew--diversity {
  background-color: #92338a;
}

.page-probono-landing.blue-theme .probono__button--hero {
  border: 1px solid #0373ac;
  background-color: #fff;
  color: #0373ac;
}
.page-probono-landing.blue-theme .probono__button--hero:after {
  background-color: #0373ac;
}
.page-probono-landing.blue-theme .probono__button--hero:hover {
  color: #fff;
}
.page-probono-landing.blue-theme .probono__button--hero.no-border {
  border: none;
}
.page-probono-landing.blue-theme .probono-carousel__lg-trigger-btn {
  background-color: #0373ac;
}
.page-probono-landing.blue-theme .probono-carousel__trigger-btn {
  background-color: #0373ac;
}
.page-probono-landing.blue-theme .probono-carousel__button-ghost {
  border: 1px solid #0373ac;
  color: #0373ac;
}
.page-probono-landing.blue-theme .probono-carousel__button-ghost:after {
  background-color: #0373ac;
}
.page-probono-landing.blue-theme .probono-carousel__button-ghost:hover {
  color: #fff;
}
.page-probono-landing.blue-theme .probono__quote-bar {
  background-color: #0373ac !important;
}
.page-probono-landing.red-theme .probono__button--hero {
  border: 1px solid #CC092F;
}
.page-probono-landing.red-theme .probono__button--hero:after {
  background-color: #CC092F;
}
.page-probono-landing.red-theme .probono-carousel__lg-trigger-btn {
  background-color: #CC092F;
}
.page-probono-landing.red-theme .probono-carousel__trigger-btn {
  background-color: #CC092F;
}
.page-probono-landing.red-theme .probono-carousel__button-ghost {
  border: 1px solid #CC092F;
  color: #CC092F;
}
.page-probono-landing.red-theme .probono-carousel__button-ghost:after {
  background-color: #CC092F;
}
.page-probono-landing.red-theme .probono-carousel__button-ghost:hover {
  color: #fff;
}
.page-probono-landing.red-theme .probono__quote-bar {
  background-color: #CC092F !important;
}
.page-probono-landing.green-theme .probono__button--hero {
  border: 1px solid #1e9f48;
}
.page-probono-landing.green-theme .probono__button--hero:after {
  background-color: #1e9f48;
}
.page-probono-landing.green-theme .probono-carousel__lg-trigger-btn {
  background-color: #1e9f48;
}
.page-probono-landing.green-theme .probono-carousel__trigger-btn {
  background-color: #1e9f48;
}
.page-probono-landing.green-theme .probono-carousel__button-ghost {
  border: 1px solid #1e9f48;
  color: #1e9f48;
}
.page-probono-landing.green-theme .probono-carousel__button-ghost:after {
  background-color: #1e9f48;
}
.page-probono-landing.green-theme .probono-carousel__button-ghost:hover {
  color: #fff;
}
.page-probono-landing.green-theme .probono__quote-bar {
  background-color: #1e9f48 !important;
}
.page-probono-landing.orange-theme .probono__button--hero {
  border: 1px solid #E76D25;
}
.page-probono-landing.orange-theme .probono__button--hero:after {
  background-color: #E76D25;
}
.page-probono-landing.orange-theme .probono-carousel__lg-trigger-btn {
  background-color: #E76D25;
}
.page-probono-landing.orange-theme .probono-carousel__trigger-btn {
  background-color: #E76D25;
}
.page-probono-landing.orange-theme .probono-carousel__button-ghost {
  border: 1px solid #E76D25;
  color: #E76D25;
}
.page-probono-landing.orange-theme .probono-carousel__button-ghost:after {
  background-color: #E76D25;
}
.page-probono-landing.orange-theme .probono-carousel__button-ghost:hover {
  color: #fff;
}
.page-probono-landing.orange-theme .probono__quote-bar {
  background-color: #E76D25 !important;
}
.page-probono-landing.cyan-theme .probono__button--hero {
  border: 1px solid #18D0E4;
}
.page-probono-landing.cyan-theme .probono__button--hero:after {
  background-color: #18D0E4;
}
.page-probono-landing.cyan-theme .probono-carousel__lg-trigger-btn {
  background-color: #18D0E4;
}
.page-probono-landing.cyan-theme .probono-carousel__trigger-btn {
  background-color: #18D0E4;
}
.page-probono-landing.cyan-theme .probono-carousel__button-ghost {
  border: 1px solid #18D0E4;
  color: #18D0E4;
}
.page-probono-landing.cyan-theme .probono-carousel__button-ghost:after {
  background-color: #18D0E4;
}
.page-probono-landing.cyan-theme .probono-carousel__button-ghost:hover {
  color: #fff;
}
.page-probono-landing.cyan-theme .probono__quote-bar {
  background-color: #18D0E4 !important;
}
.page-probono-landing.light-green-theme .probono__button--hero {
  border: 1px solid #b7f3c6;
}
.page-probono-landing.light-green-theme .probono__button--hero:after {
  background-color: #b7f3c6;
}
.page-probono-landing.light-green-theme .probono-carousel__lg-trigger-btn {
  background-color: #b7f3c6;
}
.page-probono-landing.light-green-theme .probono-carousel__trigger-btn {
  background-color: #b7f3c6;
}
.page-probono-landing.light-green-theme .probono-carousel__button-ghost {
  border: 1px solid #b7f3c6;
  color: #b7f3c6;
}
.page-probono-landing.light-green-theme .probono-carousel__button-ghost:after {
  background-color: #b7f3c6;
}
.page-probono-landing.light-green-theme .probono-carousel__button-ghost:hover {
  color: #fff;
}
.page-probono-landing.light-green-theme .probono__quote-bar {
  background-color: #b7f3c6 !important;
}
.page-probono-landing.lime-theme .probono__button--hero {
  border: 1px solid #A5CF4D;
}
.page-probono-landing.lime-theme .probono__button--hero:after {
  background-color: #A5CF4D;
}
.page-probono-landing.lime-theme .probono-carousel__lg-trigger-btn {
  background-color: #A5CF4D;
}
.page-probono-landing.lime-theme .probono-carousel__trigger-btn {
  background-color: #A5CF4D;
}
.page-probono-landing.lime-theme .probono-carousel__button-ghost {
  border: 1px solid #A5CF4D;
  color: #A5CF4D;
}
.page-probono-landing.lime-theme .probono-carousel__button-ghost:after {
  background-color: #A5CF4D;
}
.page-probono-landing.lime-theme .probono-carousel__button-ghost:hover {
  color: #fff;
}
.page-probono-landing.lime-theme .probono__quote-bar {
  background-color: #A5CF4D !important;
}
.page-probono-landing.fuschia-theme .probono__button--hero {
  border: 1px solid #92338a;
}
.page-probono-landing.fuschia-theme .probono__button--hero:after {
  background-color: #92338a;
}
.page-probono-landing.fuschia-theme .probono-carousel__trigger-btn {
  background-color: #92338a;
}
.page-probono-landing.fuschia-theme .probono-carousel__lg-trigger-btn {
  background-color: #92338a;
}
.page-probono-landing.fuschia-theme .probono-carousel__button-ghost {
  border: 1px solid #92338a;
  color: #92338a;
}
.page-probono-landing.fuschia-theme .probono-carousel__button-ghost:after {
  background-color: #92338a;
}
.page-probono-landing.fuschia-theme .probono-carousel__button-ghost:hover {
  color: #fff;
}
.page-probono-landing.fuschia-theme .probono__quote-bar {
  background-color: #92338a !important;
}

.page-probono-detail.blue-theme .probono__button--hero {
  border: 1px solid #0373ac;
}
.page-probono-detail.blue-theme .probono__button--hero:after {
  background-color: #0373ac;
}
.page-probono-detail.blue-theme .professional-card-link--diversity {
  color: #0373ac;
}
.page-probono-detail.blue-theme .probono__quote-bar {
  background-color: #0373ac !important;
}
.page-probono-detail.blue-theme .sidenav-section-skew--diversity {
  background-color: #0373ac;
}
.page-probono-detail.blue-theme .btn-ghost {
  color: #CC092F;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
}
.page-probono-detail.blue-theme .btn-ghost:hover {
  color: #fff;
}
.page-probono-detail.blue-theme .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-probono-detail.blue-theme .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #CC092F;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-probono-detail.blue-theme .btn-ghost--diversity {
  border: 1px solid #0373ac;
  color: #0373ac;
}
.page-probono-detail.blue-theme .btn-ghost--diversity:after {
  background-color: #0373ac;
}
.page-probono-detail.red-theme .probono__button--hero {
  border: 1px solid #CC092F;
}
.page-probono-detail.red-theme .probono__button--hero:after {
  background-color: #CC092F;
}
.page-probono-detail.red-theme .professional-card-link--diversity {
  color: #CC092F;
}
.page-probono-detail.red-theme .probono__quote-bar {
  background-color: #CC092F !important;
}
.page-probono-detail.red-theme .sidenav-section-skew--diversity {
  background-color: #CC092F;
}
.page-probono-detail.red-theme .btn-ghost {
  color: #CC092F;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
}
.page-probono-detail.red-theme .btn-ghost:hover {
  color: #fff;
}
.page-probono-detail.red-theme .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-probono-detail.red-theme .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #CC092F;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-probono-detail.red-theme .btn-ghost--diversity {
  border: 1px solid #CC092F;
  color: #CC092F;
}
.page-probono-detail.red-theme .btn-ghost--diversity:after {
  background-color: #CC092F;
}
.page-probono-detail.green-theme .probono__button--hero {
  border: 1px solid #1e9f48;
}
.page-probono-detail.green-theme .probono__button--hero:after {
  background-color: #1e9f48;
}
.page-probono-detail.green-theme .professional-card-link--diversity {
  color: #1e9f48;
}
.page-probono-detail.green-theme .probono__quote-bar {
  background-color: #1e9f48 !important;
}
.page-probono-detail.green-theme .sidenav-section-skew--diversity {
  background-color: #1e9f48;
}
.page-probono-detail.green-theme .btn-ghost {
  color: #CC092F;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
}
.page-probono-detail.green-theme .btn-ghost:hover {
  color: #fff;
}
.page-probono-detail.green-theme .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-probono-detail.green-theme .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #CC092F;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-probono-detail.green-theme .btn-ghost--diversity {
  border: 1px solid #1e9f48;
  color: #1e9f48;
}
.page-probono-detail.green-theme .btn-ghost--diversity:after {
  background-color: #1e9f48;
}
.page-probono-detail.orange-theme .probono__button--hero {
  border: 1px solid #E76D25;
}
.page-probono-detail.orange-theme .probono__button--hero:after {
  background-color: #E76D25;
}
.page-probono-detail.orange-theme .professional-card-link--diversity {
  color: #E76D25;
}
.page-probono-detail.orange-theme .probono__quote-bar {
  background-color: #E76D25 !important;
}
.page-probono-detail.orange-theme .sidenav-section-skew--diversity {
  background-color: #E76D25;
}
.page-probono-detail.orange-theme .btn-ghost {
  color: #CC092F;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
}
.page-probono-detail.orange-theme .btn-ghost:hover {
  color: #fff;
}
.page-probono-detail.orange-theme .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-probono-detail.orange-theme .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #CC092F;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-probono-detail.orange-theme .btn-ghost--diversity {
  border: 1px solid #E76D25;
  color: #E76D25;
}
.page-probono-detail.orange-theme .btn-ghost--diversity:after {
  background-color: #E76D25;
}
.page-probono-detail.cyan-theme .probono__button--hero {
  border: 1px solid #18D0E4;
}
.page-probono-detail.cyan-theme .probono__button--hero:after {
  background-color: #18D0E4;
}
.page-probono-detail.cyan-theme .professional-card-link--diversity {
  color: #18D0E4;
}
.page-probono-detail.cyan-theme .probono__quote-bar {
  background-color: #18D0E4 !important;
}
.page-probono-detail.cyan-theme .sidenav-section-skew--diversity {
  background-color: #18D0E4;
}
.page-probono-detail.cyan-theme .btn-ghost {
  color: #CC092F;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
}
.page-probono-detail.cyan-theme .btn-ghost:hover {
  color: #fff;
}
.page-probono-detail.cyan-theme .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-probono-detail.cyan-theme .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #CC092F;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-probono-detail.cyan-theme .btn-ghost--diversity {
  border: 1px solid #18D0E4;
  color: #18D0E4;
}
.page-probono-detail.cyan-theme .btn-ghost--diversity:after {
  background-color: #18D0E4;
}
.page-probono-detail.light-green-theme .probono__button--hero {
  border: 1px solid #b7f3c6;
}
.page-probono-detail.light-green-theme .probono__button--hero:after {
  background-color: #b7f3c6;
}
.page-probono-detail.light-green-theme .professional-card-link--diversity {
  color: #b7f3c6;
}
.page-probono-detail.light-green-theme .probono__quote-bar {
  background-color: #b7f3c6 !important;
}
.page-probono-detail.light-green-theme .sidenav-section-skew--diversity {
  background-color: #b7f3c6;
}
.page-probono-detail.light-green-theme .btn-ghost {
  color: #CC092F;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
}
.page-probono-detail.light-green-theme .btn-ghost:hover {
  color: #fff;
}
.page-probono-detail.light-green-theme .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-probono-detail.light-green-theme .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #CC092F;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-probono-detail.light-green-theme .btn-ghost--diversity {
  border: 1px solid #b7f3c6;
  color: #b7f3c6;
}
.page-probono-detail.light-green-theme .btn-ghost--diversity:after {
  background-color: #b7f3c6;
}
.page-probono-detail.lime-theme .probono__button--hero {
  border: 1px solid #A5CF4D;
}
.page-probono-detail.lime-theme .probono__button--hero:after {
  background-color: #A5CF4D;
}
.page-probono-detail.lime-theme .professional-card-link--diversity {
  color: #A5CF4D;
}
.page-probono-detail.lime-theme .probono__quote-bar {
  background-color: #A5CF4D !important;
}
.page-probono-detail.lime-theme .sidenav-section-skew--diversity {
  background-color: #A5CF4D;
}
.page-probono-detail.lime-theme .btn-ghost {
  color: #CC092F;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
}
.page-probono-detail.lime-theme .btn-ghost:hover {
  color: #fff;
}
.page-probono-detail.lime-theme .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-probono-detail.lime-theme .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #CC092F;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-probono-detail.lime-theme .btn-ghost--diversity {
  border: 1px solid #A5CF4D;
  color: #A5CF4D;
}
.page-probono-detail.lime-theme .btn-ghost--diversity:after {
  background-color: #A5CF4D;
}
.page-probono-detail.fuschia-theme .probono__button--hero {
  border: 1px solid #92338a;
}
.page-probono-detail.fuschia-theme .probono__button--hero:after {
  background-color: #92338a;
}
.page-probono-detail.fuschia-theme .professional-card-link--diversity {
  color: #92338a;
}
.page-probono-detail.fuschia-theme .probono__quote-bar {
  background-color: #92338a !important;
}
.page-probono-detail.fuschia-theme .sidenav-section-skew--diversity {
  background-color: #92338a;
}
.page-probono-detail.fuschia-theme .btn-ghost {
  color: #CC092F;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
}
.page-probono-detail.fuschia-theme .btn-ghost:hover {
  color: #fff;
}
.page-probono-detail.fuschia-theme .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-probono-detail.fuschia-theme .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #CC092F;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-probono-detail.fuschia-theme .btn-ghost--diversity {
  border: 1px solid #92338a;
  color: #92338a;
}
.page-probono-detail.fuschia-theme .btn-ghost--diversity:after {
  background-color: #92338a;
}

.page-contact-us__btn {
  margin-top: 1rem;
}

.page-topic-detail .angled-hero {
  margin-bottom: 5em;
}
@media screen and (max-width: 64em) {
  .page-topic-detail .angled-hero {
    margin-bottom: 0;
  }
}

.page-forecast-landing .btn-ghost-label {
  font-size: 1.5em;
  text-transform: none;
}
.page-forecast-landing .forecast-hero {
  max-width: 100.3125em;
  padding-left: 1.875em;
  padding-right: 1.875em;
  margin-left: auto;
  margin-right: auto;
  min-height: 31.25em;
  padding-top: 6em;
}
@media screen and (max-width: 56.1875em) {
  .page-forecast-landing .forecast-hero {
    padding-left: 1em;
    padding-right: 1em;
  }
}
.page-forecast-landing .forecast-hero__left {
  padding: 1.5625em;
}
.page-forecast-landing .forecast-hero__left img {
  max-height: 25em;
}
.page-forecast-landing .forecast-hero__right {
  padding: 1.5625em;
}
.page-forecast-landing .forecast-hero__right--header {
  color: #3D3C3C;
}
.page-forecast-landing .forecast-hero__right--header-title {
  padding-bottom: 0.2em;
}
.page-forecast-landing .forecast-hero__right--header-subtitle {
  font-size: 3.5em;
  line-height: 1.1;
  font-weight: 700;
  padding-bottom: 0.2em;
}
.page-forecast-landing .forecast-hero__right--header-subtext {
  font-size: 1.5em;
  line-height: 1.1;
}
@media screen and (min-width: 56.25em) {
  .page-forecast-landing .forecast-hero {
    display: flex;
    flex-direction: row-reverse;
  }
  .page-forecast-landing .forecast-hero__left {
    width: 50%;
  }
  .page-forecast-landing .forecast-hero__right {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.page-forecast-landing__section {
  max-width: 100.3125em;
  padding-left: 1.875em;
  padding-right: 1.875em;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 56.1875em) {
  .page-forecast-landing__section {
    padding-left: 1em;
    padding-right: 1em;
  }
}
.page-forecast-landing .read-more-intro {
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
}
.page-forecast-landing .read-more-intro strong {
  font-family: "Open Sans", sans-serif;
}
.page-forecast-landing .narrative-overview__column--buttons {
  text-align: center;
}
.page-forecast-landing .narrative-overview__column--buttons .btn-ghost {
  margin-bottom: 0.625em;
}
@media screen and (min-width: 56.25em) {
  .page-forecast-landing .narrative-overview__column--main {
    padding-right: 0.78125em;
    width: 60%;
  }
  .page-forecast-landing .narrative-overview__column--buttons {
    padding-left: 0.78125em;
    width: 40%;
    text-align: center;
  }
  .page-forecast-landing .narrative-overview__column--buttons .btn-ghost {
    margin-bottom: 0.625em;
  }
}
.page-forecast-landing .join-the-conversation {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0;
}
.page-forecast-landing .article-tile-heading.no-link + .hover-grow-fade-fx-img {
  transform: none !important;
}
.page-forecast-landing .article-tile .article-tile-title {
  font-weight: 700;
  font-size: 28px;
  line-height: 1.1;
}
.page-forecast-landing .article-tile .article-tile-card__list-link {
  font-size: 24px;
}
.page-forecast-landing .article-tile .article-tile-card__list-link:not(:last-child) {
  padding-bottom: 0.625em;
  margin-bottom: 0.625em;
}
.page-forecast-landing .article-tile .article-tile-card {
  justify-content: flex-start;
  max-width: 448px;
  width: 85%;
}
.page-forecast-landing .article-tile.forecast-theme__melon .article-tile-card {
  color: #1e9f48;
}
.page-forecast-landing .article-tile.forecast-theme__plum .article-tile-card {
  color: #92338a;
}
.page-forecast-landing .article-tile.forecast-theme__red .article-tile-card {
  color: #cd122d;
}
.page-forecast-landing .article-tile.forecast-theme__marine .article-tile-card {
  color: #0373ac;
}
.page-forecast-landing .article-tile.forecast-theme__olive .article-tile-card {
  color: #558033;
}
.page-forecast-landing .article-tile.forecast-theme__brand-red .article-tile-card {
  color: #CC092F;
}
.page-forecast-landing .article-tile.forecast-theme__royal .article-tile-card {
  color: #283584;
}
.page-forecast-landing .article-tile.forecast-theme__tangerine .article-tile-card {
  color: #E94B00;
}
.page-forecast-landing .article-tile.forecast-theme__dark .article-tile-card {
  color: #696969;
}
.page-forecast-landing .article-tile.forecast-theme__light .article-tile-card {
  color: #6A6A6A;
}
.page-forecast-landing .register-section--panel {
  max-width: 100.3125em;
  padding-left: 1.875em;
  padding-right: 1.875em;
  margin-left: auto;
  margin-right: auto;
  color: #fff;
  background-color: #cd122d;
  padding-top: 3.75em;
  padding-bottom: 3.75em;
  width: 100%;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 56.1875em) {
  .page-forecast-landing .register-section--panel {
    padding-left: 1em;
    padding-right: 1em;
  }
}
.page-forecast-landing .register-section--panel .btn-ghost {
  color: #fff;
  background-color: transparent;
  backface-visibility: hidden;
  transform: translateZ(0);
}
.page-forecast-landing .register-section--panel .btn-ghost:hover {
  color: #cd122d;
}
.page-forecast-landing .register-section--panel .btn-ghost:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.page-forecast-landing .register-section--panel .btn-ghost:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #fff;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.page-forecast-landing .register-section--panel__label--text {
  font-size: 2em;
  line-height: 1.6;
}
.page-forecast-landing .register-section--panel__button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 1.5625em;
}
.page-forecast-landing .register-section--panel__button .btn-ghost {
  border: thin solid #fff;
  color: #fff;
}
@media screen and (min-width: 56.25em) {
  .page-forecast-landing .register-section--panel {
    flex-direction: row;
  }
  .page-forecast-landing .register-section--panel__label {
    width: 70%;
  }
  .page-forecast-landing .register-section--panel__button {
    width: 30%;
    padding-top: 0;
  }
}

.forecast__red-background {
  border-color: #cd122d !important;
}
.forecast__red-background .is-selected {
  background: #cd122d !important;
}
.forecast__red {
  color: #cd122d;
}

.infographic {
  background-color: #404040;
  color: #fff;
  text-align: center;
  padding: 1.5625em;
  width: 18.75em;
  min-height: 18.75em;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}
.infographic-figure {
  font-weight: 800;
  font-size: 4em;
  width: 100%;
}
.infographic-figure:after {
  content: "";
  display: block;
  margin: 0.390625em auto;
  width: 0.78125em;
  border-top: 1px solid #E5B522;
}
.infographic-label {
  font-weight: 300;
  font-size: 1.375em;
}
.infographic--page {
  margin: 0.625em;
}
@media screen and (max-width: 56.1875em) {
  .infographic {
    width: 16.25em;
    min-height: 16.25em;
  }
  .infographic-figure {
    font-size: 3em;
  }
  .infographic-figure:after {
    margin: 0.5208333333em auto;
    width: 1.0416666667em;
  }
  .infographic-label {
    font-size: 1.125em;
  }
}
@media screen and (max-width: 47.4375em) {
  .infographic-label {
    font-size: 1.375em;
  }
}

.stacked-infographics .infographic + .infographic {
  margin-top: 2.5em;
}
@media screen and (max-width: 56.1875em) {
  .stacked-infographics .infographic + .infographic {
    margin-top: 1.375em;
  }
}

.person-card-angled {
  background-color: #CC092F;
  color: #fff;
  box-shadow: 0 7px 20px 4px rgba(0, 0, 0, 0.5);
  max-width: 26.875em;
}
.person-card-angled-name {
  font-weight: 300;
  font-size: 2em;
  margin-bottom: 0.4em;
}
.person-card-angled-meta {
  font-weight: 700;
  font-size: 1.5em;
}
.person-card-angled-details {
  padding: 2.5em 1.375em;
}
.person-card-angled-details--flex {
  display: flex;
}
.person-card-angled-text {
  flex: 0.75;
}
.person-card-angled-video {
  flex: 0.25;
}
.person-card-angled-nolink {
  cursor: pointer;
}
.person-card-angled-snapshot {
  position: relative;
  margin-left: -35px;
  margin-right: -35px;
}
.person-card-angled-svg-wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 109.756097561%;
  display: block;
}
.person-card-angled-svg-mask {
  position: absolute;
  top: 0;
  left: 0;
}
.person-card-angled-svg-img {
  -webkit-clip-path: none;
  clip-path: url("#peoplecardclipping");
}
@media screen and (max-width: 56.1875em) {
  .person-card-angled-name {
    font-size: 1.5em;
  }
  .person-card-angled-meta {
    font-size: 1.125em;
  }
  .person-card-angled-details {
    padding: 1.375em;
  }
}

.people-card-grid {
  margin-bottom: 2em;
}
.people-card-grid-clipping-path {
  position: absolute;
  width: 0;
  height: 0;
  z-index: -1;
}
.people-card-grid-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.people-card-grid .person-card-angled {
  width: 31.5%;
  margin-bottom: 5em;
}
@media screen and (max-width: 62.4375em) {
  .people-card-grid {
    margin-bottom: 1rem;
  }
  .people-card-grid .person-card-angled {
    width: 47.5%;
  }
}
@media screen and (max-width: 39.3125em) {
  .people-card-grid {
    margin-bottom: 3.125em;
  }
  .people-card-grid-list {
    display: blockl;
  }
  .people-card-grid .person-card-angled {
    width: 100%;
    max-width: 18.75em;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2.5em;
  }
}

.video-header {
  padding-top: 2.8125em;
  position: relative;
  max-width: 125em;
  margin-left: auto;
  margin-right: auto;
}
.video-header.no-featured-people {
  margin-bottom: 80px;
}
.video-header-background {
  background: transparent no-repeat center center;
  background-size: cover;
  background-color: #222;
  background-position: center 0;
  position: absolute;
  top: 0;
  bottom: -2.5em;
  left: 0;
  right: 0;
  z-index: -2;
}
.video-header:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: -2.5em;
  left: 0;
  right: 0;
  z-index: -1;
  background: rgba(0, 0, 0, 0.4);
}
.video-header-body-content {
  max-width: 63.75em;
  padding-left: 1.875em;
  padding-right: 1.875em;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 55;
  padding-top: 11.71875em;
  padding-bottom: 14.0625em;
}
@media screen and (max-width: 56.1875em) {
  .video-header-body-content {
    padding-left: 1em;
    padding-right: 1em;
  }
}
.video-header-play-button {
  display: block;
  margin: 5em auto 0;
  width: 6.1875em;
  height: 6.1875em;
  border-radius: 50%;
  border: 1px solid #fff;
  background-color: rgba(0, 0, 0, 0.43);
  color: #E5B522;
  transition: color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86), border-color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86), opacity 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86), background-color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.video-header-play-button:before {
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.875em;
}
.video-header-play-button:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
}
.video-header-featured-person-button {
  width: 5em;
  height: 5em;
  border-radius: 50%;
  border: 1px solid #fff;
  background-color: rgba(0, 0, 0, 0.43);
  color: #E5B522;
  transition: color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86), border-color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86), opacity 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86), background-color 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.video-header-featured-person-button:before {
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.875em;
}
.video-header-featured-person-button:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
}
@media screen and (max-width: 56.1875em) {
  .video-header-body-content {
    padding-top: 9.375em;
    padding-bottom: 9.375em;
  }
  .video-header-play-button {
    width: 4.0625em;
    height: 4.0625em;
  }
  .video-header-play-button:before {
    font-size: 1.25em;
  }
}
@media screen and (max-width: 47.4375em) {
  .video-header-body-content {
    padding-top: 5em;
    padding-bottom: 5em;
  }
  .video-header-play-button {
    margin-top: 5em;
  }
}

.gold-tabs {
  margin-bottom: 5em;
}
.gold-tabs-title-wrapper {
  display: flex;
  flex-flow: row-reverse;
  justify-content: space-between;
  align-items: center;
}
.gold-tabs-mobile-toggle {
  font-weight: 800;
  display: none;
  width: 100%;
  padding: 0.5em 1.375em;
  background-color: #E5B522;
  overflow: hidden;
  color: #3D3C3C;
  text-align: left;
  position: relative;
}
.gold-tabs-mobile-toggle:after {
  content: "\e900";
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.625em;
  right: 2.2em;
}
.gold-tabs-nav {
  display: flex;
  align-items: center;
  position: relative;
}
.gold-tabs-nav:before {
  content: "";
  position: absolute;
  top: 50%;
  display: block;
  left: 0;
  width: 100%;
  height: 1px;
  border-top: 1px solid #777;
  z-index: -1;
}
.gold-tabs-nav-option {
  flex-basis: 25%;
  padding-right: 2%;
}
.gold-tabs-nav-option:last-child {
  padding-right: 0;
}
.gold-tabs-nav-option--is-active .gold-tabs-btn {
  background-color: #5B5B5B;
}
.gold-tabs-btn {
  font-weight: 800;
  padding: 0.75em 1em;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100%;
  height: 4.375em;
  color: #fff;
  background-color: #B58F1B;
  backface-visibility: hidden;
  transform: translateZ(0);
}
.gold-tabs-btn:hover {
  color: #fff;
}
.gold-tabs-btn:hover:after {
  transform: skewX(-25deg) translateX(0);
}
.gold-tabs-btn:after {
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: "";
  display: block;
  position: absolute;
  right: -1em;
  top: 0;
  bottom: 0;
  left: -1em;
  background-color: #3D3C3C;
  transform: skewX(-25deg) translateX(-100%);
  backface-visibility: hidden;
}
.gold-tabs-btn-label {
  position: relative;
  z-index: 2;
}
.gold-tabs-frame {
  margin-top: 6.875em;
  position: relative;
  overflow: hidden;
}
.gold-tabs-frame-shrinkmargin {
  margin-top: 0;
}
.gold-tabs-frame-offices {
  position: relative;
  overflow: hidden;
}
.gold-tabs-menu-title-tabs {
  width: 100%;
}
.gold-tabs-title-header {
  width: 20%;
  margin-bottom: 0;
}
.gold-tabs-section-title {
  background-color: #fff;
  padding-left: 0.625em;
  display: inline-block;
  font-size: 1.5em;
  font-weight: 800;
  line-height: 1.1;
  position: relative;
}
.gold-tabs-section-title::before {
  position: absolute;
  content: "";
  display: block;
  right: 100%;
  margin-left: 1em;
  width: 62.5em;
  top: 50%;
  margin-top: -2px;
  border-top: 1px solid #777;
  transform: translateZ(0);
}
@media screen and (max-width: 62.4375em) {
  .gold-tabs-title-wrapper {
    display: block;
  }
  .gold-tabs-title-header {
    width: 100%;
    margin-bottom: 5em;
  }
  .gold-tabs-section-title::after {
    position: absolute;
    content: "";
    display: block;
    left: 100%;
    margin-left: 1em;
    width: 62.5em;
    top: 50%;
    margin-top: -2px;
    border-top: 1px solid #777;
    transform: translateZ(0);
  }
  .gold-tabs-nav-full {
    width: 100%;
    justify-content: space-between;
  }
  .gold-tabs-frame-shrinkmargin {
    margin-top: 5em;
  }
}
@media screen and (max-width: 56.1875em) {
  .gold-tabs-frame {
    margin-top: 5em;
    z-index: 0;
  }
  .gold-tabs-frame-offices {
    margin-top: 5em;
  }
}
@media screen and (max-width: 47.4375em) {
  .gold-tabs {
    margin-bottom: 4em;
  }
  .gold-tabs-mobile-toggle {
    display: block;
  }
  .gold-tabs-menu {
    position: relative;
    z-index: 15;
  }
  .gold-tabs-nav {
    position: absolute;
    width: 100%;
    top: 40px;
    max-height: 0;
    opacity: 0;
    display: block;
    overflow: hidden;
    transition: max-height 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86), opacity 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.175s;
  }
  .gold-tabs-menu--show-mobile-nav .gold-tabs-nav {
    max-height: 31.25em;
    opacity: 1;
    z-index: 1;
  }
  .gold-tabs-nav-option {
    padding-right: 0;
  }
  .gold-tabs-nav-option--is-active {
    display: none;
  }
  .gold-tabs-btn {
    text-align: left;
    padding: 1em 1.375em;
    width: 100%;
  }
}

.gold-tabs-panel {
  transition: opacity 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
}
.gold-tabs-panel--is-active {
  visibility: visible;
  opacity: 1;
  position: relative;
  z-index: 10;
}
.is-transitioning-out .gold-tabs-panel--is-active--is-active {
  opacity: 0;
}
.gold-tabs-panel__copy {
  max-width: 1220px;
}
.gold-tabs-panel-actions {
  padding-left: 6px;
  margin-top: 5em;
}
.gold-tabs-panel-cta {
  min-width: 17.5em;
  margin-right: 1em;
}
.gold-tabs-panel-cta:last-child {
  margin-right: 0;
}
@media screen and (max-width: 47.4375em) {
  .gold-tabs-panel-actions {
    margin-top: 2em;
  }
  .gold-tabs-panel-cta {
    display: block;
    margin-right: 0;
    margin-bottom: 1em;
  }
  .gold-tabs-panel-cta:last-child {
    margin-bottom: 0;
  }
}

.find-fit .section-header {
  margin-bottom: 6.875em;
}
.find-fit-column {
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
}
.find-fit-column-main {
  flex-grow: 1;
  padding-right: 1.375em;
  padding-top: 6.875em;
}
.find-fit-column-sidebar {
  width: auto;
}
.find-fit-lead-img {
  margin-bottom: 1.375em;
  text-align: center;
}
.find-fit-lead-img-main {
  max-width: 100%;
}
.find-fit .gold-tabs-menu {
  position: absolute;
  width: 100%;
  padding-right: 20.125em;
  z-index: 20;
}
.find-fit .gold-tabs {
  margin-bottom: 0;
}
.find-fit .gold-tabs .silent-wrapper {
  position: relative;
}
.find-fit .stacked-infographics {
  width: 100%;
}
@media screen and (max-width: 67.125em) {
  .find-fit-column {
    display: block;
  }
  .find-fit-column-main {
    padding-right: 0;
    padding-top: 0;
  }
  .find-fit-column-sidebar {
    width: 100%;
  }
  .find-fit .gold-tabs-menu {
    position: static;
    padding-right: 0;
  }
  .find-fit .gold-tabs {
    margin-bottom: 1.5625em;
  }
  .find-fit .stacked-infographics {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    margin-top: 6.875em;
  }
  .find-fit .infographic {
    width: 32%;
  }
  .find-fit .infographic + .infographic {
    margin-top: 0;
  }
}
@media screen and (max-width: 56.1875em) {
  .find-fit .section-header {
    margin-bottom: 5em;
  }
}
@media screen and (max-width: 47.4375em) {
  .find-fit-column-sidebar {
    margin-top: 2.5em;
  }
  .find-fit .stacked-infographics {
    margin-top: 0;
  }
  .find-fit .infographic {
    width: 21.4375em;
    min-height: 21.4375em;
    margin-top: 1.5625em;
  }
  .find-fit .infographic + .infographic {
    margin-top: 1.5625em;
  }
}
@media screen and (max-width: 39.3125em) {
  .find-fit .stacked-infographics {
    justify-content: center;
  }
  .find-fit .infographic {
    width: 21.4375em;
  }
}

.header {
  margin-top: 2em;
  font-size: 20px;
  line-height: 25.6px;
  font-weight: 700;
}

.prominent-card {
  display: flex;
  margin: 2em 0;
  border-bottom: 1px solid #3D3C3C;
}
.prominent-card:last-child {
  border-bottom: 0;
}
@media screen and (max-width: 56.1875em) {
  .prominent-card {
    flex-direction: column;
  }
}

.prominent-card-thumbnail {
  width: 9.375em;
  margin-right: 2em;
  flex-shrink: 0;
}
.prominent-card-thumbnail + .prominent-card-info {
  margin-bottom: -3em;
}
@media screen and (max-width: 56.1875em) {
  .prominent-card-thumbnail {
    margin-bottom: 1em;
  }
}

.quotation {
  background: url("../images/quote.svg") no-repeat center;
  height: 42px;
  width: 45px;
  margin-bottom: 8px;
}

.quote {
  font-family: "Lora", serif;
  margin-bottom: 1em;
  font-size: 28px;
  line-height: 42px;
  font-weight: 400;
}

.prominent-card-office {
  display: inline-block;
  font-size: 1em;
  margin-bottom: 2em;
  color: #CC092F;
}

.prominent-card-position {
  font-size: 1em;
  margin-bottom: 10px;
}

.prominent-card-link {
  color: #CC092F;
  background-repeat: no-repeat;
  background-position: 0 1.34em;
  background-size: 0 auto;
  margin-bottom: 10px;
  font-weight: 800;
  font-size: 1.25em;
}

.explore-locations .section-header {
  margin-bottom: 6.875em;
}
.explore-locations-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-right: -0.9375em;
}
.explore-locations-grid .related-item-tile {
  margin-top: 0.9375em;
  margin-right: 0.9375em;
  width: calc(50% - 0.9375em);
}
.explore-locations-search {
  margin-bottom: 5em;
}
.explore-locations-cta {
  text-align: center;
  margin-top: 5em;
}
.explore-locations .related-item-tile-header {
  padding-bottom: 3.75em;
}
.explore-locations .related-item-tile-header--external:after {
  content: "\e90b";
  display: inline-block;
  font-family: "reedsmith";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 1em;
  bottom: 1em;
  color: #E5B522;
  font-size: 1.875em;
}
@media screen and (max-width: 56.1875em) {
  .explore-locations .section-header {
    margin-bottom: 2em;
  }
  .explore-locations-search {
    margin-bottom: 2.5em;
  }
  .explore-locations-cta {
    text-align: center;
    margin-top: 2.5em;
  }
  .explore-locations .related-item-tile {
    width: 80%;
    height: 100%;
    margin-right: auto;
    margin-left: auto;
  }
  .explore-locations .related-item-tile-header {
    padding-bottom: 2.5em;
  }
  .explore-locations .related-item-tile-header--external:after {
    content: "\e90b";
    display: inline-block;
    font-family: "reedsmith";
    font-size: inherit;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    right: 1em;
    bottom: 1em;
    font-size: 1.25em;
  }
}
@media screen and (max-width: 47.4375em) {
  .explore-locations .related-item-tile {
    width: 100%;
    margin-top: 0.9375em;
    margin-right: 0.9375em;
  }
}

.featured-person .person-card-angled {
  width: 40%;
  max-width: 25.625em;
  height: 100%;
  background-color: transparent;
  box-shadow: none;
  margin-right: 5em;
}
.featured-person .person-card-angled-svg-wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 112.1951219512%;
  display: block;
}
.featured-person__overview {
  max-width: 63.75em;
  padding-left: 1.875em;
  padding-right: 1.875em;
  margin-left: auto;
  margin-right: auto;
  padding-right: 0;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
@media screen and (max-width: 56.1875em) {
  .featured-person__overview {
    padding-left: 1em;
    padding-right: 1em;
  }
}
.featured-person__overview--slide {
  max-width: 100.3125em;
  padding: 0;
}
.featured-person__overview--employee-card {
  max-width: 70em;
  padding: 0;
}
.featured-person__info-container {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.featured-person__quote {
  font-family: "Lora", serif;
  font-size: 1.125em;
  font-weight: 700;
  line-height: 1.6;
}
.featured-person__divider {
  border-bottom: 3px solid #CC092F;
  width: 95px;
  margin: 2.5em 0;
}
.featured-person__info {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 1.25em;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  line-height: 1.4;
}
.featured-person__name, .featured-person__position {
  padding-right: 0.375em;
}
.featured-person__name--last:after, .featured-person__position--last:after {
  content: ",";
  padding-right: 0.375em;
}
.featured-person__location:before {
  content: "-";
  padding-right: 0.375em;
}
@media screen and (max-width: 62.4375em) {
  .featured-person .person-card-angled {
    width: 45%;
  }
  .featured-person__overview {
    padding-left: 1.875em;
    padding-right: 1.875em;
  }
  .featured-person__info-container {
    width: 48%;
  }
}
@media screen and (max-width: 56.1875em) {
  .featured-person__divider {
    margin: 2.8125em 0;
  }
}
@media screen and (max-width: 39.3125em) {
  .featured-person .person-card-angled {
    width: 100%;
    max-width: 18.75em;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2.5em;
  }
  .featured-person__overview {
    flex-direction: column;
  }
  .featured-person__info-container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .featured-person__quote {
    text-align: center;
  }
  .featured-person__divider {
    margin: 2.1875em auto;
  }
  .featured-person__info {
    justify-content: center;
  }
  .featured-person__name, .featured-person__name--last, .featured-person__position, .featured-person__location {
    font-size: 16px;
  }
}
@media screen and (max-width: 26.1875em) {
  .featured-person__info-container {
    max-width: 18.75em;
  }
}

.featured-person-carousel {
  display: flex;
  overflow: hidden;
  width: calc(100% - 75px);
}
.featured-person-carousel__wrapper {
  display: flex;
  flex-direction: row;
}
.featured-person-carousel__slide {
  min-width: 100%;
  transition: transform 0.3s ease-in-out;
}
.featured-person-carousel__btn {
  background-color: #B58F1B;
  color: #fff;
  padding: 1.25em 0;
  height: 17.1875em;
  padding: 0 1.25em;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -webkit-border-radius: 0px;
  transition: all ease-in-out 0.15s;
  display: block;
  box-shadow: 0px 7px 20px 4px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.featured-person-carousel__btn:hover {
  background-color: #E5B522;
}
@media screen and (max-width: 39.3125em) {
  .featured-person-carousel {
    width: 100%;
    margin-bottom: 2.5em;
  }
  .featured-person-carousel__wrapper {
    flex-direction: column;
  }
  .featured-person-carousel__btn {
    padding: 1.25em 0;
    height: unset;
  }
}

.cta-bucket__wrapper {
  max-width: 63.75em;
  padding-left: 1.875em;
  padding-right: 1.875em;
  margin-left: auto;
  margin-right: auto;
}
.cta-bucket__cta-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 2.8125em;
  margin-bottom: 6.875em;
}
.cta-bucket__cta {
  flex: 1 0 auto;
}
.cta-bucket__cta:not(:last-of-type) {
  margin-right: 1em;
}
@media screen and (max-width: 74.9375em) {
  .cta-bucket__cta-container {
    flex-direction: column;
  }
  .cta-bucket__cta {
    width: 100%;
    margin-right: 0;
  }
  .cta-bucket__cta:not(:last-of-type) {
    margin-bottom: 1em;
    margin-right: 0;
  }
}

.page-infographics {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  max-width: 60em;
  margin-bottom: 6.875em;
}
@media screen and (max-width: 47.4375em) {
  .page-infographics {
    margin-bottom: 5em;
  }
}

.careers-result {
  border-bottom: 2px solid #979797;
}
.careers-result--header {
  border: none;
}
.careers-result--table {
  width: 100%;
  table-layout: fixed;
  margin-top: 3em;
}
.careers-result--left {
  text-align: left;
  padding-bottom: 1em;
  padding-top: 1em;
}
.careers-result--center {
  text-align: center;
  padding-bottom: 1em;
  padding-top: 1em;
}
.careers-result--right {
  text-align: right;
  padding-bottom: 1em;
  padding-top: 1em;
}

.banner-image {
  margin-bottom: 3.125rem;
  position: relative;
}
.banner-image__image {
  width: 100%;
}

.timeline {
  margin: 2em 0;
  font-family: "Lora";
}
.timeline__image {
  width: 100%;
}

.circle {
  width: 98px;
  height: 98px;
  border: 2px solid #AEAEAE;
  line-height: 98px;
  text-align: center;
  font-weight: 500;
  font-size: 64px;
  border-radius: 50%;
  color: #CC092F;
  margin-bottom: 20px;
}

.timeline-title {
  font-weight: 700;
  line-height: 30px;
  font-size: 20px;
  margin-bottom: 8px;
}

.timeline-description {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 31px;
}

.column {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 56.1875em) {
  .column {
    flex-direction: column;
  }
}

.timeline-list-card {
  flex-grow: 0;
  width: 48%;
  padding-right: 20px;
}
@media screen and (max-width: 56.1875em) {
  .timeline-list-card {
    width: 100%;
  }
}

.embed-instagram {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.post {
  margin-right: 16px;
  flex: 1 1 0%;
  margin-bottom: 2em;
}
.post:last-child {
  margin-right: 0;
}

.ig-button {
  display: flex;
  justify-content: center;
  margin: 2em 0;
}
.ig-button-text {
  width: fit-content;
  border: 1px solid #CC092F;
  padding: 0.75em 1.5em;
  font-weight: 800;
  font-size: 20px;
  text-transform: uppercase;
  color: #CC092F;
}

.svg-icon {
  display: inline-block;
  fill: currentColor;
  pointer-events: none;
  stroke-width: 0;
  stroke: currentColor;
  vertical-align: middle;
  width: 32px;
}
.svg-icon--large {
  width: 48px;
}
.svg-icon--globe {
  font-size: 25px;
}
.svg-icon--chevron {
  font-size: 12px;
}

/*! Flickity v2.0.9
http://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  position: absolute;
  top: 50%;
  width: 44px;
  height: 44px;
  border: none;
  border-radius: 50%;
  background: white;
  background: hsla(0, 0%, 100%, 0.75);
  cursor: pointer;
  /* vertically center */
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.flickity-prev-next-button:focus, .flickity-prev-next-button:hover {
  background: white;
  outline: none;
}

.flickity-prev-next-button:active {
  opacity: 0.6;
}

.flickity-prev-next-button.previous {
  left: 10px;
}

.flickity-prev-next-button.next {
  right: 10px;
}

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button:disabled {
  opacity: 0.3;
  cursor: auto;
}

.flickity-prev-next-button svg {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

.flickity-prev-next-button .arrow {
  fill: #333;
}

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  line-height: 1;
  list-style: none;
  margin: 0 auto;
  padding: 0 1.875rem;
  right: 0;
  text-align: center;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  border: 2px solid #3d3c3c;
  display: inline-block;
  width: 14px;
  height: 14px;
  margin: 0 8px;
  background: transparent;
  border-radius: 50%;
  opacity: 1;
  cursor: pointer;
}
@media (min-width: 1300px) {
  .flickity-page-dots .dot {
    width: 20px;
    height: 20px;
  }
}

.flickity-page-dots .dot.is-selected {
  background: #3d3c3c;
  border-color: #3d3c3c;
}

.sitefooter {
  background-color: #3d3c3c;
  color: #fff;
  margin-left: auto;
  margin-right: auto;
  max-width: 100.3125rem;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.sitefooter::before, .sitefooter::after {
  bottom: 0;
  content: "";
  display: block;
  height: 100%;
  left: 0%;
  opacity: 0.9;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(-10deg);
  transform-origin: top right;
  width: 100%;
  z-index: -1;
}
.sitefooter::before {
  background-image: linear-gradient(70deg, rgba(0, 0, 0, 0) 50%, rgb(0, 0, 0) 100%);
  opacity: 0.7;
  top: 50%;
}
@media (min-width: 1300px) {
  .sitefooter::before {
    top: 35%;
  }
}
.sitefooter::after {
  background-image: linear-gradient(70deg, rgba(0, 0, 0, 0) 26%, rgb(0, 0, 0) 100%);
  top: calc(50% + 40px);
}
@media (min-width: 1300px) {
  .sitefooter::after {
    top: calc(35% + 40px);
  }
}
.sitefooter__sitelink {
  display: none;
  height: 4.375rem;
  width: 11.125rem;
}
@media (min-width: 1300px) {
  .sitefooter__sitelink {
    display: inline-block;
  }
}
.sitefooter__content {
  padding: 2.5rem;
}
@media (min-width: 1300px) {
  .sitefooter__content {
    padding-bottom: 6.25rem;
    padding-top: 6.25rem;
  }
}
@media (min-width: 1440px) {
  .sitefooter__content {
    padding: 6.25rem 6.25rem 4.375rem;
  }
}
.sitefooter__content__social {
  padding-top: 3.75rem;
}
@media (min-width: 1300px) {
  .sitefooter__content__social {
    display: flex;
    justify-content: flex-end;
  }
}
.sitefooter__copyright {
  font-size: 0.875rem;
}
@media (min-width: 1300px) {
  .sitefooter__row {
    display: flex;
  }
}
@media (min-width: 1300px) {
  .sitefooter__cell {
    flex: 1 1 auto;
    width: 33.3333333333%;
  }
}
@media (min-width: 1300px) {
  .sitefooter__cell--logo {
    flex-grow: unset;
    width: 25%;
  }
}

.footermenu {
  list-style: none;
  margin: 0 0 1.875rem;
  padding-left: 0;
}
.footermenu--utility {
  margin-bottom: 3rem;
}
@media (min-width: 1300px) {
  .footermenu--utility {
    margin-bottom: 1rem;
  }
}
.footermenu__item--utility {
  display: inline-block;
  margin-right: 0.625rem;
  padding-right: 0.625rem;
  position: relative;
}
.footermenu__item--utility::after {
  border-right: 1px solid #fff;
  bottom: 6px;
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: 6px;
}
.footermenu__item--utility:last-child::after {
  content: none;
}
.footermenu__link {
  display: inline-block;
  font-weight: 600;
  line-height: 1.3;
  padding: 0.375rem 0;
}
.footermenu__link:focus, .footermenu__link:hover {
  text-decoration: underline;
}
.footermenu__link--utility {
  padding: 0.1875rem 0;
}

.sociallinks {
  display: flex;
  list-style: none;
  margin: 0;
  padding-left: 0;
}
.sociallinks__item {
  margin-right: 1.25rem;
}
.sociallinks__item:last-child {
  margin-right: 0;
}
@media (min-width: 1300px) {
  .sociallinks__item {
    margin-right: 1.875rem;
  }
}
.sociallinks__link {
  color: inherit;
  display: block;
  font-size: 30px;
}
.sociallinks__link:focus, .sociallinks__link:hover {
  color: #cc092f;
}

.siteheader {
  left: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 100.3125rem;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 100;
}
.siteheader--internal::before, .siteheader--internal::after {
  bottom: 0;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  width: 50vw;
}
.siteheader--internal::before {
  background-color: #fff;
  left: 50%;
  margin-left: -50vw;
}
.siteheader--internal::after {
  background-color: #3d3c3c;
  margin-right: -50vw;
  right: 50%;
}
.siteheader__inner {
  background-color: rgba(0, 0, 0, 0.45);
}
.siteheader--internal .siteheader__inner {
  background-color: #fff;
}
@media (min-width: 1300px) {
  .siteheader__inner {
    display: flex;
    justify-content: flex-end;
  }
}
.siteheader__toolbar {
  background-color: #3d3c3c;
  display: flex;
  justify-content: flex-end;
}
@media (min-width: 1300px) {
  .siteheader__toolbar {
    order: 1;
  }
}
.siteheader__utility {
  align-items: center;
  display: flex;
  height: 4.25rem;
  padding: 0 1rem 0 0;
  position: relative;
  z-index: 10;
}
@media (min-width: 760px) {
  .siteheader__utility {
    padding: 0 1.5rem;
  }
}
@media (min-width: 1300px) {
  .siteheader__utility {
    height: 6.875rem;
    padding: 0 1.875rem 0 1.875rem;
  }
}
.siteheader__utility::before {
  backface-visibility: hidden;
  background-color: #3d3c3c;
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  top: 0;
  transform: skewX(-10deg);
  transform-origin: 0 0;
  width: 0.75rem;
}
@media (min-width: 1300px) {
  .siteheader__utility::before {
    content: none;
  }
}
.siteheader__sitelink {
  align-self: center;
  display: inline-block;
  height: 1.4375rem;
  left: 1.5rem;
  top: 1.25rem;
  position: absolute;
  width: 7.6875rem;
}
.siteheader__sitelink:focus {
  outline: 5px solid #18D0E4;
}
@media (min-width: 1300px) {
  .siteheader__sitelink {
    height: 4.375rem;
    left: 1.25rem;
    width: 11.125rem;
  }
}
.siteheader--headroom {
  position: fixed;
  transition: transform 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  will-change: transform;
}
.siteheader--not-top {
  background-color: #4a4a4a;
}
.siteheader--pinned {
  transform: translate3d(0, 0, 0);
}
.siteheader--unpinned {
  transform: translate3d(0, -100%, 0);
}

.sitelogo {
  display: block;
  width: 100%;
}
@media (min-width: 1300px) {
  .sitelogo--small {
    display: none;
  }
}
.sitelogo--large {
  display: none;
}
@media (min-width: 1300px) {
  .sitelogo--large {
    display: block;
  }
}

.language-menu {
  background-color: #3d3c3c;
  color: #fff;
  margin-right: 0.5rem;
  position: relative;
  padding-right: 0.5rem;
}
@media (min-width: 760px) {
  .language-menu {
    margin-right: 1rem;
    padding-right: 0.625rem;
  }
}
@media (min-width: 1300px) {
  .language-menu {
    margin-right: 1.5rem;
    padding-right: 1rem;
  }
}
.language-menu::after {
  background-color: #fff;
  content: "";
  display: block;
  height: 1.625rem;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
}
@media (min-width: 1300px) {
  .language-menu::after {
    height: 2.5rem;
  }
}
.language-menu__button {
  background: transparent;
  border: 0;
  color: #fff;
  padding: 0.375rem;
  white-space: nowrap;
}
.language-menu__button:focus, .language-menu__button:hover {
  color: #cc092f;
}
.is-open > .language-menu__button .language-menu__chevron {
  transform: rotate(180deg);
}
.language-menu__chevron {
  transform: rotate(0);
  transition: transform 0.35s ease;
}
.language-menu__options {
  left: 0;
  list-style: none;
  overflow: hidden;
  max-height: 0;
  padding-left: 0;
  width: calc(100% - 16px);
  position: absolute;
  right: 0;
  transition: max-height 0.3s ease, visibility 0.3s;
  z-index: 20;
  visibility: hidden;
}
.is-open > .language-menu__options {
  max-height: 18.75rem;
  visibility: visible;
}
.language-menu__item {
  border-bottom: 1px solid #fff;
}
.language-menu__link {
  background-color: #3d3c3c;
  display: block;
  font-weight: 700;
  padding: 0.75rem;
  transition: background-color 0.35s ease;
  border: transparent solid 5px;
}
.is-selected .language-menu__link {
  background-color: #cc092f;
}
.language-menu__link:focus, .language-menu__link:hover {
  background-color: #f3f3f3;
  color: #3d3c3c;
  outline: none;
  border: #18D0E4 solid 5px;
}

.nav-toggle {
  background-color: #3d3c3c;
  border: 0;
  display: flex;
  height: 2.75rem;
  padding: 0.375rem;
}
.nav-toggle:focus .nav-toggle__icon__inner, .nav-toggle:focus .nav-toggle__icon__inner::before, .nav-toggle:focus .nav-toggle__icon__inner::after, .nav-toggle:hover .nav-toggle__icon__inner, .nav-toggle:hover .nav-toggle__icon__inner::before, .nav-toggle:hover .nav-toggle__icon__inner::after {
  background-color: #cc092f;
}
.nav-toggle__icon {
  align-items: center;
  display: flex;
  height: 19px;
  position: relative;
  width: 28px;
}
@media (min-width: 1300px) {
  .nav-toggle__icon {
    height: 32px;
    width: 42px;
  }
}
.nav-toggle__icon__inner {
  display: block;
}
.nav-toggle__icon__inner, .nav-toggle__icon__inner::before, .nav-toggle__icon__inner::after {
  background-color: #fff;
  height: 3px;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-duration: 75ms;
  transition-property: transform;
  width: 28px;
}
@media (min-width: 1300px) {
  .nav-toggle__icon__inner, .nav-toggle__icon__inner::before, .nav-toggle__icon__inner::after {
    height: 6px;
    width: 42px;
  }
}
.nav-toggle__icon__inner::before, .nav-toggle__icon__inner::after {
  content: "";
  display: block;
  position: absolute;
}
.nav-toggle__icon__inner::before {
  top: -8px;
  transition: top 75ms ease 0.12s, opacity 75ms ease;
}
@media (min-width: 1300px) {
  .nav-toggle__icon__inner::before {
    top: -13px;
  }
}
.is-active .nav-toggle__icon__inner::before {
  opacity: 0;
  top: 0;
  transition: top 75ms ease, opacity 75ms ease 0.12s;
}
.nav-toggle__icon__inner::after {
  bottom: -8px;
  transition: bottom 75ms ease 0.12s, transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
@media (min-width: 1300px) {
  .nav-toggle__icon__inner::after {
    bottom: -13px;
  }
}
.is-active .nav-toggle__icon__inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 75ms ease, transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
}
.nav-toggle__icon__inner {
  display: block;
  position: relative;
}
.is-active .nav-toggle__icon__inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.nav {
  background-color: #3d3c3c;
  color: #fff;
  height: calc(100vh - 68px);
  left: 0;
  overflow: auto;
  position: relative;
  right: 0;
  top: 100%;
  transform: translateY(-120%);
  transition: transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  z-index: 10;
  /*
  * primary nav
  */
  /*
  * secondary nav
  */
  /*
  * menu item structure
  */
}
.siteheader .nav {
  position: absolute;
}
.nav.is-open {
  transform: translateY(0);
}
@media (min-width: 1300px) {
  .nav.is-open {
    transform: none;
  }
}
@media (min-width: 1000px) {
  .nav {
    height: auto;
    overflow: visible;
  }
}
@media (min-width: 1300px) {
  .nav {
    background-color: transparent;
    color: #000;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    left: auto;
    min-height: 6.875rem;
    position: static;
    right: auto;
    top: auto;
    transform: none;
    transition: none;
  }
  .siteheader .nav {
    position: static;
  }
}
.nav__inner {
  padding: 0.75rem 1.5rem 2.5rem;
}
@media (min-width: 1000px) {
  .nav__inner {
    display: flex;
  }
}
@media (min-width: 1300px) {
  .nav__inner {
    display: block;
    padding: 0;
  }
}
.nav__menu {
  list-style: none;
  margin-bottom: 1.5rem;
  margin-top: 0;
}
.nav__sub {
  display: none;
}
@media (min-width: 1300px) {
  .nav__sub {
    align-items: flex-start;
    background-color: #e2e2e2;
    display: flex;
    min-height: 34.375rem;
    left: 0;
    opacity: 0;
    padding: 1.875rem 1.875rem;
    position: absolute;
    right: 0;
    transform: translate3d(0, -120%, 0);
    transition: opacity 0.2s ease, visibility 0.2s ease 0.2s, transform 0.2s ease 0.2s;
    visibility: hidden;
  }
  .nav__sub.is-open {
    opacity: 1;
    visibility: visible;
    transform: translate3d(0, -1px, 0);
    transition: opacity 0.2s ease;
  }
  .nav__sub--full-width {
    width: 100%;
  }
}
@media (min-width: 1440px) {
  .nav__sub {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}
@media (min-width: 1300px) {
  .nav__sub__left {
    width: 75%;
  }
}
@media (min-width: 1300px) {
  .nav__sub__right {
    position: relative;
    width: 25%;
  }
  .nav__sub__right::before {
    background-color: #000;
    bottom: 0;
    content: "";
    display: block;
    position: absolute;
    left: -1.875rem;
    top: 0;
    width: 1px;
  }
}
@media (min-width: 1440px) {
  .nav__sub__right::before {
    left: -3.75rem;
  }
}
@media (min-width: 1300px) {
  .nav__row {
    display: flex;
  }
}
@media (min-width: 1300px) {
  .nav__row__item {
    flex: 1;
    padding-right: 1.5rem;
  }
}
@media (min-width: 1440px) {
  .nav__row__item {
    padding-right: 6.25rem;
  }
}
.nav__row__card {
  width: 10%;
  height: 100%;
  border-left: 1px solid black;
  padding-left: 1.5rem;
  flex: 1;
}
.nav__subnav {
  list-style: none;
  margin: 0;
  padding-left: 0;
}
.nav__menu--primary {
  display: flex;
  flex-direction: column;
}
@media (min-width: 1000px) {
  .nav__menu--primary {
    width: 25%;
  }
}
@media (min-width: 1300px) {
  .nav__menu--primary {
    flex-direction: row;
    margin-bottom: 0;
    width: auto;
  }
}
.nav__menu--secondary {
  flex-direction: row;
  align-content: space-between;
  flex-wrap: nowrap;
}
.nav__menu--secondary:focus {
  outline: 0;
}
@media (min-width: 1300px) {
  .nav__menu--secondary {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 23.75rem;
    margin-bottom: 0;
    width: 100%;
  }
}
@media (min-width: 1440px) {
  .nav__menu--secondary {
    width: 75%;
  }
}
.nav__menu--secondary .nav__menu--column {
  display: flex;
  flex-direction: column;
  width: 100%;
}
@media (min-width: 1000px) {
  .nav__menu--secondary .nav__menu--column {
    width: 33%;
  }
}
.nav__menu--secondary .nav__menu--column .nav__item--secondary {
  width: auto;
}
.nav__inner__secondary {
  align-items: start;
}
@media (min-width: 1000px) {
  .nav__inner__secondary {
    padding-left: 1.875rem;
    flex: 1;
  }
}
@media (min-width: 1300px) {
  .nav__inner__secondary {
    background-color: #3d3c3c;
    display: flex;
    flex: unset;
    height: 34.375rem;
    justify-content: flex-end;
    left: 0;
    opacity: 0;
    padding: 1.5rem;
    position: absolute;
    right: 0;
    transform: translate3d(0, -120%, 0);
    transition: opacity 0.2s ease, visibility 0.2s ease 0.2s, transform 0.2s ease 0.2s;
    visibility: hidden;
    width: 100%;
  }
  .is-open .nav__inner__secondary {
    opacity: 1;
    visibility: visible;
    transform: translate3d(0, 0, 0);
    transition: opacity 0.2s ease;
  }
}
.nav__item {
  border-bottom: 1px solid #fff;
}
@media (min-width: 1300px) {
  .nav__item {
    border-bottom: 0;
  }
}
@media (min-width: 1300px) {
  .nav__item--secondary {
    display: inline-block;
    margin: 1.25rem 0;
    min-height: 3.75rem;
    padding-right: 1.5rem;
    width: 33.3333333333%;
  }
}
.nav__item--sub, .nav__item--secondary {
  border-bottom: 0;
}
.nav__item--search {
  order: -1;
}
@media (min-width: 1000px) {
  .nav__item--search {
    order: 0;
  }
}
.nav__item h2 {
  line-height: 0;
  padding-bottom: 1rem;
}
.nav__link {
  border: 0;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 2;
  padding: 0.25rem 0;
  text-decoration: none;
}
.nav__link:focus, .nav__link:hover {
  text-decoration: underline;
}
@media (min-width: 1300px) {
  .nav__link {
    color: #000;
  }
}
.nav__link--toplevel:focus, .nav__link--toplevel:hover {
  text-decoration: none;
}
@media (min-width: 1300px) {
  .nav__link--toplevel {
    backface-visibility: hidden;
    height: 6.875rem;
    line-height: 6.875rem;
    padding: 0 0.875rem;
    position: relative;
    z-index: 1;
    margin-left: -2px;
  }
  .nav__link--toplevel::before {
    backface-visibility: hidden;
    background-color: #fff;
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    transition: background-color 0.2s, color 0.2s ease;
    transform: skewX(-10deg);
  }
  .nav__link--toplevel:focus, .nav__link--toplevel:hover, .nav__link--toplevel.is-active, .nav__link--toplevel.is-current-page {
    outline: none;
  }
  .nav__link--toplevel:focus::before, .nav__link--toplevel:hover::before, .nav__link--toplevel.is-active::before, .nav__link--toplevel.is-current-page::before {
    background-color: #e2e2e2;
  }
  .nav__link--toplevel:focus::before {
    border: #18D0E4 solid 5px;
  }
  .nav__link--toplevel.is-current-page--hidden::before {
    background-color: #fff;
  }
}
@media (min-width: 1440px) {
  .nav__link--toplevel {
    padding: 0 1.125rem;
  }
}
@media (min-width: 1600px) {
  .nav__link--toplevel {
    padding: 0 1.25rem;
  }
}
.nav__link--header {
  display: inline-block;
  font-weight: 700;
  letter-spacing: -0.46px;
  line-height: 0;
}
@media (min-width: 1300px) {
  .nav__link--header {
    font-size: 1.25rem;
  }
}
.nav__link--sub, .nav__link--secondary {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.3;
  padding: 0.5rem 0;
}
@media (min-width: 1300px) {
  .nav__link--sub, .nav__link--secondary {
    padding: 0.375rem 0;
  }
}
.nav__link--search {
  overflow: visible;
}
.nav__link--search:focus .svg-icon--cross, .nav__link--search:hover .svg-icon--cross {
  color: #cc092f;
}
@media (min-width: 1300px) {
  .nav__link--search::before {
    right: -12px;
  }
  .nav__link--search.is-active .svg-icon--search, .nav__link--search.is-current-page .svg-icon--search {
    display: none;
  }
  .nav__link--search.is-active .svg-icon--cross, .nav__link--search.is-current-page .svg-icon--cross {
    display: inline-block;
  }
}
.nav__link--search .svg-icon {
  display: none;
}
@media (min-width: 1300px) {
  .nav__link--search .svg-icon {
    display: inline-block;
  }
}
@media (min-width: 1300px) {
  .nav__link--search .svg-icon--cross {
    display: none;
  }
}
@media (min-width: 1300px) {
  .nav__link--search .nav__link__label {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px !important;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
}
@media (min-width: 1300px) {
  .nav__link--secondary {
    border-bottom: 2px solid transparent;
    color: #fff;
    font-size: 2rem;
    font-weight: 600;
    transition: border-color 0.2s ease;
  }
  .nav__link--secondary:focus, .nav__link--secondary:hover {
    border-color: #fff;
    text-decoration: none;
  }
}
.nav__capabilities-link {
  display: inline-block;
  margin-top: 1rem;
  min-width: auto;
  font-size: 1rem;
  font-weight: 400;
}
.nav__capabilities-link:focus, .nav__capabilities-link:hover {
  text-decoration: underline;
}

.alpha-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 1.5rem 0;
  padding-left: 0;
}
.alpha-list__item {
  padding-right: 0.25rem;
}
.alpha-list__link {
  background-color: transparent;
  display: block;
  font-size: 1.5rem;
  padding: 0.25rem 0.375rem;
  transition: color 0.35s, background-color 0.35s ease;
}
.alpha-list__link:focus, .alpha-list__link:hover {
  background-color: #3d3c3c;
  color: #fff;
}

.people-searchform {
  margin: 0;
  padding-bottom: 1.25rem;
}
.people-searchform__fieldgroup {
  display: flex;
  max-width: 90%;
}
.people-searchform__field {
  flex: 1;
}
.people-searchform__field--button {
  flex: 0 0 auto;
  margin-left: 1.5rem;
}
.people-searchform__field--button .btn {
  height: 100%;
  width: 4rem;
}
.people-searchform__label {
  font-size: 1.5rem;
}
.people-searchform__input[type=text] {
  border: 0;
  font-size: 1.125rem;
  padding: 1.25rem 0 1.25rem 1.25rem;
  width: 100%;
}
.people-searchform__button {
  background-color: #000;
  border: 0;
  color: #fff;
}
.people-searchform__link {
  display: inline-block;
  margin-top: 1rem;
  min-width: auto;
  font-size: 1rem;
  font-weight: 400;
}
.people-searchform__link:focus, .people-searchform__link:hover {
  text-decoration: underline;
}

.globalsearch {
  align-items: center;
  background-color: #e2e2e2;
  display: flex;
  height: calc(100vh - 68px);
  justify-content: center;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 100.3125rem;
  position: fixed;
  right: 0;
  top: 68px;
  transform: translate3d(0, -130%, 0);
  transition: transform 0.35s ease, visibility 0.35s ease 0.35s;
  visibility: hidden;
  width: 100%;
  z-index: 99;
}
.globalsearch--internal {
  max-width: none;
}
.globalsearch.is-open {
  visibility: visible;
  transform: translate3d(0, -1px, 0);
  transition: transform 0.35s ease;
}
@media (min-width: 1300px) {
  .globalsearch {
    height: calc(100vh - 110px);
    top: 110px;
  }
}
.globalsearch__inner {
  padding: 1.5rem;
  width: 100%;
}
.globalsearch__form {
  margin-left: auto;
  margin-right: auto;
  max-width: 48rem;
}
@media (min-width: 1300px) {
  .globalsearch__form {
    max-width: 64rem;
  }
}
.globalsearch__fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
@media (min-width: 1300px) {
  .globalsearch__fieldset--control {
    text-align: center;
  }
}
.globalsearch__label {
  display: block;
  font-size: 1.375rem;
  margin-bottom: 1rem;
}
@media (min-width: 1300px) {
  .globalsearch__label {
    font-size: 2rem;
  }
}
.globalsearch__input[type=text] {
  background: transparent;
  border-color: #000;
  border-width: 0 0 1px 0;
  font-size: 1.5rem;
  margin-bottom: 3rem;
  padding: 0.25rem 0;
  width: 100%;
}
@media (min-width: 1300px) {
  .globalsearch__input[type=text] {
    margin-bottom: 4.5rem;
  }
}
.globalsearch__mobile-close {
  position: absolute;
  top: 15px;
  left: 5px;
}
@media (min-width: 1300px) {
  .globalsearch__mobile-close {
    display: none;
  }
}

.btn--sm, .btn--md {
  display: inline-block;
  font-weight: 600;
  min-width: 0;
  white-space: nowrap;
}
.btn--sm {
  font-size: 0.875rem;
  line-height: 1.3;
  padding: 0.375rem 0.75rem;
}
.btn--md {
  font-size: 1rem;
  padding: 0.5625rem 1.5rem;
}
.btn--icon {
  height: 2.5rem;
  min-width: 0;
  padding: 0;
  width: 2.5rem;
}
.btn--icon:focus, .btn--icon:hover {
  color: #fff;
}
.btn--icon .svg-icon {
  position: relative;
  z-index: 1;
}
.btn--search {
  font-size: 26px;
}
.btn--feature:focus, .btn--banner:focus {
  color: #000;
}
.btn--feature:focus::after, .btn--banner:focus::after {
  transform: skewX(-25deg) translateX(0);
}
.btn--black {
  border-color: #000;
  border-width: 1px;
  color: #000;
}
.btn--black::after {
  border: 1px solid #000;
  background-color: #000;
}
.btn--black:focus {
  color: #fff;
}
.btn--black:focus::after {
  transform: skewX(-25deg) translateX(0);
}

.card {
  margin-bottom: 1rem;
  width: 100%;
}
@media (min-width: 760px) {
  .card {
    margin-bottom: 1.875rem;
  }
}
@media (min-width: 1000px) {
  .card {
    flex: 1;
    flex-basis: auto;
    margin-bottom: 0;
  }
}
@media (min-width: 1000px) {
  .card--nested {
    margin-bottom: 0.9375rem;
  }
  .card--nested:last-child {
    margin-bottom: 0;
    margin-top: 0.9375rem;
  }
}
.card__link {
  display: block;
  overflow: hidden;
  position: relative;
}
@media (min-width: 760px) {
  .card__link {
    min-height: 18.75rem;
  }
  .card--event .card__link {
    min-height: 15rem;
  }
}
@media (min-width: 1000px) {
  .card__link {
    height: 100%;
  }
}
.card__link:focus .card__title, .card__link:hover .card__title {
  text-decoration: underline;
}
.card__link:focus .card__media__tag, .card__link:hover .card__media__tag {
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.7);
}
.card__link:focus .card__content, .card__link:hover .card__content {
  background-color: #f3f3f3;
}
.card__media {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  padding-bottom: 0;
  right: 0;
  top: 0;
  width: 100%;
}
.card--nav .card__media {
  position: relative;
  padding-bottom: 50%;
}
.card__media__image {
  bottom: 0;
  display: block;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  right: 0;
  top: 50%;
  transform: scale(1) translate3d(-50%, -50%, 0);
  transition: transform 0.8s ease 0.1s;
}
@supports (object-fit: cover) {
  .card__media__image {
    height: 100%;
    object-fit: cover;
    object-position: center;
    transform: scale(1) translate3d(-50%, -50%, 0);
    width: 100%;
  }
}
.card__link:focus .card__media__image, .card__link:hover .card__media__image {
  transform: scale(1.1) translate3d(-50%, -50%, 0);
}
.card__media__tag {
  background-color: #fff;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.7);
  display: block;
  font-weight: 700;
  left: 1rem;
  padding: 0.375rem 1rem;
  position: absolute;
  top: 1rem;
  transition: box-shadow 0.2s ease;
  z-index: 1;
}
.card__content {
  background-color: #fff;
  padding: 0.9375rem 1.25rem;
  transition: background-color 0.3s ease;
}
.card--nav .card__content {
  margin: 0;
}
@media (min-width: 1300px) {
  .card__content {
    margin: 1.875rem;
  }
  .card--event .card__content {
    margin: 1.25rem;
  }
}
.card__content--overlay {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 1.25rem;
  max-width: 18.75rem;
  min-height: 15rem;
  position: relative;
}
@media (min-width: 760px) {
  .card__content--overlay {
    min-height: 10rem;
  }
}
.card__title {
  display: inline-block;
  font-size: 1.375rem;
  line-height: 1.3;
  margin-bottom: 1rem;
  text-decoration: none;
}
.card--event .card__title, .card--nav .card__title {
  font-size: 1rem;
  font-weight: 700;
}
.card__title--external-link::after {
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23000000' fill-rule='evenodd'%3E%3Cpath d='M19.728 5v1.325h5.007L10 21.06l.94.94L25.675 7.265v5.007H27V5z'/%3E%3Cpath d='M21.685 25.685H6.315v-15.37h9.467V9H5v18h18V16.214h-1.315z'/%3E%3C/g%3E%3C/svg%3E");
  background-size: cover;
  content: "";
  display: inline-block;
  height: 1.25rem;
  margin-left: 0.375rem;
  width: 1.25rem;
}
.card__category {
  font-size: 0.75rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
  margin-top: 0.625rem;
  padding-top: 1rem;
  position: relative;
}
@media (min-width: 1300px) {
  .card__category {
    font-size: 0.875rem;
  }
}
.card__category::before {
  border-top: 1px solid #000;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  top: 0;
  width: 3.125rem;
}
.card__category--event {
  margin-top: 0;
  padding-top: 0;
}
.card__category--event::before {
  content: none;
}
.card__date {
  display: block;
  font-size: 0.75rem;
}
.card__date--large {
  font-size: 1.125rem;
  margin-bottom: 1.25rem;
}

.cardgroup {
  padding: 1.5rem 1.875rem;
}
@media (min-width: 1000px) {
  .cardgroup {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 3.125rem 1.875rem;
  }
}
@media (min-width: 1440px) {
  .cardgroup {
    padding-left: 85px;
    padding-right: 85px;
  }
}
.cardgroup--full {
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 1300px) {
  .cardgroup--full {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
}
@media (min-width: 1440px) {
  .cardgroup--full {
    padding-left: 85px;
    padding-right: 85px;
  }
}
@media (min-width: 1000px) {
  .cardgroup__cell {
    display: flex;
    flex-direction: column;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }
  @supports (display: grid) {
    .cardgroup__cell {
      display: grid;
      grid-auto-rows: 1fr;
    }
  }
}
@media (min-width: 1000px) {
  .cardgroup__cell--half {
    width: 50%;
  }
}
@media (min-width: 1000px) {
  .cardgroup__cell--third {
    width: 33.3333333333%;
  }
}

.hero-carousel {
  overflow: hidden;
  position: relative;
}
.hero-carousel::before, .hero-carousel::after {
  bottom: 0;
  content: "";
  display: block;
  height: 100%;
  left: 0%;
  opacity: 0.3;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(-10deg);
  transform-origin: top right;
  width: 100%;
  z-index: 1;
}
.hero-carousel::before {
  background-image: linear-gradient(70deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.75) 100%);
  top: 75%;
}
.hero-carousel::after {
  background-image: linear-gradient(70deg, rgba(0, 0, 0, 0) 26%, rgba(0, 0, 0, 0.75) 100%);
  top: calc(75% + 40px);
}
.hero-carousel__cell {
  width: 100%;
}
.hero-carousel__button {
  background-color: transparent;
  border: 0;
  bottom: 1.5rem;
  color: #fff;
  display: inline-block;
  padding: 0.5rem;
  position: absolute;
  right: 1rem;
  text-transform: uppercase;
  z-index: 3;
}
@media (min-width: 1300px) {
  .hero-carousel__button {
    bottom: 2.3125rem;
    padding: 0.75rem 0.5rem;
    font-size: 22px;
  }
}
@media (min-width: 1440px) {
  .hero-carousel__button {
    right: 3.625rem;
  }
}
.hero-carousel__button .svg-icon--play {
  display: none;
}
.hero-carousel__button.is-paused .svg-icon--play {
  display: block;
}
.hero-carousel__button.is-paused .svg-icon--pause {
  display: none;
}
.hero-carousel__button--hidden {
  display: none;
}

.hero-banner {
  background-color: #000;
  height: 25rem;
  padding-top: 4.25rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  position: relative;
}
@media (min-width: 760px) {
  .hero-banner {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
}
@media (min-width: 1300px) {
  .hero-banner {
    height: 40rem;
    padding-top: 6.875rem;
  }
}
@media (min-width: 1440px) {
  .hero-banner {
    padding-left: 6.25rem;
    padding-right: 6.25rem;
  }
}
.hero-banner__media {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
.hero-banner__media__image {
  display: block;
  height: 100%;
  width: 100%;
}
@supports (object-fit: cover) {
  .hero-banner__media__image {
    object-fit: cover;
    object-position: center;
  }
}
.hero-banner__content {
  color: #fff;
  margin-top: 1.875rem;
  max-width: 20rem;
  overflow: hidden;
  padding: 0.9375rem 0.9375rem 2.5rem 0.9375rem;
  position: relative;
  z-index: 1;
}
.hero-banner__content::after {
  background-color: #cc092f;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  opacity: 0.8;
  position: absolute;
  transform: skewY(-10deg);
  transform-origin: top left;
  transition: opacity 0.3s ease;
  top: 0;
  width: 100%;
  z-index: -1;
}
.hero-banner__content:hover::after {
  opacity: 0.9;
}
@media (min-width: 760px) {
  .hero-banner__content {
    max-width: 23.4375rem;
  }
}
@media (min-width: 1000px) {
  .hero-banner__content {
    max-width: 33.3333333333%;
    min-height: 15.625rem;
    padding: 1.875rem 1.875rem 2.5rem;
  }
}
@media (min-width: 1300px) {
  .hero-banner__content {
    margin-top: 5.625rem;
    min-height: 21.875rem;
  }
}
.hero-banner__content__title {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.2;
  margin-bottom: 1.5rem;
}
@media (min-width: 1300px) {
  .hero-banner__content__title {
    font-size: 2.25rem;
    margin-bottom: 2.25rem;
  }
}

.hero-carousel .flickity-page-dots {
  bottom: 2rem;
  padding-right: 3.5rem;
  text-align: right;
  z-index: 2;
}
@media (min-width: 1300px) {
  .hero-carousel .flickity-page-dots {
    bottom: 3rem;
  }
}
@media (min-width: 1440px) {
  .hero-carousel .flickity-page-dots {
    padding: 0 6.25rem;
  }
}
.hero-carousel .flickity-page-dots .dot {
  border-color: #fff;
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.5);
}
.hero-carousel .flickity-page-dots .dot:focus {
  outline: 5px solid #18D0E4;
}
.hero-carousel .flickity-page-dots .dot.is-selected {
  background: #cc092f;
  border-color: #cc092f;
}

.region-carousel {
  padding-bottom: 3.125rem;
}
.region-carousel::after {
  content: "flickity";
  display: none;
}
@media (min-width: 1300px) {
  .region-carousel::after {
    content: "";
  }
}
@media (min-width: 1300px) {
  .region-carousel {
    display: flex;
    margin: 0 0.9375rem;
  }
}
@media (min-width: 1440px) {
  .region-carousel {
    margin: 0 5.3125rem;
  }
}
.region-carousel .flickity-page-dots {
  bottom: 0;
}
.region-carousel__cell {
  width: 100%;
}
@media (min-width: 760px) {
  .region-carousel__cell {
    width: 50%;
  }
}
@media (min-width: 1300px) {
  .region-carousel__cell {
    width: 25%;
  }
}

.region-carousel .flickity-prev-next-button {
  background-color: rgba(61, 60, 60, 0.8);
  border-radius: 0;
  bottom: 0;
  height: 30px;
  top: auto;
  transform: none;
  width: 30px;
  z-index: 1;
}
.region-carousel .flickity-prev-next-button .arrow {
  fill: #fff;
}
.region-carousel .flickity-prev-next-button.previous {
  left: 20px;
}
.region-carousel .flickity-prev-next-button.next {
  left: 80px;
  right: auto;
}
.region-carousel .flickity-prev-next-button:focus, .region-carousel .flickity-prev-next-button:hover {
  background-color: #3d3c3c;
}

.regioncard {
  margin: 0.625rem 0.9375rem;
  overflow: hidden;
  padding: 0.0625rem 0.3125rem 0.3125rem;
  position: relative;
}
@media (min-width: 1300px) {
  .regioncard {
    height: 100%;
  }
}
.regioncard__content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1.875rem 1.875rem 3.75rem;
  position: relative;
  z-index: 2;
}
.regioncard__content::before, .regioncard__content::after {
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.5);
  content: "";
  display: block;
  left: 0;
  position: absolute;
  width: 100%;
}
.regioncard__content::after {
  height: 1px;
  top: -3px;
}
.regioncard__content::before {
  background: #f3f3f3;
  bottom: 0;
  top: 0;
  transform: skewY(-10deg);
  transform-origin: top left;
  z-index: -1;
}
.regioncard__content__footer {
  margin-top: auto;
  position: relative;
  z-index: 2;
}
.regioncard__content__summary {
  font-family: "Lora";
  line-height: 1.3;
  padding-bottom: 1.5rem;
  position: relative;
  z-index: 1;
}
@media (min-width: 1000px) {
  .regioncard__content__summary {
    padding-bottom: 3rem;
  }
}
.regioncard__title {
  color: #cc092f;
  font-size: 1.875rem;
  font-weight: 900;
  margin-bottom: 1.5rem;
}
.regioncard__subtitle {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.focus-list {
  list-style: none;
  margin-top: 3.125rem;
  padding: 0 1.875rem;
}
@media (min-width: 760px) {
  .focus-list {
    display: flex;
    flex-wrap: wrap;
  }
}
@media (min-width: 1300px) {
  .focus-list {
    margin-top: 3rem;
  }
}
@media (min-width: 1440px) {
  .focus-list {
    padding: 0 6.25rem;
  }
}
.focus-list__item {
  margin-bottom: 1.5rem;
}
.focus-list__item:last-child {
  margin-bottom: 0;
}
@media (min-width: 760px) {
  .focus-list__item {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    width: 50%;
  }
}
@media (min-width: 1300px) {
  .focus-list__item {
    margin-bottom: 3rem;
    width: 33.3333333333%;
  }
}
.focus-list__link {
  color: #fff;
  display: block;
  font-size: 1.125rem;
  font-weight: 600;
  letter-spacing: -0.2px;
  text-align: center;
  padding: 0.75rem;
  position: relative;
  transition: color 0.3s ease;
  z-index: 1;
  outline: none;
}
.focus-list__link::before {
  background-color: transparent;
  border: 2px solid #d8d8d8;
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: skewX(-30deg);
  transition: background-color 0.3s, border-color 0.3s ease;
  z-index: -1;
}
.focus-list__link:focus, .focus-list__link:hover {
  color: #3d3c3c;
}
.focus-list__link:focus::before, .focus-list__link:hover::before {
  background-color: #fff;
  border-color: #fff;
}
.focus-list__link:focus {
  outline: none;
}
.focus-list__link:focus::before {
  border: #18D0E4 solid 5px;
}
@media (min-width: 1300px) {
  .focus-list__link {
    font-size: 1.625rem;
    padding: 1.375rem 0.375rem;
  }
}

@media (min-width: 1000px) {
  .featuregroup {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  @supports (display: grid) {
    .featuregroup {
      display: grid;
      grid-auto-rows: 1fr;
      grid-template-columns: 1fr 1fr;
    }
  }
}
.featuregroup__cell {
  width: 100%;
}
@media (min-width: 1000px) {
  .featuregroup__cell--half {
    width: 50%;
  }
  @supports (display: grid) {
    .featuregroup__cell--half {
      width: 100%;
    }
  }
}

.feature {
  background-color: #000;
  color: #fff;
  overflow: hidden;
  position: relative;
}
@media (min-width: 1000px) {
  .feature {
    height: 100%;
    min-height: 25rem;
    max-height: 46.875rem;
  }
}
.feature--block {
  background-color: #fff;
  color: inherit;
}
.feature__media {
  overflow: hidden;
  position: relative;
  padding-bottom: 50%;
}
@media (min-width: 1000px) {
  .feature__media {
    bottom: 0;
    display: block;
    left: 0;
    padding-bottom: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
}
.feature__media__image {
  bottom: 0;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
@media (min-width: 1000px) {
  .feature__media__image {
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: auto;
  }
  @supports (object-fit: cover) {
    .feature__media__image {
      height: 100%;
      left: 0;
      min-height: 0;
      min-width: 0;
      object-fit: cover;
      object-position: center;
      top: 0;
      transform: none;
      width: 100%;
    }
  }
}
.feature__content {
  padding: 1.875rem;
}
@media (min-width: 1000px) {
  .feature--block .feature__content {
    background-color: #fff;
    box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.5);
    margin: 1.875rem;
    max-width: 50%;
    padding: 1.25rem;
    position: relative;
    z-index: 1;
  }
}
@media (min-width: 1440px) {
  .feature--block .feature__content {
    margin: 3.75rem 3.75rem 3.75rem 6.25rem;
    max-width: calc(50% - 100px);
  }
}
@media (min-width: 1600px) {
  .feature__content {
    padding: 3.75rem 6.25rem;
  }
  .feature--block .feature__content {
    padding: 1.875rem;
  }
}
.feature__content__body {
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.4;
  margin-bottom: 2.625rem;
}
.feature--block .feature__content__body {
  font-family: "Lora";
  font-weight: 400;
}
@media (min-width: 1000px) {
  .feature--block .feature__content__body {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }
}
@media (min-width: 1300px) {
  .feature__content__body {
    font-size: 1.5rem;
  }
}
.feature__title {
  font-size: 1.625rem;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 1.5rem;
}
.feature--gradient {
  z-index: 1;
}
.feature--gradient::before, .feature--gradient::after {
  bottom: 0;
  content: "";
  display: block;
  height: 100%;
  left: 0%;
  opacity: 0.25;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(10deg);
  transform-origin: top left;
  width: 100%;
  z-index: -1;
}
.feature--gradient::before {
  background-image: linear-gradient(-70deg, rgba(0, 0, 0, 0) 50%, rgb(0, 0, 0) 100%);
  opacity: 0.25;
  top: 40%;
}
@media (min-width: 1300px) {
  .feature--gradient::before {
    top: 35%;
  }
}
.feature--gradient::after {
  background-image: linear-gradient(-70deg, rgba(0, 0, 0, 0) 26%, rgb(0, 0, 0) 100%);
  top: calc(40% + 40px);
}
@media (min-width: 1300px) {
  .feature--gradient::after {
    top: calc(35% + 40px);
  }
}
.feature--black {
  background-color: #000;
}
.feature__title--black {
  color: #000;
}
.feature--red {
  background-color: #cc092f;
}
.feature__title--red {
  color: #cc092f;
}
.feature--light_grey {
  background-color: #f3f3f3;
}
.feature__title--light_grey {
  color: #f3f3f3;
}
.feature--mid_grey {
  background-color: #e2e2e2;
}
.feature__title--mid_grey {
  color: #e2e2e2;
}
.feature--grey {
  background-color: #4a4a4a;
}
.feature__title--grey {
  color: #4a4a4a;
}
.feature--dark_grey {
  background-color: #3d3c3c;
}
.feature__title--dark_grey {
  color: #3d3c3c;
}
.feature--purple {
  background-color: #8d338a;
}
.feature__title--purple {
  color: #8d338a;
}
.feature--orange {
  background-color: #e76d27;
}
.feature__title--orange {
  color: #e76d27;
}

.blockquote-feature {
  font-family: "Lora";
  font-size: 1.375rem;
  font-style: italic;
  line-height: 1.4;
  margin: 0;
  padding: 0;
}
.blockquote-feature__cite {
  display: block;
  font-family: "Open Sans";
  font-size: 1rem;
  font-style: normal;
  margin-top: 0.875rem;
}

.quote-carousel {
  padding-bottom: 3.125rem;
  position: relative;
}
@media (min-width: 1600px) {
  .quote-carousel {
    padding-bottom: 5rem;
  }
}
.quote-carousel__cell {
  width: 100%;
}
.quote-carousel__cell * a {
  text-decoration: underline;
}

.quote-carousel .flickity-prev-next-button {
  border-radius: 0;
  bottom: 0;
  height: 30px;
  top: auto;
  transform: none;
  width: 30px;
}
.quote-carousel .flickity-prev-next-button.previous {
  left: 0;
}
.quote-carousel .flickity-prev-next-button.next {
  left: 60px;
  right: auto;
}

@media (min-width: 1000px) {
  .banner {
    display: flex;
    max-height: 40.625rem;
    min-height: 32.25rem;
    overflow: hidden;
    position: relative;
  }
}
.banner__inner {
  position: relative;
}
@media (min-width: 1000px) {
  .banner__inner {
    padding: 1.875rem;
    width: 100%;
  }
}
@media (min-width: 1440px) {
  .banner__inner {
    padding: 2.8125rem 6.25rem;
  }
}
.banner__media {
  overflow: hidden;
  position: relative;
  padding-bottom: 50%;
}
@media (min-width: 1000px) {
  .banner__media {
    bottom: 0;
    left: 0;
    padding-bottom: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
.banner__media__image {
  bottom: 0;
  display: block;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: auto;
}
@supports (object-fit: cover) {
  .banner__media__image {
    height: 100%;
    left: 0;
    min-height: 0;
    min-width: 0;
    object-fit: cover;
    object-position: right;
    top: 0;
    transform: none;
    width: 100%;
  }
}
.banner__content {
  background-color: #000;
  color: #fff;
  font-weight: 300;
  line-height: 1.4;
  padding: 2.5rem 1.875rem 1.875rem;
}
@media (min-width: 1000px) {
  .banner__content {
    background-color: transparent;
    font-size: 1.5rem;
    position: relative;
    overflow: hidden;
    padding: 1.875rem 1.875rem 3.75rem;
    width: 33.3333333333%;
    z-index: 1;
  }
  .banner__content::after {
    background-color: #000;
    content: "";
    display: block;
    height: 100%;
    left: 0;
    opacity: 0.8;
    position: absolute;
    transform: skewY(-10deg);
    transform-origin: top left;
    transition: opacity 0.3s ease;
    top: 0;
    width: 100%;
    z-index: -1;
  }
  .banner__content:hover::after {
    opacity: 0.9;
  }
}
@media (min-width: 1440px) {
  .banner__content {
    padding: 2.5rem 2.5rem 4.375rem;
  }
}
.banner__title {
  font-size: 1.625rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}
.banner--black .banner__content {
  background-color: #000;
}
.banner--black .banner__content::after {
  background-color: #000;
}
@media (min-width: 1000px) {
  .banner--black .banner__content {
    background-color: transparent;
  }
}
.banner--red .banner__content {
  background-color: #cc092f;
}
.banner--red .banner__content::after {
  background-color: #cc092f;
}
@media (min-width: 1000px) {
  .banner--red .banner__content {
    background-color: transparent;
  }
}
.banner--light_grey .banner__content {
  background-color: #f3f3f3;
}
.banner--light_grey .banner__content::after {
  background-color: #f3f3f3;
}
@media (min-width: 1000px) {
  .banner--light_grey .banner__content {
    background-color: transparent;
  }
}
.banner--mid_grey .banner__content {
  background-color: #e2e2e2;
}
.banner--mid_grey .banner__content::after {
  background-color: #e2e2e2;
}
@media (min-width: 1000px) {
  .banner--mid_grey .banner__content {
    background-color: transparent;
  }
}
.banner--grey .banner__content {
  background-color: #4a4a4a;
}
.banner--grey .banner__content::after {
  background-color: #4a4a4a;
}
@media (min-width: 1000px) {
  .banner--grey .banner__content {
    background-color: transparent;
  }
}
.banner--dark_grey .banner__content {
  background-color: #3d3c3c;
}
.banner--dark_grey .banner__content::after {
  background-color: #3d3c3c;
}
@media (min-width: 1000px) {
  .banner--dark_grey .banner__content {
    background-color: transparent;
  }
}
.banner--purple .banner__content {
  background-color: #8d338a;
}
.banner--purple .banner__content::after {
  background-color: #8d338a;
}
@media (min-width: 1000px) {
  .banner--purple .banner__content {
    background-color: transparent;
  }
}
.banner--orange .banner__content {
  background-color: #e76d27;
}
.banner--orange .banner__content::after {
  background-color: #e76d27;
}
@media (min-width: 1000px) {
  .banner--orange .banner__content {
    background-color: transparent;
  }
}

.page-section {
  margin-left: auto;
  margin-right: auto;
  max-width: 100.3125rem;
  padding: 3.125rem 0;
}
.page-section__topics {
  max-width: none;
  margin-bottom: 3.125rem;
}
.page-section--no-padding {
  padding: 0;
}
.page-section__header {
  text-align: center;
}
.page-section__title {
  font-weight: 800;
  font-size: 1.875rem;
  margin-bottom: 0;
}
.page-section__title--white {
  color: #fff;
}
.page-section__footer .btn {
  vertical-align: top;
}
.page-section__footer--center {
  text-align: center;
}
.page-section--full .page-section__footer {
  padding-left: 1.875rem;
  padding-right: 1.875rem;
}
@media (min-width: 1300px) {
  .page-section--focus {
    padding-top: 9.375rem;
  }
}
@media (min-width: 1440px) {
  .page-section--focus {
    padding-top: 15.625rem;
  }
}
.page-section--gradient {
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.page-section__gradient::before, .page-section__gradient::after {
  bottom: 0;
  content: "";
  display: block;
  height: 100%;
  left: 0%;
  opacity: 0.3;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(-10deg);
  transform-origin: top right;
  width: 100%;
  z-index: -1;
}
.page-section__gradient--bottom::before {
  background-image: linear-gradient(70deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.75) 100%);
  top: 75%;
}
.page-section__gradient--bottom::after {
  background-image: linear-gradient(70deg, rgba(0, 0, 0, 0) 26%, rgba(0, 0, 0, 0.75) 100%);
  top: calc(75% + 40px);
}
@media (min-width: 1300px) {
  .page-section__gradient--bottom::after {
    top: calc(75% + 80px);
  }
}
.page-section__gradient--top::before, .page-section__gradient--top::after {
  transform-origin: bottom left;
  top: auto;
}
.page-section__gradient--top::before {
  background-image: linear-gradient(-70deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.75) 100%);
  bottom: 75%;
}
@media (min-width: 1300px) {
  .page-section__gradient--top::before {
    bottom: 45%;
  }
}
.page-section__gradient--top::after {
  background-image: linear-gradient(-70deg, rgba(0, 0, 0, 0) 26%, rgba(0, 0, 0, 0.75) 100%);
  bottom: calc(75% + 40px);
}
@media (min-width: 1300px) {
  .page-section__gradient--top::after {
    bottom: calc(45% + 80px);
  }
}
.page-section--region {
  position: relative;
}
@media (min-width: 1300px) {
  .page-section--region + .page-section--focus {
    margin-top: -7.5rem;
  }
}
@media (min-width: 1440px) {
  .page-section--region + .page-section--focus {
    margin-top: -12.5rem;
  }
}
.page-section--black {
  background-color: #000;
}
.page-section--red {
  background-color: #cc092f;
}
.page-section--light_grey {
  background-color: #f3f3f3;
}
.page-section--mid_grey {
  background-color: #e2e2e2;
}
.page-section--grey {
  background-color: #4a4a4a;
}
.page-section--dark_grey {
  background-color: #3d3c3c;
}
.page-section--purple {
  background-color: #8d338a;
}
.page-section--orange {
  background-color: #e76d27;
}

.cta-group {
  padding: 1.5625rem 1.875rem;
}
@media (min-width: 760px) {
  .cta-group--mobile {
    display: none;
  }
}
.cta-group--red {
  background-color: #cc092f;
}
.cta-group__item {
  margin-bottom: 1.5625rem;
}
.cta-group__item:last-child {
  margin-bottom: 0;
}