.related-professionals-intro {
	max-width: px-to-em($content-max-width-small);
	margin: 0 auto $section-spacing;
}

.related-professionals-grid {
	$grid-spacing: px-to-em(15px);

	display: flex;
	flex-wrap: wrap;
	margin-right: $grid-spacing * -1;

	.professional-card {
		margin-right: $grid-spacing;
		width: 48%;
		flex-grow: 0;
	}

}

.related-professionals-team-view {
	text-align: center;
	margin-top: $section-spacing;
	//commenting this out for now, based on finding the right fix for RS-80
	//padding-bottom: $section-spacing; 
}

@include screen($bp-tablet) {
	.related-professionals-grid  {

		display: block;
		margin-right: auto;

		.professional-card {
			margin-right: auto;
			margin-left: auto;
			width: 90%;
		}
	}

	.related-professionals-team-view {
		margin-top: $section-spacing-mobile;
	}
}


@include screen($bp-mobile) {
	.related-professionals-grid  {

		.professional-card {
			width: auto;
		}
	}

}
