$tabs-base-width: 220px;

.tabs-nav {
	display: flex;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: stretch;
	position: relative;
}

.tabs-nav-item {
	flex-basis: 25%;
	padding-right: $grid-spacing;

	&:last-child {
		padding-right: 0;
	}
}


.tabs-nav-btn {
	@extend %uc;

	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	text-align: center;
	background-color: $color-brand-gold;
	font-weight: $font-weight-extra-bold;
	color: $color-text-copy;
	padding: px-to-em(8px) px-to-em(5px);
	cursor: pointer;
	height: 100%;

	@include transition(background-color);

	.is-selected & {
		background-color: $color-brand-white;
		cursor: text;
	}
	&:hover {
		background-color: $color-brand-white;
	}
	&.link-hover {
		cursor: pointer;
	}
}

.tabs-nav-mobile-toggle {
	@extend %uc;

	background-color: $color-brand-gold;
	font-weight: $font-weight-extra-bold;
	color: $color-text-copy;
	padding:$element-spacing-small $element-spacing-small;
	padding-right: $section-spacing-small * 1.5;
	position: relative;
	width: 100%;
	text-align: left;
	display: none;

	@include ico-icon-after($icon-chevron-down) {
        $icon-size: 5px;

        @include center(vertical);

        font-size: px-to-em($icon-size);
        right: 1.3em;
    }
}

@include screen($bp-mobile) {
	.tabs-nav-wrapper {
		position: relative;
		z-index: 10;
	}

	.tabs-nav {
		display: block;
		position: absolute;
		top: 100%;
		width: 100%;
		overflow: hidden;
		max-height: 0;
		transition: max-height $transition-timing $transition-ease;


		.show-menu &{
			max-height: px-to-em(600px);
		}
	}

	.tabs-nav-item {
		margin: 0;
		padding-right: 0;
		text-align: left;
	}

	.tabs-nav-btn {
		text-align: left;
		padding:$element-spacing-small;
	}

	.tabs-nav-mobile-toggle {
		display: block;
	}
}
