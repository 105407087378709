.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 40%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  font-family: $font-family-open-sans;

  @include screen($bp-tablet) {
    z-index: 501;
    width: 100%;
    padding: 5px;
  }
}

.modal-header h4 {
  margin-top: 0;
  color: $color-brand-black;
  font-weight: $font-weight-bold;
}

.modal-body {
  margin: 20px 0;
  color: $color-brand-black;
}

.modal-footer {
  padding-bottom: 20px;
  color: $color-brand-black;
}

.modal-default-button {
  float: right;
  &.btn-left {
    padding-right: 10px;
  }
}

.modal-lock {
  overflow: hidden;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
