.js-feature-hover {

  .js-feature-hover-target {
  	position: relative;
  	overflow: hidden;
    > * { z-index: 0; }

    > .background-image {
      -webkit-transition: all .6s ease-in;
      transition: all .6s ease-in;
      background-size: cover;
      background-position: center;

      &:before {
        opacity: 0;
    		@include transition(all);
    		@include absolute-full;

    		content: " ";
    		background-color: $color-opaque-black-light;
    		z-index: 3;
    	}
    }

    &.active {

      > .background-image {
          transform: scale(1.2);
          &:before {
            opacity: 1;
      			background-color: $color-opaque-black-light;
      		}
      }

  	}
  }
}
