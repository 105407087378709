.around-office {
	overflow: hidden;
	position: relative;
	margin-bottom: $section-spacing-module;
	//min-height: 100vh;
}

.around-office-reel {
	white-space: nowrap;
	margin-top: $section-spacing;

	.around-corner-tile {
		display: inline-block;
		white-space: normal;
		vertical-align: top;

		& + .around-corner-tile{
			margin-left: $section-spacing;
		}
	}

	.article-tile {
		display: inline-block;
		white-space: normal;
		vertical-align: top;

		& + .article-tile {
			margin-left: $section-spacing;
		}
	}
}


@include screen($bp-tablet-landscape) {

	.around-office-reel {
		white-space: normal;

		.around-corner-tile {
			display: block;

			& + .around-corner-tile {
				margin-left: auto;
				margin-top: $section-spacing-mobile;
			}
		}

		.article-tile {
			display: block;

			& + .around-corner-tile,
			& + .article-tile {
				margin-left: auto;
				margin-top: $section-spacing-mobile;
			}
		}
	}
}

@include screen($bp-tablet) {
	.around-office-reel {
		margin-top: $section-spacing-mobile; 
	}
}

@include screen($bp-mobile) {
	.around-office {
		margin-bottom: $section-spacing-module-mobile;

		.around-corner-tile {
			& + .around-corner-tile {
				margin-top: $section-spacing-mobile;
			}
		}

		.article-tile {
			& + .article-tile {
				margin-top: $section-spacing-mobile;
			}
		}
	}

	.around-office-reel {
		overflow: visible;
	}
}
