.page-capability-detail {

	.read-more-toggle {
		margin-left: auto;
		margin-right: auto;
		display: block;
	}

	.inner-content {
		padding-bottom: 0;
	}

	.angled-hero {
		margin-bottom: 5em;
	}

	.flexible-panel-overview {
		.silent-wrapper {
			@include page-wrapper;
		}
	}

	@include screen($bp-tablet-landscape) {
		.angled-hero {
			margin-bottom: 0;
		}
	}

	&__forecast {
		
	
	}	
}
