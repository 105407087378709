.global-locations {
    $deg: 6;
    $wHeight: 300;
    $hero-width: 90%;
    $wedgeHeight: $wHeight*1px;
    $rate: calc(calc($wHeight / 2) / $deg) * 0.0102;
    $wedgeDisplacement: ceil(calc($wHeight / 2) * ($rate * $deg)) * 1px;

    width: 100vw;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $wedgeDisplacement 0;

    .skew-container {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;

      &:before,
      &:after {
        content: " ";
        position: absolute;
        z-index: 100;
        left: 0;
        height: $wedgeHeight;
        background: $color-brand-white;
        width: 100%;
        transform: skewY(-($deg*1deg));
      }
      &:before {
        top: $rate;
        top: -($wedgeDisplacement);
      }
      &:after {
        bottom: -($wedgeDisplacement);
      }

      .background-image {
        position: relative;
        z-index: 0;
        overflow: hidden;
        background-position: center;
        background-size: cover;
        width: 100%;
        height: 100%;
      }

    }

    .global-hero{
      position: relative;
      width: $hero-width;
      margin: 5% auto;
    }
    .silent-wrapper{
        position: relative;
        background-color: rgba(255,255,255,0.9);
        text-align: center;
        padding: px-to-em(80px);
    }

    .section-header{
        margin-bottom: px-to-em(40px);

    }
    .link-external{
        @include font-open-sans-extra-bold(36px);
        text-transform: uppercase;
        @include link-transition($color-brand-red, $color-brand-black);
        .icon-external_link{
            display: inline-block;
            @include ico-icon-before($icon-external_link)
        }
    }

    @include screen($bp-mobile) {
        .section-header{
            .section-header-title{
                display: inline-block;
            }
        }
        .silent-wrapper{
            position: relative;
            background-color: rgba(255,255,255,0.9);
            text-align: center;
            padding: px-to-em(40px);
        }
        .link-external{
            @include font-open-sans-extra-bold(26px);
            .icon-external_link{
                @include ico-icon-before($icon-external_link)
            }
        }
    };
}
