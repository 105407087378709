.probono-carousel {
 height: 30em;
  width: 90%;
  margin: 0 auto;
  position: absolute;
  z-index: 1;
  bottom: px-to-em(-180px);
  left: 50%;
  transform: translateX(-50%);
  -webkit-box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.2);
  .hero-carousel__button{
      position: initial !important;
      right: 0 !important;
  }
  .hero-carousel__button{
      right: auto;
  }
  .flickity-page-dots{
      padding-top: 1rem;
      position: initial;
      width: auto;
      .is-selected.diversity__green{
          background: $diversity-green;
      }
      .is-selected.diversity__blue{
          background: $diversity-blue;
      }
  }
  &__controls{
    bottom: 0.75rem;
    display: none;
    height: 2.75rem;
    position: absolute;
    right: 3.75em;
    .is-paused .svg-icon--play.svg-icon--play{
        display: inline-block;
    }
    .is-active .svg-icon--play.svg-icon--play{
        display: none;
    }
    .svg-icon{
      vertical-align: text-top;
    }
  }
  &__slide {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-position-x: 80%;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__text-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 75%;
    background: transparent; /* For browsers that do not support gradients */
    background: linear-gradient(to top, rgba(0,0,0,0.85), rgba(0,0,0,0));
    padding: px-to-em(35px);
  }

  &__title {
    @include font-open-sans-regular($font-size-h4-mobile);
    @include transition(font-size);
    color: $color-brand-white;
    line-height: $font-line-height-2;
    text-align: center;
    
    /*&-body {
      background-color: rgba(255, 255, 255, 0.65);
      padding: 20px; 
    }*/
  }

  &__body {
    @include font-open-sans-regular($font-size-h5-mobile);
    @include transition(font-size);
    color: $color-brand-white;
    line-height: $font-line-height-2;
    width: 55%;
    display: none;
  }

  &__button-ghost {
    @extend %uc;

    display: inline-block;
    font-weight: $font-weight-bold;
    font-size: $font-size-h6-mobile;
    width: 100%;
    padding: 0.75em 1.5em;
    margin-top: px-to-em(30px);
    border: 1px solid $diversity-green;
    border-radius: 0;
    overflow: hidden;
    position: relative;
   text-align: center;

    @include button-transition-green();

    .btn-ghost-label--video {
      @include ico-icon-after($icon-video-circle) {
          margin-left: 0.5em;
          margin-top: 0.1em;
      }
    }
  }

  &__mobile-btn-container {
    display: flex;
    flex-direction: row;
    position: relative;
    top: 30em;
  }

  &__trigger-btn {
    background-color: $diversity-green;
    color: $color-brand-white;
    width: 33%;
    height: px-to-em(55px);
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    -webkit-border-radius: 0px;
    border-right: 1px solid $color-brand-white;
    transition: all ease-in-out .15s;
    .hidden{
        display: none;
    }
    &:last-child {
      border-right: 0;
    }
    &:focus{
        z-index: 2;
    }
    &.diversity-carousel__trigger-btn--pause{
        background-color: $color-brand-white !important;
    }
  }

  &__lg-trigger-btn {
    display: none;
    background-color: $diversity-green;
    color: $color-brand-white;
    width: 75px;
    height: 210px;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    -webkit-border-radius: 0px;
    border: none;
    transition: all ease-in-out .15s;
    -webkit-box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.2);

    &:hover {
      background-color: #23bc55;
    }
  }

  &--topics {
      position: relative;
      transform: none;
      left: unset;
      bottom: 0;
      margin-bottom: px-to-em(115px);
  }

  @include screen($bp-mobile, "min") {
    bottom: px-to-em(-194px);
    &--topics {
      bottom: px-to-em(25px);
    }
  }

  @include screen($bp-between-tablet, "min") {
    width: 75%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    &__mobile-btn-container {
      display: none;
    }
    &__controls{
        display: inline-flex;
    }
    &__button-ghost {
      width: px-to-em(205px);
    }

    &__text-container {
      height: 100%;
      justify-content: space-between;
      align-items: flex-start;
      padding: px-to-em(60px);
      background: transparent;
    }

    &__title-body {
      width: 60%;
    }

    &__body {
      width: 100%;
    }

    &__title {
      @include font-open-sans-light($font-size-h3);
      color: $diversity-dark-gray;
      line-height: 42px;
      text-align: left;
      width: 90%;
      padding-bottom: 15px;
    }

    &__body {
      display: block;
      color: $diversity-dark-gray;
    }

    &__lg-trigger-btn {
      display: block;
      position: relative;

      &--back {
        left: -4.4em;
        z-index: -1;
      }

      &--forward {
        left: 4.5em;
        z-index: -1;
      }
    }
  }

  @include screen($bp-desktop-large, "min") {
    width: 64%;
  }
}

.slide-enter-active {
  transition: 400ms;
}

.slide-enter.forward {
  transform: translateX(100px);
  opacity: 0;
}

.slide-enter.backward {
  transform: translateX(-100px);
  opacity: 0;
}
