.gold-tabs {
    margin-bottom: 5em;

    &-title-wrapper {
        display: flex;
        flex-flow: row-reverse;
        justify-content: space-between;
        align-items: center;
    }

    &-mobile-toggle {
        @extend %uc;

        @include font-open-sans-extra-bold();

        display: none;
        width: 100%;
        padding: 0.5em $section-spacing-small;
        background-color: $color-brand-gold;
        overflow: hidden;
        color: $color-brand-black;
        text-align: left;
        position: relative;

        @include ico-icon-after($icon-chevron-down) {
            @include center(vertical);

            $title-size: 10px;
            font-size: px-to-em($title-size);
            right: px-to-em( $section-spacing-small-px, $title-size);
        }
    }

    &-nav {
        display: flex;
        align-items: center;
        position: relative;

         &:before {
            content: '';
            position: absolute;
            top: 50%;
            display: block;
            left: 0;
            width: 100%;
            height: 1px;
            border-top: 1px solid #777;
            z-index: -1;
        }
    }

    &-nav-option {
        flex-basis: 25%;
        padding-right: 2%;

        &:last-child {
            padding-right: 0;
        }

        &--is-active {
            .gold-tabs-btn {
                background-color: $color-text-gray;
            }
        }
    }

    &-btn {
        @extend %uc;

        @include font-open-sans-extra-bold();

        padding: 0.75em 1em;
        overflow: hidden;
        position: relative;
        text-align: center;
        width: 100%;
        height: px-to-em(70px);

        @include button-transition($background-color:$color-text-gold, $text-color: $color-brand-white, $hover-background-color: $color-brand-black)
    }

    &-btn-label {
        position: relative;
        z-index: 2;
    }

    &-frame {
        margin-top: $section-spacing-module;
        position: relative;
        overflow: hidden;
    }

    &-frame-shrinkmargin {
        margin-top: 0;
    }

    &-frame-offices {
        position: relative;
        overflow: hidden;
    }

    &-menu-title-tabs {
        width: 100%;
    }

    &-title-header {
        width: 20%;
        margin-bottom: 0;
    }

    &-section-title {
        background-color: $color-brand-white;
        padding-left: 0.625em;
        display: inline-block;
        font-size: 1.5em;
        font-weight: 800;
        line-height: 1.1;
        position: relative;

        &::before {
            position: absolute;
            content: '';
            display: block;
            right: 100%;
            margin-left: $site-padding-mobile;
            width: px-to-em(1000px);
            top: 50%;
            margin-top: -2px;
            border-top: 1px solid #777;
            transform: translateZ(0);
        }
    }

    @include screen($bp-between-desktop) {

        &-title-wrapper {
            display: block;
        }

        &-title-header {
            width: 100%;
            margin-bottom: 5em;
        }

        &-section-title {
            &::after {
                position: absolute;
                content: '';
                display: block;
                left: 100%;
                margin-left: $site-padding-mobile;
                width: px-to-em(1000px);
                top: 50%;
                margin-top: -2px;
                border-top: 1px solid #777;
                transform: translateZ(0);
            }
        }

        &-nav-full {
            width: 100%;
            justify-content: space-between;
        }

        &-frame-shrinkmargin {
            margin-top: 5em;
        }
    }

    @include screen($bp-tablet) {

        &-frame {
            margin-top: $section-spacing-module-mobile;
            z-index: 0;
        }

        &-frame-offices {
            margin-top: $section-spacing-module-mobile;
        }
    }

    @include screen($bp-mobile) {
        margin-bottom: 4em; 

        &-mobile-toggle {
            display: block;
        }

        &-menu {
            position: relative;
            z-index: 15;
        }

        &-nav {
            position: absolute;
            width: 100%;
            top: 40px;
            max-height: 0;
            opacity: 0;
            display:block;
            overflow: hidden;
            transition: max-height $transition-timing $transition-ease,
                        opacity $transition-timing $transition-ease calc($transition-timing/2);


            .gold-tabs-menu--show-mobile-nav  & {
                max-height: px-to-em(500px);
                opacity: 1;
                z-index: 1;
            }

        }

        &-nav-option {
            padding-right: 0;

            &--is-active {
                display: none;
            }
        }

        &-btn {
            text-align: left;
		    padding: 1em $section-spacing-small;
		    width: 100%;
        }
    }
}
