$share-tools-nav-width: px-to-em(65px);
$share-tools-nav-height: px-to-em(70px);


$sticky-nav-offset: $site-header-height + 25;


.share-tools {
	position: absolute;
	top: 0;
	width: $share-tools-nav-width;

	&.is-sticky {
		position: fixed;
		top: px-to-em($sticky-nav-offset);
	}

	&.is-anchored-bottom {
		position: absolute;
		bottom: 0;
		top: auto;
	}
}


.share-tools-list {
	padding-left: 5%;
	padding-top: px-to-em(10px);

}

.share-tools-option {
	position: relative;

	&:first-child {
		.share-tools-link {

			&:before {
				border-top: 0 none;	
			}
			
		}		
	}
}


.share-tools-link {
	display: block;
	height: $share-tools-nav-height;
	color: rgba(255, 255, 255, 0.5);
	margin-bottom: px-to-em(1px);
	text-align: center;

	&:hover {
		color: $color-brand-white;

		&:after {
			background-color: $color-brand-red;
		}
	}
	//angled background
	&:after {
		@include transition(background-color);

		content: '';
		position: absolute;
		left:0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: $color-gray-dark;
		z-index: 1;
		transform: skewY($angled-degree * -1);
	}


	//setup icon family
	&:before {
		@include center(vertical, relative);
		@include ico-icon();
		
		z-index: 2;
		font-size: px-to-em(30px);
	}

	&.ico-mail {

		&:before {
			font-size: rem(22px);	
		}
	}
}

@include screen($bp-desktop) {
	.share-tools-list {
		padding-left: 0;

	}
}

@include screen($bp-tablet) {

	$share-tools-nav-width: px-to-em(50px);
	$share-tools-nav-height: px-to-em(55px);


	.share-tools{
		width: $share-tools-nav-width;
	}


	.share-tools-link {
		height: $share-tools-nav-height;

		&:hover {
			color: $color-brand-white;

			&:after {
				background-color: $color-brand-red;
			}
		}


		//setup icon family
		&:before {
			font-size: px-to-em(24px);
		}

		&.ico-mail {

			&:before {
				font-size: px-to-em(16px);	
			}
		}
	}
}

@include screen($bp-mobile) {
	$share-tools-nav-width: px-to-em(50px);

	.share-tools {
		position: relative;
		width: auto;

		text-align: center;

		&.is-sticky {
			position: static;
		}

		&.is-anchored-bottom {
			position: static;
		}
	}

	.share-tools-list{
		@include clearfix;

		display: inline-block;
		padding-top: 0;
		margin-top: $section-spacing-mobile * -0.5;
		
	}
	.share-tools-option{	
		float: left;
		margin-right: px-to-em(1px);
		width: $share-tools-nav-width;

	}
	.share-tools-link {

		&:after {
			transform:skewX($angled-degree * -1);
		}
		
	}
}