.listing-site-search-item {
	font-size: 0.8em;
	padding-bottom: $section-spacing-reduced;
	border-bottom: $border-list-heavy;

	& + & {
		padding-top: $section-spacing-reduced;
	}
}

.listing-site-search-meta {
	&:before {
		content: '|';
		display: inline-block;
		margin-right: 0.25em;
	}
}

.listing-site-search-title {
	$heading-size: 36px;
	$underline-color: $color-link-hover;

	@include font-open-sans-light($heading-size);

	display: inline;
	color: $color-link;
	line-height: 1.3;

	background-image: linear-gradient(to bottom, $underline-color 0%, $underline-color 100%);
	background-repeat: no-repeat;
	background-position: 0 38px;
	background-size: 0  auto;
	transition: color $transition-timing $transition-ease,
				background $transition-timing $transition-ease;

	&:hover {
			background-size: 100%  auto;
			color: $color-link-hover;
	}
}

.listing-site-search-abstract  {
	margin-top: $section-spacing-small;
}

//from http://browserhacks.com/ FIrefox media query hack
@media all and (min--moz-device-pixel-ratio:0) {
	.listing-site-search-title {
		background-position: 0 38px;
	}
}

//from http://browserhacks.com/ IE EDge and up media query hack
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.listing-site-search-title {
		background-image: none;
		@include underline-transition($color-link-hover)
	}
}

@include screen($bp-tablet) {
	.listing-site-search-item {
		font-size: 1em;
		padding-bottom: $section-spacing-mobile;

		& + & {
			padding-top: $section-spacing-mobile;
		}
	}

	.listing-site-search-title {
		$heading-size: 24px;

		font-size: px-to-em($heading-size);

		background-position: 0 32px;
	}
}