$map-height: 380px;
.location-map{
    width: 100%;
    height: $map-height;
    padding-top: px-to-em(30px);
    position: relative;
    .map{
        height: 100%;
        text-align: left;
    }
    $image-padding: 20px;
    $spacing: 20px;
    .location-image{
        width: 50%;
        display: none;
        background-size: cover;
        height: $map-height;
        color: $color-brand-white;
        padding: $image-padding;
        position: relative;

        //dropshadow
        &:before {
            @include absolute-full;

            content: '';
            background-color:  $color-entity-tile-dim;
            
        }
    }
    .location-image-info {
        position: relative;
        z-index: 1;
    }
    .nearest-label{
        @include font-open-sans-extra-bold(18px);
        text-transform: uppercase;
        display: block;
    }
    .city-name{
        @include font-open-sans-extra-bold(36px);
        @include link-transition($color-brand-gold, $color-brand-white);
        margin-top: px-to-em($spacing, 36px);
        display: inline-block;
    }
    .addresses{
        margin-top: px-to-em($spacing);
    }
    .clear-map {
        margin-top: px-to-em($spacing);
    }
    .address-item{
        font-size: px-to-em(20px);
        display: block;
    }
    .contact-info{
        margin-top: px-to-em(2*$spacing, 20px);
        font-size: px-to-em(20px);
    }
    .phone{
        display: block;
        &:before{
            content: 'T:';
            margin-right: 6px;
        }
    }
    .fax{
        display: block;
        &:before{
            content: 'F:';
            margin-right: 6px;
        }
    }
    .marker-info-window {
        color: $color-brand-red;
        font-weight: $font-weight-bold;
        &:hover {
            text-decoration: underline;
        }
    }
    $search-padding:10px;
    .map-search{
        position: absolute;
        right: 0;
        bottom: 0;
        width: 50%;
        background-color: $color-black;
        color: $color-brand-gold;
        padding: $search-padding;
    }
    .map-search-container{
        position: relative;
        display: flex;
    }
    $input-width: 60%;
    .search-input-container{
        position: relative;
        width: $input-width;
    }
    $compass-width: 10px;
    .current-location{
    	position: absolute;
    	right: $compass-width + 10px;
    	color: $color-brand-red;
    	cursor: pointer;
    	@include center('vertical');
    	@include ico-icon-before($icon-location);
    }
    .keyword-search-input{
        margin-right: px-to-em(10px);
        width: 100%;
    }
    .get-directions{
        display: inline-block;
        border-color: $color-brand-gold;
        @include button-transition(transparent,$color-brand-gold, $color-brand-gold, $color-black);
        width: (100% - $input-width);
        min-width: 30%;
    }
    &.show-image,&.location-detail{
        display: flex;
        width: 100%;
        .location-image{
            display: block;
            height: 100%;
        }
        .map{
            width: 50%;
        }
    }
    &.location-detail{
        $map-height: 530px;
        padding-top: px-to-em(30px);
        height: $map-height;
        .map{
            height: 100%;
        }
        .location-image{
            position: relative;
        }
        .clock{
            right: $image-padding;
            top: $image-padding;
            left: auto;
            transform: none;
        }
        .city-name{
            color: $color-brand-white;
            margin: 0;
            &:hover{
                &:before{
                    display: none;
                }
            }
        }
        .addresses{
            margin-top: 0;
        }
        .contact-info{
            position: absolute;
            bottom: $image-padding;
            left: $image-padding;
            width: 100%;
        }
    }
    // Accessibility
    .skip-to {
        color: #000000;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
    @include screen($bp-mobile){
        .location-image{
            font-size: 14px;
        }
        .clear-map{
            display: block; 
            .btn-ghost{
                width: 100%; 
            }
        }
        &.show-image{
            height: auto;
            .map{
                display: none;
            }
            .map-search{
                display: none;
            }
            .location-image{
                width: 100%;
                height: 80vw;
                min-height: 340px;
            }
            .clock{
                display: none;
            }
        }
    };
}
