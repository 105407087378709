.columns {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;


  &:after {
    content: " ";
    display: block;
    clear: both;
  }

  .col {
    margin-bottom: 20px;

    @media screen and (max-width: 768px){
      clear:both !important;
      width:100% !important;
      float:left !important;
      margin-left:0 !important;
      margin-right:0 !important;
    }
  }

  &.columns-2 .col {
    width: 48.93625%;
    float: left;
    margin-right: 2.1275%;
  }

  &.columns-2 .col:nth-child(even) {
    margin-right: 0;
  }
}