﻿button,
.btn {
    display: inline-block;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0;
    padding: 0;
    margin: 0;
    transition: $transition-default;
}

.btn-primary {
    @include font-size-buttons();
}

.btn-ghost {
    @extend %uc;
    display: inline-block;
    font-weight: $font-weight-bold;
    padding: 0.75em 1.5em;
    min-width: px-to-em(320px);
    border: thin solid $color-brand-red;
    overflow: hidden;
    position: relative;
    text-align: center;

    @include button-transition();

    &--diversity {
        border: 1px solid $diversity-green;
        color: $diversity-green;

        &:after {
            background-color: $diversity-green;
        }
    }

    &--winrs {
        border: 1px solid $diversity-orange;
        color: $diversity-orange;

        &:after {
            background-color: $diversity-orange;
        }
    }
}

.btn-ghost-label {
    position: relative;
    z-index: 2;
}

.btn-ghost--inverse {
    color: $color-brand-white;
    border-color: $color-brand-white;

    &:hover {
        color: $color-brand-black;
    }

    &:after {
        background-color: $color-brand-white;
    }
}

.btn-ghost--inverse-red {
    background-color: $color-brand-red;
    color: $color-brand-white;
    border-color: $color-brand-red;

    &:hover {
        color: $color-brand-red;
    }

    &:after {
        background-color: $color-brand-white;
    }
}

.btn-ghost--video {
    .btn-ghost-label {
        @include ico-icon-after($icon-video-circle) {
            margin-left: 0.5em;
            margin-top: 0.1em;
        }
    }
}

.btn-text-load-more {
    display: block;
    margin: $section-spacing-large auto 0;

    .btn-ghost-label {
        &:after {
            content: "+";
            display: inline-block;
            margin-left: 0.25em;
        }
    }
}

.btn-ghost--submit {
    padding-left: 1em;
    padding-right: 1em;
    border-color: $color-brand-gold;
    color: $color-brand-gold;
    min-width: auto;
    line-height: 1;

    &:hover {
        color: $color-brand-black;
    }

    &:after {
        background-color: $color-brand-gold;
    }

    .btn-ghost-label {
        @include ico-icon-before($icon-search) {
            font-size: px-to-em(22px);
        }
    }
}

.btn-ghost--clear {
    @extend .btn-ghost--submit;

    &:hover {
        color: $color-brand-black;
    }

    &:after {
        background-color: $color-brand-gold;
    }

    .btn-ghost-label {
        @include ico-icon-before($icon-close) {
            font-size: px-to-em(22px);
        }
    }
}

.btn-ghost--gold {
    border-color: $color-brand-gold;
    color: $color-brand-gold;
    min-width: auto;
    line-height: 1;

    &:hover {
        color: $color-brand-black;
    }

    &:after {
        background-color: $color-brand-gold;
    }
}

.btn-ghost--orange {
    border-color: $diversity-orange;
    color: $diversity-orange;
    min-width: auto;
    line-height: 1;

    &:hover {
        color: $color-brand-black;
    }

    &:after {
        background-color: $diversity-orange;
        z-index: -1;
    }
}

.btn-ghost--black {
    border-color: $color-brand-black;
    color: $color-brand-black;
    min-width: auto;
    line-height: 1;

    &:hover {
        color: $color-brand-white;
    }

    &:after {
        background-color: $color-brand-black;
        z-index: -1;
    }
}

.btn-ghost--submit-search {
    border-color: $color-brand-gold;
    color: $color-brand-gold;
    min-width: auto;
    line-height: 1;

    &:hover {
        color: $color-brand-black;
    }

    &:after {
        background-color: $color-brand-gold;
    }

    @include ico-icon-before($icon-search) {
        @include center(both);
        font-size: px-to-em(22px);
        position: absolute;
        color: inherit;
        z-index: 2;
    }
}

@include screen($bp-tablet) {
    .btn-ghost {
        font-size: px-to-em($font-size-buttons-mobile);

        &--center {
            margin-left: auto;
            margin-right: auto;
        }
    }

    .btn-text-load-more {
        margin-top: $section-spacing-large;
    }
}

@include screen($bp-mobile-portrait) {
    .btn-ghost {
        min-width: auto;
    }
}
