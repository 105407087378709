.article-document-section {
	&__forecast {
		&--panel {
			&__this-article {
				color: $color-brand-white;
      			background-color: $forecast-primary-red;
				.btn-ghost {
					@include button-transition(transparent,$color-brand-white, $color-brand-white, $forecast-primary-red);
    			}
			}

			&__full-article {
				color: $color-brand-white;
		      	background-color: $forecast-secondary-red;
				.btn-ghost {
	    			@include button-transition(transparent,$color-brand-white, $color-brand-white, $forecast-secondary-red);
		    	}		      	
		    }

			&__subscribe {
				color: $color-brand-white;
		      	background-color: $color-charcoal;	      		 
				.btn-ghost {
	    			@include button-transition(transparent,$color-brand-white, $color-brand-white, $color-charcoal);
		    	}			      	
		    }

			&__up {
				color: $color-text-gray;
		      	background-color: $color-gray;	
				margin-top: 3em;

		      	&-previous {
		    		&--link {
						display: inline-block;
						&-icon {
							font-weight: $font-weight-bold;
							&:before {
								@include transition(transform);

								content: '⟵';
								display: inline-block;
								margin-right: 0.5em;
							}
						}

						&-title { 
							padding-top: $element-spacing-small; 
							font-size: px-to-em($font-size-copy);
						}	

						&:hover {
						    text-decoration: underline;
						}					
					}
		    	}		      	

			    &-next {
		    		text-align: right;
					padding-top: $element-spacing; 

			    	&--link {
						display: inline-block;
			    		&-icon {
							font-weight: $font-weight-bold;
					    	&:after {
								@include transition(transform);

								content: '⟶';
								display: inline-block;
								margin-left: 0.5em;
							}
						}

						&-title { 
							padding-top: $element-spacing-small; 
							font-size: px-to-em($font-size-copy);
						}	

						&:hover {
						    text-decoration: underline;
						}	
					}
			    }	

			    @include screen($bp-tablet, "min") { 
					&-previous {
				    	width: 50%;		
				    }	      	

				    &-next {
				    	width: 50%;	
				    	padding-top: 0; 
				    }				    
			    }      	
		    }

			border-bottom: px-to-em(3px) solid $color-brand-white;
		    padding: $element-spacing 0;   

		    &__wrapper {
				display: flex; 
		    	flex-direction: column;		   
				justify-content: space-between;

				@include screen($bp-tablet, "min") { 
					flex-direction: row;
				}		    		    	 
		    }

		    &__label {		    
		    	&--text {
		    		font-size: px-to-em($font-size-h3);
		    		line-height: 1.6;
		    	}

		    	&--abstract {

		    	}
		    }

		    &__button {
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-top: $element-spacing; 

		    	.btn-ghost {
		    		border: thin solid $color-brand-white;
		    		color: $color-brand-white;
		    	}
		    }

			@include screen($bp-tablet, "min") { 
			    &__label {
			    	width: 70%;
			    }

			    &__button {
			    	width: 30%;	                
			    	padding-top: 0;
			    }			
			}		    
		}
	}	
}

.page-article-detail__forecast {
	.btn-ghost {
	    &-label {
			font-size: px-to-em(24px);
			text-transform: none;
		}
	}	

	&.forecast-theme__melon {
		.btn-ghost {
			@include button-transition(transparent,$forecast-primary-melon, $forecast-primary-melon, $color-brand-white);
			border-color: $forecast-primary-melon;
		}
		.article-document-section {
			&__forecast {
				&--panel {
					&__this-article {
		      			background-color: $forecast-primary-melon;
						.btn-ghost {
	    					@include button-transition(transparent,$color-brand-white, $color-brand-white, $forecast-primary-melon);
	    					border-color: $color-brand-white;
		    			}
	    			}

					&__full-article {
				      	background-color: $forecast-secondary-melon;

						.btn-ghost {
			    			@include button-transition(transparent,$color-brand-white, $color-brand-white, $forecast-secondary-melon);
			    			border-color: $color-brand-white;
				    	}		      	
				    }
					&__subscribe {
						.btn-ghost {
			    			@include button-transition(transparent,$color-brand-white, $color-brand-white, $color-charcoal);
			    			border-color: $color-brand-white;
				    	}			      	
				    }				    
		    	}
		    }
	    }	
	    .reader-progress__bar {
	        background-color: $forecast-primary-melon;
	    }	    
    }

	&.forecast-theme__marine {
		.btn-ghost {
			@include button-transition(transparent,$forecast-primary-marine, $forecast-primary-marine, $color-brand-white);
			border-color: $forecast-primary-marine;
		}	
		.article-document-section {
			&__forecast {
				&--panel {
					&__this-article {
		      			background-color: $forecast-primary-marine;
						.btn-ghost {
	    					@include button-transition(transparent,$color-brand-white, $color-brand-white, $forecast-primary-marine);
	    					border-color: $color-brand-white;
		    			}
	    			}

					&__full-article {
				      	background-color: $forecast-secondary-marine;

						.btn-ghost {
			    			@include button-transition(transparent,$color-brand-white, $color-brand-white, $forecast-secondary-marine);
			    			border-color: $color-brand-white;
				    	}		      	
				    }
					&__subscribe {
						.btn-ghost {
			    			@include button-transition(transparent,$color-brand-white, $color-brand-white, $color-charcoal);
			    			border-color: $color-brand-white;
				    	}			      	
				    }					    
		    	}
		    }
	    }	
	    .reader-progress__bar {
	        background-color: $forecast-primary-marine;
	    }
    }    

	&.forecast-theme__plum {
		.btn-ghost {
			@include button-transition(transparent,$forecast-primary-plum, $forecast-primary-plum, $color-brand-white);
			border-color: $forecast-primary-plum;
		}	
		.article-document-section {
			&__forecast {
				&--panel {
					&__this-article {
		      			background-color: $forecast-primary-plum;
						.btn-ghost {
	    					@include button-transition(transparent,$color-brand-white, $color-brand-white, $forecast-primary-plum);
	    					border-color: $color-brand-white;
		    			}
	    			}

					&__full-article {
				      	background-color: $forecast-secondary-plum;

						.btn-ghost {
			    			@include button-transition(transparent,$color-brand-white, $color-brand-white, $forecast-secondary-plum);
			    			border-color: $color-brand-white;
				    	}		      	
				    }
					&__subscribe {
						.btn-ghost {
			    			@include button-transition(transparent,$color-brand-white, $color-brand-white, $color-charcoal);
			    			border-color: $color-brand-white;
				    	}			      	
				    }					    
		    	}
		    }
	    }	
	    .reader-progress__bar {
	        background-color: $forecast-primary-plum;
	    }	    
    }      

	&.forecast-theme__red {
		.btn-ghost {
			@include button-transition(transparent,$forecast-primary-red, $forecast-primary-red, $color-brand-white);
			border-color: $forecast-primary-red;
		}	
		.article-document-section {
			&__forecast {
				&--panel {
					&__this-article {
		      			background-color: $forecast-primary-red;
						.btn-ghost {
	    					@include button-transition(transparent,$color-brand-white, $color-brand-white, $forecast-primary-red);
	    					border-color: $color-brand-white;
		    			}
	    			}

					&__full-article {
				      	background-color: $forecast-secondary-red;

						.btn-ghost {
			    			@include button-transition(transparent,$color-brand-white, $color-brand-white, $forecast-secondary-red);
			    			border-color: $color-brand-white;
				    	}		      	
				    }
					&__subscribe {
						.btn-ghost {
			    			@include button-transition(transparent,$color-brand-white, $color-brand-white, $color-charcoal);
			    			border-color: $color-brand-white;
				    	}			      	
				    }					    
		    	}
		    }
	    }	
	    .reader-progress__bar {
	        background-color: $forecast-primary-red;
	    }	    
    }

	&.forecast-theme__olive {
		.btn-ghost {
			@include button-transition(transparent,$forecast-primary-olive, $forecast-primary-olive, $color-brand-white);
			border-color: $forecast-primary-olive;
		}	
		.article-document-section {
			&__forecast {
				&--panel {
					&__this-article {
		      			background-color: $forecast-primary-olive;
						.btn-ghost {
	    					@include button-transition(transparent,$color-brand-white, $color-brand-white, $forecast-primary-olive);
	    					border-color: $color-brand-white;
		    			}
	    			}

					&__full-article {
				      	background-color: $forecast-secondary-olive;

						.btn-ghost {
			    			@include button-transition(transparent,$color-brand-white, $color-brand-white, $forecast-secondary-olive);
			    			border-color: $color-brand-white;
				    	}		      	
				    }
					&__subscribe {
						.btn-ghost {
			    			@include button-transition(transparent,$color-brand-white, $color-brand-white, $color-charcoal);
			    			border-color: $color-brand-white;
				    	}			      	
				    }					    
		    	}
		    }
	    }	
	    .reader-progress__bar {
	        background-color: $forecast-primary-olive;
	    }	    
    }

	&.forecast-theme__brand-red {
		.btn-ghost {
			@include button-transition(transparent,$forecast-primary-brand-red, $forecast-primary-brand-red, $color-brand-white);
			border-color: $forecast-primary-brand-red;
		}	
		.article-document-section {
			&__forecast {
				&--panel {
					&__this-article {
		      			background-color: $forecast-primary-brand-red;
						.btn-ghost {
	    					@include button-transition(transparent,$color-brand-white, $color-brand-white, $forecast-primary-brand-red);
	    					border-color: $color-brand-white;
		    			}
	    			}

					&__full-article {
				      	background-color: $forecast-secondary-brand-red;

						.btn-ghost {
			    			@include button-transition(transparent,$color-brand-white, $color-brand-white, $forecast-secondary-brand-red);
			    			border-color: $color-brand-white;
				    	}		      	
				    }
					&__subscribe {
						.btn-ghost {
			    			@include button-transition(transparent,$color-brand-white, $color-brand-white, $color-charcoal);
			    			border-color: $color-brand-white;
				    	}			      	
				    }					    
		    	}
		    }
	    }	
	    .reader-progress__bar {
	        background-color: $forecast-primary-brand-red;
	    }	    
    }

	&.forecast-theme__royal {
		.btn-ghost {
			@include button-transition(transparent,$forecast-primary-royal, $forecast-primary-royal, $color-brand-white);
			border-color: $forecast-primary-royal;
		}	
		.article-document-section {
			&__forecast {
				&--panel {
					&__this-article {
		      			background-color: $forecast-primary-royal;
						.btn-ghost {
	    					@include button-transition(transparent,$color-brand-white, $color-brand-white, $forecast-primary-royal);
	    					border-color: $color-brand-white;
		    			}
	    			}

					&__full-article {
				      	background-color: $forecast-secondary-royal;

						.btn-ghost {
			    			@include button-transition(transparent,$color-brand-white, $color-brand-white, $forecast-secondary-royal);
			    			border-color: $color-brand-white;
				    	}		      	
				    }
					&__subscribe {
						.btn-ghost {
			    			@include button-transition(transparent,$color-brand-white, $color-brand-white, $color-charcoal);
			    			border-color: $color-brand-white;
				    	}			      	
				    }					    
		    	}
		    }
	    }	
	    .reader-progress__bar {
	        background-color: $forecast-primary-royal;
	    }	    
    }

	&.forecast-theme__tangerine {
		.btn-ghost {
			@include button-transition(transparent,$forecast-primary-tangerine, $forecast-primary-tangerine, $color-brand-white);
			border-color: $forecast-primary-tangerine;
		}	
		.article-document-section {
			&__forecast {
				&--panel {
					&__this-article {
		      			background-color: $forecast-primary-tangerine;
						.btn-ghost {
	    					@include button-transition(transparent,$color-brand-white, $color-brand-white, $forecast-primary-tangerine);
	    					border-color: $color-brand-white;
		    			}
	    			}

					&__full-article {
				      	background-color: $forecast-secondary-tangerine;

						.btn-ghost {
			    			@include button-transition(transparent,$color-brand-white, $color-brand-white, $forecast-secondary-tangerine);
			    			border-color: $color-brand-white;
				    	}		      	
				    }
					&__subscribe {
						.btn-ghost {
			    			@include button-transition(transparent,$color-brand-white, $color-brand-white, $color-charcoal);
			    			border-color: $color-brand-white;
				    	}			      	
				    }					    
		    	}
		    }
	    }	
	    .reader-progress__bar {
	        background-color: $forecast-primary-tangerine;
	    }	    
    }

	&.forecast-theme__dark {
		.btn-ghost {
			@include button-transition(transparent,$forecast-primary-dark, $forecast-primary-dark, $color-brand-white);
			border-color: $forecast-primary-dark;
		}	
		.article-document-section {
			&__forecast {
				&--panel {
					&__this-article {
		      			background-color: $forecast-primary-dark;
						.btn-ghost {
	    					@include button-transition(transparent,$color-brand-white, $color-brand-white, $forecast-primary-dark);
	    					border-color: $color-brand-white;
		    			}
	    			}

					&__full-article {
				      	background-color: $forecast-secondary-dark;

						.btn-ghost {
			    			@include button-transition(transparent,$color-brand-white, $color-brand-white, $forecast-secondary-dark);
			    			border-color: $color-brand-white;
				    	}		      	
				    }
					&__subscribe {
						.btn-ghost {
			    			@include button-transition(transparent,$color-brand-white, $color-brand-white, $color-charcoal);
			    			border-color: $color-brand-white;
				    	}			      	
				    }					    
		    	}
		    }
	    }	
	    .reader-progress__bar {
	        background-color: $forecast-primary-dark;
	    }	    
    }

	&.forecast-theme__light {
		.btn-ghost {
			@include button-transition(transparent,$forecast-primary-light, $forecast-primary-light, $color-brand-white);
			border-color: $forecast-primary-light;
		}	
		.article-document-section {
			&__forecast {
				&--panel {
					&__this-article {
		      			background-color: $forecast-primary-light;
						.btn-ghost {
	    					@include button-transition(transparent,$color-brand-white, $color-brand-white, $forecast-primary-light);
	    					border-color: $color-brand-white;
		    			}
	    			}

					&__full-article {
				      	background-color: $forecast-secondary-light;

						.btn-ghost {
			    			@include button-transition(transparent,$color-brand-white, $color-brand-white, $forecast-secondary-light);
			    			border-color: $color-brand-white;
				    	}		      	
				    }
					&__subscribe {
						.btn-ghost {
			    			@include button-transition(transparent,$color-brand-white, $color-brand-white, $color-charcoal);
			    			border-color: $color-brand-white;
				    	}			      	
				    }					    
		    	}
		    }
	    }	
	    .reader-progress__bar {
	        background-color: $forecast-primary-light;
	    }	    
    }
}