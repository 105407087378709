.person-card-angled {
    background-color: $color-brand-red;
    color: $color-brand-white;
    box-shadow: 0 7px 20px 4px rgba(0,0,0,0.5);
    max-width: px-to-em(430px);


    &-name {
        @include font-open-sans-light($font-size-h3);
        margin-bottom: 0.4em;
    }

    &-meta {
        @include font-open-sans-bold($font-size-h4);
    }

    &-details {
        padding: calc($section-spacing/2) $section-spacing-small;

        &--flex {
            display: flex;
        }
    }

    &-text {
        flex: .75;
    }

    &-video {
        flex: .25;
    }

    &-nolink {
        cursor: pointer;
    }

    &-snapshot {
        position: relative;
        margin-left: -35px;
        margin-right: -35px;
    }

    &-svg-wrapper {
        $card-height: 450;
        $card-width: 410;

        position: relative;
        width: 100%;
        height: 0;
        padding-top: calc($card-height / $card-width) * 100%;
        display: block;
    }

    &-svg-mask {
        position: absolute;
        top: 0;
        left: 0;
    }

    &-svg-img {
        -webkit-clip-path: none;
        clip-path: url('#peoplecardclipping');
    }

    @include screen($bp-tablet) {

        &-name {
            font-size: px-to-em($font-size-h3-mobile);
        }

        &-meta {
            font-size: px-to-em($font-size-h4-mobile);
        }

        &-details {
            padding: $section-spacing-small;
        }
    }
}
