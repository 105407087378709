.article-header {
	text-align: center;

	.page-header {
		margin-bottom: 0;
	}

	.angled-hero-slender-content {
		max-width: $content-large-max-width - ($site-padding * 2);
		margin: 0 auto;
	}
}

.article-header-meta  {
	$title-size: 24px;

	font-size: px-to-em($title-size);
	margin-bottom: px-to-em($element-spacing-px, $title-size);

}

.article-header-date {
	& + .article-header-source {
		&:before {
			content: "|";
			display: inline-block;
			margin-left:0.1em;
			margin-right:0.25em;
		}
	}
}

.artice-header-source {
	@include link-transition();
}


@include screen($bp-tablet) {
	.article-header-meta  {
		$title-size: 20px;

		font-size: px-to-em($title-size);
		margin-bottom: px-to-em($element-spacing-px, $title-size);

	}

}
