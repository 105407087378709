$detail-angle-hero-bg-height: px-to-em(405px);
$detail-angle-hero-padding: 35%;
.detail-angled-hero {
	//margin-bottom: $section-spacing-large;
	margin-bottom: 15vw;
	min-height: $detail-angle-hero-bg-height;
	height: auto;
	color: $color-brand-white;
	position: relative;
	z-index: $zindex-angled-hero;

	.angled-hero-background-container{
		position: absolute;
		width: 100%;
		max-width:  $max-width;
		height: 100% + $detail-angle-hero-padding;
		top: 0;
		left: 0;
		overflow: hidden;
		@include screen($site-max-width, 'min'){
			left: 50%;
			transform: translateX(-50%);
		};
	}
	.angled-hero-reference{
		@include angled-background('after', -8deg, 50%);
		height: 100%;
		width: 100%;
		position: relative;
		background-color: white;
		&:after{
			bottom: -1 * $detail-angle-hero-padding + 7.5%;
		}
	}
	.angled-hero-design-bg{
		height: 100%;
		width: 100%;
	}

	.angled-hero-firm-identity,
	.angled-hero-content {
		position: relative;
		z-index: 5;
	}

	.angled-hero-firm-identity  {
		color: $color-brand-white;
		text-align: right;
		float: right;
		width: $hero-logo-width;
		margin-top: 2em;

		.svg-logo {
			width: 100%;

			path{
				fill:$color-brand-white;
			}
		}
	}

	.angled-hero-content {
		clear:both;
		padding-top: $section-spacing-large;
	}


	@include screen($bp-tablet-landscape) {
		$angle-hero-bg-height: px-to-em(725px);
		.angled-hero {
			margin-bottom: 0;
			height: auto;
			min-height: $angle-hero-bg-height;


			.page-wrapper {
				padding-bottom: 26%;
			}
		}

	}

	@include screen($bp-hero-intro-tablet) {
		.angled-hero-firm-identity {
			width: $hero-logo-width-tablet-mid;
		}
	}


	@include screen($bp-tablet) {
		.angled-hero {
			min-height: inherit;
		}

		.angled-hero-content {

			padding-top: $section-spacing;
		}


		.angled-hero-firm-identity {
			width: px-to-em(180px);
		}

	}

	@include screen($bp-mobile) {

		.angled-hero {
			.page-wrapper {
				padding-bottom: 30%;
			}
		}

		.angled-hero-content {
			padding-top: $section-spacing-mobile;
		}

		.angled-hero-firm-identity {
			width: px-to-em(130px);
		}
	}
	@include screen($bp-mobile){
		$detail-angle-hero-bg-height: px-to-em(305px);
		min-height: $detail-angle-hero-bg-height; 
	};
	@include screen($bp-mobile-landscape) {

		.angled-hero {
			$extra-padding: 120px;
			.page-wrapper {
				padding-bottom: $extra-padding;
			}
		}

	}


	@include screen($bp-mobile-portrait) {
	}

}
