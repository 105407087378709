.additional-solutions-note {
	margin-bottom:  0;
}

.additional-solutions-list {
	padding-bottom: $element-spacing-small;
	

	.additional-solution-option {
		margin-bottom: $element-spacing-small;
	}

	.additional-solutions-link {
		background-position: 0 1.215em;
	}
}

//from http://browserhacks.com/ FIrefox media query hack
@media all and (min--moz-device-pixel-ratio:0) {
	.additional-solutions-list {
		.additional-solutions-link {
			background-position: 0 1.26em;
		}
	}
}