.page-capabilities-landing {
    .silent-wrapper {
        @include content-large-wrapper;
    }

    .criteria-shown {
        opacity: initial;
        visibility: initial;
    }

    .industry-list--reverse {
        padding-bottom: 4%;
    }

    .global-solutions {
        padding-top: $section-spacing;
        .services__bg {
            background-image: url('../images/capabilities-lines.jpg');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }
        &__header {
            display: flex;
            align-items: center;
            max-width: 1100px;
            margin-left: auto;
            margin-right: auto;
        }
        &__image {
            width: 30%;
            min-width: 230px;
        }
        &__description {
            max-width: 900px;
            margin: 3rem auto;
            text-align: center;
            line-height: 1.4em;
            font-size: 18px;
        }
        &__button-wrapper {
            margin: 3rem 0;
            text-align: center;
            max-width: 100%;
        }
        &__button {
            display: inline-block;
        }
        &__line {
            flex: 1 1 auto;
            height: 1px;
            background: #000;
        }
        @include max-width-md() {
            background: none;
        }
    }

    @include screen($bp-mobile, "min") {
        #search-top {
            margin-top: 3em; // To accommodate Scrollspy nav negative margin
        }
    }

    @include screen($bp-tablet) {
        .industry-list--reverse {
            padding-bottom: 0;
        }

        .global-solutions__description {
            text-align: left;
        }
    }

    @include screen($bp-mobile) {
        .page-header {
            text-align: center;
        }
    }
}
