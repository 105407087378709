﻿.breadcrumbs {
	@include page-wrapper;

	position: relative;
	margin: $element-spacing auto $element-spacing * 1.5;
	padding-left: $site-padding * 2.2;
    line-height: 1.4;

	&__locations-landing {
		margin-bottom: 5em;
	}

	&__capabilities-landing {
		margin-top: $element-spacing * 3;
	}

	&__item {
		&:after {
			content: '/';
			display: inline-block;
			padding-left: 0.4em;
			margin-right: 0.4em;
		}
		&:last-child {
			&:after {
				content: none;
			}
		}

		&--current {
			font-weight: $font-weight-bold;
		}
	}

	&__link {
		text-decoration: underline;
	}

	@include screen($bp-tablet-landscape) {
		padding-left: $site-padding-mobile * 1.5;

		&__capabilities-landing {
			margin-top: unset;
		}
	}

	@include screen($bp-mobile) {
		padding-left: $site-padding-mobile;
	}
}