﻿.general-header {
    text-align: center;

    .page-header {
        margin-bottom: 0;
    }

    &--error {
        margin-bottom: px-to-em(70px);
    }
}

.general-header-intro {
	max-width: px-to-em(800px, $font-size-copy);
	margin: $element-spacing auto 0;
}

.general-header-cta {
	margin-top: calc($section-spacing/2);

	.btn-ghost {
		min-width: px-to-em(320px);
	}
}


@include screen($bp-tablet) {
    .general-header {
        text-align: center;

        .page-header {
            margin-bottom: 0;
        }

        &--error {
            margin-bottom: px-to-em(30px);
        }
    }
}
