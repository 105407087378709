.search-landing-hero{
	background-size: cover;
	background-position: center -15px;
	min-height: 0;
	background-repeat: no-repeat;
	margin-bottom: $result-item-spacing;

	.btn-ghost--submit-search, .btn-ghost--submit{
		color:$color-brand-white;
		border-color: $color-brand-white;

		&:hover {
				color:$color-brand-black;
		}

		&:after {
				background-color: $color-brand-white;
		}    
	}

}

.search-landing-hero-content {
	max-width: px-to-em(1050px);
	text-align: left;
	margin:0 auto;
}
