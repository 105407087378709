$hero-intro-bg-width: px-to-em(2000px);
$hero-intro-bg-height: px-to-em(760px);
$letters: 38;
$transition-letter-delay: 50ms;
$max-width-hero: 1800px;
.hero-intro {
	height: $hero-intro-bg-height;
	color: $color-brand-white;
	position: relative;
	z-index: 15;
	max-width: $max-width-hero;
	margin: 0 auto $section-spacing-large;
}

.hero-intro-bg, .hero-intro-bg-mobile {
	@include center(horizontal);

	top: 0;
	width: 100%;
	background-position: center bottom;
	background-repeat: no-repeat;
	background-size: cover;
	height: 100%;
	z-index: 1; 
	max-width: $max-width-hero;
}
.hero-intro-bg-mobile {
	display: none;
}

.hero-intro-header{
	font-size: px-to-em(24px);
	font-weight: bold;
	margin-bottom: px-to-em(20px);
	display: block;
	text-transform: uppercase; 
}
.hero-intro-title{ 
	font-size: px-to-em(40px);
	font-weight: 300;
	margin-bottom: px-to-em(36px, 40px); 
}

.hero-intro-message {
	position: relative;
	z-index: 5;
	width: 50%;
}

.hero-intro-logo {
	@include transition(z-index);

	position: relative;
	text-align: right;
	z-index: $zindex-site-header; //need to have this sit on top of header
	transition-delay: $transition-timing;

	.show-site-nav ~ .main-content & {
		z-index: $zindex-angled-hero + 5;
	 	transition: none
	}

	.svg-logo-link {
		position: absolute;
		top:  $angle-hero-padding - 0.5em;
		right: $site-padding - 1em;
		display: block;
		width: px-to-em($hero-logo-width + 30px);
		height: px-to-em(60px);
	}
	.logo-img,.logo-img-mobile{
		width: 100%;
	}
	.logo-img-mobile{
		display: none;
	}
	.svg-logo {
		width: 100%;
		path{
			fill:$color-brand-white;
		}
	}
}

.hero-intro-message {
	position: absolute;
	left: 105px;
	width: calc(50% - 160px);
	transform: translateY(px-to-em(200px));
	text-align: left;
	z-index: 5;


	.hero-intro-video  {
		opacity: 1;
	}
}

.hero-intro-tagline {
	// @include font-open-sans-extra-bold($font-size-hero);
	@include transition(font-size);
	position: absolute;
	top: 5.8125em; 
	right: 1.875em;
	display: block;
	color: white;

	.word {
		position: relative;
		line-height: 1;
		perspective: 1000px;
	}

	.word-break {
		&:after {
			content: ' ';
		}

		& ~ .word-break {
			display: block;

			&:after {
				content: '';
			}

			& ~ .word-break {
				display: inline;

				&:after {
					content: ' ';
				}
			}
		}
	}

	.letter {
		position: relative;
		display: inline-block;
		opacity: 0;
		transform-origin: 0% 50%;
		transform: rotateY(45deg);
		transition: opacity $transition-timing $transition-ease,
					transform $transition-timing $transition-ease;

		@while $letters > 0 {
			&:nth-child( #{$letters} ) {
				transition-delay: $transition-letter-delay * $letters;
			}

			$letters: $letters - 1;
		}
	}
	&.animate-in{
		.letter{
			opacity: 1;
		}
	}
}

html[lang='es'] {
	$letters: 45;
	
	.hero-intro-tagline {
			

		.letter {
			transition: opacity $transition-timing $transition-ease,
					transform $transition-timing $transition-ease;

			@while $letters > 0 {
				&:nth-child( #{$letters} ) {
					transition-delay: $transition-letter-delay * $letters;
				}

				$letters: $letters - 1;
			}
		}
	}
}

@media (max-width: 1200px) {
	.hero-intro-header{
		font-size: px-to-em(14px);
	}
	.hero-intro-title{ 
		font-size: px-to-em(32px);
		margin-bottom: px-to-em(40px, 32px);
	}
}

@include screen($bp-hero-intro-tablet) {
	$hero-intro-bg-height: px-to-em(900px);

	.hero-intro {
		margin-bottom: $element-spacing-small;
	}

	.hero-intro,
	.hero-intro-bg {
		height: $hero-intro-bg-height;
	}

	.hero-intro-bg {
		background-size: auto 100%;
	}

	.hero-intro-logo {

		.svg-logo-link {
			width: px-to-em($hero-logo-width-tablet-mid);
			height: px-to-em(36.5px);
		}
	}
	
	.hero-intro-message {
		line-height: 1.1;
		transform: translateY(px-to-em(180px));
		$left-margin: 25px;
		left: $left-margin;
	}

}


@include screen($bp-tablet) {
	$hero-intro-bg-height: px-to-em(525px);

	.hero-intro {
		overflow: hidden;
	}

	.hero-intro-logo {

		.svg-logo-link {
			width: px-to-em($hero-logo-width-tablet + 16px);
			height: px-to-em(45px);
			right: 0.5em;
		}
	}

	.hero-intro-tagline {
		font-size: px-to-em(10px);
		top: 7.8em;
	}

	.hero-intro-video {
		margin: $element-spacing auto;
	}

	.hero-intro,
	.hero-intro-bg {
		height: $hero-intro-bg-height;
	}
	
	.hero-intro-header{
		font-size: px-to-em(14px);
	}
	.hero-intro-title{ 
		font-size: px-to-em(20px);
		margin-bottom: px-to-em(40px, 24px);
	}
	
	.hero-intro-message {
		line-height: 1.1;
		transform: translateY(px-to-em(180px));
		left: 25px;
		width: calc(50% - 60px);


	}

}

@include screen($bp-mobile) {
	$hero-intro-bg-height: px-to-em(480px);

	.hero-intro {
		margin-bottom: 0;

	}
	.hero-intro,
	.hero-intro-bg {
		height: $hero-intro-bg-height;
		background-position: center -15px;
		background-repeat: no-repeat
	}
	.hero-intro-bg {
		display: none;
	}
	.hero-intro-bg-mobile{
		display: block;
	}
	.hero-intro-logo{
		.logo-img{
			display: none;
		}
		.logo-img-mobile{
			display: block;
		}	
	}
	.btn-ghost{
		display: block;
	}
	.hero-intro-tagline {
		font-size: px-to-em(10px);

		.word-break {

			& ~ .word-break {

				& ~ .word-break {
					display: block;

				}
			}
		}
	}

	.hero-intro-logo {

		.svg-logo-link {
			top: $site-padding;
			width: px-to-em($hero-logo-width-mobile);
			height: px-to-em(25px);
		}
	}
	.hero-intro-header{
		font-size: px-to-em(14px);
	}
	.hero-intro-title{ 
		font-size: px-to-em(24px);
		margin-bottom: px-to-em(40px, 24px);
	}
	
	.hero-intro-message {
		line-height: 1.1;
		transform: translateY(px-to-em(100px));
		$left-margin: 15px;
		left: $left-margin;
		width: calc(100% - #{$left-margin*2});
		.word-break {
			display: block;
		}

		br {
			display: none;
		}
	}

}


//DIVERSITY
.hero-intro-diversity {
    min-height: 75vh;
    color: $color-brand-white;
    position: relative;
		z-index: 15;
		padding-bottom: px-to-em(350px);
		
    @include screen($bp-mobile, "min") {
			min-height: inherit;
    }

    &--detail {
        height: auto;
        padding-top: 10em;
        padding-bottom: 3em;
				background-size: cover;
				background-position: left;
        @include screen($bp-tablet) {
            padding-top: 9em;
						padding-bottom: 7em;
						background-position: right;
        }

        @include screen($bp-mobile) {
            padding-top: 7em;
						padding-bottom: 6em;
						min-height: unset;
					}
    }
}

.hero-intro-tagline-diversity {
    @include font-open-sans-extra-bold($font-size-h3-mobile);
    @include transition(font-size);
    padding-bottom: 0.8em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @include screen($bp-mobile, "min") {
        @include font-open-sans-extra-bold($font-size-h3);
    }

    @include screen($bp-between-tablet, "min") {
        @include font-open-sans-extra-bold($font-size-h2);
        padding-bottom: 0.5em;
    }

    @include screen($bp-desktop-large, "min") {
        @include font-open-sans-extra-bold($font-size-hero-diversity);
        padding-bottom: 60px;
    }
}

.hero-intro-tagline-diversity--detail {
	@include font-open-sans-extra-bold($font-size-detail-diversity);
	@include transition(font-size);
	padding-bottom: 0.8em;
}

.hero-intro-tagline-diversity-light {
	@include font-open-sans-light($font-size-h3-mobile);
	@include transition(font-size);
	padding-bottom: 0.5em;

	@include screen($bp-mobile, "min") {
		@include font-open-sans-light($font-size-h3);
	}

	@include screen($bp-between-tablet, "min") {
		@include font-open-sans-light($font-size-h2);
		text-transform: uppercase;
		padding-bottom: 0.5em;
	}

	@include screen($bp-desktop-large, "min") {
		@include font-open-sans-light($font-size-hero-diversity);
		text-transform: uppercase;
		padding-bottom: 0;
	}
}

.hero-intro-bg-diversity {
	position: absolute;
	width: 100%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 100%;
	z-index: 1;
	top: 0;

	@include screen($bp-between-tablet, "min") {
		background-attachment: fixed;
	}

	&--detail {
			top: 0;
	}
}

.hero-intro-message-diversity {
	position: relative;
	width: 100%;
	padding-top: 7em;
	text-align: center;
	z-index: 5;
	// display: flex;
	// flex-direction: column;
	// align-items: center;

	@include screen($bp-mobile, "min") {
		padding-top: 9em;
	}

	@include screen($bp-between-tablet, "min") {
		padding-top: 14em;
	}

	@include screen($bp-desktop-large, "min") {
		padding-top: 16em;
	}
}

.hero-intro-wrapper {

	@include screen($bp-mobile-landscape, "min") {
		width: 82%;
		margin-left: auto;
		margin-right: auto;	
	}

	@include screen($bp-between-tablet, "min") {
		width: 75%;
	}
}

.hero-intro-detail-wrapper {
    position: relative;
    z-index: 2;
    text-align: center;
}

.hero-intro-subheader {
	@include font-open-sans-regular($font-size-h5-mobile);
	@include transition(font-size);
	line-height: $font-line-height-3;

	@include screen($bp-between-tablet, "min") {
		@include font-open-sans-regular($font-size-h5);
		line-height: 30px;
	}

	@include screen($bp-desktop-large, "min") {
		@include font-open-sans-regular($font-size-subheader);
		line-height: 1.3em;
		width: 100%;
	}
}

.hero-intro-btn-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;

	@include screen($bp-mobile, "min") {
		flex-direction: row;
		flex-wrap: wrap;
		align-items: flex-start;
	}

	@include screen($bp-between-tablet, "min") {
		width: 65%;
		margin-left: auto;
		margin-right: auto;
	}
}
