﻿@use 'sass:math';

@mixin media-breakpoint-up($name) {

    @if map-has-key($breakpoints, $name) {
        @media #{inspect(map-get($breakpoints, $name))} {
            @content;
        }
    }
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. " + "Please make sure it is defined in `$breakpoints` map.";
    }
}

@function palette($key) {
    @if map-has-key($brand-palette, $key) {
        @return map-get($brand-palette, $key);
    }

    @warn "Unknown `#{$key}` in $brand-palette.";
    @return null;
}

@function strip-unit($num) {
    @return math.div($num, $num * 0 + 1);
}

@function convert-to-rem($value, $base-value: 16px) {
    $value: calc(strip-unit($value) / strip-unit($base-value)) * 1rem;

    @if ($value == 0rem) {
        $value: 0;
    }
    // Turn 0rem into 0
    @return $value;
}

@function rem-calc($values, $base-value: 16px) {
    $max: length($values);

    @if $max == 1 {
        @return convert-to-rem(nth($values, 1), $base-value);
    }

    $remValues: ();

    @for $i from 1 through $max {
        $remValues: append($remValues, convert-to-rem(nth($values, $i), $base-value));
    }

    @return $remValues;
}
