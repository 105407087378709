.slider-carousel{
    $slide-height: 210px;
    $dot-container-height:60px;
    $dot-radius:20px;
    overflow: hidden;
    width: 100%;
    position: relative;
    height: $slide-height + $dot-container-height;
    .slider-container{
        display: flex;
        position: relative;
    }
    .carousel-slide{
        $padding-slide: 12px;
        @include font-open-sans-light(20px);
        background-color: $color-brand-black;
        color: $color-brand-white;
        height: $slide-height;
        display: inline-block;
        margin-right: 20px;
        padding: 10px 20px;

        span{
          color: $color-brand-white; 
        }

        &:hover {
            color: $color-text-white-hover;
        }
    }
    .carousel-dots{
        $dot-margin:30px;
        display: inline-block;
        height: $dot-container-height - $dot-margin;
        margin-top: $dot-margin;
        width: 100%;
        text-align: center;
    }
    .slider-dot{
        width: $dot-radius;
        height: $dot-radius;
        border-radius: $dot-radius;
        margin-right: 10px;
        display: inline-block;
        border: solid 1px $color-link;
        cursor: pointer;
        &.active{
            background-color: $color-link;
        }
        &:last-child{
            margin-right: 0;
        }
    }
    @include screen($bp-tablet) {

        .carousel-slide {
            min-height: px-to-em($entity-tile-height-tablet);
        }
    }

    @include screen($bp-mobile) {
        .carousel-slide{
            min-height: $entity-tile-height-mobile;
        }
    }
}
