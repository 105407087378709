

.recently-recognized-nodes {
	margin-top: $section-spacing-large;
}


@include screen($bp-tablet) {
	.recently-recognized-nodes {
		margin-top: $section-spacing-large-mobile;
	}
}
