﻿.siteheader {
    left: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: rem-calc($max-site-width);
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 100;


    &--internal {

        &::before,
        &::after {
            bottom: 0;
            content: '';
            display: block;
            position: absolute;
            top: 0;
            width: 50vw;
        }

        &::before {
            background-color: #fff;
            left: 50%;
            margin-left: -50vw;
        }

        &::after {
            background-color: palette('dark_grey');
            margin-right: -50vw;
            right: 50%;
        }
    }

    &__inner {
        background-color: rgba(#000, 0.45);

        .siteheader--internal & {
            background-color: #fff;
        }

        @include media-breakpoint-up(lg) {
            display: flex;
            justify-content: flex-end;
        }
    }

    &__toolbar {
        background-color: palette('dark_grey');
        display: flex;
        justify-content: flex-end;
        // z-index: 1;
        @include media-breakpoint-up(lg) {
            order: 1;
        }
    }

    &__utility {
        align-items: center;
        display: flex;
        height: rem-calc($nav-height-small);
        padding: rem-calc(0 16 0 0);
        position: relative;
        z-index: 10;

        @include media-breakpoint-up(sm) {
            padding: rem-calc(0 24);
        }

        @include media-breakpoint-up(lg) {
            height: rem-calc(110);
            padding: rem-calc(0 30 0 30);
        }

        &::before {
            backface-visibility: hidden;
            background-color: palette('dark_grey');
            bottom: 0;
            content: '';
            display: block;
            left: 0;
            position: absolute;
            top: 0;
            transform: skewX(-10deg);
            transform-origin: 0 0;
            width: rem-calc(12);

            @include media-breakpoint-up(lg) {
                content: none;
            }
        }
    }

    &__sitelink {
        align-self: center;
        display: inline-block;
        height: rem-calc(23);
        left: rem-calc(24);
        top: rem-calc(20);
        position: absolute;
        width: rem-calc(123);

        &:focus {
                outline: 5px solid $diversity-cyan;
            }

        @include media-breakpoint-up(lg) {
            height: rem-calc(70);
            left: rem-calc(20);
            width: rem-calc(178);
        }
    }

    //Pinned header

    &--headroom {
        position: fixed;
        transition: transform 0.35s cubic-bezier(.785,.135,.15,.86);
        will-change: transform;
    }

    &--not-top {
        background-color: palette('grey');
    }

    &--pinned {
        transform: translate3d(0, 0, 0);
    }

    &--unpinned {
        transform: translate3d(0, -100%, 0);
    }
}

.sitelogo {
    display: block;
    width: 100%;


    &--small {

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }

    &--large {
        display: none;

        @include media-breakpoint-up(lg) {
            display: block;
        }
    }
}
