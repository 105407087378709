$accordion-spacing: 30px;

.accordions {
    margin-bottom: $section-spacing;
    height: 100%;
}

.accordion-node {
    padding: px-to-em($accordion-spacing) 0;
    border-bottom: $border-list-heavy;

    &:first-child {
        padding-top: 0;
    }

    .lawyerHeader {
        display: inline-block;
        font-weight: $font-weight-extra-bold;
        position: relative;
        font-family: $font-family-primary;
        font-size: px-to-em($section-header-size, $font-size-copy);
        line-height: 1.1;
        text-transform: uppercase;
    
        &:after {
            position: absolute;
            content: '';
            display: block;
            left: 100%;
            margin-left: $site-padding-mobile;
            width: px-to-em(1000px);
            top: 50%;
            margin-top: -1px;
            border-top:1px solid $color-border-list;
            transform: translateZ(0);
        }
    }
}

.accordion-node-title {
    font-size: 1em;
    color: $color-brand-red;

    button{
    color: $color-brand-red;
        &:hover{
            color: $color-brand-black;
        }
    }
}

.accordion-node-trigger {
    $font-size-heading: 24px;
    $icon-box-size: 35px;

    @include font-open-sans-light($font-size-heading);

    line-height: 1.3;
    position: relative;
    width: 100%;
    padding-right: px-to-em($icon-box-size * 1.5);
    text-align: left;

    &:hover {
        //color: $color-brand-red;

        &:before {
        	background-color: $color-brand-black;
        }
    }

    &:before {
    	$icon-font-size: 30px;

        @include center(vertical);
        @include transition(background-color);

        content: "+";
        font-weight: $font-weight-regular;
        background-color: $color-brand-red;
        color: $color-brand-white;
        text-align: center;
        font-size: px-to-em($icon-font-size, $font-size-heading);
        width: $icon-font-size;        
        height: $icon-font-size;
        line-height: 1;


        position: absolute;
        right: 0;

    }

    .is-expanded & {

        &:before {
            content: "–";
            line-height: 0.8;
        }
    }

}

//content has rte class applied
.accordion-node-content {
    $transition-delay: 250ms;
    @include transition(height);

    height: 0;
    overflow: hidden;
    

    &[aria-hidden="false"] {
        height:auto;
    }

    //top spacing, adding psuedo element to avoid conflict with animation of max-height
    &:before {
        content: '';
        display: block;
        padding-top: px-to-em($accordion-spacing, $font-size-copy);
    }
    a{
        font-weight: bold;
    }
    
    .explore-more-cta {
        margin-top: $element-spacing
    }

    .additional-solutions {
        margin-top: $element-spacing * 2; 
    }
}


// @include screen($bp-mobile) {

//     $accordion-spacing: 20px;

//     .accordion-node {
//          padding: px-to-em($accordion-spacing) 0;
//     }


//     .accordion-node-trigger {
//         font-size: px-to-em($font-size-copy);

//         &:after {
//             font-size: 0.7em
//         }
//     }

// }

// .capability-accordion-node-title {
// 	color: $color-black;

// 	.accordion-node-trigger {
// 		width: auto;
// 		outline: none;
// 	}
// }
