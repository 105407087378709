
.professional-results-listing {
	margin-bottom: $section-spacing-large;
}


@include screen($bp-tablet) {
	
	.professional-results-listing {
		margin-bottom: $section-spacing-large;
	}
}
