.practices-list{
    $transition-delay: 250ms;
    display: flex;
    flex-flow: row wrap;
    margin-bottom: $section-spacing-large;
    .practices-column{
        width: 50%;
        padding-right: px-to-em(20px);
        &:last-child{
            padding: 0 0 0 px-to-em(20px);
        }
    }
    .practices-item{
        padding: px-to-em(20px) 0;
        border-bottom: solid 2px $color-border;
        &.is-expanded{
            .toggle{
                &:before{
                    content: "-";
                    line-height: 0.5;
                }
            }
            .practices-item--children{
                margin-top: 10px;
                max-height: px-to-em(2000px);
                opacity: 1;
                transition: max-height $transition-timing*2 $transition-ease,
                            opacity $transition-timing $transition-ease $transition-delay,
                            transform $transition-timing linear $transition-delay;
            }
        }
    }
    $button-width: 35px;
    .toggle{
        background-color: $color-brand-red;
        color: $color-brand-white;
        width: $button-width;
        height: $button-width;
        float: right;
        &:before{
            @include font-open-sans-light(36px, 24px);
            content:"+";
            line-height: $button-width - 3px;
        }
    }
    .practices-item--main {
        @include font-open-sans-light(24px);
        color: $color-brand-red;
        .no-link {
            color: $color-brand-black;
        }
    }
    .practices-item--children{
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        transition: max-height $transition-timing $transition-ease $transition-delay,
                    opacity $transition-timing $transition-ease,
                    transform $transition-timing linear;
    }
    .practices-item--child{
        color: $color-brand-red;
        @include font-open-sans-light(20px);
    }
    ul{
        padding-left: 18px;
        margin-top: 10px;
        li{
            margin-bottom: 20px;
        }
        ul{
            margin-top: 20px;
            margin-bottom: 30px;
            .practices-item--child{
                font-size: 18px;
            }
            li{
                padding-bottom: 10px;
                margin-bottom: 10px;
                border-bottom: solid 1px $color-border;
            }
        }
    }
    a{
        color: $color-link;
        &:hover{
            color:$color-link-hover;
        }
    }
    @include screen($bp-tablet) {
        .practices-item--main{
            font-size: px-to-em(18px);
        }
    }
    @include screen($bp-mobile-landscape){
        .practices-column{
            width: 100%;
            padding-right: 0;
            &:last-child{
                padding-left: 0;
            }
        }
    };
}
