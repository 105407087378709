﻿.cardgroup {
    padding: rem-calc(24 30);

    @include media-breakpoint-up(md) {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding: rem-calc(50 30);
    }

    @include media-breakpoint-up(xl) {
        padding-left: 85px; // specified in px due to IE11 roudning error
        padding-right: 85px; // specified in px due to IE11 roudning error
    }


    &--full {
        padding-left: rem-calc(0);
        padding-right: rem-calc(0);

        @include media-breakpoint-up(lg) {
            padding-left: rem-calc(30);
            padding-right: rem-calc(30);
        }

        @include media-breakpoint-up(xl) {
            padding-left: 85px; // specified in px due to IE11 roudning error
            padding-right: 85px; // specified in px due to IE11 roudning error
        }
    }

    &__cell {

        @include media-breakpoint-up(md) {
            display: flex;
            flex-direction: column;
            padding-left: rem-calc(15);
            padding-right: rem-calc(15);

            @supports (display: grid) {
                display: grid;
                grid-auto-rows: 1fr;
            }
        }
    

        &--half {

            @include media-breakpoint-up(md) {
                width: 50%;
            }
        }

        &--third {

            @include media-breakpoint-up(md) {
                width: calc(100% / 3);
            }
        }
    }
}