.page-site-search-results {

    .listing-articles-item {
        &:last-child {
            border-bottom: 0;
        }
    }
    .keyword-search-grid-option{
        width: 50%;
    }

    
    @include screen($bp-mobile) {
        .keyword-search-grid-option{
            &.has-dropdown{
                width: 100%;
            }
        }
    }
}
