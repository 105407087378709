.people-card-grid {
    margin-bottom: 2em;

    &-clipping-path {
        position: absolute;
        width: 0;
        height: 0;
        z-index: -1;
    }

    &-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .person-card-angled {
        width: 31.5%;
        margin-bottom: $section-spacing;
    }

    @include screen($bp-between-desktop) {
        margin-bottom: 1rem;

        .person-card-angled {
            width: 47.5%;
        }
    }

    @include screen($bp-mobile-landscape) {
        margin-bottom: $section-spacing-large-mobile;

        &-list {
            display: blockl
        }

        .person-card-angled {
            width: 100%;
            max-width: px-to-em(300px);
            margin-left: auto;
            margin-right: auto;
            margin-bottom: $section-spacing-mobile;
        }
    }
}
