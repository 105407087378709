$newsletter-carousel-padding: $element-spacing;
$newsletter-timing: 350ms;

.newsletter-carousel-issue {
	padding:0 $newsletter-carousel-padding;
	position: absolute;
	opacity: 0;
	top: 0;
	left: 0;
	transition: all $newsletter-timing linear;
	z-index: -1;

	&.is-issue-shown {
		opacity: 1;
		position: static;
		z-index: 10;
		width: auto;
	}
}

.newsletter-carousel-issue-title {
	$heading-size: 36px;

	@include font-open-sans-light($heading-size);
	color: $color-brand-red;
	transition: all $newsletter-timing linear;

	&:hover {
		color:$color-link-hover;
	}

	.is-external-link {
		&:after {
			position: absolute; 
			margin-left: 0.25em;
			margin-top: 0.65em;
			font-size: px-to-em(18px, $heading-size);
		}
	}
}

.newsletter-carousel-issue-abstract {
	margin-top: px-to-em($element-spacing-px, $font-size-copy);
	transition: all $newsletter-timing linear;
}

.newsletter-carousel-issues {
	$top-margin: 8%;
	$top-margin-pixels: 100px;
	// height: 100% + $top-margin;
	// margin-top: -1*$top-margin;
	height: calc(100% + #{$top-margin-pixels});
	margin-top: -1*$top-margin-pixels;
	float: right;
	width: $newsletter-carousel-issues-width;
	padding:0 2% $section-spacing 2%;
	color: $color-brand-white;
	background-color: $color-brand-black;

}

.newsletter-carousel-issue-figure {
	$top-margin-percent: 8%;
	$top-margin-pixels: 160px;
	position: absolute;
	// height: calc(100% + #{$top-margin-percent} + #{$top-margin-pixels});
	// top: calc(-1*#{$top-margin-percent} - #{$top-margin-pixels});
	height: calc(100% + #{$top-margin-pixels});
	top: calc(-1*#{$top-margin-pixels});
	width: $newsletter-carousel-figure-width;
	bottom: 0;
	left: 0;
	overflow: hidden;
	transition: all $newsletter-timing linear ;
	transform: translateZ(0);
	@include screen(1200px){
		$top-margin-pixels: 120px;
		height: calc(100% + #{$top-margin-pixels});
		top: calc(-1*#{$top-margin-pixels});
	}
}

.is-transitioning-out {


	.newsletter-carousel-issue-title,
	.newsletter-carousel-issue-abstract, 
	.newsletter-carousel-issue-figure {
		opacity: 0
	}
}


@include screen($bp-newsletter-carousel-tablet) {

	.newsletter-carousel-issue {
		width: 50%;
	}
	
	.newsletter-carousel-issue-figure {
		display: none;
	}
}


@include screen($bp-tablet) {

	.newsletter-carousel-issue {
		left: $site-padding-mobile;
		right: $site-padding-mobile;
	}

	.newsletter-carousel-issue-title {
		font-size: px-to-em(24px);
	}
}

@include screen($bp-mobile) {

	.newsletter-carousel-issue {
		padding: 0;
		width: auto;
	}

	.newsletter-carousel-issue-abstract {
		margin-top: px-to-em($element-spacing-px, $font-size-copy);
	}

	.is-transitioning-out {

		.newsletter-carousel-issue-figure {
			opacity: 0;
		}
	}

	@include screen($angled-hero-desktop){
		$top-margin-pixels: 160px;
		.newsletter-carousel-issue-figure{
			top: -1*$top-margin-pixels;
		}
	}

}
