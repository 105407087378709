
$segment-spacing: 12px;

$segment-base-width: 190px;

.search-segments {
	position: relative;
	width: 50%;
	margin-left: 20px;
}

.search-segments-nav {
	display: flex;
	flex-wrap: wrap;
	position: relative;
	margin-right: px-to-em($segment-spacing * -1);
}

.search-segments-item {
	flex-basis: 20%;
	flex-shrink: 0;
	padding-right: px-to-em($segment-spacing);
	padding-top: px-to-em($segment-spacing);

	&.is-selected {
		.search-segments-link {
			background-color: $color-brand-white;
		}
	}
}



.search-segments-link {
	@include transition(background-color);

	background-color: $color-brand-gold;
	display: inline-block;
	font-weight: $font-weight-extra-bold;
	color: $color-brand-black;
	width: 100%;
	padding: 1em;
	text-align: center;
	cursor: pointer;

	&:hover {
		background-color: $color-brand-white;
	}
}

.search-segments-toggle {
	@extend %uc;

	background-color: $color-brand-gold;
	color: $color-brand-black;
	padding: .94em $element-spacing-small;
	//padding-right: $section-spacing-small * 1.5;
	font-weight: $font-weight-extra-bold;
	position: relative;
	width: 100%;
	text-align: left;
	display: none;

	&:after {
		@include center(vertical);

		content: '';
		width: 1em;
		display: inline-block;
		margin-left: 0.3em;
		background: url($file-path-images + 'chevron-mobile.svg') no-repeat center;
		background-size: 100% auto;
		
  		height: 0.9em; 
  		position: absolute;
  		right: $element-spacing-small;

	}
}

	.search-segments-toggle {
		display: block;
	}	

	.search-segments-nav {
		position: absolute;
		top: 100%;
		width: 100%;
		display: block;
		overflow: hidden;
		max-height: 0;
		padding: 0;
		margin-top: 0;
		transition: max-height $transition-timing $transition-ease;

		.show-menu &{
			max-height: px-to-em(600px);
		}
	}

	.search-segments-item {
		padding-right: 0;
		padding-top: 0;

		&.is-selected {
			display: none;
		}
	}

	.search-segments-link {
		text-align: left;
		padding:$element-spacing-small $element-spacing-small;
		border-top: 1px solid $color-brand-black;
	}

	@include screen($bp-mobile){
		.search-segments {
			margin-top: 1em;
			z-index: 3; // On top of search button, under typeahead results
			width: 100%;
			margin-left: 0;
		}
	}