.locations-landing-header  {
	background-size: cover;
	background-position: center -15px;
	background-repeat: no-repeat;
	padding-bottom: 2.5em;

	.btn-ghost--submit-search, .btn-ghost--submit{
		color:$color-brand-white;
		border-color: $color-brand-white;

		&:hover {
				color:$color-brand-black;
		}

		&:after {
				background-color: $color-brand-white;
		}    
	}
	

	.locations-landing-header-content {
		max-width: px-to-em(1050px);
		margin:0 auto; 
		z-index: $zindex-angled-hero + 2;
		padding-bottom: 0;
		padding-top: 5.5em;
	}


	.angled-hero-slender-polygon {
		height: px-to-em(550px);
	}


	@include screen($bp-mobile) {

		.angled-hero-slender-polygon {
			height: px-to-em(400px);
		}
		.locations-landing-header-content {
			max-width: px-to-em(1050px);
			margin:0 auto;
			z-index: $zindex-angled-hero + 2;
			padding-bottom: 0;
			padding-top: 2.6em;
		}
	
	}

}
