﻿
.svg-icon {
    display: inline-block;
    fill: currentColor;
    pointer-events: none;
    stroke-width: 0;
    stroke: currentColor;
    vertical-align: middle;
    width: 32px;

    &--large {
        width: 48px;
    }

    &--globe {
        font-size: 25px;
    }

    &--chevron {
        font-size: 12px;
    }
}
