$select-dropdown-padding: px-to-em(12px);

.select-dropdown {
    position: relative;
    // display: inline-block;
}

.select-dropdown-select {
    position: absolute;
    z-index: 2;
    top: 0;left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    background: transparent;
    cursor: pointer;
    border: 0;
}

.select-dropdown-toggle {
    //position: relative;
    //z-index: 2;
    color: $color-gray-dark;
    display: block; 
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.1;
    padding:1em $select-dropdown-padding;
    padding-right: $select-dropdown-padding* 2;
    //border: $border-input;
    border-color: $color-brand-gray;
    background-color: $color-brand-gray;
    position: relative;
    
    @include transition(background-color);

    @include ico-icon-after($icon-chevron-down) {
        $icon-size: 5px;
        @include center(vertical);
        font-size: px-to-em($icon-size);
        right: $select-dropdown-padding * 2.25;
    }

    &.focus-highlight-select {
        outline: px-to-em(2px) solid $color-brand-red;        
    }
}

@include screen($bp-tablet) {
    .select-dropdown-toggle {
        font-size: 1em;
        padding: $select-dropdown-padding * 1.5 $select-dropdown-padding;
        padding-right: $select-dropdown-padding* 2;
    }   
}