$dropdown-padding: px-to-em(15px);

.dropdown {
    position: relative;
}

.dropdown-helper-label {
    @extend %uc;
    font-weight: $font-weight-extra-bold;
}

.dropdown-toggle {
    position: relative;
    width: 100%;
    text-align: left;
    background-color: $color-brand-gold;
    padding: $dropdown-padding;
    padding-right: $dropdown-padding*2;

    @include ico-icon-after($icon-chevron-down) {
        $icon-size: 10px;

        @include center(vertical);

        font-size: px-to-em($icon-size);
        right: 1.5em;
    }
}

.dropdown-label {
    font-weight: $font-weight-extra-bold;
    display: block;
    
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.dropdown-menu { 
    position: absolute;
    top: 100%;
    width: 100%;
    background-color: $color-brand-black;
    color: $color-brand-white;
    padding: $element-spacing;
    opacity: 0;
    visibility: hidden;
    max-height: px-to-em(350px);
    overflow: auto;
}

.dropdown-show {
    opacity: 1;
    visibility: visible;
}

.dropdown-menu-option {
    font-weight: $font-weight-bold;
    color: $color-brand-white;
    cursor: pointer;
    
    & +  &  {
        border-top: 1px solid $color-opaque-white;
        padding-top: $element-spacing-small;
        margin-top: $element-spacing-small;
    }
}

.experience-group-dropdown {
    max-width: 80%;
}

@include screen($bp-tablet) {
    .dropdown-helper-label {
        display: inline-block;
        margin-bottom: $element-spacing-small;
    }

    .experience-group-dropdown {
        max-width: 100%;
    }
}