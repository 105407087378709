.read-more-intro {
	padding-bottom: px-to-em($element-spacing-px, $font-size-copy);

	&:last-child {
		padding-bottom: 0;
	}
}

.read-more-full {
	display: none;
	overflow: hidden;
	padding-bottom: px-to-em($element-spacing-px, $font-size-copy);

	margin-left: -0.75em;
	padding-left: 0.75em;
}

.read-more-toggle {
	position: relative;

	.btn-ghost-label {
		&:after {
        	content: '+';
        	display: inline-block;
        	margin-left: 0.25em;
    	}
	}

	&.is-expanded {
		.btn-ghost-label {
			&:after {
				content: "–";
			}
		}
	}
}

@include screen($bp-mobile) {
	.read-more-toggle {
		width: 100%;
	}
}